<template>
	<ion-page>
		<ion-header class="ion-padding">
			<h1 v-if="isEmailVerified" class="loginHeader" v-html="emailVerified"/>
			<h1 v-else class="loginHeader" v-html="emailVerify"/>
		</ion-header>
		<ion-content class="ion-padding">
			<div v-if="isVerifyCheckDone">
				<div v-if="isEmailVerified">
					<verify-success-animation style="max-height: 30rem; width: 100%;margin-bottom: -1em;margin-top: -1em;"></verify-success-animation>
					<div style="color: #18c75d; text-align: center">
						<p>{{ registerSuccessText }}<br>{{ registerSucessGoBackToAppInfo }}
						</p>
					</div>
				</div>
				<div v-else-if="!isEmailVerified && !newResendLinkSend">
					<verify-error-animation style="max-height: 30rem; width: 100%;margin-bottom: -1em;margin-top: -1em;"></verify-error-animation>
					<div style="color: #e04055; text-align: center">
						<p>{{ registerErrorText }}</p>
						<ion-button color="danger" expand="block" @click="resendLink">
							{{ verifyErrorResendLink }}
							<ion-icon class="ion-padding-start" :icon="icons.mailOutline"></ion-icon>
						</ion-button>
					</div>
				</div>
				<div v-else-if="newResendLinkSend" style="text-align: center" class="ion-padding">
					<login-success-animation style="max-height: 30rem; width: 100%;margin-bottom: -1em;margin-top: -1em"></login-success-animation>
					<p v-html="registerSuccessBody"/>
					<p>{{registerGoBackTo}}
						<router-link to="/login">
							<a class="link-text-color"> Login</a>
						</router-link>
					</p>
				</div>
			</div>
		</ion-content>

		<ion-footer style="padding: 25px">
			<p style="margin: auto;text-align: center;">
				<span>
					<router-link to="/privacy-crudafoch">
						<a style="cursor: pointer" class="ion-color-medium">Datenschutz</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/terms">
						<a style="cursor: pointer">AGB</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/impress">
						<a style="cursor: pointer">Impressum</a>
					</router-link>
				</span>
			</p>
			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 7px">
				Version: {{ appVersion }}
			</p>
			<mobility-logo></mobility-logo>
		</ion-footer>

	</ion-page>
</template>

<script lang="ts">
import {onBeforeMount, ref} from "vue";
import {IonContent, IonPage, IonHeader, IonFooter, onIonViewWillEnter, IonIcon} from '@ionic/vue';
import MobilityLogo from "@/components/logo/MobilityLogo.vue";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import {useRoute} from "vue-router";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import {BasicAuthError} from "@/mobipoints/api/auth";
import useToastMessage from "@/components/core/ToastMessage.vue";
import VerifySuccessAnimation from "@/components/animation/VerifySuccessAnimation.vue";
import VerifyErrorAnimation from "@/components/animation/VerifyErrorAnimation.vue";
import * as icons from 'ionicons/icons';
import LoginSuccessAnimation from "@/components/animation/LoginSuccessAnimation.vue";

export default {
	name: 'VerifyAccount',
	components: {
		LoginSuccessAnimation,
		VerifyErrorAnimation,
		VerifySuccessAnimation,
		MobilityLogo, IonContent, IonPage, IonHeader, IonFooter, IonIcon
	},
	setup() {
		const {getTextValueByKey} = useData();
		const {appVersion} = useSystem();
		const emailVerify = ref(getTextValueByKey('verify.account.title', [], 'E-Mail verifizieren'));
		const emailVerified = ref(getTextValueByKey('verified.account.title', [], 'E-Mail erfolgreich verifiziert'));
		const verifyErrorResendLink = ref(getTextValueByKey('verify.error.resend.link', [], 'Verifizierungslink erneut senden'));
		const currentRouteName = ref<string>();
		const currentRouteParams = ref<any>();
		const {openToast} = useToastMessage();
		const uuid = ref();
		const hash = ref();
		const isEmailVerified = ref(false);
		const showVerifyError = ref(false);
		const verifyError = ref("");
		const isVerifyCheckDone = ref(false);
		const newResendLinkSend = ref(false);
		const registerSuccessTitle = ref(getTextValueByKey('register.success.title', [], 'ERFOLGREICH REGISTRIERT'));
		const registerSuccessBody = ref(getTextValueByKey('register.success.body', [], 'Es wurde ein Link an deine E-Mail Adresse gesendet.<br>Bitte verifiziere deine E-Mail Adresse und schon kanns losgehen!'));
		const registerGoBackTo = ref(getTextValueByKey('register.success.go_back', [], 'Zurück zum'));
		const registerSuccessText = ref(getTextValueByKey('register.success.text', [], 'Account wurde erfolgreich verifiziert.'));
		const registerSucessGoBackToAppInfo = ref(getTextValueByKey('register.go_back_to_app.info', [], 'Du kannst jetzt zurück zur App und dich einloggen!'));
		const registerErrorText = ref(getTextValueByKey('register.error.text', [], 'Account konnte nicht verifiziert werden'));

		function hasValidVerifyParams(ignoreHashCheck = false): boolean {
			let result = true;
			verifyError.value = "";
			if (!uuid.value) {
				verifyError.value = getTextValueByKey('login.basic.email.verify.invalid.uuid', [], 'Ungültige UUID');
				result = false;
			}
			if (!ignoreHashCheck && !hash.value) {
				verifyError.value = getTextValueByKey('login.basic.email.verify.invalid.hash', [], 'Ungültiger Link');
				result = false;
			}

			return result;
		}

		async function handleVerifyError(error: any | BasicAuthError) {
			try {
				if (error instanceof BasicAuthError) {
					if (error.message) {
						await openToast(error.message, 'danger', 'top', true, 10000, undefined, true);
					}
				} else {
					await openToast(getTextValueByKey('login.basic.email.verify.general.error', [], 'E-Mail Verifizierung fehlgeschlagen'), 'danger', 'top', true, 5000, undefined, true);
				}
			} catch (error) {
				await openToast(getTextValueByKey('login.basic.email.verify.general.error', [], 'E-Mail Verifizierung fehlgeschlagen'), 'danger', 'top', true, 5000, undefined, true);
			}
		}

		async function resendLink() {
			if (currentRouteParams.value) {
				try {
					if (hasValidVerifyParams(true)) {
						const userAuth = new UserAuth();
						const result = await userAuth.resendVerifyLink(uuid.value);
						if (result) {
							await openToast(getTextValueByKey('login.basic.email.verify.resend.success', [], 'E-Mail Verifizierungslink erfolgreich gesendet!'), 'success', 'top', true, 5000, undefined, true);
							newResendLinkSend.value = true;
						}
					}
				} catch (error: any | BasicAuthError) {
					console.log(error);
					newResendLinkSend.value = false;
					await handleVerifyError(error);
				}
			} else {
				showVerifyError.value = true;
			}
			isVerifyCheckDone.value = true;
		}

		async function verifyLink() {
			if (currentRouteParams.value) {
				try {
					if (hasValidVerifyParams()) {
						const userAuth = new UserAuth();
						const result = await userAuth.verifyAccount(uuid.value, hash.value);
						if (result) {
							isEmailVerified.value = true;
						}
					}
				} catch (error: any | BasicAuthError) {
					console.log(error);
					await handleVerifyError(error);
				}
			} else {
				showVerifyError.value = true;
			}
			isVerifyCheckDone.value = true;
		}

		onBeforeMount(() => {
			const currentRoute = useRoute();
			currentRouteName.value = String(currentRoute.name);
			currentRouteParams.value = currentRoute.query || {};
			uuid.value = currentRouteParams.value.uuid ?? null;
			hash.value = currentRouteParams.value.hash ?? null;
			verifyLink();
		});

		onIonViewWillEnter(() => {
			// if (isAuthenticated.value) {
			// 	router.push('home');
			// }
		});

		return {
			appVersion,
			emailVerify,
			emailVerified,
			isEmailVerified,
			isVerifyCheckDone,
			verifyErrorResendLink,
			resendLink,
			newResendLinkSend,
			icons,
			registerSuccessTitle,
			registerSuccessBody,
			registerGoBackTo,
      registerSuccessText,
      registerSucessGoBackToAppInfo,
      registerErrorText
		}
	}
}
</script>

<style>
.loginHeader {
    text-align: center;
    color: var(--ion-text-color);
}

.link-text-color {
    cursor: pointer;
    font-weight: bold;
    color: var(--ion-color-dark);
}
</style>