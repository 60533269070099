<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script lang="ts">
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent, onBeforeMount, onDeactivated, watch, ref} from 'vue';
import useMainData from "@/composable/useMainData";
import {MobiPointsQueueQueue} from './mobipoints/queue/queue.type';
import useAuth from "@/composable/useAuth";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import useEvent from "@/composable/useEvent";
import useTracking from "@/composable/useTracking";
import useSystem from "@/composable/useSystem";
import {Network} from '@capacitor/network';
import useToastMessage from "@/components/core/ToastMessage.vue";
import useData from "@/composable/useData";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import * as icons from 'ionicons/icons';
import useUserProfile from "@/composable/useUserProfile";
import router from "@/router";

export default defineComponent({
  name: 'App',
  username: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const {setDarkModeEnabled, isDarkMode} = useUserProfile();
    const {isAuthenticated, checkIsAuthenticated} = useAuth();
    const queue = new MobiPointsQueueQueue();
    const {addCoordinateEvent} = useEvent();
    const {
      currentTracking,
      hasInitialCoordinate,
      getCurrentPosition,
      hasActiveTrack,
      startBackgroundGeolocation,
      hasCurrentPositionChanged,
      hasStartAddress,
      setStartAddressByCurrentPositionToCurrentTracking
    } = useTracking();
    const {hasInternetConnection, setInternetConnection, initVersion, isNativePlatform} = useSystem();
    const {initData} = useData();
    const {openToast} = useToastMessage();
    const isInternetConnectionTimerStarted = ref(false);

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      if (!shouldAdd && isDarkMode.value === true) {
        shouldAdd = true;
      } else if (shouldAdd && (isDarkMode.value === true || isDarkMode.value === null)) {
        shouldAdd = true;
      } else if (!isDarkMode.value) {
        shouldAdd = false;
      }
      if (isAuthenticated.value) {
        setDarkModeEnabled(shouldAdd);
      }
      document.body.classList.toggle('dark', shouldAdd);
    }

    function updateStartAddresss() {
      if (!hasStartAddress.value) {
        setStartAddressByCurrentPositionToCurrentTracking();
      }
    }

    watch(() => getCurrentPosition.value, (newValue: MobiPointsTrackingCoordinateCoordinateInterface | any, oldValue: MobiPointsTrackingCoordinateCoordinateInterface | any) => {
      const currentPos = getCurrentPosition.value;
      let valueChanged = false;
      if (oldValue && currentPos) {
        valueChanged = hasCurrentPositionChanged(oldValue, useSystem().backgroundTrackingActive.value);
      } else if (!oldValue && currentPos) {
        valueChanged = true;
      }

      const featureFlagOnlyWeb = Boolean(useData().getSystemValueByKey('app_check_initial_coord_only_for_web', false));

      if (((featureFlagOnlyWeb && !isNativePlatform()) || !featureFlagOnlyWeb) && !valueChanged && currentTracking.value && !hasInitialCoordinate.value) {
        valueChanged = true;
      }

      if (valueChanged && currentTracking.value) {
        let subType: string | undefined;
        if (currentTracking.value && currentTracking.value.getLatestTrackType()) {
          subType = currentTracking.value.getLatestTrackType();
        }
        if (subType) {
          addCoordinateEvent(currentPos, subType, currentTracking.value.uuid);
          updateStartAddresss();
        }
      }
    });

    function startLastActiveTrack() {
      if (hasActiveTrack.value) {
        startBackgroundGeolocation(true);
      }
    }

    function startInternetConnectionListener() {
      Network.addListener('networkStatusChange', status => {
        const lastConnectionStatus = hasInternetConnection();
        setInternetConnection(status.connected);
        if (!status.connected) {
          isInternetConnectionTimerStarted.value = true;
          setTimeout(() => {
            if (!hasInternetConnection()) {
              openToast('Keine Internet Verbindung 😢! Bitte überprüfe deine Internet Verbindung.', 'danger', 'bottom', true, 10000, icons.globeOutline, true);
            }
            isInternetConnectionTimerStarted.value = false;
          }, 5000);
        }
        if (!lastConnectionStatus && hasInternetConnection()) {
          setTimeout(() => {
            if (!isInternetConnectionTimerStarted.value) {
              openToast('Du bist wieder online ✅', 'success', 'bottom', true, 8000, icons.globeOutline, true);
            }
          }, 5000);
        }
      });
    }

	  function startAppListener() {
		  App.addListener('appStateChange', ({isActive}) => {
			  useSystem().setAppActiveState(isActive);
			  if (isAuthenticated.value && isActive) {
				  queue.startIfNotRunning();
			  } else {
				  //TODO extend auth token and lifetime - JWT Token via refresh token
				  checkIsAuthenticated();
			  }
		  });

		  App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
			  const slug = event.url.split('.app').pop();
			  const urlParams: any = {};
			  if (slug) {
				  const routePath = slug.split('?')[0];
				  const queryParam = slug.split('?').pop() ?? null;
				  if (queryParam) {
					  const paramTmp = new URLSearchParams(queryParam);
					  paramTmp.forEach(function (value, key) {
						  urlParams[key] = value;
					  });
				  }
				  if (urlParams && Object.keys(urlParams).length !== 0) {
					  router.push({
						  path: routePath,
						  query: urlParams
					  });
				  } else {
					  router.push({
						  path: routePath,
					  });
				  }
			  }
		  });
	  }

    onBeforeMount(() => {
      try {
        startInternetConnectionListener();
        initVersion();
        if (hasInternetConnection()) {
          initData(true);
        }
        if (isAuthenticated.value) {
          queue.start();
          useMainData().initMainData(false);
          startLastActiveTrack();
          startAppListener();
        }
      } catch (e) {
        console.log(e);
      }
    });

    onDeactivated(() => {
      queue.destroy();
      App.removeAllListeners();
    });
  }
});
</script>
