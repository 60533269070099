<template>
  <!-- why there is a key at the div with v-if? https://stdworkflow.com/566/vue-switch-element-error-error-in-nexttick-notfounderror-failed-to-execute-insertbefore-on-node-the-node -->
  <div class="ion-padding" v-if="activeChallengeList.length > 0" :key="activeChallengeList.length">
    <h3 class="ion-align-items-center ion-justify-content-start" style="display: flex">
      <ion-icon :icon="icons.star" style="margin-right: .25em;"></ion-icon>
      <span>Meine Challenges</span>
    </h3>
    <swiper v-if="isPlatform('ios') || isPlatform('android')" :height="200" :slides-per-view="1.3" :loop="false" :space-between="0">
      <swiper-slide v-for="challenge in activeChallengeList" :key="challenge.uuid">
        <challenge-teaser :uuid="challenge.uuid" show-progress="full"></challenge-teaser>
      </swiper-slide>
    </swiper>

    <swiper v-else :height="200" :slides-per-view="2.3" :loop="false" :space-between="0">
      <swiper-slide v-for="challenge in activeChallengeList" :key="challenge.uuid">
        <challenge-teaser :uuid="challenge.uuid" show-progress="full"></challenge-teaser>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import {isPlatform} from '@ionic/vue';
import {IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useChallenge from "@/composable/useChallenge";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import ChallengeTeaser from "@/components/challenge/ChallengeTeaser.vue";
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';

const challengeFacade = useChallenge();

const activeChallengeList = ref<Challenge[]>(challengeFacade.activeChallenges.value);

watch(challengeFacade.activeChallenges, async () => {
  activeChallengeList.value = challengeFacade.activeChallenges.value;
});
</script>

<style scoped>
</style>