import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {MobiPointsChallengeChallengeInterface} from "@/mobipoints/challenge/challenge/challenge.interface";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {
	MobiPointsChallengeHighscoreEntryInterface
} from "@/mobipoints/challenge/challenge/highscore/highscore.interface";

interface ChallengeGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsChallengeChallengeInterface[];
}

interface ChallengeHighscoreGetResponse extends MobiPointsApiGetResponse {
	payload: {
		list: MobiPointsChallengeHighscoreEntryInterface[];
		user?: MobiPointsChallengeHighscoreEntryInterface;
	}
}

class ChallengeResponseError extends ResponseError {
}

const MobiPointsApiChallenge = {
	getChallenges: async function (): Promise<AxiosResponse<ChallengeGetResponse>> {
		return MobiPointsApiClient.get('/challenge');
	},
	getHighscore: async function (uuid: string, count: number | null = null, offset = 0): Promise<AxiosResponse<ChallengeHighscoreGetResponse>> {
		return MobiPointsApiClient.get(`/challenge/${uuid}/highscore?offset=${offset}${null !== count ? `&count=${count}` : ''}`)
	},

	acceptChallenge: async function(uuid: string): Promise<AxiosResponse> {
		return MobiPointsApiClient.post(`/challenge/${uuid}/join`, {});
	},

	cancelChallenge: async function(uuid: string): Promise<AxiosResponse> {
		return MobiPointsApiClient.post(`/challenge/${uuid}/cancel`, {});
	},

	declineChallenge: async function(uuid: string): Promise<AxiosResponse> {
		return MobiPointsApiClient.post(`/challenge/${uuid}/decline`, {});
	}
}

export {MobiPointsApiChallenge, ChallengeGetResponse, ChallengeHighscoreGetResponse, ChallengeResponseError};