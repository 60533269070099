import {MobiPointsNewsInterface} from "@/mobipoints/news/news/news.interface";
import {News} from "@/mobipoints/news/news/news.type";
import useNewsFactory from "@/composable/useNewsFactory";


interface NewsStateContainer<T> {
	loaded: boolean,
	data: T,
}

const resetNews = (state: any) => {
	state.news.loaded = false;
	state.news.data = [];
};

const {getNewsFactory} = useNewsFactory();

const news = {
	namespaced: true,
	state: () => ({
		news: {
			loaded: false,
			data: []
		} as NewsStateContainer<Array<News | MobiPointsNewsInterface>>,
	}),
	mutations: {
		news(state: any, value: Array<MobiPointsNewsInterface>) {
			state.news.loaded = true;
			state.news.data = value;
		},
		resetNews(state: any) {
			resetNews(state);
		},
	},
	actions: {},
	getters: {
		news: (state: any) => {
			return state.news.data.map((data) => {
				if (!(data instanceof News)) {
					return getNewsFactory().createNewsByApiResponse(data);
				}
			});
		},
	}
}

export default news;