import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-719ff79a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-padding"
}
const _hoisted_2 = {
  key: 1,
  class: "redeem-voucher-loading-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_redeem_voucher_component = _resolveComponent("redeem-voucher-component")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Gutschein einlösen")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Gutschein einlösen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          ($setup.isComponentReady)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_redeem_voucher_component, {
                  "voucher-code": $setup.voucherCode,
                  "voucher-type": $setup.voucherType
                }, null, 8, ["voucher-code", "voucher-type"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ion_spinner)
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}