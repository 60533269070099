export const enum MobiPointsCoreOsDeviceTypeInterface {
	WEB = 'web',
	IOS = 'ios',
	ANDROID = 'android',
	IOS_WEB = 'ios_web',
	IOS_NATIVE = 'ios_native',
	ANDROID_WEB = 'android_web',
	ANDROID_NATIVE = 'android_native',
	IOS_ANDROID = 'ios_android',
	IOS_ANDROID_WEB = 'ios_android_web',
}