import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {MobiPointsTrackingWeatherWeatherInterface} from "@/mobipoints/tracking/weather/weather.interface";

interface WeatherGetResponse extends MobiPointsApiGetResponse {
    payload: MobiPointsTrackingWeatherWeatherInterface[]|MobiPointsTrackingWeatherWeatherInterface;
}

class WeatherResponseError extends ResponseError {
}

const MobiPointsApiWeather = {
    getWeather: async function (lat: number, lng: number): Promise<AxiosResponse<WeatherGetResponse>> {
        return MobiPointsApiClient.get('/weather?lat=' + lat + '&lng=' + lng);
    },
}

export {MobiPointsApiWeather, WeatherGetResponse, WeatherResponseError};
