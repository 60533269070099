<template>
	<svg class="animated" id="freepik_stories-ok" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" version="1.1"
		 xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
		<g id="freepik--Floor--inject-107" class="animable" style="transform-origin: 250.006px 353.102px;">
			<path id="freepik--floor--inject-107"
				  d="M86.28,447.6c-90.41-52.2-90.41-136.84,0-189s237-52.2,327.44,0,90.41,136.84,0,189S176.7,499.81,86.28,447.6Z"
				  style="fill: rgb(245, 245, 245); transform-origin: 250.006px 353.102px;" class="animable"></path>
		</g>
		<g id="freepik--Shadow--inject-107" class="animable" style="transform-origin: 249.812px 351.604px;">
			<ellipse id="freepik--shadow--inject-107" cx="158.64" cy="409.11" rx="71.04" ry="41.01"
					 style="fill: rgb(224, 224, 224); transform-origin: 158.64px 409.11px;" class="animable"></ellipse>
			<g id="freepik--shadow--inject-107">
				<ellipse cx="298.89" cy="345.58" rx="101.34" ry="50.43"
						 style="fill: rgb(224, 224, 224); transform-origin: 298.89px 345.58px; transform: rotate(-28.34deg);"
						 class="animable"></ellipse>
			</g>
		</g>
		<g id="freepik--Plants--inject-107" class="animable" style="transform-origin: 261.259px 334.164px;">
			<g id="freepik--plants--inject-107" class="animable" style="transform-origin: 397.998px 312.935px;">
				<path d="M371.85,331.71c9.85-11.74,25.28-19.39,42.63-23.91,17.14-4.45,20.21-15,8.85-18.41-12.87-3.88-28.93,3.24-39.29,13.15C366,319.81,363.73,333,363.73,333Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 396.895px 310.657px;" id="elqnewypt2vd"
					  class="animable"></path>
				<g id="el3vons2ftmk2">
					<path d="M371.85,331.71c9.85-11.74,25.28-19.39,42.63-23.91,17.14-4.45,20.21-15,8.85-18.41-12.87-3.88-28.93,3.24-39.29,13.15C366,319.81,363.73,333,363.73,333Z"
						  style="opacity: 0.15; transform-origin: 396.895px 310.657px;" class="animable"></path>
				</g>
				<path d="M366.78,331a.54.54,0,0,1-.22,0,.5.5,0,0,1-.23-.67c14.62-30,47-37.38,54.84-36.82a.5.5,0,0,1,.46.53.51.51,0,0,1-.54.46c-7.68-.58-39.47,6.73-53.86,36.27A.52.52,0,0,1,366.78,331Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 393.955px 312.246px;" id="elni0b19z75y8"
					  class="animable"></path>
				<path d="M363.73,334.83a2.73,2.73,0,0,0,4.13,2.34c9.16-5.33,22.52-8.28,44.14-2.12,11.5,3.27,17.16,1.08,19.58-3.6,2.7-5.2-3-10.23-9.5-12.6-22.13-8.09-42.1-2.57-58.35,13.57Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 397.998px 326.359px;" id="ely67wiblzpq"
					  class="animable"></path>
				<path d="M365,335.26a.51.51,0,0,1-.38-.16.51.51,0,0,1,0-.71c19.11-17.08,48.71-13.75,59.91-7.09a.5.5,0,0,1,.18.68.51.51,0,0,1-.69.18c-11-6.53-40-9.78-58.73,7A.54.54,0,0,1,365,335.26Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 394.626px 328.573px;" id="el7lm9fkdadha"
					  class="animable"></path>
			</g>
			<g id="freepik--plants--inject-107" class="animable" style="transform-origin: 124.086px 353.096px;">
				<path d="M149.92,374.65c-8.4-12.82-22.82-22.24-39.52-28.77-16.48-6.44-18.29-17.27-6.61-19.33,13.24-2.33,28.35,6.64,37.47,17.7,15.88,19.28,16.57,32.67,16.57,32.67Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 127.064px 351.549px;" id="elzh2ze5eja"
					  class="animable"></path>
				<g id="elvfr14nod5wi">
					<path d="M149.92,374.65c-8.4-12.82-22.82-22.24-39.52-28.77-16.48-6.44-18.29-17.27-6.61-19.33,13.24-2.33,28.35,6.64,37.47,17.7,15.88,19.28,16.57,32.67,16.57,32.67Z"
						  style="opacity: 0.15; transform-origin: 127.064px 351.549px;" class="animable"></path>
				</g>
				<path d="M155,374.57a.57.57,0,0,0,.22,0,.51.51,0,0,0,.31-.64c-11-31.51-42.24-42.66-50.12-43a.5.5,0,0,0-.52.47.52.52,0,0,0,.48.53c7.7.32,38.4,11.33,49.21,42.36A.5.5,0,0,0,155,374.57Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 130.223px 352.755px;" id="el871evxueea3"
					  class="animable"></path>
				<path d="M157.9,376.31a4.16,4.16,0,0,1-6.45,3c-8.48-5.68-21.11-9.37-41.8-6-11.8,1.89-17.16-1-19-5.89-2.06-5.48,4.2-9.79,10.93-11.38,22.93-5.42,42.11,2.42,56.34,20.35Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 124.086px 367.182px;" id="ellt4exnz9srl"
					  class="animable"></path>
				<path d="M156.32,379a.48.48,0,0,0,.39-.12.51.51,0,0,0,0-.71c-17-19.21-46.75-19.4-58.66-14.11a.5.5,0,0,0,.41.92c11.67-5.19,40.87-5,57.5,13.85A.46.46,0,0,0,156.32,379Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 127.304px 370.051px;" id="el9mknkq411xh"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--check-mark--inject-107" class="animable" style="transform-origin: 316.127px 243.898px;">
			<g id="freepik--check-mark--inject-107" class="animable" style="transform-origin: 316.127px 243.898px;">
				<g id="freepik--Circle--inject-107" class="animable" style="transform-origin: 299.815px 255.811px;">
					<path d="M380.82,146.17h0l-.16-.08h0l-38.12-21.94,0,0-.23-.11h0c-17.81-9-41.57-6.29-67.13,10.23-51.34,33.2-90.46,109.62-87.38,170.69,1.53,30.43,13.27,51.3,31,60.48h0l.15.08h0l38.13,21.95h0l.18.1h0c17.81,9,41.59,6.33,67.17-10.21,51.34-33.2,90.46-109.62,87.38-170.69C410.3,176.22,398.56,155.35,380.82,146.17Z"
						  style="fill: rgb(55, 71, 79); transform-origin: 299.805px 255.811px;" id="elns9s4n8uq8f"
						  class="animable"></path>
					<path d="M255.77,386.76,219,365.5h0l-.18-.11c-17.73-9.2-29.46-30.07-31-60.46-1.37-27.24,5.66-57.51,18.4-85.62l.25-.62,37.88,22.56c-12.76,28.11-19.77,58.38-18.4,85.62C227.4,356.66,238.67,377.28,255.77,386.76Z"
						  style="fill: rgb(38, 50, 56); transform-origin: 221.71px 302.725px;" id="elz6xe4e5ozre"
						  class="animable"></path>
					<path d="M324.45,377.34c51.34-33.2,90.46-109.62,87.38-170.69S364.63,123,313.29,156.2,222.82,265.82,225.9,326.88,273.11,410.54,324.45,377.34Z"
						  style="fill: rgb(69, 90, 100); transform-origin: 318.865px 266.768px;" id="elal842em13y8"
						  class="animable"></path>
				</g>
				<path id="freepik--shadow--inject-107"
					  d="M367.93,141.43,303.58,274l-13.85-15a22.77,22.77,0,0,0-33.45,30.89l36.49,39.53a22.65,22.65,0,0,0,15.49,7.28c.42,0,.84,0,1.26,0a23.11,23.11,0,0,0,3.3-.24A22.73,22.73,0,0,0,330,323.88l73.8-152C396.23,155,383.63,144.54,367.93,141.43Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 327.169px 239.065px;" class="animable"></path>
				<path d="M420.19,97.77c-.4-.23-.82-.45-1.24-.65a19.79,19.79,0,0,0-26.42,9.14L310.65,274.92l-4.66-5a19.23,19.23,0,0,0-4.64-3.71c-2.44-1.4-12.82-7.41-14.5-8.38a19.77,19.77,0,0,0-24.42,30.53l36.49,39.52a19.65,19.65,0,0,0,4.71,3.75c2.38,1.35,12.72,7.35,14.42,8.33a19.77,19.77,0,0,0,27.69-8.47l96.87-199.53a19.8,19.8,0,0,0-7.89-25.76Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 350.885px 218.88px;" id="elm50gg86rsji"
					  class="animable"></path>
				<g id="eld7pz321judf">
					<path d="M420.19,97.77c-.4-.23-.82-.45-1.24-.65a19.79,19.79,0,0,0-26.42,9.14L310.65,274.92l-4.66-5a19.23,19.23,0,0,0-4.64-3.71c-2.44-1.4-12.82-7.41-14.5-8.38a19.77,19.77,0,0,0-24.42,30.53l36.49,39.52a19.65,19.65,0,0,0,4.71,3.75c2.38,1.35,12.72,7.35,14.42,8.33a19.77,19.77,0,0,0,27.69-8.47l96.87-199.53a19.8,19.8,0,0,0-7.89-25.76Z"
						  style="opacity: 0.15; transform-origin: 350.885px 218.88px;" class="animable"></path>
				</g>
				<g id="elnbbsy5dwebm">
					<path d="M318,339.92c-1.68-1-12-7-14.41-8.33a19.7,19.7,0,0,1-4.71-3.75l-36.49-39.52a19.7,19.7,0,0,1-3.77-6l14.51,8.37a19.21,19.21,0,0,0,3.79,6l36.48,39.52A20.11,20.11,0,0,0,318,339.92Z"
						  style="opacity: 0.15; transform-origin: 288.31px 311.12px;" class="animable"></path>
				</g>
				<g id="elr58pjyjdft">
					<path d="M411.63,108.56a19.76,19.76,0,0,0-4.58,6.08L322.83,288.12l-12.18-13.2,81.88-168.66a19.52,19.52,0,0,1,4.59-6.08Z"
						  style="opacity: 0.15; transform-origin: 361.14px 194.15px;" class="animable"></path>
				</g>
				<path id="freepik--Check--inject-107"
					  d="M433.47,105.5a19.81,19.81,0,0,0-26.42,9.14L322.83,288.12,306,269.88a19.76,19.76,0,0,0-29,26.82l36.48,39.53a19.77,19.77,0,0,0,32.3-4.78l96.87-199.53A19.79,19.79,0,0,0,433.47,105.5Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 358.391px 223.053px;" class="animable"></path>
			</g>
		</g>
		<g id="freepik--Character--inject-107" class="animable" style="transform-origin: 159.518px 247.613px;">
			<g id="freepik--character--inject-107" class="animable" style="transform-origin: 159.518px 247.613px;">
				<path d="M157.23,125c-7.63,0-12.85,2-18.27,9.7-5,7.08-18.64,30.68-18.64,30.68s-10-20.52-11-24.61-.94-5.91,0-7.69a16.06,16.06,0,0,0,2.52-7c.39-4.92,1.59-9.13.49-9.27-3.05-.17-3.17,3.75-4.77,6.06a1.92,1.92,0,0,1-3.08-.33c-.67-1.37-.31-3.62-.63-5.24a21.48,21.48,0,0,1,.43-2.68,11.76,11.76,0,0,0,3.5.1c1.32,2.07,2.22,2.94,4.06,2.1.24-.38-.79-3-2-5a1.36,1.36,0,0,0-.82-.62,15.54,15.54,0,0,0-6.78-.19,1.27,1.27,0,0,0-.87.48,16.58,16.58,0,0,0-2,4c-.13.43-.53.42-.63.05-1-4.83-1-11.69-1-14.9,0-2.53-3.69-2.93-3.82.69a117.09,117.09,0,0,0,.25,14.57c0,.44-.44.65-.62.17-.64-1.71-2.21-6.88-2.87-15.8-.18-2.4-4.06-2.79-4,.58A85.57,85.57,0,0,0,89,118.58c.12.42-.27.79-.56.31a57.57,57.57,0,0,1-4.34-11c-.83-2.9-4.7-2.16-4,1.45,1.21,6.47,3.13,14.65,6,20.5,3.06,6.25,5.7,9.38,7.22,14.29s7.81,32.72,14.54,43.56c5.27,8.5,11.3,7.71,17.79,3.23s29.68-36.79,29.68-36.79Z"
					  style="fill: rgb(158, 103, 103); transform-origin: 118.623px 146.306px;" id="elo87r762jzr8"
					  class="animable"></path>
				<path d="M120.32,165.37s3.25,5.51,3.75,9.92a17.3,17.3,0,0,0-2.3-12.42Z"
					  style="fill: rgb(135, 76, 76); transform-origin: 122.352px 169.08px;" id="elfeincsjnb6i"
					  class="animable"></path>
				<path d="M158.85,125c-3.88-.4-8.71-.53-14.09,2.86s-7.26,8.11-9.87,12.24-11.63,18.76-11.63,18.76,3.31,13.89,16.47,16.75l19.71-26.74Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 141.35px 150.204px;" id="elsonwokfn0sg"
					  class="animable"></path>
				<path d="M176.52,369.23c1-.13,2.33,3.28,2.35,4.39s-1.1,3.5-2.57,5.74a40.34,40.34,0,0,0-4.85,8.63c-1.13,3.08-2.17,5.1-5.32,9.13-3.5,4.49-11.72,12-24.2,8.74-3.05-.8-4.57-1.76-5.68-2.87a3.71,3.71,0,0,1-.87-3.82Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 157.024px 387.941px;" id="el8qse964kno3"
					  class="animable"></path>
				<path d="M152,365.33l0,.11a7.83,7.83,0,0,0,.08,2.81,19.65,19.65,0,0,1-4.73,15.39,29.61,29.61,0,0,1-5.84,4.72c-6.32,4-7,6.73-6.11,10.81,2.12,4.75,16.45,7.64,24.69.57a24.27,24.27,0,0,0,7.85-10.67,75.48,75.48,0,0,1,4.16-8.8c1.59-3,4.61-6.38,4.7-9.47.05-1.7-1.63-4.86-3.26-7.46-1.5-2.39-3.05-4.66-4.4-4.4l.38,1.35c-1.13,1.17-2.76,1.61-3.74,3-1.22,1.69-1.18,4-1.93,5.9a.68.68,0,0,1-.37.45.5.5,0,0,1-.53-.27,1.45,1.45,0,0,1-.11-.62l-.09-2.91a2.45,2.45,0,0,0-.42-1.58c-.78-.88-2.7-.78-3.77-.82a41.2,41.2,0,0,0-4.31.26,2.07,2.07,0,0,0-1.4.37A2.47,2.47,0,0,0,152,365.33Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 155.943px 381.45px;" id="elcneclq7s9tm"
					  class="animable"></path>
				<path d="M130.82,307.19c3.33,10.95,18.9,48.86,19.83,51.14l3.1,7.52a11.65,11.65,0,0,0,6.32.27c.33,3.63.88,9.32,1.09,12.19.08,3.33.37,7.45-.35,10.74-.61,2.8-1.32,5.74-1.85,8.55-.23,1.24-.46,2.49-.65,3.74a6.26,6.26,0,0,0,.31,3.93,4.87,4.87,0,0,0,1.24,1.4,11,11,0,0,0,7.84,2.3,8.15,8.15,0,0,0,4.16-1.38c2.42-1.73,3.27-4.74,4.5-7.3,1.34-2.77.91-3.08,1-6.05.08-2.08.66-7.36,1.49-12.88s4.41-24.92,6.13-44.25a158.51,158.51,0,0,0,0-25.8c13.66-37.11,19.74-59.18,21.53-71.5,1.81-12.52.94-17,.71-24.19l-66.43,1.6C135.47,251.56,126.35,292.51,130.82,307.19Zm32.45-58.88Z"
					  style="fill: rgb(158, 103, 103); transform-origin: 168.628px 312.32px;" id="elcfcrrdyubf"
					  class="animable"></path>
				<path d="M129.78,279.79c1.37-12.62,10.81-61.13,10.81-61.13h66.92s.43,9.31.31,13.59-1.5,12.09-3.89,22-11.25,37.88-13,42-5,16.07-5,16.07c1.64,22.44-2.63,42.58-5.83,69.35,0,0-.93,3.61-10.41,2.9-9.22-.7-11.08-4.1-11.08-4.1L158,357.38s-5,1.54-8,.84c0,0-19.81-48.91-20.77-51.85S128.41,292.41,129.78,279.79Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 168.227px 301.661px;" id="elbepv7zj6qyg"
					  class="animable"></path>
				<g id="el5ua9t0bqds6">
					<path d="M129.78,279.79c1.37-12.62,10.81-61.13,10.81-61.13h66.92s.43,9.31.31,13.59-1.5,12.09-3.89,22-11.25,37.88-13,42-5,16.07-5,16.07c1.64,22.44-2.63,42.58-5.83,69.35,0,0-.93,3.61-10.41,2.9-9.22-.7-11.08-4.1-11.08-4.1L158,357.38s-5,1.54-8,.84c0,0-19.81-48.91-20.77-51.85S128.41,292.41,129.78,279.79Z"
						  style="opacity: 0.45; transform-origin: 168.227px 301.661px;" class="animable"></path>
				</g>
				<g id="el9bkajrh5p5">
					<path d="M158.17,250.28c-9.48-8.54-11.59-15.34-11.59-15.34a63.4,63.4,0,0,0,16.69,13.37s-9,47.52-8.34,57.29S158,357.38,158,357.38c0,.4-6.38-44.43-6.57-48C151,299.93,158.17,250.28,158.17,250.28Z"
						  style="opacity: 0.2; transform-origin: 154.925px 296.161px;" class="animable"></path>
				</g>
				<path d="M179,405.46c.93.27.81,3.05.39,4.09A12.67,12.67,0,0,1,175.1,414a21,21,0,0,0-6.35,6.83,32.15,32.15,0,0,1-9.95,9.72A24,24,0,0,1,141.3,433c-6.28-1.78-6.86-3.32-6.81-5.69Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 157.097px 419.568px;" id="elg4dikk5ar9u"
					  class="animable"></path>
				<path d="M159.6,390.92c-1.65,8-4.52,13.88-11.17,18.82-5.3,3.94-9.14,5.94-13.37,9.45-1.79,1.5-1.94,4.69-.57,8.13,2.86,2.29,6.92,3.61,12.37,3.6,6.1,0,8.44-1.09,11.94-3.48a32.11,32.11,0,0,0,4.78-4c2.93-2.94,5-6.57,7.08-8.9s6.69-4.71,8-7.52c.72-1.54.42-5.11-.06-8.15-.44-2.79.41-7.69-.93-8,0,0-.19,2-.19,2-.11.45-.39.68-1.33,1a6.94,6.94,0,0,1-2.57.07,1.54,1.54,0,0,0-1.73.49c-.9,1.06-1.14,2.56-2.3,3.44a.66.66,0,0,1-.26.14A.28.28,0,0,1,169,398a.41.41,0,0,1-.05-.33l.33-3a3.4,3.4,0,0,0,0-.9,2,2,0,0,0-1-1.32,10.75,10.75,0,0,0-2.69-1.11,15.74,15.74,0,0,0-3-.51A9.53,9.53,0,0,0,159.6,390.92Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 156.332px 410.836px;" id="elwladzwjwje"
					  class="animable"></path>
				<path d="M201.47,127.87s2.75,3.78,5,11.57a189.65,189.65,0,0,1,4.2,38.92c0,17.67-2.38,46.3-2.38,46.3-20.51,10.28-53.59,4.2-68.15-5.83.59-3.18,1.09-19.9,2.24-30.93.77-18.92,1.41-28.83,1.54-37.25.27-16.82,8.64-25.51,15-25.64l16.06.25Z"
					  style="fill: rgb(104, 200, 122); transform-origin: 175.405px 177.496px;" id="el7xeyc235czp"
					  class="animable"></path>
				<path d="M206.53,219.47c-3.05,2.51-9,7.33-10.13,9.76-1.26,2.78-2,7-2.85,8.58s.43,2.38,2.19,1.81,2.81-2.06,4.17-5.7,5.14-6.49,5.14-6.49Z"
					  style="fill: rgb(135, 76, 76); transform-origin: 199.905px 229.637px;" id="elg0kfpz26bav"
					  class="animable"></path>
				<path d="M195.39,252.2c.73,1.41,2.43,1.42,4,1.54a12.7,12.7,0,0,0,5.67-.64,10.35,10.35,0,0,0,5.45-3.79c1.54-2.22,1.94-5,2.82-7.55,1.84-5.35,7.32-9.21,8.22-14.88.37-2.34.37-5.1,1-6.55.39-.86,9.4-16.37,12.62-23.09,3.08-6.46,5.39-9.5,2.59-17.26s-12.33-35.07-19.08-44.56c-3.82-5.38-9.89-6.92-17.25-7.55-5.6,14.58.91,29.65.91,29.65L217.2,186.1s-5.76,19.68-9.36,30.62c-1,3-3,4.92-5.29,8.29-1,1.46-3.26,4-4.06,5.59-1,2-1,3.32-1.5,6.15C196.21,241.26,195,251.53,195.39,252.2Z"
					  style="fill: rgb(158, 103, 103); transform-origin: 217.168px 190.846px;" id="el90ipkf3alu"
					  class="animable"></path>
				<path d="M200.68,127.79c3.87.15,9.43.81,12.75,2.49s5.63,3.75,9.45,11.41,9.41,22.54,9.41,22.54-1.85,11.23-19.61,14.14l-12-22.13S195.33,142.45,200.68,127.79Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 215.296px 153.08px;" id="elbaqqf8uq474"
					  class="animable"></path>
				<path d="M154.54,92.05A22.22,22.22,0,0,1,155.9,95a17.33,17.33,0,0,0,2.25,3.71c2.3,2.65,5.53,4.59,8.48,6.47,1.78,1.14,3.82,2.21,6,1.9s4.61.16,6.86.36l7.87.71-.44,8.27s1-2.19,2.31-4.81c1.14-2.25,5.92-13.66,5.94-13.71,2.37-5.65,5.18-13.19,2.35-19.13a7.85,7.85,0,0,0-3.08-3.53,7.14,7.14,0,0,0,.26-2.82,9.06,9.06,0,0,0-4.18-6.62,19.89,19.89,0,0,0-7.42-3.06,46.78,46.78,0,0,0-10.74-1.19,63.38,63.38,0,0,0-10.54,1.21,37.34,37.34,0,0,1-5.87.61,11.31,11.31,0,0,1-2.54-.12,28.89,28.89,0,0,1-3.07-1.11.71.71,0,0,0-.55,0,.66.66,0,0,0-.29.47,8.91,8.91,0,0,0,1.63,7.21,4.57,4.57,0,0,1-1.3-.38,10.51,10.51,0,0,1-1.35-.75,7.63,7.63,0,0,1-1.9-1.82.55.55,0,0,0-.31-.23c-.2,0-.36.14-.48.3a7,7,0,0,0-1,5.53,12,12,0,0,0,2.6,5.11,7,7,0,0,0-.36,6.71,9.35,9.35,0,0,0,1.77,2.54c.9.92,2,1.67,2.91,2.53A17,17,0,0,1,154.54,92.05Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 171.654px 88.9847px;" id="elyayxrp2bs"
					  class="animable"></path>
				<path d="M178.51,78.27a15.14,15.14,0,0,0,.57,4.42A8.63,8.63,0,0,0,181.31,86a25.45,25.45,0,0,1,3.1,3.13,13.2,13.2,0,0,1,1.74,5.76,4,4,0,0,0,1.52,3.1c2.14,1.33,3.42-1.24,4.56-2.5,1.47-1.36,5.84-3,7.86,1.62,2.09,4.8-1.77,11.16-5.28,12.14-6.05,1.69-7.37-2.43-7.37-2.43l-1.16,21.6c-3.41,6.47-11.43,8.53-16.7,7.5-4.55-.89-7.05-4.08-2.77-8.7l.23-6.58s-5.46.65-8.09.15c-4.39-.82-6.56-4.55-7.75-9.36-1.89-7.73-2.76-15.86-.41-33.27,1.07-.59,5.4,2.78,12,3.23A30.45,30.45,0,0,0,178.51,78.27Z"
					  style="fill: rgb(158, 103, 103); transform-origin: 175.025px 107.131px;" id="elo1n0c48xtlo"
					  class="animable"></path>
				<path d="M167,120.62s9.9-1.66,13.4-3.38a11.33,11.33,0,0,0,4.9-4.55,15.51,15.51,0,0,1-2.88,5.46c-2.62,3.13-15.51,5.09-15.51,5.09Z"
					  style="fill: rgb(135, 76, 76); transform-origin: 176.105px 117.965px;" id="elrg9ew1x5dpi"
					  class="animable"></path>
				<path d="M172.15,86l4.61,1.41a2.53,2.53,0,0,0-1.66-3.09A2.36,2.36,0,0,0,172.15,86Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 174.502px 85.8174px;" id="ela3tx23fenkc"
					  class="animable"></path>
				<path d="M170.55,107.84l-6.79,2.3a3.53,3.53,0,0,0,4.48,2.37A3.76,3.76,0,0,0,170.55,107.84Z"
					  style="fill: rgb(135, 76, 76); transform-origin: 167.242px 110.261px;" id="eli7x5s6ixrc"
					  class="animable"></path>
				<path d="M170,109.5a4,4,0,0,0-3.87,3,3.38,3.38,0,0,0,2.11,0,3.69,3.69,0,0,0,2.42-2.94A3.7,3.7,0,0,0,170,109.5Z"
					  style="fill: rgb(242, 143, 143); transform-origin: 168.395px 111.084px;" id="elvpq54hjrega"
					  class="animable"></path>
				<path d="M151.57,86.73l4.69-.61a2.29,2.29,0,0,0-2.6-2A2.41,2.41,0,0,0,151.57,86.73Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 153.91px 85.4134px;" id="elp9k138f46ga"
					  class="animable"></path>
				<path d="M180.4,88.73,195.87,94a5.66,5.66,0,0,0-4.46,2.26l-10.95-4.16Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 188.135px 92.495px;" id="elnzvv5qvgucp"
					  class="animable"></path>
				<path d="M153.88,94.77a1.87,1.87,0,0,0,1.78,2,1.89,1.89,0,0,0,1.91-1.87,1.87,1.87,0,0,0-1.78-2A1.89,1.89,0,0,0,153.88,94.77Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 155.725px 94.835px;" id="eltjrdvytlyu8"
					  class="animable"></path>
				<path d="M171.55,94.77a1.85,1.85,0,1,0,1.91-1.86A1.89,1.89,0,0,0,171.55,94.77Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 173.4px 94.7595px;" id="el8aaagqduktl"
					  class="animable"></path>
				<path d="M144.75,89.85a4.18,4.18,0,0,0,1,2.18,2,2,0,0,1,.39.78c.36,1.49.67,4.31,1.38,5.89a6.48,6.48,0,0,0,6.33,4.18,9,9,0,0,0,2.16-.18l.16,0a6.66,6.66,0,0,0,1.76-.69l.1-.07.15-.09.19-.13.17-.14.19-.15a.93.93,0,0,0,.18-.16,3.73,3.73,0,0,0,.34-.35,8,8,0,0,0,1.41-2.23c.92-2.06.68-4.94,2.18-5.28l.18,0h.2c2,0,1.82,3.17,3,5.41s3,4.43,7.47,4.49h.57a3.44,3.44,0,0,0,.46,0,6.9,6.9,0,0,0,1.07-.15l.33-.08a3,3,0,0,0,.32-.09l.21-.06a6.35,6.35,0,0,0,1.2-.57,3.51,3.51,0,0,0,.4-.27,5,5,0,0,0,.69-.61,7,7,0,0,0,1.35-2.08c.69-1.55.87-4.37,1.21-5.84a2.48,2.48,0,0,1,.34-.85,4,4,0,0,0,1-2.1,1.84,1.84,0,0,0-1.24-1.85l-1.09-.14c-3.5-.43-8.06-.61-10.79.13-3.42.93-4.61,2-6.52,2s-2.94-1.18-6.19-2.24c-2.58-.84-6.85-.81-10.11-.51l-1,.1A1.74,1.74,0,0,0,144.75,89.85ZM167.27,92a2.87,2.87,0,0,1,1-.79,1,1,0,0,1,.17-.1l.1,0c.15-.08.31-.16.48-.23l.45-.18a13.2,13.2,0,0,1,1.46-.46l.58-.14c.5-.1,1-.18,1.5-.23a2.28,2.28,0,0,1,.36,0c3.29-.27,5.35.51,6.1,1.18s.42,3.75.11,5.3l0,.11s0,.08,0,.12c0,.19-.08.4-.14.63s-.07.25-.11.38v0c-.07.21-.14.42-.22.64h0a2.14,2.14,0,0,1-.1.22,1.12,1.12,0,0,1-.05.13l-.07.15-.09.18-.11.21c0,.07-.09.14-.14.21a1.64,1.64,0,0,1-.14.22,5.19,5.19,0,0,1-4.61,2.18,6.38,6.38,0,0,1-4.86-1.86h0a6.9,6.9,0,0,1-1.59-3.12C166.86,95,166.63,92.94,167.27,92Zm-19.38-1.61a3,3,0,0,1,.8-.51l.06,0,.36-.14.22-.07.18,0,.12,0h.05l.18,0c.23,0,.48-.09.76-.13l.41,0h1.33c.48,0,1,.07,1.52.14,2.35.34,4.81,1.43,5.45,2.4s.5,3,.19,4.69a7.12,7.12,0,0,1-.3,1.16.46.46,0,0,0,0,.09s0,.07,0,.11l0,.08-.06.14-.12.28c0,.08-.08.17-.13.25a.59.59,0,0,1,0,.08l-.1.17-.09.14a0,0,0,0,0,0,0,5.19,5.19,0,0,1-4.9,2.24,5.86,5.86,0,0,1-2.46-.6c-2.34-1.18-2.94-3.81-3.21-5C147.65,94.12,147.19,91.05,147.89,90.38Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 163.795px 95.5627px;" id="elliu9p1mueg"
					  class="animable"></path>
				<path d="M163.76,93.46l-.35,12.86-7.17-2.08,6.69-10.89A1.86,1.86,0,0,1,163.76,93.46Z"
					  style="fill: rgb(135, 76, 76); transform-origin: 160px 99.8308px;" id="eloipypjdty88"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--speech-bubble--inject-107" class="animable" style="transform-origin: 262.35px 63.9719px;">
			<g id="freepik--speech-bubble--inject-107" class="animable" style="transform-origin: 262.35px 63.9719px;">
				<g id="freepik--speech-bubble--inject-107" class="animable"
				   style="transform-origin: 262.35px 63.9719px;">
					<ellipse cx="264.02" cy="62.64" rx="37.05" ry="32.45"
							 style="fill: rgb(104, 200, 122); transform-origin: 264.02px 62.64px;" id="el1ep5r7ngig1"
							 class="animable"></ellipse>
					<path d="M234.45,81.41c-.21,4.35-3.89,12.13-10.54,15a.46.46,0,0,0,.07.87,20,20,0,0,0,20.5-7.76Z"
						  style="fill: rgb(104, 200, 122); transform-origin: 234.055px 89.5819px;" id="elkc9c076t49p"
						  class="animable"></path>
				</g>
				<path d="M238.9,60.07a11.88,11.88,0,0,1,1-4.12,9.68,9.68,0,0,1,2.38-3.35A11.35,11.35,0,0,1,246,50.33a15.81,15.81,0,0,1,10.17,0,11.45,11.45,0,0,1,3.76,2.27A9.81,9.81,0,0,1,262.29,56a11.88,11.88,0,0,1,1,4.12c.05,1.12.07,2.29.07,3.51s0,2.37-.07,3.43a11.88,11.88,0,0,1-1,4.12,10,10,0,0,1-2.38,3.37,11,11,0,0,1-3.76,2.27,16.18,16.18,0,0,1-10.17,0,10.87,10.87,0,0,1-3.74-2.27,9.85,9.85,0,0,1-2.38-3.37,11.88,11.88,0,0,1-1-4.12c-.05-1.06-.08-2.21-.08-3.43S238.85,61.19,238.9,60.07ZM256,66.82c0-.47.05-1,.08-1.56s0-1.16,0-1.76,0-1.18,0-1.74-.06-1-.08-1.5a5.94,5.94,0,0,0-.41-1.79,4.34,4.34,0,0,0-.94-1.43,4.24,4.24,0,0,0-1.5-.95,6.51,6.51,0,0,0-4.21,0,4.37,4.37,0,0,0-1.51.95,4.31,4.31,0,0,0-.93,1.43,5.94,5.94,0,0,0-.41,1.79,13.3,13.3,0,0,0-.1,1.5c0,.56,0,1.14,0,1.74s0,1.18,0,1.76a14.45,14.45,0,0,0,.1,1.56,5.23,5.23,0,0,0,1.34,3.24,4.73,4.73,0,0,0,3.61,1.28,4.65,4.65,0,0,0,3.59-1.28A5.5,5.5,0,0,0,256,66.82Z"
					  style="fill: rgb(250, 250, 250); transform-origin: 251.07px 63.5649px;" id="el4netnnpysv"
					  class="animable"></path>
				<path d="M291,75.91a.69.69,0,0,1,.11.43.82.82,0,0,1-.25.6.83.83,0,0,1-.6.26H284a1.59,1.59,0,0,1-.9-.24,3.83,3.83,0,0,1-.7-.66L275,67v9.13a1.08,1.08,0,0,1-1,1.06h-5.12a1,1,0,0,1-.74-.32,1,1,0,0,1-.31-.74V50.94a1,1,0,0,1,.31-.74,1,1,0,0,1,.74-.32H274a1.08,1.08,0,0,1,1,1.06v8.23l6.87-8.43a7.24,7.24,0,0,1,.5-.54,1.46,1.46,0,0,1,1.06-.32h6.08a.83.83,0,0,1,.61.26.82.82,0,0,1,.25.6.67.67,0,0,1-.12.43L280.65,63Z"
					  style="fill: rgb(250, 250, 250); transform-origin: 279.471px 63.5372px;" id="elblcg82lm4ci"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--Icons--inject-107" class="animable" style="transform-origin: 261.152px 152.675px;">
			<g id="freepik--like-icon--inject-107" class="animable" style="transform-origin: 363.709px 81.4172px;">
				<polygon points="359.62 93.32 354.01 95.01 349.96 81.48 355.55 79.8 359.62 93.32"
						 style="fill: rgb(224, 224, 224); transform-origin: 354.79px 87.405px;" id="elqf0mop78pak"
						 class="animable"></polygon>
				<path d="M377.13,81.31a2.6,2.6,0,0,1-1.59,2.62,3.79,3.79,0,0,1,.79,1.43c.32,1-.53,2.45-2.22,3l-12.83,3.86-3.77-12.57h0s2.17-5.55,2.28-6.39-1.17-3.92-1.35-4.5,1.9-1.34,3.54-.68a2.94,2.94,0,0,1,1,.73,8.87,8.87,0,0,1,1.47,2.11,7.53,7.53,0,0,1,.78,3.54L373,72.09a2.44,2.44,0,0,1,1.66,0,2.19,2.19,0,0,1,.62,3.7,2.07,2.07,0,0,1,2.12,1.43,2.28,2.28,0,0,1-1.28,2.59A2.41,2.41,0,0,1,377.13,81.31Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 367.484px 80.0222px;" id="el1rplpgzzeci"
					  class="animable"></path>
			</g>
			<g id="freepik--like-icon--inject-107" class="animable" style="transform-origin: 451.668px 221.58px;">
				<polygon points="440 233.61 432.81 231.88 437 214.54 444.19 216.27 440 233.61"
						 style="fill: rgb(224, 224, 224); transform-origin: 438.5px 224.075px;" id="elkhze5sp1bnq"
						 class="animable"></polygon>
				<path d="M466.77,231.66a3.29,3.29,0,0,1-3.4,1.85,4.9,4.9,0,0,1-.05,2.06c-.32,1.35-2.15,2.34-4.31,1.81l-16.46-4,3.88-16.11h0s5.91-4.67,6.56-5.52,1.21-5,1.39-5.77,2.93-.25,4.3,1.52a3.65,3.65,0,0,1,.68,1.46,11.19,11.19,0,0,1,.26,3.23,9.65,9.65,0,0,1-1.4,4.37l9.94,2.4a3.07,3.07,0,0,1,1.78,1.11,2.87,2.87,0,0,1,.53,2.3,2.91,2.91,0,0,1-2.21,2.13,2.61,2.61,0,0,1,1.4,2.91,2.9,2.9,0,0,1-3.05,2A3,3,0,0,1,466.77,231.66Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 456.538px 221.58px;" id="el21w54k8uuzk"
					  class="animable"></path>
			</g>
			<g id="freepik--like-icon--inject-107" class="animable" style="transform-origin: 71.0639px 206.511px;">
				<polygon points="76.82 223.24 84.67 225.6 90.35 206.66 82.51 204.3 76.82 223.24"
						 style="fill: rgb(224, 224, 224); transform-origin: 83.585px 214.95px;" id="elat0glgu7f5i"
						 class="animable"></polygon>
				<path d="M52.29,206.41a3.64,3.64,0,0,0,2.23,3.67,5.44,5.44,0,0,0-1.1,2c-.45,1.47.75,3.44,3.1,4.14l18,5.41L79.78,204h0s-3.05-7.78-3.2-9,1.65-5.48,1.89-6.3c.2-.66-2.66-1.87-5-.94a3.93,3.93,0,0,0-1.46,1,12.36,12.36,0,0,0-2.06,3,10.69,10.69,0,0,0-1.1,5L58.05,193.5a3.38,3.38,0,0,0-2.33.08,3.06,3.06,0,0,0-.86,5.18,2.89,2.89,0,0,0-3,2,3.21,3.21,0,0,0,1.79,3.64A3.4,3.4,0,0,0,52.29,206.41Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 65.7789px 204.526px;" id="el96q4flsx5ko"
					  class="animable"></path>
			</g>
		</g>
		<defs>
			<filter id="active" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
			<filter id="hover" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
				<feColorMatrix type="matrix"
							   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
			</filter>
		</defs>
	</svg>
</template>

<script lang="ts">

export default {
	name: "VerifySuccessAnimation",
	components: {},
	props: {
		height: String,
	},
	setup() {
		return {}
	}
}
</script>

<style scoped>
svg#freepik_stories-ok:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-ok.animated #freepik--Floor--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--Shadow--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--Plants--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--check-mark--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--Character--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--speech-bubble--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-ok.animated #freepik--Icons--inject-107 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(-2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>