import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a9c2330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "overlay-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": true,
    "show-backdrop": true,
    "swipe-to-close": false,
    keyboardClose: true,
    onIonModalDidDismiss: _ctx.close,
    onDidDismiss: _ctx.close,
    trigger: "open-modal",
    class: "fullscreenModal",
    "backdrop-dismiss": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { onClick: _ctx.close }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.trackTypeCancel), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: "" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.trackTypeTitle), 1),
          _createVNode(_component_ion_grid, { class: "adv-grid" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFullTrackingTypeList(), (trackingTrackType) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      size: "6",
                      key: trackingTrackType.code,
                      "offset-4": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card, {
                          class: "ion-activatable ripple-parent",
                          onClick: ($event: any) => (_ctx.selectTrackType(trackingTrackType.code))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, { style: {"text-align":"center"} }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      icon: trackingTrackType.getTrackTypeIcon(),
                                      class: "overlay-track-icon-size"
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createElementVNode("p", null, _toDisplayString(trackingTrackType.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_ripple_effect)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_component_ion_button, {
              size: "large",
              expand: "block",
              onClick: _ctx.close
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trackTypeCancel), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onIonModalDidDismiss", "onDidDismiss"]))
}