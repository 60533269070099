import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01a479ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding-top ion-text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "pb-2 pt-1"
}
const _hoisted_4 = { class: "inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500" }
const _hoisted_5 = { class: "text-xl font-medium leading-none text-white" }
const _hoisted_6 = {
  key: 1,
  src: "https://www.gravatar.com/avatar?d=mm&s=140",
  alt: "profileImage"
}
const _hoisted_7 = { class: "font-bold text-base" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_default_stat = _resolveComponent("default-stat")!
  const _component_UserWalletItemFilter = _resolveComponent("UserWalletItemFilter")!
  const _component_UserWalletItemList = _resolveComponent("UserWalletItemList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_refresher, {
      slot: "fixed",
      onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => ($setup.refreshData($event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_refresher_content)
      ]),
      _: 1
    }),
    ($setup.wallet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($props.showIcon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    ($setup.wallet.getWalletImage())
                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                          key: 0,
                          icon: $setup.wallet.getWalletImage(),
                          class: "text-xl"
                        }, null, 8, ["icon"]))
                      : (_openBlock(), _createElementBlock("img", _hoisted_6))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          ($props.showBadge)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 1,
                color: "success"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString($setup.wallet.getPointsFormatted()), 1),
                  _createTextVNode(" " + _toDisplayString($setup.wallet.getWalletPointsName()), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.showStats)
            ? (_openBlock(), _createBlock(_component_default_stat, {
                key: 2,
                title: $setup.wallet.getWalletStatTitle(),
                name: $setup.wallet.getWalletStatName(),
                change: $setup.wallet.getWalletStatChange(),
                "change-type": $setup.wallet.getWalletStatChangeType(),
                "previous-stat": $setup.wallet.getWalletStatBefore30Days(),
                stat: $setup.wallet.getWalletStat()
              }, null, 8, ["title", "name", "change", "change-type", "previous-stat", "stat"]))
            : _createCommentVNode("", true),
          ($setup.wallet.points === 0 && $props.showEmptyPointsMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", {
                  innerHTML: $setup.wallet.getEmptyWalletPointsMessage()
                }, null, 8, _hoisted_9)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _withDirectives(_createElementVNode("p", null, "Mach weiter so! Du bist auf einen guten Weg!", 512), [
                  [_vShow, $props.showMotivationText]
                ]),
                _createVNode(_component_UserWalletItemFilter, {
                  types: $setup.getWalletItemTypes(),
                  "sub-types": $setup.getWalletItemSubTypes(),
                  onFilter: $setup.filterItems,
                  onReset: $setup.resetItems
                }, null, 8, ["types", "sub-types", "onFilter", "onReset"]),
                _createVNode(_component_UserWalletItemList, { wallet: $setup.wallet }, null, 8, ["wallet"])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}