import {
	EVENT_TYPE_END_TRACK, EVENT_TYPE_END_TRACKING,
	EVENT_TYPE_START_TRACK,
	EVENT_TYPE_START_TRACKING,
	MobiPointsQueueEventAbstractEvent
} from "@/mobipoints/queue/event/abstract_event.type";
import store from '@/store';
import {MobiPointApiEvent} from "@/mobipoints/api/events";
import {ResponseError} from "@/mobipoints/api/profile";
import useEvent from "@/composable/useEvent";
import useAuth from "@/composable/useAuth";
import useSystem from "@/composable/useSystem";
import useToastMessage from "@/components/core/ToastMessage.vue";
import useTracking from "@/composable/useTracking";
import {getDateDiffInSeconds} from "@/mobipoints/core/date";
import useTrackingFactory from "@/composable/useTrackingFactory";
import useQueue from "@/composable/useQueue";

export class MobiPointsQueueQueue {
	private eventInterval = 15000;
	private errorCount = 0;

	private queueInterval: any;
	private readonly events: any | MobiPointsQueueEventAbstractEvent[] = [];
	private readonly isAuthenticated: any | boolean = false;

	constructor() {
		this.events = useEvent().getEventList;
		this.isAuthenticated = useAuth().isAuthenticated;
	}

	getEvents(): MobiPointsQueueEventAbstractEvent[] {
		return this.events.value;
	}

	isAllowedToDeleteEvents(): boolean {
		let result = true;
		const eventTypeList = [EVENT_TYPE_START_TRACKING, EVENT_TYPE_START_TRACK, EVENT_TYPE_END_TRACK, EVENT_TYPE_END_TRACKING];
		if (this.getEvents() && this.getEvents().length > 0 && this.getEvents().some(e => e && eventTypeList.includes(e.type))) {
			result = false;
		}
		return result;
	}

	stopActiveTracking() {
		if (useTracking().hasActiveTracking.value) {
			try {
				useTracking().resetCurrentTracking();
			} catch (error) {
				console.log(error);
			}
		}
	}

	async sendEvents(throwException = true) {
		if (!this.isAuthenticated.value) {
			return;
		}

		if (!useSystem().hasInternetConnection()) {
			return;
		}

		const allowedToDeleteEvents = this.isAllowedToDeleteEvents();
		let events :MobiPointsQueueEventAbstractEvent[]|null = null;
		try {
			if (useQueue().isSending.value) {
				return;
			}
			useQueue().setIsSending(true);
			events = [...this.getEvents()];
			if (events.length > 0) {
				if (useSystem().isDevelopmentEnv()) {
					console.log("FOUND NEW EVENTS TO SEND", events);
				}
				try {
					useSystem().addLog('FOUND ' +  events.length + ' NEW EVENTS TO SEND');
				} catch (error) {
					//
				}
				if (await MobiPointApiEvent.sendEvents(events)) {
					store.commit('queue/removeEvents', events)
					this.errorCount = 0;
				} else {
					console.log("Error sending Event");
				}
			}
		} catch (error: any) {
			this.errorCount++;
			console.log(error);
			useSystem().addLogByError(error, 'Send Events Error, Error Count: ' + this.errorCount);

			if (events === null) {
				events = [...this.getEvents()];
			}
			if (events.length > 0) {
				if (this.errorCount > 200) {
					if (allowedToDeleteEvents) {
						store.commit('queue/removeEvents', events);
					} else {
						this.stopActiveTracking();
						await useToastMessage().openToast('Es tut uns leid 😢! Leider konnten wir dein Tracking nicht aufzeichen! Daher mussten wir, das aktuelle Tracking stoppen! Bitte probiere es erneut oder kontaktiere unsern Support. Danke ', 'danger', 'top', true, 15000);
					}
					this.errorCount = 0;
				}
				if (throwException) {
					throw new ResponseError(
						error.status,
						error.message || ''
					);
				}
			}
		} finally {
			useQueue().setIsSending(false);
		}
	}

	public async startIfNotRunning(force = false) {
		let result = force;

		if (result) {
			await this.start();
		} else {
			if (!useEvent().getLastEventTimestamp.value || useEvent().getLastEventTimestamp.value === null) {
				result = true;
			} else {
				const lastEventTimestamp: any = useEvent().getLastEventTimestamp.value;
				const lastEventSendSeconds = getDateDiffInSeconds(useTrackingFactory().getTrackingFactory().createCurrentTimeStamp(), lastEventTimestamp);
				if (lastEventSendSeconds > 60) {
					result = true;
				}

				if (!result && (useQueue().queueInterval.value === undefined || !useQueue().queueInterval.value) ) {
					result = true;
				} else if (!result && useEvent().getEventList.value.length > 0 && lastEventSendSeconds > 20) {
					result = true;
				}
			}

			if (result) {
				await this.start();
			}
		}
	}

	public async start() {
		this.destroy();
		this.queueInterval = setInterval(() => {
			void (async () => {
				await this.sendEvents(false);
			})();
		}, this.eventInterval);
		useQueue().setQueueInterval(this.queueInterval);
	}

	public destroy() {
		const intervalID: number|null = useQueue().queueInterval.value;
		if (intervalID) {
			clearInterval(intervalID);
			useQueue().setQueueInterval(null);
			this.queueInterval = null;
		}
	}
}
