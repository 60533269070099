<template>
	<div class="ion-padding">
		<h1>Statistic</h1>
		<swiper v-if="isPlatform('ios') || isPlatform('android')" :height="200" :slides-per-view="2.5" :loop="false" :space-between="0">
			<swiper-slide v-for="data in statisticData" :key="data.title">
				<ion-card>
					<ion-card-header>
						<ion-card-title>{{data.title}}</ion-card-title>
						<ion-card-subtitle class="statisticSubtitle">{{data.description}} <div class="blob blue"></div></ion-card-subtitle>
					</ion-card-header>
				</ion-card>
			</swiper-slide>
		</swiper>

		<swiper v-else :height="200" :slides-per-view="3.5" :loop="false" :space-between="5">
			<swiper-slide v-for="data in statisticData" :key="data.title">
				<ion-card>
					<ion-card-header>
						<ion-card-title>{{data.title}}</ion-card-title>
						<ion-card-subtitle class="statisticSubtitle">{{data.description}} <div class="blob blue"></div></ion-card-subtitle>
					</ion-card-header>
				</ion-card>
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
import {ref} from "vue";
import { isPlatform } from '@ionic/vue';
import {IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
	name: 'StatisticOverview',
	components: {IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, Swiper, SwiperSlide},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const statisticData = ref([
			{
				"title": "MOBI POINTS",
				"description": "0"
			},
			{
				"title": "GESAMTSTRECKE",
				"description": "0"
			},
			{
				"title": "Ø km/h",
				"description": "0"
			},
		]);

		return {
			icons,
			statisticData,
			isPlatform,
		}
	}
}
</script>

<style scoped>
ion-card-title {
	color: #B0B3BA;
    font-family: Rubik, sans-serif !important;
    font-size: 12px;
    font-weight:normal;
}
.statisticSubtitle {
    color: var(--ion-text-color);
    font-family: Rubik, sans-serif !important;
    font-size: 18px;
    font-weight:normal;
	text-align: center;
}

.blobs-container {
    display: flex;
}

.blob {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.blob.white {
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

.blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

.blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}

.blob.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

.blob.purple {
    background: rgba(142, 68, 173, 1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
    animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
    }
}
</style>