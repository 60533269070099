<template>
	<ion-page>
		<ion-content fullscreen>
			<ion-header translucent>
				<ion-toolbar>
					<ion-buttons slot="start">
						<ion-back-button defaultHref="/profile/userLocation" :text="backButtonText"></ion-back-button>
					</ion-buttons>
					<ion-title v-if="isEditMode">Addresse bearbeiten</ion-title>
					<ion-title v-else>Addresse</ion-title>
					<ion-buttons slot="end">
						<ion-button v-if="!isEditMode" @click="deleteUserLocation">
							<ion-icon :icon="icons.trashOutline"></ion-icon>
							<ion-ripple-effect></ion-ripple-effect>
						</ion-button>
						<ion-button v-if="!isEditMode" @click="editUserLocation">
							<ion-icon :icon="icons.pencilOutline"></ion-icon>
							<ion-ripple-effect></ion-ripple-effect>
						</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>
			<div v-if="isEditMode" style="padding-top: 10px">
				<form autocomplete="off">
					<div class="ion-padding">
						<ion-label position="stacked">Typ</ion-label>
						<ion-input :value="userAddressEditData.type.name" disabled autocomplete="off"></ion-input>
						<div class="ion-align-items-center ion-justify-content-start location-type-selection">
							<ion-button v-for="locationType in locationTypes" :key="locationType.code"
										:value="locationType.code"
										:color="locationType.code === userAddressEditData.type.code ? 'primary' : 'medium'"
										@click="locationTypeChanged">
								<ion-icon :icon="locationType.icon" slot="icon-only" :title="locationType.name"
										  style="padding: .5em"></ion-icon>
								<ion-ripple-effect/>
							</ion-button>
						</div>
					</div>

					<div>
						<vue-google-autocomplete id="searchUserLocation" autocomplete="off"
												 classname="form-control"
												 :placeholder="searchInputPlaceholder" enable-geolocation="true"
												 v-on:placechanged="getAddressData"
						/>
					</div>
				</form>
				<div style="height: 300px; width: 100%">
					<!--				<default-map :init-cords="coords" height="100%" width="100%"/>-->
					<disabled-map :init-cords="coords" height="100%" width="100%" :disable-marker-update-event="false"
								  :update-address-on-click="true" show-map-type-control="true"
								  @onMarkerUpdated="updateAddressData"></disabled-map>
				</div>
				<ion-list class="ion-no-margin" lines="full">
					<ion-list-header lines="full">
					</ion-list-header>
					<ion-item-divider>
						<ion-label>
							{{ addressOverviewTitle }}
							<ion-chip>
								<ion-icon :icon="icons.pin" color="primary"></ion-icon>
								<ion-label class="ion-text-wrap address-chip-text">{{ userAddressData.street }}
									{{ userAddressData.streetNumber }}, {{ userAddressData.city }}
									{{ userAddressData.postalCode }} <span
											v-if="userAddressData.province && userAddressData.country.code === 'IT'">{{ userAddressData.province }}</span>,
									{{ userAddressData.country.code }}
								</ion-label>
							</ion-chip>
							<div class="ion-padding-bottom">
								<p>{{ userAddressData.street }} {{ userAddressData.streetNumber }}</p>
								<p>{{ userAddressData.city }} {{ userAddressData.postalCode }}</p>
								<p v-if="userAddressData.province && userAddressData.country.code === 'IT'">
									{{ userAddressData.province }}</p>
								<p>{{ userAddressData.country.name }}</p>
								<p>Lat: {{userAddressData.latitude}}, Lng: {{userAddressData.longitude}}</p>
							</div>
						</ion-label>
					</ion-item-divider>
				</ion-list>
			</div>
			<div v-else>
				<ion-card>
					<ion-card-header>
						<disabled-map :init-cords="coords" :street-view-control="false" :zoom-control="false"
									  disable-marker-update-event="false" height="17rem"
									  width="100%"></disabled-map>
						<ion-card-subtitle>{{ userAddressData.getFormattedAddress() }}</ion-card-subtitle>
					</ion-card-header>

					<ion-card-content>
						<ion-item>
							<ion-icon slot="start" :icon="userAddressData.type.icon"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Typ</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.type.name }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.pin"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Strasse</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.street }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.pin"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Hausnummer</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.streetNumber }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.homeOutline"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Stadt</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.city }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.pin"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>ZIP</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.postalCode }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.pin"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Provinz</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.province }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-icon slot="start" :icon="icons.navigateOutline"></ion-icon>
							<ion-label class="ion-text-wrap">
								<ion-text color="primary">
									<h3>Land</h3>
								</ion-text>
								<ion-text>
									{{ userAddressData.country.name }}
								</ion-text>
							</ion-label>
						</ion-item>
						<ion-item>
							<ion-label class="ion-text-wrap">
								<ion-text>
									<ion-chip v-for="userAddressTag in userAddressData.tags" :key="userAddressTag"
											  :color="getRandomColor">{{ userAddressTag }}
									</ion-chip>
								</ion-text>
							</ion-label>
						</ion-item>
					</ion-card-content>
				</ion-card>
			</div>
		</ion-content>
		<ion-footer v-if="isEditMode">
			<ion-toolbar>
				<ion-button slot="start" color="medium" class="ion-margin-start" @click="cancelUserLocation">
					<ion-icon :icon="icons.close"></ion-icon>
					<ion-label style="margin-left: .25rem;">{{cancelButtonText}}</ion-label>
					<ion-ripple-effect></ion-ripple-effect>
				</ion-button>
				<ion-button slot="end" class="ion-margin-end" @click="updateUserLocation">
					<ion-icon :icon="icons.save"></ion-icon>
					<ion-label style="margin-left: .5rem;">{{saveButtonText}}</ion-label>
					<ion-ripple-effect></ion-ripple-effect>
				</ion-button>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {
  alertController,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon, IonicSafeString,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRippleEffect,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import DisabledMap from "@/components/map/DisabledMap.vue";
import {AddressInterface, useCoordinate} from "@/composable/useCoordinate";
import router from "@/router";
import {useRoute} from "vue-router";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {ProfileFactory} from "@/mobipoints/profile/profile_factory";
import useToastMessage from "@/components/core/ToastMessage.vue";
import * as icons from 'ionicons/icons';
import {
	MobiPointsLocationCountryInterface,
	MobiPointsLocationTypeInterface,
	MobiPointsLocationTypes
} from "@/mobipoints/location/location_type.interface";
import useMainData from "@/composable/useMainData";
import store from "@/store";
import useData from "@/composable/useData";

export default defineComponent({
	name: 'DetailUserLocationList',
	components: {
		DisabledMap,
		IonItem, IonList,
		IonLabel,
		IonContent,
		IonHeader,
		IonToolbar,
		IonTitle,
		IonListHeader,
		IonInput,
		VueGoogleAutocomplete,
		IonPage,
		IonButton,
		IonButtons,
		IonBackButton,
		IonIcon,
		IonRippleEffect,
		IonCard,
		IonCardHeader,
		IonCardSubtitle,
		IonCardContent,
		IonText,
		IonChip,
	},
	props: {
		id: Number,
	},
	setup(props) {
		const userAddressData = ref<UserLocation>((new ProfileFactory()).createEmptyProfileUserLocation());
		const userAddressEditData = ref<UserLocation>((new ProfileFactory()).createEmptyProfileUserLocation());
		const coords = ref({lat: 0, lng: 0});
		const {getCurrentPosition} = useCoordinate();
		const {openToast} = useToastMessage();
		const isEditMode = ref(false);
		const locationId = ref();
		const {getTextValueByKey} = useData();

		const addUserLocationTitle = ref(getTextValueByKey('add.user.location.title', [], 'Neue Adresse'));
		const cancelButtonText = ref(getTextValueByKey('add.user.location.cancel.btn', [], 'Abbrechen'));
		const saveButtonText = ref(getTextValueByKey('add.user.location.save.btn', [], 'Speichern'));
		const searchLabelText = ref(getTextValueByKey('add.user.location.search.label', [], 'Gib deine Adresse ein'));
		const searchInputPlaceholder = ref(getTextValueByKey('add.user.location.search.placeholder', [], '📍 Gib deine Adresse ein'));
		const addressOverviewTitle = ref(getTextValueByKey('add.user.location.overview.title', [], 'Adresse'));
		const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

		const mainData = useMainData();

		const locationTypes = ref<MobiPointsLocationTypeInterface[]>(mainData.locationTypesSelectable.value);

		onMounted(() => {
			if (props.id) {
				locationId.value = props.id;
			} else {
				const referenceFromRoute = useRoute().params.id;
				locationId.value = Number(referenceFromRoute);
			}

			loadUserLocation();
		})

		function getProfileFactory(): ProfileFactory {
			return new ProfileFactory();
		}

		function getRandomColor(): string {
			const randomColors = [
				'success', 'danger', 'secondary', 'dark',
			];

			const randomIndex = Math.floor(Math.random() * randomColors.length);
			return randomColors[randomIndex];
		}

		async function loadUserLocation() {
			const location = mainData.getLocationById(locationId.value);
			if (null !== location) {
				userAddressData.value = location;
				userAddressEditData.value = location;

				coords.value.lat = userAddressEditData.value.latitude;
				coords.value.lng = userAddressEditData.value.longitude;
			}
		}

		function goBackToUserLocationList() {
			router.push("/profile/userLocation");
		}

		async function cancelUserLocation() {
			mainData.reloadUserLocations(true).then( () => {
				if (locationId.value && mainData) {
					loadUserLocation();
					isEditMode.value = false;
				}
			});
		}

		function editUserLocation() {
			isEditMode.value = true;
		}

		async function deleteUserLocation() {
			const alert = await alertController
				.create({
					cssClass: 'my-custom-class',
					header: 'Sicherheitsabfrage:',
					message: new IonicSafeString('Möchtest du die Adresse wirklich <strong>löschen</strong>?'),
					buttons: [
						{
							text: 'Nein',
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button',
						},
						{
							text: 'Ja',
							cssClass: 'danger',
							id: 'confirm-button',
							handler: async () => {
								const result: string | null = await store.dispatch('mainData/removeUserLocation', locationId.value);
								if (null === result) {
									goBackToUserLocationList();
								} else {
									await openToast(`Die Adresse konnte nicht gelöscht werden: ${result || 'Unknown'}`, "danger", "top", true, 2000, icons.alertOutline, true)
								}
							},
						},
					],
				});
			return alert.present();
		}

		function validateUserAddressData() {
			userAddressEditData.value.latitude = coords.value.lat
			userAddressEditData.value.longitude = coords.value.lng
			delete userAddressEditData.value.name;
		}

		async function updateUserLocation() {
			validateUserAddressData();
			const result: string | null = await store.dispatch('mainData/updateUserLocation', userAddressEditData.value);
			if (null === result) {
				userAddressData.value = userAddressEditData.value
				isEditMode.value = false;
				await openToast("Die Addresse wurde erfolgreich aktualisiert!", "success", "top", true, 2000, icons.checkmarkOutline, true)
			} else {
				await openToast("Beim Speichern der Änderungen ist ein Fehler aufgetreten!", "danger", "top", true, 2000, icons.alertOutline, true)
			}
		}

		function updateAddressData(addressData: AddressInterface) {
			userAddressEditData.value.street = addressData.street;
			userAddressEditData.value.streetNumber = addressData.streetNumber;
			userAddressEditData.value.postalCode = addressData.postalCode;
			userAddressEditData.value.country = {code: addressData.country, name: addressData.countryName};
			userAddressEditData.value.province = addressData.adminArea2 || addressData.adminArea1 || '';
			userAddressEditData.value.city = addressData.city;
			userAddressEditData.value.latitude = coords.value.lat;
			userAddressEditData.value.longitude = coords.value.lng;
		}

		function getCountryCodeFromPlaceResult(result: any): MobiPointsLocationCountryInterface | null {
			const country = (result.address_components as Array<any>).find((addressComponent) => {
				return !!(addressComponent.types as Array<string>).find((type) => 'country' === type);
			});

			return country && {code: country.short_name, name: country.long_name} || null;
		}

		function getAddressData(addressData, placeResultData, id) {
			coords.value.lat = addressData.latitude;
			coords.value.lng = addressData.longitude;

			// const address = getAddressByCoords(coords.value.lat, coords.value.lng);
			userAddressEditData.value.street = addressData.route;
			userAddressEditData.value.streetNumber = addressData.street_number;
			userAddressEditData.value.postalCode = addressData.postal_code;
			userAddressEditData.value.country = getCountryCodeFromPlaceResult(placeResultData) || addressData.country;
			userAddressEditData.value.city = addressData.locality;
			userAddressEditData.value.province = addressData.administrative_area_level_1;
			userAddressEditData.value.latitude = coords.value.lat;
			userAddressEditData.value.longitude = coords.value.lng;
		}

		function locationTypeChanged(e: any) {
			const newType: MobiPointsLocationTypes = (e.target as typeof IonButton).getAttribute('value');
			userAddressEditData.value.type = mainData.getLocationTypeByCode(newType) as MobiPointsLocationTypeInterface;
		}

		return {
			getAddressData,
			coords,
			deleteUserLocation,
			updateAddressData,
			cancelUserLocation,
			userAddressData,
			userAddressEditData,
			editUserLocation,
			isEditMode,
			getRandomColor,
			updateUserLocation,
			icons,
			locationTypes,
			locationTypeChanged,
			addUserLocationTitle,
			cancelButtonText,
			saveButtonText,
			searchLabelText,
			searchInputPlaceholder,
			addressOverviewTitle,
			backButtonText
		}
	}
})
</script>

<style scoped>
#searchUserLocation:focus {
    border-color: var(--ion-color-primary);
    box-shadow: 0 0 8px 0 var(--ion-color-primary);
}


#searchUserLocation {
    width: 100%;
    padding: .75em;
    background-color: rgba(var(--ion-color-primary-rgb), .9);
    /*background-color: rgba(var(--ion-text-color-rgb), .9);*/
    /*color: var(--ion-text-color);*/
    color: var(--ion-color-white);
    border: 2px solid transparent;
    box-sizing: border-box;
    outline: 0;
    border-radius: 10px;
    appearance: none;
    transition: all var(--search-dur) var(--search-bez);
    transition-property: width, border-radius;
    z-index: 1;
    position: relative;
    text-align: left;
    box-shadow: 0px 0.15em 0.5em 1px grey;
}

#searchUserLocation::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--ion-color-white);
    opacity: .85;
}
#searchUserLocation::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--ion-color-white);
    opacity: .85;
}
#searchUserLocation::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--ion-color-white);
    opacity: .85;
}

.address-chip-text {
    font-size: 10px;
}
</style>
