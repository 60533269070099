<template>
	<ion-page>
		<ion-header no-border>
			<ion-toolbar>
				<ion-title>{{ tutorialTitle }}</ion-title>
				<ion-buttons slot="end">
					<ion-button color='primary' @click="endTutorial()" v-show="!showSkip">Skip</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content fullscreen>
			<swiper ref="tutorialSlides" @slide-next-transition-start="activeIndex++"
						@slide-prev-transition-start="activeIndex--" @slide-change="onSlideChangeStart($event)" pager="true"
						style="height: 100%">
				<swiper-slide>
					<!--					<img src="/assets/img/ica-slidebox-img-2.png" class="slide-image" />-->
					<GlobalWarmingAnimation v-if="activeIndex === 0" class="animationSize"></GlobalWarmingAnimation>
					<div class="text-margin">
						<h2 class="slide-title" v-html="tutorialSlide1Title"/>
						<p v-html="tutorialSlide1Description"/>
					</div>
				</swiper-slide>

				<swiper-slide>
					<!--					<img src="/assets/img/ica-slidebox-img-1.png" class="slide-image" />-->
					<HappyEarthAnimation v-if="activeIndex === 1" class="animationSize"></HappyEarthAnimation>
					<div class="text-margin">
						<h2 class="slide-title" v-html="tutorialSlide2Title"/>
						<p v-html="tutorialSlide2Description"/>
					</div>
				</swiper-slide>

				<swiper-slide>
					<ion-grid>
						<ion-row>
							<ion-col>
								<WalkingAnimation v-if="activeIndex === 2" class="twoAnimationsSize"></WalkingAnimation>
							</ion-col>
							<ion-col>
								<BicycleAnimation v-if="activeIndex === 2" class="twoAnimationsSize"></BicycleAnimation>
							</ion-col>
						</ion-row>
					</ion-grid>
					<div class="text-margin">
						<h2 class="slide-title" v-html="tutorialSlide3Title"/>
						<p v-html="tutorialSlide3Description"/>
					</div>
				</swiper-slide>

				<swiper-slide>
					<WinnerAnimation v-if="activeIndex === 3" class="animationSize"></WinnerAnimation>
					<div class="text-margin">
						<h2 class="slide-title" v-html="tutorialSlide4Title"/>
						<ion-button fill="clear" @click="endTutorial()">
							<span v-html="tutorialSlide4Description"/>
							<ion-icon slot="end" name="arrow-forward"></ion-icon>
						</ion-button>
					</div>
				</swiper-slide>
			</swiper>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {ref, onMounted} from "vue";
import useCustomStore from "@/composable/useCustomStore";
import router from "@/router";
import {
	IonPage,
	IonIcon,
	IonContent,
	IonButtons,
	IonButton,
	IonHeader,
	IonToolbar
} from '@ionic/vue';
import {arrowForward} from 'ionicons/icons';
import useData from "@/composable/useData";
import WalkingAnimation from "@/components/tutorial/WalkingAnimation.vue";
import HappyEarthAnimation from "@/components/tutorial/HappyEarthAnimation.vue";
import GlobalWarmingAnimation from "@/components/tutorial/GlobalWarmingAnimation.vue";
import BicycleAnimation from "@/components/tutorial/BicycleAnimation.vue";
import WinnerAnimation from "@/components/tutorial/WinnerAnimation.vue";
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
	name: 'Tutorial',
	components: {
		WalkingAnimation,
		WinnerAnimation,
		BicycleAnimation,
		GlobalWarmingAnimation,
		HappyEarthAnimation,
		IonPage, IonIcon, IonButtons, IonButton, IonHeader, IonToolbar, IonContent,
			Swiper, SwiperSlide
	},
	setup() {
		const {setTutorialCompleted} = useCustomStore();
		const {getTextValueByKey} = useData();

		const tutorialSlides = ref<any>(null);
		const showSkip = ref(false);
		const activeIndex = ref(0);
		const tutorialTitle = ref(getTextValueByKey('tutorial.title'));
		const tutorialSlide1Title = ref(getTextValueByKey('tutorial.slide.1.title'));
		const tutorialSlide1Description = ref(getTextValueByKey('tutorial.slide.1.description'));
		const tutorialSlide2Title = ref(getTextValueByKey('tutorial.slide.2.title'));
		const tutorialSlide2Description = ref(getTextValueByKey('tutorial.slide.2.description'));
		const tutorialSlide3Title = ref(getTextValueByKey('tutorial.slide.3.title'));
		const tutorialSlide3Description = ref(getTextValueByKey('tutorial.slide.3.description'));
		const tutorialSlide4Title = ref(getTextValueByKey('tutorial.slide.4.title'));
		const tutorialSlide4Description = ref(getTextValueByKey('tutorial.slide.4.description'));

		const slideOpts = ref({});

		function onSlideChangeStart() {
			showSkip.value = true;
		}

		function endTutorial() {
			setTutorialCompleted(true);
			router.push('/home');
		}

		onMounted(() => {
			activeIndex.value = 0;
		});

		return {
			tutorialSlides,
			arrowForward,
			onSlideChangeStart,
			endTutorial,
			showSkip,
			tutorialTitle,
			tutorialSlide1Title,
			tutorialSlide1Description,
			tutorialSlide2Title,
			tutorialSlide2Description,
			tutorialSlide3Title,
			tutorialSlide3Description,
			tutorialSlide4Title,
			tutorialSlide4Description,
			activeIndex,
			slideOpts
		}
	}
}
</script>

<style scoped>
ion-toolbar {
    --background: transparent;
    --border-color: transparent;
}

.swiper-slide {
    display: block;
}

.slide-title {
    margin-top: 2.8rem;
}

.slide-image {
    max-height: 50%;
    max-width: 60%;
    margin: 36px 0;
}

b {
    font-weight: 500;
}

p {
    padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: #60646B;
}

p b {
    color: #000000;
}

.animationSize {
    height: 50vh;
}

.text-margin {
    margin-top: -50px;
}

.twoAnimationsSize {
    height: 100%;
}
</style>