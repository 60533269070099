import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { style: {"text-align":"center","margin":"auto","line-height":"54px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!

  return (_openBlock(), _createBlock(_component_ion_label, {
    slot: "end",
    style: {"cursor":"pointer"},
    onClick: $setup.showAvatarMenu
  }, {
    default: _withCtx(() => [
      ($props.showPoints === true)
        ? (_openBlock(), _createBlock(_component_ion_badge, {
            key: 0,
            class: "points-in-menu",
            color: "dark",
            slot: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "points-text ion-no-padding" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.currentPoints) + " ", 1),
                  ($setup.currentPoints.toString().length <= 3)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "P"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.getUserImage)
        ? (_openBlock(), _createBlock(_component_ion_avatar, {
            key: 1,
            slot: "end",
            style: _normalizeStyle(['padding: 5px; margin-right: 13px', $props.imgHeight.length > 0 ? 'height: ' + $props.imgWidth : '', $props.imgWidth.length > 0 ? 'width: ' + $props.imgWidth : '']),
            title: $setup.getFullName
          }, {
            default: _withCtx(() => [
              ($setup.getUserImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: $setup.getFullName,
                    src: $setup.getUserImage
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["style", "title"]))
        : (_openBlock(), _createBlock(_component_ion_avatar, {
            key: 2,
            slot: "end",
            class: "avatar-abbr",
            style: _normalizeStyle(['margin-right: 13px;', $props.imgHeight.length > 0 ? 'height: ' + $props.imgWidth : '', $props.imgWidth.length > 0 ? 'width: ' + $props.imgWidth : '']),
            title: $setup.getFullName
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString($setup.getUserAbbr), 1)
            ]),
            _: 1
          }, 8, ["style", "title"]))
    ]),
    _: 1
  }, 8, ["onClick"]))
}