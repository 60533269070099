import {
	WalletInterface,
	WalletState, WalletType,
} from '@/mobipoints/wallet/wallet.interface';
import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';
import * as icons from 'ionicons/icons';
import useData from '@/composable/useData';

export abstract class AbstractWallet implements WalletInterface {
	uuid: string;
	state: WalletState;
	type: WalletType;
	user_reference: string;
	items?: WalletItemList;
	points?: number;

	constructor(
			uuid: string, state: WalletState, type: WalletType, user_reference: string, items?: WalletItemList | undefined, points?: number | undefined) {
		this.uuid = uuid;
		this.state = state;
		this.type = type;
		this.user_reference = user_reference;
		this.items = items;
		this.points = points;
	}

	getWalletTitle(): string {
		return useData().getTextValueByKey('mobipoints.wallet.title.default', [], 'Mein Wallet');
	}

	getWalletPointsName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.default', [], 'Punkte');
	}

	getWalletImage(): string {
		return icons.trophyOutline;
	}

	getEmptyWalletPointsMessage(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.empty.default', [], 'Du hast noch keine Punkte gesammelt!<br>Starte gleich mit deinem ersten Tracking!');
	}

	getPointsFormatted(): string {
		if (!this.points) {
			return '0';
		}
		return this.points?.toFixed(0);
	}

	getWalletStatTitle(): string {
		return '';
		// return useData().getTextValueByKey('mobipoints.wallet.points.stat.default.title', [], 'Letzten 30 Tage');
	}

	getWalletStatName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.stat.default.name', [], 'Punkte der letzten 30 Tage');
	}

	getWalletStat(): string {
		//TODO filter items , irgendwann soll es dann über die API kommen!
		if (!this.items) {
			return '0';
		}
		return this.items.getTotalAmount().toFixed(0);
	}

	getWalletStatChange(): string {
		//TODO filter items , irgendwann soll es dann über die API kommen!
		if (!this.items) {
			return '0';
		}

		const amountBefore30Days = Number(this.getWalletStatBefore30Days());
		const totalAmount = this.items.getTotalAmount();
		const changeInPercent = (100 - ((amountBefore30Days * 100) / totalAmount)).toFixed(0);
		return changeInPercent + ' %';
	}

	getWalletStatChangeType(): string {
		return 'increase';
	}

	getWalletStatBefore30Days(): string {
		//TODO filter items , irgendwann soll es dann über die API kommen!
		if (!this.items) {
			return '0';
		}

		const currentDate = new Date();
		const thirtyDaysAgo = new Date();
		thirtyDaysAgo.setDate(currentDate.getDate() - 30);

		let amount = 0;
		const walletItemList: WalletItemList|any = [...this.items];
		walletItemList.filter((walletItem) => {
			const entryTimestamp = walletItem.getTimestampAsDate();
			if (entryTimestamp && (entryTimestamp < thirtyDaysAgo) ) {
				amount += walletItem.amount;
			}
			return entryTimestamp < thirtyDaysAgo;
		});

		return amount.toFixed(0);
	}
}