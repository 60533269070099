import {computed, ComputedRef, onBeforeMount} from "vue";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import store from "@/store";
import {ChallengeResponseError, MobiPointsApiChallenge} from "@/mobipoints/api/challenge";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {CHALLENGE_STATE_IN_PROGRESS} from "@/mobipoints/challenge/challenge/challenge.interface";
import {
	USER_CHALLENGE_STATE_ACCEPTED, USER_CHALLENGE_STATE_CANCELLED, USER_CHALLENGE_STATE_FINISHED,
	USER_CHALLENGE_STATE_NULLIFY
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";

export default function useChallenge() {
	const challenges: ComputedRef<Array<Challenge>> = computed(() => store.getters['challenge/challenges']);
	const openChallenges: ComputedRef<Array<Challenge>> = computed(() => {
		return challenges.value.filter((challenge: Challenge) => {
			if (CHALLENGE_STATE_IN_PROGRESS !== challenge.state.code) {
				return false;
			}

			return challenge.userChallenges.length < 1 || challenge.userChallenges.every((userChallenge) => {
				return USER_CHALLENGE_STATE_NULLIFY === userChallenge.state.code;
			});
		});
	});
	const activeChallenges: ComputedRef<Array<Challenge>> = computed(() => {
		return challenges.value.filter((challenge: Challenge) => {
			if (CHALLENGE_STATE_IN_PROGRESS !== challenge.state.code) {
				return false;
			}

			return challenge.userChallenges.length > 0 && challenge.userChallenges.every((userChallenge) => {
				return USER_CHALLENGE_STATE_ACCEPTED === userChallenge.state.code
					|| USER_CHALLENGE_STATE_CANCELLED === userChallenge.state.code
					|| USER_CHALLENGE_STATE_FINISHED === userChallenge.state.code;
			});
		});
	});

	const loadChallenges = async function () {
		try {
			const response = await MobiPointsApiChallenge.getChallenges();
			return response.data.payload;
		} catch (error: any | AxiosError) {
			throw new ChallengeResponseError(error.status, error.error.message);
		}
	}

	const initChallenges = async function (forceRefresh = false) {
		if (challenges.value.length < 1 || forceRefresh) {
			store.commit('challenge/challenges', await loadChallenges());
		}
	}

	const reloadChallenges = async function () {
		store.commit('challenge/resetChallenges');
		await initChallenges();
	}

	const getChallenge = function(uuid: string): Challenge|null {
		return challenges.value.find((challenge) => uuid === challenge.uuid) || null;
	}

	onBeforeMount(() => {
		initChallenges().then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		challenges,
		openChallenges,
		activeChallenges,
		initChallenges,
		reloadChallenges,
		getChallenge,
	}
}