import store from '@/store';
import MobiPointsApiClient from "@/mobipoints/api/client";
import {AuthenticationError} from "@/mobipoints/api/auth";
import {AxiosRequestConfig, AxiosError} from "axios";

const TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh_token";

const MobiPointApiToken = {

	getToken() {
		return store.getters['user/getToken'];
	},

	saveToken(accessToken: string) {
		store.commit('user/token', accessToken);
		store.commit('auth/token', accessToken);
		store.commit('auth/authenticating', true);
	},

	setTokenLifeTime(lifetime: number) {
		store.commit('auth/tokenLifeTime', lifetime);
	},

	getTokenLifeTime() {
		return store.getters['user/getLifeTime'];
	},

	removeToken() {
		store.commit('user/token', '');
		store.commit('auth/authenticating', false);
		localStorage.removeItem(TOKEN_KEY);
	},

	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},

	saveRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	},

	refreshToken: async function () {
		//TODO

		// const refreshToken = MobiPointApiToken.getRefreshToken();
		// try {
		// 	const response = await MobiPointsApiClient.customRequest(requestData);
		//
		// 	MobiPointApiToken.saveToken(response.data.access_token);
		// 	MobiPointApiToken.saveRefreshToken(response.data.refresh_token);
		// 	MobiPointsApiClient.setHeader();
		//
		// 	return response.data.access_token;
		// } catch (error: any | AxiosError) {
		// 	throw new AuthenticationError(
		// 		error.response.status,
		// 		error.response.data.error_description
		// 	);
		// }
	},
};

export { MobiPointApiToken };