const custom = {
	namespaced: true,
	state: () => ({
		tutorialCompleted: false,
		co2ChallengeDialog: false,
		treasureHuntChallengeDialog: false,
	}),
	mutations: {
		// eslint-disable-next-line
		setTutorialCompleted(state: any, value: boolean) {
			state.tutorialCompleted = value;
		},
		// eslint-disable-next-line
		setHasSeenCo2ChallengeDialog(state: any, value: boolean) {
			state.co2ChallengeDialog = value;
		},
		// eslint-disable-next-line
		setHasSeenTreasureHuntChallengeDialog(state: any, value: boolean) {
			state.treasureHuntChallengeDialog = value;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		getTutorialCompleted: (state: any) => {
			return state.tutorialCompleted;
		},
		// eslint-disable-next-line
		hasTutorialCompleted: (state: any) => {
			return state.tutorialCompleted;
		},
		// eslint-disable-next-line
		hasSeenCo2ChallengeDialog: (state: any) => {
			return state.co2ChallengeDialog;
		},
		// eslint-disable-next-line
		hasSeenTreasureHuntChallengeDialog: (state: any) => {
			return state.treasureHuntChallengeDialog;
		},
	}
}

export default custom;