import {MobiPointsCoreLog} from "@/mobipoints/core/log.type";
import {MobiPointsCoreLogInterface} from "@/mobipoints/core/log.interface";

export class MobiPointsCoreLogFactory {
	createLog(timestamp: number): MobiPointsCoreLog {
		return new MobiPointsCoreLog(timestamp);
	}

	createLogListByInterface(listInterface: MobiPointsCoreLogInterface): MobiPointsCoreLog[] {
		const list: MobiPointsCoreLog[] = [];

		listInterface.payload.forEach((data) => {
			list.push(this.createLogByInterface(data));
		});

		return list;
	}

	createLogByInterface(response: MobiPointsCoreLogInterface): MobiPointsCoreLog {
		const log = this.createLog(response.timestamp);
		log.error = response.error;
		log.message = response.message;
		log.trace = response.trace;

		return log;
	}
}