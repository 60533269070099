<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button defaultHref="/tabs/profile" :text="backButtonText"></ion-back-button>
				</ion-buttons>
				<ion-title>Mein MobiPoints Wallet</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Mein MobiPoints Wallet</ion-title>
				</ion-toolbar>
			</ion-header>
			<UserWallet :wallet-type="mobiPointsWalletType"></UserWallet>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import useSystem from '@/composable/useSystem';
import UserWallet from '@/components/wallet/UserWallet.vue';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import {ref} from 'vue';
import useData from '@/composable/useData';

export default {
	name: 'MobiPointsWallet',
	components: {
			IonButtons,
			IonBackButton,
		UserWallet,
		IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
	},
	setup() {
		const mobiPointsWalletType = WalletType.MOBI_POINTS;
		const {isNativePlatform} = useSystem();
		const {getTextValueByKey} = useData();
		const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

		return {mobiPointsWalletType, isNativePlatform, backButtonText};
	},
};
</script>

<style scoped>
ion-spinner {
    position: absolute;
    width: 5em;
    height: 5em;
    --color: #25c977;
}

.redeem-voucher-loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>