<template>
	<ion-page>
		<ion-content class="ion-no-padding">
			<div class="bg-white">
				<div class="isolate">
					<!-- Hero section -->
					<div class="relative pt-14">
						<div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
							 aria-hidden="true">
							<div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
								 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
						</div>
						<div class="py-24 sm:py-32">
							<div class="mx-auto max-w-7xl px-6 lg:px-8">
								<div class="mx-auto max-w-2xl text-center">
									<h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" v-html="titleText"></h1>
									<p class="mt-6 text-lg leading-8 text-gray-600" v-html="descriptionText"></p>
									<div class="mt-10 text-gr-dark pt-10">
										<h1>Datenschutzerklärung für Mobi Points</h1>

										<p class="text-gr-dark pt-4">Gemäß Art 13, Art 14 DSGVO sowie § 165 Abs 3 TKG informieren wir dich hier umfassend über alle Datenverarbeitungen. Bitte mach dir ein Bild darüber, welche deiner personenbezogenen Daten (im Folgenden einfach "Daten" genannt) wie und warum verarbeitet werden, wenn du die App "Mobi Points" nutzt. Welche Datenkategorien verarbeiten wir im Rahmen dieser App? Bei der Nutzung der App können folgende Datenkategorien verarbeitet werden: Endgeräte-ID, Name, E-Mail-Adresse, Foto (Wohn-)Adresse(n), GPS- bzw. Standort-Daten, Fahrzeiten, gewählte Verkehrsmittel. Wir verarbeiten diese Daten zu dem Zweck, dir diese App als Belohnungssystem zur Verfügung zu stellen, um breitenwirksame Anreize für nachhaltige Mobilität sowie ökologisches Handeln zu schaffen. Die Verarbeitung erfolgt im jeweils erforderlichen Umfang basierend auf deiner freiwilligen Einwilligung zur Nutzung dieser App (Art 6 Abs 1 lit a sowie Art 49 Abs 1 lit a - Ausnahmen für bestimmte Fälle - DSGVO) sowie unser überwiegendes berechtigtes Interesse auf die Schaffung eines dauerhaften Bewusstseins für eine nachhaltige Mobilität (Art 6 Abs 1 lit f DSGVO). Du bist nicht verpflichtet, uns deine Daten bereitzustellen, doch kannst du die App ohne Bereitstellung deiner Daten nicht nutzen. Für den Betrieb der App ist es notwendig, dass wir einige deiner Daten den folgenden Empfängern übermitteln:</p>

										<ul>
											<li>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, sowie Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, für die Nutzung des Dienstes "Google Maps" zur Anzeige von Standorten. Der Anbieter kann die erhobenen Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und personalisieren. Daher wurde eine Vereinbarung über die gemeinsame Verantwortlichkeit gemäß Art 26 DSGVO geschlossen: <a href="https://business.safety.google/intl/de/controllerterms/">Link zur Vereinbarung</a>.</li>
											<li>Für die Verwendung des Google-Logins werden die Scopes "Email" und "Profile" verwendet, die nur für die Anmeldung benötigt werden. Die Verarbeitung dieser Daten erfolgt ausschließlich zum Zweck der Authentifizierung und wird im jeweils erforderlichen Umfang basierend auf deiner Einwilligung zur Nutzung des Google-Logins (Art 6 Abs 1 lit a DSGVO) sowie unser überwiegendes berechtigtes Interesse an einer reibungslosen Anmeldeerfahrung (Art 6 Abs 1 lit f DSGVO) durchgeführt.</li>
										</ul>

										<p class="text-gr-dark pt-4">Wie lange werden deine Daten gespeichert? Wir speichern deine Daten nur so lange, wie es für jene Zwecke erforderlich ist, für die wir deine Daten erhoben haben. In diesem Zusammenhang sind gegebenenfalls gesetzliche Aufbewahrungspflichten zu berücksichtigen.</p>

										<p class="text-gr-dark pt-4">Welche Rechte stehen dir hinsichtlich der Datenverarbeitung zu? Wir möchten dich darüber informieren, dass du, sofern die gesetzlichen Voraussetzungen dafür vorliegen, die Rechte hast:</p>

										<ul>
											<li>Auskunft darüber zu verlangen, welche Daten von dir bei uns verarbeitet werden (siehe im Detail Art 15 DSGVO).</li>
											<li>Die Berichtigung oder Vervollständigung dich betreffender unrichtiger oder unvollständiger Daten zu verlangen (siehe im Detail Art 16 DSGVO).</li>
											<li>Auf Löschung deiner Daten (siehe im Detail Art 17 DSGVO), soweit dem keine Aufbewahrungspflichten entgegenstehen.</li>
											<li>Auf Einschränkung der Verarbeitung deiner Daten (siehe im Detail Art 18 DSGVO).</li>
											<li>Auf Datenübertragbarkeit - den Erhalt der von dir bereitgestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format (siehe im Detail Art 20 DSGVO).</li>
											<li>Gegen eine Verarbeitung deiner Daten, die auf Artikel 6 Absatz 1 Buchstaben e oder f DSGVO basiert, Widerspruch einzulegen (siehe im Detail Art 21 DSGVO). Sofern wir deine Daten auf Basis deiner Einwilligung verarbeiten, hast du das Recht, diese Einwilligung jederzeit zu widerrufen. Dadurch wird die Rechtmäßigkeit der bis zu diesem Zeitpunkt erfolgten Datenverarbeitung nicht beeinträchtigt (Art 7 Abs 3 DSGVO).</li>
										</ul>

										<p class="text-gr-dark pt-4">Sollte es wider Erwarten zu einer Verletzung deines Rechtes auf rechtmäßige Verarbeitung deiner Daten kommen, setz dich bitte mit uns in Verbindung. Wir werden uns bemühen, dein Anliegen umgehend, spätestens jedoch innerhalb der gesetzlichen Frist von einem Monat, zu bearbeiten. Du hast zudem stets das Recht, eine Beschwerde bei der für dich zuständigen Aufsichtsbehörde für Datenschutzangelegenheiten zu erheben.</p>

										<p class="text-gr-dark pt-4">Wer ist der Verantwortliche und wie kannst du mit uns Kontakt aufnehmen? Verantwortlich im Sinne des Art 4 Z 7 DSGVO für die hier dargelegten Datenverarbeitungen ist:</p>

										<p class="text-gr-dark pt-4">niceshops GmbH Saaz 99 8341 Paldau Österreich office@niceshops.com, mobitaskforce@niceshops.com</p>

										<p class="text-gr-dark pt-4">Geschäftsführer: Roland Fink, Mag. Christoph Schreiner, Barbara Unterkofler Die Kontaktdaten des Datenschutzbeauftragten der niceshops-Gruppe sind:</p>

										<ul>
											<li>E-Mail: privacy@niceshops.com</li>
											<li>Postalisch: niceshops GmbH, z. H. des Datenschutzbeauftragten, Annenstraße 23, 8020 Graz, Österreich.</li>
										</ul>

									</div>
								</div>
							</div>
						</div>
						<div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
							 aria-hidden="true">
							<div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
								 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
						</div>


					</div>

					<!-- CTA section -->
					<div class="relative z-10 mt-4 px-6 lg:px-8 pb-20">
						<div class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
							 aria-hidden="true">
							<div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
								 style="clip-path: polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)"/>
						</div>
						<div class="mx-auto max-w-2xl text-center">
							<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Auf gehts!</h2>
							<div class="mt-10 flex items-center justify-center gap-x-6">
								<router-link to="/login">
									<a class="h-12 cursor-pointer rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
										Login</a>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {ref} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import useData from '@/composable/useData';
import * as icons from 'ionicons/icons';

export default {
	name: 'PrivacyNew',
	components: {
		IonPage, IonContent,
	},
	setup() {
		const {getTextValueByKey} = useData();

		const titleText = ref(getTextValueByKey('privacy.title', [], 'Datenschutz'));
		const descriptionText = ref(getTextValueByKey('privacy.description', [], 'MobiPoints - crudafoch'));

		return {titleText, descriptionText, icons};
	},
};
</script>

<style>
</style>
