import {DataFactory} from "@/mobipoints/data/data_factory";

export default function useDataFactory() {
	let dataFactory: DataFactory | null = null;
	const getDataFactory = (): DataFactory => {
		if (!dataFactory) {
			dataFactory = new DataFactory();
		}

		return dataFactory;
	}

	return {
		getDataFactory,
	}
}