<template>
	<div class="ion-padding-top ion-text-center">
		<ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>
		<div v-if="wallet">
			<div class="pb-2 pt-1" v-if="showIcon">
							  <span class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
				<span class="text-xl font-medium leading-none text-white">
									<ion-icon v-if="wallet.getWalletImage()" :icon="wallet.getWalletImage()"
											  class="text-xl"></ion-icon>
				<img v-else src="https://www.gravatar.com/avatar?d=mm&s=140" alt="profileImage">
				</span>
			  </span>
			</div>

			<ion-badge v-if="showBadge" color="success"><span class="font-bold text-base">{{ wallet.getPointsFormatted() }}</span>
				{{ wallet.getWalletPointsName() }}
			</ion-badge>

			<default-stat v-if="showStats" :title="wallet.getWalletStatTitle()" :name="wallet.getWalletStatName()"
						  :change="wallet.getWalletStatChange()" :change-type="wallet.getWalletStatChangeType()"
						  :previous-stat="wallet.getWalletStatBefore30Days()"
						  :stat="wallet.getWalletStat()"></default-stat>

			<div v-if="wallet.points === 0 && showEmptyPointsMessage">
				<p v-html="wallet.getEmptyWalletPointsMessage()"></p>
			</div>
			<div v-else>
				<p v-show="showMotivationText">Mach weiter so! Du bist auf einen guten Weg!</p>
				<UserWalletItemFilter :types="getWalletItemTypes()" :sub-types="getWalletItemSubTypes()" @filter="filterItems" @reset="resetItems"></UserWalletItemFilter>
				<UserWalletItemList :wallet="wallet"></UserWalletItemList>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {onMounted, ref} from 'vue';
import {IonBadge, IonIcon, IonRefresher, IonRefresherContent, loadingController} from '@ionic/vue';
import useWallet from '@/composable/useWallet';
import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import UserWalletItemList from '@/components/wallet/UserWalletItemList.vue';
import useData from '@/composable/useData';
import * as icons from 'ionicons/icons';
import useSystem from '@/composable/useSystem';
import useToastMessage from '@/components/core/ToastMessage.vue';
import DefaultStat from '@/components/stats/DefaultStat.vue';
import {AxiosError} from 'axios';
import UserWalletItemFilter, { FilterSubmit } from '@/components/wallet/UserWalletItemFilter.vue';
import {WalletItemSubType, WalletItemType} from '@/mobipoints/wallet/item/wallet_item.interface';

export default {
	name: 'UserWallet',
	components: {
		UserWalletItemFilter,
		DefaultStat,
		IonRefresher,
		IonRefresherContent,
		UserWalletItemList,
		IonBadge,
		IonIcon,
	},
	props: {
		walletType: {
			type: String,
			default: WalletType.MOBI_POINTS,
		},
		showIcon: {
			type: Boolean,
			default: true,
		},
		showStats: {
			type: Boolean,
			default: true,
		},
		showMotivationText: {
			type: Boolean,
			default: true,
		},
		showBadge: {
			type: Boolean,
			default: true,
		},
		showEmptyPointsMessage: {
			type: Boolean,
			default: true,
		},
	},
  emits: ['handleRefresh'],
  setup(props: any, {emit}) {
		const wallet = ref<AbstractWallet>();
		const walletUuid = ref(null);
		const walletName = ref('TODO BY LTM');
		const walletImage = ref('TODO by WALLET');
		const currentPoints = ref(0);
		const {hasInternetConnection} = useSystem();
		const {initWallets, filterWalletItems, wallets} = useWallet();
		const {openToast} = useToastMessage();
		const {getTextValueByKey} = useData();

		function getWalletItemTypes(): Array<WalletItemType> {
			return [
				WalletItemType.DEPOSIT,
				WalletItemType.PAYOUT,
				WalletItemType.CORRECTION,
			];
		}

		function getWalletItemSubTypes(): Array<WalletItemSubType> {
			return [
				WalletItemSubType.TRACKING,
				WalletItemSubType.CHALLENGE,
				WalletItemSubType.VOUCHER,
			];
		}

		function initWallet_by_Type() {
			if (wallets.value) {
				wallet.value = wallets.value.getFirst_by_Type(props.walletType);
			}
		}

		async function refreshData(event: CustomEvent | any) {
			if (event && event.target && hasInternetConnection()) {
				try {
					setTimeout(() => {
						event.target.complete();
					}, 1500);
					await initWallet_for_Component(true);
					event.target.complete();
					await openToast(getTextValueByKey('refresh.data.success', [], 'Wallet erfolgreich aktualisiert'), 'success',
							'bottom', true, 1500, icons.refreshOutline, true);
          			emit('handleRefresh');
				} catch (error) {
					event.target.complete();
					console.error(error);
					await openToast(getTextValueByKey('refresh.data.error', [], 'Fehler beim aktualisieren deines Wallets!'),
							'danger', 'top', true, 1500, icons.bugOutline, true);
				}
			} else {
				event.target.complete();
			}
		}

		async function filterItems(filterObj: FilterSubmit) {
			const walletUuid = wallet.value?.uuid;
			if (walletUuid) {
				try {
					const loading = await loadingController.create({
						cssClass: '',
						duration: 3000,
					});

					setTimeout(function() {
						loading.dismiss();
					}, 3000);

					loading.present().then(() => {
						filterWalletItems(walletUuid, filterObj.dateFrom.length > 0 ? filterObj.dateFrom : null, filterObj.dateTo.length > 0 ? filterObj.dateTo : null, filterObj.selectedType && filterObj.selectedType.length > 0 ? [filterObj.selectedType] : null, filterObj.selectedSubtype && filterObj.selectedSubtype.length > 0 ? [filterObj.selectedSubtype] : null).then(() => {
							initWallet_by_Type();
							loading.dismiss();
						});
					});
				} catch (error: any | AxiosError) {
					console.log(error);
				}
			}
		}

		async function resetItems() {
			const walletUuid = wallet.value?.uuid;
			if (walletUuid) {
				initWallet_for_Component(true);
			}
		}

		async function initWallet_for_Component(forceReload = false) {
			try {
				const loading = await loadingController.create({
					cssClass: '',
					duration: 3000,
				});

				setTimeout(function() {
					loading.dismiss();
				}, 3000);

				loading.present().then(() => {
					initWallets(forceReload).then(() => {
						initWallet_by_Type();
						loading.dismiss();
					});
				});
			} catch (error: any | AxiosError) {
				console.log(error);
			}
		}

		onMounted(() => {
			initWallet_for_Component();
		});

		return {
			wallet,
			walletName,
			walletImage,
			currentPoints,
			walletUuid,
			refreshData,
			getWalletItemTypes,
		  	getWalletItemSubTypes,
			filterItems,
			resetItems
		};
	},
};
</script>

<style scoped>

.center-profile-image {
    margin: 0 auto;
}

ion-toolbar {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    --color: white;
}

ion-toolbar ion-button,
ion-toolbar ion-back-button,
ion-toolbar ion-menu-button {
    --color: white;
}

.about-header {
    position: relative;

    width: 100%;
    height: 30%;
}

.about-header .about-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
}

/*.about-header .madison {*/
/*    background-image: url(/assets/img/about/madison.jpg);*/
/*}*/

/*.about-header .austin {*/
/*    background-image: url(/assets/img/about/austin.jpg);*/
/*}*/

/*.about-header .chicago {*/
/*    background-image: url(/assets/img/about/chicago.jpg);*/
/*}*/

/*.about-header .seattle {*/
/*    background-image: url(/assets/img/about/seattle.jpg);*/
/*}*/

.about-info {
    margin-top: -10px;
    border-radius: 10px;
}

.about-info h3 {
    margin-top: 0;
}

.about-info ion-list {
    padding-top: 0;
}

.about-info p {
    line-height: 130%;

    color: var(--ion-color-dark);
}

.about-info ion-icon {
    margin-inline-end: 32px;
}

/*
 * iOS Only
 */

.ios .about-info {
    --ion-padding: 19px;
}

.ios .about-info h3 {
    font-weight: 700;
}
</style>
