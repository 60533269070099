import useDataFactory from "@/composable/useDataFactory";
import {MobiPointsDataInterface} from "@/mobipoints/data/data/data.interface";
import {Data} from "@/mobipoints/data/data/data.type";

interface DataStateContainer<T> {
	loaded: boolean,
	data: T,
}

const resetDataList = (state: any) => {
	state.dataList.loaded = false;
	state.dataList.data = [];
};

const {getDataFactory} = useDataFactory();

const data = {
	namespaced: true,
	state: () => ({
		dataList: {
			loaded: false,
			data: []
		} as DataStateContainer<Array<Data | MobiPointsDataInterface>>,
	}),
	mutations: {
		dataList(state: any, value: Array<MobiPointsDataInterface>) {
			state.dataList.loaded = true;
			state.dataList.data = value;
		},
		resetDataList(state: any) {
			resetDataList(state);
		},
	},
	actions: {},
	getters: {
		dataList: (state: any) => {
			return state.dataList.data.map((data) => {
				if (!(data instanceof Data)) {
					return getDataFactory().createDataByApiResponse(data);
				}
			});
		},
	}
}

export default data;