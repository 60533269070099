<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>Meine Trackings</ion-title>
				<avatar-menu :show-points="true"></avatar-menu>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true" class="">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Meine Trackings</ion-title>
				</ion-toolbar>
			</ion-header>
			<user-tracking-list></user-tracking-list>
			<ExploreContainer name="Tab 1 page"></ExploreContainer>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar
} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import AvatarMenu from "@/components/menu/AvatarMenu.vue";
import UserTrackingList from "@/components/user/tracking/UserTrackingList.vue";

// import {System} from "@/services/system.service";

export default {
	name: 'UserTrackingListTab',
	components: {
		UserTrackingList,
		AvatarMenu,
		ExploreContainer,
		IonHeader,
		IonToolbar,
		IonTitle,
		IonContent,
		IonPage,
	},
	setup(props: any) {
		console.log(props) // { user: '' }

		return {}
	}
}
</script>

<style>
</style>