<template>
	<div class="ion-padding-top ion-text-center">
		<ion-avatar class="center-profile-image">
			<img v-if="getUserImage" :src="getUserImage" alt="profileImage" class="profile-image-size">
			<img v-else src="https://www.gravatar.com/avatar?d=mm&s=140" alt="profileImage">
		</ion-avatar>
		<h2>{{ getFullName }}</h2>

		<ion-badge color="success"><span style="font-weight: bold">{{ currentPoints }}</span> Mobi Points</ion-badge>
		<p v-if="currentPoints === 0">Du hast noch keine Punkte gesammelt!<br>Starte gleich mit deinem ersten Tracking!
		</p>
		<p v-else>Mach weiter so! Du bist auf einen guten Weg!</p>
		<ion-list inset>
			<!--				<ion-item @click="updatePicture()">Profilbild aktualisieren</ion-item>-->
			<ion-item router-link="/mobipoints/transactions" router-direction="back">Mein Wallet</ion-item>
			<ion-item router-link="/profile/userLocation" router-direction="back">Standorte verwalten</ion-item>
			<ion-item>
				<ion-label>Dark Mode</ion-label>
				<ion-toggle color="dark" @ionChange="toggleDarkMode" :checked="darkMode"
							:value="darkMode"></ion-toggle>
			</ion-item>
			<ion-item>
				<ion-label>Tracking Auto Stop</ion-label>
				<ion-toggle color="dark" @ionChange="toggleTrackingAutoStop" :checked="trackingAutoStop"
							:value="trackingAutoStop"></ion-toggle>
			</ion-item>
			<ion-item>
				<ion-label><span v-html="trackingStopConfirmItemTitle"></span></ion-label>
				<ion-toggle color="dark" @ionChange="toggleTrackingStopConfirm" :checked="trackingStopConfirm"
							:value="trackingStopConfirm"></ion-toggle>
			</ion-item>
			<ion-item router-link="/redeem/voucher" router-direction="back">Gutschein einlösen</ion-item>
			<ion-item button @click="openTutorial()">Tutorial ansehen</ion-item>
			<!--				<ion-item router-link="/profile/userTrackingTemplate" router-direction="back" >Tracking Vorlagen verwalten</ion-item>-->
			<ion-item router-link="/privacy-crudafoch" router-direction="back">Datenschutz</ion-item>
			<ion-item router-link="/impress" router-direction="back">Impressum</ion-item>
			<ion-item router-link="/terms" router-direction="back">AGB</ion-item>
			<ion-item @click="logout()">Logout</ion-item>
			<div v-if="isDevelopmentEnv() || isDeveloper()">
				<ion-item @click="openLogs(true)">DEVELOPMENT MODE - LOGS</ion-item>
				<ion-modal
						:is-open="isLogsOpen"
						css-class="my-custom-class"
						:swipe-to-close="true"
						:keyboardClose="true"
						@didDismiss="openLogs(false)"
				>
					<ion-content class="ion-padding-top">
						<ion-buttons class="ion-padding-horizontal ion-padding-top ion-justify-content-between"
									 style="display: flex">
							<ion-button color="warning" class="ion-margin-start" @click="resetLog()">
								<ion-icon :icon="icons.trashOutline"></ion-icon>
								<ion-label style="margin-left: .25rem;">Logs leeren</ion-label>
								<ion-ripple-effect></ion-ripple-effect>
							</ion-button>
							<ion-button color="medium" class="ion-margin-start" @click="openLogs(false)">
								<ion-icon :icon="icons.close"></ion-icon>
								<ion-label style="margin-left: .25rem;">Close</ion-label>
								<ion-ripple-effect></ion-ripple-effect>
							</ion-button>
						</ion-buttons>
						<div v-for="(log, idx) in logsSortedDesc" :key="idx" class="ion-padding">
							<div v-if="log.message">
								<h4>Message</h4>
								<span>{{ log.message }}</span>
							</div>
							<div v-if="log.timestamp">
								<h4>Timestamp: </h4>
								<span>{{ dateHelper.formatTimestamp(log.timestamp) }}</span>
							</div>
							<div v-if="log.trace">
								<h4>Trace: </h4>
								<span>{{ log.trace }}</span>
							</div>
						</div>
					</ion-content>

				</ion-modal>
			</div>
		</ion-list>

		<div class="about-info">
			<h3 class="ion-padding-top ion-padding-start">über MobiPoints</h3>

			<p class="ion-padding-start ion-padding-end" v-html="aboutMobiPoints"></p>

			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 9px"
			   @click="enableDeveloperMode">
				Version: {{ appVersion }} - <span v-if="isNativePlatform()">Native</span><span v-else>Web</span>
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import {ref} from 'vue';
import {
	IonAvatar,
	IonItem,
	IonList,
	alertController,
	IonToggle,
	IonLabel,
	IonModal,
	IonIcon,
	IonButton,
	IonButtons,
	IonRippleEffect
} from '@ionic/vue';
import useUserRepository from "@/components/user/UserRepository.vue";
import router from "@/router";
import useCustomStore from "@/composable/useCustomStore";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import usePoints from "@/composable/usePoints";
import useSystem from "@/composable/useSystem";
import * as dateHelper from "@/mobipoints/core/date";
import * as icons from 'ionicons/icons';
import useTrackingFactory from "@/composable/useTrackingFactory";
import {getDateDiffInSeconds} from "@/mobipoints/core/date";
import useUserProfile from '@/composable/useUserProfile';
import useData from "@/composable/useData";

export default {
	name: 'UserProfile',
	components: {
		IonItem,
		IonList,
		IonAvatar,
		IonToggle,
		IonLabel,
		IonModal,
		IonButton, IonButtons, IonRippleEffect,
		IonIcon
	},
	props: {
		provider: {
			type: String,
			default: "google"
		}
	},
	setup(props: any) {
		const {getFullName, getUserImage, getUserEmail} = useUserRepository();
		const {setTutorialCompleted} = useCustomStore();
		const {setDarkModeEnabled, isDarkMode, isTrackingAutoStop, isTrackingStopConfirm, setTrackingAutoStop, setIsTrackingStopConfirm} = useUserProfile();
		const {currentPoints} = usePoints();
		const {
			appVersion,
			isDevelopmentEnv,
			isDeveloper,
			logs,
			logsSortedDesc,
			resetLogs,
			hasDeveloperMode,
			setDeveloperMode,
			isNativePlatform
		} = useSystem();

		const lastActiveDate = ref('');
		const developerModeLastTimestamp: any = ref(null);
		const developerModeCount = ref(0);
    const trackingStopConfirmItemTitle = ref(useData().getTextValueByKey('mobipoints.profile.trackingstop_confirm.item.title', [], 'Tracking beenden bestätigen'));
    const aboutMobiPoints = ref(useData().getTextValueByKey('mobipoints.about.description', [], 'Die Mobi Points sind ein Belohnungssystem um breitenwirksame Anreize für nachhaltige Mobilität und\n' +
			'ökologisches Handeln zu schaffen.\n' +
			'Wir wollen damit ein dauerhaftes Bewusstsein für eine nachhaltige Mobilität in der Gesellschaft schaffen.'));
		const darkMode = ref(isDarkMode.value);
		const trackingAutoStop = ref(isTrackingAutoStop.value);
		const trackingStopConfirm = ref(isTrackingStopConfirm.value);

		//DEVELOPMENT
		const isLogsOpen = ref(false);
		const openLogs = (state: boolean) => {
			isLogsOpen.value = state;
		};

		function openTutorial() {
			setTutorialCompleted(false);
			router.push('/tutorial')
		}

		function resetLog() {
			resetLogs();
		}

		function toggleDarkMode() {
			const toggleDarkMode = !isDarkMode.value;
			setDarkModeEnabled(toggleDarkMode);
			document.body.classList.toggle('dark', toggleDarkMode);
		}

		async function toggleTrackingAutoStop() {
			const toggleTrackingAutoStop = !isTrackingAutoStop.value;
			try {
				await setTrackingAutoStop(toggleTrackingAutoStop);
			} catch (e) {
				console.error(e);
			}
		}

		async function toggleTrackingStopConfirm() {
			const toggleTrackingStopConfirm = !isTrackingStopConfirm.value;
			try {
				await setIsTrackingStopConfirm(toggleTrackingStopConfirm);
			} catch (e) {
				console.error(e);
			}
		}

		async function logout() {
			const alert = await alertController
				.create({
					cssClass: 'my-custom-class',
					header: 'Ausloggen?!',
					message: 'Möchtest <strong>du</strong> dich wirklich ausloggen?',
					buttons: [
						{
							text: 'Abbrechen',
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button',
						},
						{
							text: 'Ja',
							id: 'confirm-button',
							handler: () => {
								const userAuth = new UserAuth();
								userAuth.logout();
							},
						},
					],
				});
			return alert.present();
		}

		function enableDeveloperMode() {
			if (isDeveloper()) {
				return;
			}
			const startTimestamp = useTrackingFactory().getTrackingFactory().createCurrentTimeStamp();
			if (!developerModeLastTimestamp.value) {
				developerModeLastTimestamp.value = startTimestamp
			}
			const dateDiffInSeconds = getDateDiffInSeconds(startTimestamp, developerModeLastTimestamp.value);
			if (dateDiffInSeconds > 10) {
				developerModeLastTimestamp.value = null;
				developerModeCount.value = 0;
			}
			if (developerModeCount.value === 10) {
				setDeveloperMode(true);
			}
			developerModeCount.value++;
		}

		return {
			getFullName,
			getUserImage,
			getUserEmail,
			lastActiveDate,
			logout,
			openTutorial,
			toggleDarkMode,
			isDarkMode,
			isTrackingAutoStop,
			toggleTrackingAutoStop,
      toggleTrackingStopConfirm,
      trackingStopConfirm,
			currentPoints,
			appVersion,
			isDevelopmentEnv,
			isDeveloper,
			openLogs,
			isLogsOpen,
			logs,
			logsSortedDesc,
			resetLog,
			dateHelper,
			icons,
			hasDeveloperMode,
			enableDeveloperMode,
			isNativePlatform,
			aboutMobiPoints,
			trackingAutoStop,
			darkMode,
      trackingStopConfirmItemTitle,
		}
	},
}
</script>

<style scoped>

.center-profile-image {
    margin: 0 auto;
}

ion-toolbar {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    --color: white;
}

ion-toolbar ion-button,
ion-toolbar ion-back-button,
ion-toolbar ion-menu-button {
    --color: white;
}

.about-header {
    position: relative;

    width: 100%;
    height: 30%;
}

.about-header .about-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
}

/*.about-header .madison {*/
/*    background-image: url(/assets/img/about/madison.jpg);*/
/*}*/

/*.about-header .austin {*/
/*    background-image: url(/assets/img/about/austin.jpg);*/
/*}*/

/*.about-header .chicago {*/
/*    background-image: url(/assets/img/about/chicago.jpg);*/
/*}*/

/*.about-header .seattle {*/
/*    background-image: url(/assets/img/about/seattle.jpg);*/
/*}*/

.about-info {
    margin-top: -10px;
    border-radius: 10px;
}

.about-info h3 {
    margin-top: 0;
}

.about-info ion-list {
    padding-top: 0;
}

.about-info p {
    line-height: 130%;

    color: var(--ion-color-dark);
}

.about-info ion-icon {
    margin-inline-end: 32px;
}

/*
 * iOS Only
 */

.ios .about-info {
    --ion-padding: 19px;
}

.ios .about-info h3 {
    font-weight: 700;
}

ion-toggle::part(track) {
  background: rgb(221, 40, 64);
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

ion-toggle.toggle-checked::part(track) {
  background: #2da63f;

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

ion-toggle::part(handle) {
  background: #dddddd;
}

ion-toggle.toggle-checked::part(handle) {
  background: #dddddd;
}
</style>
