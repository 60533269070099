import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f86cd46a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString($setup.favoriteTitle), 1),
    ($setup.isPlatform('ios') || $setup.isPlatform('android'))
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 0,
          height: 200,
          options: { slidesPerView: 2.2, loop: false, spaceBetween: 0 }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.favoriteImageMap, (favoriteTrack) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: favoriteTrack.type,
                onClick: ($event: any) => ($setup.triggerTrack(favoriteTrack.type))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, { class: "ion-activatable ripple-parent" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        class: "slide-image",
                        src: favoriteTrack.image,
                        alt: favoriteTrack.image
                      }, null, 8, ["src", "alt"]),
                      _createVNode(_component_ion_icon, {
                        icon: favoriteTrack.icon
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, {
                            class: _normalizeClass(['favorite-title',  $setup.isIosPlatform() ? 'ios-font-size' : ''])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(favoriteTrack.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_card_content),
                      _createVNode(_component_ion_ripple_effect)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_swiper, {
          key: 1,
          height: 200,
          "slides-per-view": 3.2,
          loop: false,
          "space-between": 5
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.favoriteImageMap, (favoriteTrack) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: favoriteTrack.type,
                onClick: ($event: any) => ($setup.triggerTrack(favoriteTrack.type))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        class: "slide-image",
                        src: favoriteTrack.image,
                        alt: favoriteTrack.image
                      }, null, 8, ["src", "alt"]),
                      _createVNode(_component_ion_icon, {
                        icon: favoriteTrack.icon
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(favoriteTrack.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_card_content)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }))
  ]))
}