import {Data} from "@/mobipoints/data/data/data.type";
import {DataGetResponse} from "@/mobipoints/api/data";
import {MobiPointsDataInterface} from "@/mobipoints/data/data/data.interface";
import {MobiPointsDataItemInterface} from "@/mobipoints/data/data/data_item.interface";

export class DataFactory {
	createData(type: string, items: MobiPointsDataItemInterface[]): Data {
		return new Data(type, items);
	}

	createDataListByApiResponse(response: DataGetResponse): Data[] {
		const list: Data[] = [];

		response.payload.forEach((data) => {
			list.push(this.createDataByApiResponse(data));
		});

		return list;
	}

	createDataByApiResponse(response: MobiPointsDataInterface): Data {
		return this.createData(response.type, response.items);
	}
}