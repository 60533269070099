<template>
	<span v-if="hasActiveTracking">{{getDistanceFormatted}} km</span>
</template>

<script lang="ts">
import {onMounted, watch} from "vue";
import useTracking from "@/composable/useTracking";
import useSystem from "@/composable/useSystem";

export default {
	name: 'TrackingDistance',
	components: {},
	props: {},
	setup() {
		const {hasActiveTracking, getDistanceFormatted, calculateDistance_for_CurrentTrack} = useTracking();
		const {appActiveState, addLog} = useSystem();

		watch(() => appActiveState.value, (newValue: boolean, oldValue: boolean) => {
			if (newValue) {
				addLog('calc distance, after active state changed!')
				calculateDistance_for_CurrentTrack();
			}
		});


		onMounted(() => {
			if (hasActiveTracking.value) {
				calculateDistance_for_CurrentTrack()
			}
		})

		return {
			getDistanceFormatted, hasActiveTracking
		}
	}
}
</script>

<style scoped>

</style>