<template>
	<div v-if="activeBannerNews && activeBannerNews.length > 0 || activeCardNews && activeCardNews.length > 0 || activePopupNews && activePopupNews.length > 0">
		<ion-list v-if="showBanner && activeBannerNews && activeBannerNews.length > 0">
			<ion-item v-for="(bannerNews, idx) in activeBannerNews" :key="idx" color="primary">
				<ion-label>
					<h3 v-html="bannerNews.title"></h3>
					<p v-html="bannerNews.description"/>
				</ion-label>
				<router-link :to="'/'+bannerNews.buttonLink" v-if="bannerNews.showButton && bannerNews.buttonType === 'internal'" >
					<ion-button :color="bannerNews.buttonColor" expand="block" class="ripple-parent">
						<span v-html="bannerNews.buttonText"/>
						<ion-ripple-effect></ion-ripple-effect>
					</ion-button>
				</router-link>

				<a :href="bannerNews.buttonLink" v-if="bannerNews.showButton && bannerNews.buttonType === 'external'" >
					<ion-button :color="bannerNews.buttonColor" expand="block" class="ripple-parent">
						<span v-html="bannerNews.buttonText"/>
						<ion-ripple-effect></ion-ripple-effect>
					</ion-button>
				</a>
			</ion-item>
		</ion-list>
		<div v-if="showCard && activeCardNews && activeCardNews.length > 0">
			<ion-card  v-for="(cardNews, idx) in activeCardNews" :key="idx" class="ion-margin">
				<img v-if="cardNews.image" class="slide-image favoriteImg" :src="cardNews.image" :alt="cardNews.image"/>
				<ion-card-header>
					<ion-card-title class="ion-align-items-center ion-justify-content-start"
									style="display:flex; color: var(--ion-color-warning);">
						<span v-html="cardNews.title"/>
					</ion-card-title>
				</ion-card-header>
				<ion-card-content>
					<p v-html="cardNews.description"/>

					<router-link :to="'/'+cardNews.buttonLink" v-if="cardNews.showButton && cardNews.buttonType === 'internal'" >
						<ion-button :color="cardNews.buttonColor" expand="block" class="ripple-parent">
							<span v-html="cardNews.buttonText"/>
							<ion-ripple-effect></ion-ripple-effect>
						</ion-button>
					</router-link>

					<a :href="cardNews.buttonLink" v-if="cardNews.showButton && cardNews.buttonType === 'external'" >
						<ion-button :color="cardNews.buttonColor" expand="block" class="ripple-parent">
							<span v-html="cardNews.buttonText"/>
							<ion-ripple-effect></ion-ripple-effect>
						</ion-button>
					</a>
				</ion-card-content>
			</ion-card>
		</div>
		<div v-if="showPopup && activePopupNews && activePopupNews.length > 0">
			<div v-for="(popupNews, idx) in activePopupNews" :key="idx">
				<div :class="popupNews.fullscreen ? 'back-drop-news-popup-fullscreen' : 'back-drop-news-popup-inline'">
				<ion-card class="ion-margin">
					<img v-if="popupNews.image" class="slide-image favoriteImg" :src="popupNews.image" :alt="popupNews.image"/>
					<ion-card-header>
						<ion-card-title class="ion-align-items-center ion-justify-content-start"
										style="display:flex; color: var(--ion-color-warning);">
              <span v-html="popupNews.title"/>
						</ion-card-title>
					</ion-card-header>
					<ion-card-content>
						<p v-html="popupNews.description"/>

						<router-link @click="popupNews.show=false" :to="'/'+popupNews.buttonLink" v-if="popupNews.showButton && popupNews.buttonType === 'internal'" >
							<ion-button :color="popupNews.buttonColor" expand="block" class="ripple-parent">
								<span v-html="popupNews.buttonText"/>
								<ion-ripple-effect></ion-ripple-effect>
							</ion-button>
						</router-link>

						<a :href="popupNews.buttonLink" v-if="popupNews.showButton && popupNews.buttonType === 'external'" >
							<ion-button :color="popupNews.buttonColor" expand="block" class="ripple-parent">
								<span v-html="popupNews.buttonText"/>
								<ion-ripple-effect></ion-ripple-effect>
							</ion-button>
						</a>
					</ion-card-content>
				</ion-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {onMounted, defineComponent} from "vue";
import {
	IonList,
	IonItem,
	IonLabel,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
} from '@ionic/vue';
import useNews from "@/composable/useNews";

export default defineComponent({
	name: 'NewsInfo',
	components: {
		IonList,
		IonItem,
		IonLabel,
		IonCard,
		IonCardHeader,
		IonCardTitle,
		IonCardContent,
	},
	props: {
		showBanner: {type: Boolean, default: true},
		showCard: {type: Boolean, default: true},
		showPopup: {type: Boolean, default: true},
		forceReload: {type: Boolean, default: true},
	},
	emits: [],
	setup(props) {
		const {news, activeBannerNews, activeCardNews, activePopupNews, initNews} = useNews();

		onMounted(() => {
			initNews(props.forceReload);
		});

		return {
			news,
			activeBannerNews,
			activeCardNews,
			activePopupNews,
		}
	}
})
</script>

<style scoped>
ion-card img {
  width: 100%;
  height: auto;
}

.back-drop-news-popup-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 100;
    backdrop-filter: blur(1em);
    background: rgba(var(--ion-color-dark-rgb), .15);
}

.back-drop-news-popup-inline {
    position: absolute;
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 99999;
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(0.5em);
    background: rgba(var(--ion-color-dark-rgb), .15);
    width: 100%;
}
</style>
