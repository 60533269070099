import useWalletFactory from "@/composable/useWalletFactory";
import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';
import {WalletList} from '@/mobipoints/wallet/wallet_list.type';
import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';

// const setUserChallengeState = (state: any, uuid: string, userChallengeState: string) => {
// 	const challenge = state.challenges.data.find((challenge) => uuid === challenge.uuid) || null;
// 	if (null !== challenge) {
// 		if (challenge.userChallenges.length < 1) {
// 			const userChallenge = {
// 				user_id: 0,
// 				challenge_uuid: uuid,
// 				state: {code: userChallengeState, name: userChallengeState},
// 				progress: 0
// 			};
// 			challenge.userChallenges.push(userChallenge);
// 		}
// 		challenge.userChallenges.forEach((userChallenge) => {
// 			userChallenge.state.code = userChallengeState;
// 		});
// 	}
// }

export interface SetWalletItemsInterface {
	wallet_uuid: string,
	walletItem_List: WalletItemList
}

const {getWalletFactory} = useWalletFactory();

const wallet = {
	namespaced: true,
	state: () => ({
		wallets: WalletList,
	}),
	mutations: {
		wallets(state: any, value: WalletList) {
			state.wallets = value;
		},
		resetWallets(state: any) {
			state.wallets = getWalletFactory().createEmptyWalletList();
		},
		setWalletItems(state: any, {wallet_uuid, walletItem_List}: SetWalletItemsInterface) {
			if (!wallet_uuid) {
				return;
			}

			if (state.wallets) {
				const index = state.wallets.findIndex((wallet: AbstractWallet) => {
					return wallet.uuid === wallet_uuid;
				});

				if (index !== -1) {
					state.wallets[index].items = walletItem_List;
				}
			}
		},
		// acceptChallenge(state: any, uuid: string) {
		// 	setUserChallengeState(state, uuid, USER_CHALLENGE_STATE_ACCEPTED);
		// 	console.log("accept challenge", uuid);
		// },
	},
	actions: {
		//update wallet items by wallet_uuid
		//update points
		// async acceptChallenge(context: any, uuid: string): Promise<string|null> {
		// 	try {
		// 		const response = await MobiPointsApiChallenge.acceptChallenge(uuid);
		// 		if (200 === response.status) {
		// 			context.commit('acceptChallenge', uuid);
		// 			return null;
		// 		} else {
		// 			return `Response answered with status ${response.status}!`;
		// 		}
		// 	} catch (error: any | AxiosError) {
		// 		return `Response fails with error: ${error.code || 'unknown'}!`;
		// 	}
		// },
	},
	getters: {
		wallets: (state: any): WalletList|undefined => {
			if (state.wallets instanceof WalletList) {
				return state.wallets;
			} else if (!(state.wallets instanceof WalletList)) {
				return getWalletFactory().createWalletListByResponse(state.wallets);
			} else {
				return undefined;
			}
		},
	}
}

export default wallet;