<template>
	<svg class="animated" id="freepik_stories-winners" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
		 version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
		<g id="freepik--Floor--inject-150" class="animable" style="transform-origin: 247.24px 368.78px;">
			<ellipse id="freepik--floor--inject-150" cx="247.24" cy="368.78" rx="220.56" ry="122"
					 style="fill: rgb(245, 245, 245); transform-origin: 247.24px 368.78px;" class="animable"></ellipse>
		</g>
		<g id="freepik--Shadow--inject-150" class="animable" style="transform-origin: 254.402px 376.359px;">
			<path id="freepik--shadow--inject-150"
				  d="M152,469.06,91.34,434a1.32,1.32,0,0,1,0-2.5l256-147.78a4.79,4.79,0,0,1,4.33,0l60.67,35a1.32,1.32,0,0,1,0,2.5l-81,46.75a1.32,1.32,0,0,0,0,2.5l3.79,2.19a1.32,1.32,0,0,1,0,2.5l-85.38,49.28a4.89,4.89,0,0,1-4.35,0l-3.9-2.17a4.89,4.89,0,0,0-4.35,0l-80.82,46.66A4.79,4.79,0,0,1,152,469.06Z"
				  style="fill: rgb(224, 224, 224); transform-origin: 251.84px 376.359px;" class="animable"></path>
			<g id="freepik--Shadows--inject-150" class="animable" style="transform-origin: 326.552px 415.613px;">
				<path d="M356,448.39a7.42,7.42,0,0,1-2.92-.58l-15-6.46a3.55,3.55,0,0,0-1.36-.25,3.77,3.77,0,0,0-.76.07l-17.74,3.47a8.17,8.17,0,0,1-1.56.15c-2.27,0-4.29-1-5.13-2.44a3.18,3.18,0,0,1,0-3.31l5.72-9.75-10.93-8.47A3.41,3.41,0,0,1,305,417c.62-1.81,2.79-3,5.41-3l18.8.14a2.91,2.91,0,0,0,1.69-.43l10.82-8.83a6.63,6.63,0,0,1,4.18-1.32c1.86,0,4.2.66,5.2,2.51l5.56,10.32a2.44,2.44,0,0,0,.95.34l17.89,3.21c2.37.43,4,1.82,4.15,3.62.14,1.28-.51,3-2.74,3.88l-15.29,6.24a1.72,1.72,0,0,0-.27.13l.24,10.5a3.49,3.49,0,0,1-1,2.49A6.49,6.49,0,0,1,356,448.39Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 342.257px 425.977px;" id="eljuk65o2a4vc"
					  class="animable"></path>
				<path d="M269.66,467.9a5.64,5.64,0,0,1-2.22-.44l-10.07-4.33a1.7,1.7,0,0,0-.64-.12,1.61,1.61,0,0,0-.39,0l-11.9,2.33a6.45,6.45,0,0,1-1.17.11,4.5,4.5,0,0,1-4-2,2.83,2.83,0,0,1,0-2.88l3.55-6L236,449.28a2.93,2.93,0,0,1-1.11-3.32c.51-1.51,2.19-2.48,4.26-2.48l12.6.09a1.55,1.55,0,0,0,.73-.14l7.24-5.91a5.07,5.07,0,0,1,3.22-1,4.49,4.49,0,0,1,4.07,2l3.63,6.74a1.43,1.43,0,0,0,.27.07l12,2.16c1.89.34,3.16,1.49,3.32,3a3.2,3.2,0,0,1-2.24,3.28l-10,4.09.14,6.6a2.94,2.94,0,0,1-.87,2.14A5,5,0,0,1,269.66,467.9Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 260.491px 452.207px;" id="elumydk2k42f"
					  class="animable"></path>
				<path d="M403.66,391.34a5.13,5.13,0,0,1-2-.41l-8.79-3.79a1.24,1.24,0,0,0-.47-.08l-.29,0-10.39,2a6.36,6.36,0,0,1-1.07.1,4.2,4.2,0,0,1-3.73-1.85,2.75,2.75,0,0,1,0-2.77l3-5.09L374.06,375a2.82,2.82,0,0,1-1.05-3.18,4,4,0,0,1,4-2.34l11,.08a1.27,1.27,0,0,0,.5-.08l6.3-5.15a4.71,4.71,0,0,1,3-1,4.16,4.16,0,0,1,3.77,1.91l3.13,5.8.11,0L415.24,373a3.43,3.43,0,0,1,3.11,2.85,3,3,0,0,1-2.12,3.12l-8.58,3.5.12,5.6a2.85,2.85,0,0,1-.83,2.06A4.67,4.67,0,0,1,403.66,391.34Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 395.615px 377.332px;" id="el1vor0dzcaj8"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--Plants--inject-150" class="animable" style="transform-origin: 253.853px 335.687px;">
			<g id="freepik--plants--inject-150" class="animable" style="transform-origin: 76.9918px 382.558px;">
				<path d="M107.8,405.69c-.25-1.32-3.26-33.49-17-52.67C73.9,329.29,60.23,326.6,52.53,335.38c-6.92,7.88,3.28,17.56,15.53,27.4,14.53,11.67,25.37,25.07,29.67,42.77Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 79.0437px 368.234px;" id="elv7uak3pq7ad"
					  class="animable"></path>
				<g id="elux61k30dszj">
					<path d="M107.8,405.69c-.25-1.32-3.26-33.49-17-52.67C73.9,329.29,60.23,326.6,52.53,335.38c-6.92,7.88,3.28,17.56,15.53,27.4,14.53,11.67,25.37,25.07,29.67,42.77Z"
						  style="opacity: 0.2; transform-origin: 79.0437px 368.234px;" class="animable"></path>
				</g>
				<path d="M101.54,403.53a.7.7,0,0,1-.75-.6c-5.44-37.7-34.05-59.75-41.42-63.58a.7.7,0,0,1-.29-1,.69.69,0,0,1,.94-.29c7.5,3.91,36.63,26.33,42.15,64.62a.69.69,0,0,1-.59.79Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 80.5763px 370.755px;" id="elp4xxx6ufjq"
					  class="animable"></path>
				<path d="M82.67,434.15c-1-.07-2-.17-2.91-.28-3.2-.37-6.54-.95-9.09-2.91s-4-5.73-2.41-8.53c.8-1.39,2.24-2.39,2.8-3.9.83-2.2-.55-4.71-2.48-6s-4.3-1.82-6.55-2.5a16.81,16.81,0,0,1-8.12-4.77c-2-2.43-2.92-6.06-1.45-8.88,1.06-2,3.24-3.65,3.17-5.94-.06-2.12-2.08-3.66-3.41-5.07-1.71-1.82-3.65-3.43-4.89-5.66a7.76,7.76,0,0,1-.46-7.25,8.88,8.88,0,0,1,4.75-3.74,23.43,23.43,0,0,1,12.53-1,43.36,43.36,0,0,1,12,4.23,58.62,58.62,0,0,1,19.37,15.07,42,42,0,0,1,8.11,15.74,63.17,63.17,0,0,1,2.4,18.64c-.19,4.24-.8,8.17-5,10.24C95.32,434.3,88.87,434.64,82.67,434.15Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 76.114px 400.814px;" id="elgsx4upkomt"
					  class="animable"></path>
				<g id="elxfyxibvh9sb">
					<g style="opacity: 0.3; transform-origin: 76.114px 400.814px;" class="animable">
						<path d="M82.67,434.15c-1-.07-2-.17-2.91-.28-3.2-.37-6.54-.95-9.09-2.91s-4-5.73-2.41-8.53c.8-1.39,2.24-2.39,2.8-3.9.83-2.2-.55-4.71-2.48-6s-4.3-1.82-6.55-2.5a16.81,16.81,0,0,1-8.12-4.77c-2-2.43-2.92-6.06-1.45-8.88,1.06-2,3.24-3.65,3.17-5.94-.06-2.12-2.08-3.66-3.41-5.07-1.71-1.82-3.65-3.43-4.89-5.66a7.76,7.76,0,0,1-.46-7.25,8.88,8.88,0,0,1,4.75-3.74,23.43,23.43,0,0,1,12.53-1,43.36,43.36,0,0,1,12,4.23,58.62,58.62,0,0,1,19.37,15.07,42,42,0,0,1,8.11,15.74,63.17,63.17,0,0,1,2.4,18.64c-.19,4.24-.8,8.17-5,10.24C95.32,434.3,88.87,434.64,82.67,434.15Z"
							  style="fill: rgb(255, 255, 255); transform-origin: 76.114px 400.814px;" id="el3im2dk31vyo"
							  class="animable"></path>
					</g>
				</g>
				<path d="M103.19,426.56a.69.69,0,0,0,.2,0,.59.59,0,0,0,.36-.76c-14.53-41.62-47.62-51.41-47.95-51.5a.6.6,0,0,0-.74.41.59.59,0,0,0,.41.73c9.09,2.57,35.26,16.69,47.16,50.75A.58.58,0,0,0,103.19,426.56Z"
					  style="fill: rgb(250, 250, 250); transform-origin: 79.4117px 400.422px;" id="elf27mow40has"
					  class="animable"></path>
				<path d="M89.51,401a.62.62,0,0,0,.49-.26.6.6,0,0,0-.15-.83c-8.66-6.09-23-4.78-30.49-1.9a.6.6,0,1,0,.43,1.11c7.2-2.78,21.08-4.07,29.38,1.77A.6.6,0,0,0,89.51,401Z"
					  style="fill: rgb(250, 250, 250); transform-origin: 74.5046px 398.296px;" id="elgbyc17gfae"
					  class="animable"></path>
			</g>
			<g id="freepik--plants--inject-150" class="animable" style="transform-origin: 426.658px 279.898px;">
				<g id="freepik--Plant--inject-150" class="animable" style="transform-origin: 417.822px 272.766px;">
					<path d="M392.38,286.67a31.32,31.32,0,0,0,1.26,3.8c1.42,3.61,3.22,7.14,3.72,11,.23,1.79.2,3.7,1.11,5.26,2.11,3.59,4.53.95,6-1.24,3.46-5.34,6.89-10.48,12.83-13.22,2.95-1.35,6.07-2.41,8.7-4.32s4.72-4.93,4.46-8.17c-.16-1.94-1.15-3.8-.94-5.74.26-2.43,2.35-4.27,4.53-5.36s4.61-1.71,6.65-3.06,3.66-3.78,3-6.14c-.88-3.35-5.67-4.81-5.91-8.27-.25-3.74,5.18-6.14,4.73-9.86-.82-6.82-10.81-4-14.4-2-3.17,1.8-6.11,4.11-9.57,5.28-4.36,1.47-9.68,1.25-12.78,4.66-3.53,3.9-2,10.12-3.8,15.07-2,5.59-8.12,9.06-9.75,14.78A14.35,14.35,0,0,0,392.38,286.67Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 417.822px 272.766px;" id="elywykijvkm6s"
						  class="animable"></path>
					<g id="el1o3lr7ve7iy">
						<path d="M392.38,286.67a31.32,31.32,0,0,0,1.26,3.8c1.42,3.61,3.22,7.14,3.72,11,.23,1.79.2,3.7,1.11,5.26,2.11,3.59,4.53.95,6-1.24,3.46-5.34,6.89-10.48,12.83-13.22,2.95-1.35,6.07-2.41,8.7-4.32s4.72-4.93,4.46-8.17c-.16-1.94-1.15-3.8-.94-5.74.26-2.43,2.35-4.27,4.53-5.36s4.61-1.71,6.65-3.06,3.66-3.78,3-6.14c-.88-3.35-5.67-4.81-5.91-8.27-.25-3.74,5.18-6.14,4.73-9.86-.82-6.82-10.81-4-14.4-2-3.17,1.8-6.11,4.11-9.57,5.28-4.36,1.47-9.68,1.25-12.78,4.66-3.53,3.9-2,10.12-3.8,15.07-2,5.59-8.12,9.06-9.75,14.78A14.35,14.35,0,0,0,392.38,286.67Z"
							  style="opacity: 0.2; transform-origin: 417.822px 272.766px;" class="animable"></path>
					</g>
					<path d="M399.69,301.65h0a.49.49,0,0,1-.47-.53c1.8-28.18,22.84-52.63,33.71-59.46a.5.5,0,0,1,.7.16.52.52,0,0,1-.16.7c-10.71,6.73-31.46,30.85-33.24,58.66A.5.5,0,0,1,399.69,301.65Z"
						  style="fill: rgb(255, 255, 255); transform-origin: 416.461px 271.615px;" id="elm3cua59h8wf"
						  class="animable"></path>
				</g>
				<g id="freepik--plant--inject-150" class="animable" style="transform-origin: 431.631px 294.461px;">
					<path d="M404,322.76c6-21.25,27.77-31.37,47.68-37.47,12-3.68,11.23-13.16,7.1-16.27-6.32-4.76-24.88-4.25-38.32,6.74s-18.6,28.91-18.72,40.79Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 431.631px 294.461px;" id="elxxlnqazg7"
						  class="animable"></path>
					<g id="elacwib95cc1">
						<path d="M404,322.76c6-21.25,27.77-31.37,47.68-37.47,12-3.68,11.23-13.16,7.1-16.27-6.32-4.76-24.88-4.25-38.32,6.74s-18.6,28.91-18.72,40.79Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 431.631px 294.461px;"
							  class="animable"></path>
					</g>
					<path d="M403.5,314.23h-.14a.51.51,0,0,1-.35-.63c7.87-28.28,35.53-42.48,48.81-42.48a.5.5,0,0,1,.5.51.5.5,0,0,1-.5.5c-13,0-40.1,13.95-47.84,41.74A.49.49,0,0,1,403.5,314.23Z"
						  style="fill: rgb(255, 255, 255); transform-origin: 427.655px 292.675px;" id="elij4qm0gk38"
						  class="animable"></path>
				</g>
			</g>
		</g>
		<g id="freepik--Stars--inject-150" class="animable" style="transform-origin: 326.523px 411.395px;">
			<g id="freepik--stars--inject-150" class="animable" style="transform-origin: 326.523px 411.395px;">
				<g id="freepik--Star--inject-150" class="animable" style="transform-origin: 342.212px 419.811px;">
					<path d="M310.46,404.93c-2.08,0-3.55.94-3.63,2-.19.39-.05,9.67,0,10a1.65,1.65,0,0,0,.7,1.07l8.94,5.6-3.22,5.48a1.28,1.28,0,0,0-.18.54h0v.09c-.05.87,0,9.63,0,9.87.1,1.3,2.38,2.32,4.75,1.86l17.73-3.47a5.8,5.8,0,0,1,3.3.3l15,6.47c2.39,1,5.78,0,5.74-1.69v-10.8c0-.68.55-1.33,1.51-1.72l15-6.26c1.15-.46,1.62-1.2,1.5-1.89s.2-7.91,0-9.51a1.32,1.32,0,0,0,0-.34h0c-.2-.73-1.06-1.4-2.48-1.66l-17.89-3.21a3.27,3.27,0,0,1-2.36-1.36l-5.56-10.33c-.88-1.64-4.64-2-6.36-.58l-10.81,8.83a4.78,4.78,0,0,1-3,.88Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 342.2px 419.822px;" id="el92roct10f4o"
						  class="animable"></path>
					<g id="el1hklv6ebb55">
						<path d="M310.46,404.93c-2.08,0-3.55.94-3.63,2-.19.39-.05,9.67,0,10a1.65,1.65,0,0,0,.7,1.07l8.94,5.6-3.22,5.48a1.28,1.28,0,0,0-.18.54h0v.09c-.05.87,0,9.63,0,9.87.1,1.3,2.38,2.32,4.75,1.86l17.73-3.47a5.8,5.8,0,0,1,3.3.3l15,6.47c2.39,1,5.78,0,5.74-1.69v-10.8c0-.68.55-1.33,1.51-1.72l15-6.26c1.15-.46,1.62-1.2,1.5-1.89s.2-7.91,0-9.51a1.32,1.32,0,0,0,0-.34h0c-.2-.73-1.06-1.4-2.48-1.66l-17.89-3.21a3.27,3.27,0,0,1-2.36-1.36l-5.56-10.33c-.88-1.64-4.64-2-6.36-.58l-10.81,8.83a4.78,4.78,0,0,1-3,.88Z"
							  style="opacity: 0.2; transform-origin: 342.2px 419.822px;" class="animable"></path>
					</g>
					<path d="M313.08,430c.25,1.23,2.43,2.16,4.72,1.71l17.74-3.46a6.12,6.12,0,0,1,2.09,0l.11,9.8a5.86,5.86,0,0,0-2.21,0l-17.73,3.47c-2.37.46-4.65-.56-4.74-1.86C313,439.33,313.16,431.69,313.08,430Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 325.393px 434.896px;" id="elihob6z7ggmf"
						  class="animable"></path>
					<g id="el5qfgo43fm93">
						<path d="M313.08,430c.25,1.23,2.43,2.16,4.72,1.71l17.74-3.46a6.12,6.12,0,0,1,2.09,0l.11,9.8a5.86,5.86,0,0,0-2.21,0l-17.73,3.47c-2.37.46-4.65-.56-4.74-1.86C313,439.33,313.16,431.69,313.08,430Z"
							  style="opacity: 0.1; transform-origin: 325.393px 434.896px;" class="animable"></path>
					</g>
					<path d="M313.25,429.05l6-10.24a1.41,1.41,0,0,0-.53-1.9l-11.2-8.67c-1.78-1.38-.05-3.33,2.92-3.31l18.73.14a4.78,4.78,0,0,0,3-.88L343,395.36c1.72-1.4,5.48-1.06,6.36.58l5.56,10.33a3.27,3.27,0,0,0,2.36,1.36l17.89,3.21c2.84.51,3.43,2.68,1,3.67l-15.29,6.25c-1,.39-1.53,1-1.51,1.72l.24,10.81c0,1.72-3.36,2.71-5.74,1.69l-15-6.47a5.81,5.81,0,0,0-3.3-.3l-17.74,3.47C315,432.23,312.29,430.68,313.25,429.05Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 342.246px 414.938px;" id="eld1sppjyoa9o"
						  class="animable"></path>
					<path d="M360.1,421.2h0a1.66,1.66,0,0,0-.74,1.28l.25,10.8c0,.82-.76,1.48-1.83,1.83l.06,9.7c1-.35,1.78-1,1.76-1.79v-10.8a1.44,1.44,0,0,1,.42-1h0a2.93,2.93,0,0,1,.66-.5,3.48,3.48,0,0,1,.43-.22l15-6.25c1.15-.48,1.61-1.21,1.5-1.9s.17-8.06,0-9.56c0,.63-.44,1.29-1.51,1.73l-15.28,6.24-.34.16,0,0Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 367.723px 428.8px;" id="ellh6jrlvfz8"
						  class="animable"></path>
					<g id="elozir5azym1n">
						<path d="M360.1,421.2h0a1.66,1.66,0,0,0-.74,1.28l.25,10.8c0,.82-.76,1.48-1.83,1.83l.06,9.7c1-.35,1.78-1,1.76-1.79v-10.8a1.44,1.44,0,0,1,.42-1h0a2.93,2.93,0,0,1,.66-.5,3.48,3.48,0,0,1,.43-.22l15-6.25c1.15-.48,1.61-1.21,1.5-1.9s.17-8.06,0-9.56c0,.63-.44,1.29-1.51,1.73l-15.28,6.24-.34.16,0,0Z"
							  style="opacity: 0.1; transform-origin: 367.723px 428.8px;" class="animable"></path>
					</g>
				</g>
				<g id="freepik--star--inject-150" class="animable" style="transform-origin: 260.452px 447.452px;">
					<path d="M239.14,437.48c-1.4,0-2.38.63-2.44,1.33-.12.26,0,6.48,0,6.7a1.15,1.15,0,0,0,.48.71l6,3.76L241,453.65a.9.9,0,0,0-.12.36h0v0c0,.58,0,6.46,0,6.62.07.87,1.6,1.56,3.19,1.25L256,459.61a3.9,3.9,0,0,1,2.21.2l10.06,4.34c1.6.69,3.87,0,3.85-1.13v-7.24c0-.46.37-.89,1-1.16l10.09-4.19c.77-.32,1.08-.81,1-1.27s.14-5.3,0-6.37a.85.85,0,0,0,0-.23h0c-.13-.49-.71-.93-1.66-1.11l-12-2.15a2.22,2.22,0,0,1-1.59-.91l-3.73-6.93c-.59-1.09-3.11-1.33-4.26-.38L253.69,437a3.14,3.14,0,0,1-2,.59Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 260.452px 447.457px;" id="elim6jk4r0vsr"
						  class="animable"></path>
					<g id="eli5me5q9qtb">
						<path d="M239.14,437.48c-1.4,0-2.38.63-2.44,1.33-.12.26,0,6.48,0,6.7a1.15,1.15,0,0,0,.48.71l6,3.76L241,453.65a.9.9,0,0,0-.12.36h0v0c0,.58,0,6.46,0,6.62.07.87,1.6,1.56,3.19,1.25L256,459.61a3.9,3.9,0,0,1,2.21.2l10.06,4.34c1.6.69,3.87,0,3.85-1.13v-7.24c0-.46.37-.89,1-1.16l10.09-4.19c.77-.32,1.08-.81,1-1.27s.14-5.3,0-6.37a.85.85,0,0,0,0-.23h0c-.13-.49-.71-.93-1.66-1.11l-12-2.15a2.22,2.22,0,0,1-1.59-.91l-3.73-6.93c-.59-1.09-3.11-1.33-4.26-.38L253.69,437a3.14,3.14,0,0,1-2,.59Z"
							  style="opacity: 0.2; transform-origin: 260.452px 447.457px;" class="animable"></path>
					</g>
					<path d="M240.89,454.26c.17.83,1.64,1.45,3.17,1.15L256,453.09a4.39,4.39,0,0,1,1.4,0l.08,6.57a3.88,3.88,0,0,0-1.48,0l-11.89,2.33c-1.59.31-3.12-.38-3.18-1.25C240.87,460.54,241,455.42,240.89,454.26Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 249.185px 457.55px;" id="el7235nex39dv"
						  class="animable"></path>
					<g id="els6z8pwzgz0l">
						<path d="M240.89,454.26c.17.83,1.64,1.45,3.17,1.15L256,453.09a4.39,4.39,0,0,1,1.4,0l.08,6.57a3.88,3.88,0,0,0-1.48,0l-11.89,2.33c-1.59.31-3.12-.38-3.18-1.25C240.87,460.54,241,455.42,240.89,454.26Z"
							  style="opacity: 0.1; transform-origin: 249.185px 457.55px;" class="animable"></path>
					</g>
					<path d="M241,453.65l4-6.86a1,1,0,0,0-.35-1.28l-7.51-5.81c-1.19-.92,0-2.24,2-2.22l12.56.09a3.14,3.14,0,0,0,2-.59l7.25-5.91c1.15-.95,3.67-.71,4.26.38l3.73,6.93a2.19,2.19,0,0,0,1.59.91l12,2.15c1.9.35,2.3,1.8.67,2.47l-10.25,4.18c-.65.27-1,.7-1,1.15l.16,7.25c0,1.16-2.24,1.82-3.84,1.13l-10.07-4.33a3.9,3.9,0,0,0-2.21-.2l-11.89,2.32C242.17,455.78,240.37,454.74,241,453.65Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 260.442px 444.189px;" id="elct6i1onvf7b"
						  class="animable"></path>
					<path d="M272.42,448.39h0a1.08,1.08,0,0,0-.5.86l.16,7.24c0,.55-.5,1-1.22,1.22l0,6.51c.69-.24,1.19-.66,1.18-1.2v-7.24a1,1,0,0,1,.29-.67h0a2,2,0,0,1,.44-.34,1.59,1.59,0,0,1,.29-.15l10.09-4.19c.77-.32,1.08-.81,1-1.27s.11-5.41,0-6.41c0,.42-.29.87-1,1.16l-10.25,4.18-.22.11h0Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 277.523px 453.485px;" id="el8fhuk5y0r2"
						  class="animable"></path>
					<g id="elu76j3tpia8">
						<path d="M272.42,448.39h0a1.08,1.08,0,0,0-.5.86l.16,7.24c0,.55-.5,1-1.22,1.22l0,6.51c.69-.24,1.19-.66,1.18-1.2v-7.24a1,1,0,0,1,.29-.67h0a2,2,0,0,1,.44-.34,1.59,1.59,0,0,1,.29-.15l10.09-4.19c.77-.32,1.08-.81,1-1.27s.11-5.41,0-6.41c0,.42-.29.87-1,1.16l-10.25,4.18-.22.11h0Z"
							  style="opacity: 0.1; transform-origin: 277.523px 453.485px;" class="animable"></path>
					</g>
				</g>
				<g id="freepik--star--inject-150" class="animable" style="transform-origin: 395.61px 373.187px;">
					<path d="M377,364.49c-1.22,0-2.08.55-2.13,1.17-.11.22,0,5.66,0,5.85a1,1,0,0,0,.41.62l5.24,3.28-1.89,3.21a1.11,1.11,0,0,0-.11.32h0v0c0,.51,0,5.64,0,5.78.06.77,1.4,1.36,2.78,1.09l10.39-2a3.51,3.51,0,0,1,1.94.17l8.79,3.79c1.4.6,3.38,0,3.36-1v-6.32c0-.4.33-.78.89-1l8.82-3.66c.67-.28.95-.71.87-1.11s.12-4.63,0-5.57a.44.44,0,0,0,0-.2h0c-.12-.43-.62-.82-1.45-1l-10.48-1.88a1.92,1.92,0,0,1-1.38-.8l-3.26-6c-.52-1-2.72-1.16-3.72-.34l-6.34,5.17a2.75,2.75,0,0,1-1.74.51Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 395.61px 373.187px;" id="el3pl8gr1o2bj"
						  class="animable"></path>
					<g id="elm1awnm81jj">
						<path d="M377,364.49c-1.22,0-2.08.55-2.13,1.17-.11.22,0,5.66,0,5.85a1,1,0,0,0,.41.62l5.24,3.28-1.89,3.21a1.11,1.11,0,0,0-.11.32h0v0c0,.51,0,5.64,0,5.78.06.77,1.4,1.36,2.78,1.09l10.39-2a3.51,3.51,0,0,1,1.94.17l8.79,3.79c1.4.6,3.38,0,3.36-1v-6.32c0-.4.33-.78.89-1l8.82-3.66c.67-.28.95-.71.87-1.11s.12-4.63,0-5.57a.44.44,0,0,0,0-.2h0c-.12-.43-.62-.82-1.45-1l-10.48-1.88a1.92,1.92,0,0,1-1.38-.8l-3.26-6c-.52-1-2.72-1.16-3.72-.34l-6.34,5.17a2.75,2.75,0,0,1-1.74.51Z"
							  style="opacity: 0.2; transform-origin: 395.61px 373.187px;" class="animable"></path>
					</g>
					<path d="M378.53,379.16c.15.72,1.43,1.26,2.77,1l10.39-2a3.57,3.57,0,0,1,1.22,0l.07,5.74a3.34,3.34,0,0,0-1.3,0l-10.39,2c-1.39.27-2.72-.33-2.77-1.09C378.51,384.64,378.58,380.17,378.53,379.16Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 385.75px 382.037px;" id="ellc5au3sc3rd"
						  class="animable"></path>
					<g id="elbradegattx">
						<path d="M378.53,379.16c.15.72,1.43,1.26,2.77,1l10.39-2a3.57,3.57,0,0,1,1.22,0l.07,5.74a3.34,3.34,0,0,0-1.3,0l-10.39,2c-1.39.27-2.72-.33-2.77-1.09C378.51,384.64,378.58,380.17,378.53,379.16Z"
							  style="opacity: 0.1; transform-origin: 385.75px 382.037px;" class="animable"></path>
					</g>
					<path d="M378.63,378.62l3.52-6a.81.81,0,0,0-.3-1.11l-6.56-5.08c-1.05-.81,0-1.95,1.71-1.94l11,.08a2.75,2.75,0,0,0,1.74-.51l6.34-5.17c1-.82,3.2-.62,3.72.34l3.26,6a1.92,1.92,0,0,0,1.38.8L414.89,368c1.66.3,2,1.57.59,2.15l-9,3.65c-.56.24-.9.61-.89,1l.14,6.34c0,1-2,1.59-3.36,1l-8.79-3.78a3.38,3.38,0,0,0-1.93-.18l-10.39,2C379.65,380.49,378.07,379.58,378.63,378.62Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 395.616px 370.376px;" id="elfgh5r0i84b6"
						  class="animable"></path>
					<path d="M406.07,374h0a1,1,0,0,0-.43.76l.14,6.32c0,.48-.44.87-1.07,1.07l0,5.69c.6-.21,1-.58,1-1.06v-6.32a.89.89,0,0,1,.25-.59h0a2.08,2.08,0,0,1,.39-.29,1.19,1.19,0,0,1,.25-.13l8.82-3.66c.67-.28.94-.71.88-1.11s.1-4.72,0-5.6c0,.37-.25.75-.88,1l-9,3.66-.19.09h0Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 410.522px 378.46px;" id="eljay4yvi0cv"
						  class="animable"></path>
					<g id="elxeeesfmo89d">
						<path d="M406.07,374h0a1,1,0,0,0-.43.76l.14,6.32c0,.48-.44.87-1.07,1.07l0,5.69c.6-.21,1-.58,1-1.06v-6.32a.89.89,0,0,1,.25-.59h0a2.08,2.08,0,0,1,.39-.29,1.19,1.19,0,0,1,.25-.13l8.82-3.66c.67-.28.94-.71.88-1.11s.1-4.72,0-5.6c0,.37-.25.75-.88,1l-9,3.66-.19.09h0Z"
							  style="opacity: 0.1; transform-origin: 410.522px 378.46px;" class="animable"></path>
					</g>
				</g>
			</g>
		</g>
		<g id="freepik--podium-3--inject-150" class="animable" style="transform-origin: 332.66px 313.439px;">
			<g id="freepik--Podium--inject-150" class="animable" style="transform-origin: 332.66px 313.439px;">
				<polygon points="324.94 368.19 324.94 347.92 403.93 302.32 403.93 322.59 324.94 368.19"
						 style="fill: rgb(255, 193, 0); transform-origin: 364.435px 335.255px;" id="elepudap369vc"
						 class="animable"></polygon>
				<g id="ellem28z7u58g">
					<polygon points="324.94 368.19 324.94 347.92 403.93 302.32 403.93 322.59 324.94 368.19"
							 style="opacity: 0.3; transform-origin: 364.435px 335.255px;" class="animable"></polygon>
				</g>
				<g id="elyz2wu9hpimn">
					<path d="M403.93,311.33v-9l-79,45.6L267.89,315v9l54.71,31.58a5.15,5.15,0,0,0,4.67,0Z"
						  style="opacity: 0.15; transform-origin: 335.91px 329.235px;" class="animable"></path>
				</g>
				<path d="M404.9,295.91a1.68,1.68,0,0,0-1-1.36l-61.15-35.3a5.15,5.15,0,0,0-4.68,0l-76.65,44.26a1.63,1.63,0,0,0-1,1.35V315a1.64,1.64,0,0,0,1,1.35l61.15,35.3a5.15,5.15,0,0,0,4.68,0l76.65-44.25a1.66,1.66,0,0,0,1-1.35Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 332.66px 305.45px;" id="el5ie7gdud9dx"
					  class="animable"></path>
				<g id="eltqqxr3a5osd">
					<path d="M404.9,295.91a1.68,1.68,0,0,0-1-1.36l-61.15-35.3a5.15,5.15,0,0,0-4.68,0l-76.65,44.26a1.63,1.63,0,0,0-1,1.35V315a1.64,1.64,0,0,0,1,1.35l61.15,35.3a5.15,5.15,0,0,0,4.68,0l76.65-44.25a1.66,1.66,0,0,0,1-1.35Z"
						  style="opacity: 0.2; transform-origin: 332.66px 305.45px;" class="animable"></path>
				</g>
				<path d="M403.93,294.55l-61.15-35.3a5.15,5.15,0,0,0-4.68,0l-76.65,44.26c-1.29.74-1.29,1.95,0,2.7l61.15,35.3a5.15,5.15,0,0,0,4.68,0l76.65-44.25C405.22,296.51,405.22,295.3,403.93,294.55Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 332.69px 300.38px;" id="elrkzkpt8vm5"
					  class="animable"></path>
				<g id="el6ulda78nmgg">
					<path d="M403.93,294.55l-61.15-35.3a5.15,5.15,0,0,0-4.68,0l-76.65,44.26c-1.29.74-1.29,1.95,0,2.7l61.15,35.3a5.15,5.15,0,0,0,4.68,0l76.65-44.25C405.22,296.51,405.22,295.3,403.93,294.55Z"
						  style="opacity: 0.1; transform-origin: 332.69px 300.38px;" class="animable"></path>
				</g>
			</g>
			<g id="freepik--shadow--inject-150">
				<ellipse cx="349.47" cy="296.28" rx="35.32" ry="20.39"
						 style="opacity: 0.07; transform-origin: 349.47px 296.28px;" class="animable"></ellipse>
			</g>
		</g>
		<g id="freepik--character-3--inject-150" class="animable" style="transform-origin: 355.598px 191.643px;">
			<g id="freepik--Character--inject-150" class="animable" style="transform-origin: 355.598px 191.643px;">
				<path d="M334.89,254.12l-14.61-.26c.22,9.75,1.77,20.57,2,29.73.05,1.72,0,13.38.61,16.93,1,5.32,7.3,4.6,7.63,1.41s-.4-16.8-.38-18.2c-.06-5.87,2.69-15.78,4.13-26.31C334.36,256.9,334.83,254.63,334.89,254.12Z"
					  style="fill: rgb(255, 189, 167); transform-origin: 327.585px 279.141px;" id="elk62vff4d8a"
					  class="animable"></path>
				<path d="M332.93,304.15c.13,3.13-.15,5.24-1.07,7.18a4.6,4.6,0,0,1-5.11,2.39c-2.16-.32-5-1.47-5.88-4.1a17.21,17.21,0,0,1-.75-7.41Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 326.481px 308.022px;" id="el7e2g105oacw"
					  class="animable"></path>
				<path d="M322.27,282.47c-.86.15-.7,4.36-.78,8.59-.09,4.54-1.24,6.53-1.35,10.25-.13,4.25.28,6.11,2,8.5s7.19,4.36,9.39.46c1.79-3.17,1.55-6.47.94-10.64A104.15,104.15,0,0,1,331.21,289c-.15-3.16-.24-6.23-1-6.5,0,.46,0,1.06,0,1.52s.07,2.06-.41,2.13a5.94,5.94,0,0,1-.13-1.19,1.49,1.49,0,0,0-.34-1,1.61,1.61,0,0,0-1-.33,23.05,23.05,0,0,0-2.63-.14c-.43,0-.87,0-1.3.07a3.63,3.63,0,0,0-1.09.27.79.79,0,0,0-.43.48,1.3,1.3,0,0,0,0,.48,3.17,3.17,0,0,1,0,.87c0,.13-.08.29-.21.27s-.16-.13-.17-.24c-.05-.5,0-.88-.06-1.47,0-.27,0-.65,0-.92S322.28,282.74,322.27,282.47Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 326.519px 297.476px;" id="elkr17zq1d8j"
					  class="animable"></path>
				<path d="M320.22,300.09c0,.62-.14,4.68,1,5.29s1.59-2.55,1.59-2.55,2.69-1.13,6.9.23c-.14.67.7,2.76,1.76,2.19s1-5.2,1-5.26c.57,4,.75,7.2-1,10.28-2.2,3.9-7.66,1.93-9.39-.46s-2.14-4.25-2-8.5C320.15,300.88,320.18,300.48,320.22,300.09Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 326.462px 306.236px;" id="eljj3yzpvjlx"
					  class="animable"></path>
				<path d="M323.19,303.07c.13,1.52-1.42,1.16-1.7-1s.38-7.34,0-11c.08-4.23-.08-8.44.78-8.59,0,0,0,.1,0,.15l.1,2.14c0,.31,0,.59,0,.91.29,3.1.46,9.13.47,12A48.77,48.77,0,0,0,323.19,303.07Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 322.308px 293.239px;" id="elt4klw8mopsg"
					  class="animable"></path>
				<path d="M329.27,303.07a46.94,46.94,0,0,0,.29-5.41c0-2.84.18-8.8.47-11.91a8,8,0,0,0,.13-1.76c0-.43,0-1,0-1.42,0,0,0-.07,0-.1.57.21.77,1.88.9,4,0,1.22,0,2.74-.12,4.59-.38,3.69.29,8.92,0,11.06S329.14,304.59,329.27,303.07Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 330.161px 293.24px;" id="el2xx2vyyb7a2"
					  class="animable"></path>
				<path d="M361.89,273.4c-.11-3,1.91-31.07,1.91-31.07L350,251.09c3,17.73,3.6,21.91,3.66,24.81h0a2.15,2.15,0,0,0-.08.75c0,3.44,3.9,5.3,6.93,7.6a57,57,0,0,0,8.21,5.35c2.2,1.11,6.67,1.21,7.23-.21-3.69-2.82-9.06-7-12-10.25C362.53,277.6,362,276.42,361.89,273.4Z"
					  style="fill: rgb(255, 189, 167); transform-origin: 362.975px 266.387px;" id="elrmnjje6fm69"
					  class="animable"></path>
				<path d="M382.22,293.1a2.67,2.67,0,0,1-.62,2.77,12.35,12.35,0,0,1-9.2,2.19c-4.61-.6-7.1-1.9-8.94-3.44s-4.42-3.72-6.5-4.18a12.45,12.45,0,0,1-4.66-1.9c-.62-.59-.53-3.25-.53-3.25Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 367.069px 291.753px;" id="eltgm0br9ffl"
					  class="animable"></path>
				<path d="M353.61,275.06a.75.75,0,0,0-.7.62c-.14.73-.3,2.86-.6,4.07a21.3,21.3,0,0,0-.45,6.45c.25,1.36,3.4,2.31,5.42,3.08a25.38,25.38,0,0,1,6.52,4.12,18.51,18.51,0,0,0,9.72,3.62c3.54,0,7.54-1.18,8.46-2.82,1.13-2,.24-3.22-5-5.66-1-.46-4.5-2.53-5.69-3.28-3.22-2-6.42-4.86-7.9-8.45a2,2,0,0,0-.76-1.1,1.62,1.62,0,0,0-1-.09,8.66,8.66,0,0,0-2.54.76c-.7.34-1.71.73-1.69,1.63a3.22,3.22,0,0,0,.2.92c.06.19.11.43-.05.54s-.36,0-.49-.14a12.92,12.92,0,0,1-1.42-1.7,5.56,5.56,0,0,0-.83-.85,9.38,9.38,0,0,1-.91-.58C353.54,275.88,353.62,275.48,353.61,275.06Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 367.1px 286.04px;" id="elj43bx2duu9"
					  class="animable"></path>
				<path d="M363.8,293.4a32.88,32.88,0,0,0-2.87-2.14h0s6.19,3.77,8.69,2.06a2.25,2.25,0,0,0,.81-2.87c0-.42,3.93-2.25,5.5-2.44h0c.43.24.8.44,1,.54,5.26,2.44,6.15,3.62,5,5.66-.92,1.64-3.81,2.93-8.4,2.93C370.58,297.13,366,295.18,363.8,293.4Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 371.652px 292.575px;" id="elriehteeuuv"
					  class="animable"></path>
				<path d="M370,289.2c3.27,1.31,1.87,1.92,1,2.05s-6.43-3.09-9.79-5.74-5.71-5.81-7-6.83c-.49-.38-1-.84-1.47-1.28.08-.72.15-1.37.22-1.72a.75.75,0,0,1,.7-.62c0,.42-.07.82.25,1.14a9.38,9.38,0,0,0,.91.58,5.56,5.56,0,0,1,.83.85,12.92,12.92,0,0,0,1.42,1.7C359.87,283,366.72,287.89,370,289.2Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 362.398px 283.157px;" id="el1dqwz7r84z4"
					  class="animable"></path>
				<path d="M370.72,290.77a.37.37,0,0,1-.2-.07.33.33,0,0,1,0-.46,7.82,7.82,0,0,1,6-2.36.32.32,0,0,1,.29.35.33.33,0,0,1-.36.29,7.18,7.18,0,0,0-5.43,2.13A.3.3,0,0,1,370.72,290.77Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 373.619px 289.323px;" id="el57ccmqrj1t5"
					  class="animable"></path>
				<path d="M367.87,289a.32.32,0,0,1-.25-.53,7.8,7.8,0,0,1,6-2.36.32.32,0,1,1-.07.64,7.24,7.24,0,0,0-5.42,2.13A.32.32,0,0,1,367.87,289Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 370.724px 287.55px;" id="elcgpa66qcu7d"
					  class="animable"></path>
				<path d="M365,287.35a.33.33,0,0,1-.25-.53,7.79,7.79,0,0,1,6-2.36.32.32,0,0,1,.29.35.33.33,0,0,1-.36.29,7.14,7.14,0,0,0-5.42,2.13A.34.34,0,0,1,365,287.35Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 367.862px 285.9px;" id="el5kq5oetr58n"
					  class="animable"></path>
				<path d="M362.37,285.45a.34.34,0,0,1-.21-.08.32.32,0,0,1,0-.45,7.75,7.75,0,0,1,6-2.36.31.31,0,0,1,.29.35.33.33,0,0,1-.36.29,7.19,7.19,0,0,0-5.42,2.13A.32.32,0,0,1,362.37,285.45Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 365.26px 284.001px;" id="el51wactx8v5v"
					  class="animable"></path>
				<path d="M359.78,283.55a.37.37,0,0,1-.2-.07.33.33,0,0,1-.06-.46,8,8,0,0,1,6.55-2.39.32.32,0,0,1,.29.35.33.33,0,0,1-.36.29,7.4,7.4,0,0,0-6,2.15A.33.33,0,0,1,359.78,283.55Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 362.907px 282.07px;" id="elertkgozry7k"
					  class="animable"></path>
				<path d="M331.87,150.52c-2,4.22-7.53,11.14-9.31,15.88-3.65,9.7-1.9,16.72-.85,29.48,1.13,13.67,2.21,24.48,2.21,24.48-.21,2.43-3.58,7.17-4,17.91a216.73,216.73,0,0,0,.69,22.79s7.58,4.2,13.19,0c0,0,5.41-25.82,7-38.39,1.38-11,3-19.81,3-19.81L348,223.21s-.31,5.51-.76,10.19c-.72,7.39,3.41,21.9,3.41,21.9s7.84,2.34,12.62-1.9c0,0,1.86-21.18,1.66-31.69-.22-12-2.3-52-3.78-69.11Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 342.405px 206.723px;" id="elhcqxg81b4v"
					  class="animable"></path>
				<g id="eljshedq1amaf">
					<path d="M331.87,150.52c-2,4.22-7.53,11.14-9.31,15.88-3.65,9.7-1.9,16.72-.85,29.48,1.13,13.67,2.21,24.48,2.21,24.48-.21,2.43-3.58,7.17-4,17.91a216.73,216.73,0,0,0,.69,22.79s7.58,4.2,13.19,0c0,0,5.41-25.82,7-38.39,1.38-11,3-19.81,3-19.81L348,223.21s-.31,5.51-.76,10.19c-.72,7.39,3.41,21.9,3.41,21.9s7.84,2.34,12.62-1.9c0,0,1.86-21.18,1.66-31.69-.22-12-2.3-52-3.78-69.11Z"
						  style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 342.405px 206.723px;"
						  class="animable"></path>
				</g>
				<g id="el264k5a8ry6i">
					<path d="M343.73,202.82l2.59-21.13a17.83,17.83,0,0,0,10.91-6.24s-1.44,5.07-8.45,7.79L345.39,202,348,223.23Z"
						  style="opacity: 0.2; transform-origin: 350.48px 199.34px;" class="animable"></path>
				</g>
				<path d="M329.84,303.18a.27.27,0,0,1-.12,0,10.39,10.39,0,0,0-6.76,0,.32.32,0,1,1-.23-.6,10.78,10.78,0,0,1,7.23,0,.33.33,0,0,1-.12.63Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 326.339px 302.583px;" id="elryu6bth9nwf"
					  class="animable"></path>
				<path d="M330.24,299.81l-.11,0a12.81,12.81,0,0,0-7.58,0,.32.32,0,0,1-.41-.2.32.32,0,0,1,.2-.41,13.15,13.15,0,0,1,8,0,.33.33,0,0,1,.19.41A.32.32,0,0,1,330.24,299.81Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 326.334px 299.203px;" id="elm4u483m516m"
					  class="animable"></path>
				<path d="M322.45,296.62a.34.34,0,0,1-.3-.19.33.33,0,0,1,.16-.43,11.14,11.14,0,0,1,8-.05.34.34,0,0,1,.18.42.33.33,0,0,1-.43.18s-4-1.56-7.47,0A.31.31,0,0,1,322.45,296.62Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 326.315px 295.926px;" id="elvccn8wifz3m"
					  class="animable"></path>
				<path d="M322.38,293.64a.32.32,0,0,1-.14-.61,10,10,0,0,1,8.14-.09.33.33,0,0,1,.15.43.32.32,0,0,1-.43.15,9.67,9.67,0,0,0-7.59.09A.31.31,0,0,1,322.38,293.64Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 326.307px 292.879px;" id="elq37qh06nkn9"
					  class="animable"></path>
				<path d="M322.31,290.3a.31.31,0,0,1-.29-.18.32.32,0,0,1,.14-.43c3.87-1.91,8-.09,8.21,0a.33.33,0,0,1,.16.43.32.32,0,0,1-.43.16s-4.07-1.77-7.65,0Z"
					  style="fill: rgb(235, 235, 235); transform-origin: 326.271px 289.566px;" id="eljpt4j5bwf6s"
					  class="animable"></path>
				<path d="M345.34,105.66c-1.13,0-2.28-.14-3.51-.22a17.44,17.44,0,0,1-16.26-17.39A18.6,18.6,0,0,1,350,70.39c.86.29,1.67.78,2.52,1s1.54.24,2.29.47a11.31,11.31,0,0,1,7.07,6.53c1.41,3.63.81,7.73-.36,11.45a27.5,27.5,0,0,1-5.84,10.58,18,18,0,0,1-6.92,4.65A9.49,9.49,0,0,1,345.34,105.66Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 344.135px 87.5568px;" id="el0cnh4y98yhc"
					  class="animable"></path>
				<path d="M334.82,103.2c-.2,1.86-.33,4.65-3.62,5.94,0,0,1.55,3.87-2.28,5.37,0,0,1.12,3.81-2.07,5.22a4.6,4.6,0,0,1-1.83,5.06s2,3.72-.43,5.87a5,5,0,0,1,1.25,2.85c.11,1.66-1,3.85-2.6,4.2a2.06,2.06,0,0,0,.19-2.58c-1-1.23-2-2.6-.46-4.28,0,0-3-1.78-.63-6.25,0,0-2.35-3.56,1.11-5.82,0,0-2-4,2-5.66,0,0-1.94-2.76,1.12-5.85A6.92,6.92,0,0,1,329,97.59Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 328.124px 117.65px;" id="ely972hvec988"
					  class="animable"></path>
				<path d="M353.63,110.84l3.6,15.7s11.59,14.89,14.89,17.54c1.75,1.4,5.69,3.3,9.36-2.09,4.22-6.22,13.67-24.82,14.45-26.07s1.34-2.28,2.1-3.4a31.5,31.5,0,0,0,2.22-3.7,13.53,13.53,0,0,0,1.57-6.15,8.3,8.3,0,0,0-2.29-5.82A11.81,11.81,0,0,1,397.66,95c-.2-.3-.34-.63-.56-.9a1.28,1.28,0,0,0-2.12.44,4.34,4.34,0,0,0-.3,1.28,23.36,23.36,0,0,1-3,8.8,1.22,1.22,0,0,1-.45.5c-.48.24-.66-.21-.72-.57a7.71,7.71,0,0,0-.67-2,4.43,4.43,0,0,0-1.56-1.86,1.43,1.43,0,0,0-.66-.24c-.94,0-.55,1-.49,1.55.12,1,.22,2,.3,2.93a27.69,27.69,0,0,1,.14,6.62c-.25,1.49-.95,3.43-3.42,7.14-2,3-8.34,12.23-8.34,12.23s-1.14-2-7.22-11.18-9.75-9.61-14.92-8.9"
					  style="fill: rgb(255, 189, 167); transform-origin: 377.725px 119.645px;" id="elq43yfwnayma"
					  class="animable"></path>
				<path d="M362.55,140.46c6-5.65,4.34-11.82,2.14-16.36-4.39-9.08-9.09-13.26-11.06-13.26H341.2c-5.94.7-10.12,1-11.56,1.28-3.66.8-4.14,3.52-4.35,7.4-.23,4.26.12,5.28,1.11,12.33l3.44,7.55c1.49,3.28,1.25,6.56.07,10a22.29,22.29,0,0,1-2.29,4.46A64.41,64.41,0,0,0,321,170.33a12.4,12.4,0,0,0,8.69-4s4.72,7.11,16.42,7.7,17.48-9.81,17.48-9.81l-2.51-13.5C361.58,148.29,362.19,143.77,362.55,140.46Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 343.849px 142.447px;" id="elj0drqp0qflk"
					  class="animable"></path>
				<g id="el77dbc7adnon">
					<path d="M337.67,137.14a9.71,9.71,0,0,0,9.16,5.23,10.25,10.25,0,0,0,8.76-5.35s-.34,7.14-8.78,7.06S337.67,137.14,337.67,137.14Z"
						  style="opacity: 0.1; transform-origin: 346.63px 140.55px;" class="animable"></path>
				</g>
				<path d="M355.29,77c2.06,1.15,4.7,6.2,4.71,16.93,0,9.09-2.88,11.41-4.35,12.09s-4.32.36-7.11,0l.13,5.46s7.87,8.19,1.52,9.15c-3.18.48-7.24-1.43-10-3.5a11.11,11.11,0,0,1-3.66-5.64l-.23-11.87s-1.59,1.63-4.42-.73c-2.33-1.94-3.18-5.29-1.44-7.14a4,4,0,0,1,6.36,1.11s1.63.1,3.18-3a19.9,19.9,0,0,0,8.6-1.6C355.87,85,356.09,79.32,355.29,77Z"
					  style="fill: rgb(255, 189, 167); transform-origin: 344.758px 98.8524px;" id="elgye6l8mgt5d"
					  class="animable"></path>
				<path d="M348.19,91.3a1.16,1.16,0,0,1-1.05,1.27,1.19,1.19,0,1,1,1.05-1.27Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 347.004px 91.3879px;" id="el6xflhzme6wa"
					  class="animable"></path>
				<path d="M349,99l3.73,1.09a1.91,1.91,0,0,1-2.37,1.38A2,2,0,0,1,349,99Z"
					  style="fill: rgb(240, 153, 122); transform-origin: 350.826px 100.271px;" id="elosjx9kd8ws"
					  class="animable"></path>
				<path d="M358.44,87.5,356.09,86a1.35,1.35,0,0,1,1.91-.44A1.47,1.47,0,0,1,358.44,87.5Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 357.362px 86.4174px;" id="el1yuivc64ics"
					  class="animable"></path>
				<path d="M346.9,86.63l-2.6,1.07a1.35,1.35,0,0,1,.71-1.83A1.49,1.49,0,0,1,346.9,86.63Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 345.535px 86.7345px;" id="elvk6n9ll0a7g"
					  class="animable"></path>
				<path d="M357.89,90.82a1.18,1.18,0,0,1-1.06,1.27,1.19,1.19,0,1,1,1.06-1.27Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 356.704px 90.9068px;" id="elkjhbmbv8nxr"
					  class="animable"></path>
				<polygon points="352.39 90.3 352.73 97.17 356.33 95.97 352.39 90.3"
						 style="fill: rgb(240, 153, 122); transform-origin: 354.36px 93.735px;" id="elmuzc4zusrj9"
						 class="animable"></polygon>
				<path d="M348.54,105.93c-2.93-.31-9-1.89-10-4.25a6.06,6.06,0,0,0,2.18,3c1.83,1.5,7.84,3.09,7.84,3.09Z"
					  style="fill: rgb(240, 153, 122); transform-origin: 343.55px 104.725px;" id="eltik0cdy0r3"
					  class="animable"></path>
				<path d="M336.81,92.77l.11,4.78h0a3.25,3.25,0,0,0,3.18-3.33L340,89.81Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 338.455px 93.68px;" id="el5f2jenv9sq"
					  class="animable"></path>
				<g id="freepik--Trophy--inject-150" class="animable" style="transform-origin: 363.721px 165.514px;">
					<path d="M347.25,179.26v7.64a.59.59,0,0,0,.34.47l13.81,8a1.72,1.72,0,0,0,.83.19V170.33l-14.64,8.45A.59.59,0,0,0,347.25,179.26Z"
						  style="fill: rgb(38, 50, 56); transform-origin: 354.74px 182.945px;" id="el1yqklfzhjgf"
						  class="animable"></path>
					<path d="M363.05,195.35l15.36-8.87a.59.59,0,0,0,.34-.47v-7.65a.59.59,0,0,0-.34-.47l-13.81-8a1.78,1.78,0,0,0-1.65,0l-.72.41v25.21A1.71,1.71,0,0,0,363.05,195.35Z"
						  style="fill: rgb(55, 71, 79); transform-origin: 370.49px 182.6px;" id="elyh29gxsq19t"
						  class="animable"></path>
					<path d="M361.4,187.71l-13.81-8a.5.5,0,0,1,0-.95L363,169.92a1.78,1.78,0,0,1,1.65,0l13.81,8a.5.5,0,0,1,0,1l-15.36,8.87A1.84,1.84,0,0,1,361.4,187.71Z"
						  style="fill: rgb(69, 90, 100); transform-origin: 363.103px 178.839px;" id="elik85gmfn8u"
						  class="animable"></path>
					<path d="M370.93,180.61c.08-.12.16-.25.23-.38l0-.1c.05-.11.1-.22.14-.33s0-.07,0-.11.08-.28.11-.42h0a3.22,3.22,0,0,0,0-.43v-.13a1.53,1.53,0,0,0,0-.3.78.78,0,0,0,0-.16c0-.1-.05-.19-.07-.29l-.05-.14a2,2,0,0,0-.17-.4.54.54,0,0,0-.07-.13L371,177l-.12-.17-.09-.12-.14-.17-.08-.08c-1.83-2.11-4.52-2.81-7.52-2.81s-5.7.71-7.53,2.81l-.06.07-.15.18a1.13,1.13,0,0,0-.1.13l-.11.15a2.48,2.48,0,0,0-.18.31.53.53,0,0,0-.06.1,3.65,3.65,0,0,0-.18.41l0,.13a1.57,1.57,0,0,0-.08.31.59.59,0,0,0,0,.14,1.66,1.66,0,0,0,0,.32.41.41,0,0,0,0,.11,3,3,0,0,0,.14.88l0,.09c.05.11.09.23.15.35l0,.09a4.23,4.23,0,0,0,.24.4v0c.1.14.22.28.34.42l0,0c.11.12.24.24.37.36l.09.08.39.31.1.07.56.36a13.35,13.35,0,0,0,12.06,0c.2-.12.38-.23.55-.35l.11-.09a3.8,3.8,0,0,0,.38-.29l.1-.09c.13-.12.25-.23.36-.35l0,0,.34-.42Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 363.005px 178.66px;" id="elp5622a3bd1q"
						  class="animable"></path>
					<g id="elat3m99vx834">
						<g style="opacity: 0.15; transform-origin: 363.005px 178.66px;" class="animable">
							<path d="M370.93,180.61c.08-.12.16-.25.23-.38l0-.1c.05-.11.1-.22.14-.33s0-.07,0-.11.08-.28.11-.42h0a3.22,3.22,0,0,0,0-.43v-.13a1.53,1.53,0,0,0,0-.3.78.78,0,0,0,0-.16c0-.1-.05-.19-.07-.29l-.05-.14a2,2,0,0,0-.17-.4.54.54,0,0,0-.07-.13L371,177l-.12-.17-.09-.12-.14-.17-.08-.08c-1.83-2.11-4.52-2.81-7.52-2.81s-5.7.71-7.53,2.81l-.06.07-.15.18a1.13,1.13,0,0,0-.1.13l-.11.15a2.48,2.48,0,0,0-.18.31.53.53,0,0,0-.06.1,3.65,3.65,0,0,0-.18.41l0,.13a1.57,1.57,0,0,0-.08.31.59.59,0,0,0,0,.14,1.66,1.66,0,0,0,0,.32.41.41,0,0,0,0,.11,3,3,0,0,0,.14.88l0,.09c.05.11.09.23.15.35l0,.09a4.23,4.23,0,0,0,.24.4v0c.1.14.22.28.34.42l0,0c.11.12.24.24.37.36l.09.08.39.31.1.07.56.36a13.35,13.35,0,0,0,12.06,0c.2-.12.38-.23.55-.35l.11-.09a3.8,3.8,0,0,0,.38-.29l.1-.09c.13-.12.25-.23.36-.35l0,0,.34-.42Z"
								  id="el46za2ibbval" class="animable"
								  style="transform-origin: 363.005px 178.66px;"></path>
						</g>
					</g>
					<path d="M363,175.85l-.37,0a.7.7,0,0,0-.69.44,45.06,45.06,0,0,0-1.83,5.44.73.73,0,0,0,.59.93,13.32,13.32,0,0,0,4.6,0,.74.74,0,0,0,.59-.93,48.85,48.85,0,0,0-1.83-5.44.7.7,0,0,0-.69-.44Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 362.999px 179.354px;" id="elxqhot05omsc"
						  class="animable"></path>
					<path d="M364.64,176.39a37.86,37.86,0,0,1,1.81,5.06.75.75,0,0,0,1,.52,8.36,8.36,0,0,0,2.22-1.27,1,1,0,0,0,.13-1.23,15.11,15.11,0,0,0-4-3.74,1.28,1.28,0,0,0-1.06-.22C364.48,175.61,364.45,176,364.64,176.39Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 367.236px 178.748px;" id="eliexp4c8qw0j"
						  class="animable"></path>
					<path d="M366.07,175.46a17.8,17.8,0,0,1,4,3.51c.29.34.69.32.79-.12a2,2,0,0,0,.07-.62,2.15,2.15,0,0,0-.74-1.34,10.28,10.28,0,0,0-3.92-2.12c-.43-.11-.78-.06-.81.06S365.7,175.22,366.07,175.46Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 368.195px 176.957px;" id="elex3khy4mfad"
						  class="animable"></path>
					<path d="M365.35,174.23c-.08-.1.09-.2.41-.22a3.82,3.82,0,0,1,.58,0,6.55,6.55,0,0,1,2.88,1.23,6.31,6.31,0,0,1,1.13,1.05c.25.32.13.37-.22.08a9.32,9.32,0,0,0-3.87-1.86A2.17,2.17,0,0,1,365.35,174.23Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 367.907px 175.28px;" id="els0bnj6f6mf"
						  class="animable"></path>
					<path d="M361.36,176.39a39.3,39.3,0,0,0-1.82,5.06.74.74,0,0,1-1,.52,8.32,8.32,0,0,1-2.21-1.27,1,1,0,0,1-.13-1.23,15,15,0,0,1,4-3.74,1.27,1.27,0,0,1,1.05-.22C361.52,175.61,361.55,176,361.36,176.39Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 358.764px 178.751px;" id="ellpq6bdf2f39"
						  class="animable"></path>
					<path d="M359.93,175.46a17.8,17.8,0,0,0-4,3.51c-.29.34-.7.32-.8-.12a2.42,2.42,0,0,1-.06-.62,2.15,2.15,0,0,1,.74-1.34,10.28,10.28,0,0,1,3.92-2.12c.43-.11.77-.06.81.06S360.3,175.22,359.93,175.46Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 357.806px 176.957px;" id="elh6t65vu4b7f"
						  class="animable"></path>
					<path d="M360.65,174.23c.08-.1-.09-.2-.41-.22a3.82,3.82,0,0,0-.58,0,6.55,6.55,0,0,0-2.88,1.23,6.31,6.31,0,0,0-1.13,1.05c-.25.32-.13.37.22.08a9.32,9.32,0,0,1,3.87-1.86A2.17,2.17,0,0,0,360.65,174.23Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 358.093px 175.28px;" id="el42tstj1z31"
						  class="animable"></path>
					<path d="M370.18,161.32h-.07a1.64,1.64,0,0,1-1.54-1.62c-.12-1.13.15-4.18,5.29-8.25,4.37-3.45,8-6.68,8.22-11.17.07-1.65-.54-3-1.54-3.33s-2.4.48-3.62,2.22a4,4,0,0,0-.76,2.91l.17-.07a.71.71,0,0,1,.62,1.28,1.43,1.43,0,0,1-1.84-.25c-.85-1-.34-3.26.65-4.68,2.31-3.3,4.43-3,5.24-2.75,1.62.56,2.6,2.42,2.49,4.74-.24,5.09-4.1,8.54-8.75,12.21-3.74,3-4.92,5.55-4.76,7,0,.16.06.35.18.36a1,1,0,0,0,.77-.83.71.71,0,1,1,1.39.25A2.34,2.34,0,0,1,370.18,161.32Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 376.024px 148.394px;" id="elguzr2lmim5b"
						  class="animable"></path>
					<g id="elkzb3ge4i0p">
						<g style="opacity: 0.2; transform-origin: 376.024px 148.394px;" class="animable">
							<path d="M370.18,161.32h-.07a1.64,1.64,0,0,1-1.54-1.62c-.12-1.13.15-4.18,5.29-8.25,4.37-3.45,8-6.68,8.22-11.17.07-1.65-.54-3-1.54-3.33s-2.4.48-3.62,2.22a4,4,0,0,0-.76,2.91l.17-.07a.71.71,0,0,1,.62,1.28,1.43,1.43,0,0,1-1.84-.25c-.85-1-.34-3.26.65-4.68,2.31-3.3,4.43-3,5.24-2.75,1.62.56,2.6,2.42,2.49,4.74-.24,5.09-4.1,8.54-8.75,12.21-3.74,3-4.92,5.55-4.76,7,0,.16.06.35.18.36a1,1,0,0,0,.77-.83.71.71,0,1,1,1.39.25A2.34,2.34,0,0,1,370.18,161.32Z"
								  id="elqdm35aa81f" class="animable"
								  style="transform-origin: 376.024px 148.394px;"></path>
						</g>
					</g>
					<path d="M347.26,144.73c0,7.71,2.66,11.65,5.74,16.32,3.65,5.53,8.51,9.38,8.73,13.27a.6.6,0,0,0,.37.52,2,2,0,0,0,1.81,0,.64.64,0,0,0,.37-.52h0c.08-3.89,5.07-7.74,8.72-13.27,3.08-4.67,5.75-8.55,5.75-16.32Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 363.005px 159.893px;" id="eljkwchzgows"
						  class="animable"></path>
					<g id="el1yawm0rf653">
						<path d="M347.26,144.84c0,7.63,2.68,11.57,5.74,16.21,3.65,5.53,8.51,9.38,8.73,13.27a.6.6,0,0,0,.37.52,1.51,1.51,0,0,0,.37.15,21.48,21.48,0,0,0-4-11.76,26.8,26.8,0,0,1-5.21-15.85s-3.38-2-4.93-2.65h-1S347.26,144.8,347.26,144.84Z"
							  style="opacity: 0.1; transform-origin: 354.865px 159.86px;" class="animable"></path>
					</g>
					<path d="M351.87,151.16c6.14,3.54,16.11,3.54,22.26,0s6.15-9.31,0-12.86-16.11-3.55-22.26,0S345.72,147.61,351.87,151.16Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 363px 144.726px;" id="elgi4h1dymll"
						  class="animable"></path>
					<g id="elzmnasb8s9w">
						<path d="M351.87,151.16c6.14,3.54,16.11,3.54,22.26,0s6.15-9.31,0-12.86-16.11-3.55-22.26,0S345.72,147.61,351.87,151.16Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 363px 144.726px;"
							  class="animable"></path>
					</g>
					<path d="M354,149.94c5,2.87,13.06,2.87,18,0s5-7.54,0-10.42-13.06-2.88-18,0S349,147.06,354,149.94Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 363px 144.726px;" id="el57efgpoweel"
						  class="animable"></path>
					<g id="eln7lmiovy62">
						<path d="M354,149.94c5,2.87,13.06,2.87,18,0s5-7.54,0-10.42-13.06-2.88-18,0S349,147.06,354,149.94Z"
							  style="opacity: 0.2; transform-origin: 363px 144.726px;" class="animable"></path>
					</g>
					<g id="elhj8maq9dhw">
						<path d="M357.83,138a14.94,14.94,0,0,0-3.85,1.52c-5,2.88-5,7.54,0,10.42a15.29,15.29,0,0,0,3,1.28C353.39,145.17,355.45,139.25,357.83,138Z"
							  style="opacity: 0.2; transform-origin: 354.03px 144.61px;" class="animable"></path>
					</g>
					<path d="M356.23,169.44a1.14,1.14,0,0,1-1.11-.94.71.71,0,0,1,1.27-.57,3,3,0,0,0,1.06-2,1.47,1.47,0,0,0-.4-1.35c-.34-.27-1.28-.59-3.68.28-3.23,1.17-6.06,2-7.87.85a4,4,0,0,1-1.55-3.38,12.08,12.08,0,0,1,3.91-8.8,3.81,3.81,0,0,1,3.43-.91,2.15,2.15,0,0,1,1.42,1.86,3.73,3.73,0,0,1-1.52,3.64.72.72,0,0,1-1-.19.73.73,0,0,1,.2-1,2.3,2.3,0,0,0,.91-2.24c-.06-.38-.22-.66-.4-.72a2.44,2.44,0,0,0-2.16.65,10.79,10.79,0,0,0-3.39,7.63,2.74,2.74,0,0,0,.92,2.27c1.26.83,4,0,6.61-1,2.31-.84,4-.87,5-.07a2.8,2.8,0,0,1,.94,2.61c-.15,1.6-1.41,3.25-2.55,3.33Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 351.403px 160.982px;" id="el1q6dniq8k7w"
						  class="animable"></path>
					<g id="ele7k1z35qj07">
						<g style="opacity: 0.3; transform-origin: 351.403px 160.982px;" class="animable">
							<path d="M356.23,169.44a1.14,1.14,0,0,1-1.11-.94.71.71,0,0,1,1.27-.57,3,3,0,0,0,1.06-2,1.47,1.47,0,0,0-.4-1.35c-.34-.27-1.28-.59-3.68.28-3.23,1.17-6.06,2-7.87.85a4,4,0,0,1-1.55-3.38,12.08,12.08,0,0,1,3.91-8.8,3.81,3.81,0,0,1,3.43-.91,2.15,2.15,0,0,1,1.42,1.86,3.73,3.73,0,0,1-1.52,3.64.72.72,0,0,1-1-.19.73.73,0,0,1,.2-1,2.3,2.3,0,0,0,.91-2.24c-.06-.38-.22-.66-.4-.72a2.42,2.42,0,0,0-2.16.66,10.75,10.75,0,0,0-3.39,7.62,2.74,2.74,0,0,0,.92,2.27c1.26.83,4,0,6.61-1,2.31-.84,4-.87,5-.07a2.8,2.8,0,0,1,.94,2.61c-.15,1.6-1.41,3.25-2.55,3.33Z"
								  id="elawu2nb24i0r" class="animable"
								  style="transform-origin: 351.403px 160.982px;"></path>
						</g>
					</g>
				</g>
				<path d="M330.24,112c-6.28,0-8.37,2.36-10.43,7.45-2,4.89-8.52,23-9.86,27.58s-.31,5.71,2.85,7.46c2.14,1.18,5.24,2.52,7.93,3.68,1.08.47,7.47,3,9.66,3.91a35.94,35.94,0,0,1,5.44,3.12c2,1.23,3.73,3.1,6,3.65a5.86,5.86,0,0,0,2.58-.16,7.8,7.8,0,0,0,3.93-1.81c1.19-1.12,1.31-3.21,1.5-4.77a6.44,6.44,0,0,0-.31-3.46,7.53,7.53,0,0,0-1.48-2c-.18-.2-.4-.54-.66-.47,0,0-.31.46-.41.64s-.4.72-.59,1.12-.22.58-.5.69a.74.74,0,0,1-.92-.4c-.16-.33,0-.69.19-1a7.41,7.41,0,0,1,.44-.84c.14-.21.63-1,.63-1a2.17,2.17,0,0,0-.65-.16,13.57,13.57,0,0,0-4.37.56,5.37,5.37,0,0,1-1.57.29,6,6,0,0,1-1-.28,14.26,14.26,0,0,1-2.6-1.12c-3.08-1.59-12.85-8-12.85-8L331.55,128S334.45,114.93,330.24,112Z"
					  style="fill: rgb(255, 189, 167); transform-origin: 329.662px 140.463px;" id="el54nxxum79ow"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--podium-2--inject-150" class="animable" style="transform-origin: 256.905px 339.454px;">
			<g id="freepik--podium--inject-150" class="animable" style="transform-origin: 256.905px 339.454px;">
				<polygon points="245.92 418.87 245.92 358.06 329.32 309.92 329.32 370.73 245.92 418.87"
						 style="fill: rgb(255, 193, 0); transform-origin: 287.62px 364.395px;" id="ellbkg6ajzfer"
						 class="animable"></polygon>
				<g id="ell02z0mutfoh">
					<polygon points="245.92 418.87 245.92 358.06 329.32 309.92 329.32 370.73 245.92 418.87"
							 style="fill: rgb(255, 255, 255); opacity: 0.2; transform-origin: 287.62px 364.395px;"
							 class="animable"></polygon>
				</g>
				<polygon points="184.49 322.59 184.49 383.39 245.92 418.87 245.92 358.06 184.49 322.59"
						 style="fill: rgb(255, 193, 0); transform-origin: 215.205px 370.73px;" id="el6i8ktshof7m"
						 class="animable"></polygon>
				<g id="el6yqz1a4m1w5">
					<polygon points="184.49 322.59 184.49 383.39 245.92 418.87 245.92 358.06 184.49 322.59"
							 style="opacity: 0.15; transform-origin: 215.205px 370.73px;" class="animable"></polygon>
				</g>
				<g id="elp71vzz9lpne">
					<path d="M329.32,318.93v-9l-83.4,48.14-61.43-35.47v9l59.09,34.12a4.85,4.85,0,0,0,2.34.56,4.79,4.79,0,0,0,2.34-.56Z"
						  style="opacity: 0.1; transform-origin: 256.905px 338.105px;" class="animable"></path>
				</g>
				<path d="M336.73,299.79a1.63,1.63,0,0,0-1-1.35L270.23,260.6a5.15,5.15,0,0,0-4.68,0l-87.5,50.51a1.63,1.63,0,0,0-1,1.35v10.13a1.66,1.66,0,0,0,1,1.35l65.53,37.83a5.15,5.15,0,0,0,4.68,0l87.5-50.5a1.63,1.63,0,0,0,1-1.35Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 256.905px 311.185px;" id="elpkje7ydlnn"
					  class="animable"></path>
				<g id="elnq9r1qd8k9i">
					<path d="M177.08,312.46v10.13a1.66,1.66,0,0,0,1,1.35l65.53,37.83a4.74,4.74,0,0,0,2.34.56l4.41-92.94-72.28,41.72A1.63,1.63,0,0,0,177.08,312.46Z"
						  style="opacity: 0.1; transform-origin: 213.72px 315.861px;" class="animable"></path>
				</g>
				<g id="elpbc3gho9qef">
					<path d="M248.26,361.77l87.5-50.5a1.63,1.63,0,0,0,1-1.35V299.79a1.63,1.63,0,0,0-1-1.35L270.23,260.6a5.15,5.15,0,0,0-4.68,0l-15.22,8.79-4.41,92.94A4.68,4.68,0,0,0,248.26,361.77Z"
						  style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 291.34px 311.185px;"
						  class="animable"></path>
				</g>
				<path d="M335.76,298.44,270.23,260.6a5.15,5.15,0,0,0-4.68,0l-87.5,50.51c-1.29.74-1.29,1.95,0,2.7l65.53,37.83a5.15,5.15,0,0,0,4.68,0l87.5-50.5C337.05,300.39,337.05,299.18,335.76,298.44Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 256.905px 306.12px;" id="elkhml4cze0p"
					  class="animable"></path>
			</g>
			<g id="freepik--shadow--inject-150">
				<ellipse cx="253.35" cy="308.59" rx="37.32" ry="21.54"
						 style="opacity: 0.07; transform-origin: 253.35px 308.59px;" class="animable"></ellipse>
			</g>
		</g>
		<g id="freepik--character-2--inject-150" class="animable" style="transform-origin: 253.904px 168.721px;">
			<g id="freepik--character--inject-150" class="animable" style="transform-origin: 253.904px 168.721px;">
				<path d="M279.63,89.38c-.35-6-.47-8.93-.81-11.48a27.05,27.05,0,0,0-1.2-5.42,5.37,5.37,0,0,0-.5-1.24,4.29,4.29,0,0,0-.72-.69,4.35,4.35,0,0,0-2.22-1,9.95,9.95,0,0,0-5.63,1.24,4.17,4.17,0,0,0-1.67,2,3,3,0,0,0-.13,1.58,2.35,2.35,0,0,0,.77,1.22,4.89,4.89,0,0,1,1.4,2.55,10.75,10.75,0,0,0-2.29-1.91,2.1,2.1,0,0,0-2.82.41c-.24.38.76.92,1.74,2.47,1.27,2,1.38,3.51,3.26,5.26A3.66,3.66,0,0,1,270,86.64c.53,5.3-1.82,15.92-2.72,20.65-1.36,7.12-13.66,22.79-18.35,25.17-3.82,6.17,4.75,15,10.47,14.95,3.93-8.56,12.73-17.77,18.55-31a21.19,21.19,0,0,0,1.69-7.48A149.54,149.54,0,0,0,279.63,89.38Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 263.968px 108.465px;" id="elgq0v9e3c357"
					  class="animable"></path>
				<path d="M276.37,71.21a1,1,0,0,1,.79.43c.21.38.07.71.09,1.1s.24.9.29,1.34c.13,1,.24,2.56-.61,3.25-.26.21-.6.3-.86.5s-.48.57-.76.82a3,3,0,0,1-1.49.58c-.48.09-1,.16-1.46.27s-.79.31-1.21.4a4.56,4.56,0,0,1-2.73-.23,3.76,3.76,0,0,1-2.17-3.87c.07-.57.27-1.12.37-1.69a6.7,6.7,0,0,1,.12-1.42c.25-.65,1.27-.86,1.86-1a21.14,21.14,0,0,1,3.36-.42c1-.06,2-.08,3-.08A9.83,9.83,0,0,1,276.37,71.21Z"
					  style="fill: rgb(242, 143, 143); transform-origin: 271.926px 75.5923px;" id="el08nqu6jmjo2f"
					  class="animable"></path>
				<g id="freepik--trophy--inject-150" class="animable" style="transform-origin: 285.444px 56.5327px;">
					<path d="M286.19,88.41,293.47,95a.73.73,0,0,0,.75.08l19.57-6.25a2.19,2.19,0,0,0,.9-.61l-24-21.84L286,87.67A.74.74,0,0,0,286.19,88.41Z"
						  style="fill: rgb(38, 50, 56); transform-origin: 300.329px 80.7643px;" id="el6a1yav35k1p"
						  class="animable"></path>
					<path d="M315.21,87.29,320.07,65a.74.74,0,0,0-.16-.74l-7.28-6.62a.73.73,0,0,0-.75-.08l-19.56,6.25a2.34,2.34,0,0,0-1.43,1.57l-.22,1,24,21.84A2.12,2.12,0,0,0,315.21,87.29Z"
						  style="fill: rgb(55, 71, 79); transform-origin: 305.389px 72.8558px;" id="elfeqpj615vzh"
						  class="animable"></path>
					<path d="M306.51,82.24l-19.57,6.25c-.64.21-1.05-.16-.9-.82l4.85-22.31a2.34,2.34,0,0,1,1.43-1.57l19.56-6.25c.65-.21,1.05.16.91.82l-4.86,22.31A2.33,2.33,0,0,1,306.51,82.24Z"
						  style="fill: rgb(69, 90, 100); transform-origin: 299.413px 73.015px;" id="el7djcfnx15li"
						  class="animable"></path>
					<path d="M308,67a5,5,0,0,0-.16-.55.58.58,0,0,1,0-.14c-.06-.14-.13-.28-.2-.42a.83.83,0,0,0-.07-.13,2.85,2.85,0,0,0-.31-.46h0a2.46,2.46,0,0,0-.38-.41l-.12-.11c-.1-.09-.21-.16-.31-.24l-.17-.11-.34-.18-.17-.08a3.13,3.13,0,0,0-.54-.19L305,64l-.41-.08-.27,0-.19,0h-.43c-3.59-.08-6.59,1.87-9.19,4.74s-4.27,6-3.84,9.6a.57.57,0,0,0,0,.13l0,.29a1.6,1.6,0,0,0,.05.21,1.55,1.55,0,0,0,.05.23,2.83,2.83,0,0,0,.13.44.83.83,0,0,0,0,.15,4.12,4.12,0,0,0,.24.52l.09.15a4,4,0,0,0,.23.34l.11.14c.09.11.19.21.29.31l.1.09a3.44,3.44,0,0,0,1,.62l.11,0,.47.17.12,0a5.6,5.6,0,0,0,.59.12h0a5.37,5.37,0,0,0,.7,0h.05a5.12,5.12,0,0,0,.67,0l.16,0c.2,0,.41-.06.63-.1l.15,0c.27-.06.54-.13.82-.22a17.19,17.19,0,0,0,10.45-11.5c.06-.29.11-.56.14-.83a1.1,1.1,0,0,0,0-.18c0-.21,0-.41,0-.61v-.18c0-.22,0-.43,0-.64a.17.17,0,0,0,0-.07c0-.24-.06-.47-.1-.69C308,67.06,308,67,308,67Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 299.313px 73.0495px;" id="eliw1p0ohlxo"
						  class="animable"></path>
					<g id="el3mkbs8jmqx5">
						<g style="opacity: 0.15; transform-origin: 299.313px 73.0495px;" class="animable">
							<path d="M308,67a5,5,0,0,0-.16-.55.58.58,0,0,1,0-.14c-.06-.14-.13-.28-.2-.42a.83.83,0,0,0-.07-.13,2.85,2.85,0,0,0-.31-.46h0a2.46,2.46,0,0,0-.38-.41l-.12-.11c-.1-.09-.21-.16-.31-.24l-.17-.11-.34-.18-.17-.08a3.13,3.13,0,0,0-.54-.19L305,64l-.41-.08-.27,0-.19,0h-.43c-3.59-.08-6.59,1.87-9.19,4.74s-4.27,6-3.84,9.6a.57.57,0,0,0,0,.13l0,.29a1.6,1.6,0,0,0,.05.21,1.55,1.55,0,0,0,.05.23,2.83,2.83,0,0,0,.13.44.83.83,0,0,0,0,.15,4.12,4.12,0,0,0,.24.52l.09.15a4,4,0,0,0,.23.34l.11.14c.09.11.19.21.29.31l.1.09a3.44,3.44,0,0,0,1,.62l.11,0,.47.17.12,0a5.6,5.6,0,0,0,.59.12h0a5.37,5.37,0,0,0,.7,0h.05a5.12,5.12,0,0,0,.67,0l.16,0c.2,0,.41-.06.63-.1l.15,0c.27-.06.54-.13.82-.22a17.19,17.19,0,0,0,10.45-11.5c.06-.29.11-.56.14-.83a1.1,1.1,0,0,0,0-.18c0-.21,0-.41,0-.61v-.18c0-.22,0-.43,0-.64a.17.17,0,0,0,0-.07c0-.24-.06-.47-.1-.69C308,67.06,308,67,308,67Z"
								  id="el56jw19v9duv" class="animable"
								  style="transform-origin: 299.313px 73.0495px;"></path>
						</g>
					</g>
					<path d="M296.59,70.45l-.33.34a.88.88,0,0,0-.19,1,60.43,60.43,0,0,0,3.6,6.46,1,1,0,0,0,1.4.25,15.37,15.37,0,0,0,2.18-2,15,15,0,0,0,1.81-2.37,1,1,0,0,0-.38-1.37,60.91,60.91,0,0,0-6.77-3,.9.9,0,0,0-1,.28A4.68,4.68,0,0,1,296.59,70.45Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 300.591px 74.1954px;" id="elf5czb9dq55t"
						  class="animable"></path>
					<path d="M298.52,69.36a47.52,47.52,0,0,1,6.4,2.65,1,1,0,0,0,1.35-.48,11.17,11.17,0,0,0,.71-3.22,1.24,1.24,0,0,0-1.06-1.19,19.8,19.8,0,0,0-7.06.6,1.69,1.69,0,0,0-1.13.82C297.64,68.83,298,69.18,298.52,69.36Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 302.348px 69.5716px;" id="elgcg7fy4zu2g"
						  class="animable"></path>
					<path d="M298.88,67.19a23.18,23.18,0,0,1,6.83-.8c.57,0,.9-.38.57-.85a2.7,2.7,0,0,0-.53-.61,2.79,2.79,0,0,0-1.92-.45,13.25,13.25,0,0,0-5.41,1.9c-.48.31-.73.69-.65.82S298.33,67.33,298.88,67.19Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 302.083px 65.8762px;" id="elqz3vf5ccb8l"
						  class="animable"></path>
					<path d="M297.09,66.81c-.16,0-.12-.27.14-.59a5,5,0,0,1,.51-.54A8.52,8.52,0,0,1,301.41,64a9,9,0,0,1,2-.17c.52.05.46.2-.11.28a12,12,0,0,0-5.13,2.09A3,3,0,0,1,297.09,66.81Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 300.381px 65.3184px;" id="elt2clom31q2"
						  class="animable"></path>
					<path d="M295.69,72.48a47.82,47.82,0,0,0,3.24,6.11,1,1,0,0,1-.35,1.39,10.55,10.55,0,0,1-3.13,1,1.23,1.23,0,0,1-1.29-.94,19.53,19.53,0,0,1-.07-7.08,1.66,1.66,0,0,1,.71-1.2C295.08,71.65,295.46,72,295.69,72.48Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 296.44px 76.3616px;" id="eleesi5f9qh1e"
						  class="animable"></path>
					<path d="M293.56,73a22.72,22.72,0,0,0-.14,6.87c.08.56-.3.93-.8.65a3.69,3.69,0,0,1-.66-.47,2.88,2.88,0,0,1-.63-1.87,13.42,13.42,0,0,1,1.38-5.57c.27-.51.62-.79.76-.72S293.65,72.47,293.56,73Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 292.469px 76.2475px;" id="eltmh7sp6whvr"
						  class="animable"></path>
					<path d="M293,71.28c0-.15-.28-.08-.57.21a4,4,0,0,0-.5.56,8.46,8.46,0,0,0-1.32,3.81,8.16,8.16,0,0,0,0,2c.09.52.24.44.27-.13a11.9,11.9,0,0,1,1.59-5.3A2.92,2.92,0,0,0,293,71.28Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 291.774px 74.7054px;" id="elnrwdwz6p5y"
						  class="animable"></path>
					<path d="M289,51l-.06.06a2.11,2.11,0,0,1-2.88.06c-1.17-.85-3.85-3.76-3.27-12.18.49-7.16.57-13.41-3.53-17.5-1.5-1.51-3.32-2.08-4.5-1.43s-1.63,2.7-1,5.38a5.06,5.06,0,0,0,2.11,3.24,1.2,1.2,0,0,0,.08-.22.91.91,0,1,1,1.75.51,1.82,1.82,0,0,1-1.82,1.54c-1.69-.05-3.41-2.5-3.9-4.67-1.15-5.07,1-6.85,1.92-7.38,1.93-1.06,4.55-.38,6.67,1.74,4.64,4.63,4.58,11.31,4.06,18.91-.42,6.14,1,9.5,2.53,10.59.16.13.39.25.5.14a1.25,1.25,0,0,0-.13-1.45.91.91,0,0,1,1.45-1.11A3,3,0,0,1,289,51Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 280.653px 34.7876px;" id="el0uqaf11cnwi"
						  class="animable"></path>
					<g id="elzxozhpys9c">
						<g style="opacity: 0.2; transform-origin: 280.653px 34.7876px;" class="animable">
							<path d="M289,51l-.06.06a2.11,2.11,0,0,1-2.88.06c-1.17-.85-3.85-3.76-3.27-12.18.49-7.16.57-13.41-3.53-17.5-1.5-1.51-3.32-2.08-4.5-1.43s-1.63,2.7-1,5.38a5.06,5.06,0,0,0,2.11,3.24,1.2,1.2,0,0,0,.08-.22.91.91,0,1,1,1.75.51,1.82,1.82,0,0,1-1.82,1.54c-1.69-.05-3.41-2.5-3.9-4.67-1.15-5.07,1-6.85,1.92-7.38,1.93-1.06,4.55-.38,6.67,1.74,4.64,4.63,4.58,11.31,4.06,18.91-.42,6.14,1,9.5,2.53,10.59.16.13.39.25.5.14a1.25,1.25,0,0,0-.13-1.45.91.91,0,0,1,1.45-1.11A3,3,0,0,1,289,51Z"
								  id="elxb7ic0wmikm" class="animable"
								  style="transform-origin: 280.653px 34.7876px;"></path>
						</g>
					</g>
					<path d="M253.3,58.48c7.34,6.68,13.41,7.56,20.53,8.67,8.43,1.32,16.31,0,20.2,3.19a.83.83,0,0,0,.82.1,2.59,2.59,0,0,0,1.57-1.73.8.8,0,0,0-.17-.8h0c-3.64-3.46-3-11.55-5.09-19.81-1.78-7-3.17-12.89-10.58-19.62Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 274.881px 49.4927px;" id="elyyk3gd6m8g"
						  class="animable"></path>
					<g id="el9trnz6gzmzg">
						<path d="M253.41,58.58c7.29,6.59,13.34,7.47,20.42,8.57,8.43,1.32,16.31,0,20.2,3.19a.83.83,0,0,0,.82.1,1.86,1.86,0,0,0,.47-.23,27.64,27.64,0,0,0-14.71-6.34A34.46,34.46,0,0,1,261,55.1s-4.84,1.48-6.8,2.4l-.88,1Z"
							  style="opacity: 0.1; transform-origin: 274.32px 62.8027px;" class="animable"></path>
					</g>
					<path d="M263.42,59.66c8.7-2.78,17.34-12.28,19.28-21.21s-3.53-13.92-12.24-11.14-17.35,12.28-19.29,21.21S254.71,62.44,263.42,59.66Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 266.935px 43.485px;" id="eltsmed9vtf1i"
						  class="animable"></path>
					<g id="elcdkyhbfy5hb">
						<path d="M263.42,59.66c8.7-2.78,17.34-12.28,19.28-21.21s-3.53-13.92-12.24-11.14-17.35,12.28-19.29,21.21S254.71,62.44,263.42,59.66Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 266.935px 43.485px;"
							  class="animable"></path>
					</g>
					<path d="M264.08,56.59c7.06-2.25,14.06-10,15.63-17.19s-2.86-11.28-9.92-9-14.05,10-15.63,17.19S257,58.85,264.08,56.59Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 266.933px 43.4913px;" id="elx9pbliuevkn"
						  class="animable"></path>
					<g id="elv79367ed7d">
						<path d="M264.08,56.59c7.06-2.25,14.06-10,15.63-17.19s-2.86-11.28-9.92-9-14.05,10-15.63,17.19S257,58.85,264.08,56.59Z"
							  style="opacity: 0.2; transform-origin: 266.933px 43.4913px;" class="animable"></path>
					</g>
					<g id="elsnvz4wev12q">
						<path d="M256,42.58a19.51,19.51,0,0,0-1.88,5c-1.58,7.23,2.87,11.28,9.92,9a18.93,18.93,0,0,0,3.8-1.73C259,53,255.17,45.93,256,42.58Z"
							  style="opacity: 0.2; transform-origin: 260.821px 49.8978px;" class="animable"></path>
					</g>
					<path d="M284.62,71.35a1.46,1.46,0,0,1-1.86.23.9.9,0,0,1-.35-1.24.92.92,0,0,1,.91-.46,3.87,3.87,0,0,0-.94-2.7,2,2,0,0,0-1.64-.8c-.56.1-1.68.72-2.93,3.75-1.68,4.1-3.29,7.56-6,8.24-1.42.36-3-.13-4.56-1.45a15.51,15.51,0,0,1-5-11.35,4.87,4.87,0,0,1,2.1-4,2.75,2.75,0,0,1,3,.25,4.82,4.82,0,0,1,2.15,4.61.91.91,0,0,1-1.8-.27,3,3,0,0,0-1.35-2.81c-.41-.27-.83-.37-1-.24a3.1,3.1,0,0,0-1.25,2.62,13.88,13.88,0,0,0,4.32,9.83c1.15.95,2.12,1.31,3,1.09,1.89-.47,3.39-3.8,4.77-7.16,1.2-2.93,2.64-4.57,4.29-4.85A3.62,3.62,0,0,1,283.73,66c1.39,1.54,1.87,4.17,1,5.32A.27.27,0,0,0,284.62,71.35Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 273.708px 69.8616px;" id="el0895a7lo8oih"
						  class="animable"></path>
					<g id="elap9dng254rn">
						<g style="opacity: 0.3; transform-origin: 273.708px 69.8616px;" class="animable">
							<path d="M284.62,71.35a1.46,1.46,0,0,1-1.86.23.9.9,0,0,1-.35-1.24.92.92,0,0,1,.91-.46,3.87,3.87,0,0,0-.94-2.7,2,2,0,0,0-1.64-.8c-.56.1-1.68.72-2.93,3.75-1.68,4.1-3.29,7.56-6,8.24-1.42.36-3-.13-4.56-1.45a15.51,15.51,0,0,1-5-11.35,4.87,4.87,0,0,1,2.1-4,2.75,2.75,0,0,1,3,.25,4.82,4.82,0,0,1,2.15,4.61.91.91,0,0,1-1.8-.27,3,3,0,0,0-1.35-2.81c-.41-.27-.83-.37-1-.24a3.1,3.1,0,0,0-1.25,2.62,13.88,13.88,0,0,0,4.32,9.83c1.15.95,2.12,1.31,3,1.09,1.88-.47,3.39-3.8,4.77-7.16,1.2-2.93,2.64-4.57,4.29-4.85A3.62,3.62,0,0,1,283.73,66c1.39,1.54,1.87,4.17,1,5.32Z"
								  id="eluqw9as3z6i" class="animable"
								  style="transform-origin: 273.708px 69.8616px;"></path>
						</g>
					</g>
				</g>
				<path d="M221.72,103.67c-1.7,3.66-2.35,7.71-3.28,11.61a13.73,13.73,0,0,1-6.09,9c-1.89,1.23-4,2.14-5.89,3.33a11.39,11.39,0,0,0-4.63,4.8,5.56,5.56,0,0,0,3.52,7.79,9,9,0,0,0-1.35,7.33,10.65,10.65,0,0,0,7.09,7.49,19.6,19.6,0,0,0,8.82.46,28.59,28.59,0,0,0,24.19-25.48A28.13,28.13,0,0,0,244,123c-.35-2.4-.2-4.81-.57-7.24a28.43,28.43,0,0,0-4.09-11.54A20.17,20.17,0,0,0,233,98.11c-4.27-2.68-8.9,1-10.84,4.69C222,103.09,221.86,103.38,221.72,103.67Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 222.771px 126.486px;" id="elftgul5wh3fj"
					  class="animable"></path>
				<path d="M260.41,180.06c2.56,11.23,3.37,14.32,5.39,21A311.2,311.2,0,0,1,273,235.74c.43,2.95-2,10.23-3.09,13-6.31,16.19-16.74,42-16.74,42a13.91,13.91,0,0,0-.89,6.91,36.67,36.67,0,0,0,3,10.1,27.23,27.23,0,0,0,1.64,3.29c1.44,3.2-.59,3.76-3.84,2.32-3.42-1.51-5.59-5.14-6.53-7.76-1.39-3.92-4.06-10.08-4-12.07l0,0,.06-.22h0l.5-3.93s2.59-12.14,4.58-26.06c1.77-12.38,2.83-16.46,8.47-25.39L233.61,190.4Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 253.33px 247.085px;" id="elyp30aju1kfk"
					  class="animable"></path>
				<path d="M238.26,296.5a4.27,4.27,0,0,0-.74,2.33c.57,2.89,3.7,4.91,4.95,6.72s2.56,5.78,3.38,8.14a8.24,8.24,0,0,0,.7,1.47,13.47,13.47,0,0,0,.7,1.23,8,8,0,0,0,2.86,1.93,13.86,13.86,0,0,0,6.78,1.16,8,8,0,0,0,4.85-1.74,2.08,2.08,0,0,0,.82-1.94c-.21-.78-1.07-1.16-1.83-1.41-1.61-.53-3.24-1-4.82-1.6a14.7,14.7,0,0,1-3.67-2,20.75,20.75,0,0,1-2.77-2.85c-1.8-2-3.62-4.05-5.46-6C243.65,301.52,238.72,296,238.26,296.5Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 250.049px 307.997px;" id="eln13ijlnahk"
					  class="animable"></path>
				<path d="M240.49,291.71c-1,1.47-3.24,3.75-2.18,6.31.92,2.23,3.21,4.35,4.73,6.11a11.41,11.41,0,0,1,1.64,3.2c.83,1.85,1.33,3.83,2.17,5.67,1,2.28,3.65,3.64,6,4.17A16.61,16.61,0,0,0,260,317a4,4,0,0,0,2.46-1.22,2.82,2.82,0,0,0,.31-2.63,6.09,6.09,0,0,0-1.59-2.24c-1.05-1-2.25-1.86-3.32-2.85-2-1.82-2.68-4.12-3.46-6.61-.55-1.75-1.36-4.27-1.1-5.44a6.2,6.2,0,0,0,.26-1.77,1.11,1.11,0,0,0-.68-.81,5.7,5.7,0,0,0-2.85-.23c-.84,0-1.66.28-2.49.36a4.19,4.19,0,0,1-4.37-4.19l.22-.82C242.54,287.91,241.57,290.05,240.49,291.71Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 250.497px 302.957px;" id="elr88nk7qyq2n"
					  class="animable"></path>
				<path d="M270,215.22S262.52,185,260.19,179l-27.61,10.63,23,48.36c-1.88,1.82-6.18,9.82-7.2,17.82s-4.09,27-4.09,27a9.3,9.3,0,0,0,5.06,4c3.79,1.44,5.45,0,5.45,0s12-28.73,15-36.19,4.52-9.95,3.59-16.52S271.61,223.76,270,215.22Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 253.121px 233.225px;" id="elvdqhwy174lf"
					  class="animable"></path>
				<path d="M251.9,203.61a16.3,16.3,0,0,0,8-10.4,18,18,0,0,1-9.22,7.32l-3,20.72,7.95,16.69-6-16.6Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 253.79px 215.575px;" id="elmvfbyzrkpk"
					  class="animable"></path>
				<path d="M210.3,199.64a5.64,5.64,0,0,0-2.55-2.47,6.31,6.31,0,0,0-6.38,2c-2,2.36-2.38,5.67-3.63,7.48s-4.6,4.26-6.52,5.85a11.78,11.78,0,0,0-3.48,9.37,5,5,0,0,0,3,4.43Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 199px 211.633px;" id="elqebdnpwvzoq"
					  class="animable"></path>
				<path d="M209.44,198.66c-1.59-.75-4.48-.3-6.51,1.59-1.76,1.64-3,4.52-4.07,6.56a11.37,11.37,0,0,1-2.42,2.66c-1,1-3.66,3-4.61,4.06a11.79,11.79,0,0,0-1.82,3,11.25,11.25,0,0,0-1,3.68c-.13,2,.4,4.91,1.88,6.24a3.61,3.61,0,0,0,5.61-.63c.47-.68.94-1.34,1.37-2.05a21.66,21.66,0,0,1,2.91-3.66,25.06,25.06,0,0,1,4.76-3.65c.19-.11.38-.24.57-.33,2.11-1,4.27-1.87,4.57-2.63s.81-7.41,2.48-8.84c.44-.37.21-1-.19-1.6S211,199.42,209.44,198.66Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 201.195px 212.912px;" id="el7lb9qsz5wmj"
					  class="animable"></path>
				<path d="M227,176.32c-3.06,9.17-4,14.33-3.82,25.18.13,7.12,1.22,23.89,1.87,32.05s.72,14.24,3.82,16.82,9.77,1.78,12.17-3.59,4.23-11.84,5.84-21.77A76.29,76.29,0,0,0,248,208.77l1-26.83Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 236.079px 214.053px;" id="el0mxi0ym7kwb"
					  class="animable"></path>
				<path d="M233.24,251.77c-3.58.28-5.92-1-11.23-9.3s-16.4-30.4-16.4-30.4-1.54-4.22,1.39-7.18c3.3-3.33,5.44-1.54,5.44-1.54s2.82,3.55,6,7c2.9,3.18,6.6,6.26,9.12,9.25C229.31,221.69,233.24,251.77,233.24,251.77Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 219.223px 227.337px;" id="elxx9z4itknl"
					  class="animable"></path>
				<path d="M223.81,215.71s-1.63-13.1-1.11-22.24A52.73,52.73,0,0,1,227,176.32l34.71,6.9c-.29,11.28-11,17.31-11,17.31l-4.11,27.93c-3.52,17.27-5.29,22.78-12.36,24s-11.09-7.51-14-11.93-9.47-18.42-9.47-18.42a10.75,10.75,0,0,1,3.58-6.87,12.35,12.35,0,0,1,6-2.85Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 236.24px 214.448px;" id="el831p6ws81lm"
					  class="animable"></path>
				<path d="M223.81,215.71s1.1,20.15,2,27.27a69.73,69.73,0,0,1-3.21-14.88,118.33,118.33,0,0,1-1.07-14.55Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 223.67px 228.265px;" id="elnb1h4w8a5me"
					  class="animable"></path>
				<path d="M259.13,169.62c.14-2.38,0-6.34,0-7.52,5.94-5.68,4.18-11.79,1.94-16.28-4.47-9-8.75-14-12.7-13.84l-10.8.93c-5.9.77-9.92,1.49-11.35,1.82-3.62.83-3.49,3.47-3.65,7.33A41.07,41.07,0,0,0,223.1,154l5.1,10.69,1,5.43c-1.11,2.41-6,9.72-6.79,20.8,0,0,8.4,2.87,21.43,3,13.65.12,19.35-5.83,19.35-5.83Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 242.774px 162.949px;" id="el95yl33vgtt"
					  class="animable"></path>
				<g id="elz2rvnn1i02f">
					<path d="M234.36,159.1a9.66,9.66,0,0,0,9.17,5.09,10.18,10.18,0,0,0,8.64-5.42s-.26,7.1-8.64,7.12S234.36,159.1,234.36,159.1Z"
						  style="opacity: 0.1; transform-origin: 243.265px 162.33px;" class="animable"></path>
				</g>
				<path d="M256.41,103.08c1.53-3.7,2.38-10.92-2-12.88-1.7-.76-3.66-.26-5.46.17a61.54,61.54,0,0,1-7.62,1.29c-8,.83-13.32,2.1-16.19,10.54-1.82,5.35-4.61,12.73-1.45,17.41,3.32,4.92,8.93,7.66,16.87,6.92,0,.52,4.38-.4,4.81-.53,2.23-.63,4.26-3.26,5.56-5.07a31.55,31.55,0,0,0,1.77-2.81c.46-.81.75-1.66,1.15-2.47a24.41,24.41,0,0,1,1.92-2.53,12.94,12.94,0,0,0,1.54-2.86c.73-2,.93-5.42-1.08-6.82A3,3,0,0,0,256.41,103.08Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 240.041px 108.247px;" id="elxb25rkxpq2k"
					  class="animable"></path>
				<path d="M249.92,98.51c2.12,1,5.08,5.83,5.83,16.47.63,9-2.08,11.51-3.48,12.28s-4.27.65-7.06.45l.51,5.4s8.36,7.58,2.13,9c-3.12.69-7.27-.93-10.15-2.79a11.09,11.09,0,0,1-4-5.35l-1-11.74s-1.47,1.72-4.44-.42c-2.44-1.77-3.51-5-1.92-7a4,4,0,0,1,6.39.66s1.62,0,2.95-3.16a12.28,12.28,0,0,0,7.56-2.45A16.92,16.92,0,0,0,249.92,98.51Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 240.716px 120.391px;" id="el96xesk4jynh"
					  class="animable"></path>
				<path d="M243.86,113.23a1.14,1.14,0,1,1-2.25.32,1.14,1.14,0,1,1,2.25-.32Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 242.735px 113.39px;" id="el6x86mi5r1m"
					  class="animable"></path>
				<path d="M244.59,120l5,1.1a2.53,2.53,0,0,1-3,2A2.7,2.7,0,0,1,244.59,120Z"
					  style="fill: rgb(177, 102, 104); transform-origin: 247.068px 121.577px;" id="elleh5epq1hwn"
					  class="animable"></path>
				<path d="M246.61,123.14a2.43,2.43,0,0,0,1.2,0,2.45,2.45,0,0,0-2.43-2.16,2.19,2.19,0,0,0-.77.14A2.64,2.64,0,0,0,246.61,123.14Z"
					  style="fill: rgb(242, 143, 143); transform-origin: 246.21px 122.098px;" id="elzs7xao0cau"
					  class="animable"></path>
				<path d="M253.76,108.76l-2.44-1.37a1.35,1.35,0,0,1,1.87-.57A1.48,1.48,0,0,1,253.76,108.76Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 252.619px 107.703px;" id="el7pvw98vyppo"
					  class="animable"></path>
				<path d="M241.91,109.41l-2.5,1.24a1.35,1.35,0,0,1,.58-1.86A1.46,1.46,0,0,1,241.91,109.41Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 240.576px 109.647px;" id="elcqgxo8pszoa"
					  class="animable"></path>
				<path d="M253.44,112.09a1.14,1.14,0,1,1-1.29-1A1.16,1.16,0,0,1,253.44,112.09Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 252.307px 112.22px;" id="elm02g6c0fct"
					  class="animable"></path>
				<polygon points="247.95 111.95 248.77 118.74 252.25 117.3 247.95 111.95"
						 style="fill: rgb(242, 143, 143); transform-origin: 250.1px 115.345px;" id="el63bbkas0s4h"
						 class="animable"></polygon>
				<path d="M245.21,127.71c-2.92-.11-9-1.26-10.18-3.53A6.15,6.15,0,0,0,237.4,127c1.92,1.36,8,2.52,8,2.52Z"
					  style="fill: rgb(242, 143, 143); transform-origin: 240.215px 126.85px;" id="elhxk9slwra3"
					  class="animable"></path>
				<path d="M232.68,115.47l.44,4.73h0a3.23,3.23,0,0,0,2.92-3.52l-.41-4.37Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 234.367px 116.255px;" id="elzphcyhnv7y"
					  class="animable"></path>
				<path d="M203.33,122.35c8.14,16.58,14.22,21.22,19.77,31.62,9.23.43,9.92-13.86,5.68-19.74,0,0-13.48-13.29-15.75-20.18-2.09-6.35-5.12-17.43-5-22.75a3.72,3.72,0,0,1,1-2.36c1.75-1.88,1.76-3.39,2.88-5.47.88-1.62,1.83-2.23,1.56-2.59a2.09,2.09,0,0,0-2.84-.21,11,11,0,0,0-2.15,2.06,24.11,24.11,0,0,1,1.67-7.17c1.94-4.38,1.47-6.61-4.44-5-3.71,1-6.93,3.87-7.49,12.77a93.48,93.48,0,0,0,.06,11.32c.64,6.85,1.49,13.12,2.33,18.79A37.13,37.13,0,0,0,203.33,122.35Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 214.679px 112.008px;" id="elzz7ujzhb1v"
					  class="animable"></path>
				<path d="M275.38,71.94a2.29,2.29,0,0,0,0,.37c.05.59.25,1.12.9,1.26l.25-.48a4.44,4.44,0,0,1,.49,1.7,2.39,2.39,0,0,1-.53,1.65,7.12,7.12,0,0,1-1.18.91c-.38.29-.72.66-1.13.92a1.8,1.8,0,0,1-.71.3c-.27.05-.52,0-.77,0-.55,0-1.08.52-1.6.7a3.54,3.54,0,0,1-3.44-.8,6.17,6.17,0,0,1-1.77-3.19,3.22,3.22,0,0,1-.15-1.44c.11-.55.19-1.12.34-1.67a3.51,3.51,0,0,1,1-1.81,4.62,4.62,0,0,1,1.56-.76,18.08,18.08,0,0,1,3.53-1,6.94,6.94,0,0,1,2.18,0,4.89,4.89,0,0,1,1.05.32c.11,0,.73.36.65.54A7.21,7.21,0,0,0,275.38,71.94Z"
					  style="fill: rgb(255, 168, 167); transform-origin: 271.37px 73.9678px;" id="elql2lj1j187e"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--podium-1--inject-150" class="animable" style="transform-origin: 168.27px 400.757px;">
			<g id="freepik--podium--inject-150" class="animable" style="transform-origin: 168.27px 400.757px;">
				<polygon points="158.49 464.28 158.49 428.81 241.53 380.86 241.53 416.33 158.49 464.28"
						 style="fill: rgb(255, 193, 0); transform-origin: 200.01px 422.57px;" id="ela95b7921919"
						 class="animable"></polygon>
				<g id="eli6vgqlygzy">
					<polygon points="158.49 464.28 158.49 428.81 241.53 380.86 241.53 416.33 158.49 464.28"
							 style="opacity: 0.3; transform-origin: 200.01px 422.57px;" class="animable"></polygon>
				</g>
				<polygon points="101.44 395.87 101.44 431.34 158.49 464.28 158.49 428.81 101.44 395.87"
						 style="fill: rgb(255, 193, 0); transform-origin: 129.965px 430.075px;" id="el3ui8mgk67g4"
						 class="animable"></polygon>
				<g id="elae8msw2yxa7">
					<polygon points="101.44 395.87 101.44 431.34 158.49 464.28 158.49 428.81 101.44 395.87"
							 style="opacity: 0.35; transform-origin: 129.965px 430.075px;" class="animable"></polygon>
				</g>
				<g id="elxrhs8yznc8s">
					<path d="M241.53,389.87v-9l-83,47.95-57.05-32.94v9l54.71,31.58a5.17,5.17,0,0,0,4.68,0Z"
						  style="opacity: 0.15; transform-origin: 171.505px 408.945px;" class="animable"></path>
				</g>
				<path d="M242.5,374.45a1.64,1.64,0,0,0-1-1.35l-61.15-35.31a5.21,5.21,0,0,0-4.68,0L95,384.39a1.65,1.65,0,0,0-1,1.35v10.13a1.65,1.65,0,0,0,1,1.35l61.15,35.3a5.15,5.15,0,0,0,4.68,0l80.71-46.59a1.64,1.64,0,0,0,1-1.35Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 168.27px 385.159px;" id="elhhhlw9w7wy"
					  class="animable"></path>
				<g id="elwyqgyq00mi9">
					<path d="M160.83,432.52l80.7-46.59a1.63,1.63,0,0,0,1-1.35V374.45a1.63,1.63,0,0,0-1-1.35l-61.14-35.31a5.21,5.21,0,0,0-4.68,0l-13.17,7.6-4,87.69A4.71,4.71,0,0,0,160.83,432.52Z"
						  style="opacity: 0.2; transform-origin: 200.535px 385.158px;" class="animable"></path>
				</g>
				<g id="ele1zv7yoxkhi">
					<path d="M94,385.74v10.13a1.65,1.65,0,0,0,1,1.35l61.15,35.3a4.71,4.71,0,0,0,2.34.56l4-87.69L95,384.39A1.65,1.65,0,0,0,94,385.74Z"
						  style="opacity: 0.3; transform-origin: 128.245px 389.236px;" class="animable"></path>
				</g>
				<path d="M241.53,373.1l-61.14-35.31a5.21,5.21,0,0,0-4.68,0L95,384.39c-1.29.74-1.29,1.95,0,2.7l61.15,35.3a5.15,5.15,0,0,0,4.68,0l80.7-46.59C242.83,375.05,242.83,373.84,241.53,373.1Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 168.269px 380.094px;" id="elfm17py7cdul"
					  class="animable"></path>
				<g id="elzecixz5nev">
					<path d="M241.53,373.1l-61.14-35.31a5.21,5.21,0,0,0-4.68,0L95,384.39c-1.29.74-1.29,1.95,0,2.7l61.15,35.3a5.15,5.15,0,0,0,4.68,0l80.7-46.59C242.83,375.05,242.83,373.84,241.53,373.1Z"
						  style="opacity: 0.05; transform-origin: 168.269px 380.094px;" class="animable"></path>
				</g>
			</g>
			<g id="freepik--shadow--inject-150">
				<ellipse cx="159.49" cy="381.69" rx="39.74" ry="22.94"
						 style="opacity: 0.07; transform-origin: 159.49px 381.69px;" class="animable"></ellipse>
			</g>
		</g>
		<g id="freepik--character-1--inject-150" class="animable" style="transform-origin: 127.185px 257.697px;">
			<g id="freepik--character--inject-150" class="animable" style="transform-origin: 127.185px 257.697px;">
				<path d="M184.43,173.13c-5.63,12.38-13.77,21.35-18.47,32.16-9.18,1.16-12.49-10.28-8.73-16.48,0,0,15.15-16.14,16.86-23.19,1.59-6.49,3.72-17.78,3.14-23.07a3.69,3.69,0,0,0-1.21-2.27c-1.9-1.74-2-3.24-3.3-5.23-1-1.54-2-2.07-1.77-2.46a2.11,2.11,0,0,1,2.82-.43,11,11,0,0,1,2.31,1.89,24,24,0,0,0-2.24-7c-2.28-4.21-2-6.47,4-5.39,3.77.68,7.21,3.31,8.47,12.14a92.19,92.19,0,0,1,.85,11.28c-.1,6.88-.44,13.2-.83,18.92A32.33,32.33,0,0,1,184.43,173.13Z"
					  style="fill: rgb(177, 102, 104); transform-origin: 171.433px 163.382px;" id="elkf8apm8a9v"
					  class="animable"></path>
				<path d="M155.12,188.41s5.83-5.54,8.78-8.61,8.33-10,8.33-10,9.8,1.47,10.65,12.76c0,0-4.74,6.22-9.49,13.7-4.11,6.46-4,11.33-4,11.33S153.67,201.1,155.12,188.41Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 168.953px 188.695px;" id="elraqlfftmac"
					  class="animable"></path>
				<g id="ele10vg2od1u5">
					<path d="M155.12,188.41s5.83-5.54,8.78-8.61,8.33-10,8.33-10,9.8,1.47,10.65,12.76c0,0-4.74,6.22-9.49,13.7-4.11,6.46-4,11.33-4,11.33S153.67,201.1,155.12,188.41Z"
						  style="opacity: 0.3; transform-origin: 168.953px 188.695px;" class="animable"></path>
				</g>
				<path d="M128.66,379.77c-.4,1.9-.26,2.4,1,3.5s3.86,2.93,4.46,5.14,1.25,5.8,4.92,8.17a19.68,19.68,0,0,0,12.44,2.95c3.75-.52,4.67-1.69,4.82-2.78a6.7,6.7,0,0,0-2.23-5.37C152,389.48,128.66,379.77,128.66,379.77Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 142.386px 389.697px;" id="elgkk9dmg6f3m"
					  class="animable"></path>
				<polygon points="130.58 377.5 139.54 377.5 140.63 361.19 130.58 361.52 130.58 377.5"
						 style="fill: rgb(177, 102, 104); transform-origin: 135.605px 369.345px;" id="el0jusn1z7s3pc"
						 class="animable"></polygon>
				<polygon points="158.06 365.57 167.38 363.68 168.68 346.11 158.06 345.75 158.06 365.57"
						 style="fill: rgb(177, 102, 104); transform-origin: 163.37px 355.66px;" id="elgxm9phkttbn"
						 class="animable"></polygon>
				<path d="M130,235.11c-1.79,21,0,72.35,0,72.35-.19,2.27-2.66,11.52-2.22,20.23.6,11.83,2.6,40.88,2.6,40.88s5.27,2.37,10.25.21c0,0,5.8-43.5,7.22-55.22,1.24-10.23,3.6-39.56,3.6-39.56l4.3,31.62a48.74,48.74,0,0,0-1,13.11c.37,5.42,3.05,37.4,3.05,37.4a13.93,13.93,0,0,0,10.41.83s5.12-46.48,5.21-51.46c.11-5.67-2-58.42-2-58.42Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 150.576px 302.399px;" id="elstinb9fj1jm"
					  class="animable"></path>
				<path d="M139.61,371.71a1.7,1.7,0,0,1,1.5.35,2.26,2.26,0,0,1,.45,1A26.78,26.78,0,0,0,147.77,384c2.67,2.85,7.38,7,8.36,10s-4,4.74-9.16,4.07c-3.09-.41-8.65-3.24-10-5.33s-2-5.16-3.05-6.69-4.7-4.48-5.28-6.25c-.33-1,.06-3.1.51-4.9.18-.72.45-3.12,1.37-3.33-.18,0,.09,1.3.13,1.43a2.74,2.74,0,0,0,.49,1,3.18,3.18,0,0,0,2.82,1.24c1-.21,1.67-1.17,2.42-1.89a6,6,0,0,1,3-1.56Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 142.386px 384.891px;" id="el0jgyrbohhoh"
					  class="animable"></path>
				<path d="M156.47,373.67c-.07,1.47,0,1.78.74,2.26s5.07,1,7.15.94a17.54,17.54,0,0,1,8.15,2.2,16.84,16.84,0,0,0,11,2.48c4.89-.67,6.46-1.39,7.37-2.42s1.27-4-.73-4.56S156.47,373.67,156.47,373.67Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 174.03px 377.67px;" id="elwv4s1e75hzi"
					  class="animable"></path>
				<path d="M167.61,360.53l.27-.12a1.73,1.73,0,0,1,1.13-.16c.51.16.77.69,1,1.16a13.5,13.5,0,0,0,2.88,3.79,19,19,0,0,0,4.06,2.47c3.49,1.82,10.28,4.62,13.35,6.06,1.92.9,1.83,3.31-.16,4.69s-6.77,2.36-12.52,1c-3.13-.73-7-3-9.79-3.58-2.53-.55-9.47-.82-11.38-2.19a9.21,9.21,0,0,1-.6-6.79c.59-2.74,1.32-7,2.19-6.83-.1,0,.12,1.06.15,1.15a5.25,5.25,0,0,0,1.67,2.35,3.15,3.15,0,0,0,2.75.59,7.28,7.28,0,0,0,2.55-2A9,9,0,0,1,167.61,360.53Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 173.575px 370.049px;" id="el326lnoo9zz9"
					  class="animable"></path>
				<path d="M168.68,364.85c2.55,2.08,7.22,5.41,10.18,5.94s3.42-.63,3.42-.63h0c-2-.89-3.92-1.77-5.31-2.49a19,19,0,0,1-4.06-2.47,13.5,13.5,0,0,1-2.88-3.79c-.25-.47-.51-1-1-1.16a1.73,1.73,0,0,0-1.13.16l-.27.12a9,9,0,0,0-2.43,1.62,7.28,7.28,0,0,1-2.55,2C164.6,363.51,166.12,362.77,168.68,364.85Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 172.465px 365.573px;" id="el4vj6cscpxz7"
					  class="animable"></path>
				<path d="M151.93,267.89a33.51,33.51,0,0,0,10.82-7s-1,4.85-8.6,8.84l1.27,33L151.48,274Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 157.115px 281.81px;" id="eld9jobpxdmo"
					  class="animable"></path>
				<path d="M124.35,209.53c2.86,8.28,5.54,22.8,5.54,22.8l-1.3,23.77c11.62,5.44,31.6,5.11,43.28-4.3,0,0-.2-37.5-3.63-49.39-2.44-8.48-8.05-14.21-14.07-14-6.35.2-14.51,1.1-14.51,1.1l-12.25,2.09S121.49,201.25,124.35,209.53Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 147.72px 224.03px;" id="elnk0vr3u2ab"
					  class="animable"></path>
				<path d="M120.23,177.83c-.8-.4-5.74,3.39-8,6.53s-2.38,7.53-1.66,8.43,9.61-3.15,9.61-3.15Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 115.204px 185.361px;" id="el70u1qcnk86g"
					  class="animable"></path>
				<g id="el4mz5m7lpa37">
					<path d="M120.23,177.83c-.8-.4-5.74,3.39-8,6.53s-2.38,7.53-1.66,8.43,9.61-3.15,9.61-3.15Z"
						  style="opacity: 0.6; transform-origin: 115.204px 185.361px;" class="animable"></path>
				</g>
				<path d="M104.85,178.41C113,195,119.07,199.63,124.62,210c5.76-.45,8.58-12.32,6.08-18.55,0,0-13.87-14.49-16.15-21.37-2.09-6.35-5.12-17.43-5-22.75a3.64,3.64,0,0,1,1-2.36c1.74-1.88,1.75-3.39,2.87-5.47.88-1.62,1.84-2.23,1.56-2.59a2.09,2.09,0,0,0-2.84-.21,10.67,10.67,0,0,0-2.15,2.06,23.47,23.47,0,0,1,.73-4.41c.29-1.11.89-2.14.73-3.28a4.31,4.31,0,0,0-1.32-2.88,3.81,3.81,0,0,0-2.73-.59,7.78,7.78,0,0,0-6,4,21.84,21.84,0,0,0-1.63,7.7,93.37,93.37,0,0,0,0,11.32c.64,6.85,1.49,13.12,2.33,18.79A37.59,37.59,0,0,0,104.85,178.41Z"
					  style="fill: rgb(177, 102, 104); transform-origin: 115.606px 168.775px;" id="elgwfa8eqxbf7"
					  class="animable"></path>
				<path d="M131.15,191.54l-10.92-13.71s-.2,12-9.62,14.95l14,17.76c1.62,2.71,6.07-.81,7.07-6.91C133.1,195.13,131.15,191.54,131.15,191.54Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 121.404px 194.648px;" id="elmmk8nut8xoc"
					  class="animable"></path>
				<g id="elvdbbyh2rw3c">
					<path d="M131.15,191.54l-10.92-13.71s-.2,12-9.62,14.95l14,17.76c1.62,2.71,6.07-.81,7.07-6.91C133.1,195.13,131.15,191.54,131.15,191.54Z"
						  style="opacity: 0.3; transform-origin: 121.404px 194.648px;" class="animable"></path>
				</g>
				<path d="M144.36,158.88A10.38,10.38,0,1,0,154.24,148,10.39,10.39,0,0,0,144.36,158.88Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 154.728px 158.369px;" id="eltas9jogk0r"
					  class="animable"></path>
				<path d="M131.37,157.57a3.27,3.27,0,0,1,1.35-1.23c.67-.32,1.6-1,2.1-.16.31.52.15,1.4.23,2,.28,2,.55,4,.83,6a7.4,7.4,0,0,1,.27,1.92l2-1.78.43,16.57a2.15,2.15,0,0,1-1.87-1.5c-.76-1.83-1.15-3.77-1.71-5.44s-1.51-4-2.16-5.78c-1-2.66-2.28-4.6-2.21-7.43a7,7,0,0,1,.45-2.5A3.78,3.78,0,0,1,131.37,157.57Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 134.604px 168.333px;" id="el7lkf4ix8s1j"
					  class="animable"></path>
				<path d="M137.64,169.1c-.69.45-1.77-.86-2.7-1.74s-3.84-2-5.13,1.08,1.65,6.9,3.89,7.67c2.85,1,3.58-1,3.58-1l.75,16.3h0c5.12,6,19.56,3.45,13.67-2.76l-.21-5.26a24.31,24.31,0,0,0,5.25,0c2.84-.58,4.53-2.92,5.25-6,1.16-5,1.5-9.08-.14-18.8-1.8-10.65-14.27-10.18-20.8-5.62S137.64,169.1,137.64,169.1Z"
					  style="fill: rgb(177, 102, 104); transform-origin: 146.233px 172.364px;" id="elq31z48bles"
					  class="animable"></path>
				<path d="M160.12,156.3a17,17,0,0,1-6.31,2A14.84,14.84,0,0,1,144,156a5.13,5.13,0,0,1-5.67,4.7,13.5,13.5,0,0,1,.15,8,2.19,2.19,0,0,1-.44.92c-1,1.05-2.39-1.63-3.09-2.21-.47-.4-.49-1.36-.54-1.92a29.29,29.29,0,0,1-.05-3.38c.11-3.56-1.25-7.3.32-10.72,1.33-2.91,4.43-4.67,7.56-5.36s6.36-.49,9.56-.6,6.53-.57,9.18-2.37c.55-.37,1.2-.82,1.83-.58a1.73,1.73,0,0,1,.88,1.38,7.46,7.46,0,0,1-1.33,5.82,1.57,1.57,0,0,1,2.26.24,2.42,2.42,0,0,1,.15,2.44,5.8,5.8,0,0,1-1.64,1.95A19.1,19.1,0,0,1,160.12,156.3Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 149.474px 156.137px;" id="elirop9zo4xsf"
					  class="animable"></path>
				<path d="M151.49,183.4s-5.73-.7-8-1.78a9.51,9.51,0,0,1-3.61-3.47,7.43,7.43,0,0,0,1.59,3.79c1.73,2,10.12,3.16,10.12,3.16Z"
					  style="fill: rgb(154, 74, 77); transform-origin: 145.735px 181.625px;" id="el0xr8zm5durk"
					  class="animable"></path>
				<path d="M149.17,167a1.28,1.28,0,1,1-1.34-1.27A1.3,1.3,0,0,1,149.17,167Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 147.89px 167.009px;" id="elxwb9nm2huq"
					  class="animable"></path>
				<path d="M147.59,162.19l-2.66,1.64a1.63,1.63,0,0,1,.53-2.21A1.53,1.53,0,0,1,147.59,162.19Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 146.146px 162.615px;" id="elrznocyehxp"
					  class="animable"></path>
				<path d="M149.25,174.51,154,176a2.44,2.44,0,0,1-3.09,1.69A2.6,2.6,0,0,1,149.25,174.51Z"
					  style="fill: rgb(154, 74, 77); transform-origin: 151.574px 176.157px;" id="el6ph7lfpgc8x"
					  class="animable"></path>
				<path d="M160.4,162.65l-2.58-1.8a1.51,1.51,0,0,1,2.15-.44A1.66,1.66,0,0,1,160.4,162.65Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 159.24px 161.393px;" id="elqamb5y3u20f"
					  class="animable"></path>
				<path d="M159.79,166.09a1.28,1.28,0,1,1-2.55.11,1.28,1.28,0,1,1,2.55-.11Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 158.515px 166.145px;" id="elyg27wqypsin"
					  class="animable"></path>
				<polygon points="153.22 165.18 154 172.85 157.96 171.29 153.22 165.18"
						 style="fill: rgb(154, 74, 77); transform-origin: 155.59px 169.015px;" id="elggyz7jqt5ec"
						 class="animable"></polygon>
				<path d="M143.85,385.93c3.17,2.73,5.59,1.49,5.53.56a5.84,5.84,0,0,0-.43-1.31c-.42-.43-.82-.84-1.17-1.22a26.69,26.69,0,0,1-6.22-10.89,2.26,2.26,0,0,0-.45-1,1.7,1.7,0,0,0-1.5-.35l-.17,0a6,6,0,0,0-3,1.56c-.75.72-1.4,1.68-2.42,1.89C136,375.12,140.68,383.19,143.85,385.93Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 141.701px 379.632px;" id="el7dvjkwbc8rq"
					  class="animable"></path>
				<g id="freepik--trophy--inject-150" class="animable" style="transform-origin: 91.3435px 147.496px;">
					<path d="M65.89,156.11,62.71,164a.64.64,0,0,0,.15.63l10.87,13.95a1.82,1.82,0,0,0,.77.54L85,153.18l-18.56,2.58A.65.65,0,0,0,65.89,156.11Z"
						  style="fill: rgb(38, 50, 56); transform-origin: 73.841px 166.15px;" id="el3ax7i0851py"
						  class="animable"></path>
					<path d="M75.43,179.22l19.47-2.71a.64.64,0,0,0,.55-.35l3.18-7.85a.64.64,0,0,0-.15-.63l-10.87-14a2,2,0,0,0-1.69-.68l-.92.13-10.5,25.9A1.8,1.8,0,0,0,75.43,179.22Z"
						  style="fill: rgb(55, 71, 79); transform-origin: 86.579px 166.108px;" id="elcwcvgienup"
						  class="animable"></path>
					<path d="M76.92,170.69,66.05,156.74c-.36-.46-.18-.9.39-1l19.48-2.71a2,2,0,0,1,1.69.68l10.87,14c.36.46.18.9-.4,1l-19.47,2.71A2,2,0,0,1,76.92,170.69Z"
						  style="fill: rgb(69, 90, 100); transform-origin: 82.2648px 162.223px;" id="elv54lhxfbx6"
						  class="animable"></path>
					<path d="M89.66,167.37a4,4,0,0,0,.39-.3l.1-.08a4,4,0,0,0,.29-.29l.08-.09a4.73,4.73,0,0,0,.28-.39h0c.08-.14.15-.28.22-.43l0-.13c0-.11.08-.22.11-.32l0-.17c0-.11,0-.22.05-.33s0-.11,0-.17,0-.32,0-.48a.88.88,0,0,1,0-.16l-.06-.36-.06-.22a.94.94,0,0,0,0-.16c0-.08,0-.16-.07-.23s0-.09,0-.13c-1-2.92-3.47-4.76-6.56-6s-6.15-1.65-8.9-.25l-.1.05-.23.12-.15.09-.18.11-.32.24-.1.08a3.85,3.85,0,0,0-.35.35l-.09.11c-.08.1-.14.19-.21.29l-.08.13c-.06.11-.11.21-.16.32a.61.61,0,0,0-.05.12,3.36,3.36,0,0,0-.22,1v.1a2.94,2.94,0,0,0,0,.42.49.49,0,0,1,0,.12,4.54,4.54,0,0,0,.09.51v0a3.22,3.22,0,0,0,.18.58s0,0,0,0c.07.18.14.35.23.53l.06.12c.09.16.18.32.28.48a.41.41,0,0,0,.07.11,7.28,7.28,0,0,0,.42.6,14.8,14.8,0,0,0,12.4,5c.25,0,.48-.08.71-.13l.15,0,.51-.14.14-.05c.18-.07.35-.13.51-.21l.06,0a4.35,4.35,0,0,0,.52-.29Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 82.2424px 162.057px;" id="elaoys2oauj76"
						  class="animable"></path>
					<g id="el1ut26ca7svp">
						<g style="opacity: 0.15; transform-origin: 82.2424px 162.057px;" class="animable">
							<path d="M89.66,167.37a4,4,0,0,0,.39-.3l.1-.08a4,4,0,0,0,.29-.29l.08-.09a4.73,4.73,0,0,0,.28-.39h0c.08-.14.15-.28.22-.43l0-.13c0-.11.08-.22.11-.32l0-.17c0-.11,0-.22.05-.33s0-.11,0-.17,0-.32,0-.48a.88.88,0,0,1,0-.16l-.06-.36-.06-.22a.94.94,0,0,0,0-.16c0-.08,0-.16-.07-.23s0-.09,0-.13c-1-2.92-3.47-4.76-6.56-6s-6.15-1.65-8.9-.25l-.1.05-.23.12-.15.09-.18.11-.32.24-.1.08a3.85,3.85,0,0,0-.35.35l-.09.11c-.08.1-.14.19-.21.29l-.08.13c-.06.11-.11.21-.16.32a.61.61,0,0,0-.05.12,3.36,3.36,0,0,0-.22,1v.1a2.94,2.94,0,0,0,0,.42.49.49,0,0,1,0,.12,4.54,4.54,0,0,0,.09.51v0a3.22,3.22,0,0,0,.18.58s0,0,0,0c.07.18.14.35.23.53l.06.12c.09.16.18.32.28.48a.41.41,0,0,0,.07.11,7.28,7.28,0,0,0,.42.6,14.8,14.8,0,0,0,12.4,5c.25,0,.48-.08.71-.13l.15,0,.51-.14.14-.05c.18-.07.35-.13.51-.21l.06,0a4.35,4.35,0,0,0,.52-.29Z"
								  id="eltjwbn7htcm" class="animable"
								  style="transform-origin: 82.2424px 162.057px;"></path>
						</g>
					</g>
					<path d="M83.5,159.17l-.38-.17a.76.76,0,0,0-.89.15A53.41,53.41,0,0,0,78.09,164a.82.82,0,0,0,.21,1.21,13.31,13.31,0,0,0,2.28,1.16,13.1,13.1,0,0,0,2.45.76.81.81,0,0,0,1-.72,51.5,51.5,0,0,0,.39-6.35.77.77,0,0,0-.53-.74Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 81.1652px 163.036px;" id="elbchpezklk5u"
						  class="animable"></path>
					<path d="M85,160.4a40.74,40.74,0,0,1-.24,6,.83.83,0,0,0,.79.94,9,9,0,0,0,2.81-.38,1.06,1.06,0,0,0,.65-1.21,16.87,16.87,0,0,0-2.58-5.52,1.48,1.48,0,0,0-1-.67C85.12,159.53,84.93,159.91,85,160.4Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 86.8932px 163.45px;" id="el0djni0t365lt"
						  class="animable"></path>
					<path d="M86.81,160.05a19.4,19.4,0,0,1,2.67,5.27c.16.47.58.62.87.22a3,3,0,0,0,.33-.62,2.46,2.46,0,0,0-.2-1.68,11.5,11.5,0,0,0-3.15-3.81c-.4-.3-.78-.38-.86-.27S86.53,159.64,86.81,160.05Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 88.5969px 162.442px;" id="elbdlix8iqf9p"
						  class="animable"></path>
					<path d="M86.58,158.48c0-.13.18-.17.52,0a3.58,3.58,0,0,1,.59.25,7.31,7.31,0,0,1,2.45,2.46,7.21,7.21,0,0,1,.72,1.55c.12.44,0,.43-.25,0a10.39,10.39,0,0,0-3.21-3.52C87,158.89,86.61,158.62,86.58,158.48Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 88.7434px 160.717px;" id="elmyq2muhaqid"
						  class="animable"></path>
					<path d="M81.59,159a41.61,41.61,0,0,0-4,4.44.82.82,0,0,1-1.22.13,9.35,9.35,0,0,1-1.75-2.23,1.06,1.06,0,0,1,.38-1.32,16.64,16.64,0,0,1,5.7-2.16,1.45,1.45,0,0,1,1.18.21C82.08,158.3,82,158.7,81.59,159Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 78.2554px 160.799px;" id="elft0onjuislp"
						  class="animable"></path>
					<path d="M80.5,157.49a19.76,19.76,0,0,0-5.59,1.93c-.44.23-.85,0-.77-.45a2.62,2.62,0,0,1,.19-.67,2.43,2.43,0,0,1,1.32-1.07,11.43,11.43,0,0,1,4.91-.54c.49.06.82.26.81.4S81,157.39,80.5,157.49Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 77.7501px 158.067px;" id="el44tg3c1xk52"
						  class="animable"></path>
					<path d="M81.76,156.53c.11-.07,0-.25-.34-.4a3.62,3.62,0,0,0-.59-.24,7.4,7.4,0,0,0-3.48.07,7.27,7.27,0,0,0-1.6.61c-.39.23-.28.32.19.17a10.36,10.36,0,0,1,4.76-.29A2.68,2.68,0,0,0,81.76,156.53Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 78.6569px 156.265px;" id="elp8h7x7dmbl"
						  class="animable"></path>
					<path d="M96.92,147.23l-.06,0a1.83,1.83,0,0,1-.91-2.31c.34-1.2,1.9-4.23,8.87-6.27,5.93-1.73,11-3.53,13.1-8.06.76-1.66.69-3.3-.19-4.06s-2.67-.51-4.65.78a4.37,4.37,0,0,0-2,2.66.56.56,0,0,0,.2,0,.79.79,0,0,1,.84.73.78.78,0,0,1-.73.83,1.57,1.57,0,0,1-1.79-1c-.46-1.38,1-3.5,2.61-4.54,3.75-2.44,5.82-1.26,6.53-.65,1.44,1.25,1.67,3.57.59,5.91-2.37,5.13-7.77,7.07-14.08,8.91-5.08,1.48-7.36,3.65-7.8,5.19,0,.17-.08.39,0,.45a1.08,1.08,0,0,0,1.13-.54.79.79,0,0,1,1.33.85A2.62,2.62,0,0,1,96.92,147.23Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 107.915px 135.929px;" id="el0lq63b67ksin"
						  class="animable"></path>
					<g id="elqanhrcmcwoj">
						<g style="opacity: 0.2; transform-origin: 107.915px 135.929px;" class="animable">
							<path d="M96.92,147.23l-.06,0a1.83,1.83,0,0,1-.91-2.31c.34-1.2,1.9-4.23,8.87-6.27,5.93-1.73,11-3.53,13.1-8.06.76-1.66.69-3.3-.19-4.06s-2.67-.51-4.65.78a4.37,4.37,0,0,0-2,2.66.56.56,0,0,0,.2,0,.79.79,0,0,1,.84.73.78.78,0,0,1-.73.83,1.57,1.57,0,0,1-1.79-1c-.46-1.38,1-3.5,2.61-4.54,3.75-2.44,5.82-1.26,6.53-.65,1.44,1.25,1.67,3.57.59,5.91-2.37,5.13-7.77,7.07-14.08,8.91-5.08,1.48-7.36,3.65-7.8,5.19,0,.17-.08.39,0,.45a1.08,1.08,0,0,0,1.13-.54.79.79,0,0,1,1.33.85A2.62,2.62,0,0,1,96.92,147.23Z"
								  id="eli7ny1xp0ee" class="animable"
								  style="transform-origin: 107.915px 135.929px;"></path>
						</g>
					</g>
					<path d="M80.28,120.63c-3.21,7.92-2.12,13.09-.89,19.17,1.44,7.19,4.84,13.18,3.43,17.27a.69.69,0,0,0,.17.69,2.21,2.21,0,0,0,1.86.75.71.71,0,0,0,.6-.38h0c1.71-4,8.44-5.84,14.49-10,5.11-3.52,9.47-6.4,12.7-14.39Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 95.4046px 139.574px;" id="elzv7w5ya23f"
						  class="animable"></path>
					<g id="el5y7qzo4x6nj">
						<path d="M80.24,120.74c-3.16,7.86-2.07,13-.85,19.06,1.44,7.19,4.84,13.18,3.43,17.27a.69.69,0,0,0,.17.69,1.83,1.83,0,0,0,.32.3,23.81,23.81,0,0,0,.74-13.76,29.8,29.8,0,0,1,1.25-18.47s-2.63-3.47-4-4.77l-1-.43Z"
							  style="opacity: 0.1; transform-origin: 81.7356px 139.345px;" class="animable"></path>
					</g>
					<path d="M82.34,129.15c4.84,6.21,15.08,10.36,22.88,9.28s10.19-7,5.36-13.21-15.09-10.36-22.88-9.28S77.5,122.94,82.34,129.15Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 96.459px 127.185px;" id="el1wkbe3u7ece"
						  class="animable"></path>
					<g id="elyiwsyo0r8s">
						<path d="M82.34,129.15c4.84,6.21,15.08,10.36,22.88,9.28s10.19-7,5.36-13.21-15.09-10.36-22.88-9.28S77.5,122.94,82.34,129.15Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 96.459px 127.185px;"
							  class="animable"></path>
					</g>
					<path d="M85,128.78c3.92,5,12.22,8.39,18.54,7.51s8.26-5.67,4.34-10.7-12.22-8.4-18.54-7.51S81.1,123.75,85,128.78Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 96.4444px 127.183px;" id="elamdqh7qirt6"
						  class="animable"></path>
					<g id="elgbcx47nnumq">
						<path d="M85,128.78c3.92,5,12.22,8.39,18.54,7.51s8.26-5.67,4.34-10.7-12.22-8.4-18.54-7.51S81.1,123.75,85,128.78Z"
							  style="opacity: 0.2; transform-origin: 96.4444px 127.183px;" class="animable"></path>
					</g>
					<g id="el6k2q3b679ww">
						<path d="M94,118.11a16.83,16.83,0,0,0-4.59,0C83,119,81.1,123.75,85,128.78a16,16,0,0,0,2.53,2.55C86.4,123.63,91,118.4,94,118.11Z"
							  style="opacity: 0.2; transform-origin: 88.4906px 124.641px;" class="animable"></path>
					</g>
					<path d="M79.21,149.76a1.26,1.26,0,0,1-.75-1.43.79.79,0,0,1,1.54-.06,3.3,3.3,0,0,0,1.91-1.56,1.7,1.7,0,0,0,.16-1.56c-.25-.42-1.08-1.15-3.9-1.25-3.82-.14-7.09-.41-8.45-2.4a4.48,4.48,0,0,1-.19-4.12A13.38,13.38,0,0,1,77.22,130a4.2,4.2,0,0,1,3.9.49,2.38,2.38,0,0,1,.69,2.5,4.15,4.15,0,0,1-3.09,3.11.79.79,0,0,1-.31-1.54,2.56,2.56,0,0,0,1.87-1.92c.09-.42,0-.78-.12-.91a2.7,2.7,0,0,0-2.49-.23A12,12,0,0,0,71,137.89a3,3,0,0,0,0,2.72c.94,1.38,4.08,1.61,7.2,1.72,2.73.1,4.48.78,5.2,2a3.08,3.08,0,0,1-.12,3.07c-.82,1.59-2.81,2.76-4,2.36Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 76.4352px 139.783px;" id="eltc1ajn7gi3"
						  class="animable"></path>
					<g id="el3p0l7n5nbyg">
						<g style="opacity: 0.3; transform-origin: 76.4352px 139.783px;" class="animable">
							<path d="M79.21,149.76a1.26,1.26,0,0,1-.75-1.43.79.79,0,0,1,1.54-.06,3.3,3.3,0,0,0,1.91-1.56,1.7,1.7,0,0,0,.16-1.56c-.25-.42-1.08-1.15-3.9-1.25-3.82-.14-7.09-.41-8.45-2.4a4.48,4.48,0,0,1-.19-4.12A13.38,13.38,0,0,1,77.22,130a4.2,4.2,0,0,1,3.9.49,2.38,2.38,0,0,1,.69,2.5,4.15,4.15,0,0,1-3.09,3.11.79.79,0,0,1-.31-1.54,2.56,2.56,0,0,0,1.87-1.92c.09-.42,0-.78-.12-.91a2.7,2.7,0,0,0-2.49-.23A12,12,0,0,0,71,137.89a3,3,0,0,0,0,2.72c.94,1.38,4.08,1.61,7.2,1.72,2.73.1,4.48.78,5.2,2a3.08,3.08,0,0,1-.12,3.07c-.82,1.59-2.81,2.76-4,2.36Z"
								  id="el0hn3572f132g" class="animable"
								  style="transform-origin: 76.4352px 139.783px;"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="freepik--Confetti--inject-150" class="animable" style="transform-origin: 250.217px 164.295px;">
			<g id="freepik--confetti--inject-150" class="animable" style="transform-origin: 250.217px 164.295px;">
				<path d="M304.28,28.73c-3.57,3.06-2.3,13,3.57,12.3,3.25-.39,6.43-5,3.58-7.66a.51.51,0,0,0-.6,0c-3.15,2.07-4.53,5.79-1,8.3a8.24,8.24,0,0,0,10.23-.9c.16-.15-.18-.37-.32-.27-2.7,1.85-6.23,2.66-9.13.74a4.34,4.34,0,0,1-2.08-2.94,3.48,3.48,0,0,1,.35-2c.25-.52,1.39-2.16,2-2.16,1.25,0,.71,2.73.4,3.4a5,5,0,0,1-3.21,2.77c-2.31.47-3.83-1.65-4.49-3.58-.89-2.6-.8-5.59,1.11-7.72.17-.2-.27-.43-.42-.3Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 311.197px 35.8562px;" id="el2ri3gcljqni"
					  class="animable"></path>
				<path d="M277.81,145.21c1.47-3.31,1.32-6.74,5.6-7.83,1.88-.48,6.33-.92,7.74.76a2,2,0,0,1-.37,2.57c-1.11,1-1.95-.2-2.23-1.26a4.82,4.82,0,0,1,2.14-5.18,8.59,8.59,0,0,1,11.47,1.85c.16.19.33-.05.26-.23-3.28-7.55-19-2.3-14,5,1.53,2.19,4.23.25,3.72-2.06-.56-2.57-5-2.44-6.88-2.32a8,8,0,0,0-5.32,2c-1.72,1.69-1.94,4.35-2.3,6.6,0,.1.09.25.15.1Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 290.038px 138.739px;" id="el7ktqmka1yz2"
					  class="animable"></path>
				<path d="M420,31.88c3.07-.9,7.29-.65,9.86,1.42,2,1.6,3.12,5.07.19,6.26-1.15.46-4.94.46-5.39-1.1-.32-1.09.85-1.74,1.79-1.88,2.75-.43,5.33,2.47,5.26,5.06-.09,3.89-4.62,5.18-7.82,5.21-.21,0-.16.4,0,.41,5.45.53,11.53-4.52,6.68-9.58-1.3-1.36-3.34-2.33-5.22-1.58-1.06.43-1.9,1.39-1.37,2.54.85,1.85,4.27,2.17,5.93,1.7a3.33,3.33,0,0,0,2.3-4.28c-1.34-4.92-8.23-6-12.31-4.47-.12,0-.07.33.07.29Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 426.108px 39.1219px;" id="elqvapg1updqg"
					  class="animable"></path>
				<path d="M196.25,164.56c3.07-.9,7.29-.65,9.87,1.42,2,1.6,3.12,5.07.18,6.26-1.14.46-4.93.46-5.39-1.1-.31-1.09.86-1.74,1.79-1.88,2.76-.43,5.33,2.47,5.27,5.06-.09,3.89-4.62,5.18-7.82,5.21-.22,0-.16.4,0,.42,5.45.52,11.53-4.53,6.69-9.59-1.31-1.36-3.34-2.33-5.22-1.58-1.07.43-1.91,1.39-1.38,2.54.85,1.85,4.28,2.17,5.94,1.7a3.33,3.33,0,0,0,2.3-4.28c-1.34-4.92-8.23-6-12.32-4.47-.11,0-.07.33.07.29Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 202.375px 171.806px;" id="elupovhds0rh"
					  class="animable"></path>
				<path d="M445,157.57c3.07-.89,7.29-.65,9.87,1.43,2,1.59,3.12,5.06.18,6.25-1.15.47-4.93.47-5.39-1.1-.31-1.09.86-1.74,1.79-1.88,2.76-.42,5.33,2.48,5.27,5.07-.09,3.89-4.62,5.17-7.82,5.21-.22,0-.16.39,0,.41,5.45.52,11.53-4.53,6.69-9.58-1.31-1.37-3.34-2.33-5.22-1.58-1.07.42-1.91,1.38-1.38,2.54.85,1.85,4.28,2.16,5.94,1.69a3.31,3.31,0,0,0,2.29-4.28c-1.33-4.92-8.22-5.94-12.31-4.46-.12,0-.07.32.07.28Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 451.121px 164.831px;" id="el0c78ijk9p63"
					  class="animable"></path>
				<path d="M437.73,88.79c-3.66,1.09-7.06,7.53-3.66,10.46,2.89,2.49,8.73,1.93,7.76-2.82a.31.31,0,0,0-.2-.24c-3.84-.92-5.11,3.56-4.3,6.52,1.25,4.6,5.89,4.54,9.24,2.14.11-.08.06-.4-.1-.32-2.74,1.37-6.58,2.59-8.25-.91-.89-1.87-.92-5,.8-6.41a2.38,2.38,0,0,1,1.65-.64c1.28.15,1.06,1.53.34,2.41-1.15,1.4-3.46,1.25-5,.71-5.27-1.87-1.65-8.7,1.79-10.62.1-.06.08-.32-.07-.28Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 439.649px 97.6249px;" id="el29jgy37bx1s"
					  class="animable"></path>
				<path d="M423.06,132.68c-4.31-.55-13.6,3.09-11.19,9,1.29,3.14,7.05,5.16,8.95,1.55a.42.42,0,0,0-.21-.53c-9-5.09-18.49,9.63-8.93,15,.3.17.29-.34.11-.47a7.51,7.51,0,0,1-1.87-10.57,9.87,9.87,0,0,1,4-3.62c1.23-.58,4.32-1.4,5.46-.19.53.55.51-.15.24.5-.16.4-.71.63-1.1.78a4.46,4.46,0,0,1-2.95-.11c-2-.69-3.58-2.53-3.05-4.75,1.06-4.43,6.7-6,10.64-6.07.28,0,.1-.51-.11-.54Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 415.497px 145.18px;" id="elu4x5m2mqj2"
					  class="animable"></path>
				<path d="M376,32.83s-.26,4.84,3.33,7.53l3.76-1.15s-2.51-1.2-3.76-6.38Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 379.543px 36.595px;" id="elhosmd8vh7pn"
					  class="animable"></path>
				<path d="M281.16,157.73s-.27,4.83,3.33,7.52l3.76-1.14s-2.51-1.21-3.76-6.38Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 284.703px 161.49px;" id="elprsdea4ksa"
					  class="animable"></path>
				<path d="M461.26,57.08s-2.2,4.32-6.65,4.85l-2.69-2.88s2.78.22,6.46-3.63Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 456.59px 58.675px;" id="el3bd1ujh2s5d"
					  class="animable"></path>
				<path d="M430.72,198.09s-2.19,4.31-6.65,4.84l-2.68-2.87s2.78.21,6.45-3.64Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 426.055px 199.675px;" id="el8foj0r1nn44"
					  class="animable"></path>
				<path d="M308.86,179.32s-2.19,4.32-6.65,4.85l-2.69-2.88s2.79.22,6.46-3.63Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 304.19px 180.915px;" id="el0csucjod3xha"
					  class="animable"></path>
				<path d="M417,74s-4.81.6-7.74-2.81l.89-3.83s1.37,2.43,6.62,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 413.13px 70.7003px;" id="elc50morb8coh"
					  class="animable"></path>
				<path d="M347.26,50.39s-4.8.6-7.73-2.8l.88-3.83s1.38,2.42,6.63,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 343.395px 47.0954px;" id="elp4x0q1ahpn"
					  class="animable"></path>
				<path d="M400.44,178.26s-4.81.6-7.74-2.8l.89-3.84s1.38,2.43,6.62,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 396.57px 174.96px;" id="elflsvicw3jag"
					  class="animable"></path>
				<path d="M307.69,113.79s-4.81.59-7.74-2.81l.88-3.83s1.38,2.43,6.63,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 303.82px 110.49px;" id="elxnc2wpc4yxl"
					  class="animable"></path>
				<path d="M467.82,140.22s-4.81.59-7.73-2.81l.88-3.83s1.38,2.43,6.62,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 463.955px 136.92px;" id="ellq15my126ad"
					  class="animable"></path>
				<path d="M37.24,130.84s-4.8.59-7.73-2.81l.88-3.83s1.38,2.42,6.63,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 33.375px 127.54px;" id="eli4kmh5eyx2"
					  class="animable"></path>
				<path d="M384.07,73s-2.2,4.32-6.65,4.85L374.73,75s3.29.51,7-3.34Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 379.4px 74.755px;" id="elsk1uamobgom"
					  class="animable"></path>
				<path d="M474.26,209.05s-4.83-.42-7-4.35l1.66-3.57s.76,3.24,5.71,5.2Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 470.945px 205.09px;" id="elrk0rrsp6hkd"
					  class="animable"></path>
				<path d="M388.91,79.37s-.86,4.76-5,6.55l-3.4-2a12.45,12.45,0,0,0,5.71-5.21Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 384.71px 82.315px;" id="el8ykecj0zlpr"
					  class="animable"></path>
				<path d="M219.89,36.85c4.68-.46,11,7.33,6.42,11.08-2.53,2.09-8.07,1.18-8-2.71a.5.5,0,0,1,.41-.43c3.68-.84,7.31.73,6.69,5a8.23,8.23,0,0,1-7.72,6.76c-.23,0-.15-.38,0-.41,3.2-.68,6.22-2.67,6.84-6.09a4.33,4.33,0,0,0-.69-3.53,3.43,3.43,0,0,0-1.67-1.12c-.54-.17-2.52-.48-2.95,0-.87.9,1.48,2.4,2.18,2.64a4.93,4.93,0,0,0,4.22-.4c1.94-1.35,1.46-3.91.52-5.73-1.26-2.44-3.49-4.44-6.35-4.53-.25,0-.12-.49.08-.51Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 222.722px 46.6902px;" id="el0n0tnb9as4fe"
					  class="animable"></path>
				<path d="M50.42,180.12c-1.47-3.31-1.32-6.74-5.59-7.83-1.88-.48-6.33-.92-7.75.75a2.05,2.05,0,0,0,.37,2.58c1.12,1,1.95-.2,2.24-1.26a4.85,4.85,0,0,0-2.14-5.18A8.61,8.61,0,0,0,26.07,171c-.15.2-.32-.05-.25-.22,3.28-7.55,19-2.3,14,4.94-1.53,2.19-4.24.26-3.73-2.05.57-2.58,5-2.45,6.88-2.32a8,8,0,0,1,5.33,2c1.71,1.68,1.94,4.34,2.3,6.6,0,.1-.09.25-.16.1Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 38.2019px 173.606px;" id="el4z33zt62873"
					  class="animable"></path>
				<path d="M89.5,31.88c-3.07-.9-7.29-.65-9.87,1.42-2,1.6-3.11,5.07-.18,6.26,1.15.46,4.94.46,5.39-1.1.32-1.09-.85-1.74-1.79-1.88-2.75-.43-5.33,2.47-5.27,5.06.1,3.89,4.63,5.18,7.83,5.21.21,0,.16.4,0,.41-5.45.53-11.53-4.52-6.69-9.58,1.31-1.36,3.35-2.33,5.23-1.58,1.06.43,1.9,1.39,1.37,2.54-.85,1.85-4.27,2.17-5.93,1.7a3.33,3.33,0,0,1-2.3-4.28c1.34-4.92,8.22-6,12.31-4.47.12,0,.07.33-.07.29Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 83.3919px 39.1219px;" id="elxgzkq1b0idr"
					  class="animable"></path>
				<path d="M90.83,271.25c-3.07-.89-7.29-.65-9.87,1.43-2,1.59-3.11,5.06-.18,6.25,1.15.47,4.94.47,5.39-1.1.32-1.09-.85-1.73-1.79-1.88-2.75-.42-5.33,2.48-5.27,5.07.1,3.89,4.63,5.18,7.83,5.21.21,0,.15.39,0,.41-5.45.53-11.53-4.52-6.69-9.58,1.31-1.37,3.35-2.33,5.23-1.58,1.06.42,1.9,1.38,1.37,2.54-.85,1.85-4.27,2.17-5.93,1.69a3.31,3.31,0,0,1-2.3-4.28c1.34-4.92,8.22-5.94,12.31-4.46.12,0,.07.33-.07.28Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 84.7189px 278.512px;" id="elb7wwpqftyr7"
					  class="animable"></path>
				<path d="M61.66,90.64c3.66,1.09,7.06,7.53,3.66,10.46-2.89,2.5-8.73,1.93-7.76-2.82a.33.33,0,0,1,.2-.24c3.84-.91,5.11,3.57,4.3,6.53-1.25,4.59-5.89,4.54-9.24,2.13-.11-.08-.06-.39.1-.31,2.74,1.37,6.58,2.58,8.25-.92.89-1.87.92-5-.8-6.4a2.39,2.39,0,0,0-1.65-.65c-1.28.15-1.06,1.54-.34,2.41,1.15,1.4,3.46,1.25,5,.71,5.27-1.86,1.65-8.7-1.79-10.62-.1-.06-.08-.32.07-.28Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 59.7415px 99.4774px;" id="el96nfhwdt1vg"
					  class="animable"></path>
				<path d="M66.23,207.77c-6.23.5-5.1,12.33-.07,13.92a2.4,2.4,0,0,0,1.91-.1,2.76,2.76,0,0,0,1.13-2.4c-.6-2-4,.54-4.39,1.33a5.81,5.81,0,0,0,.47,6.8c2.57,2.82,7.46,2.92,10.67,1.3.17-.08.2.23.08.31-3.44,2.21-9.23,1.89-11.78-1.61-2-2.81-.64-10.23,3.83-9.57,4.16.61,1.28,5.35-1.87,4.72s-4.29-4.81-4.59-7.4c-.39-3.26.59-7.67,4.63-7.59.13,0,.11.28,0,.29Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 68.8219px 218.903px;" id="el1jgrvnzopa3"
					  class="animable"></path>
				<path d="M143.3,74.87c4.31-.55,13.6,3.09,11.19,9-1.29,3.14-7.05,5.16-9,1.55a.42.42,0,0,1,.21-.53c9-5.09,18.49,9.63,8.93,15-.3.17-.29-.34-.11-.47a7.51,7.51,0,0,0,1.87-10.57,9.87,9.87,0,0,0-4-3.62c-1.23-.58-4.32-1.4-5.46-.2-.53.56-.51-.14-.24.51.16.4.71.63,1.1.77a4.43,4.43,0,0,0,3-.1c2-.69,3.57-2.53,3-4.75-1.06-4.43-6.7-6-10.64-6.07-.28,0-.1-.51.11-.54Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 150.813px 87.3698px;" id="elqux1yommszp"
					  class="animable"></path>
				<path d="M134,41.63s.27,4.84-3.33,7.53L126.91,48s2.51-1.2,3.76-6.38Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 130.457px 45.39px;" id="el8g8r86jx4jb"
					  class="animable"></path>
				<path d="M46.61,57.08s2.2,4.32,6.65,4.85L56,59.05s-2.78.22-6.46-3.63Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 51.305px 58.675px;" id="el1s7n6737kfj"
					  class="animable"></path>
				<path d="M97.19,227.38s2.2,4.32,6.65,4.85l2.69-2.88s-2.78.21-6.46-3.64Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 101.86px 228.97px;" id="elwen7vw3cnr"
					  class="animable"></path>
				<path d="M33.52,291.08s2.2,4.32,6.65,4.85l2.69-2.88s-2.78.22-6.46-3.63Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 38.19px 292.675px;" id="elyzuirp5ia2"
					  class="animable"></path>
				<path d="M140.49,126.41s2.19,4.32,6.65,4.85l2.68-2.88s-2.78.22-6.45-3.63Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 145.155px 128.005px;" id="elu8ojiitof5"
					  class="animable"></path>
				<path d="M175.74,72s2.19,4.32,6.64,4.85L185.07,74s-2.78.22-6.46-3.63Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 180.405px 73.61px;" id="ely5eyfzoknn"
					  class="animable"></path>
				<path d="M90.86,74s4.81.6,7.74-2.81l-.89-3.83s-1.37,2.43-6.62,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 94.73px 70.7003px;" id="elnkg4i2v411"
					  class="animable"></path>
				<path d="M173,36.55s4.81.6,7.74-2.8l-.88-3.83s-1.38,2.42-6.63,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 176.87px 33.2554px;" id="el18byvi3fz6e"
					  class="animable"></path>
				<path d="M175.67,102.54s4.81.6,7.74-2.8l-.89-3.84s-1.37,2.43-6.62,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 179.54px 99.2404px;" id="elyyvp0cw9tym"
					  class="animable"></path>
				<path d="M33,247.79s4.81.6,7.73-2.8l-.88-3.84s-1.38,2.43-6.62,3.33Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 36.865px 244.49px;" id="el09r9919jzfy9"
					  class="animable"></path>
				<path d="M108.77,308.74s4.81.6,7.74-2.8l-.88-3.83s-1.38,2.42-6.63,3.32Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 112.64px 305.445px;" id="el436n4ebgo8l"
					  class="animable"></path>
				<path d="M115.57,93.92s2.19,4.32,6.65,4.85l2.68-2.87s-3.28.5-7-3.35Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 120.235px 95.66px;" id="el9axa8qqfd0b"
					  class="animable"></path>
				<path d="M110.73,100.3s.86,4.77,5,6.56l3.4-2a12.47,12.47,0,0,1-5.7-5.2Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 114.93px 103.26px;" id="elphhu414gd1r"
					  class="animable"></path>
				<path d="M249.39,21.18s-2.2,4.32-6.65,4.85l-2.69-2.88s3.29.51,7-3.34Z"
					  style="fill: rgb(69, 90, 100); transform-origin: 244.72px 22.92px;" id="elsxwbtfzi2dm"
					  class="animable"></path>
				<path d="M243.13,70.41s-.86,4.77-5,6.55l-3.4-2a12.46,12.46,0,0,0,5.7-5.21Z"
					  style="fill: rgb(55, 71, 79); transform-origin: 238.93px 73.355px;" id="elt6jzkicmbyk"
					  class="animable"></path>
			</g>
		</g>
		<defs>
			<filter id="active" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
			<filter id="hover" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
				<feColorMatrix type="matrix"
							   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
			</filter>
		</defs>
	</svg>
</template>

<script lang="ts">

export default {
	name: "WinnerAnimation",
	components: {},
	props: {
		height: String,
	},
	setup() {
		return {}
	}
}
</script>

<style scoped>

svg#freepik_stories-winners:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-winners.animated #freepik--Floor--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--Shadow--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--Plants--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--Stars--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--podium-3--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--character-3--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--podium-2--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--character-2--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--podium-1--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--character-1--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-winners.animated #freepik--Confetti--inject-150 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>