import {computed, ComputedRef} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {MobiPointsProfileUser} from "@/mobipoints/profile/userProfile/user_profile.type";
import {MobiPointApiProfile, UserProfileResponseError} from "@/mobipoints/api/profile";

export default function useUserProfile() {
	const userProfile: ComputedRef<MobiPointsProfileUser> = computed(() => store.getters['userProfile/profile']);
	const isDarkMode: ComputedRef<boolean> = computed(() => store.getters['userProfile/isDarkMode']);
	const isTrackingAutoStop: ComputedRef<boolean> = computed(() => store.getters['userProfile/isTrackingAutoStop']);
	const isTrackingStopConfirm: ComputedRef<boolean> = computed(() => store.getters['userProfile/isTrackingStopConfirm']);

	async function setDarkModeEnabled(value: boolean) {
		if (isDarkMode.value !== value) {
			await store.commit('userProfile/darkMode', value);
			return await store.dispatch('userProfile/updateUserProfile', userProfile.value);
		}
	}

	async function setTrackingAutoStop(value: boolean) {
		if (isTrackingAutoStop.value !== value) {
			await store.commit('userProfile/trackingAutoStop', value);
			return await store.dispatch('userProfile/updateUserProfile', userProfile.value);
		}
	}

	async function setIsTrackingStopConfirm(value: boolean) {
		if (isTrackingStopConfirm.value !== value) {
			await store.commit('userProfile/trackingStopConfirm', value);
			return await store.dispatch('userProfile/updateUserProfile', userProfile.value);
		}
	}

	const loadUserProfile = async function () {
		try {
			const response = await MobiPointApiProfile.getUserProfile();
			return response.data.payload;
		} catch (error: any | AxiosError | UserProfileResponseError) {
			console.log("LOAD USER PROFILE ERROR ", error?.error, JSON.stringify(error))
			throw new UserProfileResponseError(error.status, error.error.message);
		}
	}

	const initUserProfile = async function (force = false) {
		if ( (force) || (!userProfile.value) ) {
			store.commit('userProfile/profile', await loadUserProfile());
		}
		return;
	}

	const resetProfile = async function () {
		store.commit('userProfile/resetProfile');
		await initUserProfile();
	}

	function checkToggleMode() {
		document.body.classList.toggle('dark', isDarkMode.value);
	}

	return {
		initUserProfile,
		resetProfile,
		setDarkModeEnabled,
		setTrackingAutoStop,
		setIsTrackingStopConfirm,
		isDarkMode,
		isTrackingAutoStop,
		isTrackingStopConfirm,
		checkToggleMode
	}
}
