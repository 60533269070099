<template>
	<form class="input-split-form">
		<input class="input-split"
			   v-for="(n, index) in code"
			   :key="index"
			   :type="inputType"
			   :pattern="inputPattern"
			   :id="'input_' + index"
			   maxlength="1"
			   v-model="code[index]"
			   @input="handleInput"
			   @keypress="isValidByEvent"
			   @keydown.delete="handleDelete"
			   @paste="onPaste"
		/>
	</form>
</template>

<script lang="ts">
import {defineComponent, watch} from 'vue';

export default defineComponent({
	name: 'InputSplit',
	components: {},
	emits: ['submitPinInput', 'handleInputCount'],
	props: {
		autoSendSubmit: {
			type: Boolean,
			default: true,
		},
		inputLength: {
			type: Number,
			default: 6,
		},
		inputType: {
			type: String,
			default: 'text', //number...
		},
		inputPattern: {
			type: String,
			default: '[A-Za-z0-9]{1}', //[A-Z0-9]
		},
		allowedKeyList: {
			type: Array,
			default: (): string[] => ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		},
		inputValue: {
			type: String,
			default: '',
		},
	},
	setup(props, {emit}) {

		const code: string[] = Array(props.inputLength);
		let dataFromPaste: string[] | undefined;

		function isValidByValue(value: string): boolean {
			const re = new RegExp(props.inputPattern);
			return re.test(value);
		}

		function isValidByEvent(event: Event) {
			(event.currentTarget as HTMLInputElement).value = "";
			const keyPressed: string = (event as KeyboardEvent).key;
			if (!isValidByValue(keyPressed)) {
				event.preventDefault();
			}
			// if (!props.allowedKeyList.includes(keyPressed)) {
			// 	event.preventDefault();
			// }
		}

		function handleInput(event: Event) {

			const inputType = (event as InputEvent).inputType;
			let currentActiveElement = event.target as HTMLInputElement;

			if (inputType === "insertText")
				(currentActiveElement.nextElementSibling as HTMLElement)?.focus();

			if (inputType === "insertFromPaste" && dataFromPaste) {
				for (const num of dataFromPaste) {
					const id: number = parseInt(currentActiveElement.id.split("_")[1]);
					currentActiveElement.value = num.toUpperCase();
					code[id] = num;
					if (currentActiveElement.nextElementSibling) {
						currentActiveElement =
							currentActiveElement.nextElementSibling as HTMLInputElement;
						(currentActiveElement.nextElementSibling as HTMLElement)?.focus();
					}
				}
			}
			const fullCode = code.join('').toUpperCase();
			emit('handleInputCount', fullCode.length);
			if (props.autoSendSubmit && fullCode.length === props.inputLength) {
				emit('submitPinInput', fullCode);
			}
		}

		function handleDelete(event: Event) {
			const value = (event.target as HTMLInputElement).value;
			const currentActiveElement = event.target as HTMLInputElement;
			if (!value)
				(currentActiveElement.previousElementSibling as HTMLElement)?.focus();
		}

		//TODO watch inputValue and call onPaste method

		function onPaste(event: Event) {
			dataFromPaste = (event as ClipboardEvent).clipboardData
				?.getData("text")
				.trim()
				.split("");

			if (dataFromPaste) {
				for (const num of dataFromPaste) {
					if (!isValidByValue(num)) event.preventDefault();
				}
			}
		}

		watch(() => props.inputValue, (newValue: any) => {
			if (newValue && newValue.length > 0) {
				const dataToPaste = newValue.trim().split("");

				if (dataToPaste) {
					const re = new RegExp(props.inputPattern);
					for (const num of dataToPaste) {
						if (!re.test(num)) {
							return false;
						}
					}
				}
			}
		});

		return {
			code,
			handleInput,
			handleDelete,
			onPaste,
			isValidByEvent
		};
	}
})
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

.input-split-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: black;
}

.input-split {
    display: block;
    background-color: #E5E7EB;
    font-size: 1.325rem;
    line-height: 1.75rem;
    text-align: center;
    width: 100%;
    height: 3rem;
    min-height: 100%;
    text-transform: uppercase;
    border-radius: 0.8rem;
    padding: 3px;
    margin: 3px;
    /* box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px; */
    /* box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset; */
    box-shadow: rgb(6 24 44 / 40%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset;
}

.input-split:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (min-width: 640px) {
    .input-split-form {
        margin-left: 1rem;
    }
}
</style>
