<template>
	<button id="open-modal" type="button" class="shadow-lg rounded-md outline outline-2 outline-offset-2 outline-gray-700 px-3.5 py-1.5 text-sm text-gray-900 hover:bg-gray-50 hover:bg-opacity-60 mt-2">{{buttonTitle}}</button>

	<ion-modal ref="modal" trigger="open-modal" :can-dismiss="true" :presenting-element="presentingElement">
		<ion-header>
			<ion-toolbar>
				<ion-title>{{title}}</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="dismissModal()">Abbrechen</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<redeem-voucher-component :voucher-type="voucherType" :redirect-to-start-page="redirectToStartPage" :show-input-field="showInputField" @cancel="dismissModal()" class="pb-10"></redeem-voucher-component>
		</ion-content>
	</ion-modal>
</template>

<script lang="ts">
import {ref} from "vue";
import {
	IonButtons,
	IonButton,
	IonModal,
	IonHeader,
	IonContent,
	IonToolbar,
	IonTitle,
	modalController
} from '@ionic/vue';
import RedeemVoucherComponent from "@/components/voucher/RedeemVoucherComponent.vue";
import {MobiPointsQueueEventTypeRedeemVoucherType} from '@/mobipoints/queue/event/type/redeem_voucher_event.type';

export default {
	name: 'RedeemVoucherModal',
	components: {
		RedeemVoucherComponent,
		IonButtons,
		IonButton,
		IonModal,
		IonHeader,
		IonContent,
		IonToolbar,
		IonTitle,
	},
	props: {
		title: {
			type: String,
			default: "Gutschein einlösen"
		},
		buttonTitle: {
			type: String,
			default: "Gutschein einlösen"
		},
		voucherType: {
			type: String,
			default: MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE
		},
		redirectToStartPage: {
			type: Boolean,
			default: false,
		},
		showInputField: {
			type: Boolean,
			default: true,
		}
	},
	setup() {
		const presentingElement: any = ref(null);

		function dismissModal() {
			modalController.dismiss();
		}

		return {dismissModal, presentingElement}
	}
}
</script>

<style scoped>
ion-modal ion-toolbar {
    --background: rgb(229, 162, 24);
    --color: white;
}
ion-modal ion-toolbar ion-button {
    --color: #646464;
}
@media (min-width: 640px) {
    ion-modal {
        --height: 90%;
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    ion-modal::part(backdrop) {
        background: rgba(209, 213, 219);
        opacity: 1;
    }

    ion-modal ion-toolbar {
        --background: rgb(229, 162, 24);
        --color: white;
    }
}
</style>
