import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import {star} from "ionicons/icons";
import {dateTimestampToMilliseconds} from "@/mobipoints/core/date";

export class MobiPointsTrackingCoordinateCoordinateList {
	private items: MobiPointsTrackingCoordinateCoordinate[] = [];

	constructor(items: MobiPointsTrackingCoordinateCoordinate[] = []) {
		this.items.push(...items);
	}

	add(items: MobiPointsTrackingCoordinateCoordinate) {
		this.items.push(items);
	}

	push(item: MobiPointsTrackingCoordinateCoordinate) {
		this.items.push(item);
	}

	remove(index: number) {
		this.items.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.items.length;
		this.items.splice(itemCount, 1);
	}

	getItem(index: number): MobiPointsTrackingCoordinateCoordinate {
		return this.items[index];
	}

	getItems(): MobiPointsTrackingCoordinateCoordinate[] {
		return this.items;
	}

	getItemsForTimeRange(startDate: Date | null = null, endDate: Date | null = null, clone = true): MobiPointsTrackingCoordinateCoordinate[] {
		let clonedItemList:MobiPointsTrackingCoordinateCoordinate[]|null = null;
		if (clone) {
			clonedItemList = [...this.items];
		} else {
			clonedItemList = this.items;
		}

		if (!startDate && !endDate) {
			return clonedItemList;
		}

		if (startDate && !endDate) {
			return clonedItemList.filter((item: MobiPointsTrackingCoordinateCoordinate) => {
				return dateTimestampToMilliseconds(item.timestamp) >= startDate.getTime();
			});
		}

		if (startDate && endDate) {
			return clonedItemList.filter((item: MobiPointsTrackingCoordinateCoordinate) => {
				return dateTimestampToMilliseconds(item.timestamp) >= startDate.getTime() &&
					dateTimestampToMilliseconds(item.timestamp) <= endDate.getTime();
			});
		}

		if (!startDate && endDate) {
			return clonedItemList.filter((item: MobiPointsTrackingCoordinateCoordinate) => {
				return dateTimestampToMilliseconds(item.timestamp) <= endDate.getTime();
			});
		}

		return clonedItemList;
	}

	sortItems(desc = true, limit: number | null = null, clone = true): MobiPointsTrackingCoordinateCoordinate | MobiPointsTrackingCoordinateCoordinate[] | null {
		let clonedItemList:MobiPointsTrackingCoordinateCoordinate[]|null = null;
		if (clone) {
			clonedItemList = [...this.items];
		} else {
			clonedItemList = this.items;
		}

		if (!clonedItemList) {
			return null;
		}

		let sortedList:MobiPointsTrackingCoordinateCoordinate[]|null = null;
		if (desc) {
			sortedList = clonedItemList.sort((a: MobiPointsTrackingCoordinateCoordinate, b: MobiPointsTrackingCoordinateCoordinate) => b.timestamp -
				a.timestamp);
		} else {
			sortedList = clonedItemList.sort((a: MobiPointsTrackingCoordinateCoordinate, b: MobiPointsTrackingCoordinateCoordinate) => a.timestamp -
				b.timestamp);
		}

		if (sortedList) {
			if (limit && limit > 1) {
				return sortedList.slice(0, limit);
			} else if (limit === 1) {
				const lastCoordinate = sortedList.shift();
				if (lastCoordinate) {
					return lastCoordinate;
				} else {
					return null;
				}
			}
			return sortedList;
		}

		return null;
	}
}