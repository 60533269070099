export const enum WalletItemType {
	CORRECTION = 'correction',
	DEPOSIT = 'deposit',
	PAYOUT = 'payout',
}

export const enum WalletItemSubType {
	CHALLENGE = 'challenge',
	TRACKING = 'tracking',
	VOUCHER = 'voucher',
}


export const enum WalletItemState {
	ACTIVE = 'active',
	NULLIFY = 'nullify'
}


export interface WalletItemInterface {
	[key: string]: any;
	uuid: string,
	user_wallet_uuid: string,
	type: WalletItemType,
	state?: WalletItemState,
	subtype?: WalletItemSubType,
	amount?: number,
	reference?: string,
	data?: any,
	timestamp?: number,
}