import {NewsFactory} from "@/mobipoints/news/news_factory";

export default function useNewsFactory() {
	let newsFactory: NewsFactory | null = null;
	const getNewsFactory = (): NewsFactory => {
		if (!newsFactory) {
			newsFactory = new NewsFactory();
		}

		return newsFactory;
	}

	return {
		getNewsFactory,
	}
}