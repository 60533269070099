export const enum MobiPointsVoucherReason {
	INVALID_CODE = 'invalid_code', //(ungültiger Code oder Voucher nicht gefunden)
	INVALID_STATE = 'invalid_state',//(Voucher muss den State valid haben)
	INVALID_DATE = 'invalid_date',//(aktuelles Datum liegt außerhalb des Start- und Enddatums)
	USED = 'used',//(bereits einem User zugeordnet)
}

export interface MobiPointsVoucherValidationInterface {
	valid: boolean,
	reason: MobiPointsVoucherReason,
	message?: string,
}
