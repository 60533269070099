<template>
	<ion-page>
		<ion-fab v-if="isTreasureHuntEnabled" vertical="bottom" horizontal="center" slot="fixed" size="medium" :class="['treasureHuntFabButton', isIosPlatform() && isNativePlatform() ? 'mb-10' : '']">
			<ion-fab-button class="ion-no-margin" router-link="/tabs/treasurehunt" router-direction="root" color="warning">
				<img ref="treasureImage" class="mx-auto p-2 pb-3 h-14 w-auto z-50 shadow-amber-50"
					 :class="{ 'bounce': isBouncing }" src="../assets/images/treasure_hunt/treasure.png"
					 alt="Niceshops Treasure Hunt"/>
			</ion-fab-button>
		</ion-fab>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom">
				<ion-tab-button tab="home" href="/tabs/home" class="firstTab" @click="scrollToTopOnDoubleClick">
					<ion-icon :icon="icons.home"/>
					<ion-label>{{ mobiDashboardTitle }}</ion-label>
				</ion-tab-button>

				<!--        <ion-tab-button tab="detail" href="/tabs/userTrackingList">-->
				<!--          <ion-icon :icon="icons.list" />-->
				<!--          <ion-label>Trackings</ion-label>-->
				<!--        </ion-tab-button>-->

				<ion-tab-button tab="co2" href="/tabs/co2" :class="[isTreasureHuntEnabled ? 'pr-4' : '']">
					<ion-icon :icon="icons.leaf"/>
					<ion-label>{{ co2TabTitle }}</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="challenges" href="/tabs/challenges" :class="[isTreasureHuntEnabled ? 'pl-4' : '']">
					<ion-icon :icon="icons.happy"/>
					<ion-label>Challenges</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="profile" href="/tabs/profile" class="lastTab">
					<ion-icon :icon="icons.person"/>
					<ion-label>Profile</ion-label>
				</ion-tab-button>

				<!--        <ion-tab-button tab="redeemPoints" href="/tabs/redeemPoints" class="lastTab">-->
				<!--          <ion-icon :icon="icons.cart" />-->
				<!--          <ion-label>Punkte einlösen</ion-label>-->
				<!--        </ion-tab-button>-->
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script lang="ts">
import {
	IonIcon,
	IonLabel,
	IonPage,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonRouterOutlet, IonFabButton, IonFab,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useData from '@/composable/useData';
import useTrackingFactory from '@/composable/useTrackingFactory';
import {getDateDiffInSeconds, isNewerOrEqualToDate} from '@/mobipoints/core/date';
import {onMounted, ref} from 'vue';
import useSystem from '@/composable/useSystem';

export default {
	name: 'Tabs',
	components: {IonFab, IonFabButton, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet},
	setup() {
		const mobiDashboardTitle = useData().getTextValueByKey('tabs.mobi.dashboard.title', [], 'Mobi Dashboard');
		const co2TabTitle = useData().getTextValueByKey('tabs.mobi.co2.title', [], 'CO²');
		const tabClickLastTimestamp: any = ref(null);
		const tabClickCount = ref(0);
		const isTreasureHuntEnabled = ref(false);
		const {isIosPlatform, isNativePlatform} = useSystem();

		const isBouncing = ref(false);

		// const startBounce = () => {
		// 	isBouncing.value = true;
		// 	setTimeout(() => {
		// 		isBouncing.value = false;
		// 	}, 4500);
		// };

		onMounted(() => {
			// isTreasureHuntEnabled.value = useData().getSystemValueByKey('feature_flag_nice_treasure_hunt', false);
			// if (!isTreasureHuntEnabled.value) {
			// 	isTreasureHuntEnabled.value = isNewerOrEqualToDate('2024-03-11') || isDevelopmentEnv() || isDeveloper();
			// }
			// if (isTreasureHuntEnabled.value) {
			// 	startBounce();
			// }
		});

		function scrollToTopOnDoubleClick() {
			const startTimestamp = useTrackingFactory().getTrackingFactory().createCurrentTimeStamp();
			if (!tabClickLastTimestamp.value) {
				tabClickLastTimestamp.value = startTimestamp;
			}
			const dateDiffInSeconds = getDateDiffInSeconds(startTimestamp, tabClickLastTimestamp.value);
			if (dateDiffInSeconds > 4) {
				tabClickLastTimestamp.value = null;
				tabClickCount.value = 0;
			}
			if (tabClickCount.value === 2) {
				document.body.scrollTop = 0; // For Safari
				document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
				tabClickLastTimestamp.value = null;
				tabClickCount.value = 0;
			}
			tabClickCount.value++;
		}

		return {icons, mobiDashboardTitle, co2TabTitle, scrollToTopOnDoubleClick, isTreasureHuntEnabled, isBouncing, isIosPlatform, isNativePlatform};
	},
};
</script>

<style>
ion-tab-bar {
    bottom: 0px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-topright: 20px;
    width: 100%;
    box-shadow: 0 3px 28px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

ion-tab-button {
    --color: var(--ion-color-medium);
    --color-selected: var(--ion-color-primary);
}

ion-tab-button::before {
    background-color: transparent;
    display: block;
    content: "";
    margin: 0 auto;
    width: 20px;
    height: 2px;
}

ion-tab-button.tab-selected::before {
    background-color: var(--ion-color-primary);
}

.firstTab {
    border-top-left-radius: 20px;
}

.lastTab {
    border-top-right-radius: 20px;
}

.treasureHuntFabButton {
    @apply mb-1;
    @apply h-16 w-16;
    border-radius: 4rem;
    box-shadow: 0 0 .35em .175em rgba(var(--ion-color-warning-rgb), .33);
    //box-shadow: 0 0 .35em .175em rgba(var(--ion-color-dark-rgb), .33);
}

.treasureHuntFabButton ion-fab-button {
    @apply h-16 w-16;
    margin: auto;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.bounce {
    animation: bounce 1.5s ease infinite;
}
</style>
