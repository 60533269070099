import useSystem from "@/composable/useSystem";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {
	MobiPointsTrackingGeolocationWebBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/web/web_background_geolocation";
import {
	MobiPointsTrackingGeolocationNativeBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/native/native_background_geolocation";
import {
	MobiPointsTrackingGeolocationBackgroundGeolocationPermission
} from "@/mobipoints/tracking/geolocation/background_geolocation_permission";
import {
	MobiPointsTrackingGeolocationAbstractBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/abstract_background_geolocation";
import {Geolocation} from "@capacitor/geolocation";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import useMainData from "@/composable/useMainData";
import useData from "@/composable/useData";
const {getTrackingFactory} = useTrackingFactory();

export class MobiPointsTrackingGeolocationBackgroundGeolocationFacade {

	public getBackgroundGeolocationPermission(): MobiPointsTrackingGeolocationBackgroundGeolocationPermission
	{
		return getTrackingFactory().createBackgroundGeolocationPermission();
	}

	public async hasPermissions() {
		return await this.getBackgroundGeolocationPermission().checkPermissions();
	}

	public async handlePermissions() {
		return await this.getBackgroundGeolocationPermission().handlePermission();
	}

	protected getBackgroundGeolocation(): MobiPointsTrackingGeolocationAbstractBackgroundGeolocation|MobiPointsTrackingGeolocationWebBackgroundGeolocation|MobiPointsTrackingGeolocationNativeBackgroundGeolocation
	{
		let backgroundGeolocation: MobiPointsTrackingGeolocationWebBackgroundGeolocation|MobiPointsTrackingGeolocationNativeBackgroundGeolocation;
		if (useSystem().isNativePlatform()) {
			backgroundGeolocation = getTrackingFactory().createNativeBackgroundGeolocation();
		} else {
			backgroundGeolocation = getTrackingFactory().createWebBackgroundGeolocation();
		}

		return backgroundGeolocation;
	}

	public async startGeolocationTracking(forceStart = false) {
		await this.getBackgroundGeolocation().startGeolocationTracking(forceStart);
	}

	public async stopGeolocationTracking() {
		return await this.getBackgroundGeolocation().stopGeolocationTracking();
	}


	public async loadCurrentPosition(optionMaximumAge = 0) {
		let result: MobiPointsTrackingCoordinateCoordinateInterface|undefined;
		try {
			const options = {
				enableHighAccuracy: useMainData().userProfileSettings.value.enableHighAccuracy ?? true,
				timeout: Number(useData().getSystemValueByKey('gps_load_current_position_timeout', 8000)),
				maximumAge: optionMaximumAge,
			};
			const currentPosition = await Geolocation.getCurrentPosition(options);
			result = getTrackingFactory().createCoordinate(currentPosition.coords.latitude, currentPosition.coords.longitude, currentPosition.timestamp);
		} catch (error) {
			console.log('current position error', error)
		}
		return result;
	}
}