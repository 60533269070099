<template>
	<div class="ion-padding">
		<ion-list v-show="wallet?.items && wallet?.items.length > 0">
			<ion-list-header>Transaktionen</ion-list-header>
			<ion-item v-for="walletItem in wallet?.items" :key="walletItem.uuid">
				<ion-badge style="z-index: 9; width: 96%" class="text-base" :color="walletItem.getBadgeColor()"
						   slot="start">
					{{ walletItem.getAmountFormatted(true) }}
				</ion-badge>
				<ion-label>
					<h2 style="margin-top: 2rem" class="text-center text-slate-950 dark:text-slate-500">
						{{ walletItem.getItemName() }}</h2>
					<h3 class="text-center text-slate-900 dark:text-slate-400"
						v-html="walletItem.getItemDescription()"/>
					<h3 class="text-center text-slate-900 dark:text-slate-400">{{ walletItem.getDateFormatted() }}</h3>
				</ion-label>
			</ion-item>
		</ion-list>
		<ion-item v-show="wallet?.items && wallet?.items.length < 1">
			<ion-icon size="large" :icon="icons.informationCircleOutline"></ion-icon>
			<ion-label class="ion-text-wrap" style="margin-left: .5rem;">Es sind noch kein Einträge in deinem Wallet
				vorhanden!
			</ion-label>
		</ion-item>
		<ion-infinite-scroll
				@ionInfinite="loadMoreWalletItems($event)"
				threshold="100px"
				id="infinite-scroll"
		>
			<ion-infinite-scroll-content
					loading-spinner="dots"
					loading-text="Loading more wallet items...">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
	</div>
</template>

<script lang="ts">
import {onMounted, ref, watch} from 'vue';
import {
	IonItem,
	IonList,
	IonLabel,
	IonIcon,
	IonBadge,
	IonInfiniteScroll,
	IonListHeader,
	IonInfiniteScrollContent,
	InfiniteScrollCustomEvent, toastController, loadingController,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useWallet from '@/composable/useWallet';
import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import {AxiosError} from 'axios';
import {useCoordinate} from '@/composable/useCoordinate';
import useData from '@/composable/useData';

export default {
	name: 'UserWalletItemList',
	components: {
		IonInfiniteScrollContent,
		IonListHeader,
		IonInfiniteScroll,
		IonBadge,
		IonItem,
		IonList,
		IonLabel,
		IonIcon,
	},
	props: {
		wallet: {
			type: AbstractWallet,
		},
	},
	setup(props: any) {
		const {getTextValueByKey} = useData();
		const {initWalletItems, loadWalletItems, setWalletItems} = useWallet();
		const currentWalletItemCount = ref<number>(25);
		const isLoading = ref(false);

		async function initWalletItems_first_Time(uuid: string) {
			try {
				const loading = await loadingController.create({
					cssClass: '',
					duration: 3000,
				});

				setTimeout(function() {
					loading.dismiss();
				}, 3000);

				loading.present().then(() => {
					initWalletItems(uuid).then(() => {
						isLoading.value = false;
						loading.dismiss();
					});
				});
			} catch (error: any | AxiosError) {
				isLoading.value = false;
			}
		}

		async function loadWalletItems_for_Component(uuid: string) {
			try {
				loadWalletItems(uuid, currentWalletItemCount.value).then((walletItemList) => {
					setWalletItems(uuid, walletItemList);
					isLoading.value = false;
				});
			} catch (error: any | AxiosError) {
				isLoading.value = false;
				const toast = await toastController.create({
					message: `Could not load wallet items!`,
					position: 'bottom',
					duration: 2000,
					icon: icons.warning,
					buttons: [
						{
							side: 'end',
							role: 'cancel',
							icon: icons.close,
						},
					],
				});

				await toast.present();
			}
		}

		function loadMoreWalletItems(ev: InfiniteScrollCustomEvent) {
			if (isLoading.value || !props.wallet?.uuid) {
				ev.target.complete();
				return;
			}
			setTimeout(async () => {
				if (props.wallet && props.wallet.items?.length <= currentWalletItemCount.value) {
					currentWalletItemCount.value += 25;
					await loadWalletItems_for_Component(props.uuid);
					await ev.target.complete();
				} else {
					ev.target.disabled = true;
				}
			}, 500);
		}

		onMounted(() => {
			if (props.wallet) {
				initWalletItems_first_Time(props.wallet.uuid);
			}
		});

		watch(() => props.wallet, (newValue: AbstractWallet | any, oldValue: AbstractWallet | any) => {
			if ((newValue.items === undefined) || !oldValue) {
				initWalletItems_first_Time(props.wallet.uuid);
			}
		});

		return {
			icons,
			loadMoreWalletItems,
		};
	},
};
</script>

<style scoped>

ion-badge {
    position: absolute;
    top: 0;
    margin-top: 10px;
    left: 0;
    margin-left: 5px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    line-height: 20px;
}

ion-avatar ion-icon {
    width: 100%;
    height: 100%;
    color: var(--ion-color-step-800);
}

ion-item[is-current-user='false'] {
    border-left: 2px solid transparent;
}

ion-item[is-current-user='true'] {
    border-left: 2px solid var(--ion-color-primary);
}

ion-badge {
    width: auto;
}

ion-item {
    @apply px-0 py-1 text-left;
}

ion-item::part(native) .item-inner {
    border-style: none !important;
}

ion-item::part(native) {
    @apply bg-white dark:bg-gray-800 shadow-md rounded-md;
}

ion-item::part(detail-icon) {
    @apply text-gray-700 dark:text-white;
    opacity: 1;
    font-size: 20px;
}
</style>
