import {MobiPointsQueueEventAbstractEvent} from "@/mobipoints/queue/event/abstract_event.type";
import {MobiPointsQueueEventInterface} from "@/mobipoints/queue/event/event.interface";
import useTrackingFactory from "@/composable/useTrackingFactory";

const queue = {
	namespaced: true,
	state: () => ({
		// eventList: Array<MobiPointsQueueEventAbstractEvent>
		eventList: Array<MobiPointsQueueEventAbstractEvent | MobiPointsQueueEventInterface>(),
		queueInterval: null as number|null,
		lastEventSendTimestamp: null as number|null,
		isSending: false,
	}),
	mutations: {
		setQueueInterval(state: any, queueInterval: number|null) {
			state.queueInterval = queueInterval;
		},
		setIsSending(state: any, value: boolean) {
			state.isSending = value;
		},
		addEvent(state: any, event: MobiPointsQueueEventAbstractEvent) {
			if (!state.eventList) {
				state.eventList = [];
			}
			state.eventList.push(event);
		},
		addEvents(state: any, events: Array<MobiPointsQueueEventAbstractEvent>) {
			if (!state.eventList) {
				state.eventList = [];
			}
			state.eventList.push(...events);
		},
		removeEvent(state: any, event: MobiPointsQueueEventAbstractEvent) {
			const eventToDeleteIndex = state.eventList.findIndex(currentEvent => currentEvent.id === event.id);
			if (eventToDeleteIndex) {
				state.eventList = state.eventList.filter((event, index) => event !== eventToDeleteIndex);
			}
		},
		removeEvents(state: any, eventsToDelete: Array<MobiPointsQueueEventAbstractEvent>) {
			const arrEventsToDelete: Array<string> = [];
			for (const item of eventsToDelete) {
				const eventToDeleteIndex = state.eventList.findIndex((it) => {
					return it.id == item.id
				})
				if (eventToDeleteIndex > -1) {
					arrEventsToDelete.push(eventToDeleteIndex);
				}
			}
			state.eventList = state.eventList.filter((event, index) => !arrEventsToDelete.includes(index));
			state.lastEventSendTimestamp = useTrackingFactory().getTrackingFactory().createCurrentTimeStamp();
		},
		setLastEventTimestamp(state: any, timestamp: number) {
			state.lastEventSendTimestamp = timestamp;
		},
	},
	actions: {},
	getters: {
		queueInterval: (state: any): number|null => {
			return state.queueInterval;
		},

		getEventList: (state: any): Array<MobiPointsQueueEventAbstractEvent> => {
			return state.eventList;
		},

		getLastEventTimestamp: (state: any): number|null => {
			return state.lastEventSendTimestamp;
		},

		hasOpenEvents: (state: any) => {
			return state.queueList.length;
		},

		isSending: (state: any): boolean => {
			return state.isSending;
		},
	}
}

export default queue;
