import {
	AbstractWalletItem
} from '@/mobipoints/wallet/item/abstract_wallet_item.type';
import useData from '@/composable/useData';

export class WalletItemPayout extends AbstractWalletItem{
	getBadgeColor(): string {
		return 'danger';
	}

	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.payout.name', [], 'Auszahlung');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount = super.getAmountFormatted(withSuffix);
		if (withSuffix) {
			amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.payout.amount.suffix', [], 'Punkte ausgegeben');
		}
		return amount;
	}
}