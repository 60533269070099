<template>
	<!--	<ion-page>-->
	<ion-content fullscreen>
		<ion-list lines="full" v-show="userLocationListData.length > 0" inset="true">
			<ion-item-sliding v-for="userLocation in userLocationListData" :key="userLocation.id">
				<ion-item @click="showDetailLocation(userLocation.id)">
					<ion-icon :icon="userLocation.type.icon" slot="start"></ion-icon>
					<ion-label>
            <span v-for="(addressItem, idx) in userLocation.getFormattedAddressList()" :key="idx"
				  style="display:block;">
              <strong v-if="idx === 0">{{ addressItem }}</strong>
              <span v-else>{{ addressItem }}</span>
            </span>
					</ion-label>
				</ion-item>
				<ion-item-options side="end">
					<ion-item-option color="danger" @click="deleteUserLocation(userLocation.id)">Delete
					</ion-item-option>
				</ion-item-options>
			</ion-item-sliding>
		</ion-list>

		<ion-list-header v-show="userLocationListData.length === 0">Keine Addressen gefunden</ion-list-header>
	</ion-content>
	<!--	</ion-page>-->
</template>

<script lang="ts">
import {ref, defineComponent, onMounted, watch} from 'vue';
import {
	IonItem, IonList,
	IonItemOption,
	IonItemOptions,
	IonItemSliding,
	IonLabel,
	IonContent,
	IonListHeader,
	IonIcon
} from '@ionic/vue';
import {
	add,
} from 'ionicons/icons';
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import {ProfileFactory} from "@/mobipoints/profile/profile_factory";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import router from "@/router";
import useToastMessage from "@/components/core/ToastMessage.vue";
import store from "@/store";
import useMainData from "@/composable/useMainData";
import * as icons from 'ionicons/icons';

export default defineComponent({
	name: 'UserLocationList',
	components: {
		IonItem, IonList,
		IonItemOption,
		IonItemOptions,
		IonItemSliding,
		IonLabel,
		IonContent,
		IonListHeader,
		IonIcon
	},
	props: {},
	setup() {
		const {openToast} = useToastMessage();
		const mainData = useMainData();
		const userLocationListData = ref<UserLocation[]>(mainData.userLocationsEditable.value);

		onMounted(() => {
			mainData.reloadUserLocations();
		})

		watch(mainData.userLocationsEditable, async () => {
			userLocationListData.value = mainData.userLocationsEditable.value;
		});

		function getProfileFactory(): ProfileFactory {
			return new ProfileFactory();
		}

		function deleteUserLocation(userLocationId: number) {
			MobiPointApiProfile.deleteUserLocation(userLocationId).then((response) => {
				if (response) {
					userLocationListData.value = userLocationListData.value.filter(x => {
						return x.id != userLocationId;
					});
					store.commit('mainData/resetUserLocations');
					mainData.reloadUserLocations();
					openToast("Der ausgewählte Standort wurde erfolgreich gelöscht!", "success", "top")
				}
			}).catch(err => {
				openToast("Leider ist ein Fehler aufgetreten! Bitte nocheinmal probieren :)", "danger", "top")
				console.log(err)
			});
		}

		function showDetailLocation(userLocationId: number) {
			if (userLocationId) {
				router.push("/profile/userLocation/detail/" + userLocationId);
			}
		}

		return {userLocationListData, showDetailLocation, deleteUserLocation, add, icons}
	}
})
</script>

<style scoped>

</style>