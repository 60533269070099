// export const LOCATION_TYPE_COMPANY_LOCATION = 'company_location';
// export const LOCATION_TYPE_HOME_LOCATION = 'home_location';
// export const LOCATION_TYPE_USER_LOCATION = 'user_location';

export const enum MobiPointsLocationTypes {
	COMPANY_LOCATION = 'company_location',
	HOME_LOCATION = 'home_location',
	USER_LOCATION = 'user_location',
}

export interface MobiPointsLocationTypeInterface {
	code: MobiPointsLocationTypes;
	name: string;
	icon?: string;
}

export interface MobiPointsLocationCountryInterface {
	code: string;
	name: string;
}