import {MobiPointsNewsInterface} from "@/mobipoints/news/news/news.interface";
import {MobiPointsCoreOsDeviceTypeInterface} from "@/mobipoints/core/os_device_type.interface";

export const NEWS_TYPE_BANNER = 'banner_type';
export const NEWS_TYPE_CARD = 'card_type';
export const NEWS_TYPE_POPUP = 'popup_type';

export class News implements MobiPointsNewsInterface {
	private _title = '';
	private _type = '';
	private _description: string | null = null;
	private _image?: string | null = null;
	private _show = false;
	private _showButton = false;
	private _buttonType?: string | null = null;
	private _buttonLink?: string | null = null;
	private _buttonText?: string | null = null;
	private _buttonColor?: string | null = null;
	private _startDate?: Date | null = null;
	private _endDate?: Date | null = null;
	private _fullscreen = false;
	private _specificShowType?: MobiPointsCoreOsDeviceTypeInterface | null = null;

	constructor(title: string, type: string) {
		this.title = title;
		this.type = type;
	}


	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}


	get type(): string {
		return this._type;
	}

	set type(value: string) {
		this._type = value;
	}

	get description(): string | null {
		return this._description;
	}

	set description(value: string | null) {
		this._description = value;
	}

	get image(): string | null | undefined {
		return this._image;
	}

	set image(value: string | null | undefined) {
		this._image = value;
	}

	get show(): boolean {
		return this._show;
	}

	set show(value: boolean) {
		this._show = value;
	}

	get showButton(): boolean {
		return this._showButton;
	}

	set showButton(value: boolean) {
		this._showButton = value;
	}

	get buttonType(): string | null | undefined {
		return this._buttonType;
	}

	set buttonType(value: string | null | undefined) {
		this._buttonType = value;
	}

	get buttonLink(): string | null | undefined {
		return this._buttonLink;
	}

	set buttonLink(value: string | null | undefined) {
		this._buttonLink = value;
	}

	get buttonText(): string | null | undefined {
		return this._buttonText;
	}

	set buttonText(value: string | null | undefined) {
		this._buttonText = value;
	}

	get buttonColor(): string {
		if (!this._buttonColor) {
			this._buttonColor = 'primary';
		}
		return this._buttonColor;
	}

	set buttonColor(value: string | null | undefined) {
		this._buttonColor = value;
	}

	get startDate(): Date | null | undefined {
		return this._startDate;
	}

	set startDate(value: Date | null | undefined) {
		this._startDate = value;
	}

	get endDate(): Date | null | undefined {
		return this._endDate;
	}

	set endDate(value: Date | null | undefined) {
		this._endDate = value;
	}

	get fullscreen(): boolean {
		return this._fullscreen;
	}

	set fullscreen(value: boolean) {
		this._fullscreen = value;
	}

	get specificShowType(): MobiPointsCoreOsDeviceTypeInterface|null|undefined {
		return this._specificShowType;
	}

	set specificShowType(value: MobiPointsCoreOsDeviceTypeInterface|null|undefined) {
		this._specificShowType = value;
	}
}
