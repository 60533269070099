import {onMounted, ref} from 'vue';
import {Geolocation} from '@capacitor/geolocation';
import {NativeGeocoderResult, NativeGeocoderOptions } from "@ionic-native/native-geocoder";
import useSystem from "@/composable/useSystem";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";

export interface AddressInterface {
	street: string;
	streetNumber: string;
	city: string;
	locality: string;
	political: string;
	country: string;
	countryName: string;
	postalCode: string;
	adminArea1: string;
	adminArea2: string;
	formattedAddress: string;
	latitude?: number;
	longitude?: string;
}

interface GoogleMapsAddressData {
	route: string;
	streetNumber: string;
	city: string;
	locality: string;
	political: string;
	country: string;
	countryName: string;
	postalCode: string;
	adminArea1: string;
	adminArea2: string;
	formattedAddress: string;
}

interface CoordinateForGeoCodingInterface {
	latitude: number;
	longitude: number;
}

export function useCoordinate() {

	const address = ref<AddressInterface>();

	const nativeGeocoderOptions: NativeGeocoderOptions = {
		useLocale: true,
		maxResults: 5
	};

	function createEmptyAddress(): AddressInterface
	{
		return {
			street: "",
			streetNumber: "",
			city: "",
			locality: "",
			political: "",
			country: "",
			countryName: "",
			postalCode: "",
			adminArea1: "",
			adminArea2: "",
			formattedAddress: "",
		};
	}

	onMounted(() => {
		address.value = createEmptyAddress();
	})

	async function getCurrentPosition() {
		const coordinates = await Geolocation.getCurrentPosition();
		return {latitude: coordinates.coords.latitude, longitude: coordinates.coords.longitude};
	}

	function prettifyAddress(addressToPrettify: NativeGeocoderResult[]) {
		const obj = [] as any;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		let data = "";
		for (const key in addressToPrettify) {
			obj.push(addressToPrettify[key]);
		}
		obj.reverse();
		for (const val in obj) {
			if (obj[val].length)
				data += obj[val] + ', ';
		}
		return addressToPrettify.slice(0, -2).toString();
	}

	function initAddress(
		street = "",
		streetNumber = "",
		city = "",
		locality = "",
		political = "",
		country = "",
		countryName = "",
		postalCode = "",
		adminArea1 = "",
		adminArea2 = "",
		formattedAddress = "",
	){
		if (address.value){
			address.value.street = street;
			address.value.streetNumber = streetNumber;
			address.value.city = city;
			address.value.locality = locality;
			address.value.political = political;
			address.value.country = country;
			address.value.countryName = countryName;
			address.value.postalCode = postalCode;
			address.value.adminArea1 = adminArea1;
			address.value.adminArea2 = adminArea2;
			address.value.formattedAddress = formattedAddress;

			if (formattedAddress.length === 0){
				address.value.formattedAddress = street + ' ' + streetNumber + ', ' + city + ' ' + postalCode + ', ' + country;
			}
		}
	}

	async function getAddressFromCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinateInterface|null, includeCoordinates = false) {
		address.value = createEmptyAddress();
		let coordinateForCalculation: CoordinateForGeoCodingInterface;
		if (!coordinate) {
			coordinateForCalculation = await getCurrentPosition();
		} else {
			coordinateForCalculation = {latitude: coordinate.latitude, longitude: coordinate.longitude};
		}

		return await getAddressFromCurrentLocation(coordinateForCalculation, includeCoordinates);
	}

	// get address using coordinates
	async function getAddressFromCurrentLocation(coordinates: CoordinateForGeoCodingInterface|null, includeCoordinates = false) {
		if (!coordinates) {
			coordinates = await getCurrentPosition();
		} else {
			coordinates = {latitude: coordinates.latitude, longitude: coordinates.longitude};
		}
		let latitude;
		let longitude;
		if (coordinates){
			latitude = coordinates.latitude;
			longitude = coordinates.longitude;
		} else {
			return address;
		}

		if (includeCoordinates && address.value) {
			address.value.latitude = latitude;
			address.value.longitude = longitude;
		}

		if (useSystem().isNativePlatform()) {
			await initAddressByGoogle(latitude, longitude);
			// NativeGeocoder.reverseGeocode(latitude, longitude, nativeGeocoderOptions)
			// const foo = NativeGeocoder.reverseGeocode(latitude, longitude, nativeGeocoderOptions).then((res: NativeGeocoderResult[]) => {
			// 		console.log("NATIVE RESULT ", res)
			// 		const prettifyAddressData = res[0];
			// 		useSystem().addLog('get address by cords native, ' + JSON.stringify(res))
			// 		// const prettifyAddressData = prettifyAddress(res.slice(0));
			// 		initAddress(prettifyAddressData.locality, prettifyAddressData.subLocality, prettifyAddressData.administrativeArea, prettifyAddressData.locality, "", prettifyAddressData.countryCode, prettifyAddressData.countryName, prettifyAddressData.postalCode);
			// 	})
			// 	.catch((error: any) => {
			// 		console.log(error);
			// 		//TODO fallback
			// 		// const googleMapsAddressResponse: any = await getAddressByGoogleMapsGeocoding(latitude, longitude);
			// 		// if (googleMapsAddressResponse && googleMapsAddressResponse.results) {
			// 		// 	const firstAddress = googleMapsAddressResponse.results[0];
			// 		// 	const googleMapsAddressData = getGoogleMapsAddressData(firstAddress);
			// 		// 	initAddress(googleMapsAddressData.route, googleMapsAddressData.streetNumber, googleMapsAddressData.city, googleMapsAddressData.locality, googleMapsAddressData.political, googleMapsAddressData.country, googleMapsAddressData.countryName, googleMapsAddressData.postalCode, googleMapsAddressData.adminArea1, googleMapsAddressData.adminArea2, googleMapsAddressData.formattedAddress);
			// 		// }
			// 	});
		} else {
			await initAddressByGoogle(latitude, longitude);
		}
		return address;
	}

	async function initAddressByGoogle(latitude: number, longitude: number) {
		try {
			const googleMapsAddressResponse: any = await getAddressByGoogleMapsGeocoding(latitude, longitude);
			if (googleMapsAddressResponse && googleMapsAddressResponse.results) {
				const firstAddress = googleMapsAddressResponse.results[0];
				const googleMapsAddressData = getGoogleMapsAddressData(firstAddress);
				initAddress(googleMapsAddressData.route, googleMapsAddressData.streetNumber, googleMapsAddressData.city, googleMapsAddressData.locality, googleMapsAddressData.political, googleMapsAddressData.country, googleMapsAddressData.countryName, googleMapsAddressData.postalCode, googleMapsAddressData.adminArea1, googleMapsAddressData.adminArea2, googleMapsAddressData.formattedAddress);
			}
		} catch (error) {
			console.log(error);
			return false;
		}

		return true;
	}

	async function getAddressByGoogleMapsGeocoding(latitude: number, longitude: number)
	{
		try {
			const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + '&key=' + process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
			const response = await fetch(url, {
				method: "GET",
				mode: 'cors',
				headers: {
					'Accept-Language': '*',
				},
			});
			return await response.clone().json();
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	function getGoogleMapsAddressData(addressData: any = {}): GoogleMapsAddressData{
		const googleMapsAddressData = {} as GoogleMapsAddressData;
		if (addressData && addressData.address_components){
			googleMapsAddressData.formattedAddress = addressData.formatted_address;
			addressData.address_components.forEach((addressData) => {
				if (addressData && addressData.types){
					if (addressData.types && Array.isArray(addressData.types)){
						addressData.types.forEach((type) => {
							switch(type) {
								case "route": {
									googleMapsAddressData.route = addressData.short_name;
									break;
								}
								case "street_number": {
									googleMapsAddressData.streetNumber = addressData.short_name;
									break;
								}
								case "city": {
									if (!googleMapsAddressData.city && addressData.short_name) {
										googleMapsAddressData.city = addressData.short_name;
									}
									break;
								}
								case "locality": {
									if (googleMapsAddressData.city === undefined){
										googleMapsAddressData.city = addressData.short_name;
									}
									googleMapsAddressData.locality = addressData.short_name;
									break;
								}
								case "postal_town": {
									if (googleMapsAddressData.city === undefined || !googleMapsAddressData.city.length){
										googleMapsAddressData.city = addressData.short_name;
									}
									break;
								}
								case "political": {
									googleMapsAddressData.political = addressData.short_name;
									break;
								}
								case "administrative_area_level_2": {
									googleMapsAddressData.adminArea1 = addressData.short_name;
									break;
								}
								case "administrative_area_level_1": {
									googleMapsAddressData.adminArea2 = addressData.short_name;
									break;
								}
								case "country": {
									googleMapsAddressData.country = addressData.short_name;
									googleMapsAddressData.countryName = addressData.long_name;
									break;
								}
								case "postal_code": {
									googleMapsAddressData.postalCode = addressData.short_name;
									break;
								}
								default: {
									break;
								}
							}
						})
					}
				}
			});
		}
		return googleMapsAddressData;
	}

	// get address using coordinates
	async function getAddressByCoords(latitude: number, longitude: number) {
		const response = await getAddressByGoogleMapsGeocoding(latitude, longitude);
		if (response) {
			const firstAddress = response.results[0];
			const googleMapsAddressData = getGoogleMapsAddressData(firstAddress);
			initAddress(googleMapsAddressData.route, googleMapsAddressData.streetNumber, googleMapsAddressData.city, googleMapsAddressData.locality, googleMapsAddressData.political, googleMapsAddressData.country, googleMapsAddressData.countryName, googleMapsAddressData.postalCode, googleMapsAddressData.adminArea1, googleMapsAddressData.adminArea2, googleMapsAddressData.formattedAddress);
		} else {
			console.log('Error, cannot get location!');
			alert('Error, cannot get location!');
		}
		// if (useSystem().isNativePlatform()) {
		// 	const response: NativeGeocoderResult[] = await NativeGeocoder.reverseGeocode(latitude, longitude, nativeGeocoderOptions);
		// 	if (response) {
		// 		const prettifyAddressData = response[0];
		// 		initAddress(prettifyAddressData.locality, prettifyAddressData.subLocality, prettifyAddressData.administrativeArea, prettifyAddressData.locality, "", prettifyAddressData.countryCode, prettifyAddressData.countryName, prettifyAddressData.postalCode);
		// 	}
		// } else {
		// 	const response = await getAddressByGoogleMapsGeocoding(latitude, longitude);
		// 	if (response) {
		// 		const firstAddress = response.results[0];
		// 		const googleMapsAddressData = getGoogleMapsAddressData(firstAddress);
		// 		initAddress(googleMapsAddressData.route, googleMapsAddressData.streetNumber, googleMapsAddressData.city, googleMapsAddressData.locality, googleMapsAddressData.political, googleMapsAddressData.country, googleMapsAddressData.countryName, googleMapsAddressData.postalCode, googleMapsAddressData.adminArea1, googleMapsAddressData.adminArea2, googleMapsAddressData.formattedAddress);
		// 	} else {
		// 		alert('Error getting location');
		// 	}
		// }
		return address.value;
	}

	return {
		address,
		getAddressFromCurrentLocation,
		getCurrentPosition,
		getAddressByCoords,
		getAddressByGoogleMapsGeocoding,
		getAddressFromCoordinate,
	};
}