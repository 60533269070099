<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button shape="round" v-if="currentRouteName === 'UserTrackingTemplateDetail'"
                      @click="$router.replace({ name: 'UserTrackingTemplateOverview' })">
            <ion-icon slot="start" :icon="icons.arrowBackOutline"></ion-icon>
          </ion-button>
          <ion-button shape="round" v-if="currentRouteName === 'UserTrackingTemplateEdit'"
                      @click="$router.replace({ name: 'UserTrackingTemplateDetail', params : {id : userTrackingTemplateId} })">
            <ion-icon slot="start" :icon="icons.arrowBackOutline"></ion-icon>
          </ion-button>
          <ion-back-button v-if="currentRouteName === 'UserTrackingTemplateOverview'" :text="backButtonText"
                           default-href="/profile"></ion-back-button>
        </ion-buttons>
        <ion-title>Meine Tracking Vorlagen</ion-title>
        <ion-buttons v-if="currentRouteName === 'UserTrackingTemplateDetail'" slot="end">
          <ion-button @click="deleteUserTrackingTemplate(userTrackingTemplateId)">
            <ion-icon :icon="icons.trashOutline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-button @click="editUserTrackingTemplate(userTrackingTemplateId)">
            <ion-icon :icon="icons.pencilOutline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="currentRouteName === 'UserTrackingTemplateOverview'" slot="end">
          <ion-button @click="toogleReorderUserTrackingTemplates">
            <ion-icon :icon="icons.reorderTwoOutline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-button @click="addUserTrackingTemplate">
            <ion-icon :icon="icons.add"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" ref="content">
      <div v-if="currentRouteName === 'UserTrackingTemplateOverview'">
        <user-tracking-template-overview ref="userTrackingTemplateOverview" :show-scroll-to-top="scroll"></user-tracking-template-overview>
      </div>
      <div v-if="currentRouteName === 'UserTrackingTemplateDetail'">
        <user-tracking-template-detail ref="userTrackingTemplateDetail"
                                       :user-tracking-template-id="userTrackingTemplateId"></user-tracking-template-detail>
      </div>
      <div v-if="currentRouteName === 'UserTrackingTemplateEdit'">
        <user-tracking-template-edit ref="userTrackingTemplateEdit"
                                     :user-tracking-template-id="userTrackingTemplateId"></user-tracking-template-edit>
      </div>
      <div v-if="currentRouteName === 'UserTrackingTemplateAdd'">
        <user-tracking-template-edit ref="userTrackingTemplateEdit"></user-tracking-template-edit>
      </div>
      <ion-fab v-if="currentRouteName === 'UserTrackingTemplateOverview'" vertical="bottom" horizontal="end"
               slot="fixed">
        <ion-fab-button @click="addUserTrackingTemplate">
          <ion-icon size="large" :icon="icons.add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

    </ion-content>
    <ion-footer v-if="currentRouteName === 'UserTrackingTemplateEdit' || currentRouteName === 'UserTrackingTemplateAdd'">
      <ion-toolbar>
        <ion-button slot="start" color="medium" class="ion-margin-start" @click="cancelUserTrackingTemplate">
          <ion-icon :icon="icons.close"></ion-icon>
          <ion-label style="margin-left: .25rem;">Cancel</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button slot="end" class="ion-margin-end" @click="saveUserTrackingTemplate">
          <ion-icon :icon="icons.save"></ion-icon>
          <ion-label style="margin-left: .5rem;">Save</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {defineComponent} from "vue";
//  explicit name definition not required
export default defineComponent({
  name: "UserTrackingTemplate",
})
</script>
<script setup lang="ts">
import {computed, onBeforeMount, onMounted, onUpdated, ref} from "vue";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonRippleEffect,
  IonFab, IonFabButton,
  onIonViewDidEnter, onIonViewDidLeave, alertController, toastController
} from "@ionic/vue";

import UserTrackingTemplateOverview from "@/components/profile/userTracking/UserTrackingTemplateOverview.vue";
import {RouteParams, useRoute} from "vue-router";
import UserTrackingTemplateDetail from "@/components/profile/userTracking/UserTrackingTemplateDetail.vue";
import * as icons from 'ionicons/icons';
import UserTrackingTemplateEdit from "@/components/profile/userTracking/UserTrackingTemplateEdit.vue";
import router from "@/router";
import {AxiosError, AxiosResponse} from "axios";
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import useData from "@/composable/useData";

const content = ref<InstanceType<typeof IonContent> | null>(null);
const userTrackingTemplateEdit = ref<InstanceType<typeof UserTrackingTemplateEdit> | null>(null);
const userTrackingTemplateDetail = ref<InstanceType<typeof UserTrackingTemplateDetail> | null>(null);
const userTrackingTemplateOverview = ref<InstanceType<typeof UserTrackingTemplateOverview> | null>(null);
const scroll = ref(false);
const currentRouteName = ref<string>();
const currentRouteParams = ref<RouteParams>();
const userTrackingTemplateId = computed(() => {
  return Number(currentRouteParams.value && currentRouteParams.value.id);
});
const {getTextValueByKey} = useData();
const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

onBeforeMount(() => {
  const currentRoute = useRoute();
  currentRouteName.value = String(currentRoute.name);
  currentRouteParams.value = currentRoute.params || {};
});

onUpdated(() => {
  // console.log("UPDATE: content is", content.value);
});

const deleteUserTrackingTemplate = async function (id: number) {
  const alert = await alertController.create({
    header: 'Sicherheitsabfrage:',
    message: 'Tracking Vorlage wirklich löschen?',
    buttons: [
      {
        text: 'Nein',
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: 'Ja',
        cssClass: 'danger',
        handler: async function () {
          let response: AxiosResponse | null = null;
          let message = '';
          try {
            response = await MobiPointApiProfile.deleteUserTrackingTemplate(id);
            if (!response.data.status || response.data.status !== 'error') {
              if (currentRouteName.value === 'UserTrackingTemplateDetail') {
                router.replace({
                  name: 'UserTrackingTemplateOverview',
                })
              } else {
                router.back();
              }

              return;
            }

            message = `Could not delete tracking template error: ${response.data.message || 'Unkown'}` || message;
          } catch (error: any | AxiosError) {
            message = `Could not delete tracking template! Please try again :)`;
          }

          const toast = await toastController.create({
            message: message,
            position: 'bottom',
            duration: 3000,
            icon: icons.warning,
            buttons: [
              {
                side: 'end',
                role: 'cancel',
                icon: icons.close,
              }
            ],
          });

          return toast.present();
        },
      },
    ]
  });

  return alert.present();
};

const editUserTrackingTemplate = (id: number) => {
  router.replace({
    name: 'UserTrackingTemplateEdit',
    params: {id: id}
  });
};

const cancelUserTrackingTemplate = () => {
  if (currentRouteName.value === 'UserTrackingTemplateEdit') {
    router.replace({
      name: 'UserTrackingTemplateDetail',
      params: {id: userTrackingTemplateId.value}
    });
  } else if(currentRouteName.value === 'UserTrackingTemplateAdd') {
    router.replace({
      name: 'UserTrackingTemplateOverview',
    });
  } else {
    router.back();
  }
};

const saveUserTrackingTemplate = async function (): Promise<boolean> {
  const valid = await userTrackingTemplateEdit.value.validateUserTrackingTemplate();
  if (!valid) {
    return false;
  }
  const response = await userTrackingTemplateEdit.value.saveUserTrackingTemplate();

  if (currentRouteName.value === 'UserTrackingTemplateEdit') {
    await router.replace({
      name: 'UserTrackingTemplateDetail',
      params: {id: userTrackingTemplateId.value}
    });
  } else if(currentRouteName.value === 'UserTrackingTemplateAdd') {
    await router.replace({
      name: 'UserTrackingTemplateOverview',
    });
  }

  return null !== response;
};


const addUserTrackingTemplate = () => {
  router.replace({
    name: 'UserTrackingTemplateAdd',
  });
}

const toogleReorderUserTrackingTemplates = () => {
  userTrackingTemplateOverview.value.toggleReorderDisabled();
}

// onIonViewDidEnter(() => console.log("PAGE DID ENTER UserTrackingTemplate"));
// onIonViewDidLeave(() => console.log("PAGE DID LEAVE UserTrackingTemplate"));
</script>

<style scoped>
</style>