import useData from '@/composable/useData';
import {
	WalletItemDeposit
} from '@/mobipoints/wallet/item/deposit/wallet_item_deposit.type';

export class TreasureWalletItemDeposit extends WalletItemDeposit{
	getBadgeColor(): string {
		return 'success';
	}

	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.deposit.name', [], 'Einzahlung');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount: string;
		if (!this.amount) {
			amount =  '0';
		} else {
			amount = this.amount?.toFixed(0);
		}
		if (withSuffix) {
			if (this.amount == 1) {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.deposit.amount.suffix', [], 'Los erhalten');
			} else {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.deposit.amount.suffix', [], 'Lose erhalten');
			}
		}

		return amount;
	}
}