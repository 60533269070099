import { Store, createLogger } from 'vuex'
import { createStore } from 'vuex-extensions'
import tracking from "@/store/modules/tracking";
import queue from "@/store/modules/queue";
import user from "@/store/modules/user";
import auth from "@/store/modules/auth";
import custom from "@/store/modules/custom";
import mainData from '@/store/modules/mainData';
import system from '@/store/modules/system';
import points from '@/store/modules/points';
import news from '@/store/modules/news';
import data from '@/store/modules/data';
import userProfile from '@/store/modules/userProfile';
import wallet from '@/store/modules/wallet';

import VuexPersistence from 'vuex-persist'
import challenge from "@/store/modules/challenge";

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore(Store, {
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tracking,
    queue,
    user,
    auth,
    userProfile,
    custom,
    mainData,
    challenge,
    system,
    points,
    news,
    data,
    wallet,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
})
