<template>
	<ion-page>
		<ion-header class="ion-padding">
			<h1 v-if="hasPasswordChangedSuccessfuly" class="loginHeader" v-html="passwordChanged"/>
			<h1 v-else class="loginHeader" v-html="passwordVerify"/>
		</ion-header>
		<ion-content class="ion-padding">
			<div>
				<div v-if="verifyError" style="color: #e04055; text-align: center" class="ion-padding">
					<p>{{ verifyError }}</p>
				</div>
				<div v-if="hasPasswordChangedSuccessfuly">
					<verify-success-animation
							style="max-height: 30rem; width: 100%;margin-bottom: -1em;margin-top: -1em;"></verify-success-animation>
					<div style="color: #18c75d; text-align: center">
						<p>{{ setNewPasswordSuccessText }}<br>{{ setNewPasswordSucessGoBackToAppInfo }}
						</p>
					</div>
				</div>
				<div v-else-if="!hasPasswordChangedSuccessfuly && !newResendLinkSend">
					<form @submit.prevent="sendNewPassword">
						<ion-card class="ion-no-padding ion-no-margin">
							<ion-item>
								<ion-label position="stacked">Passwort</ion-label>
								<ion-input v-model="passwordFormData.password" placeholder="Neues Passwort" type="password"
										   required="true" tabindex="3"></ion-input>
							</ion-item>
							<ion-item>
								<ion-label position="stacked">Passwort bestätigen</ion-label>
								<ion-input v-model="passwordFormData.passwordConfirm" placeholder="Neues Passwort bestätigen"
										   type="password" required="true" tabindex="4" @keyup.enter="sendNewPassword"></ion-input>
							</ion-item>
							<span class="error ion-padding" v-if="passwordError"
								  id="password-guidelines-hover">{{ passwordError }} <button
									@click="handlePasswordHover">ℹ️</button></span>
							<ion-button class="basic-login-btn" expand="block" type="submit"
										style="min-height: 65px; margin-bottom: -5px; margin-top: 20px">
								<p class="basic-login-text">Passwort zurücksetzen</p>
							</ion-button>
						</ion-card>
					</form>

					<div style="color: #e04055; text-align: center" class="ion-padding-top">
						<p style="color: var(--ion-color-dark)">{{ resendPasswordTitle }}</p>
						<ion-button color="danger" expand="block" @click="resendLink">
							{{ verifyErrorResendLink }}
							<ion-icon class="ion-padding-start" :icon="icons.mailOutline"></ion-icon>
						</ion-button>
					</div>
				</div>
				<div v-else-if="newResendLinkSend" style="text-align: center" class="ion-padding">
					<login-success-animation
							style="max-height: 30rem; width: 100%;margin-bottom: -1em;margin-top: -1em"></login-success-animation>
					<p v-html="setNewPasswordSuccessBody"/>
					<p>{{ setNewPasswordGoBackTo }}
						<router-link to="/login">
							<a class="link-text-color"> Login</a>
						</router-link>
					</p>
				</div>
			</div>
		</ion-content>

		<ion-footer style="padding: 25px" v-if="!hasPasswordChangedSuccessfuly && !newResendLinkSend">
			<p style="margin: auto;text-align: center;">
				<span>
					<router-link to="/privacy-crudafoch">
						<a style="cursor: pointer" class="ion-color-medium">Datenschutz</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/terms">
						<a style="cursor: pointer">AGB</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/impress">
						<a style="cursor: pointer">Impressum</a>
					</router-link>
				</span>
			</p>
			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 7px">
				Version: {{ appVersion }}
			</p>
			<mobility-logo></mobility-logo>
		</ion-footer>

	</ion-page>
</template>

<script lang="ts">
import {onBeforeMount, ref} from "vue";
import {
	IonContent,
	IonPage,
	IonHeader,
	IonFooter,
	IonIcon,
	popoverController,
	IonInput,
	IonButton,
	IonCard
} from '@ionic/vue';
import MobilityLogo from "@/components/logo/MobilityLogo.vue";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import {useRoute} from "vue-router";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import {BasicAuthError} from "@/mobipoints/api/auth";
import useToastMessage from "@/components/core/ToastMessage.vue";
import VerifySuccessAnimation from "@/components/animation/VerifySuccessAnimation.vue";
import * as icons from 'ionicons/icons';
import LoginSuccessAnimation from "@/components/animation/LoginSuccessAnimation.vue";

export default {
	name: 'SetNewPassword',
	components: {
		LoginSuccessAnimation,
		VerifySuccessAnimation,
		MobilityLogo, IonContent, IonPage, IonHeader, IonFooter, IonIcon,
		IonInput, IonButton, IonCard
	},
	setup() {
		const {getTextValueByKey} = useData();
		const {appVersion} = useSystem();
		const passwordVerify = ref(getTextValueByKey('set_new_password.title', [], 'Passwort zurücksetzen'));
		const passwordChanged = ref(getTextValueByKey('set_new_password.success.title', [], 'Passwort erfolgreich geändert'));
		const verifyErrorResendLink = ref(getTextValueByKey('set_new_password.error.resend.link', [], 'Passwort erneut zurücksetzen?'));
		const currentRouteName = ref<string>();
		const currentRouteParams = ref<any>();
		const {openToast} = useToastMessage();
		const uuid = ref();
		const hash = ref();
		const hasPasswordChangedSuccessfuly = ref(false);
		const showVerifyError = ref(false);
		const verifyError = ref("");
		const newResendLinkSend = ref(false);
		const passwordError = ref("");
		const passwordHover = ref(false);
		const setNewPasswordSuccessTitle = ref(getTextValueByKey('set_new_password.success.title', [], 'ERFOLGREICH GEÄNDERT'));
		const setNewPasswordSuccessBody = ref(getTextValueByKey('set_new_password.success.body', [], 'Es wurde ein Link an deine E-Mail Adresse gesendet.<br>Überprüfe deine E-Mails, klicke auf den Passwort Rücksetzungs Link und schon kanns losgehen!'));
		const setNewPasswordGoBackTo = ref(getTextValueByKey('set_new_password.success.go_back', [], 'Zurück zum'));
		const setNewPasswordSuccessText = ref(getTextValueByKey('set_new_password.success.text', [], 'Passwort wurde erfolgreich geändert.'));
		const setNewPasswordSucessGoBackToAppInfo = ref(getTextValueByKey('set_new_password.go_back_to_app.info', [], 'Du kannst jetzt zurück zur App und dich einloggen!'));
		const resendPasswordTitle = ref(getTextValueByKey('set_new_password.error.text', [], 'Möchtest du einen neuen Link anfordern?'));
		const currentPopover = ref();
		const passwordFormData: any = ref({password: "", passwordConfirm: ""});


		async function handlePasswordHover(ev) {
			const popover = await popoverController.create({
				component: 'password-guideline-popover',
				event: ev,
				translucent: true,
			});
			currentPopover.value = popover;
			return popover.present();
		}

		customElements.define(
			'password-guideline-popover',
			class ModalContent extends HTMLElement {
				connectedCallback() {
					this.innerHTML = `
          <ion-list>
            <ion-list-header>PASSWORT RICHTLINIEN</ion-list-header>
            <ion-item button>MINDESTENS 8 ZEICHEN</ion-item>
            <ion-item button>1 GROßBUCHSTABE</ion-item>
            <ion-item button>1 KLEINBUCHSTABE</ion-item>
            <ion-item button>MINDESTENS EINE ZAHL</ion-item>
          </ion-list>
        `;
				}
			}
		);

		function hasValidPasswordResetData(ignoreHashCheck = false): boolean {
			let result = true;
			verifyError.value = "";
			if (!uuid.value) {
				verifyError.value = getTextValueByKey('set_new_password.verify.invalid.uuid', [], 'Ungültige UUID');
				result = false;
			}
			if (!ignoreHashCheck && !hash.value) {
				verifyError.value = getTextValueByKey('set_new_password.verify.invalid.hash', [], 'Ungültiger Link');
				result = false;
			}
			if (!passwordFormData.value.password) {
				passwordError.value = 'Bitte trag ein gültiges Passwort ein!';
				result = false;
			}
			if (!passwordFormData.value.passwordConfirm && passwordFormData.value.password) {
				passwordError.value = 'Bitte bestätige dein Passwort!';
				result = false;
			}
			if (passwordFormData.value.password && passwordFormData.value.passwordConfirm && passwordFormData.value.password !== passwordFormData.value.passwordConfirm) {
				passwordError.value = 'Passwörter stimmen nicht überein';
				result = false;
			}

			return result;
		}

		async function handleVerifyError(error: any | BasicAuthError) {
			try {
				if (error instanceof BasicAuthError) {
					if (error.message) {
						await openToast(error.message, 'danger', 'top', true, 10000, undefined, true);
					}
				} else {
					await openToast(getTextValueByKey('set_new_password.verify.general.error', [], 'Passwort zurücksetzen fehlgeschlagen'), 'danger', 'top', true, 5000, undefined, true);
				}
			} catch (error) {
				await openToast(getTextValueByKey('set_new_password.verify.general.error', [], 'Passwort zurücksetzen fehlgeschlagen'), 'danger', 'top', true, 5000, undefined, true);
			}
		}

		async function resendLink() {
			if (currentRouteParams.value) {
				try {
					if (!uuid.value) {
						verifyError.value = getTextValueByKey('set_new_password.verify.invalid.uuid', [], 'Ungültige UUID');
					} else {
						const userAuth = new UserAuth();
						const result = await userAuth.resendResetNewPasswordLink(uuid.value);
						if (result) {
							await openToast(getTextValueByKey('set_new_password.verify.resend.success', [], 'Passwort zurücksetzen Link wurde erfolgreich gesendet!'), 'success', 'top', true, 5000, undefined, true);
							newResendLinkSend.value = true;
						}
					}
				} catch (error: any | BasicAuthError) {
					console.log(error);
					newResendLinkSend.value = false;
					await handleVerifyError(error);
				}
			} else {
				showVerifyError.value = true;
			}
		}

		async function sendNewPassword() {
			if (currentRouteParams.value) {
				try {
					if (hasValidPasswordResetData()) {
						const userAuth = new UserAuth();
						const useNewPasswordData = {
							password: passwordFormData.value.password,
							hash: hash.value,
						}
						const result = await userAuth.resetAndVerifyNewPassword(uuid.value, useNewPasswordData);
						if (result) {
							hasPasswordChangedSuccessfuly.value = true;
						}
					}
				} catch (error: any | BasicAuthError) {
					console.log(error);
					await handleVerifyError(error);
				}
			} else {
				showVerifyError.value = true;
			}
		}

		onBeforeMount(() => {
			const currentRoute = useRoute();
			currentRouteName.value = String(currentRoute.name);
			currentRouteParams.value = currentRoute.query || {};
			uuid.value = currentRouteParams.value.uuid ?? null;
			hash.value = currentRouteParams.value.hash ?? null;
		});

		return {
			appVersion,
			passwordVerify,
			passwordChanged,
			hasPasswordChangedSuccessfuly,
			verifyErrorResendLink,
			resendLink,
			sendNewPassword,
			newResendLinkSend,
			icons,
			setNewPasswordSuccessTitle,
			setNewPasswordSuccessBody,
			setNewPasswordGoBackTo,
			setNewPasswordSuccessText,
			setNewPasswordSucessGoBackToAppInfo,
			resendPasswordTitle,
			handlePasswordHover,
			passwordFormData,
			passwordError,
			passwordHover,
			verifyError
		}
	}
}
</script>

<style>
.loginHeader {
    text-align: center;
    color: var(--ion-text-color);
}

.link-text-color {
    cursor: pointer;
    font-weight: bold;
    color: var(--ion-color-dark);
}
</style>