import axios, {AxiosRequestConfig} from "axios";
import {loadingController} from '@ionic/vue';
import {MobiPointApiToken} from "@/mobipoints/api/token";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import useData from "@/composable/useData";
import {HttpHeaders} from "@capacitor/core";

let loading: HTMLIonLoadingElement;
const loadingTimeout = 5000;

const MobiPointsApiClient = {
	_requestInterceptor: 0,
	_401interceptor: 0,

	init(baseURL: string | undefined) {
		axios.defaults.baseURL = baseURL;
		this.setHeader();
		this.initLoadingController();
	},

	async initLoadingController(){
		loading = await loadingController
			.create({
				spinner: null,
				duration: loadingTimeout,
				message: 'Click the backdrop to dismiss early...',
				translucent: true,
				cssClass: 'custom-class custom-loading',
				backdropDismiss: true
			});
	},

	async showLoadingController() {
		await loading.present();

		setTimeout(function() {
			loading.dismiss()
		}, loadingTimeout);
	},

	hideLoadingController() {
		loading.dismiss()
	},

	setHeader() {
		// const username = process.env.VUE_APP_API_USERNAME;
		// const password = process.env.VUE_APP_API_PASSWORD;
		// const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		axios.defaults.headers.common["x-api-key"] = this.getApiKey();
		axios.defaults.withCredentials = true;
		// axios.defaults.headers.common["content-type"] = 'application/json';
		const BearerToken = MobiPointApiToken.getToken();
		if (BearerToken) {
			//Todo check lifetime
			axios.defaults.headers.common["Authorization"] = `${BearerToken}`; //Bearer
		}
	},

	getApiKey(): string {
		return process.env.VUE_APP_API_KEY;
	},

	async getCsrfToken() {
		return this.get('/csrf')
			.then(response => {
				return response.data.token;
			})
			.catch((error) => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
					console.log(error.request);
				} else {
					// Something happened in setting up the request and triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	},

	async getDefaultHeadersForRequest(): Promise<HttpHeaders> {
		const csrf = await this.getCsrfToken();
		return {
			'Content-Type': 'application/json',
			'x-csrf-token': csrf as string,
			'x-api-key': this.getApiKey() as string,
		};
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	async get(resource: string, useCsrfToken = false) {
		if (useCsrfToken) {
			return axios.get(resource, {headers: await this.getDefaultHeadersForRequest()});
		} else {
			return axios.get(resource);
		}
	},

	async post(resource: string, data: any, addCsrfTokenToBody = false) {
		const headerData = await this.getDefaultHeadersForRequest();
		if (addCsrfTokenToBody && headerData["x-csrf-token"]) {
			data.csrf = headerData["x-csrf-token"];
		}
		return axios.post(resource, data, {
			headers: headerData
		});
	},

	async put(resource: string, data: any) {
		return axios.put(resource, data, {
			headers: await this.getDefaultHeadersForRequest()
		});
	},

	async delete(resource: string, useCsrfToken = true) {
		if (useCsrfToken) {
			return axios.delete(resource, {headers: await this.getDefaultHeadersForRequest()});
		} else {
			return axios.delete(resource);
		}
	},

	customRequest(data: AxiosRequestConfig) {
		return axios(data);
	},

	mountRequestInterceptor() {
		this._requestInterceptor = axios.interceptors.request.use(async config => {
			const loading = await loadingController
				.create({
					spinner: null,
					duration: loadingTimeout,
					message: useData().getTextValueByKey('login.please.wait', [], 'Bitte warten...'),
					translucent: true,
					cssClass: 'custom-class custom-loading',
					backdropDismiss: true
				});
			await loading.present();

			return config;
		});
	},

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			response => {
				//loading.dismiss().then(r => console.log(r));
				return response;
			},
			// async error => {
			function (error) {
				//loading.dismiss().then(r => console.log(r));
				if (
					error.response &&
					[401].includes(error.response.status)
				) {
					(new UserAuth()).logout(true, false);
					// MobiPointsApiAuth.logout();
				} else if (error.response &&
					[419].includes(error.response.status)
				) {
					console.log("CSRF- ERROR", error.response);
				}
				throw error;
			}
		);
	},

	unmount401Interceptor() {
		axios.interceptors.response.eject(this._401interceptor);
	}
}

export default MobiPointsApiClient;
