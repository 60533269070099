import {ProfileFactory} from "@/mobipoints/profile/profile_factory";

export default function useProfileFactory() {

	let profileFactory: ProfileFactory | null = null;
	const getProfileFactory = (): ProfileFactory => {
		if (!profileFactory) {
			profileFactory = new ProfileFactory();
		}
		return profileFactory;
	}

	return {
		getProfileFactory,
	}
}