import {
	MobiPointsProfileUserTrackingTrackTypeInterface
} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.interface";
import * as icons from 'ionicons/icons';
import {
	TRACK_TYPE_BICYCLE,
	TRACK_TYPE_BUS, TRACK_TYPE_CAR,
	TRACK_TYPE_CARPOOL,
	TRACK_TYPE_E_BIKE,
	TRACK_TYPE_E_CAR,
	TRACK_TYPE_E_MOTORCYCLE, TRACK_TYPE_E_SCOOTER,
	TRACK_TYPE_FOOT,
	TRACK_TYPE_SUBWAY,
	TRACK_TYPE_TRAIN,
	TRACK_TYPE_TRAM
} from "@/mobipoints/tracking/track/abstract_track.type";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import bimOutline from '@/assets/images/track_types/bim.svg';

export class UserTrackingTrackType implements MobiPointsProfileUserTrackingTrackTypeInterface {
	private _name = '';
	private _code = '';
	private _style = ''

	constructor(code: string, name: string, style = '') {
		this.code = code;
		this.name = name;
		this.style = style;
	}

	get code(): string {
		return this._code;
	}

	set code(value: string) {
		this._code = value;
	}

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	get style(): string {
		return this._name;
	}

	set style(value: string) {
		this._style = value;
	}

	getTrackTypeIcon(): string {
		switch (this.code) {
			case TRACK_TYPE_BICYCLE:
			case TRACK_TYPE_E_BIKE:
				return icons.bicycle;

			case TRACK_TYPE_BUS:
				return icons.bus;

			case TRACK_TYPE_CARPOOL:
				return icons.shareSocial;

			case TRACK_TYPE_E_CAR:
				return icons.carSport;

			case TRACK_TYPE_CAR:
				return icons.car;

			case TRACK_TYPE_FOOT:
				return icons.walk;

			case TRACK_TYPE_SUBWAY:
				return icons.subway;

			case TRACK_TYPE_TRAIN:
				return icons.train;

			case TRACK_TYPE_TRAM:
				return bimOutline;

			case TRACK_TYPE_E_MOTORCYCLE:
			case TRACK_TYPE_E_SCOOTER:
			default:
				return icons.help;
		}
	}
}