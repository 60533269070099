<template>
	<google-map v-if="googleMapsKey" v-show="mapIsReady"
			:api-key="googleMapsKey"
			:style="'width:' +  width + '; height:' + newHeight"
			:styles="mapTheme"
			:center="centerLatLng"
			:mapTypeControl="showMapTypeControl"
			:fullscreenControl="false"
			:rotateControl="false"
			:scaleControl="false"
			:zoomControl="zoomControl"
			:streetViewControl="streetViewControl"
			:disableUI="true"
			:zoom="zoom"
			:mapId="getMapID_by_ThemeType()"
			@click="setMarker"
			id="map"
			ref="mapRef"
	>
		<Polyline ref="polylineRef" v-if="showPath && mapIsReady" :options="routePath"/>
		<Marker v-for="marker in markers" :key="marker" :options="marker.options"></Marker>
	</google-map>
	<div v-show="!mapIsReady" style="margin-top: -5px">
		<ion-skeleton-text :style="'width:' +  width + '; height:' + height" animated></ion-skeleton-text>
	</div>
</template>

<script lang="ts">
import {ref, watch, onMounted} from "vue";
import "@codetrix-studio/capacitor-google-auth";
import {GoogleMap, Marker, Polyline} from 'vue3-google-map'
import {AddressInterface, useCoordinate} from "@/composable/useCoordinate";
import {MobiPointsTrackingCoordinateCoordinateList} from "@/mobipoints/tracking/coordinate/coordinate_list.type";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import useTracking from "@/composable/useTracking";
import {IonSkeletonText} from "@ionic/vue";
import useUserProfile from "@/composable/useUserProfile";
import useData from "@/composable/useData";

export default {
	name: 'DisabledMap',
	// eslint-disable-next-line
	components: {GoogleMap, Marker, Polyline, IonSkeletonText},
	props: {
		initCords: {lat: Number, lng: Number},
		zoom: {type: Number, default: 15},
		mapTheme: {type: String, default: "default"}, //    const themes = ['aubergine', 'dark', 'grey', 'minimal', 'retro', 'roadways', 'roadwaysMinimal', 'ultraLight']
		height: {type: String, default: "200px"},
		width: {type: String, default: "100%"},
		disableMarkerUpdateEvent: {type: Boolean, default: false},
		zoomControl: {type: Boolean, default: true},
		streetViewControl: {type: Boolean, default: true},
		showPath: {
			type: Boolean,
			default: false,
		},
		pathCoordinates: {
			type: [MobiPointsTrackingCoordinateCoordinateList, null],
			default: null,
		},
		updateAddressOnClick: {type: Boolean, default: false},
		showMapTypeControl: {type: Boolean, default: false},
	},
	emits: ['onMarkerUpdated'],
	setup(props: any, {emit}) {
		const markers = ref([]) as any;
		const mapRef = ref<any>(null);
		const mapIsReady = ref(false);
		const newHeight = ref<string>(props.height);
		const polylineRef = ref<any>(null);
		const centerLatLng = ref({lat: 42, lng: 11});
		const waitPosition = ref();
		const routePlanCoordinates: Array<{ lat, lng }> | any = ref([]);
		const pathCoordinatesForRoute: MobiPointsTrackingCoordinateCoordinateList | any = ref(props.pathCoordinates);
		const isMounted = ref(false);
		const routePath = ref({
			path: routePlanCoordinates.value,
			geodesic: true,
			strokeColor: "#ec3333",
			strokeOpacity: 1.0,
			strokeWeight: 2,
			visible: true
		});
		const {
			currentTracking,
		} = useTracking();
		const {isDarkMode} = useUserProfile();
		const {getAddressByCoords, address} = useCoordinate();

		const googleMapsKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

		function getMapID_by_ThemeType(): string
		{
			if (isDarkMode.value && useData().getSystemValueByKey('feature_flag.map.allow_dark_mode_via_dark_mode_settings', false)) {
				return 'd64ac2da201f67ed';
			} else {
				switch (props.mapTheme) {
					case 'dark':
						return 'd64ac2da201f67ed';
					case 'default':
					case 'light':
					default:
						return "3720398df4b3300b";
				}
			}
		}

		async function setMarker(event) {
			if (!props.disableMarkerUpdateEvent) {
				centerLatLng.value.lat = event.latLng.lat();
				centerLatLng.value.lng = event.latLng.lng();
				await updateMarker();
			}
		}

		function resetMarkers(): void
		{
			markers.value = [];
		}

		async function updateMarker(updateAddress = false) {
			if (!props.disableMarkerUpdateEvent) {
				if ( updateAddress || props.updateAddressOnClick) {
					await getAddressByCoords(centerLatLng.value.lat, centerLatLng.value.lng)
					if (address.value) {
						onMarkerUpdated(address.value);
					}
				}
				resetMarkers();
				markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
			}
		}

		function buildPolyLinePathForMap() {
			if (pathCoordinatesForRoute.value) {
				routePlanCoordinates.value = [];
				let lastCoordinate: MobiPointsTrackingCoordinateCoordinate|null = null;
				pathCoordinatesForRoute.value.getItems().forEach((coordinate: MobiPointsTrackingCoordinateCoordinate) => {
					if (lastCoordinate != null && lastCoordinate.latitude === coordinate.latitude && lastCoordinate.longitude === coordinate.longitude) {
						return;
					}
					const path = {
						lat: coordinate.latitude,
						lng: coordinate.longitude,
					}
					lastCoordinate = coordinate;
					routePlanCoordinates.value.push(path);
				});
				routePath.value = {
					path: routePlanCoordinates.value,
					geodesic: true,
					strokeColor: "#ec3333",
					strokeOpacity: 1.0,
					strokeWeight: 2,
					visible: true
				};
			}
		}

		function loadPathCoordniates() {
			if (props.pathCoordinates === null) {
				if (currentTracking.value) {
					pathCoordinatesForRoute.value = currentTracking.value.getAllCoordinatesForMap();
				}
			}
		}

		onMounted(() => {
			if (!props.initCords || !props.initCords.lat || !props.initCords.lng) {
				//DEFAULT CENTER
				centerLatLng.value.lat = 46.957230;
				centerLatLng.value.lng = 15.850660;
			} else {
				centerLatLng.value = props.initCords;
				markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
			}
		})

		function calculateSize(size = -1): string
		{
			let sizeType = 'em';
			if (newHeight.value && newHeight.value.includes('px')) {
				sizeType = 'px';
			} else if (newHeight.value && newHeight.value.includes('%')) {
				sizeType = '%';
			} else if (newHeight.value && newHeight.value.includes('vh')) {
				sizeType = 'vh';
			}

			if (sizeType === 'px' || sizeType === '%') {
				return String(parseInt(newHeight.value)+size)+sizeType;
			} else {
				return String(parseFloat(newHeight.value)+size)+sizeType;
			}
		}

		watch(() => mapRef.value?.ready, (ready) => {
			if (!isMounted.value) {
				if (ready) {
					setTimeout(() => {
						newHeight.value = calculateSize(0.1);
						if (props.showPath) {
							loadPathCoordniates();
							buildPolyLinePathForMap();
						}
						mapRef.value.map.panTo(centerLatLng.value);
						isMounted.value = true;
						mapIsReady.value = true;
					}, 100);
				} else {
					newHeight.value = calculateSize(-0.1);
				}
			}
		})

		watch(() => props.pathCoordinates, (newValue: MobiPointsTrackingCoordinateCoordinateList|any, oldValue: MobiPointsTrackingCoordinateCoordinateList|any) => {
			if (props.showPath) {
				pathCoordinatesForRoute.value = newValue;
				buildPolyLinePathForMap();
			}
		});

		watch(props.initCords, value => {
			if (value.lat !== undefined && value.lng !== undefined){
				centerLatLng.value.lat = value.lat;
				centerLatLng.value.lng = value.lng;
				if (mapRef.value?.ready) {
					mapRef.value.map.panTo(centerLatLng.value)
				}
				resetMarkers();
				markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
				// markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
				updateMarker();
				if (props.showPath) {
					loadPathCoordniates();
					buildPolyLinePathForMap();
				}
			}
		});

		watch(() => props.initCords, (newValue: any, oldValue: any) => {
			if (oldValue !== newValue) {
				if (newValue.lat !== undefined && newValue.lng !== undefined) {
					centerLatLng.value.lat = newValue.lat;
					centerLatLng.value.lng = newValue.lng;
					if (mapRef.value?.ready) {
						mapRef.value.map.panTo(centerLatLng.value)
					}
					resetMarkers();
					markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
					// markers.value.push({options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'}});
					updateMarker();
					if (props.showPath) {
						loadPathCoordniates();
						buildPolyLinePathForMap();
					}
				}
			}
		});

		const onMarkerUpdated = (addressData: AddressInterface) => {
			emit('onMarkerUpdated', addressData);
		};

		return {
			mapRef, centerLatLng, getMapID_by_ThemeType, googleMapsKey, waitPosition, markers, setMarker, routePath,polylineRef,mapIsReady, newHeight
		};
	},
}
</script>

<style scoped>
#map * {
    /*overflow:visible;*/
}
</style>