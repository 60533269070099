<template>
	<div class='flex items-center p-3 rounded-md justify-center bg-gradient-to-r from-teal-200 to-lime-200'>
		<div class="p-4 items-center justify-center w-[680px] rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-xl hover:rounded-2xl">
			<div class="flex justify-center">
				<avatar-menu img-height="64px" img-width="64px"/>
			</div>
			<div class="sm:w-8/12 pl-0 p-5">
				<div class="space-y-2">
					<div class="space-y-4">
						<h4 class="text-md font-semibold text-cyan-900 text-justify">
							{{headerMessage}}
						</h4>
					</div>
					<div class="flex items-center space-x-4 justify-between">
						<div class="flex gap-3 space-y-1">
							<span class="text-sm text-gray-900">{{welcomeMessage}} 🔥</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {ref, onMounted} from "vue";
import {isPlatform} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import AvatarMenu from "@/components/menu/AvatarMenu";
import useUserRepository from "@/components/user/UserRepository.vue";
import useData from "@/composable/useData";

export default {
	name: 'WelcomeProfileInfo',
	components: {AvatarMenu},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const welcomeMessage = ref("");
		const {getGivenName, getUserImage} = useUserRepository();
		const {getTextValueByKey} = useData();
		const headerMessage = ref('');

		onMounted(() => {
			headerMessage.value = getTextValueByKey('dashboard.welcome.title', [{key: 'Name', value: getGivenName.value}])

			const isNewUser = true;
			if (isNewUser) {
				welcomeMessage.value = getTextValueByKey('dashboard.welcome.description');
			} else {
				welcomeMessage.value = getTextValueByKey('dashboard.welcome.back.description');
			}
		})

		return {
			icons,
			isPlatform,
			welcomeMessage,
			getGivenName,
			getUserImage,
			headerMessage
		}
	}
}
</script>

<style scoped>
.welcomeMessage {
    color: #B0B3BA;
    font-family: Rubik, sans-serif !important;
    font-size: 12px;
    font-weight:normal;
}
</style>