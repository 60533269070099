import {computed} from 'vue';
import {MobiPointsChallengeChallengeTypeInterface} from "@/mobipoints/challenge/challenge/challenge_type.interface";
import {MobiPointsChallengeChallengeStateInterface} from "@/mobipoints/challenge/challenge/challenge_state.interface";
import {MobiPointsChallengeUserChallengeInterface} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import useTracking from "@/composable/useTracking";
import store from "@/store";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {MobiPointsTrackingTrackingFactory} from "@/mobipoints/tracking/tracking_factory.type";
import useTrackingFactory from "@/composable/useTrackingFactory";
import useUserProfile from "@/composable/useUserProfile";
import {
	MobiPointsTrackingGeolocationWatcherStopPositionWatcher
} from "@/mobipoints/tracking/geolocation/watcher/stop_position_watcher";
import useData from "@/composable/useData";
import {DistanceCalculation} from "@/mobipoints/tracking/geolocation/distance_calculation";

export const maxCachedLocationInSeconds = 8;

export interface MobiPointsChallengeChallengeDataInterface {
	[key: string]: any;
	points?: number;
	img?: string;
}

export interface MobiPointsTrackingGeolocationBackgroundGeolocationInterface {
	uuid: string;
	startTimestamp: number | null;
	endTimestamp: number | null;
	title: string;
	description?: string | null;
	data: MobiPointsChallengeChallengeDataInterface | null;
	type: MobiPointsChallengeChallengeTypeInterface;
	state: MobiPointsChallengeChallengeStateInterface;
	userChallenges: MobiPointsChallengeUserChallengeInterface[];
}

export abstract class MobiPointsTrackingGeolocationAbstractBackgroundGeolocation {

	abstract checkPermissions(): boolean;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public startGeolocationTracking(forceStart = false) {
		this.startTrackingAutoStopWatcher()
	}

	public stopGeolocationTracking() {
		this.stopTrackingAutoStopWatcher();
	}

	protected getPositionWatchId(): any|number|null {
		return useTracking().getPositionWatchId.value;
	}

	protected getDistanceCalculation(): DistanceCalculation {
		return this.getTrackingFactory().createDistanceCalculation();
	}

	protected setPositionWatchId(positionWatchId: number|null) {
		return useTracking().setPositionWatchId(positionWatchId);
	}

	protected getTrackingAutoStopIntervalId(): any|number|null {
		return useTracking().getTrackingAutoStopIntervalId.value;
	}

	protected setTrackingAutoStopIntervalId(trackingAutoStopIntervalId: number|null) {
		return useTracking().setTrackingAutoStopIntervalId(trackingAutoStopIntervalId);
	}

	protected resetPositionWatchId() {
		return useTracking().setPositionWatchId(null);
	}

	protected getCurrentPosition() {
		return computed(() => store.getters['tracking/getCurrentPosition']);
	}

	protected setCurrentPosition(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, isInitialCoordinate = false) {
		if (!this.getCurrentPosition().value || isInitialCoordinate ||  (this.getCurrentPosition().value && !this.getCurrentPosition().value.latitude && !this.getCurrentPosition().value.longitude) ) {
			store.commit('tracking/setInitialCoordinateSend', true)
		}
		store.commit('tracking/setCurrentPosition', coordinate)
	}

	protected updateCurrentPositionTimestamp(timestamp: number) {
		store.commit('tracking/setCurrentPositionTimestamp', timestamp)
	}

	protected getTrackingFactory(): MobiPointsTrackingTrackingFactory {
		return useTrackingFactory().getTrackingFactory();
	}

	protected hasEmptyOrInvalidPositionWatchId(): boolean {
		return !this.getPositionWatchId() || this.getPositionWatchId().length === 0 || this.getPositionWatchId() === null;
	}

	public async startTrackingAutoStopWatcher()
	{
		if (useUserProfile().isTrackingAutoStop.value) {
			if (!this.getTrackingAutoStopIntervalId()) {
				const stopPositionWatcher = new MobiPointsTrackingGeolocationWatcherStopPositionWatcher();
				const trackingAutoStopInterval = setInterval(() => {
					void (async () => {
						const result = await stopPositionWatcher.check();
						if (result) {
							await stopPositionWatcher.stopActiveTracking();
						}
					})();
				}, useData().getSystemValueByKey('tracking.tracking_auto_stop_interval', 1000 * 30));
				this.setTrackingAutoStopIntervalId(trackingAutoStopInterval);
			}
		}
	}

	public stopTrackingAutoStopWatcher()
	{
		console.log("STOP AUTO WATCHER");
		if (this.getTrackingAutoStopIntervalId()) {
			clearInterval(this.getTrackingAutoStopIntervalId());
			this.setTrackingAutoStopIntervalId(null);
		}
	}
}
