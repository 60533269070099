<template>
	<svg class="animated" id="freepik_stories-happy-earth" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
		 version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
		<g id="freepik--background-simple--inject-24" class="animable" style="transform-origin: 247.749px 252.182px;">
			<path d="M383.16,135.05a210,210,0,0,0-10.85-19C326.19,45.75,202.7,7.47,141.51,80.15c-14.59,17.32-19.67,38.26-29.49,58.12-22.32,45.19-64,72.2-90.2,114-20.27,32.44-17.57,74-6.26,109.07,12.29,38.1,33.92,64.71,68.52,84.93,38.79,22.68,80.27,16.38,122.2,8.49,18.85-3.55,38.29-7.17,57.57-5.81,24.14,1.69,48,8.52,71.92,11.84,24.9,3.46,50.74,4.27,74.73-3.25,76.83-24.1,95-137,63.44-201.73-11.18-22.9-30.14-40.57-47.74-58.5C407.2,178,395.56,159.07,383.16,135.05Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 247.749px 252.182px;" id="eljmaca8zkl28"
				  class="animable"></path>
			<g id="ely5qylguamls">
				<path d="M383.16,135.05a210,210,0,0,0-10.85-19C326.19,45.75,202.7,7.47,141.51,80.15c-14.59,17.32-19.67,38.26-29.49,58.12-22.32,45.19-64,72.2-90.2,114-20.27,32.44-17.57,74-6.26,109.07,12.29,38.1,33.92,64.71,68.52,84.93,38.79,22.68,80.27,16.38,122.2,8.49,18.85-3.55,38.29-7.17,57.57-5.81,24.14,1.69,48,8.52,71.92,11.84,24.9,3.46,50.74,4.27,74.73-3.25,76.83-24.1,95-137,63.44-201.73-11.18-22.9-30.14-40.57-47.74-58.5C407.2,178,395.56,159.07,383.16,135.05Z"
					  style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 247.749px 252.182px;"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--Hearts--inject-24" class="animable" style="transform-origin: 245.338px 121.906px;">
			<path d="M158.54,108.27,154,112.82l-13.11,13.12-17.67-17.67a9.28,9.28,0,0,1,13.12-13.12l4.55,4.55,4.54-4.55a9.28,9.28,0,0,1,13.12,13.12Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 140.885px 109.265px;" id="eluruxim8qmmb"
				  class="animable"></path>
			<path d="M92,160.35l-2.57,2.57-7.4,7.4-10-10a5.24,5.24,0,1,1,7.41-7.4L82,155.51,84.56,153a5.24,5.24,0,1,1,7.41,7.4Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 82px 160.779px;" id="el299mn9ly2xc"
				  class="animable"></path>
			<path d="M202.13,58.37l-2.56,2.56-7.41,7.41-10-10a5.24,5.24,0,0,1,7.4-7.41l2.57,2.57L194.73,51a5.24,5.24,0,1,1,7.4,7.41Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 192.288px 58.8313px;" id="elllx5b10xwee"
				  class="animable"></path>
			<path d="M418.86,184.52l-2.57,2.56-7.41,7.41-10-10a5.24,5.24,0,0,1,7.41-7.41l2.56,2.57,2.57-2.57a5.24,5.24,0,0,1,7.41,7.41Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 408.855px 185.038px;" id="els2kfmwwmwpn"
				  class="animable"></path>
			<path d="M357.26,86.55l-2.57,2.57-7.4,7.41-10-10a5.24,5.24,0,1,1,7.41-7.4l2.57,2.56,2.56-2.56a5.24,5.24,0,1,1,7.41,7.4Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 347.265px 86.9888px;" id="elomt69iitgu"
				  class="animable"></path>
			<path d="M409.44,135.38l-4,4L393.85,151l-15.6-15.59a8.19,8.19,0,1,1,11.58-11.58l4,4,4-4a8.19,8.19,0,0,1,11.58,11.58Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 393.712px 136.156px;" id="el41odmqdcgv3"
				  class="animable"></path>
		</g>
		<g id="freepik--Sparkles--inject-24" class="animable" style="transform-origin: 245.995px 239.735px;">
			<path d="M426.51,266.1h0a.51.51,0,0,1-.5-.45c-.79-7-1.25-7.47-8.26-8.26a.5.5,0,0,1,0-1c7-.79,7.47-1.26,8.26-8.26a.5.5,0,0,1,1,0c.79,7,1.25,7.47,8.26,8.26a.5.5,0,0,1,0,1c-7,.79-7.47,1.26-8.26,8.26A.51.51,0,0,1,426.51,266.1Zm-5.21-9.2c3.37.67,4.53,1.84,5.21,5.2.68-3.36,1.84-4.53,5.21-5.2-3.37-.68-4.53-1.85-5.21-5.21C425.83,255.05,424.67,256.22,421.3,256.9Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 426.51px 256.865px;" id="ell7pb5lijdf"
				  class="animable"></path>
			<path d="M440.65,250.33a.51.51,0,0,1-.5-.45c-.79-7-1.25-7.47-8.26-8.26a.5.5,0,0,1,0-1c7-.79,7.47-1.26,8.26-8.26a.5.5,0,0,1,.5-.45h0a.51.51,0,0,1,.5.45c.79,7,1.25,7.47,8.26,8.26a.5.5,0,0,1,0,1c-7,.79-7.47,1.26-8.26,8.26A.52.52,0,0,1,440.65,250.33Zm-5.21-9.21c3.37.68,4.53,1.85,5.21,5.21.68-3.36,1.84-4.53,5.21-5.21-3.37-.67-4.53-1.84-5.21-5.2C440,239.28,438.81,240.45,435.44,241.12Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 440.65px 241.12px;" id="el0wjhvk5gxl6e"
				  class="animable"></path>
			<path d="M427.05,240.54a.5.5,0,0,1-.49-.45c-1.2-10.59-2-11.41-12.61-12.61a.5.5,0,0,1,0-1c10.59-1.2,11.41-2,12.61-12.61a.5.5,0,0,1,1,0c1.2,10.59,2,11.41,12.61,12.61a.5.5,0,0,1,0,1c-10.59,1.2-11.41,2-12.61,12.61A.5.5,0,0,1,427.05,240.54ZM417.86,227c6.48,1,8.16,2.71,9.19,9.19,1-6.48,2.72-8.16,9.2-9.19-6.48-1-8.16-2.72-9.2-9.2C426,224.27,424.34,226,417.86,227Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 427.06px 226.955px;" id="elwe04h1bj9rc"
				  class="animable"></path>
			<path d="M65.47,266.1a.49.49,0,0,1-.49-.45c-.79-7-1.26-7.47-8.26-8.26a.5.5,0,0,1,0-1c7-.79,7.47-1.26,8.26-8.26a.5.5,0,0,1,.49-.45.51.51,0,0,1,.5.45c.79,7,1.25,7.47,8.26,8.26a.5.5,0,0,1,0,1c-7,.79-7.47,1.26-8.26,8.26A.5.5,0,0,1,65.47,266.1Zm-5.21-9.2c3.37.67,4.54,1.84,5.21,5.2.68-3.36,1.85-4.53,5.21-5.2-3.36-.68-4.53-1.85-5.21-5.21C64.8,255.05,63.63,256.22,60.26,256.9Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 65.475px 256.89px;" id="elbcz65gcfq28"
				  class="animable"></path>
			<path d="M51.33,250.33a.51.51,0,0,1-.49-.45c-.79-7-1.26-7.47-8.26-8.26a.5.5,0,0,1,0-1c7-.79,7.47-1.26,8.26-8.26a.5.5,0,0,1,1,0c.79,7,1.25,7.47,8.26,8.26a.5.5,0,0,1,0,1c-7,.79-7.47,1.26-8.26,8.26A.51.51,0,0,1,51.33,250.33Zm-5.2-9.21c3.36.68,4.53,1.85,5.2,5.21.68-3.36,1.85-4.53,5.21-5.21-3.36-.67-4.53-1.84-5.21-5.2C50.66,239.28,49.49,240.45,46.13,241.12Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 51.34px 241.095px;" id="elg48ep3c2evm"
				  class="animable"></path>
			<path d="M64.93,240.54a.5.5,0,0,1-.5-.45c-1.19-10.59-2-11.41-12.61-12.61a.5.5,0,0,1,0-1c10.59-1.2,11.42-2,12.61-12.61a.5.5,0,0,1,.5-.45h0a.51.51,0,0,1,.5.45c1.19,10.59,2,11.41,12.61,12.61a.5.5,0,0,1,0,1c-10.59,1.2-11.42,2-12.61,12.61A.51.51,0,0,1,64.93,240.54ZM55.73,227c6.48,1,8.17,2.71,9.2,9.19,1-6.48,2.71-8.16,9.2-9.19-6.49-1-8.17-2.72-9.2-9.2C63.9,224.27,62.21,226,55.73,227Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 64.93px 226.98px;" id="elsc5os0inawo"
				  class="animable"></path>
		</g>
		<g id="freepik--Trees--inject-24" class="animable" style="transform-origin: 247.645px 275.69px;">
			<path d="M316.44,107.83c-15.1,0-27.33,11.65-27.33,26V134a14,14,0,0,0,14.35,13.87,21.45,21.45,0,0,1,3.79.24,9.46,9.46,0,0,1,5.94,3.69,4,4,0,0,0,3.1,1.58h.29a4,4,0,0,0,3.1-1.58,9.51,9.51,0,0,1,5.94-3.69,21.45,21.45,0,0,1,3.79-.24A14,14,0,0,0,343.76,134v-.21C343.76,119.48,331.53,107.86,316.44,107.83Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 316.435px 130.605px;" id="el942e52hnc5o"
				  class="animable"></path>
			<polygon
					points="325.89 139.77 319.84 141.07 327.06 132.09 317.21 141.27 317.15 141.91 316.71 132.25 316.01 147.5 306.3 138.44 313.51 147.43 307.46 146.13 315.11 149.42 315.88 150.38 314.54 179.84 318.88 179.84 317.25 144.3 318.25 143.06 325.89 139.77"
					style="fill: rgb(38, 50, 56); transform-origin: 316.68px 155.965px;" id="el848u4drxykw"
					class="animable"></polygon>
			<path d="M308.31,131.87s-.91-2.59-2.86-3.5a16,16,0,0,0-3.24-1.17S303.24,132.52,308.31,131.87Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 305.26px 129.563px;" id="el6ykgx3pn0xn"
				  class="animable"></path>
			<path d="M310.66,125s-.15-2.75-1.77-4.16a16,16,0,0,0-2.79-2S305.62,124.19,310.66,125Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 308.374px 121.92px;" id="el6ru49mjrf37"
				  class="animable"></path>
			<path d="M332.28,146s-1.74-2.13-3.88-2.32a16.1,16.1,0,0,0-3.44,0S327.75,148.34,332.28,146Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 328.62px 145.123px;" id="elm0rdiuqnqx"
				  class="animable"></path>
			<path d="M298.3,145.35s1.74-2.13,3.88-2.32a16.22,16.22,0,0,1,3.45,0S302.84,147.69,298.3,145.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 301.965px 144.473px;" id="el9bov2pj6d8"
				  class="animable"></path>
			<path d="M303,122.64a6.75,6.75,0,0,0-1.77-4.16,15.74,15.74,0,0,0-2.79-2S298,121.85,303,122.64Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 300.715px 119.56px;" id="elt1lq78qmfj"
				  class="animable"></path>
			<path d="M328.43,124.2s.15-2.75,1.77-4.16a16,16,0,0,1,2.79-2S333.48,123.41,328.43,124.2Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 330.716px 121.12px;" id="elwm7w9uhj9u"
				  class="animable"></path>
			<path d="M315.77,120.67s1.59-2.25,1.2-4.36A15.66,15.66,0,0,0,316,113S312.31,116.92,315.77,120.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 315.661px 116.835px;" id="elz2kxpp8szjc"
				  class="animable"></path>
			<path d="M317.54,131s.26-2.73-1.13-4.37a16.06,16.06,0,0,0-2.46-2.42S312.67,129.48,317.54,131Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 315.679px 127.605px;" id="elogj7fvm75o"
				  class="animable"></path>
			<path d="M311,118.5s.82-2.63-.2-4.52a15.74,15.74,0,0,0-1.91-2.87S306.56,116,311,118.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 309.795px 114.805px;" id="eltpeuao01ch"
				  class="animable"></path>
			<path d="M313.08,138.87a6.79,6.79,0,0,0-.19-4.52,15.74,15.74,0,0,0-1.91-2.87S308.64,136.37,313.08,138.87Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 311.871px 135.175px;" id="elwvnchnz3ra"
				  class="animable"></path>
			<path d="M319.23,135.79s2.33-1.47,2.77-3.58a15.31,15.31,0,0,0,.4-3.42S317.46,131,319.23,135.79Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 320.624px 132.29px;" id="elylbvan1b00c"
				  class="animable"></path>
			<path d="M319.51,118.5s-.82-2.63.2-4.52a16.09,16.09,0,0,1,1.9-2.87S324,116,319.51,118.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 320.722px 114.805px;" id="elwc2pygvhv2e"
				  class="animable"></path>
			<path d="M322.75,125.09s2.31-1.48,2.75-3.58a15.65,15.65,0,0,0,.39-3.43S321,120.32,322.75,125.09Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 324.131px 121.585px;" id="el06s84oh52ssv"
				  class="animable"></path>
			<path d="M329,132.28s2.66-.69,3.72-2.55a16.19,16.19,0,0,0,1.44-3.14S328.77,127.18,329,132.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 331.576px 129.435px;" id="el3qwa6idn56v"
				  class="animable"></path>
			<path d="M329.59,138.7s2.5,1.15,4.51.38a15.5,15.5,0,0,0,3.09-1.52S332.65,134.61,329.59,138.7Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 333.39px 137.938px;" id="el7tubet2isqt"
				  class="animable"></path>
			<path d="M333.1,132.86a6.72,6.72,0,0,0,4.5.38,15.82,15.82,0,0,0,3.1-1.51S336.15,128.77,333.1,132.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 336.9px 132.079px;" id="elmzonyn06r67"
				  class="animable"></path>
			<path d="M298.14,136.59s1.68,2.18,3.81,2.43a16.06,16.06,0,0,0,3.45.09S302.74,134.39,298.14,136.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 301.77px 137.584px;" id="elbdk9mfkh2wg"
				  class="animable"></path>
			<path d="M292.69,129.06s1.68,2.18,3.81,2.43a15.28,15.28,0,0,0,3.45.09S297.29,126.86,292.69,129.06Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 296.32px 130.057px;" id="elaqdgiufhutb"
				  class="animable"></path>
			<path d="M341.29,146.43c-12,0-21.79,9.29-21.79,20.72v.18a11.18,11.18,0,0,0,11.44,11.06,15.72,15.72,0,0,1,3,.19,7.51,7.51,0,0,1,4.73,2.94,3.17,3.17,0,0,0,2.48,1.26h.23a3.16,3.16,0,0,0,2.47-1.26,7.51,7.51,0,0,1,4.74-2.94,15.67,15.67,0,0,1,3-.19,11.19,11.19,0,0,0,11.45-11.06v-.18C363.09,155.72,353.33,146.45,341.29,146.43Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 341.27px 164.605px;" id="el411q7y80qo2"
				  class="animable"></path>
			<polygon
					points="348.83 171.9 344.01 172.94 349.76 165.77 341.91 173.1 341.86 173.6 341.51 165.91 340.95 178.07 333.2 170.84 338.96 178.01 334.14 176.97 340.23 179.6 340.85 180.37 339.77 203.86 343.24 203.86 341.94 175.51 342.74 174.53 348.83 171.9"
					style="fill: rgb(38, 50, 56); transform-origin: 341.48px 184.815px;" id="elo32478mij2"
					class="animable"></polygon>
			<path d="M334.81,165.6a5.39,5.39,0,0,0-2.28-2.79,12.92,12.92,0,0,0-2.59-.93S330.77,166.12,334.81,165.6Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 332.375px 163.762px;" id="elgi4i01yfss"
				  class="animable"></path>
			<path d="M336.68,160.1a5.39,5.39,0,0,0-1.41-3.32,13.19,13.19,0,0,0-2.22-1.61S332.66,159.48,336.68,160.1Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 334.86px 157.635px;" id="elq293r22knr"
				  class="animable"></path>
			<path d="M353.93,176.87a5.38,5.38,0,0,0-3.09-1.85,12.68,12.68,0,0,0-2.75,0S350.32,178.74,353.93,176.87Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 351.01px 176.17px;" id="elk747asvxw6h"
				  class="animable"></path>
			<path d="M326.83,176.35a5.38,5.38,0,0,1,3.09-1.85,12.68,12.68,0,0,1,2.75,0S330.44,178.22,326.83,176.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 329.75px 175.65px;" id="elsvo9pucqxmp"
				  class="animable"></path>
			<path d="M330.58,158.24a5.39,5.39,0,0,0-1.41-3.32,12.31,12.31,0,0,0-2.23-1.61S326.56,157.61,330.58,158.24Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 328.755px 155.775px;" id="ele9kqc7sf19"
				  class="animable"></path>
			<path d="M350.86,159.48a5.39,5.39,0,0,1,1.41-3.32,13.19,13.19,0,0,1,2.22-1.61S354.88,158.85,350.86,159.48Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 352.68px 157.015px;" id="elmf6lxcn3clb"
				  class="animable"></path>
			<path d="M340.76,156.66a5.32,5.32,0,0,0,.95-3.47,12.71,12.71,0,0,0-.74-2.65S338,153.68,340.76,156.66Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 340.656px 153.6px;" id="el3zpc1aepkpf"
				  class="animable"></path>
			<path d="M342.17,164.91a5.36,5.36,0,0,0-.9-3.49,12.55,12.55,0,0,0-2-1.93S338.29,163.69,342.17,164.91Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 340.676px 162.2px;" id="el8kuj77zcx2a"
				  class="animable"></path>
			<path d="M337,154.93a5.33,5.33,0,0,0-.16-3.6,12.83,12.83,0,0,0-1.52-2.29S333.41,152.94,337,154.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 336.026px 151.985px;" id="elz52uyzcoyw"
				  class="animable"></path>
			<path d="M338.62,171.18a5.4,5.4,0,0,0-.16-3.6,12.36,12.36,0,0,0-1.52-2.29S335.07,169.19,338.62,171.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 337.651px 168.235px;" id="eldcsyq50q18i"
				  class="animable"></path>
			<path d="M343.52,168.72a5.34,5.34,0,0,0,2.21-2.84,13.06,13.06,0,0,0,.32-2.74S342.11,164.91,343.52,168.72Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 344.633px 165.93px;" id="elune9d4hvbr"
				  class="animable"></path>
			<path d="M343.74,154.93a5.4,5.4,0,0,1,.16-3.6,12.83,12.83,0,0,1,1.52-2.29S347.29,152.94,343.74,154.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 344.709px 151.985px;" id="elh6przqgs3zr"
				  class="animable"></path>
			<path d="M346.33,160.2a5.38,5.38,0,0,0,2.19-2.86,12.49,12.49,0,0,0,.31-2.73S344.89,156.39,346.33,160.2Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 347.422px 157.405px;" id="el9riw7pg2tba"
				  class="animable"></path>
			<path d="M351.31,165.93a5.37,5.37,0,0,0,3-2,12.74,12.74,0,0,0,1.14-2.5S351.13,161.86,351.31,165.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 353.377px 163.68px;" id="el46lqkhn5yts"
				  class="animable"></path>
			<path d="M351.79,171.05a5.34,5.34,0,0,0,3.59.3,12.39,12.39,0,0,0,2.46-1.21S354.22,167.79,351.79,171.05Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 354.815px 170.426px;" id="elnvxo6hjkal"
				  class="animable"></path>
			<path d="M354.58,166.39a5.38,5.38,0,0,0,3.59.31,13,13,0,0,0,2.47-1.22S357,163.13,354.58,166.39Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 357.61px 165.768px;" id="elwzogqlyjgbn"
				  class="animable"></path>
			<path d="M326.7,169.36a5.39,5.39,0,0,0,3,1.94,12.67,12.67,0,0,0,2.75.07S330.37,167.61,326.7,169.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 329.575px 170.155px;" id="el2wrasoucq6g"
				  class="animable"></path>
			<path d="M322.36,163.36a5.34,5.34,0,0,0,3,1.94,12.65,12.65,0,0,0,2.75.07S326,161.6,322.36,163.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 325.235px 164.153px;" id="el2hjnog8idpx"
				  class="animable"></path>
			<path d="M178.15,132.57c-12,0-21.8,9.29-21.8,20.72v.17a11.19,11.19,0,0,0,11.45,11.06,16.54,16.54,0,0,1,3,.2,7.56,7.56,0,0,1,4.74,2.93,3.17,3.17,0,0,0,2.47,1.27h.23a3.14,3.14,0,0,0,2.47-1.27,7.59,7.59,0,0,1,4.74-2.93,16.59,16.59,0,0,1,3-.2,11.17,11.17,0,0,0,11.44-11.06v-.17C199.94,141.86,190.19,132.59,178.15,132.57Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 178.12px 150.745px;" id="elcqvu8lxkzb"
				  class="animable"></path>
			<polygon
					points="185.69 158.04 180.87 159.08 186.62 151.91 178.76 159.24 178.71 159.74 178.36 152.04 177.81 164.2 170.06 156.98 175.81 164.15 170.99 163.11 177.09 165.74 177.7 166.5 176.63 190 180.09 190 178.8 161.65 179.59 160.67 185.69 158.04"
					style="fill: rgb(38, 50, 56); transform-origin: 178.34px 170.955px;" id="el9buk4ko26w8"
					class="animable"></polygon>
			<path d="M171.66,151.74a5.35,5.35,0,0,0-2.27-2.79,12.39,12.39,0,0,0-2.59-.93S167.63,152.26,171.66,151.74Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 169.23px 149.902px;" id="elzlvvy516yfa"
				  class="animable"></path>
			<path d="M173.54,146.24a5.39,5.39,0,0,0-1.41-3.32,12.31,12.31,0,0,0-2.23-1.61S169.52,145.61,173.54,146.24Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 171.715px 143.775px;" id="elvzkzdfsngeb"
				  class="animable"></path>
			<path d="M190.79,163a5.38,5.38,0,0,0-3.1-1.85,12.72,12.72,0,0,0-2.75,0S187.17,164.87,190.79,163Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 187.865px 162.301px;" id="elaaw9tfzwyc"
				  class="animable"></path>
			<path d="M163.68,162.49a5.38,5.38,0,0,1,3.1-1.85,12.68,12.68,0,0,1,2.75,0S167.3,164.36,163.68,162.49Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 166.605px 161.79px;" id="elc0tegk1fmew"
				  class="animable"></path>
			<path d="M167.43,144.38a5.39,5.39,0,0,0-1.41-3.32,13,13,0,0,0-2.22-1.62S163.41,143.75,167.43,144.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 165.61px 141.91px;" id="eluh4kgdscg3i"
				  class="animable"></path>
			<path d="M187.72,145.62a5.42,5.42,0,0,1,1.4-3.32,12.31,12.31,0,0,1,2.23-1.61S191.74,145,187.72,145.62Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 189.54px 143.155px;" id="el2p932wochyu"
				  class="animable"></path>
			<path d="M177.62,142.8a5.38,5.38,0,0,0,.95-3.47,12.71,12.71,0,0,0-.74-2.65S174.85,139.82,177.62,142.8Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 177.513px 139.74px;" id="elf6ug9r9ncvh"
				  class="animable"></path>
			<path d="M179,151.05a5.39,5.39,0,0,0-.91-3.49,12.81,12.81,0,0,0-2-1.93S175.14,149.83,179,151.05Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 177.503px 148.34px;" id="elxdpfa65pbl8"
				  class="animable"></path>
			<path d="M173.82,141.07a5.4,5.4,0,0,0-.16-3.6,12.36,12.36,0,0,0-1.52-2.29S170.27,139.08,173.82,141.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 172.851px 138.125px;" id="el4390d16ojm3"
				  class="animable"></path>
			<path d="M175.47,157.32a5.37,5.37,0,0,0-.15-3.6,13,13,0,0,0-1.52-2.29S171.93,155.33,175.47,157.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 174.509px 154.375px;" id="elogpou56cawq"
				  class="animable"></path>
			<path d="M180.38,154.86a5.39,5.39,0,0,0,2.21-2.85,13,13,0,0,0,.32-2.73S179,151.05,180.38,154.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 181.498px 152.07px;" id="elm9rq7g1a5zt"
				  class="animable"></path>
			<path d="M180.6,141.07a5.33,5.33,0,0,1,.16-3.6,12.36,12.36,0,0,1,1.52-2.29S184.15,139.08,180.6,141.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 181.567px 138.125px;" id="elmuitdia5ln"
				  class="animable"></path>
			<path d="M183.18,146.33a5.39,5.39,0,0,0,2.2-2.85,12.55,12.55,0,0,0,.31-2.74S181.75,142.52,183.18,146.33Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 184.279px 143.535px;" id="elaojgyj6rpvl"
				  class="animable"></path>
			<path d="M188.16,152.07a5.38,5.38,0,0,0,3-2,12.74,12.74,0,0,0,1.14-2.5S188,148,188.16,152.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 190.228px 149.82px;" id="ely7zpnpnsboc"
				  class="animable"></path>
			<path d="M188.64,157.18a5.38,5.38,0,0,0,3.59.31,12.71,12.71,0,0,0,2.47-1.21S191.08,153.92,188.64,157.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 191.67px 156.559px;" id="elenzl506m92g"
				  class="animable"></path>
			<path d="M191.44,152.53a5.34,5.34,0,0,0,3.59.3,12.71,12.71,0,0,0,2.47-1.21S193.87,149.27,191.44,152.53Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 194.47px 151.906px;" id="elv3xywozy9gh"
				  class="animable"></path>
			<path d="M163.56,155.5a5.39,5.39,0,0,0,3,1.94,12.67,12.67,0,0,0,2.75.07S167.23,153.74,163.56,155.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 166.435px 156.293px;" id="elv6bdsx3br0r"
				  class="animable"></path>
			<path d="M159.21,149.5a5.39,5.39,0,0,0,3,1.94,12.67,12.67,0,0,0,2.75.07S162.88,147.74,159.21,149.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 162.085px 150.293px;" id="elxrl18po98v"
				  class="animable"></path>
			<path d="M151.16,162.15c-9.38,0-17,7.23-17,16.13v.13A8.71,8.71,0,0,0,143.1,187a12.8,12.8,0,0,1,2.36.15,5.86,5.86,0,0,1,3.68,2.29,2.47,2.47,0,0,0,1.93,1h.18a2.45,2.45,0,0,0,1.92-1,5.86,5.86,0,0,1,3.69-2.29,12.64,12.64,0,0,1,2.35-.15,8.71,8.71,0,0,0,8.91-8.61v-.13C168.12,169.38,160.53,162.16,151.16,162.15Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 151.14px 176.295px;" id="elhsdtxlig9ig"
				  class="animable"></path>
			<polygon
					points="157.03 181.97 153.27 182.78 157.75 177.2 151.63 182.9 151.6 183.3 151.32 177.31 150.89 186.77 144.86 181.15 149.34 186.73 145.59 185.92 150.33 187.97 150.81 188.56 149.98 206.85 152.67 206.85 151.66 184.78 152.28 184.02 157.03 181.97"
					style="fill: rgb(38, 50, 56); transform-origin: 151.305px 192.025px;" id="elumzhqfqesu"
					class="animable"></polygon>
			<path d="M146.11,177.07a4.15,4.15,0,0,0-1.77-2.17,9.69,9.69,0,0,0-2-.73S143,177.47,146.11,177.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 144.225px 175.637px;" id="eln0hn8b0bmf"
				  class="animable"></path>
			<path d="M147.57,172.79a4.16,4.16,0,0,0-1.1-2.58,9.8,9.8,0,0,0-1.73-1.26S144.44,172.3,147.57,172.79Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 146.151px 170.87px;" id="eleu1rule0zt"
				  class="animable"></path>
			<path d="M161,185.84a4.2,4.2,0,0,0-2.4-1.44,10.44,10.44,0,0,0-2.15,0S158.18,187.29,161,185.84Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 158.725px 185.296px;" id="elhiiol8k7n94"
				  class="animable"></path>
			<path d="M139.9,185.44a4.2,4.2,0,0,1,2.41-1.44,9.51,9.51,0,0,1,2.14,0S142.71,186.89,139.9,185.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 142.175px 184.893px;" id="elwvokmar80fc"
				  class="animable"></path>
			<path d="M142.82,171.34a4.22,4.22,0,0,0-1.1-2.58A9.8,9.8,0,0,0,140,167.5S139.69,170.85,142.82,171.34Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 141.406px 169.42px;" id="els52ei1nr0j"
				  class="animable"></path>
			<path d="M158.6,172.31a4.24,4.24,0,0,1,1.1-2.59,10,10,0,0,1,1.73-1.25S161.73,171.82,158.6,172.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 160.019px 170.39px;" id="elr4r0ipm2bet"
				  class="animable"></path>
			<path d="M150.74,170.11a4.17,4.17,0,0,0,.75-2.7,10.49,10.49,0,0,0-.58-2.06S148.59,167.79,150.74,170.11Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 150.664px 167.73px;" id="elaxt1hq8ic66"
				  class="animable"></path>
			<path d="M151.84,176.53a4.21,4.21,0,0,0-.7-2.72,10.24,10.24,0,0,0-1.53-1.49S148.82,175.59,151.84,176.53Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 150.687px 174.425px;" id="elryb5t7hj1ui"
				  class="animable"></path>
			<path d="M147.79,168.77a4.18,4.18,0,0,0-.13-2.81,9.86,9.86,0,0,0-1.18-1.78S145,167.21,147.79,168.77Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 147.03px 166.475px;" id="elkgep8zmjtwd"
				  class="animable"></path>
			<path d="M149.07,181.41a4.18,4.18,0,0,0-.12-2.8,9.86,9.86,0,0,0-1.18-1.78S146.31,179.86,149.07,181.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 148.32px 179.12px;" id="elfzqkswaqzo"
				  class="animable"></path>
			<path d="M152.89,179.5a4.19,4.19,0,0,0,1.72-2.22,9.5,9.5,0,0,0,.25-2.12S151.79,176.53,152.89,179.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 153.756px 177.33px;" id="elr6cbp3cerc8"
				  class="animable"></path>
			<path d="M153.06,168.77a4.18,4.18,0,0,1,.13-2.81,9.86,9.86,0,0,1,1.18-1.78S155.83,167.21,153.06,168.77Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 153.816px 166.475px;" id="elrzwwl8xdb5j"
				  class="animable"></path>
			<path d="M155.07,172.86a4.19,4.19,0,0,0,1.71-2.22,10.43,10.43,0,0,0,.25-2.13S154,169.9,155.07,172.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 155.936px 170.685px;" id="el25mvhlol9gy"
				  class="animable"></path>
			<path d="M159,177.32a4.15,4.15,0,0,0,2.32-1.58,10.14,10.14,0,0,0,.89-2S158.81,174.16,159,177.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 160.601px 175.53px;" id="elz16ixy5vonc"
				  class="animable"></path>
			<path d="M159.32,181.31a4.24,4.24,0,0,0,2.8.24,10.07,10.07,0,0,0,1.92-1S161.22,178.77,159.32,181.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 161.68px 180.814px;" id="el3ig0ex1ak21"
				  class="animable"></path>
			<path d="M161.5,177.68a4.15,4.15,0,0,0,2.79.24,9.69,9.69,0,0,0,1.93-.94S163.39,175.15,161.5,177.68Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 163.86px 177.199px;" id="elkqzyzz6p3p"
				  class="animable"></path>
			<path d="M139.8,180a4.2,4.2,0,0,0,2.37,1.51,10.1,10.1,0,0,0,2.14.05S142.66,178.63,139.8,180Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 142.055px 180.615px;" id="elc6m1peebwt5"
				  class="animable"></path>
			<path d="M136.42,175.33a4.2,4.2,0,0,0,2.36,1.51,10.08,10.08,0,0,0,2.14,0S139.27,174,136.42,175.33Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 138.67px 175.936px;" id="elo9jccufmfa"
				  class="animable"></path>
			<path d="M212.3,107.92c-12,0-21.8,9.29-21.8,20.73v.17A11.19,11.19,0,0,0,202,139.88a15.67,15.67,0,0,1,3,.19,7.53,7.53,0,0,1,4.74,2.94,3.16,3.16,0,0,0,2.47,1.26h.23a3.13,3.13,0,0,0,2.47-1.26,7.53,7.53,0,0,1,4.74-2.94,15.72,15.72,0,0,1,3-.19,11.18,11.18,0,0,0,11.44-11.06v-.17C234.09,117.21,224.34,107.94,212.3,107.92Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 212.295px 126.095px;" id="elhsu28apqmj"
				  class="animable"></path>
			<polygon
					points="219.84 133.39 215.01 134.43 220.77 127.27 212.91 134.59 212.86 135.1 212.51 127.4 211.96 139.56 204.21 132.34 209.96 139.5 205.14 138.46 211.24 141.09 211.85 141.86 210.78 165.35 214.24 165.35 212.95 137.01 213.74 136.02 219.84 133.39"
					style="fill: rgb(38, 50, 56); transform-origin: 212.49px 146.31px;" id="elazoghx70jmj"
					class="animable"></polygon>
			<path d="M205.81,127.1a5.39,5.39,0,0,0-2.27-2.8,13.17,13.17,0,0,0-2.59-.93S201.78,127.61,205.81,127.1Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 203.38px 125.256px;" id="el1alvrtrmpj3"
				  class="animable"></path>
			<path d="M207.69,121.59a5.35,5.35,0,0,0-1.41-3.31,12.77,12.77,0,0,0-2.23-1.62S203.67,121,207.69,121.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 205.865px 119.125px;" id="elzq5xkgd6ju"
				  class="animable"></path>
			<path d="M224.94,138.36a5.38,5.38,0,0,0-3.1-1.85,12.68,12.68,0,0,0-2.75,0S221.32,140.23,224.94,138.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 222.015px 137.66px;" id="elh7oxqdqau8j"
				  class="animable"></path>
			<path d="M197.83,137.84a5.39,5.39,0,0,1,3.1-1.84,12.68,12.68,0,0,1,2.75,0S201.45,139.71,197.83,137.84Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 200.755px 137.146px;" id="elyeu9c45hozs"
				  class="animable"></path>
			<path d="M201.58,119.73a5.39,5.39,0,0,0-1.41-3.32A13.19,13.19,0,0,0,198,114.8S197.56,119.1,201.58,119.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 199.783px 117.265px;" id="elafm2ebs32xc"
				  class="animable"></path>
			<path d="M221.87,121a5.31,5.31,0,0,1,1.4-3.31A12.77,12.77,0,0,1,225.5,116S225.89,120.35,221.87,121Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 223.69px 118.5px;" id="el59tke06e1i3"
				  class="animable"></path>
			<path d="M211.77,118.16a5.42,5.42,0,0,0,.95-3.48A13,13,0,0,0,212,112S209,115.17,211.77,118.16Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 211.665px 115.08px;" id="el8kppx6zv8gh"
				  class="animable"></path>
			<path d="M213.18,126.4a5.4,5.4,0,0,0-.91-3.49,12.81,12.81,0,0,0-2-1.93S209.29,125.19,213.18,126.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 211.68px 123.69px;" id="elwqcdluuo5gp"
				  class="animable"></path>
			<path d="M208,116.43a5.4,5.4,0,0,0-.16-3.6,12.72,12.72,0,0,0-1.52-2.3S204.42,114.43,208,116.43Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 207.025px 113.48px;" id="elgojecnvv5ab"
				  class="animable"></path>
			<path d="M209.62,132.67a5.33,5.33,0,0,0-.16-3.6,13.2,13.2,0,0,0-1.51-2.29S206.08,130.68,209.62,132.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 208.658px 129.725px;" id="eldc3t4ulb9vg"
				  class="animable"></path>
			<path d="M214.53,130.22a5.39,5.39,0,0,0,2.2-2.85,12.7,12.7,0,0,0,.33-2.73S213.11,126.4,214.53,130.22Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 215.641px 127.43px;" id="elk1ci56a406n"
				  class="animable"></path>
			<path d="M214.75,116.43a5.34,5.34,0,0,1,.16-3.6,12.72,12.72,0,0,1,1.52-2.3S218.29,114.43,214.75,116.43Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 215.715px 113.48px;" id="elcuk2ucpli3"
				  class="animable"></path>
			<path d="M217.33,121.69a5.38,5.38,0,0,0,2.2-2.86,13.33,13.33,0,0,0,.31-2.73S215.9,117.88,217.33,121.69Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 218.429px 118.895px;" id="el2w4skd5tdou"
				  class="animable"></path>
			<path d="M222.31,127.42a5.38,5.38,0,0,0,3-2,12.44,12.44,0,0,0,1.14-2.5S222.13,123.35,222.31,127.42Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 224.377px 125.17px;" id="elyu9lqojdt8n"
				  class="animable"></path>
			<path d="M222.79,132.54a5.38,5.38,0,0,0,3.59.31,13,13,0,0,0,2.47-1.22S225.22,129.28,222.79,132.54Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 225.82px 131.918px;" id="el3ub7rmwn2dy"
				  class="animable"></path>
			<path d="M225.58,127.88a5.41,5.41,0,0,0,3.6.31,12.39,12.39,0,0,0,2.46-1.21S228,124.62,225.58,127.88Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 228.61px 127.259px;" id="eluk1dlkmffw"
				  class="animable"></path>
			<path d="M197.71,130.86a5.39,5.39,0,0,0,3,1.93,13.19,13.19,0,0,0,2.75.08S201.38,129.1,197.71,130.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 200.585px 131.65px;" id="elqg22qxq8ky"
				  class="animable"></path>
			<path d="M193.36,124.85a5.39,5.39,0,0,0,3,1.94,12.67,12.67,0,0,0,2.75.07S197,123.1,193.36,124.85Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 196.235px 125.645px;" id="elq19r09u1blb"
				  class="animable"></path>
			<path d="M307.36,432.39c-15.1,0-27.33-11.64-27.33-26v-.22a14,14,0,0,1,14.35-13.87,20.7,20.7,0,0,0,3.8-.24,9.46,9.46,0,0,0,5.94-3.68,3.91,3.91,0,0,1,3.09-1.58h.29a3.93,3.93,0,0,1,3.1,1.58,9.46,9.46,0,0,0,5.94,3.68,20.65,20.65,0,0,0,3.8.24,14,14,0,0,1,14.35,13.87v.22C334.69,420.75,322.46,432.37,307.36,432.39Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 307.36px 409.595px;" id="elk8uup6i8sl"
				  class="animable"></path>
			<polygon
					points="316.81 400.46 310.77 399.15 317.98 408.14 308.13 398.96 308.07 398.32 307.63 407.97 306.93 392.73 297.22 401.78 304.43 392.79 298.39 394.1 306.03 390.8 306.8 389.84 305.46 360.38 309.8 360.38 308.18 395.93 309.17 397.16 316.81 400.46"
					style="fill: rgb(38, 50, 56); transform-origin: 307.6px 384.26px;" id="elbasew8kytst"
					class="animable"></polygon>
			<path d="M299.23,408.35s-.91,2.6-2.86,3.51a16.07,16.07,0,0,1-3.24,1.16S294.17,407.7,299.23,408.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 296.18px 410.657px;" id="el55laa32k4m"
				  class="animable"></path>
			<path d="M301.58,415.25s-.15,2.75-1.77,4.16a15.54,15.54,0,0,1-2.79,2S296.54,416,301.58,415.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 299.294px 418.33px;" id="eli0661i7cg7a"
				  class="animable"></path>
			<path d="M323.21,394.23s-1.74,2.13-3.88,2.31a15.9,15.9,0,0,1-3.45,0S318.67,391.89,323.21,394.23Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 319.545px 395.103px;" id="elc2av0plfhtn"
				  class="animable"></path>
			<path d="M289.22,394.88s1.75,2.13,3.89,2.31a15.79,15.79,0,0,0,3.44,0S293.76,392.54,289.22,394.88Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 292.885px 395.753px;" id="elhr6wuxf8yi"
				  class="animable"></path>
			<path d="M293.92,417.59a6.78,6.78,0,0,1-1.76,4.16,16.18,16.18,0,0,1-2.79,2S288.88,418.37,293.92,417.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 291.639px 420.67px;" id="elvlh8fz04g9"
				  class="animable"></path>
			<path d="M319.36,416a6.78,6.78,0,0,0,1.76,4.16,16.18,16.18,0,0,0,2.79,2S324.4,416.82,319.36,416Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 321.641px 419.08px;" id="ell8g6tpzgo8n"
				  class="animable"></path>
			<path d="M306.7,419.56s1.58,2.25,1.19,4.36a16.1,16.1,0,0,1-.93,3.32S303.23,423.3,306.7,419.56Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 306.587px 423.4px;" id="elvo02agj342e"
				  class="animable"></path>
			<path d="M308.46,409.22s.26,2.74-1.13,4.38a15.62,15.62,0,0,1-2.46,2.41S303.59,410.75,308.46,409.22Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 306.599px 412.615px;" id="elr2yj2gfv3qg"
				  class="animable"></path>
			<path d="M301.93,421.73a6.76,6.76,0,0,1-.2,4.52,16.09,16.09,0,0,1-1.9,2.87S297.48,424.23,301.93,421.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 300.718px 425.425px;" id="elydqlekprn5"
				  class="animable"></path>
			<path d="M304,401.36s.82,2.62-.2,4.51a16.11,16.11,0,0,1-1.91,2.88S299.56,403.86,304,401.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 302.795px 405.055px;" id="el6ir6roy17v"
				  class="animable"></path>
			<path d="M310.16,404.44s2.32,1.47,2.76,3.57a15.85,15.85,0,0,1,.41,3.43S308.38,409.22,310.16,404.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 311.552px 407.94px;" id="eli7vckrajf5l"
				  class="animable"></path>
			<path d="M310.43,421.73a6.76,6.76,0,0,0,.2,4.52,15.74,15.74,0,0,0,1.91,2.87S314.88,424.23,310.43,421.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 311.644px 425.425px;" id="el8e3xbfb4l67"
				  class="animable"></path>
			<path d="M313.67,415.13s2.32,1.48,2.75,3.59a15.59,15.59,0,0,1,.39,3.42S311.88,419.91,313.67,415.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 315.044px 418.635px;" id="elm0wpuiijzyi"
				  class="animable"></path>
			<path d="M319.92,408s2.66.69,3.73,2.55a16.45,16.45,0,0,1,1.43,3.14S319.69,413,319.92,408Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 322.496px 410.845px;" id="el50xi6ax2cye"
				  class="animable"></path>
			<path d="M320.52,401.53a6.76,6.76,0,0,1,4.5-.39,15.81,15.81,0,0,1,3.09,1.52S323.57,405.62,320.52,401.53Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 324.315px 402.309px;" id="elgi9ieshncok"
				  class="animable"></path>
			<path d="M324,407.37s2.5-1.15,4.5-.39a16.14,16.14,0,0,1,3.1,1.52S327.07,411.46,324,407.37Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 327.8px 408.128px;" id="el9j5jof8avkr"
				  class="animable"></path>
			<path d="M289.07,403.64s1.67-2.18,3.81-2.43a16,16,0,0,1,3.44-.09S293.67,405.84,289.07,403.64Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 292.695px 402.646px;" id="elj8qw810a1bh"
				  class="animable"></path>
			<path d="M283.62,411.16s1.67-2.18,3.81-2.43a16,16,0,0,1,3.44-.09S288.22,413.37,283.62,411.16Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 287.245px 410.167px;" id="elgaqvt4cvan8"
				  class="animable"></path>
			<path d="M250.8,443.55c-15.1,0-27.33-11.65-27.33-26v-.22a14,14,0,0,1,14.35-13.87,20.54,20.54,0,0,0,3.79-.24,9.41,9.41,0,0,0,5.94-3.68,4,4,0,0,1,3.1-1.58h.29a3.93,3.93,0,0,1,3.1,1.58,9.46,9.46,0,0,0,5.94,3.68,20.54,20.54,0,0,0,3.79.24,14,14,0,0,1,14.35,13.87,1.7,1.7,0,0,1,0,.22C278.13,431.9,265.89,443.52,250.8,443.55Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 250.797px 420.755px;" id="elf6pbufhe8v"
				  class="animable"></path>
			<polygon
					points="260.25 411.61 254.21 410.3 261.42 419.29 251.57 410.11 251.51 409.47 251.07 419.12 250.37 403.88 240.66 412.93 247.87 403.94 241.83 405.25 249.47 401.95 250.24 400.99 248.9 371.54 253.24 371.54 251.62 407.08 252.61 408.31 260.25 411.61"
					style="fill: rgb(38, 50, 56); transform-origin: 251.04px 395.415px;" id="elanbhz2myq8i"
					class="animable"></polygon>
			<path d="M242.67,419.5s-.91,2.6-2.86,3.51a15.32,15.32,0,0,1-3.24,1.16S237.61,418.85,242.67,419.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 239.62px 421.807px;" id="elvh3v3ews3zd"
				  class="animable"></path>
			<path d="M245,426.4s-.15,2.75-1.77,4.16a16,16,0,0,1-2.79,2S240,427.19,245,426.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 242.715px 429.48px;" id="elg1rtbr7tsve"
				  class="animable"></path>
			<path d="M266.65,405.38s-1.75,2.13-3.89,2.31a15.79,15.79,0,0,1-3.44,0S262.11,403,266.65,405.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 262.985px 406.245px;" id="elkc1v3x2j0tm"
				  class="animable"></path>
			<path d="M232.66,406s1.74,2.13,3.88,2.31a15.9,15.9,0,0,0,3.45,0S237.2,403.69,232.66,406Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 236.325px 406.879px;" id="elcp8j3mizzn"
				  class="animable"></path>
			<path d="M237.36,428.74a6.78,6.78,0,0,1-1.76,4.16,16,16,0,0,1-2.8,2S232.32,429.52,237.36,428.74Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 235.074px 431.82px;" id="el99orgwrcqxe"
				  class="animable"></path>
			<path d="M262.79,427.18s.15,2.75,1.77,4.16a16.68,16.68,0,0,0,2.79,2S267.84,428,262.79,427.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 265.076px 430.26px;" id="eljt6lzyq5z9q"
				  class="animable"></path>
			<path d="M250.13,430.71s1.59,2.25,1.2,4.36a16.1,16.1,0,0,1-.93,3.32S246.67,434.46,250.13,430.71Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 250.025px 434.55px;" id="elnj2dgom289q"
				  class="animable"></path>
			<path d="M251.9,420.38s.26,2.73-1.13,4.37a16.06,16.06,0,0,1-2.46,2.42S247,421.9,251.9,420.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 250.035px 423.775px;" id="ellkw9y9252y8"
				  class="animable"></path>
			<path d="M245.37,432.88a6.76,6.76,0,0,1-.2,4.52,15.74,15.74,0,0,1-1.91,2.87S240.92,435.38,245.37,432.88Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 244.156px 436.575px;" id="el6vh7cuov07b"
				  class="animable"></path>
			<path d="M247.44,412.51s.82,2.62-.19,4.51a16.11,16.11,0,0,1-1.91,2.88S243,415,247.44,412.51Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 246.239px 416.205px;" id="elaam6wz8ird7"
				  class="animable"></path>
			<path d="M253.59,415.59s2.33,1.47,2.77,3.57a15.85,15.85,0,0,1,.41,3.43S251.82,420.37,253.59,415.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 254.989px 419.09px;" id="elindhd54pogd"
				  class="animable"></path>
			<path d="M253.87,432.88a6.76,6.76,0,0,0,.2,4.52,16.09,16.09,0,0,0,1.9,2.87S258.32,435.38,253.87,432.88Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 255.082px 436.575px;" id="elu3u3ams1hi"
				  class="animable"></path>
			<path d="M257.11,426.28s2.32,1.48,2.75,3.59a15.59,15.59,0,0,1,.39,3.42S255.31,431.06,257.11,426.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 258.483px 429.785px;" id="elcepxzqhqtca"
				  class="animable"></path>
			<path d="M263.36,419.1s2.66.69,3.73,2.55a16.45,16.45,0,0,1,1.43,3.14S263.13,424.19,263.36,419.1Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 265.936px 421.945px;" id="elqzw1of7al5"
				  class="animable"></path>
			<path d="M264,412.68s2.5-1.15,4.51-.39a16.21,16.21,0,0,1,3.09,1.52S267,416.77,264,412.68Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 267.8px 413.438px;" id="elpct1jgvjdub"
				  class="animable"></path>
			<path d="M267.46,418.52s2.5-1.15,4.5-.39a16.14,16.14,0,0,1,3.1,1.52S270.51,422.61,267.46,418.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 271.26px 419.278px;" id="elb5rb460ott"
				  class="animable"></path>
			<path d="M232.5,414.79s1.68-2.18,3.81-2.43a16.06,16.06,0,0,1,3.45-.09S237.1,417,232.5,414.79Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 236.13px 413.797px;" id="ell2wp7d4besd"
				  class="animable"></path>
			<path d="M227.05,422.31s1.68-2.18,3.81-2.43a16.06,16.06,0,0,1,3.45-.09S231.65,424.52,227.05,422.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 230.68px 421.317px;" id="el4n77lj3uoql"
				  class="animable"></path>
			<path d="M341.29,400.89c-12,0-21.79-9.29-21.79-20.72V380a11.19,11.19,0,0,1,11.44-11.07,15.72,15.72,0,0,0,3-.19,7.51,7.51,0,0,0,4.73-2.94,3.19,3.19,0,0,1,2.48-1.26h.23a3.19,3.19,0,0,1,2.47,1.26,7.51,7.51,0,0,0,4.74,2.94,15.67,15.67,0,0,0,3,.19A11.2,11.2,0,0,1,363.09,380v.17C363.09,391.6,353.33,400.87,341.29,400.89Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 341.295px 382.715px;" id="el1tzqt4e7dco"
				  class="animable"></path>
			<polygon
					points="348.83 375.42 344.01 374.38 349.76 381.55 341.91 374.23 341.86 373.72 341.51 381.42 340.95 369.26 333.2 376.48 338.96 369.31 334.14 370.35 340.23 367.72 340.85 366.96 339.77 343.46 343.24 343.46 341.94 371.81 342.74 372.79 348.83 375.42"
					style="fill: rgb(38, 50, 56); transform-origin: 341.48px 362.505px;" id="elw7083xried"
					class="animable"></polygon>
			<path d="M334.81,381.72a5.39,5.39,0,0,1-2.28,2.79,12.17,12.17,0,0,1-2.59.93S330.77,381.2,334.81,381.72Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 332.375px 383.558px;" id="elnmhw218gs3"
				  class="animable"></path>
			<path d="M336.68,387.22a5.39,5.39,0,0,1-1.41,3.32,12.5,12.5,0,0,1-2.22,1.61S332.66,387.85,336.68,387.22Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 334.86px 389.685px;" id="el7w3mz3962wa"
				  class="animable"></path>
			<path d="M353.93,370.45a5.38,5.38,0,0,1-3.09,1.85,12.68,12.68,0,0,1-2.75,0S350.32,368.59,353.93,370.45Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 351.01px 371.151px;" id="elyuqqkojme5c"
				  class="animable"></path>
			<path d="M326.83,371a5.38,5.38,0,0,0,3.09,1.85,12.68,12.68,0,0,0,2.75,0S330.44,369.1,326.83,371Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 329.75px 371.694px;" id="elz34wlrhxgd"
				  class="animable"></path>
			<path d="M330.58,389.08a5.39,5.39,0,0,1-1.41,3.32,12.77,12.77,0,0,1-2.23,1.62S326.56,389.71,330.58,389.08Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 328.755px 391.55px;" id="el8lrd848ae1o"
				  class="animable"></path>
			<path d="M350.86,387.84a5.39,5.39,0,0,0,1.41,3.32,12.5,12.5,0,0,0,2.22,1.61S354.88,388.47,350.86,387.84Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 352.68px 390.305px;" id="elh66dct5gob8"
				  class="animable"></path>
			<path d="M340.76,390.66a5.32,5.32,0,0,1,.95,3.47,12.71,12.71,0,0,1-.74,2.65S338,393.64,340.76,390.66Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 340.656px 393.72px;" id="el22sne02xtyr"
				  class="animable"></path>
			<path d="M342.17,382.41a5.35,5.35,0,0,1-.9,3.49,12.55,12.55,0,0,1-2,1.93S338.29,383.63,342.17,382.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 340.676px 385.12px;" id="el2qatrpy7noc"
				  class="animable"></path>
			<path d="M337,392.39a5.33,5.33,0,0,1-.16,3.6,12.36,12.36,0,0,1-1.52,2.29S333.41,394.38,337,392.39Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 336.026px 395.335px;" id="elygrn8wtn6rd"
				  class="animable"></path>
			<path d="M338.62,376.14a5.4,5.4,0,0,1-.16,3.6,12.36,12.36,0,0,1-1.52,2.29S335.07,378.13,338.62,376.14Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 337.651px 379.085px;" id="el6dl0y4xlxdq"
				  class="animable"></path>
			<path d="M343.52,378.6a5.34,5.34,0,0,1,2.21,2.85,13,13,0,0,1,.32,2.73S342.11,382.41,343.52,378.6Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 344.633px 381.39px;" id="elqgtlugi6mg"
				  class="animable"></path>
			<path d="M343.74,392.39a5.4,5.4,0,0,0,.16,3.6,12.36,12.36,0,0,0,1.52,2.29S347.29,394.38,343.74,392.39Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 344.709px 395.335px;" id="eliatj14lr9va"
				  class="animable"></path>
			<path d="M346.33,387.13a5.39,5.39,0,0,1,2.19,2.85,12.55,12.55,0,0,1,.31,2.74S344.89,390.93,346.33,387.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 347.422px 389.925px;" id="elg6le969svul"
				  class="animable"></path>
			<path d="M351.31,381.39a5.37,5.37,0,0,1,3,2,12.74,12.74,0,0,1,1.14,2.5S351.13,385.46,351.31,381.39Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 353.377px 383.64px;" id="elxfq8y45vfsq"
				  class="animable"></path>
			<path d="M351.79,376.28a5.38,5.38,0,0,1,3.59-.31,12.39,12.39,0,0,1,2.46,1.21S354.22,379.54,351.79,376.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 354.815px 376.901px;" id="el2wvg7di5dqr"
				  class="animable"></path>
			<path d="M354.58,380.93a5.34,5.34,0,0,1,3.59-.3,12.71,12.71,0,0,1,2.47,1.21S357,384.19,354.58,380.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 357.61px 381.554px;" id="elj2cvyuo1bmq"
				  class="animable"></path>
			<path d="M326.7,378a5.39,5.39,0,0,1,3-1.94,12.67,12.67,0,0,1,2.75-.07S330.37,379.72,326.7,378Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 329.575px 377.2px;" id="elrrev4yevch9"
				  class="animable"></path>
			<path d="M322.36,384a5.34,5.34,0,0,1,3-1.94,12.65,12.65,0,0,1,2.75-.07S326,385.72,322.36,384Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 325.235px 383.2px;" id="elxropjjrf3r"
				  class="animable"></path>
			<path d="M178.15,414.75c-12,0-21.8-9.29-21.8-20.72v-.17A11.19,11.19,0,0,1,167.8,382.8a17.44,17.44,0,0,0,3-.19,7.62,7.62,0,0,0,4.74-2.94A3.2,3.2,0,0,1,178,378.4h.23a3.17,3.17,0,0,1,2.47,1.27,7.64,7.64,0,0,0,4.74,2.94,17.49,17.49,0,0,0,3,.19,11.17,11.17,0,0,1,11.44,11.06V394C199.94,405.46,190.19,414.74,178.15,414.75Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 178.115px 396.575px;" id="elmrlsr8u3ke"
				  class="animable"></path>
			<polygon
					points="185.69 389.29 180.87 388.24 186.62 395.41 178.76 388.09 178.71 387.58 178.36 395.28 177.81 383.12 170.06 390.34 175.81 383.17 170.99 384.21 177.09 381.58 177.7 380.82 176.63 357.32 180.09 357.32 178.8 385.67 179.59 386.66 185.69 389.29"
					style="fill: rgb(38, 50, 56); transform-origin: 178.34px 376.365px;" id="el93i9qnx3ecp"
					class="animable"></polygon>
			<path d="M171.66,395.58a5.39,5.39,0,0,1-2.27,2.8,13.17,13.17,0,0,1-2.59.93S167.63,395.06,171.66,395.58Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 169.23px 397.423px;" id="elk6czfet65n"
				  class="animable"></path>
			<path d="M173.54,401.08a5.39,5.39,0,0,1-1.41,3.32A12.77,12.77,0,0,1,169.9,406S169.52,401.71,173.54,401.08Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 171.715px 403.54px;" id="el6aek4atbmgf"
				  class="animable"></path>
			<path d="M190.79,384.32a5.39,5.39,0,0,1-3.1,1.84,12.72,12.72,0,0,1-2.75,0S187.17,382.45,190.79,384.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 187.865px 385.014px;" id="elqoj4uyll598"
				  class="animable"></path>
			<path d="M163.68,384.83a5.38,5.38,0,0,0,3.1,1.85,12.68,12.68,0,0,0,2.75,0S167.3,383,163.68,384.83Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 166.605px 385.537px;" id="els3d206v6fy"
				  class="animable"></path>
			<path d="M167.43,403a5.37,5.37,0,0,1-1.41,3.31,13,13,0,0,1-2.22,1.62S163.41,403.57,167.43,403Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 165.61px 405.465px;" id="elbeczgwwy6k8"
				  class="animable"></path>
			<path d="M187.72,401.7a5.42,5.42,0,0,0,1.4,3.32,12.77,12.77,0,0,0,2.23,1.62S191.74,402.33,187.72,401.7Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 189.54px 404.17px;" id="elh1ect5it74a"
				  class="animable"></path>
			<path d="M177.62,404.52a5.4,5.4,0,0,1,.95,3.48,12.71,12.71,0,0,1-.74,2.64S174.85,407.51,177.62,404.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 177.513px 407.58px;" id="el04g7xqu11qxj"
				  class="animable"></path>
			<path d="M179,396.28a5.4,5.4,0,0,1-.91,3.49,13,13,0,0,1-2,1.92S175.14,397.49,179,396.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 177.503px 398.985px;" id="elkaswtg9yooh"
				  class="animable"></path>
			<path d="M173.82,406.25a5.4,5.4,0,0,1-.16,3.6,12.36,12.36,0,0,1-1.52,2.29S170.27,408.24,173.82,406.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 172.851px 409.195px;" id="elgsym2jab29v"
				  class="animable"></path>
			<path d="M175.47,390a5.37,5.37,0,0,1-.15,3.6,13,13,0,0,1-1.52,2.29S171.93,392,175.47,390Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 174.509px 392.945px;" id="el2zvmvmzdu2y"
				  class="animable"></path>
			<path d="M180.38,392.46a5.39,5.39,0,0,1,2.21,2.85,13,13,0,0,1,.32,2.73S179,396.27,180.38,392.46Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 181.498px 395.25px;" id="el6e6d48rz5vn"
				  class="animable"></path>
			<path d="M180.6,406.25a5.33,5.33,0,0,0,.16,3.6,12.36,12.36,0,0,0,1.52,2.29S184.15,408.24,180.6,406.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 181.567px 409.195px;" id="el4uba7wg4fh2"
				  class="animable"></path>
			<path d="M183.18,401a5.38,5.38,0,0,1,2.2,2.86,12.44,12.44,0,0,1,.31,2.73S181.75,404.8,183.18,401Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 184.279px 403.795px;" id="eliyr9ihe9yy"
				  class="animable"></path>
			<path d="M188.16,395.26a5.4,5.4,0,0,1,3,2,12.64,12.64,0,0,1,1.14,2.51S188,399.32,188.16,395.26Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 190.228px 397.515px;" id="elx82uedximb"
				  class="animable"></path>
			<path d="M188.64,390.14a5.38,5.38,0,0,1,3.59-.31A12.71,12.71,0,0,1,194.7,391S191.08,393.4,188.64,390.14Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 191.67px 390.753px;" id="elekqettmygwr"
				  class="animable"></path>
			<path d="M191.44,394.79a5.4,5.4,0,0,1,3.59-.3,12.71,12.71,0,0,1,2.47,1.21S193.87,398.06,191.44,394.79Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 194.47px 395.418px;" id="elclfyz4qsbjb"
				  class="animable"></path>
			<path d="M163.56,391.82a5.39,5.39,0,0,1,3-1.94,12.67,12.67,0,0,1,2.75-.07S167.23,393.58,163.56,391.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 166.435px 391.027px;" id="el0vupsz6m8eil"
				  class="animable"></path>
			<path d="M159.21,397.82a5.39,5.39,0,0,1,3-1.94,12.67,12.67,0,0,1,2.75-.07S162.88,399.58,159.21,397.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 162.085px 397.027px;" id="el9eksbx1tgbi"
				  class="animable"></path>
			<path d="M151.16,385.17c-9.38,0-17-7.23-17-16.13v-.13a8.71,8.71,0,0,1,8.91-8.61,12.8,12.8,0,0,0,2.36-.15,5.86,5.86,0,0,0,3.68-2.29,2.47,2.47,0,0,1,1.93-1h.18a2.45,2.45,0,0,1,1.92,1,5.86,5.86,0,0,0,3.69,2.29,12.64,12.64,0,0,0,2.35.15,8.71,8.71,0,0,1,8.91,8.61V369C168.12,377.94,160.53,385.16,151.16,385.17Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 151.125px 371.015px;" id="elq93c9mlaq8"
				  class="animable"></path>
			<polygon
					points="157.03 365.35 153.27 364.54 157.75 370.12 151.63 364.42 151.6 364.02 151.32 370.01 150.89 360.55 144.86 366.17 149.34 360.59 145.59 361.4 150.33 359.36 150.81 358.76 149.98 340.47 152.67 340.47 151.66 362.54 152.28 363.3 157.03 365.35"
					style="fill: rgb(38, 50, 56); transform-origin: 151.305px 355.295px;" id="elx4wuznf5nbm"
					class="animable"></polygon>
			<path d="M146.11,370.25a4.19,4.19,0,0,1-1.77,2.18,10,10,0,0,1-2,.72S143,369.85,146.11,370.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 144.225px 371.683px;" id="elfly6zdazni"
				  class="animable"></path>
			<path d="M147.57,374.53a4.14,4.14,0,0,1-1.1,2.58,9.8,9.8,0,0,1-1.73,1.26S144.44,375,147.57,374.53Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 146.151px 376.45px;" id="elgzw5f2ogvh"
				  class="animable"></path>
			<path d="M161,361.48a4.2,4.2,0,0,1-2.4,1.44,9.62,9.62,0,0,1-2.15,0S158.18,360,161,361.48Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 158.725px 362.021px;" id="eluxyzom3icp8"
				  class="animable"></path>
			<path d="M139.9,361.89a4.15,4.15,0,0,0,2.41,1.43,10,10,0,0,0,2.14,0S142.71,360.43,139.9,361.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 142.175px 362.428px;" id="el1tonqtiortt"
				  class="animable"></path>
			<path d="M142.82,376a4.2,4.2,0,0,1-1.1,2.58,9.8,9.8,0,0,1-1.73,1.26S139.69,376.47,142.82,376Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 141.401px 377.92px;" id="el9iv0hh4sacm"
				  class="animable"></path>
			<path d="M158.6,375a4.22,4.22,0,0,0,1.1,2.58,9.8,9.8,0,0,0,1.73,1.26S161.73,375.5,158.6,375Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 160.019px 376.92px;" id="eli4n45bfqnwl"
				  class="animable"></path>
			<path d="M150.74,377.21a4.17,4.17,0,0,1,.75,2.7,10.2,10.2,0,0,1-.58,2.06S148.59,379.53,150.74,377.21Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 150.664px 379.59px;" id="elffzh8i1xsno"
				  class="animable"></path>
			<path d="M151.84,370.79a4.21,4.21,0,0,1-.7,2.72,10.73,10.73,0,0,1-1.53,1.5S148.82,371.74,151.84,370.79Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 150.687px 372.9px;" id="el7zw6l8iuwln"
				  class="animable"></path>
			<path d="M147.79,378.55a4.18,4.18,0,0,1-.13,2.81,9.86,9.86,0,0,1-1.18,1.78S145,380.11,147.79,378.55Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 147.03px 380.845px;" id="elhz9ld07brab"
				  class="animable"></path>
			<path d="M149.07,365.91a4.19,4.19,0,0,1-.12,2.8,9.86,9.86,0,0,1-1.18,1.78S146.31,367.46,149.07,365.91Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 148.32px 368.2px;" id="elf7pi18v1xa4"
				  class="animable"></path>
			<path d="M152.89,367.82a4.19,4.19,0,0,1,1.72,2.22,9.5,9.5,0,0,1,.25,2.12S151.79,370.79,152.89,367.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 153.756px 369.99px;" id="el8w8zcp2klqt"
				  class="animable"></path>
			<path d="M153.06,378.55a4.18,4.18,0,0,0,.13,2.81,9.86,9.86,0,0,0,1.18,1.78S155.83,380.11,153.06,378.55Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 153.816px 380.845px;" id="elyua4qwpje"
				  class="animable"></path>
			<path d="M155.07,374.46a4.19,4.19,0,0,1,1.71,2.22,10.43,10.43,0,0,1,.25,2.13S154,377.42,155.07,374.46Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 155.936px 376.635px;" id="elgh0mdmbli9"
				  class="animable"></path>
			<path d="M159,370a4.21,4.21,0,0,1,2.32,1.58,10.14,10.14,0,0,1,.89,1.95S158.81,373.16,159,370Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 160.601px 371.765px;" id="ellzlwc925mki"
				  class="animable"></path>
			<path d="M159.32,366a4.24,4.24,0,0,1,2.8-.24,10.07,10.07,0,0,1,1.92,1S161.22,368.55,159.32,366Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 161.68px 366.498px;" id="elyxwy568y0zq"
				  class="animable"></path>
			<path d="M161.5,369.64a4.15,4.15,0,0,1,2.79-.24,10.42,10.42,0,0,1,1.93.94S163.39,372.18,161.5,369.64Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 163.86px 370.123px;" id="el9hia80cv1"
				  class="animable"></path>
			<path d="M139.8,367.32a4.2,4.2,0,0,1,2.37-1.51,10.1,10.1,0,0,1,2.14-.05S142.66,368.69,139.8,367.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 142.055px 366.705px;" id="elmam518n04x"
				  class="animable"></path>
			<path d="M136.42,372a4.16,4.16,0,0,1,2.36-1.5,9.77,9.77,0,0,1,2.14-.06S139.27,373.36,136.42,372Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 138.67px 371.384px;" id="el1kp9g7nl4ak"
				  class="animable"></path>
			<path d="M212.3,439.4c-12,0-21.8-9.29-21.8-20.72v-.17A11.2,11.2,0,0,1,202,407.44a15.67,15.67,0,0,0,3-.19,7.53,7.53,0,0,0,4.74-2.94,3.16,3.16,0,0,1,2.47-1.26h.23a3.13,3.13,0,0,1,2.47,1.26,7.53,7.53,0,0,0,4.74,2.94,15.72,15.72,0,0,0,3,.19,11.19,11.19,0,0,1,11.44,11.07v.17C234.09,430.11,224.34,439.38,212.3,439.4Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 212.295px 421.225px;" id="elvjysrlqeido"
				  class="animable"></path>
			<polygon
					points="219.84 413.93 215.01 412.89 220.77 420.06 212.91 412.73 212.86 412.23 212.51 419.92 211.96 407.76 204.21 414.99 209.96 407.82 205.14 408.86 211.24 406.23 211.85 405.46 210.78 381.97 214.24 381.97 212.95 410.32 213.74 411.3 219.84 413.93"
					style="fill: rgb(38, 50, 56); transform-origin: 212.49px 401.015px;" id="el8lk0955n8yb"
					class="animable"></polygon>
			<path d="M205.81,420.23a5.35,5.35,0,0,1-2.27,2.79A12.39,12.39,0,0,1,201,424S201.78,419.71,205.81,420.23Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 203.405px 422.093px;" id="elas8kt9l8cfd"
				  class="animable"></path>
			<path d="M207.69,425.73a5.39,5.39,0,0,1-1.41,3.32,13,13,0,0,1-2.23,1.61S203.67,426.36,207.69,425.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 205.865px 428.195px;" id="el1583crhu4w3"
				  class="animable"></path>
			<path d="M224.94,409a5.38,5.38,0,0,1-3.1,1.85,12.68,12.68,0,0,1-2.75,0S221.32,407.09,224.94,409Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 222.015px 409.692px;" id="elt6pzz8zap1"
				  class="animable"></path>
			<path d="M197.83,409.48a5.38,5.38,0,0,0,3.1,1.85,12.68,12.68,0,0,0,2.75,0S201.45,407.61,197.83,409.48Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 200.755px 410.18px;" id="elkzy7gih3jk"
				  class="animable"></path>
			<path d="M201.58,427.59a5.39,5.39,0,0,1-1.41,3.32,12.5,12.5,0,0,1-2.22,1.61S197.56,428.22,201.58,427.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 199.76px 430.055px;" id="el16gaioav9coh"
				  class="animable"></path>
			<path d="M221.87,426.35a5.35,5.35,0,0,0,1.4,3.32,12.31,12.31,0,0,0,2.23,1.61S225.89,427,221.87,426.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 223.69px 428.815px;" id="el7s3qyw7kurn"
				  class="animable"></path>
			<path d="M211.77,429.17a5.38,5.38,0,0,1,.95,3.47,12.71,12.71,0,0,1-.74,2.65S209,432.15,211.77,429.17Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 211.663px 432.23px;" id="elbldhdg4m4o"
				  class="animable"></path>
			<path d="M213.18,420.92a5.39,5.39,0,0,1-.91,3.49,12.81,12.81,0,0,1-2,1.93S209.29,422.14,213.18,420.92Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 211.68px 423.63px;" id="elc0e1h2yx13r"
				  class="animable"></path>
			<path d="M208,430.9a5.4,5.4,0,0,1-.16,3.6,12.83,12.83,0,0,1-1.52,2.29S204.42,432.89,208,430.9Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 207.025px 433.845px;" id="elzxclbiwye3r"
				  class="animable"></path>
			<path d="M209.62,414.65a5.33,5.33,0,0,1-.16,3.6,12.7,12.7,0,0,1-1.51,2.29S206.08,416.64,209.62,414.65Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 208.658px 417.595px;" id="eldrnxoq2js2r"
				  class="animable"></path>
			<path d="M214.53,417.11a5.34,5.34,0,0,1,2.2,2.85,12.65,12.65,0,0,1,.33,2.73S213.11,420.92,214.53,417.11Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 215.641px 419.9px;" id="elpbm5d7s1q6o"
				  class="animable"></path>
			<path d="M214.75,430.9a5.33,5.33,0,0,0,.16,3.6,12.83,12.83,0,0,0,1.52,2.29S218.29,432.89,214.75,430.9Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 215.715px 433.845px;" id="eln3yfzetd42"
				  class="animable"></path>
			<path d="M217.33,425.63a5.43,5.43,0,0,1,2.2,2.86,13.33,13.33,0,0,1,.31,2.73S215.9,429.44,217.33,425.63Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 218.429px 428.425px;" id="el9k7f78y5msr"
				  class="animable"></path>
			<path d="M222.31,419.9a5.38,5.38,0,0,1,3,2,12.74,12.74,0,0,1,1.14,2.5S222.13,424,222.31,419.9Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 224.377px 422.15px;" id="elhmiaedqcl5c"
				  class="animable"></path>
			<path d="M222.79,414.78a5.34,5.34,0,0,1,3.59-.3,12.71,12.71,0,0,1,2.47,1.21S225.22,418,222.79,414.78Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 225.82px 415.396px;" id="elx4n2a75itkq"
				  class="animable"></path>
			<path d="M225.58,419.44a5.41,5.41,0,0,1,3.6-.31,12.69,12.69,0,0,1,2.46,1.22S228,422.7,225.58,419.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 228.61px 420.062px;" id="elf24gfp4imin"
				  class="animable"></path>
			<path d="M197.71,416.47a5.39,5.39,0,0,1,3-1.94,12.67,12.67,0,0,1,2.75-.07S201.38,418.22,197.71,416.47Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 200.585px 415.675px;" id="elk42reymmgb"
				  class="animable"></path>
			<path d="M193.36,422.47a5.39,5.39,0,0,1,3-1.94,12.67,12.67,0,0,1,2.75-.07S197,424.23,193.36,422.47Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 196.235px 421.677px;" id="elaqohcp8tmsb"
				  class="animable"></path>
			<path d="M92.2,340.5c-.58-14.4,10-26.53,23.68-27.11h.2a13.39,13.39,0,0,1,13.81,13.13,19.77,19.77,0,0,0,.38,3.6,9,9,0,0,0,3.75,5.52,3.74,3.74,0,0,1,1.64,2.89v.28a3.76,3.76,0,0,1-1.38,3,9.08,9.08,0,0,0-3.28,5.82,20.06,20.06,0,0,0-.07,3.62,13.39,13.39,0,0,1-12.65,14.25h-.2C104.42,366.09,92.84,354.89,92.2,340.5Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 113.919px 339.453px;" id="ell2unavitqu"
				  class="animable"></path>
			<polygon
					points="123.04 348.22 124.04 342.41 115.76 349.65 124.12 339.88 124.72 339.8 115.5 339.77 130.01 338.49 120.98 329.59 129.85 336.11 128.36 330.4 131.81 337.55 132.76 338.25 160.79 335.78 160.97 339.92 127.01 339.81 125.87 340.8 123.04 348.22"
					style="fill: rgb(38, 50, 56); transform-origin: 138.235px 339.62px;" id="el52caj54t7j"
					class="animable"></polygon>
			<path d="M114.8,331.78a6.49,6.49,0,0,1-3.46-2.59,15.15,15.15,0,0,1-1.24-3S115.21,326.92,114.8,331.78Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 112.462px 328.985px;" id="el8kgj6z8gnri"
				  class="animable"></path>
			<path d="M108.32,334.3a6.48,6.48,0,0,1-4-1.52,15.1,15.1,0,0,1-2-2.58S107.36,329.52,108.32,334.3Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 105.32px 332.233px;" id="el4ozduoagpve"
				  class="animable"></path>
			<path d="M129.24,354.07a6.48,6.48,0,0,1-2.37-3.61,15.3,15.3,0,0,1-.12-3.29S131.29,349.65,129.24,354.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 128.241px 350.62px;" id="elzq8gwk598g"
				  class="animable"></path>
			<path d="M127.25,321.69a6.49,6.49,0,0,0-2.06,3.8,15,15,0,0,0,.16,3.28S129.66,325.92,127.25,321.69Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 126.572px 325.23px;" id="elqyg1eirw0ki"
				  class="animable"></path>
			<path d="M105.78,327.09a6.43,6.43,0,0,1-4-1.52,15.1,15.1,0,0,1-2-2.58S104.83,322.31,105.78,327.09Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 102.78px 325.023px;" id="elna409p49e3h"
				  class="animable"></path>
			<path d="M108.29,351.28a6.4,6.4,0,0,0-3.89,1.85,15,15,0,0,0-1.82,2.74S107.75,356.12,108.29,351.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 105.435px 353.576px;" id="elra02e3m2ud"
				  class="animable"></path>
			<path d="M104.41,339.35a6.4,6.4,0,0,1-4.1,1.31,15.07,15.07,0,0,1-3.21-.75S100.7,336.19,104.41,339.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 100.755px 339.37px;" id="elf1qdof0ypk4"
				  class="animable"></path>
			<path d="M114.34,340.61a6.43,6.43,0,0,1-4.22-.9,15.61,15.61,0,0,1-2.4-2.25S112.69,336,114.34,340.61Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 111.03px 338.953px;" id="elbuz2pwetoce"
				  class="animable"></path>
			<path d="M102.15,334.89a6.41,6.41,0,0,1-4.31,0,15.17,15.17,0,0,1-2.82-1.7S99.59,330.75,102.15,334.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 98.585px 333.869px;" id="el4mv54wfhiwg"
				  class="animable"></path>
			<path d="M121.66,336.05a6.41,6.41,0,0,1-4.31,0,15.17,15.17,0,0,1-2.82-1.7S119.1,331.91,121.66,336.05Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 118.095px 335.029px;" id="el0x7xnccmwlo9"
				  class="animable"></path>
			<path d="M119,342a6.45,6.45,0,0,1-3.29,2.78,15,15,0,0,1-3.25.52S114.34,340.54,119,342Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 115.73px 343.511px;" id="elqniyaeeshtq"
				  class="animable"></path>
			<path d="M102.5,343a6.43,6.43,0,0,0-4.3.37,15.4,15.4,0,0,0-2.66,1.93S100.29,347.34,102.5,343Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 99.02px 344.284px;" id="elgb7hhu81lvd"
				  class="animable"></path>
			<path d="M108.92,345.82a6.44,6.44,0,0,1-3.31,2.77,15.28,15.28,0,0,1-3.25.51S104.29,344.3,108.92,345.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 105.64px 347.311px;" id="elhni3o8elksi"
				  class="animable"></path>
			<path d="M116,351.49a6.47,6.47,0,0,1-2.28,3.66,15.46,15.46,0,0,1-2.93,1.49S111.15,351.48,116,351.49Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 113.395px 354.065px;" id="elnbojx5uxixg"
				  class="animable"></path>
			<path d="M122.17,351.8a6.43,6.43,0,0,1,.55,4.28,15,15,0,0,1-1.33,3S118.39,354.87,122.17,351.8Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 121.564px 355.44px;" id="elub45br3men"
				  class="animable"></path>
			<path d="M116.74,355.38a6.42,6.42,0,0,1,.55,4.27,15.35,15.35,0,0,1-1.32,3S113,358.45,116.74,355.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 116.144px 359.015px;" id="ellaufhy4bd2"
				  class="animable"></path>
			<path d="M118.89,321.89a6.5,6.5,0,0,1,2.47,3.54,15.39,15.39,0,0,1,.22,3.28S117,326.37,118.89,321.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 120.013px 325.3px;" id="eloorlbllqhhf"
				  class="animable"></path>
			<path d="M111.49,317a6.4,6.4,0,0,1,2.47,3.54,15.09,15.09,0,0,1,.23,3.28S109.57,321.48,111.49,317Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 112.612px 320.41px;" id="elnhkdpo0z3fn"
				  class="animable"></path>
			<path d="M79.29,287c-.59-14.4,10-26.53,23.67-27.11h.21A13.38,13.38,0,0,1,117,273a19.78,19.78,0,0,0,.38,3.61,9.08,9.08,0,0,0,3.76,5.52,3.76,3.76,0,0,1,1.63,2.89v.27a3.76,3.76,0,0,1-1.38,3,9,9,0,0,0-3.27,5.81,19.31,19.31,0,0,0-.08,3.63A13.39,13.39,0,0,1,105.37,312h-.2C91.5,312.6,79.92,301.41,79.29,287Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 101.019px 285.953px;" id="ela7gwzopdpoi"
				  class="animable"></path>
			<polygon
					points="110.12 294.74 111.12 288.92 102.84 296.17 111.2 286.4 111.81 286.32 102.58 286.29 117.09 285.01 108.07 276.11 116.93 282.63 115.44 276.92 118.89 284.07 119.84 284.77 147.87 282.29 148.05 286.43 114.09 286.32 112.95 287.32 110.12 294.74"
					style="fill: rgb(38, 50, 56); transform-origin: 125.315px 286.14px;" id="el8ht6ksoivs"
					class="animable"></polygon>
			<path d="M101.88,278.29a6.41,6.41,0,0,1-3.45-2.58,15.16,15.16,0,0,1-1.25-3S102.3,273.44,101.88,278.29Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 99.5422px 275.5px;" id="elz8zv1egg7h"
				  class="animable"></path>
			<path d="M95.4,280.81a6.46,6.46,0,0,1-4-1.51,15.58,15.58,0,0,1-2-2.58S94.45,276,95.4,280.81Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.4px 278.746px;" id="elzlfyyw9hmno"
				  class="animable"></path>
			<path d="M116.32,300.59A6.44,6.44,0,0,1,114,297a15,15,0,0,1-.13-3.29S118.37,296.17,116.32,300.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 115.344px 297.15px;" id="eludogmxs9y4b"
				  class="animable"></path>
			<path d="M114.33,268.21a6.44,6.44,0,0,0-2,3.79,14.71,14.71,0,0,0,.15,3.29S116.74,272.44,114.33,268.21Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 113.683px 271.75px;" id="el9gbgil7kii"
				  class="animable"></path>
			<path d="M92.86,273.61a6.46,6.46,0,0,1-4-1.52,14.91,14.91,0,0,1-2-2.58S91.91,268.83,92.86,273.61Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 89.86px 271.543px;" id="el8b21zbglsu2"
				  class="animable"></path>
			<path d="M95.38,297.8a6.42,6.42,0,0,0-3.9,1.85,15.67,15.67,0,0,0-1.82,2.74S94.83,302.63,95.38,297.8Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.52px 300.096px;" id="elzxhn8ji95lg"
				  class="animable"></path>
			<path d="M91.5,285.86a6.42,6.42,0,0,1-4.11,1.32,15.16,15.16,0,0,1-3.2-.76S87.79,282.71,91.5,285.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 87.845px 285.888px;" id="el2sjycn8g7ym"
				  class="animable"></path>
			<path d="M101.42,287.13a6.4,6.4,0,0,1-4.21-.9A15.45,15.45,0,0,1,94.8,284S99.78,282.55,101.42,287.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 98.11px 285.484px;" id="elhvai8e0hez8"
				  class="animable"></path>
			<path d="M89.24,281.41a6.43,6.43,0,0,1-4.32,0,14.82,14.82,0,0,1-2.81-1.7S86.67,277.27,89.24,281.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 85.675px 280.39px;" id="el126w3xomh0cd"
				  class="animable"></path>
			<path d="M108.75,282.57a6.5,6.5,0,0,1-4.32,0,15.49,15.49,0,0,1-2.81-1.7S106.18,278.43,108.75,282.57Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 105.185px 281.548px;" id="elukts1br94n"
				  class="animable"></path>
			<path d="M106.06,288.55a6.44,6.44,0,0,1-3.3,2.79,14.91,14.91,0,0,1-3.25.52S101.42,287.05,106.06,288.55Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 102.785px 290.06px;" id="elgazm5jromh6"
				  class="animable"></path>
			<path d="M89.58,289.52a6.43,6.43,0,0,0-4.3.37,15.4,15.4,0,0,0-2.66,1.93S87.37,293.86,89.58,289.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 86.1px 290.804px;" id="el12ztc2fbjy1"
				  class="animable"></path>
			<path d="M96,292.34a6.44,6.44,0,0,1-3.3,2.77,15.32,15.32,0,0,1-3.26.51S91.37,290.82,96,292.34Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.72px 293.831px;" id="elqvka9uz2f0f"
				  class="animable"></path>
			<path d="M103.11,298a6.41,6.41,0,0,1-2.29,3.65,14.89,14.89,0,0,1-2.93,1.5S98.24,298,103.11,298Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 100.5px 300.575px;" id="elxc9ax2rdxg"
				  class="animable"></path>
			<path d="M109.25,298.32a6.42,6.42,0,0,1,.55,4.27,15.35,15.35,0,0,1-1.32,3S105.48,301.39,109.25,298.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 108.647px 301.955px;" id="el1slrb3d5eab"
				  class="animable"></path>
			<path d="M103.82,301.89a6.45,6.45,0,0,1,.55,4.28,15.4,15.4,0,0,1-1.32,3S100.05,305,103.82,301.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 103.217px 305.53px;" id="elqqdutixvxd"
				  class="animable"></path>
			<path d="M106,268.41a6.45,6.45,0,0,1,2.47,3.54,15.09,15.09,0,0,1,.23,3.28S104.05,272.89,106,268.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 107.117px 271.82px;" id="elvi1j4kgr0n"
				  class="animable"></path>
			<path d="M98.57,263.52a6.4,6.4,0,0,1,2.47,3.53,15.19,15.19,0,0,1,.23,3.29S96.66,268,98.57,263.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 99.694px 266.93px;" id="elj6hl2o5so0q"
				  class="animable"></path>
			<path d="M103.8,216.58c-.46-11.48,8-21.16,18.88-21.62h.17a10.68,10.68,0,0,1,11,10.47,15.32,15.32,0,0,0,.3,2.88,7.22,7.22,0,0,0,3,4.4,3,3,0,0,1,1.3,2.3v.22a3,3,0,0,1-1.1,2.41,7.19,7.19,0,0,0-2.61,4.63,15.76,15.76,0,0,0-.07,2.9,10.67,10.67,0,0,1-10.08,11.36h-.16C113.54,237,104.31,228.06,103.8,216.58Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 121.117px 215.751px;" id="elk2ak6de23bd"
				  class="animable"></path>
			<polygon
					points="128.39 222.74 129.19 218.1 122.59 223.88 129.26 216.09 129.74 216.02 122.38 216 133.96 214.98 126.76 207.88 133.82 213.08 132.64 208.52 135.39 214.23 136.15 214.78 158.5 212.81 158.64 216.12 131.56 216.03 130.66 216.82 128.39 222.74"
					style="fill: rgb(38, 50, 56); transform-origin: 140.51px 215.88px;" id="elvehofvckzl"
					class="animable"></polygon>
			<path d="M121.83,209.62a5.08,5.08,0,0,1-2.76-2.06,12.24,12.24,0,0,1-1-2.43S122.16,205.75,121.83,209.62Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 119.959px 207.375px;" id="elueuuho7id1"
				  class="animable"></path>
			<path d="M116.66,211.63a5.12,5.12,0,0,1-3.23-1.21,12.65,12.65,0,0,1-1.63-2S115.89,207.82,116.66,211.63Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 114.23px 210.008px;" id="elo5m8h9ud9wp"
				  class="animable"></path>
			<path d="M133.34,227.4a5.15,5.15,0,0,1-1.89-2.87,12.26,12.26,0,0,1-.1-2.63S135,223.88,133.34,227.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 132.547px 224.65px;" id="elmyr41uxmfbj"
				  class="animable"></path>
			<path d="M131.75,201.58a5.16,5.16,0,0,0-1.64,3,12.48,12.48,0,0,0,.12,2.62S133.68,205,131.75,201.58Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 131.212px 204.39px;" id="eldas0b6nqv0q"
				  class="animable"></path>
			<path d="M114.63,205.89a5.16,5.16,0,0,1-3.22-1.21,12.22,12.22,0,0,1-1.63-2.06S113.87,202.08,114.63,205.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 112.205px 204.242px;" id="elacqql86uexe"
				  class="animable"></path>
			<path d="M116.64,225.18a5.14,5.14,0,0,0-3.11,1.47,12.56,12.56,0,0,0-1.45,2.19S116.2,229,116.64,225.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 114.36px 227.01px;" id="eli7p4yxtkir"
				  class="animable"></path>
			<path d="M113.54,215.66a5.13,5.13,0,0,1-3.27,1.05,12.44,12.44,0,0,1-2.56-.6S110.58,213.15,113.54,215.66Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 110.625px 215.681px;" id="elhwv8yqr1sns"
				  class="animable"></path>
			<path d="M121.46,216.67a5.08,5.08,0,0,1-3.36-.72,12.2,12.2,0,0,1-1.92-1.79S120.15,213,121.46,216.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 118.82px 215.351px;" id="elfpx5c3br44"
				  class="animable"></path>
			<path d="M111.74,212.11a5.11,5.11,0,0,1-3.44,0,12.24,12.24,0,0,1-2.25-1.35S109.69,208.81,111.74,212.11Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 108.895px 211.298px;" id="el4uif4md1mm9"
				  class="animable"></path>
			<path d="M127.3,213a5.11,5.11,0,0,1-3.44,0,12.24,12.24,0,0,1-2.25-1.35S125.25,209.73,127.3,213Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 124.455px 212.194px;" id="el9w8fq6gbjqk"
				  class="animable"></path>
			<path d="M125.15,217.81a5.14,5.14,0,0,1-2.62,2.22,12.31,12.31,0,0,1-2.6.41S121.46,216.61,125.15,217.81Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 122.54px 219.009px;" id="elusvaexosho8"
				  class="animable"></path>
			<path d="M112,218.57a5.1,5.1,0,0,0-3.42.3,12.43,12.43,0,0,0-2.13,1.54S110.25,222,112,218.57Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 109.225px 219.591px;" id="elad272vyjnzl"
				  class="animable"></path>
			<path d="M117.13,220.82A5.13,5.13,0,0,1,114.5,223a12.31,12.31,0,0,1-2.6.41S113.44,219.61,117.13,220.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 114.515px 221.996px;" id="elafgzene6a4q"
				  class="animable"></path>
			<path d="M122.8,225.34a5.14,5.14,0,0,1-1.82,2.92,12.07,12.07,0,0,1-2.34,1.19S118.92,225.34,122.8,225.34Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 120.72px 227.395px;" id="el6imosuobi0u"
				  class="animable"></path>
			<path d="M127.7,225.59a5.11,5.11,0,0,1,.44,3.41,12.29,12.29,0,0,1-1.06,2.41S124.69,228,127.7,225.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 127.219px 228.5px;" id="elg6p760y29b"
				  class="animable"></path>
			<path d="M123.37,228.44a5.15,5.15,0,0,1,.44,3.42,12.4,12.4,0,0,1-1,2.4S120.36,230.9,123.37,228.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 122.898px 231.35px;" id="eljsse2eyeid"
				  class="animable"></path>
			<path d="M125.08,201.74a5.11,5.11,0,0,1,2,2.82,12.17,12.17,0,0,1,.18,2.62S123.56,205.31,125.08,201.74Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 125.992px 204.46px;" id="eljrqpv3hhf8f"
				  class="animable"></path>
			<path d="M119.19,197.84a5.16,5.16,0,0,1,2,2.82,12.15,12.15,0,0,1,.18,2.62S117.66,201.41,119.19,197.84Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 120.1px 200.56px;" id="elt8969to162n"
				  class="animable"></path>
			<path d="M81.68,250.14c-.46-11.48,8-21.16,18.88-21.62h.17a10.68,10.68,0,0,1,11,10.47,15.13,15.13,0,0,0,.3,2.87,7.16,7.16,0,0,0,3,4.4,3,3,0,0,1,1.3,2.31v.22a3,3,0,0,1-1.11,2.41,7.23,7.23,0,0,0-2.61,4.63,16.67,16.67,0,0,0-.06,2.9,10.68,10.68,0,0,1-10.08,11.36h-.17C91.42,270.55,82.19,261.62,81.68,250.14Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 98.9966px 249.311px;" id="eljg7hgybkfx9"
				  class="animable"></path>
			<polygon
					points="106.27 256.3 107.07 251.66 100.47 257.43 107.14 249.65 107.62 249.58 100.26 249.56 111.84 248.54 104.64 241.44 111.7 246.64 110.52 242.08 113.27 247.79 114.02 248.34 136.38 246.37 136.52 249.67 109.44 249.59 108.53 250.38 106.27 256.3"
					style="fill: rgb(38, 50, 56); transform-origin: 118.39px 249.435px;" id="el21eo5s07lij"
					class="animable"></polygon>
			<path d="M99.7,243.18A5.13,5.13,0,0,1,97,241.12a11.51,11.51,0,0,1-1-2.43S100,239.31,99.7,243.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 97.858px 240.935px;" id="elb44f12urdzu"
				  class="animable"></path>
			<path d="M94.53,245.19A5.16,5.16,0,0,1,91.31,244a12.42,12.42,0,0,1-1.63-2.06S93.77,241.38,94.53,245.19Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.105px 243.551px;" id="elx4syot4whrq"
				  class="animable"></path>
			<path d="M111.22,261a5.14,5.14,0,0,1-1.89-2.88,12.17,12.17,0,0,1-.1-2.62S112.85,257.44,111.22,261Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 110.421px 258.25px;" id="elbrfhj6v9otm"
				  class="animable"></path>
			<path d="M109.63,235.14a5.15,5.15,0,0,0-1.64,3,12.55,12.55,0,0,0,.12,2.63S111.56,238.51,109.63,235.14Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 109.092px 237.955px;" id="elnk1btofxeb"
				  class="animable"></path>
			<path d="M92.51,239.44a5.1,5.1,0,0,1-3.22-1.21,12,12,0,0,1-1.63-2S91.75,235.63,92.51,239.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 90.085px 237.818px;" id="eluwwee7wv7os"
				  class="animable"></path>
			<path d="M94.52,258.73a5.17,5.17,0,0,0-3.11,1.48A11.86,11.86,0,0,0,90,262.4S94.08,262.59,94.52,258.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.26px 260.566px;" id="el7alw4gjztlr"
				  class="animable"></path>
			<path d="M91.42,249.22a5.13,5.13,0,0,1-3.27,1.05,12.44,12.44,0,0,1-2.56-.6S88.46,246.7,91.42,249.22Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 88.505px 249.239px;" id="elmppbx1utyoe"
				  class="animable"></path>
			<path d="M99.34,250.23a5.15,5.15,0,0,1-3.36-.72,11.63,11.63,0,0,1-1.92-1.79S98,246.57,99.34,250.23Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 96.7px 248.911px;" id="eljh5tq6xdff"
				  class="animable"></path>
			<path d="M89.62,245.67a5.18,5.18,0,0,1-3.44,0,11.88,11.88,0,0,1-2.25-1.36S87.57,242.36,89.62,245.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 86.775px 244.852px;" id="elrlok2w6ho3s"
				  class="animable"></path>
			<path d="M105.18,246.59a5.11,5.11,0,0,1-3.44,0,12.24,12.24,0,0,1-2.25-1.35S103.13,243.29,105.18,246.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 102.335px 245.778px;" id="el3vqzqqtbj7i"
				  class="animable"></path>
			<path d="M103,251.36a5.08,5.08,0,0,1-2.63,2.22,11.9,11.9,0,0,1-2.59.42S99.34,250.17,103,251.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 100.39px 252.565px;" id="el5nje411qzcv"
				  class="animable"></path>
			<path d="M89.89,252.13a5.13,5.13,0,0,0-3.43.3A11.92,11.92,0,0,0,84.34,254S88.13,255.59,89.89,252.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 87.115px 253.164px;" id="ellxdssmy1tro"
				  class="animable"></path>
			<path d="M95,254.38a5.13,5.13,0,0,1-2.63,2.21,12.31,12.31,0,0,1-2.6.41S91.32,253.17,95,254.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 92.385px 255.572px;" id="elojwdudrnlfp"
				  class="animable"></path>
			<path d="M100.68,258.9a5.14,5.14,0,0,1-1.82,2.92A12.4,12.4,0,0,1,96.52,263S96.8,258.89,100.68,258.9Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 98.6px 260.95px;" id="elrrqp4z6luj"
				  class="animable"></path>
			<path d="M105.58,259.15a5.11,5.11,0,0,1,.44,3.41A12,12,0,0,1,105,265S102.57,261.6,105.58,259.15Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 105.105px 262.075px;" id="elsngzgg5i94"
				  class="animable"></path>
			<path d="M101.25,262a5.11,5.11,0,0,1,.44,3.41,12.29,12.29,0,0,1-1.06,2.41S98.24,264.45,101.25,262Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 100.769px 264.91px;" id="elkqzr0uxehcc"
				  class="animable"></path>
			<path d="M103,235.3a5.16,5.16,0,0,1,2,2.82,12.15,12.15,0,0,1,.18,2.62S101.43,238.87,103,235.3Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 103.903px 238.02px;" id="elp9neug6838k"
				  class="animable"></path>
			<path d="M97.06,231.4a5.06,5.06,0,0,1,2,2.82,12.15,12.15,0,0,1,.18,2.62S95.54,235,97.06,231.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 97.9716px 234.12px;" id="eld4h5qasc8ih"
				  class="animable"></path>
			<path d="M403,340.5c.58-14.4-10-26.53-23.68-27.11h-.2a13.39,13.39,0,0,0-13.81,13.13,19.77,19.77,0,0,1-.38,3.6,9,9,0,0,1-3.75,5.52,3.74,3.74,0,0,0-1.64,2.89v.28a3.76,3.76,0,0,0,1.38,3,9.08,9.08,0,0,1,3.28,5.82,20.06,20.06,0,0,1,.07,3.62A13.39,13.39,0,0,0,377,365.5h.2C390.82,366.09,402.4,354.89,403,340.5Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 381.281px 339.453px;" id="elltdqi8jvxas"
				  class="animable"></path>
			<polygon
					points="372.2 348.22 371.2 342.41 379.48 349.65 371.12 339.88 370.52 339.8 379.74 339.77 365.23 338.49 374.25 329.59 365.39 336.11 366.88 330.4 363.43 337.55 362.48 338.25 334.45 335.78 334.27 339.92 368.23 339.81 369.37 340.8 372.2 348.22"
					style="fill: rgb(38, 50, 56); transform-origin: 357.005px 339.62px;" id="el1hni6p6ps05"
					class="animable"></polygon>
			<path d="M380.44,331.78a6.49,6.49,0,0,0,3.46-2.59,15.15,15.15,0,0,0,1.24-3S380,326.92,380.44,331.78Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 382.777px 328.985px;" id="el8zzpivje5w2"
				  class="animable"></path>
			<path d="M386.92,334.3a6.48,6.48,0,0,0,4-1.52,15.1,15.1,0,0,0,2-2.58S387.88,329.52,386.92,334.3Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 389.92px 332.233px;" id="elg173k7g5xqa"
				  class="animable"></path>
			<path d="M366,354.07a6.48,6.48,0,0,0,2.37-3.61,15.3,15.3,0,0,0,.12-3.29S364,349.65,366,354.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 367.008px 350.62px;" id="elnhk15o1tbv"
				  class="animable"></path>
			<path d="M368,321.69a6.49,6.49,0,0,1,2.06,3.8,15,15,0,0,1-.16,3.28S365.58,325.92,368,321.69Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 368.676px 325.23px;" id="el3pfbzsttzsc"
				  class="animable"></path>
			<path d="M389.46,327.09a6.43,6.43,0,0,0,4-1.52,15.1,15.1,0,0,0,2-2.58S390.41,322.31,389.46,327.09Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 392.46px 325.023px;" id="elzzfsfj9srwe"
				  class="animable"></path>
			<path d="M387,351.28a6.4,6.4,0,0,1,3.89,1.85,15.67,15.67,0,0,1,1.82,2.74S387.49,356.12,387,351.28Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 389.855px 353.576px;" id="elv5a899d80l"
				  class="animable"></path>
			<path d="M390.83,339.35a6.4,6.4,0,0,0,4.1,1.31,15.07,15.07,0,0,0,3.21-.75S394.53,336.19,390.83,339.35Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 394.485px 339.37px;" id="elc40wfw1133n"
				  class="animable"></path>
			<path d="M380.9,340.61a6.43,6.43,0,0,0,4.22-.9,15.61,15.61,0,0,0,2.4-2.25S382.55,336,380.9,340.61Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 384.21px 338.953px;" id="elxptiarz1mb"
				  class="animable"></path>
			<path d="M393.09,334.89a6.41,6.41,0,0,0,4.31,0,15.17,15.17,0,0,0,2.82-1.7S395.65,330.75,393.09,334.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 396.655px 333.869px;" id="elnii6fzzdu1s"
				  class="animable"></path>
			<path d="M373.58,336.05a6.41,6.41,0,0,0,4.31,0,15.17,15.17,0,0,0,2.82-1.7S376.14,331.91,373.58,336.05Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 377.145px 335.029px;" id="elbm3159godg"
				  class="animable"></path>
			<path d="M376.27,342a6.45,6.45,0,0,0,3.29,2.78,15,15,0,0,0,3.25.52S380.9,340.54,376.27,342Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 379.54px 343.511px;" id="elsmgpwodixah"
				  class="animable"></path>
			<path d="M392.74,343a6.43,6.43,0,0,1,4.3.37,15.4,15.4,0,0,1,2.66,1.93S395,347.34,392.74,343Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 396.22px 344.284px;" id="ellhratqgdukn"
				  class="animable"></path>
			<path d="M386.32,345.82a6.44,6.44,0,0,0,3.31,2.77,15.28,15.28,0,0,0,3.25.51S391,344.3,386.32,345.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 389.6px 347.311px;" id="el2g3hfb7gi1l"
				  class="animable"></path>
			<path d="M379.22,351.49a6.47,6.47,0,0,0,2.28,3.66,15.46,15.46,0,0,0,2.93,1.49S384.09,351.48,379.22,351.49Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 381.825px 354.065px;" id="elwc8s3ch8wb"
				  class="animable"></path>
			<path d="M373.07,351.8a6.43,6.43,0,0,0-.55,4.28,15,15,0,0,0,1.33,3S376.85,354.87,373.07,351.8Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 373.676px 355.44px;" id="elq0ex8rgoewd"
				  class="animable"></path>
			<path d="M378.5,355.38a6.42,6.42,0,0,0-.55,4.27,15.35,15.35,0,0,0,1.32,3S382.27,358.45,378.5,355.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 379.103px 359.015px;" id="elnmmy6xcxiks"
				  class="animable"></path>
			<path d="M376.35,321.89a6.5,6.5,0,0,0-2.47,3.54,15.39,15.39,0,0,0-.22,3.28S378.27,326.37,376.35,321.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 375.233px 325.3px;" id="ellkiryymdbee"
				  class="animable"></path>
			<path d="M383.75,317a6.4,6.4,0,0,0-2.47,3.54,15.09,15.09,0,0,0-.23,3.28S385.67,321.48,383.75,317Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 382.628px 320.41px;" id="el8in21amo796"
				  class="animable"></path>
			<path d="M416,287c.59-14.4-10-26.53-23.67-27.11h-.21A13.38,13.38,0,0,0,378.27,273a19.78,19.78,0,0,1-.38,3.61,9.08,9.08,0,0,1-3.76,5.52A3.76,3.76,0,0,0,372.5,285v.27a3.76,3.76,0,0,0,1.38,3,9,9,0,0,1,3.27,5.81,19.31,19.31,0,0,1,.08,3.63A13.39,13.39,0,0,0,389.87,312h.2C403.74,312.6,415.32,301.41,416,287Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 394.261px 285.952px;" id="el71gwmem3x74"
				  class="animable"></path>
			<polygon
					points="385.12 294.74 384.12 288.92 392.4 296.17 384.04 286.4 383.43 286.32 392.66 286.29 378.14 285.01 387.17 276.11 378.31 282.63 379.8 276.92 376.35 284.07 375.4 284.77 347.37 282.29 347.19 286.43 381.15 286.32 382.29 287.32 385.12 294.74"
					style="fill: rgb(38, 50, 56); transform-origin: 369.925px 286.14px;" id="elko5shee6kjn"
					class="animable"></polygon>
			<path d="M393.36,278.29a6.41,6.41,0,0,0,3.45-2.58,15.16,15.16,0,0,0,1.25-3S392.94,273.44,393.36,278.29Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 395.698px 275.5px;" id="elov9l1r6ts0k"
				  class="animable"></path>
			<path d="M399.84,280.81a6.46,6.46,0,0,0,4-1.51,15.58,15.58,0,0,0,2-2.58S400.79,276,399.84,280.81Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 402.84px 278.746px;" id="eleayqsmdx019"
				  class="animable"></path>
			<path d="M378.92,300.59a6.44,6.44,0,0,0,2.36-3.61,15,15,0,0,0,.13-3.29S376.87,296.17,378.92,300.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 379.918px 297.14px;" id="elsazt45n2xaq"
				  class="animable"></path>
			<path d="M380.91,268.21a6.44,6.44,0,0,1,2,3.79,14.71,14.71,0,0,1-.15,3.29S378.5,272.44,380.91,268.21Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 381.557px 271.75px;" id="elyjla9g47z7"
				  class="animable"></path>
			<path d="M402.38,273.61a6.46,6.46,0,0,0,4-1.52,14.91,14.91,0,0,0,2-2.58S403.33,268.83,402.38,273.61Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 405.38px 271.543px;" id="elivgzy6w7eub"
				  class="animable"></path>
			<path d="M399.86,297.8a6.42,6.42,0,0,1,3.9,1.85,15.67,15.67,0,0,1,1.82,2.74S400.41,302.63,399.86,297.8Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 402.72px 300.096px;" id="eltt9u5xrimk"
				  class="animable"></path>
			<path d="M403.74,285.86a6.42,6.42,0,0,0,4.11,1.32,15.16,15.16,0,0,0,3.2-.76S407.45,282.71,403.74,285.86Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 407.395px 285.888px;" id="ely7c0suwudt"
				  class="animable"></path>
			<path d="M393.81,287.13a6.43,6.43,0,0,0,4.22-.9,15,15,0,0,0,2.4-2.25S395.46,282.55,393.81,287.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 397.12px 285.477px;" id="elaqbxe8gv1qe"
				  class="animable"></path>
			<path d="M406,281.41a6.43,6.43,0,0,0,4.32,0,14.82,14.82,0,0,0,2.81-1.7S408.57,277.27,406,281.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 409.565px 280.39px;" id="elrj5jw8wblfg"
				  class="animable"></path>
			<path d="M386.49,282.57a6.5,6.5,0,0,0,4.32,0,15.49,15.49,0,0,0,2.81-1.7S389.06,278.43,386.49,282.57Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 390.055px 281.548px;" id="elb5faivxaai8"
				  class="animable"></path>
			<path d="M389.18,288.55a6.44,6.44,0,0,0,3.3,2.79,14.91,14.91,0,0,0,3.25.52S393.82,287.05,389.18,288.55Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 392.455px 290.06px;" id="elbz3t4o1eihi"
				  class="animable"></path>
			<path d="M405.66,289.52a6.43,6.43,0,0,1,4.3.37,15.4,15.4,0,0,1,2.66,1.93S407.87,293.86,405.66,289.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 409.14px 290.804px;" id="elx0mv8zu8d7r"
				  class="animable"></path>
			<path d="M399.24,292.34a6.44,6.44,0,0,0,3.3,2.77,15.32,15.32,0,0,0,3.26.51S403.86,290.82,399.24,292.34Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 402.52px 293.831px;" id="elw2k4iuj5pco"
				  class="animable"></path>
			<path d="M392.13,298a6.41,6.41,0,0,0,2.29,3.65,14.89,14.89,0,0,0,2.93,1.5S397,298,392.13,298Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 394.74px 300.575px;" id="ela5p5lvm6eys"
				  class="animable"></path>
			<path d="M386,298.32a6.42,6.42,0,0,0-.55,4.27,15.35,15.35,0,0,0,1.32,3S389.76,301.39,386,298.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 386.6px 301.955px;" id="elq0pw1n2fq5i"
				  class="animable"></path>
			<path d="M391.41,301.89a6.47,6.47,0,0,0-.54,4.28,15.4,15.4,0,0,0,1.32,3S395.19,305,391.41,301.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 392.021px 305.53px;" id="elzzl2el6hoa"
				  class="animable"></path>
			<path d="M389.27,268.41A6.4,6.4,0,0,0,386.8,272a15.09,15.09,0,0,0-.23,3.28S391.19,272.89,389.27,268.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 388.148px 271.845px;" id="elcxhdtyoidps"
				  class="animable"></path>
			<path d="M396.67,263.52a6.4,6.4,0,0,0-2.47,3.53,15.19,15.19,0,0,0-.23,3.29S398.58,268,396.67,263.52Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 395.546px 266.93px;" id="el95gprl4ulyh"
				  class="animable"></path>
			<path d="M391.44,216.58c.46-11.48-8-21.16-18.88-21.62h-.17a10.68,10.68,0,0,0-11,10.47,15.32,15.32,0,0,1-.3,2.88,7.22,7.22,0,0,1-3,4.4,3,3,0,0,0-1.3,2.3v.22a3,3,0,0,0,1.1,2.41,7.19,7.19,0,0,1,2.61,4.63,15.76,15.76,0,0,1,.07,2.9,10.67,10.67,0,0,0,10.08,11.36h.16C381.7,237,390.93,228.06,391.44,216.58Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 374.123px 215.751px;" id="elgwuy2bj1ek"
				  class="animable"></path>
			<polygon
					points="366.85 222.74 366.05 218.1 372.65 223.88 365.98 216.09 365.5 216.02 372.86 216 361.28 214.98 368.48 207.88 361.42 213.08 362.6 208.52 359.85 214.23 359.1 214.78 336.74 212.81 336.6 216.12 363.68 216.03 364.58 216.82 366.85 222.74"
					style="fill: rgb(38, 50, 56); transform-origin: 354.73px 215.88px;" id="el3fuzod6qbhx"
					class="animable"></polygon>
			<path d="M373.41,209.62a5.08,5.08,0,0,0,2.76-2.06,12.24,12.24,0,0,0,1-2.43S373.08,205.75,373.41,209.62Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 375.281px 207.375px;" id="elrpnl8a56a6"
				  class="animable"></path>
			<path d="M378.58,211.63a5.12,5.12,0,0,0,3.23-1.21,12.65,12.65,0,0,0,1.63-2S379.35,207.82,378.58,211.63Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 381.01px 210.008px;" id="el2umgkcr5qdu"
				  class="animable"></path>
			<path d="M361.9,227.4a5.15,5.15,0,0,0,1.89-2.87,12.26,12.26,0,0,0,.1-2.63S360.27,223.88,361.9,227.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 362.699px 224.65px;" id="elfu7wtzjax1r"
				  class="animable"></path>
			<path d="M363.49,201.58a5.16,5.16,0,0,1,1.64,3,12,12,0,0,1-.13,2.62S361.56,205,363.49,201.58Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 364.027px 204.39px;" id="elfsmt051tk6"
				  class="animable"></path>
			<path d="M380.61,205.89a5.16,5.16,0,0,0,3.22-1.21,12.22,12.22,0,0,0,1.63-2.06S381.37,202.08,380.61,205.89Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 383.035px 204.242px;" id="el4s4jffv3p33"
				  class="animable"></path>
			<path d="M378.6,225.18a5.14,5.14,0,0,1,3.11,1.47,12.56,12.56,0,0,1,1.45,2.19S379,229,378.6,225.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 380.88px 227.01px;" id="elbtrsyv3usi6"
				  class="animable"></path>
			<path d="M381.7,215.66a5.13,5.13,0,0,0,3.27,1.05,12.44,12.44,0,0,0,2.56-.6S384.66,213.15,381.7,215.66Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 384.615px 215.681px;" id="elba12qgvmjeq"
				  class="animable"></path>
			<path d="M373.78,216.67a5.08,5.08,0,0,0,3.36-.72,12.2,12.2,0,0,0,1.92-1.79S375.09,213,373.78,216.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 376.42px 215.351px;" id="el6umry8lqwki"
				  class="animable"></path>
			<path d="M383.5,212.11a5.11,5.11,0,0,0,3.44,0,12.24,12.24,0,0,0,2.25-1.35S385.55,208.81,383.5,212.11Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 386.345px 211.298px;" id="elyy8rxgjeva"
				  class="animable"></path>
			<path d="M367.94,213a5.11,5.11,0,0,0,3.44,0,12.24,12.24,0,0,0,2.25-1.35S370,209.73,367.94,213Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 370.785px 212.194px;" id="el15l6c9kqxzx"
				  class="animable"></path>
			<path d="M370.09,217.81a5.14,5.14,0,0,0,2.62,2.22,12.31,12.31,0,0,0,2.6.41S373.78,216.61,370.09,217.81Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 372.7px 219.009px;" id="elp76lpi9qzb"
				  class="animable"></path>
			<path d="M383.23,218.57a5.1,5.1,0,0,1,3.42.3,12.43,12.43,0,0,1,2.13,1.54S385,222,383.23,218.57Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 386.005px 219.591px;" id="el9ws241yrb3o"
				  class="animable"></path>
			<path d="M378.11,220.82a5.13,5.13,0,0,0,2.63,2.21,12.28,12.28,0,0,0,2.59.41S381.8,219.61,378.11,220.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 380.72px 222.012px;" id="elwd2xuj64os"
				  class="animable"></path>
			<path d="M372.44,225.34a5.14,5.14,0,0,0,1.82,2.92,12.07,12.07,0,0,0,2.34,1.19S376.32,225.34,372.44,225.34Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 374.52px 227.395px;" id="elsnd2uv3pqyi"
				  class="animable"></path>
			<path d="M367.54,225.59a5.11,5.11,0,0,0-.44,3.41,12.29,12.29,0,0,0,1.06,2.41S370.55,228,367.54,225.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 368.021px 228.5px;" id="elikpttd7mkb"
				  class="animable"></path>
			<path d="M371.87,228.44a5.15,5.15,0,0,0-.44,3.42,12.4,12.4,0,0,0,1.05,2.4S374.88,230.9,371.87,228.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 372.35px 231.35px;" id="el8ysdx0e8mr4"
				  class="animable"></path>
			<path d="M370.16,201.74a5.15,5.15,0,0,0-2,2.82,12.48,12.48,0,0,0-.17,2.62S371.68,205.31,370.16,201.74Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 369.253px 204.46px;" id="elf25fu9mtsgm"
				  class="animable"></path>
			<path d="M376.05,197.84a5.16,5.16,0,0,0-2,2.82,12.15,12.15,0,0,0-.18,2.62S377.58,201.41,376.05,197.84Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 375.14px 200.56px;" id="elacky1b2bjji"
				  class="animable"></path>
			<path d="M413.56,250.14c.46-11.48-8-21.16-18.88-21.62h-.17a10.68,10.68,0,0,0-11,10.47,15.13,15.13,0,0,1-.3,2.87,7.16,7.16,0,0,1-3,4.4,3,3,0,0,0-1.3,2.31v.22a3,3,0,0,0,1.11,2.41,7.23,7.23,0,0,1,2.61,4.63,16.67,16.67,0,0,1,.06,2.9,10.68,10.68,0,0,0,10.08,11.36h.16C403.82,270.55,413.05,261.62,413.56,250.14Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 396.243px 249.311px;" id="el1oe23uhoqhk"
				  class="animable"></path>
			<polygon
					points="388.97 256.3 388.17 251.66 394.77 257.43 388.1 249.65 387.62 249.58 394.98 249.56 383.4 248.54 390.6 241.44 383.54 246.64 384.73 242.08 381.97 247.79 381.21 248.34 358.86 246.37 358.72 249.67 385.8 249.59 386.71 250.38 388.97 256.3"
					style="fill: rgb(38, 50, 56); transform-origin: 376.85px 249.435px;" id="el0tvt4ychqla"
					class="animable"></polygon>
			<path d="M395.54,243.18a5.13,5.13,0,0,0,2.75-2.06,11.51,11.51,0,0,0,1-2.43S395.21,239.31,395.54,243.18Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 397.406px 240.935px;" id="el3bwphrxy4ya"
				  class="animable"></path>
			<path d="M400.71,245.19a5.16,5.16,0,0,0,3.22-1.21,12.42,12.42,0,0,0,1.63-2.06S401.47,241.38,400.71,245.19Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 403.135px 243.542px;" id="el4f5m9484hs9"
				  class="animable"></path>
			<path d="M384,261a5.14,5.14,0,0,0,1.89-2.88,12.17,12.17,0,0,0,.1-2.62S382.39,257.44,384,261Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 384.803px 258.25px;" id="el7z81hajc1gm"
				  class="animable"></path>
			<path d="M385.61,235.14a5.15,5.15,0,0,1,1.64,3,12.55,12.55,0,0,1-.12,2.63S383.68,238.51,385.61,235.14Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 386.148px 237.955px;" id="elxi06ly56yd"
				  class="animable"></path>
			<path d="M402.73,239.44a5.1,5.1,0,0,0,3.22-1.21,12,12,0,0,0,1.63-2S403.49,235.63,402.73,239.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 405.155px 237.818px;" id="elmcsqgb35bz"
				  class="animable"></path>
			<path d="M400.72,258.73a5.17,5.17,0,0,1,3.11,1.48,11.86,11.86,0,0,1,1.45,2.19S401.16,262.59,400.72,258.73Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 403px 260.566px;" id="el44fiuntlmti"
				  class="animable"></path>
			<path d="M403.82,249.22a5.13,5.13,0,0,0,3.27,1.05,12.44,12.44,0,0,0,2.56-.6S406.78,246.7,403.82,249.22Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 406.735px 249.239px;" id="el5lk5cqss8iv"
				  class="animable"></path>
			<path d="M395.9,250.23a5.15,5.15,0,0,0,3.36-.72,11.63,11.63,0,0,0,1.92-1.79S397.22,246.57,395.9,250.23Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 398.54px 248.911px;" id="elltl8n8lhzg9"
				  class="animable"></path>
			<path d="M405.62,245.67a5.18,5.18,0,0,0,3.44,0,11.88,11.88,0,0,0,2.25-1.36S407.67,242.36,405.62,245.67Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 408.465px 244.852px;" id="elfy3i4uya6q5"
				  class="animable"></path>
			<path d="M390.06,246.59a5.11,5.11,0,0,0,3.44,0,12.24,12.24,0,0,0,2.25-1.35S392.11,243.29,390.06,246.59Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 392.905px 245.778px;" id="eld9ua0iwxnh8"
				  class="animable"></path>
			<path d="M392.21,251.36a5.08,5.08,0,0,0,2.63,2.22,11.9,11.9,0,0,0,2.59.42S395.9,250.17,392.21,251.36Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 394.82px 252.565px;" id="elaqy3w13lrms"
				  class="animable"></path>
			<path d="M405.35,252.13a5.1,5.1,0,0,1,3.42.3A11.8,11.8,0,0,1,410.9,254S407.11,255.59,405.35,252.13Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 408.125px 253.164px;" id="eltprq5pg033j"
				  class="animable"></path>
			<path d="M400.23,254.38a5.13,5.13,0,0,0,2.63,2.21,12.31,12.31,0,0,0,2.6.41S403.92,253.17,400.23,254.38Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 402.845px 255.572px;" id="elb82chku89z8"
				  class="animable"></path>
			<path d="M394.56,258.9a5.14,5.14,0,0,0,1.82,2.92,12.4,12.4,0,0,0,2.34,1.19S398.44,258.89,394.56,258.9Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 396.64px 260.955px;" id="elbjnojg5zvyi"
				  class="animable"></path>
			<path d="M389.66,259.15a5.11,5.11,0,0,0-.44,3.41,12,12,0,0,0,1.06,2.4S392.67,261.6,389.66,259.15Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 390.141px 262.055px;" id="ely6k54oz3qcp"
				  class="animable"></path>
			<path d="M394,262a5.11,5.11,0,0,0-.44,3.41,12.29,12.29,0,0,0,1.06,2.41S397,264.45,394,262Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 394.479px 264.91px;" id="elno364mop6e"
				  class="animable"></path>
			<path d="M392.28,235.3a5.16,5.16,0,0,0-2,2.82,12.15,12.15,0,0,0-.18,2.62S393.81,238.87,392.28,235.3Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 391.37px 238.02px;" id="elhen1ei37yq"
				  class="animable"></path>
			<path d="M398.18,231.4a5.11,5.11,0,0,0-2,2.82,12.46,12.46,0,0,0-.17,2.62S399.7,235,398.18,231.4Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 397.273px 234.12px;" id="elc2x9g1pt4mv"
				  class="animable"></path>
		</g>
		<g id="freepik--Earth--inject-24" class="animable" style="transform-origin: 244.87px 272.83px;">
			<g id="elmlpmxr1s79f">
				<circle cx="244.87" cy="272.83" r="122.08"
						style="fill: rgb(146, 227, 169); transform-origin: 244.87px 272.83px; transform: rotate(-45deg);"
						class="animable"></circle>
			</g>
			<polygon
					points="224.79 219.82 237.38 221.57 241.91 225.68 247.48 226.16 249.58 231.29 248.53 234.91 244.88 236.89 235.56 235.99 235.24 234.88 239.39 231.55 236.02 226.2 227.7 225.61 223.06 222.14 224.79 219.82"
					style="fill: rgb(255, 255, 255); transform-origin: 236.32px 228.355px;" id="el6thtefldi7s"
					class="animable"></polygon>
			<path d="M332.44,327.8l-9.95-1-12-.72-8.62,2.52-8.55.69L285,333.83l-4.92,1.74-3.06.89-4.87,5.05-4.91,3.85-8.89-2.53-.33-8.36-5.3-.56-5.56,1.62-1.56-2.25-.91-5.17-2.76-2.21-3.73,1.69-.3,3.11-4.65,1.66-5.63-3.79L224,322.38l1.89-9,8.23-1.8,2.79,1.29,3.34-1,3.44-3.73,3.94.06,7.53,7.16,1.86-.85-2.43-6.23L252,305.46,251,300l9-7.46-1.62-10.69,3.39-4.92,1.75-7.45,2.62-1.37,5.17-6-.37-2.31-4.37,1.58-1.48-2-3.06.89-4.4,2.5,1.16-4.27,8.92-5.63,1.5-3.15,5.68,5,3.23,1.77,3.57-2.25-2.72-3.13-7.06-2.46L263,243.06l-8.34-2.69-7,.84-4.31-2.36-4.78.19-.23,1.27,1.81,8.23,1.92,2.45.1,9.63-6.86-2.82-.31-4.14-6.65-1.07-3.4-2.33-.64-7.36,6.36-8.19-1.4-6.84-3,1.17-2.67-1.93-2.06-3.93-2,.29.19,4.78-2.89,1.45-4.24-9.33-2.63-.74-4.38-.53-3.8,3.53,7.11,3.65,2.49,4.41-12.21-4.58L190,220.63l-2.76-7.35-7.88-.11-4.5,5.25a44.08,44.08,0,0,1-3.52,3.44,48.8,48.8,0,0,1-6.35,3.06l.27,5.06L159,232.13l1.45,2.89,10.78-1.34,7,4.3,2.85,9.73-.54,9.51,3.24,9-3.8,15.9,4.27,20.79,5.92,10.95,6.21,7.84,4.34-.66L198,317l-3.21-2.68-3.17-5.71,2.34-1.29,6.82,8.87,5.38,8.09,2.11,7.23,7.16,4.85,11.49,4.19,15.59-.34,5.67,2.87,4.64,3.47,5.9,1.6,6.61-.12,6-2.07s.54,2.86,1,4.25-2.11,7.26-2.11,7.26l-1.43,6.46,4,5.46,6.85.72,11.23,6.38,4.38.53,1.22,4.17,1.05,1.8a121.64,121.64,0,0,0,50.3-44.52l-.45-2.05,3.52-15.83-4.34-1.44Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 254.945px 298.08px;" id="eldjrikerdck6"
				  class="animable"></path>
			<path d="M329.24,209.5l-4.6-4.39-5.68-5-3.56-8,5.25-.62-.53-2.87-3.7.78,2.65-2.28,2.77-2.93-.81-2.78-4.64-3.47-2.46-5.78a122.17,122.17,0,0,0-147.26,6.9l7.74,2.66L172,187.87l-5,2.66,1.64,7.67-2,1.48-1.88,3.87,1.74,4.93,4.89-.83-.57,5.3,4.73-1.38,5.06-12.65-.47-4.69,7.62-2.84,1-3.9,7.82-3.2-.55-5,5.76-2s3.52-3.45,4.35-3.69a34.52,34.52,0,0,1,5.14,0l5.14,2.11,5.84-1.7,4.11-4.53,6.56-1.31a7.26,7.26,0,0,0,3.39.22c1.39-.41,5-3.58,5-3.58l9.53,2.65,4.07,1.53,1.22,4.17,4.33-.66.66-2.91,4.1.61.37,2.3-7.69,4.67,6.52,4.73,11.64,2.64,5.79,4.34,6.28-1.23.58-3.19,2.06-1.21-8.85-3.45-4.61.75,3.29-2.17-4.72-3.75,4.64-1.66,7.1,1.55,3.34,6.26,4.11,2.72L300,190.9l7.42,2.66,2.69,9.17,7.61,2.31.62,3.14.22,3.85,3.13,4.52,1.29,2.34,5-.55.85-5.37Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 247px 184.826px;" id="el0e93p5erwrnn"
				  class="animable"></path>
			<path d="M324.67,180.43l.46,1.6-.09,4.85,2,3.65,3.38.22L332,196l-3.75-.41,3.56,15.26L330.21,218l4.5,7.14,4.59,2.28,4.39,15,7,10.63,6.5,7.76,6.88-.2.6-6.22.7-1A122.72,122.72,0,0,0,362,238.57,121.49,121.49,0,0,0,324.67,180.43Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 345.02px 220.62px;" id="elbhexqne87j"
				  class="animable"></path>
			<polygon
					points="252.29 321.06 251.5 322.49 260.57 322.56 265.05 325.47 270.5 324.49 271.55 320.86 264.83 321.61 252.29 321.06"
					style="fill: rgb(255, 255, 255); transform-origin: 261.525px 323.165px;" id="elpznwgxznvy"
					class="animable"></polygon>
			<polygon
					points="278.58 319.1 273.23 322.48 274.71 324.46 281.94 322.34 282.68 319.71 280.26 320.72 278.58 319.1"
					style="fill: rgb(255, 255, 255); transform-origin: 277.955px 321.78px;" id="elqdscso2grug"
					class="animable"></polygon>
			<path d="M244.85,388.91a116,116,0,0,1-17.07-1.27.5.5,0,0,1-.42-.57.51.51,0,0,1,.57-.42,113.72,113.72,0,0,0,19.94,1.22.53.53,0,0,1,.51.48.5.5,0,0,1-.49.52C246.88,388.89,245.86,388.91,244.85,388.91Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 237.867px 387.778px;" id="elrbxnzvwprwf"
				  class="animable"></path>
			<path d="M222.93,386.8h-.1c-3.46-.67-6.94-1.51-10.33-2.5a.5.5,0,0,1-.34-.62.49.49,0,0,1,.62-.34c3.36,1,6.81,1.81,10.24,2.48a.5.5,0,0,1,.4.59A.51.51,0,0,1,222.93,386.8Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 217.785px 385.059px;" id="elzov180deda8"
				  class="animable"></path>
			<path d="M208.36,383a.45.45,0,0,1-.16,0A116.13,116.13,0,0,1,131.38,248.5a.49.49,0,0,1,.59-.38.49.49,0,0,1,.39.59A115.13,115.13,0,0,0,208.52,382a.5.5,0,0,1-.16,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 168.874px 315.557px;" id="elxir8bayhpu"
				  class="animable"></path>
			<path d="M135.82,235.11a.51.51,0,0,1-.17,0,.5.5,0,0,1-.3-.64,116.59,116.59,0,0,1,9.4-20.28.5.5,0,1,1,.86.5,116.8,116.8,0,0,0-9.32,20.11A.49.49,0,0,1,135.82,235.11Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 140.513px 224.507px;" id="elnt5zouryun"
				  class="animable"></path>
			<path d="M244.92,395.42a122.62,122.62,0,1,1,34.35-4.94A122.79,122.79,0,0,1,244.92,395.42ZM245,151.2a121.73,121.73,0,1,0,58.22,15A121.88,121.88,0,0,0,245,151.2Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 244.816px 272.8px;" id="eld29etdx5z1b"
				  class="animable"></path>
			<path d="M195.16,228a1.41,1.41,0,0,1-.49-.09,1.49,1.49,0,0,1-.92-1.91,21.06,21.06,0,0,1,22.8-13.63,21.32,21.32,0,0,1,11.18,5.14,1.5,1.5,0,0,1-2,2.25,18.24,18.24,0,0,0-9.61-4.41A18,18,0,0,0,196.58,227,1.52,1.52,0,0,1,195.16,228Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 210.949px 220.077px;" id="elcfxv3pz4ris"
				  class="animable"></path>
			<path d="M291.68,223.7a1.49,1.49,0,0,1-1.28-.72,18.34,18.34,0,0,0-7.87-7,18.06,18.06,0,0,0-22.13,5.37,1.5,1.5,0,1,1-2.4-1.79,21.23,21.23,0,0,1,35,1.91,1.51,1.51,0,0,1-.5,2.07A1.55,1.55,0,0,1,291.68,223.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.431px 217.477px;" id="elcmsenfp376n"
				  class="animable"></path>
			<g id="elkywkzd51nt">
				<ellipse cx="293.96" cy="278.99" rx="15.32" ry="8.36"
						 style="opacity: 0.19; transform-origin: 293.96px 278.99px;" class="animable"></ellipse>
			</g>
			<g id="elr0plndvu07">
				<ellipse cx="194.5" cy="278.99" rx="15.32" ry="8.36"
						 style="opacity: 0.19; transform-origin: 194.5px 278.99px;" class="animable"></ellipse>
			</g>
			<path d="M244.23,297h-.57a30.69,30.69,0,0,1-29.12-24,1,1,0,1,1,2-.45,28.71,28.71,0,0,0,27.21,22.49h.53A28.86,28.86,0,0,0,272.11,272a1,1,0,1,1,1.95.43C270.94,286.73,258.44,297,244.23,297Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 244.306px 284.08px;" id="elxqogh6knm9p"
				  class="animable"></path>
			<path d="M230.55,253.85s-5.56-7.78-13-9.63-11.85,3.33-13.33,4.44-2.23,2.23-5.56-.37-5.93-1.11-4.08,1.48l1.86,2.6h0c-4.82,1.48-10,0-7.41,3.7s8.89,1.85,8.89,1.85-9.64,4.08-3.71,5.19,9.64-2.59,13.71-5.93,7.78-7,13.34-3.7S231.66,258.3,230.55,253.85Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 209.524px 253.568px;" id="el9iavu5msp8p"
				  class="animable"></path>
			<path d="M257.07,253.85s5.55-7.78,13-9.63,11.86,3.33,13.34,4.44,2.23,2.23,5.56-.37,5.93-1.11,4.08,1.48l-1.86,2.6h0c4.82,1.48,10,0,7.41,3.7s-8.89,1.85-8.89,1.85,9.63,4.08,3.71,5.19-9.64-2.59-13.71-5.93-7.78-7-13.34-3.7S256,258.3,257.07,253.85Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 278.105px 253.568px;" id="elmdwn990c44l"
				  class="animable"></path>
			<path d="M281.54,339.61h-.14c-70.89-10-101.2-46.8-101.5-47.17a1,1,0,0,1,.16-1.4,1,1,0,0,1,1.4.15c.3.36,30.16,36.59,100.22,46.44a1,1,0,0,1-.14,2Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 231.149px 315.227px;" id="elsjvpp8sptsj"
				  class="animable"></path>
			<path d="M210.71,339.61a1,1,0,0,1-1-.86,1,1,0,0,1,.85-1.13c70.12-9.86,99.92-46.08,100.21-46.44a1,1,0,0,1,1.41-.15,1,1,0,0,1,.15,1.4c-.3.37-30.6,37.2-101.5,47.17Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 261.125px 315.208px;" id="eljme3q71m7mr"
				  class="animable"></path>
			<path d="M215.73,337.5s-1.11,1.11-10.59-7.25-12.26-8.91-11.7-3.34a10.11,10.11,0,0,0,5.57,7.8s-1.11,1.12-7.8,1.12-12.26,3.34-10,5.57,12.26,1.67,12.26,1.67-10,2.79-6.69,6.69,12.82-1.12,12.82-1.12-8.36,2.79-2.79,6.69S215.73,337.5,215.73,337.5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 198.214px 339.526px;" id="elkkj27u2mv7q"
				  class="animable"></path>
			<path d="M198.3,356.8a3.59,3.59,0,0,1-2.09-.65c-1.73-1.22-2.51-2.54-2.31-3.93,0-.13,0-.26.08-.39-2.89.69-6.12.76-8-1.42a3.19,3.19,0,0,1-.8-3.12,6.43,6.43,0,0,1,3.13-3.19c-3-.14-6.48-.62-7.85-2a2.43,2.43,0,0,1-.59-2.77c1-2.49,6.11-4.51,11.32-4.51a31,31,0,0,0,5.49-.39,10.56,10.56,0,0,1-4.25-7.43c-.17-1.7-.18-3.75,1.31-4.51,1.92-1,5.07.85,12,7s9.26,7,9.65,7a1,1,0,0,1,.9.17,1,1,0,0,1,.23,1.31c-1.09,1.77-10.82,17.33-17.34,18.68A4.42,4.42,0,0,1,198.3,356.8Zm-.61-6.18c-.91.54-1.71,1.22-1.81,1.89s.75,1.49,1.48,2a1.78,1.78,0,0,0,1.48.23c4.42-.92,11.91-11.32,15.27-16.55-1.64-.73-4.5-2.66-9.63-7.19-7.6-6.71-9.4-6.93-9.81-6.72,0,0-.46.3-.23,2.53a9.1,9.1,0,0,0,5,7,1,1,0,0,1,.3,1.62c-.33.33-1.92,1.41-8.51,1.41-5,0-8.92,1.94-9.48,3.27-.07.17-.1.33.16.59,1.32,1.32,7.51,1.6,11.5,1.38a1,1,0,0,1,.32,2c-2.46.68-6.13,2.33-6.59,3.84-.08.24-.12.63.39,1.23,1.78,2.07,6.25.92,9.32-.31a12.75,12.75,0,0,1,2.42-1.1,1,1,0,0,1,1.24.55,1,1,0,0,1-.45,1.28C199.76,349.68,198.89,350.12,197.69,350.62ZM215,336.79h0Zm0,0Zm0,0h0Zm0,0Zm0,0Zm0,0Zm0,0h0Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 198.168px 339.517px;" id="eluzurfi2xcjd"
				  class="animable"></path>
			<path d="M276.53,337.5s1.11,1.11,10.58-7.25,12.26-8.91,11.7-3.34a10.08,10.08,0,0,1-5.57,7.8s1.12,1.12,7.8,1.12,12.26,3.34,10,5.57-12.26,1.67-12.26,1.67,10,2.79,6.69,6.69-12.81-1.12-12.81-1.12,8.35,2.79,2.78,6.69S276.53,337.5,276.53,337.5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 294.04px 339.526px;" id="el40d6adrvg2v"
				  class="animable"></path>
			<path d="M294,356.8a4.52,4.52,0,0,1-.95-.1c-6.51-1.35-16.25-16.91-17.34-18.68a1,1,0,0,1,1.14-1.48c.39-.08,2.55-.78,9.64-7s10.13-8,12.05-7c1.48.76,1.48,2.81,1.31,4.51a10.62,10.62,0,0,1-4.26,7.44,32.14,32.14,0,0,0,5.49.38c5.22,0,10.3,2,11.33,4.51a2.44,2.44,0,0,1-.59,2.77c-1.37,1.37-4.81,1.85-7.85,2a6.4,6.4,0,0,1,3.12,3.19,3.18,3.18,0,0,1-.79,3.12c-1.86,2.18-5.09,2.11-8,1.42a2.74,2.74,0,0,1,.09.39c.2,1.39-.58,2.71-2.31,3.93A3.59,3.59,0,0,1,294,356.8Zm-15.82-18.61c3.37,5.23,10.86,15.63,15.28,16.55a1.78,1.78,0,0,0,1.48-.23c.73-.51,1.58-1.28,1.48-2s-.9-1.35-1.81-1.89c-1.2-.5-2.07-.94-2.35-1.09a1,1,0,0,1-.45-1.28,1,1,0,0,1,1.23-.55,13,13,0,0,1,2.43,1.1c3.07,1.23,7.53,2.38,9.31.31.51-.6.47-1,.4-1.23-.46-1.51-4.13-3.16-6.59-3.84a1,1,0,0,1-.72-1.13,1,1,0,0,1,1-.84c4,.22,10.18-.06,11.5-1.38.26-.26.22-.42.15-.59-.55-1.33-4.52-3.27-9.48-3.27-6.59,0-8.17-1.08-8.5-1.41a1,1,0,0,1-.28-.89,1,1,0,0,1,.58-.73,9,9,0,0,0,5-7c.22-2.23-.23-2.52-.23-2.53-.42-.21-2.21,0-9.81,6.72C282.65,335.53,279.79,337.46,278.14,338.19Zm-.91-1.4Zm0,0Zm0,0Zm0,0h0Zm0,0Zm0,0Zm0,0Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 294.087px 339.54px;" id="elcsgdzb7wp2f"
				  class="animable"></path>
		</g>
		<g id="freepik--Sun--inject-24" class="animable" style="transform-origin: 246.95px 105.185px;">
			<circle cx="246.95" cy="105.37" r="13.47"
					style="fill: rgb(146, 227, 169); transform-origin: 246.95px 105.37px;" id="el2osbqqop83q"
					class="animable"></circle>
			<path d="M247,119.34a14,14,0,1,1,14-14A14,14,0,0,1,247,119.34Zm0-26.95a13,13,0,1,0,13,13A13,13,0,0,0,247,92.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 247px 105.34px;" id="elsp8clxfhp9o"
				  class="animable"></path>
			<path d="M247,89a.5.5,0,0,1-.5-.5V73a.5.5,0,0,1,1,0v15.5A.5.5,0,0,1,247,89Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 247px 80.75px;" id="el8zv3z6igafv"
				  class="animable"></path>
			<path d="M247,137.87a.5.5,0,0,1-.5-.5v-15.5a.5.5,0,0,1,1,0v15.5A.5.5,0,0,1,247,137.87Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 247px 129.62px;" id="el0im3so517dyi"
				  class="animable"></path>
			<path d="M230.28,105.7h-15.5a.5.5,0,0,1,0-1h15.5a.5.5,0,0,1,0,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 222.53px 105.2px;" id="elkocnryz2svr"
				  class="animable"></path>
			<path d="M279.12,105.7h-15.5a.5.5,0,0,1,0-1h15.5a.5.5,0,0,1,0,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 271.37px 105.2px;" id="elwsz6yoysel8"
				  class="animable"></path>
			<path d="M224.2,128.44a.49.49,0,0,1-.35-.14.51.51,0,0,1,0-.71l11-11a.49.49,0,0,1,.7.7l-10.95,11A.5.5,0,0,1,224.2,128.44Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 229.701px 122.443px;" id="eldj1g53120t8"
				  class="animable"></path>
			<path d="M258.74,93.91a.47.47,0,0,1-.35-.15.48.48,0,0,1,0-.7l10.95-11a.5.5,0,0,1,.71,0,.51.51,0,0,1,0,.71l-11,11A.47.47,0,0,1,258.74,93.91Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 264.216px 87.9111px;" id="elkvfmexz4o4"
				  class="animable"></path>
			<path d="M269.7,128.44a.5.5,0,0,1-.36-.14l-10.95-11a.49.49,0,0,1,.7-.7l11,11a.51.51,0,0,1,0,.71A.49.49,0,0,1,269.7,128.44Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 264.24px 122.45px;" id="elak0kmnu29lt"
				  class="animable"></path>
			<path d="M235.16,93.91a.47.47,0,0,1-.35-.15l-11-11a.51.51,0,0,1,0-.71.5.5,0,0,1,.71,0l10.95,11a.48.48,0,0,1,0,.7A.47.47,0,0,1,235.16,93.91Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 229.644px 87.9061px;" id="elo3a984jgq0b"
				  class="animable"></path>
		</g>
		<g id="freepik--Clouds--inject-24" class="animable" style="transform-origin: 236.812px 261.893px;">
			<path d="M226.65,106.46a5.54,5.54,0,0,0-2.27.49,8.8,8.8,0,0,0-7.73-7.12,6.83,6.83,0,0,0-12.73-2.39,11.14,11.14,0,0,0-1.84-.16,11.29,11.29,0,0,0-9.26,4.83,8.78,8.78,0,0,0-14.62,5,5.56,5.56,0,0,0-2.63-.68,5.63,5.63,0,1,0,5,8.22,8.74,8.74,0,0,0,5.45,2.66,6.84,6.84,0,0,0,12.77,2,11.07,11.07,0,0,0,3.32.5,11.28,11.28,0,0,0,8.67-4,8.81,8.81,0,0,0,11.06-.9,5.62,5.62,0,1,0,4.84-8.48Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 201.119px 108.437px;" id="elhlt9zhnns7i"
				  class="animable"></path>
			<path d="M192.73,123.51a7.32,7.32,0,0,1-7.16-5.72,9.26,9.26,0,0,1-4.92-2.31,6.07,6.07,0,0,1-5.08,2.74,6.13,6.13,0,1,1,0-12.26,5.83,5.83,0,0,1,2.27.46,9.27,9.27,0,0,1,14.9-5,11.82,11.82,0,0,1,9.34-4.6,11.06,11.06,0,0,1,1.58.11,7.33,7.33,0,0,1,13.42,2.49,9.36,9.36,0,0,1,7.66,6.89,6.21,6.21,0,0,1,1.91-.31,6.13,6.13,0,0,1,0,12.26,6.07,6.07,0,0,1-4.94-2.52,9.32,9.32,0,0,1-10.87.8A11.8,11.8,0,0,1,199,120,7.33,7.33,0,0,1,192.73,123.51Zm-12.31-9.67.48.5a8.29,8.29,0,0,0,5.14,2.5l.38,0,.06.37a6.34,6.34,0,0,0,11.84,1.91l.19-.37.4.12a10.79,10.79,0,0,0,11.46-3.39l.29-.35.38.26a8.3,8.3,0,0,0,10.43-.86l.45-.43.32.54a5.12,5.12,0,1,0,2.34-7.28l-.58.26-.12-.62a8.31,8.31,0,0,0-7.28-6.71l-.39,0-.05-.39a6.34,6.34,0,0,0-11.81-2.22l-.17.31-.34-.06a11.4,11.4,0,0,0-1.76-.15,10.83,10.83,0,0,0-8.85,4.61l-.32.47-.42-.38a8.28,8.28,0,0,0-13.79,4.74l-.12.69-.61-.33a5.12,5.12,0,1,0,2.13,6.87Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 201.106px 108.492px;" id="elu1t13yqjx5"
				  class="animable"></path>
			<path d="M179.21,300.56a7.43,7.43,0,0,0-3,.65A11.64,11.64,0,0,0,166,291.79a9,9,0,0,0-16.85-3.16,14.81,14.81,0,0,0-14.67,6.18,11.61,11.61,0,0,0-19.34,6.66,7.3,7.3,0,0,0-3.48-.91,7.45,7.45,0,1,0,6.58,10.88,11.54,11.54,0,0,0,7.2,3.51,9.05,9.05,0,0,0,16.9,2.72,14.95,14.95,0,0,0,15.86-4.7,11.63,11.63,0,0,0,14.63-1.2,7.43,7.43,0,1,0,6.39-11.21Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 145.417px 303.181px;" id="elvoaeuml5nec"
				  class="animable"></path>
			<path d="M134.34,323a9.53,9.53,0,0,1-9.33-7.54,12.15,12.15,0,0,1-6.67-3.16,7.94,7.94,0,1,1-6.69-12.19,7.78,7.78,0,0,1,3.12.67,12.11,12.11,0,0,1,19.62-6.66,15.35,15.35,0,0,1,14.5-6,9.54,9.54,0,0,1,17.53,3.26,12.22,12.22,0,0,1,10.15,9.19,7.69,7.69,0,0,1,2.64-.47,7.95,7.95,0,0,1,0,15.9,7.83,7.83,0,0,1-6.5-3.42,12.12,12.12,0,0,1-14.43,1.09,15.44,15.44,0,0,1-11.56,5.2,15.28,15.28,0,0,1-4.15-.57A9.54,9.54,0,0,1,134.34,323Zm-16.23-12.36.48.5a11.09,11.09,0,0,0,6.89,3.37l.38,0,.07.38a8.54,8.54,0,0,0,15.95,2.56l.2-.36.39.12a14.59,14.59,0,0,0,4.25.64,14.44,14.44,0,0,0,11.08-5.18l.29-.35.38.26a11.13,11.13,0,0,0,14-1.15l.46-.43.32.54a6.86,6.86,0,0,0,6,3.44,6.95,6.95,0,0,0,0-13.9,6.86,6.86,0,0,0-2.8.61l-.58.25-.12-.62a11.19,11.19,0,0,0-9.77-9l-.39,0-.06-.39a8.54,8.54,0,0,0-15.91-3l-.17.3-.35-.06a14.22,14.22,0,0,0-2.34-.2,14.47,14.47,0,0,0-11.84,6.17l-.33.47-.42-.39a11.12,11.12,0,0,0-18.5,6.38l-.12.69-.61-.33a6.86,6.86,0,0,0-3.25-.85,7,7,0,1,0,6.13,10.15Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 145.419px 303.274px;" id="el4oj2ymzplew"
				  class="animable"></path>
			<path d="M364.35,328.38a4.81,4.81,0,0,0-1.94.41,7.53,7.53,0,0,0-6.62-6.09,5.85,5.85,0,0,0-10.9-2.05,9.16,9.16,0,0,0-1.57-.14,9.65,9.65,0,0,0-7.93,4.14A7.52,7.52,0,0,0,322.87,329a4.82,4.82,0,1,0,2,6.46,7.49,7.49,0,0,0,4.66,2.27,5.86,5.86,0,0,0,10.94,1.76,9.67,9.67,0,0,0,10.27-3,7.52,7.52,0,0,0,9.47-.78,4.82,4.82,0,1,0,4.14-7.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 342.481px 330.12px;" id="el8b8ee6zxane"
				  class="animable"></path>
			<path d="M335.3,343.05a6.33,6.33,0,0,1-6.18-4.91,8,8,0,0,1-4.15-1.93,5.31,5.31,0,1,1-4.36-8.33,5.16,5.16,0,0,1,1.89.36,8,8,0,0,1,12.8-4.32,10.11,10.11,0,0,1,9.33-3.82,6.35,6.35,0,0,1,11.59,2.15,8.08,8.08,0,0,1,6.55,5.87,5.26,5.26,0,0,1,1.58-.24,5.32,5.32,0,1,1,0,10.64,5.24,5.24,0,0,1-4.23-2.13,8,8,0,0,1-9.29.67,10.16,10.16,0,0,1-7.51,3.32,10.38,10.38,0,0,1-2.61-.34A6.35,6.35,0,0,1,335.3,343.05Zm-10.55-8.48.48.5a7,7,0,0,0,4.35,2.13l.38,0,.07.38a5.35,5.35,0,0,0,10,1.6l.19-.36.4.12a9.16,9.16,0,0,0,9.74-2.88l.29-.35.37.26a7.08,7.08,0,0,0,4,1.23,7,7,0,0,0,4.87-2l.45-.43.32.54a4.28,4.28,0,0,0,3.71,2.14,4.32,4.32,0,1,0,0-8.64,4.25,4.25,0,0,0-1.74.37l-.58.26-.11-.62a7.08,7.08,0,0,0-6.18-5.7l-.39,0-.05-.38a5.36,5.36,0,0,0-10-1.88l-.17.31-.34-.06a9.09,9.09,0,0,0-9,3.79l-.33.47-.42-.39a7.07,7.07,0,0,0-4.76-1.85,7,7,0,0,0-6.93,5.88l-.11.69-.62-.33a4.22,4.22,0,0,0-2-.52,4.32,4.32,0,1,0,3.81,6.31Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 342.481px 330.099px;" id="elb2i8dhefnrs"
				  class="animable"></path>
			<path d="M364.63,215a4.83,4.83,0,0,0-1.95.41,7.53,7.53,0,0,0-6.62-6.09,5.85,5.85,0,0,0-10.9-2.05,10.18,10.18,0,0,0-1.57-.14,9.67,9.67,0,0,0-7.93,4.14,7.52,7.52,0,0,0-12.52,4.31,4.82,4.82,0,1,0,2,6.46,7.52,7.52,0,0,0,4.67,2.27,5.85,5.85,0,0,0,10.93,1.76A9.67,9.67,0,0,0,351,223a7.52,7.52,0,0,0,9.47-.78,4.82,4.82,0,1,0,4.15-7.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 342.746px 216.724px;" id="elbncjsys833o"
				  class="animable"></path>
			<path d="M335.57,229.68a6.34,6.34,0,0,1-6.18-4.9,8,8,0,0,1-4.14-1.94,5.31,5.31,0,1,1-4.37-8.33,5.16,5.16,0,0,1,1.89.36,8,8,0,0,1,12.81-4.32,10.17,10.17,0,0,1,8-3.91,10.31,10.31,0,0,1,1.31.09,6.36,6.36,0,0,1,11.59,2.15,8.1,8.1,0,0,1,6.55,5.87,5.32,5.32,0,1,1,1.59,10.4,5.23,5.23,0,0,1-4.24-2.13,8,8,0,0,1-9.29.67,10.13,10.13,0,0,1-7.51,3.32,10.44,10.44,0,0,1-2.61-.34A6.35,6.35,0,0,1,335.57,229.68ZM325,221.2l.48.5a7,7,0,0,0,4.36,2.13l.37,0,.07.38a5.36,5.36,0,0,0,10,1.61l.2-.37.39.12a9.16,9.16,0,0,0,9.74-2.88l.29-.35.38.26a7,7,0,0,0,8.84-.73l.45-.43.33.54a4.27,4.27,0,0,0,3.71,2.14,4.32,4.32,0,0,0,0-8.64,4.26,4.26,0,0,0-1.75.37l-.58.26-.11-.62a7,7,0,0,0-6.18-5.69l-.39,0-.05-.39a5.36,5.36,0,0,0-10-1.88l-.17.31-.34-.06a9.11,9.11,0,0,0-1.49-.13,9.2,9.2,0,0,0-7.52,3.92l-.33.47-.42-.38a7,7,0,0,0-11.69,4l-.11.69-.62-.33a4.22,4.22,0,0,0-2-.52,4.32,4.32,0,1,0,3.82,6.31Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 342.751px 216.737px;" id="elnogytlig1vd"
				  class="animable"></path>
			<path d="M266.26,392.29a4.91,4.91,0,0,0-1.95.42,7.52,7.52,0,0,0-6.61-6.09,5.85,5.85,0,0,0-10.91-2.05,9,9,0,0,0-1.57-.14,9.67,9.67,0,0,0-7.93,4.14,7.46,7.46,0,0,0-5.09-2,7.54,7.54,0,0,0-7.43,6.31,4.74,4.74,0,0,0-2.25-.59,4.83,4.83,0,1,0,4.25,7,7.52,7.52,0,0,0,4.67,2.27,5.86,5.86,0,0,0,10.94,1.76,9.53,9.53,0,0,0,2.84.43,9.67,9.67,0,0,0,7.43-3.47,7.52,7.52,0,0,0,9.47-.78,4.81,4.81,0,1,0,4.14-7.26Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 244.347px 393.988px;" id="elvr076nvycoc"
				  class="animable"></path>
			<path d="M237.21,407a6.35,6.35,0,0,1-6.19-4.91,8,8,0,0,1-4.14-1.93,5.32,5.32,0,1,1-4.36-8.34,5.21,5.21,0,0,1,1.89.37,8,8,0,0,1,12.8-4.32,10.19,10.19,0,0,1,8-3.91,10.34,10.34,0,0,1,1.32.09,6.35,6.35,0,0,1,11.59,2.15,8.06,8.06,0,0,1,6.54,5.87,5.39,5.39,0,0,1,1.59-.25,5.33,5.33,0,1,1-4.24,8.52,8,8,0,0,1-9.28.67,10.18,10.18,0,0,1-7.52,3.32,10.36,10.36,0,0,1-2.6-.34A6.38,6.38,0,0,1,237.21,407Zm-10.56-8.48.48.5a7,7,0,0,0,4.36,2.12l.38,0,.06.38a5.36,5.36,0,0,0,10,1.6l.19-.36.39.12a9.14,9.14,0,0,0,9.74-2.89l.3-.34.37.25a7,7,0,0,0,8.84-.72l.46-.44.32.55a4.28,4.28,0,0,0,3.71,2.14,4.33,4.33,0,0,0,0-8.65,4.23,4.23,0,0,0-1.74.38l-.58.25-.12-.62a7.07,7.07,0,0,0-6.17-5.69l-.39,0-.06-.38a5.35,5.35,0,0,0-10-1.88l-.17.31-.35-.06a9,9,0,0,0-1.49-.13,9.19,9.19,0,0,0-7.52,3.92l-.33.47-.42-.39a7,7,0,0,0-11.68,4l-.12.69-.61-.33a4.32,4.32,0,1,0,1.79,5.79Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 244.395px 394.049px;" id="elnuakkf9s8xf"
				  class="animable"></path>
			<path d="M186.34,385a3.51,3.51,0,0,0-1.42.3,5.55,5.55,0,0,0-4.85-4.47,4.29,4.29,0,0,0-8-1.5,7.34,7.34,0,0,0-1.15-.1,7.1,7.1,0,0,0-5.82,3,5.52,5.52,0,0,0-9.18,3.16,3.53,3.53,0,1,0,1.47,4.74,5.54,5.54,0,0,0,3.42,1.67,4.3,4.3,0,0,0,8,1.29,7.19,7.19,0,0,0,2.09.31,7.07,7.07,0,0,0,5.44-2.55,5.46,5.46,0,0,0,3.13,1,5.53,5.53,0,0,0,3.82-1.54,3.53,3.53,0,1,0,3-5.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 170.295px 386.223px;" id="elax1s4wk24zr"
				  class="animable"></path>
			<path d="M165,395.93a4.78,4.78,0,0,1-4.64-3.61,6.08,6.08,0,0,1-2.91-1.34,4,4,0,0,1-3.22,1.63,4,4,0,1,1,0-8.07,3.9,3.9,0,0,1,1.3.22,6,6,0,0,1,9.45-3.18,7.62,7.62,0,0,1,5.9-2.81,7,7,0,0,1,.89.06,4.79,4.79,0,0,1,8.68,1.6,6.06,6.06,0,0,1,4.78,4.25,4.3,4.3,0,0,1,1.07-.14,4,4,0,1,1,0,8.07,4,4,0,0,1-3.13-1.51,6.12,6.12,0,0,1-6.76.47,7.57,7.57,0,0,1-7.38,2.17A4.79,4.79,0,0,1,165,395.93Zm-7.77-6.58.48.5a5.06,5.06,0,0,0,3.11,1.52l.38,0,.07.37a3.79,3.79,0,0,0,7.08,1.14l.2-.36.39.12a6.7,6.7,0,0,0,1.94.29A6.58,6.58,0,0,0,176,390.6l.29-.35.38.26A5.1,5.1,0,0,0,183,390l.46-.44.32.54a3,3,0,0,0,2.6,1.51,3,3,0,1,0,0-6.07,2.88,2.88,0,0,0-1.22.26l-.58.26-.12-.63a5,5,0,0,0-4.41-4.06l-.39,0-.05-.39a3.78,3.78,0,0,0-7.06-1.32l-.18.3-.34-.06a7,7,0,0,0-1.07-.09,6.61,6.61,0,0,0-5.41,2.82l-.32.47-.43-.39a5,5,0,0,0-8.34,2.87l-.12.69-.61-.32a3,3,0,0,0-1.42-.37A3,3,0,1,0,157,390Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 170.265px 386.301px;" id="el932j7sw22gc"
				  class="animable"></path>
			<path d="M338.21,386.54a3.55,3.55,0,0,0-1.43.3,5.52,5.52,0,0,0-4.85-4.46,4.3,4.3,0,0,0-8-1.51,7.23,7.23,0,0,0-1.15-.1,7.07,7.07,0,0,0-5.81,3,5.51,5.51,0,0,0-9.18,3.17,3.47,3.47,0,0,0-1.66-.43,3.54,3.54,0,1,0,3.12,5.16,5.49,5.49,0,0,0,3.43,1.67,4.29,4.29,0,0,0,8,1.29,7.19,7.19,0,0,0,2.09.31,7.08,7.08,0,0,0,5.44-2.54,5.52,5.52,0,0,0,6.95-.57,3.53,3.53,0,1,0,3-5.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 322.147px 387.785px;" id="elpw51odq0tsh"
				  class="animable"></path>
			<path d="M316.9,397.43a4.79,4.79,0,0,1-4.64-3.61,6,6,0,0,1-2.91-1.34,4,4,0,0,1-3.22,1.63,4,4,0,0,1,0-8.07,3.86,3.86,0,0,1,1.3.22,6,6,0,0,1,9.45-3.18,7.62,7.62,0,0,1,5.9-2.81,7.15,7.15,0,0,1,.9.06,4.79,4.79,0,0,1,8.67,1.6,6.05,6.05,0,0,1,4.78,4.26,3.91,3.91,0,0,1,1.08-.15,4,4,0,0,1,0,8.07,4,4,0,0,1-3.13-1.51,6.14,6.14,0,0,1-6.77.48,7.58,7.58,0,0,1-5.53,2.39,7.21,7.21,0,0,1-1.85-.23A4.79,4.79,0,0,1,316.9,397.43Zm-7.77-6.57.48.5a5,5,0,0,0,3.11,1.51l.38,0,.07.38a3.79,3.79,0,0,0,7.08,1.13l.2-.36.39.12a6.7,6.7,0,0,0,1.94.29,6.59,6.59,0,0,0,5.06-2.36l.29-.35.38.26a5.11,5.11,0,0,0,6.31-.52l.46-.44.32.55a3,3,0,1,0,2.61-4.57,3.09,3.09,0,0,0-1.23.26l-.58.26-.11-.62a5.06,5.06,0,0,0-4.41-4.07l-.39,0-.06-.39a3.79,3.79,0,0,0-7.06-1.32l-.17.3-.35-.05a6,6,0,0,0-1.07-.1,6.6,6.6,0,0,0-5.4,2.82l-.33.47-.42-.39a5.06,5.06,0,0,0-3.4-1.33,5,5,0,0,0-5,4.21l-.11.69-.62-.33a3,3,0,0,0-1.42-.37,3,3,0,1,0,2.68,4.43Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 322.17px 387.806px;" id="el62y6er3514c"
				  class="animable"></path>
			<path d="M298,419.42a3.43,3.43,0,0,0-1.43.31,5.52,5.52,0,0,0-4.85-4.47,4.29,4.29,0,0,0-8-1.5,6.15,6.15,0,0,0-1.15-.11,7.1,7.1,0,0,0-5.81,3,5.52,5.52,0,0,0-9.18,3.16,3.59,3.59,0,0,0-1.66-.43,3.54,3.54,0,1,0,3.13,5.17,5.51,5.51,0,0,0,3.42,1.66,4.3,4.3,0,0,0,8,1.29,7.12,7.12,0,0,0,7.53-2.23,5.51,5.51,0,0,0,6.94-.57,3.53,3.53,0,1,0,3-5.32Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 281.94px 420.644px;" id="elekb4se9ci67"
				  class="animable"></path>
			<path d="M276.69,430.31A4.78,4.78,0,0,1,272,426.7a6,6,0,0,1-2.9-1.34,4,4,0,1,1-3.23-6.44,3.87,3.87,0,0,1,1.3.23,6,6,0,0,1,9.46-3.18,7.59,7.59,0,0,1,5.89-2.82,7.15,7.15,0,0,1,.9.06,4.8,4.8,0,0,1,8.68,1.6,6.1,6.1,0,0,1,4.78,4.26,3.84,3.84,0,0,1,1.07-.15h0a4,4,0,0,1,0,8.07,4,4,0,0,1-3.13-1.5,6.13,6.13,0,0,1-6.76.47,7.58,7.58,0,0,1-5.54,2.39,7.67,7.67,0,0,1-1.85-.22A4.78,4.78,0,0,1,276.69,430.31Zm-7.78-6.57.49.5a5,5,0,0,0,3.11,1.52l.37,0,.07.38a3.79,3.79,0,0,0,7.08,1.14l.2-.37.39.12a6.38,6.38,0,0,0,1.94.29,6.59,6.59,0,0,0,5.06-2.36l.29-.35.38.26a5.13,5.13,0,0,0,6.32-.52l.45-.43.32.54a3,3,0,1,0,2.61-4.57,3.12,3.12,0,0,0-1.23.26l-.57.26-.12-.62a5,5,0,0,0-4.41-4.06l-.39,0-.06-.39a3.79,3.79,0,0,0-7.06-1.33l-.17.31-.35-.06a7.1,7.1,0,0,0-1.07-.1,6.6,6.6,0,0,0-5.4,2.83l-.33.46-.42-.38a5,5,0,0,0-8.35,2.87l-.11.69-.62-.33a3,3,0,1,0-1.42,5.7,3,3,0,0,0,2.68-1.64Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 281.958px 420.689px;" id="eljjjqy9mu1p"
				  class="animable"></path>
			<path d="M270.81,134.41a4.34,4.34,0,0,0-1.8.39,7,7,0,0,0-6.11-5.64,5.41,5.41,0,0,0-10.08-1.89,8.59,8.59,0,0,0-1.45-.13A9,9,0,0,0,244,131a7,7,0,0,0-11.57,4,4.41,4.41,0,0,0-2.08-.54,4.46,4.46,0,1,0,3.93,6.51,7,7,0,0,0,4.32,2.1,5.41,5.41,0,0,0,10.1,1.63,9.14,9.14,0,0,0,2.63.39,8.92,8.92,0,0,0,6.86-3.21,6.94,6.94,0,0,0,8.76-.71,4.45,4.45,0,1,0,3.82-6.71Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 250.546px 136.028px;" id="elvldfjhjgg4m"
				  class="animable"></path>
			<path d="M244,148a5.9,5.9,0,0,1-5.75-4.54,7.42,7.42,0,0,1-3.79-1.76,5,5,0,1,1-4-7.8,4.69,4.69,0,0,1,1.72.33,7.45,7.45,0,0,1,11.85-4,9.47,9.47,0,0,1,7.41-3.6,8.61,8.61,0,0,1,1.2.09,5.9,5.9,0,0,1,10.76,2,7.5,7.5,0,0,1,6,5.41,4.7,4.7,0,0,1,1.44-.22,5,5,0,0,1,0,9.92,4.89,4.89,0,0,1-3.92-2,7.45,7.45,0,0,1-8.57.62,9.43,9.43,0,0,1-6.95,3,9.17,9.17,0,0,1-2.39-.31A5.91,5.91,0,0,1,244,148Zm-9.77-7.94.48.5a6.45,6.45,0,0,0,4,2l.37,0,.07.38a4.91,4.91,0,0,0,9.17,1.47l.2-.36.39.12a8.66,8.66,0,0,0,2.48.37,8.41,8.41,0,0,0,6.48-3l.29-.35.38.26a6.43,6.43,0,0,0,8.12-.66l.46-.44.32.54a3.91,3.91,0,0,0,3.39,2,4,4,0,0,0,0-7.92,4,4,0,0,0-1.59.34l-.58.26-.12-.62a6.48,6.48,0,0,0-5.67-5.23l-.39,0,0-.39a4.91,4.91,0,0,0-9.15-1.71l-.17.3-.35-.06a8.66,8.66,0,0,0-1.37-.12,8.47,8.47,0,0,0-6.92,3.61l-.32.47-.43-.39A6.45,6.45,0,0,0,233,135l-.12.69-.61-.33a3.9,3.9,0,0,0-1.85-.48,4,4,0,1,0,3.49,5.78Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 250.264px 135.994px;" id="elhvwwfze1yyu"
				  class="animable"></path>
			<path d="M303.87,114.15a3.16,3.16,0,0,0-1.3.28,5,5,0,0,0-4.39-4.05,3.89,3.89,0,0,0-7.24-1.36,6,6,0,0,0-1.05-.09,6.4,6.4,0,0,0-5.26,2.74,5,5,0,0,0-8.32,2.87,3.16,3.16,0,0,0-1.5-.39,3.21,3.21,0,1,0,2.83,4.68,5,5,0,0,0,3.1,1.51,3.89,3.89,0,0,0,7.26,1.17,6.6,6.6,0,0,0,1.89.28,6.46,6.46,0,0,0,4.94-2.3,5,5,0,0,0,6.29-.52,3.17,3.17,0,0,0,2.75,1.59,3.21,3.21,0,0,0,0-6.41Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 289.239px 115.3px;" id="el5541j2wmnye"
				  class="animable"></path>
			<path d="M284.57,124.07a4.38,4.38,0,0,1-4.25-3.28,5.46,5.46,0,0,1-2.58-1.19,3.71,3.71,0,1,1-1.79-5.76,5.49,5.49,0,0,1,8.59-2.88,6.94,6.94,0,0,1,5.35-2.53,5.32,5.32,0,0,1,.79,0,4.39,4.39,0,0,1,7.93,1.45,5.56,5.56,0,0,1,4.32,3.84,3.7,3.7,0,1,1,.94,7.29,3.64,3.64,0,0,1-2.84-1.35,5.59,5.59,0,0,1-6.11.42,7,7,0,0,1-6.68,2A4.39,4.39,0,0,1,284.57,124.07ZM277.52,118l.48.5a4.46,4.46,0,0,0,2.79,1.36l.38,0,.06.38a3.39,3.39,0,0,0,6.33,1l.2-.36.39.12a6,6,0,0,0,6.29-1.86l.29-.35.38.25a4.57,4.57,0,0,0,5.66-.46l.46-.44.32.55a2.67,2.67,0,0,0,2.32,1.34,2.71,2.71,0,0,0,0-5.41,2.62,2.62,0,0,0-1.09.24l-.58.25-.12-.62a4.51,4.51,0,0,0-3.95-3.64l-.39,0-.05-.39a3.39,3.39,0,0,0-6.32-1.19l-.17.31-.34-.06a5.75,5.75,0,0,0-1-.08A5.91,5.91,0,0,0,285,112l-.33.47-.42-.39a4.5,4.5,0,0,0-7.49,2.58l-.11.69-.62-.33a2.64,2.64,0,0,0-1.26-.33,2.71,2.71,0,1,0,2.39,3.95Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 289.298px 115.273px;" id="elg6jpyi6e27w"
				  class="animable"></path>
			<path d="M364.7,148.06a3.29,3.29,0,0,0-1.3.27,5,5,0,0,0-4.39-4,3.9,3.9,0,0,0-7.25-1.37,7.06,7.06,0,0,0-1-.09,6.44,6.44,0,0,0-5.27,2.75,5,5,0,0,0-8.31,2.87,3.19,3.19,0,1,0,1.33,4.29,5,5,0,0,0,3.1,1.51,3.89,3.89,0,0,0,7.26,1.16,6.23,6.23,0,0,0,1.89.29,6.41,6.41,0,0,0,4.93-2.31,5,5,0,0,0,6.29-.51,3.2,3.2,0,1,0,2.76-4.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 350.215px 149.246px;" id="eltpez3kt9we"
				  class="animable"></path>
			<path d="M345.4,158a4.38,4.38,0,0,1-4.25-3.28,5.5,5.5,0,0,1-2.58-1.18,3.7,3.7,0,1,1-2.93-5.95,3.45,3.45,0,0,1,1.14.19,5.5,5.5,0,0,1,8.59-2.89,7,7,0,0,1,5.35-2.53,7.45,7.45,0,0,1,.79,0,4.39,4.39,0,0,1,7.92,1.46,5.56,5.56,0,0,1,4.33,3.84,3.7,3.7,0,1,1-1.91,5.94,5.61,5.61,0,0,1-6.11.42,7,7,0,0,1-6.68,2A4.39,4.39,0,0,1,345.4,158Zm-7.06-6.08.49.5a4.52,4.52,0,0,0,2.79,1.36l.37,0,.07.37a3.39,3.39,0,0,0,6.33,1l.19-.37.4.13a6,6,0,0,0,6.29-1.87l.29-.35.38.26a4.58,4.58,0,0,0,5.66-.46l.45-.44.33.54A2.67,2.67,0,0,0,364.7,154a2.7,2.7,0,0,0,0-5.4,2.82,2.82,0,0,0-1.1.23l-.57.26-.12-.62a4.53,4.53,0,0,0-4-3.65l-.39,0-.06-.39a3.39,3.39,0,0,0-6.31-1.18l-.17.3-.35-.05a5.72,5.72,0,0,0-1-.09,5.94,5.94,0,0,0-4.86,2.54l-.32.46-.42-.38a4.5,4.5,0,0,0-7.49,2.58l-.11.68-.62-.32a2.64,2.64,0,0,0-1.26-.33A2.7,2.7,0,1,0,338,152.5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 350.166px 149.21px;" id="el4zecxnq1qbm"
				  class="animable"></path>
			<path d="M166.22,156a3.16,3.16,0,0,0-1.3.28,5,5,0,0,0-4.39-4.05,3.89,3.89,0,0,0-7.24-1.36,6,6,0,0,0-1-.1,6.4,6.4,0,0,0-5.26,2.75,5,5,0,0,0-8.32,2.87,3.2,3.2,0,1,0,1.33,4.29,5,5,0,0,0,3.1,1.51,3.89,3.89,0,0,0,7.26,1.16,6.27,6.27,0,0,0,1.89.29,6.41,6.41,0,0,0,4.93-2.31,5,5,0,0,0,6.3-.51,3.2,3.2,0,1,0,2.75-4.82Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 151.739px 157.147px;" id="el94gk3zoa8r"
				  class="animable"></path>
			<path d="M146.92,165.94a4.38,4.38,0,0,1-4.25-3.27,5.51,5.51,0,0,1-2.58-1.19,3.7,3.7,0,1,1-2.93-5.95,3.41,3.41,0,0,1,1.14.19,5.5,5.5,0,0,1,8.59-2.89,7,7,0,0,1,5.35-2.53,7.13,7.13,0,0,1,.79,0,4.4,4.4,0,0,1,7.93,1.46,5.54,5.54,0,0,1,4.32,3.84,3.7,3.7,0,1,1,.94,7.29,3.67,3.67,0,0,1-2.85-1.35,5.57,5.57,0,0,1-6.1.42,7,7,0,0,1-6.68,2A4.38,4.38,0,0,1,146.92,165.94Zm-7-6.08.48.5a4.52,4.52,0,0,0,2.79,1.36l.38,0,.06.37a3.39,3.39,0,0,0,6.33,1l.2-.36.39.12a6,6,0,0,0,1.74.26,5.93,5.93,0,0,0,4.55-2.13l.29-.35.38.26a4.58,4.58,0,0,0,5.66-.46l.46-.44.32.55a2.7,2.7,0,1,0,2.32-4.07,2.73,2.73,0,0,0-1.09.23l-.58.26-.12-.62a4.53,4.53,0,0,0-4-3.65l-.39,0-.05-.38a3.39,3.39,0,0,0-6.32-1.19l-.17.3-.35-.05a5.63,5.63,0,0,0-1-.09,6,6,0,0,0-4.86,2.54l-.32.47-.42-.39a4.5,4.5,0,0,0-7.49,2.58l-.11.69-.62-.33a2.64,2.64,0,0,0-1.26-.33,2.71,2.71,0,1,0,2.39,3.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 151.664px 157.152px;" id="el6or0dozan4c"
				  class="animable"></path>
		</g>
		<g id="freepik--Butterflies--inject-24" class="animable" style="transform-origin: 246.588px 173.075px;">
			<path d="M375.36,177.75s-5.12-7.69-8-7-1.36,3-.3,5,2,5.79,4.13,6.78,4.81-3.65,4.81-3.65Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 370.85px 176.687px;" id="el5nohs4cfck"
				  class="animable"></path>
			<path d="M371.85,183.21A2,2,0,0,1,371,183c-1.74-.81-2.69-3.11-3.53-5.14-.29-.69-.56-1.35-.83-1.87l-.26-.47c-.74-1.38-1.51-2.81-1-4a2.57,2.57,0,0,1,1.88-1.3c3.14-.77,8,6.37,8.51,7.19l0,0,.81,1.42-.14.24C376.19,179.62,374.07,183.21,371.85,183.21Zm-4.08-12a1.33,1.33,0,0,0-.28,0,1.65,1.65,0,0,0-1.19.74c-.31.7.37,2,1,3.07l.26.49c.3.56.58,1.24.87,1.95.77,1.86,1.64,4,3,4.61s3.26-2,4-3.2l-.51-.9C373.12,175.29,369.69,171.22,367.77,171.22Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 370.898px 176.686px;" id="eleo10x6jh1b"
				  class="animable"></path>
			<path d="M376,178.85s-3.42,4-3,5.3,2.28,3.78,3.29,4,2-2.85,1.7-4.38A15.75,15.75,0,0,0,376,178.85Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 375.504px 183.506px;" id="elho3hndgbmgq"
				  class="animable"></path>
			<path d="M376.38,188.69l-.24,0c-1.3-.33-3.24-3.13-3.64-4.37-.46-1.41,2.26-4.78,3.1-5.77L376,178l.37.55a16.07,16.07,0,0,1,2.05,5.14,6.37,6.37,0,0,1-1.27,4.63A1.13,1.13,0,0,1,376.38,188.69Zm-.46-9c-1.4,1.75-2.65,3.75-2.47,4.28.4,1.24,2.21,3.53,2.93,3.7,0,0,.12-.05.27-.25a5.9,5.9,0,0,0,.82-3.56A16,16,0,0,0,375.92,179.71Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 375.456px 183.345px;" id="el3pem0jzau5o"
				  class="animable"></path>
			<path d="M378.13,176.35s2-9,5-9.37,2.34,2.31,2.06,4.58.21,6.11-1.44,7.81-5.79-1.69-5.79-1.69Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 381.706px 173.396px;" id="elxcebkb15ugg"
				  class="animable"></path>
			<path d="M382.49,180.35c-2,0-4.52-2-4.88-2.28l-.22-.19.26-1.64c.21-1,2.2-9.35,5.39-9.76a2.57,2.57,0,0,1,2.21.55c.89.9.68,2.5.48,4.06l-.07.53c-.07.58-.09,1.29-.11,2-.06,2.19-.13,4.68-1.47,6.06A2.13,2.13,0,0,1,382.49,180.35Zm-4-2.88c1.16.88,3.89,2.59,4.89,1.55s1.14-3.38,1.19-5.39c0-.77,0-1.5.12-2.13l.06-.54c.17-1.26.35-2.68-.19-3.22a1.6,1.6,0,0,0-1.38-.26c-1.88.23-3.78,5.61-4.53,9Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 381.648px 173.386px;" id="el0647j7tvstbt"
				  class="animable"></path>
			<path d="M377.94,177.6s4.64,2.56,4.71,3.88-.78,4.34-1.63,4.94-2.86-2-3.16-3.49A15.8,15.8,0,0,1,377.94,177.6Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 380.163px 182.054px;" id="elujtwhu0q43n"
				  class="animable"></path>
			<path d="M380.73,187a1.35,1.35,0,0,1-.52-.11,6.4,6.4,0,0,1-2.84-3.87,15.92,15.92,0,0,1,.09-5.54l.15-.64.58.31c1.14.63,4.88,2.81,5,4.29.06,1.3-.75,4.61-1.85,5.38A1,1,0,0,1,380.73,187Zm-2.42-8.62a15.79,15.79,0,0,0,0,4.45,5.87,5.87,0,0,0,2,3c.2.14.32.15.35.14.6-.42,1.47-3.2,1.41-4.5C382.12,180.94,380.24,179.52,378.31,178.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 380.182px 181.92px;" id="elq7xbu89po1g"
				  class="animable"></path>
			<path d="M378,184.4a.59.59,0,0,1-.59-.46l-1.82-7.83a.6.6,0,0,1,.45-.73.6.6,0,0,1,.73.45l1.82,7.83a.61.61,0,0,1-.45.73Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 377.089px 179.881px;" id="elsm9kataks3"
				  class="animable"></path>
			<path d="M376.06,176.65a5.74,5.74,0,0,0-2.18-2.76l.29-.42a6.26,6.26,0,0,1,2.37,3Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 375.21px 175.06px;" id="el87s20ai9hma"
				  class="animable"></path>
			<path d="M376,176c0-.1-.38-2.34.82-3.3l.32.39c-1,.78-.65,2.8-.65,2.82Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 376.541px 174.35px;" id="elbk9vlw2xn2e"
				  class="animable"></path>
			<path d="M373.24,179.62a1.61,1.61,0,1,1-1.61-1.68A1.64,1.64,0,0,1,373.24,179.62Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 371.631px 179.55px;" id="eld69ehe7szj4"
				  class="animable"></path>
			<path d="M384,177a1.61,1.61,0,1,1-1.61-1.61A1.61,1.61,0,0,1,384,177Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 382.39px 177px;" id="elxid8k3oyu4c"
				  class="animable"></path>
			<path d="M377,184.76a1.4,1.4,0,1,1-1.39-1.53A1.47,1.47,0,0,1,377,184.76Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 375.604px 184.63px;" id="elan3kzbxecmq"
				  class="animable"></path>
			<path d="M381.59,182.94a1.25,1.25,0,1,1-1.25-1.29A1.27,1.27,0,0,1,381.59,182.94Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 380.34px 182.9px;" id="el92vmdx542u"
				  class="animable"></path>
			<path d="M384.16,168.87a.75.75,0,1,1-1.5,0,.75.75,0,1,1,1.5,0Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 383.41px 168.87px;" id="eltjn6q80i1eb"
				  class="animable"></path>
			<path d="M368.38,172.4a.79.79,0,1,1-.79-.82A.81.81,0,0,1,368.38,172.4Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 367.59px 172.37px;" id="elck6kq6txfp8"
				  class="animable"></path>
			<path d="M227.89,171.07s1.19-11.15-2-12.72-3.59,1.75-4.16,4.46-2.64,6.95-1.41,9.54,7.33.33,7.33.33Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 224.022px 165.769px;" id="el3megxd0tqlw"
				  class="animable"></path>
			<path d="M223.05,174.11c-1.38,0-2.66-.37-3.22-1.54-1-2.08-.09-4.94.69-7.47.27-.87.53-1.69.68-2.39l.13-.65c.37-1.84.75-3.74,2.07-4.39a3,3,0,0,1,2.67.23c3.44,1.68,2.44,12,2.32,13.22v0l-.33,1.89-.27.1A15.28,15.28,0,0,1,223.05,174.11Zm1.33-15.66a1.25,1.25,0,0,0-.53.11c-.89.44-1.23,2.17-1.54,3.7l-.14.65c-.15.75-.42,1.6-.69,2.49-.73,2.35-1.56,5-.74,6.74s4.76.73,6.43.16l.23-1.3c.31-3,.61-11-1.77-12.2A3,3,0,0,0,224.38,158.45Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 224.003px 165.785px;" id="elzsl6pqka37o"
				  class="animable"></path>
			<path d="M227.62,172.58s-6.33,1.12-6.92,2.62-.81,5.3-.06,6.31,4.05-1.12,5-2.77A19,19,0,0,0,227.62,172.58Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 223.891px 177.176px;" id="elk1nlchf1dtk"
				  class="animable"></path>
			<path d="M221.26,182.28a1.18,1.18,0,0,1-1-.47c-1-1.28-.58-5.34,0-6.8.65-1.62,5.75-2.65,7.3-2.92l.65-.12-.06.66a19.63,19.63,0,0,1-2.06,6.36c-.78,1.36-3.19,3.2-4.69,3.28ZM227,173.2c-2.73.55-5.59,1.48-5.87,2.18-.56,1.41-.72,5-.12,5.84.05.06.22.06.27.06a6.87,6.87,0,0,0,3.89-2.79A19,19,0,0,0,227,173.2Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 223.934px 177.126px;" id="elnqr8shzgexb"
				  class="animable"></path>
			<path d="M231.57,171.92s8.89-6.84,11.91-4.91.39,4-1.63,5.88-4.54,5.88-7.4,6.18-4.1-6.07-4.1-6.07Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 237.6px 172.874px;" id="el5hp2dnl8"
				  class="animable"></path>
			<path d="M234.24,179.59c-3,0-4.25-5.82-4.38-6.5l-.06-.28,1.47-1.29c.93-.72,9.25-7,12.48-4.93a2.93,2.93,0,0,1,1.59,2.15c.13,1.47-1.29,2.79-2.66,4.07l-.48.45c-.52.49-1.09,1.14-1.69,1.83-1.75,2-3.72,4.24-6,4.48Zm-3.34-6.41c.4,1.8,1.66,5.6,3.5,5.4s3.73-2.3,5.35-4.15c.62-.71,1.21-1.37,1.76-1.9l.49-.45c1.14-1.07,2.43-2.27,2.35-3.25a2.1,2.1,0,0,0-1.14-1.39c-2.23-1.43-8.91,3-11.32,4.86Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 237.574px 172.882px;" id="el2b6fpjlhgyb"
				  class="animable"></path>
			<path d="M230.42,172.94s2.35,6,1.39,7.27-4.1,3.46-5.36,3.35-1.15-4-.24-5.71A19.17,19.17,0,0,1,230.42,172.94Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 228.778px 178.252px;" id="el6jnzi0gg4za"
				  class="animable"></path>
			<path d="M226.54,184.06h-.13a1.25,1.25,0,0,1-1-.73c-.71-1.32-.4-4.34.35-5.72a20,20,0,0,1,4.35-5.07l.53-.4.24.62c.57,1.46,2.37,6.35,1.32,7.75C231.3,181.73,228.17,184.06,226.54,184.06Zm3.66-10.29a18.93,18.93,0,0,0-3.55,4.33,6.87,6.87,0,0,0-.35,4.76s.12.2.2.2c1,.07,4-1.94,4.91-3.15C231.86,179.3,231.16,176.38,230.2,173.77Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 228.766px 178.1px;" id="eld15ouwl3dc"
				  class="animable"></path>
			<path d="M225.16,179.31a.74.74,0,0,1-.19-.9l4.38-8.73a.73.73,0,1,1,1.31.66l-4.37,8.73a.74.74,0,0,1-1,.33A.67.67,0,0,1,225.16,179.31Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 227.816px 174.378px;" id="elzcsjzguf948"
				  class="animable"></path>
			<path d="M229.91,170.8l-.46-.19a7.18,7.18,0,0,0,.11-4.31l.48-.13A7.4,7.4,0,0,1,229.91,170.8Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 229.9px 168.485px;" id="el9xfi1j34kpg"
				  class="animable"></path>
			<path d="M230.33,170.16l-.44-.25c.06-.1,1.42-2.42,3.24-2.42h0v.5h0C231.6,168,230.34,170.14,230.33,170.16Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 231.51px 168.825px;" id="el7b1oew3q0c5"
				  class="animable"></path>
			<path d="M224.46,171.17a2,2,0,1,1-.2-2.82A2,2,0,0,1,224.46,171.17Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 222.949px 169.86px;" id="elz1pedp2hgpf"
				  class="animable"></path>
			<path d="M236.52,177a1.95,1.95,0,1,1-.25-2.75A2,2,0,0,1,236.52,177Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 235.014px 175.75px;" id="el0shseuxeriy"
				  class="animable"></path>
			<path d="M223.94,178.86a1.7,1.7,0,1,1-.11-2.52A1.78,1.78,0,0,1,223.94,178.86Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 222.728px 177.65px;" id="el1c8ji1nxjsi"
				  class="animable"></path>
			<path d="M229.69,180.76a1.52,1.52,0,1,1-.17-2.17A1.53,1.53,0,0,1,229.69,180.76Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 228.545px 179.758px;" id="elhbpkl67t1h"
				  class="animable"></path>
			<path d="M243,169.6a.91.91,0,1,1-.09-1.31A.92.92,0,0,1,243,169.6Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 242.324px 168.988px;" id="el4a05eax89ef"
				  class="animable"></path>
			<path d="M225.52,160.66a1,1,0,1,1-.1-1.38A1,1,0,0,1,225.52,160.66Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 224.75px 160.022px;" id="el1r4z68cf4kx"
				  class="animable"></path>
			<path d="M116.82,177.37s-8.52,1.59-9,4.33,2.08,2.27,4.22,2.1,5.73.41,7.38-1.07-1.38-5.5-1.38-5.5Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 113.837px 180.585px;" id="elm9wfgyc6phj"
				  class="animable"></path>
			<path d="M110,184.44a2.89,2.89,0,0,1-2.2-.7,2.41,2.41,0,0,1-.45-2.12c.5-3,8.47-4.57,9.38-4.74h0l1.54-.18.17.23c.34.44,3.25,4.43,1.32,6.17-1.36,1.22-3.7,1.19-5.76,1.17-.71,0-1.37,0-1.92,0l-.5,0C111.07,184.39,110.52,184.44,110,184.44Zm6.89-6.57c-3.16.59-8.26,2.17-8.55,3.91a1.49,1.49,0,0,0,.19,1.28c.48.52,1.81.39,3,.28l.51,0c.6-.05,1.28,0,2,0,1.88,0,4,0,5.08-.91s-.49-3.48-1.28-4.6Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 113.843px 180.578px;" id="elkcwxq3ar8rh"
				  class="animable"></path>
			<path d="M118,177.24s2.23,4.44,3.47,4.55,4.11-.57,4.7-1.35-1.74-2.75-3.16-3.09A14.89,14.89,0,0,0,118,177.24Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 122.132px 179.44px;" id="eltctqke1knsf"
				  class="animable"></path>
			<path d="M121.72,182.3h-.29c-1.41-.12-3.32-3.73-3.88-4.83l-.29-.59.65-.12a15.1,15.1,0,0,1,5.21.12,6,6,0,0,1,3.55,2.82,1,1,0,0,1-.11,1.05C125.86,181.68,123.1,182.3,121.72,182.3Zm-3-4.66c1.06,2,2.28,3.61,2.74,3.65,1.21.11,3.84-.6,4.26-1.15.06-.4-1.55-2-2.88-2.3A14.51,14.51,0,0,0,118.77,177.64Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 122.015px 179.445px;" id="elelybtytu1tb"
				  class="animable"></path>
			<path d="M116.63,174.46s-7-5.08-6.26-7.74,2.86-1.17,4.72-.1,5.36,2.07,6.21,4.12-3.59,4.39-3.59,4.39Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 115.862px 170.178px;" id="elffmqr4tnluh"
				  class="animable"></path>
			<path d="M117.69,175.7l-1.35-.84c-.75-.54-7.29-5.36-6.45-8.28a2.4,2.4,0,0,1,1.31-1.73c1.11-.45,2.42.33,3.7,1.08l.44.26c.47.27,1.08.55,1.72.84,1.87.86,4,1.84,4.71,3.52,1,2.4-3.33,4.76-3.83,5Zm-.78-1.66.81.51c1.19-.68,3.64-2.38,3.12-3.61s-2.49-2.21-4.2-3c-.66-.3-1.29-.59-1.8-.89l-.45-.26c-1-.6-2.17-1.27-2.82-1-.31.12-.55.48-.72,1.07C110.37,168.55,114.31,172.16,116.91,174Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 115.868px 170.209px;" id="el371qlav13sw"
				  class="animable"></path>
			<path d="M117.65,175.08s3.91-3.06,5.08-2.63,3.47,2.27,3.67,3.23-2.75,1.75-4.18,1.44A14.77,14.77,0,0,1,117.65,175.08Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 122.03px 174.798px;" id="ela4fdc9b305i"
				  class="animable"></path>
			<path d="M122.94,177.68a4.11,4.11,0,0,1-.82-.07,15.26,15.26,0,0,1-4.77-2.12l-.53-.39.52-.41c1-.76,4.24-3.2,5.56-2.71,1.16.42,3.73,2.36,4,3.6a1.07,1.07,0,0,1-.35,1A5.83,5.83,0,0,1,122.94,177.68Zm-4.43-2.63a15.06,15.06,0,0,0,3.82,1.58c1.33.29,3.47-.46,3.58-.87-.14-.66-2.21-2.42-3.35-2.84C122.12,172.75,120.31,173.72,118.51,175.05Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 121.869px 174.799px;" id="elxppj9ga3cyb"
				  class="animable"></path>
			<path d="M123.53,177.58a.56.56,0,0,1-.62.33l-7.44-1.31a.57.57,0,0,1,.2-1.12l7.44,1.31a.57.57,0,0,1,.46.66A.5.5,0,0,1,123.53,177.58Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 119.307px 176.698px;" id="elq4qtbs1a4s"
				  class="animable"></path>
			<path d="M113,177.24l-.27-.43a5.83,5.83,0,0,1,3.5-.93l-.05.49A5.48,5.48,0,0,0,113,177.24Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 114.48px 176.555px;" id="el0mrgqjkcwk9"
				  class="animable"></path>
			<path d="M115.5,176.2c-.09,0-2.16-.53-2.55-1.94l.48-.13c.31,1.12,2.17,1.58,2.19,1.58Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 114.285px 175.165px;" id="elbba5pzfjibf"
				  class="animable"></path>
			<path d="M117.65,179.89a1.51,1.51,0,1,1-2,.77A1.55,1.55,0,0,1,117.65,179.89Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 117.032px 181.274px;" id="elmagl4yqqbf"
				  class="animable"></path>
			<circle cx="118.73" cy="171.05" r="1.51"
					style="fill: rgb(146, 227, 169); transform-origin: 118.73px 171.05px;" id="eluqtjablqzra"
					class="animable"></circle>
			<path d="M123.47,178.58a1.32,1.32,0,1,1-1.84.64A1.39,1.39,0,0,1,123.47,178.58Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 122.843px 179.754px;" id="eldccuz0mr7rf"
				  class="animable"></path>
			<path d="M123.6,173.9a1.17,1.17,0,1,1-.92,2.16,1.17,1.17,0,1,1,.92-2.16Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 123.14px 174.98px;" id="el8l172fcng65"
				  class="animable"></path>
			<path d="M112.39,166.49a.73.73,0,0,1,.41.94.74.74,0,0,1-1,.36.71.71,0,1,1,.55-1.3Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 112.071px 167.133px;" id="el94wp02d3ez"
				  class="animable"></path>
			<path d="M109.63,181.43a.74.74,0,1,1-1,.38A.76.76,0,0,1,109.63,181.43Z"
				  style="fill: rgb(146, 227, 169); transform-origin: 109.312px 182.101px;" id="elrrp819cqlhk"
				  class="animable"></path>
		</g>
		<defs>
			<filter id="active" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
			<filter id="hover" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
				<feColorMatrix type="matrix"
							   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
			</filter>
		</defs>
	</svg>
</template>

<script lang="ts">

export default {
	name: "HappyEarthAnimation",
	components: {},
	props: {
		height: String,
	},
	setup() {
		return {}
	}
}
</script>

<style scoped>
svg#freepik_stories-happy-earth:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-happy-earth.animated #freepik--background-simple--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Hearts--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Sparkles--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Trees--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Earth--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Sun--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Clouds--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-happy-earth.animated #freepik--Butterflies--inject-24 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>