import {computed, ComputedRef, onBeforeMount} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {MobiPointsApiPoints, PointsResponseError} from "@/mobipoints/api/points";

export default function usePoints() {
	const currentPoints: ComputedRef<number> = computed(() => store.getters['points/currentPoints']);

	const loadPoints = async function () {
		try {
			const response = await MobiPointsApiPoints.getPoints();
			return response.data.payload;
		} catch (error: any | AxiosError) {
			throw new PointsResponseError(error.status, error.error.message);
		}
	}

	const initPoints = async function (forceReload = false) {
		if (!currentPoints.value || forceReload) {
			store.commit('points/currentPoints', await loadPoints());
		}
	}

	onBeforeMount(() => {
		initPoints(true).then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		currentPoints,
		loadPoints,
		initPoints
	}
}