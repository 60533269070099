import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';

export const enum WalletType {
	MOBI_POINTS = 'mobi_points',
	CARBON_EMISSIONS = 'carbon_emissions',
	TREASURES = 'treasures',
}


export const enum WalletState {
	ACTIVE = 'active',
}


export interface WalletInterface {
	[key: string]: any;
	uuid: string,
	state: WalletState,
	type: WalletType,
	user_reference: string,
	items?: WalletItemList,
	points?: number,
}