<template>
	<LottieAnimation :animationData="animationData" :loop="loop" :autoplay="autoplay" :fullscreen="fullscreen" :width="width" :height="height" :start-time="startTime" speed="0.5" :z-index="9999" :sound-src="animationSound"/>
</template>

<script lang="ts">

import LottieAnimation from '@/components/animation/LottieAnimation.vue';
import animationData from './json/confetti.json';
import animationSound from './json/confetti.mp3';

export default {
	name: 'ConfettiBombAnimation',
	components: {LottieAnimation},
	props: {
		maxHeight: {
			type: String,
			default: '25em',
		},
		loop: {
			type: Boolean,
			default: false,
		},
		autoplay: {
			type: Boolean,
			default: true,
		},
		fullscreen: {
			type: Boolean,
			default: true,
		},
		fixed: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
			default: '300px',
		},
		height: {
			type: String,
			default: '300px',
		},
		startTime: {
			type: Number,
			default: 300
		},
	},
	setup(props) {
		return {
			animationData,
			animationSound
		}
	},
};
</script>


<style scoped>
</style>