import {
	MobiPointsChallengeHighscoreEntryInterface
} from "@/mobipoints/challenge/challenge/highscore/highscore.interface";

export class ChallengeHighscoreEntry implements MobiPointsChallengeHighscoreEntryInterface {
	private _position = 0;
	private _user_id: number | null = null;
	private _user_name = '';
	private _user_email = '';
	private _user_avatar = '';
	private _progress = 0;
	private _text = '';


	constructor(position: number, user_id: number, user_name: string, user_email: string, user_avatar = '', progress = 0, text = '') {
		this.position = position;
		this.user_id = user_id;
		this.user_name = user_name;
		this.user_email = user_email;
		this.user_avatar = user_avatar;
		this.progress = progress;
		this.text = text;
	}

	get position(): number {
		return this._position;
	}

	set position(value: number) {
		this._position = value;
	}

	get user_id(): number {
		return this._user_id as number;
	}

	set user_id(value: number) {
		this._user_id = value;
	}

	get user_name(): string {
		return this._user_name;
	}

	set user_name(value: string) {
		this._user_name = value;
	}

	get user_email(): string {
		return this._user_email;
	}

	set user_email(value: string) {
		this._user_email = value;
	}

	get user_avatar(): string {
		return this._user_avatar;
	}

	set user_avatar(value: string) {
		this._user_avatar = value;
	}

	get progress(): number {
		return this._progress;
	}

	set progress(value: number) {
		this._progress = value;
	}

	get text(): string {
		return this._text;
	}

	set text(value: string) {
		this._text = value;
	}

	getProgressPercentage(): number {
		return this.progress / 100
	}
}