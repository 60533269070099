<template>
	<div class="ion-padding">
		<ion-list>
			<ion-item>
				<ion-label>Pokémon Yellow</ion-label>
			</ion-item>
			<ion-item>
				<ion-label>Mega Man X</ion-label>
			</ion-item>
			<ion-item>
				<ion-label>The Legend of Zelda</ion-label>
			</ion-item>
			<ion-item>
				<ion-label>Pac-Man</ion-label>
			</ion-item>
			<ion-item>
				<ion-label>Super Mario World</ion-label>
			</ion-item>
		</ion-list>
	</div>
</template>
<script>
import {ref} from "vue";
import { isPlatform } from '@ionic/vue';
import {IonList, IonItem, IonLabel} from '@ionic/vue';
import * as icons from 'ionicons/icons';

export default {
	name: 'UserTrackingList',
	components: {IonList, IonItem, IonLabel},
	props: {
		state: String,
		type: String,
	},
	setup() {
		return {
			icons,
			isPlatform,
		}
	}
}
</script>

<style scoped>
</style>