import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c41148e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "open-modal",
  type: "button",
  class: "shadow-lg rounded-md outline outline-2 outline-offset-2 outline-gray-700 px-3.5 py-1.5 text-sm text-gray-900 hover:bg-gray-50 hover:bg-opacity-60 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_redeem_voucher_component = _resolveComponent("redeem-voucher-component")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _hoisted_1, _toDisplayString($props.buttonTitle), 1),
    _createVNode(_component_ion_modal, {
      ref: "modal",
      trigger: "open-modal",
      "can-dismiss": true,
      "presenting-element": $setup.presentingElement
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.title), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.dismissModal()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_redeem_voucher_component, {
              "voucher-type": $props.voucherType,
              "redirect-to-start-page": $props.redirectToStartPage,
              "show-input-field": $props.showInputField,
              onCancel: _cache[1] || (_cache[1] = ($event: any) => ($setup.dismissModal())),
              class: "pb-10"
            }, null, 8, ["voucher-type", "redirect-to-start-page", "show-input-field"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["presenting-element"])
  ], 64))
}