<template>
	<div class="ion-padding">
		<ion-list v-show="highscoreList.length > 0">
			<ion-list-header>Highscore</ion-list-header>
			<ion-item v-for="highscoreEntry in highscoreList" :key="highscoreEntry.position"
					  :is-current-user="isCurrentUser(highscoreEntry.user_email)">
				<ion-badge style="z-index: 9" :color="getColorByRanking(highscoreEntry.position)" slot="start">
					{{ highscoreEntry.position }}
				</ion-badge>
				<ion-avatar slot="start">
					<ion-img v-if="highscoreEntry.user_avatar" :src="highscoreEntry.user_avatar"
							 :alt="highscoreEntry.user_avatar"/>
					<ion-icon style="z-index: 8" v-else :icon="icons.personCircleOutline"></ion-icon>
				</ion-avatar>
				<ion-label>
					<h2>{{ highscoreEntry.user_name }}</h2>
					<div>
						<h3 class="ion-align-items-center ion-justify-content-between" style="display: flex">
							<span>{{ highscoreEntry.progress }}%</span>
							<ion-icon v-show="highscoreEntry.progress >= 100" :icon="icons.checkmarkOutline"
									  style="margin-left: .25em;"></ion-icon>
						</h3>
						<ion-progress-bar :color="getColorForProgressBar(highscoreEntry.getProgressPercentage())"
										  :value="highscoreEntry.getProgressPercentage()"></ion-progress-bar>
					</div>
					<p>{{ highscoreEntry.text }}</p>
				</ion-label>
			</ion-item>
		</ion-list>
		<ion-item v-show="highscoreList.length < 1">
			<ion-icon size="large" :icon="icons.informationCircleOutline"></ion-icon>
			<ion-label class="ion-text-wrap" style="margin-left: .5rem;">Es sind noch kein Einträge in der Highscore
				vorhanden!
			</ion-label>
		</ion-item>
		<ion-infinite-scroll
				@ionInfinite="loadMoreHighscoreData($event)"
				threshold="100px"
				id="infinite-scroll"
		>
			<ion-infinite-scroll-content
					loading-spinner="dots"
					loading-text="Loading more highscores...">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
	</div>
</template>

<script setup lang="ts">
import {defineProps, onMounted, ref, watch} from "vue";
import {
	IonAvatar,
	IonBadge,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonIcon,
	IonImg,
	IonProgressBar,
	toastController,
	IonInfiniteScroll,
	IonInfiniteScrollContent, InfiniteScrollCustomEvent,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import {ChallengeHighscoreEntry} from "@/mobipoints/challenge/challenge/highscore/highscore_entry.type";
import {MobiPointsApiChallenge} from "@/mobipoints/api/challenge";
import useChallengeFactory from "@/composable/useChallengeFactory";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError, AxiosResponse} from "axios";
import useUserRepository from "@/components/user/UserRepository.vue";
import useChallenge from "@/composable/useChallenge";


const props = defineProps({
	uuid: {
		type: String,
		required: true
	}
});

const {getUserEmail} = useUserRepository();

const {getChallengeFactory} = useChallengeFactory();

const challengeFacade = useChallenge();

const highscoreList = ref<ChallengeHighscoreEntry[]>([]);

const currentHighscoreCount = ref<number>(25);

const loadHighscore = async function (uuid: string) {
	let response: AxiosResponse | null = null;

	try {
		response = await MobiPointsApiChallenge.getHighscore(uuid, currentHighscoreCount.value);
		highscoreList.value = getChallengeFactory().createChallengeHighscoreEntryListByApiResponse(response.data);
	} catch (error: any | AxiosError) {
		const toast = await toastController.create({
			message: `Could not load challenge highscore!`,
			position: 'bottom',
			duration: 2000,
			icon: icons.warning,
			buttons: [
				{
					side: 'end',
					role: 'cancel',
					icon: icons.close
				}
			]
		});

		await toast.present();
	}
}

const getColorByRanking = function (ranking: number | any = null): string {
	switch (ranking) {
		case 1:
			return "success";
		case 2:
			return "warning";
		case 3:
			return "warning";
		default:
			return "medium";
	}
}


const getColorForProgressBar = function (progressPercentage: number): string {
	let color = 'tertiary';
	if (progressPercentage >= 1) {
		color = 'success';
	} else if (progressPercentage >= .8) {
		color = 'primary';
	} else if (progressPercentage >= .5) {
		color = 'secondary';
	}

	return color;
}

const isCurrentUser = function (email: string): boolean {
	return getUserEmail.value === email;
}


const loadMoreHighscoreData = (ev: InfiniteScrollCustomEvent) => {
	setTimeout(async () => {
		if (highscoreList.value.length <= currentHighscoreCount.value) {
			currentHighscoreCount.value += 25;
			await loadHighscore(props.uuid);
			await ev.target.complete();
		} else {
			ev.target.disabled = true;
		}
	}, 500);
}

onMounted(() => {
	loadHighscore(props.uuid);
});

watch(() => challengeFacade.getChallenge(props.uuid), async (newChallenge) => {
	if (props.uuid === newChallenge?.uuid) {
		await loadHighscore(props.uuid);
	}
});
</script>

<style scoped>

ion-badge {
    position: absolute;
    top: 0;
    margin-top: 10px;
    left: 0;
    margin-left: 5px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    line-height: 20px;
}

ion-avatar ion-icon {
    width: 100%;
    height: 100%;
    color: var(--ion-color-step-800);
}

ion-item[is-current-user='false'] {
    border-left: 2px solid transparent;
}

ion-item[is-current-user='true'] {
    border-left: 2px solid var(--ion-color-primary);
}
</style>