<template>
	<ion-label slot="end" style="cursor: pointer" @click="showAvatarMenu">
		<ion-badge class="points-in-menu" v-if="showPoints === true" color="dark" slot="start">
			<ion-label class="points-text ion-no-padding">{{ currentPoints }} <span v-if="currentPoints.toString().length <= 3">P</span></ion-label>
		</ion-badge>
		<ion-avatar v-if="getUserImage"
					slot="end"
				:style="['padding: 5px; margin-right: 13px', imgHeight.length > 0 ? 'height: ' + imgWidth : '', imgWidth.length > 0 ? 'width: ' + imgWidth : '']"
				:title="getFullName">
			<img v-if="getUserImage" :alt="getFullName" :src="getUserImage">
		</ion-avatar>
		<ion-avatar v-else
				slot="end" class="avatar-abbr"
				:style="['margin-right: 13px;', imgHeight.length > 0 ? 'height: ' + imgWidth : '', imgWidth.length > 0 ? 'width: ' + imgWidth : '']"
				:title="getFullName">
			<div style="text-align: center;margin: auto;line-height: 54px;">{{getUserAbbr}}</div>
		</ion-avatar>
	</ion-label>
</template>

<script lang="ts">
import {actionSheetController, IonAvatar,} from '@ionic/vue';
import {person, trash, walk, warning} from 'ionicons/icons';
import useUserRepository from "@/components/user/UserRepository.vue";
import router from "@/router";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import usePoints from "@/composable/usePoints";

export default {
	name: 'AvatarMenu',
	components: {
		IonAvatar,
		// IonBadge,
	},
	props: {
		imgHeight: {
			type: String,
			default: ""
		},
		imgWidth: {
			type: String,
			default: ""
		},
		showPoints: {
			type: Boolean,
			default: false
		},
	},
	setup(props: any) {
		const {
			getFullName,
			getGivenName,
			getUserImage,
			getUserAbbr,
		} = useUserRepository();
		const {currentPoints, initPoints} = usePoints();

		async function showAvatarMenu() {
			await initPoints(true);
			const actionSheet = await actionSheetController
				.create({
					header: getGivenName.value + " was möchtest du tun?",
					cssClass: 'my-custom-class',
					buttons: [
						{
							text: 'Mein Profil',
							icon: person,
							handler: () => {
								router.push('/tabs/profile');
							},
						},
						{
							text: 'Abmelden',
							role: 'destructive',
							icon: trash,
							cssClass: 'delete',
							handler: () => {
								const userAuth = new UserAuth();
								userAuth.logout();
							},
						},
					],
				});
			await actionSheet.present();
			await actionSheet.onDidDismiss();
		}

		return {showAvatarMenu, warning, walk, getFullName, getUserImage, getUserAbbr, currentPoints, initPoints}
	}
}
</script>

<style>
.delete {
    color: white !important;
    background-color: #ff909c !important;
}

.delete ion-icon {
    color: white !important;
}

.points-in-menu {
    position: absolute;
    top: 0;
    margin-top: 2px;
    height: 28px;
    width: 28px;
    border-radius: 28px;
    right: 0;
	margin-right: 5px;
	font-size: 8px;
}

.points-text {
	position: relative;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    white-space: break-spaces;
	text-align: center;
    margin-left: -5px;
    left: 0;
    margin-right: -5px;
    min-width: 100%;
}

.avatar-abbr {
	background-color: var(--ion-color-dark);
	font-size: 20px;
	color: var(--ion-color-white);
    border: 1px solid white;
    box-shadow: 0px 1px 3px grey;
}
</style>