import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/images/treasure_hunt/treasure.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      ($setup.isTreasureHuntEnabled)
        ? (_openBlock(), _createBlock(_component_ion_fab, {
            key: 0,
            vertical: "bottom",
            horizontal: "center",
            slot: "fixed",
            size: "medium",
            class: _normalizeClass(['treasureHuntFabButton', $setup.isIosPlatform() && $setup.isNativePlatform() ? 'mb-10' : ''])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                class: "ion-no-margin",
                "router-link": "/tabs/treasurehunt",
                "router-direction": "root",
                color: "warning"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    ref: "treasureImage",
                    class: _normalizeClass(["mx-auto p-2 pb-3 h-14 w-auto z-50 shadow-amber-50", { 'bounce': $setup.isBouncing }]),
                    src: _imports_0,
                    alt: "Niceshops Treasure Hunt"
                  }, null, 2)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "home",
                href: "/tabs/home",
                class: "firstTab",
                onClick: $setup.scrollToTopOnDoubleClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.icons.home
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.mobiDashboardTitle), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_tab_button, {
                tab: "co2",
                href: "/tabs/co2",
                class: _normalizeClass([$setup.isTreasureHuntEnabled ? 'pr-4' : ''])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.icons.leaf
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.co2TabTitle), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_tab_button, {
                tab: "challenges",
                href: "/tabs/challenges",
                class: _normalizeClass([$setup.isTreasureHuntEnabled ? 'pl-4' : ''])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.icons.happy
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Challenges")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_tab_button, {
                tab: "profile",
                href: "/tabs/profile",
                class: "lastTab"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.icons.person
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Profile")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}