import {computed, ComputedRef, onBeforeMount} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {Data, DATA_TYPE_SYSTEM_SETTINGS, DATA_TYPE_TEXT} from "@/mobipoints/data/data/data.type";
import {DataResponseError, MobiPointsApiData} from "@/mobipoints/api/data";
import {MobiPointsDataItemInterface} from "@/mobipoints/data/data/data_item.interface";

export default function useData() {
	const dataList: ComputedRef<Array<Data>> = computed(() => store.getters['data/dataList']);
	const textData: ComputedRef<Array<Data>> = computed(() => {
		if (dataList.value) {
			return dataList.value.filter((data: Data) => {
				if (data.type === DATA_TYPE_TEXT) {
					return true;
				}
			});
		} else {
			return [];
		}
	});
	const systemSettingsData: ComputedRef<Array<Data>> = computed(() => {
		if (dataList.value) {
			return dataList.value.filter((data: Data) => {
				if (data.type === DATA_TYPE_SYSTEM_SETTINGS) {
					return true;
				}
			});
		} else {
			return [];
		}
	});

	function resolvePlaceholder(value?: string, placeholders: Array<{key: string, value: string}> = []): string {
		let result = value;
		if (result) {
			placeholders.forEach((placeholder) => {
				if (placeholder && placeholder.key && placeholder.value) {
					if (result) {
						const regexp = new RegExp('\\{'+placeholder.key+'\\}', 'gi');
						result = result.replace(regexp, placeholder.value);
					}
				}
			});
		}
		return String(result);
	}

	function getTextValueByKey(key: any, placeholders: Array<{key: string, value: string}> = [], defaultValue: any = null): any
	{
		let result: any = defaultValue;
		const textList = textData.value;
		let itemFound = false;
		if (textList) {
			textList.forEach((data) => {
				const item = data.items.find((item: MobiPointsDataItemInterface|any) => {
					if (item.key === key) {
						return true;
					}
				});

				if (item) {
					itemFound = true;
					result = resolvePlaceholder(String(item.value), placeholders);
					return;
				}
			});
		}

		if (!itemFound && result) {
			result = resolvePlaceholder(String(result), placeholders);
		}
		return result;
	}

	function getSystemValueByKey(key: any, defaultValue: any = null): any
	{
		let result: any = defaultValue;
		const systemSettingList = systemSettingsData.value;
		if (systemSettingList) {
			systemSettingList.forEach((data) => {
				const item = data.items.find((item: MobiPointsDataItemInterface|any) => {
					if (item.key === key) {
						return true;
					}
				});

				if (item) {
					result = item.value;
				}
			});
		}
		return result;
	}


	const loadData = async function () {
		try {
			const response = await MobiPointsApiData.getData();
			return response.data.payload;
		} catch (error: any | AxiosError) {
			throw new DataResponseError(error.status, error.error.message);
		}
	}

	const initData = async function (force = false) {
		if (force || !dataList.value || dataList.value.length < 1) {
			store.commit('data/dataList', await loadData());
		}
	}

	const reloadData = async function () {
		store.commit('data/resetDataList');
		await initData();
	}

	onBeforeMount(() => {
		initData().then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		dataList,
		textData,
		systemSettingsData,
		initData,
		reloadData,
		getTextValueByKey,
		getSystemValueByKey
	}
}