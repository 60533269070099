<template>
  <ion-button color="white" expand="block" @click="signIn" style="min-height: 55px" id="login-via-google">
    <img style="position:absolute;left: 0" alt="login-with-google" id="google-logo-9808_bx"
         src="../../../public/assets/login/google-logo-9808_bx.png"
         srcset="../../../public/assets/login/google-logo-9808_bx.png 1x, ../../../public/assets/login/google-logo-9808_bx@2x.png 2x">
    <p class="google-login-text">Sign in with Google</p>
  </ion-button>
</template>

<script lang="ts">
import {onMounted, ref} from 'vue'
import {IonButton, loadingController, isPlatform} from '@ionic/vue';
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import useUserRepository from "@/components/user/UserRepository.vue";
import useToastMessage from "@/components/core/ToastMessage.vue";
import router from "@/router";
import {UserAuth} from '@/mobipoints/auth/user_auth';
import useData from "@/composable/useData";
import {AuthenticationError} from "@/mobipoints/api/auth";

export default {
  name: 'GoogleLogin',
  components: {IonButton},
  props: {
    provider: {
      type: String,
      default: "google"
    }
  },
  setup(props: any) {
    const providerLink = ref("");
    const {setUserName, setGivenName, setEmail, setProfileImage} = useUserRepository();
    const {openToast} = useToastMessage();
    const {getTextValueByKey} = useData();
    const loadingTimeout = ref(1000);

    async function presentLoading() {
      const loading = await loadingController
          .create({
            cssClass: '',
            message: 'Bitte warten... du wirst eingeloggt!',
            duration: loadingTimeout.value,
          });

      await loading.present();

      setTimeout(function () {
        loading.dismiss()
      }, loadingTimeout.value);
    }

    async function initializeGoogle() {
      if (!isPlatform('capacitor')) {
        await GoogleAuth.initialize({
          clientId: '670048602856-c17ie444fkvvvip2ffn1dt70q1u736c9.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: true,
        });
      }
    }

    onMounted(() => {
      providerLink.value = process.env.VUE_APP_API_OAUTH + '/' + props.provider + "?originUrl=" + window.location.href;
      initializeGoogle();
    })

    async function handleGoogleLoginError(error) {
      try {
        if (error instanceof AuthenticationError) {
          if (error.message.includes('Provider Data Error')) {
            await openToast(getTextValueByKey('login.google.invalid_user', [], 'Ungültiger User!'), 'danger', 'top', true, 10000, undefined, true);
          } else {
            await openToast(getTextValueByKey('login.google.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
          }
        } else {
          if (error.error == "popup_closed_by_user") {
            await openToast(getTextValueByKey('login.google.canceled', [], 'Login Error'), 'warning', 'top', true, 5000, undefined, true);
          } else {
            await openToast(getTextValueByKey('login.google.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
          }
        }
      } catch (error) {
        await openToast(getTextValueByKey('login.google.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
      }
    }

    async function signOut(): Promise<void> {
      try {
        GoogleAuth.signOut();
      } catch (error) {
        console.log(error);
      }
    }

    async function signIn(): Promise<void> {
      try {
        const result = await GoogleAuth.signIn();
        await presentLoading();
        if (result) {
          const userAuth = new UserAuth();
          const authToken = userAuth.login(result);
          if (await authToken) {
            setUserName(result.name);
            setGivenName(result.givenName);
            setEmail(result.email);
            setProfileImage(result.imageUrl);
            await router.push('home');
          }
        } else {
          await signOut();
          await openToast(getTextValueByKey('login.google.error', [], 'Login Error'), 'danger', 'top', true, 5000);
        }
      } catch (error) {
        console.log(error);
        await signOut();
        await handleGoogleLoginError(error);
      }
    }

    return {providerLink, signIn}
  },
}
</script>

<style scoped>
#google-logo-9808_bx {
  padding: 3px 3px 3px 3px;
  /*height: 18px;*/
}

.google-login-text {
  font-family: Rubik, sans-serif !important;
  font-size: large;
  font-weight: normal;
  text-align: center;
  /*padding-left: 24px;*/
}

/*#login-via-google .button-native{*/
/*    box-shadow: 2px 5px 25px #0000004a*/
/*}*/
</style>
