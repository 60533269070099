import {Plugins} from "@capacitor/core";
import useSystem from "@/composable/useSystem";
import {
	MobiPointsTrackingGeolocationAbstractBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/abstract_background_geolocation";
import useMainData from "@/composable/useMainData";
const {BackgroundGeolocation} = Plugins;
import { Dialog } from '@capacitor/dialog';
import useData from "@/composable/useData";
import {convertTimeStampToSeconds, formatTimestamp} from "@/mobipoints/core/date";

export interface MobiPointsNativeLocationInterface {
	// Longitude in degrees.
	longitude: number,
	// Latitude in degrees.
	latitude: number,
	// Radius of horizontal uncertainty in metres, with 68% confidence.
	accuracy: number,
	// Metres above sea level (or null).
	altitude: number|null,
	// Vertical uncertainty in metres, with 68% confidence (or null).
	altitudeAccuracy: number|null,
	// Deviation from true north in degrees (or null).
	bearing: number|null,
	// True if the location was simulated by software, rather than GPS.
	simulated: boolean,
	// Speed in metres per second (or null).
	speed: number|null,
	// Time the location was produced, in milliseconds since the unix epoch.
	time: number
}

export class MobiPointsTrackingGeolocationNativeBackgroundGeolocation extends MobiPointsTrackingGeolocationAbstractBackgroundGeolocation {
	private started = Date.now();
	public last_location: MobiPointsNativeLocationInterface|null = null;

	protected log_for_watcher(text, time) {
		const logText = String(Math.floor((time - this.started) / 1000)) + ":" + text;
		useSystem().addLog(logText)
	}

	protected log_error(error) {
		console.error(error);
		return this.log_for_watcher(
			error.name + ": " + error.message,
			Date.now(),
		);
	}

	protected getNativeBackgroundGeolocationConfig()
	{
		const systemDistanceFilter = Number(useData().getSystemValueByKey('system_settings', useMainData().userProfileSettings.value.trackingDistanceFilter ?? 10));
		return {
			backgroundTitle: "Tracking You.",
			backgroundMessage: "Cancel to prevent battery drain.",
			requestPermissions: true,
			stale: false,
			distanceFilter: systemDistanceFilter,
		}
	}


	public make_guess() {
		return new Promise( (resolve) => {
			this.last_location = null;
			const id = BackgroundGeolocation.addWatcher(
				this.getNativeBackgroundGeolocationConfig(),
				( (location: MobiPointsNativeLocationInterface, error) => {
					if (error) {
						if (error.code === "NOT_AUTHORIZED") {
							Dialog.confirm({
								title: "Location Required",
								message:
									"MobiPoints needs your location, " +
									"but does not have permission.\n\n" +
									"Open settings now?"
							}).then( (({value}) => {
								if (value) {
									BackgroundGeolocation.openSettings().catch(
										(error) => this.log_error(error)
									);
								}
							})).catch((error) => this.log_error(error));
						}
						return this.log_error(error);
					}

					const isInitialLoc = !this.last_location;
					this.last_location = location;

					let locationSimulated = false;
					if (location.simulated ?? false) {
						locationSimulated = true;
					}

					//TODO if location time is older than 8 seconds get current location!
					const coordinate = this.getTrackingFactory().createCoordinate(location.latitude, location.longitude, convertTimeStampToSeconds(location.time), true, locationSimulated);
					useSystem().setBackgroundTrackingActive(true);
					this.startTrackingAutoStopWatcher();
					this.setCurrentPosition(coordinate, isInitialLoc);
				})
			);
			this.setPositionWatchId(id);
		});
	}

	public async startGeolocationTracking(forceStart = false) {
		super.startGeolocationTracking();
		if (this.getPositionWatchId() && forceStart) {
			await this.stopGeolocationTracking();
		}

		if (useSystem().isNativePlatform() && this.hasEmptyOrInvalidPositionWatchId() && ( useSystem().isIosPlatform() || useSystem().isAndroidPlatform()) ) {
			return this.make_guess().then( (location: any)  => {
				return (
					location === null
						? this.log_for_watcher("null", Date.now())
						: this.log_for_watcher(
							[
								location.latitude,
								location.longitude
							].map(String).join(":"),
							location.time
						)
				);
			});
		}
	}

	public async stopGeolocationTracking() {
		super.stopGeolocationTracking();
		if (this.getPositionWatchId() === null && this.getPositionWatchId() !== undefined) {
			return false;
		}

		try {
			const id = { id: this.getPositionWatchId() };
			await BackgroundGeolocation.removeWatcher(id);
			this.resetPositionWatchId();
			this.stopTrackingAutoStopWatcher();
		} catch (error) {
			useSystem().addLog('Error remove watcher, ' + JSON.stringify(error))
			return false;
		}
		return true;
	}

	checkPermissions(): boolean {
		BackgroundGeolocation.openSettings().catch(
			(error) => {
				this.log_error(error);
			}
		);

		return false;
	}
}
