import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {UserTrackingTemplate} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.type";
import {UserProfileGetResponse, UserTrackingTemplateGetResponse} from "@/mobipoints/api/profile";
import {
	MobiPointsProfileUserTrackingTrackTypeInterface
} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.interface";
import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {UserTrackingTrackType} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.type";
import {
	MobiPointsLocationCountryInterface,
	MobiPointsLocationTypeInterface,
	MobiPointsLocationTypes
} from "@/mobipoints/location/location_type.interface";
import useMainData from "@/composable/useMainData";
import {UserSettings} from "@/mobipoints/profile/userSettings/user_settings.type";
import {MobiPointsProfileUserSettingsInterface} from "@/mobipoints/profile/userSettings/user_settings.interface";
import {MobiPointsProfileUser} from "@/mobipoints/profile/userProfile/user_profile.type";
import {MobiPointsProfileUserInterface} from "@/mobipoints/profile/userProfile/user_profile.interface";

const mainData = useMainData();

export class ProfileFactory {

	createEmptyProfileUserLocation(): UserLocation {
		const type = mainData.getLocationTypeByCode(MobiPointsLocationTypes.HOME_LOCATION) as MobiPointsLocationTypeInterface;
		const country = { code: '', name: ''} as MobiPointsLocationCountryInterface;
		const userLocation = new UserLocation(null, type, '', 0, 0, country, "", "", "", "", "", []);

		userLocation.state = '';

		return userLocation;
	}

	createProfileUserLocationByApiResponse(data: MobiPointsProfileUserLocationInterface): UserLocation {
		const userLocation = this.createEmptyProfileUserLocation();
		userLocation.id = data.id;
		userLocation.city = data.city;
		userLocation.country = data.country;
		userLocation.latitude = data.latitude;
		userLocation.longitude = data.longitude;
		userLocation.name = data.name;
		userLocation.postalCode = data.postalCode;
		userLocation.province = data.province;
		userLocation.street = data.street;
		userLocation.streetNumber = data.streetNumber;
		userLocation.tags = data.tags ?? [];
		userLocation.state = data.state;
		userLocation.type = mainData.getLocationTypeByCode(data.type.code) || data.type;
		userLocation.isEditable = data.isEditable || false;

		return userLocation;
	}

	createProfileUserLocationListByApiResponse(response): UserLocation[] {
		return response.data.locations.user.map((userLocationData) => {
			return this.createProfileUserLocationByApiResponse(userLocationData);
		});
	}

	createEmptyUserTrackingTemplate(): UserTrackingTemplate {
		return new UserTrackingTemplate(null, '', null, this.createUserTrackingTrackType('', ''), this.createEmptyProfileUserLocation(), this.createEmptyProfileUserLocation());
	}

	createUserTrackingTrackType(code: string, name: string): UserTrackingTrackType {
		return new UserTrackingTrackType(code, name);
	}

	createUserTrackingTrackTypeByApiResponse(data: MobiPointsProfileUserTrackingTrackTypeInterface) {
		return this.createUserTrackingTrackType(data.code, data.name);
	}

	createUserTrackingTemplate(id: number | null, name: string, state: string | null = null, userTrackingTrackType: UserTrackingTrackType | null = null, startLocation: UserLocation | null = null, endLocation: UserLocation | null = null): UserTrackingTemplate {
		const userTrackingTemplate = this.createEmptyUserTrackingTemplate();
		userTrackingTemplate.id = id;
		userTrackingTemplate.name = name;
		userTrackingTemplate.state = state;
		userTrackingTemplate.userTrackingTrackType = userTrackingTrackType;
		userTrackingTemplate.startLocation = startLocation;
		userTrackingTemplate.endLocation = endLocation;

		return userTrackingTemplate;
	}

	createUserTrackingTemplateListByApiResponse(response: UserTrackingTemplateGetResponse): UserTrackingTemplate[] {
		const list: UserTrackingTemplate[] = [];

		response.payload.forEach((data) => {
			const startLocation = (data.startLocation && this.createProfileUserLocationByApiResponse(data.startLocation)) ?? this.createEmptyProfileUserLocation();
			const endLocation = (data.endLocation && this.createProfileUserLocationByApiResponse(data.endLocation)) ?? this.createEmptyProfileUserLocation();
			const trackType = (data.userTrackingTrackType && this.createUserTrackingTrackTypeByApiResponse(data.userTrackingTrackType)) ?? this.createUserTrackingTrackType('', '');

			list.push(this.createUserTrackingTemplate(data.id, data.name, data.state, trackType, startLocation, endLocation));
		});
		return list;
	}

	createUserSetting(data: MobiPointsProfileUserSettingsInterface): UserSettings {
		return new UserSettings(data.geoLocationInterval, data.enableHighAccuracy, data.trackingDistanceFilter, data.maxHomeDistanceTolerance);
	}

	createUserProfileByApiResponse(response: MobiPointsProfileUserInterface): MobiPointsProfileUser {
		if (response && response['profile']) {
			const responseProfileData = response['profile'];
			if (!responseProfileData['darkMode']) {
				responseProfileData['darkMode'] = false;
			}
			if (!responseProfileData['trackingAutoStop']) {
				responseProfileData['trackingAutoStop'] = false;
			}
			return new MobiPointsProfileUser(responseProfileData);
		} else {
			throw new Error('invalid API respons! Error: ' + JSON.stringify(response));
		}
	}
}
