<script lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'

export default function useUserRepository(){
	const store = useStore();

	const getFullName = computed(() => store.getters['user/fullName'])
	const getGivenName = computed(() => store.getters['user/getGivenName'])
	const getUserImage = computed(() => store.getters['user/getProfileImage'])
	const getUserEmail = computed(() => store.getters['user/getEmail'])
	const getUserAbbr = computed(() => store.getters['user/getUserAbbr'])

	function setUserName(name: string){
		store.commit('user/username', name)
	}

	function setGivenName(givenName: string){
		store.commit('user/givenName', givenName)
	}

	function setEmail(email: string){
		store.commit('user/email', email)
	}

	function setProfileImage(profileImage: string){
		store.commit('user/profileImage', profileImage)
	}

	return {getFullName, getGivenName, getUserImage, getUserEmail, setUserName, setGivenName, setEmail, setProfileImage, getUserAbbr}
}
</script>