import { createApp } from 'vue'
import App from './App.vue'

// import './registerServiceWorker';
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './assets/styles/index.css';


/* API */
import MobiPointsApiClient from "@/mobipoints/api/client";
import store from "@/store/index";

const app = createApp(App)
  .use(IonicVue, {innerHTMLTemplatesEnabled: true, swipeBackEnabled: true})
  .use(store)
  .use(router);

MobiPointsApiClient.init(process.env.VUE_APP_API);
MobiPointsApiClient.mount401Interceptor();

router.isReady().then(() => {
  app.mount('#app');
});
