<template>
	<ion-modal :is-open="true" :show-backdrop="true" :swipe-to-close="false" :keyboardClose="true" @ionModalDidDismiss="close"
			   @didDismiss="close" trigger="open-modal"
			   class="fullscreenModal" :backdrop-dismiss="false">
		<ion-header translucent>
			<ion-toolbar>
				<ion-title></ion-title>
				<ion-buttons slot="end">
					<ion-button @click="close">{{trackTypeCancel}}</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content fullscreen>
			<h1 style="text-align: center">{{trackTypeTitle}}</h1>
			<ion-grid class="adv-grid">
				<ion-row>
					<ion-col size="6" v-for="trackingTrackType in getFullTrackingTypeList()"
							 :key="trackingTrackType.code"
							 offset-4>
						<ion-card class="ion-activatable ripple-parent"
								  @click="selectTrackType(trackingTrackType.code)">
							<ion-card-header style="text-align: center">
								<ion-card-title>
									<ion-icon :icon="trackingTrackType.getTrackTypeIcon()"
											  class="overlay-track-icon-size"></ion-icon>
								</ion-card-title>
								<p>{{ trackingTrackType.name }}</p>
							</ion-card-header>
							<ion-ripple-effect></ion-ripple-effect>
						</ion-card>
					</ion-col>
				</ion-row>
			</ion-grid>
			<section class="overlay-footer">
				<ion-button size="large" expand="block" @click="close">{{trackTypeCancel}}</ion-button>
			</section>
		</ion-content>
	</ion-modal>
</template>

<script lang="ts">
import {ref, defineComponent} from "vue";
import {
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonRippleEffect,
	IonModal,
	IonButton,
	IonContent,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import useData from "@/composable/useData";

export default defineComponent({
	name: 'AdvancedTrackingOverlay',
	components: {
		IonIcon,
		IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonRippleEffect,
		IonModal,
		IonButton, IonContent,
	},
	props: {},
	emits: ['selectTrackType', 'closeOverlay'],
	setup(props, {emit}) {
		const {getTextValueByKey} = useData();
		const trackTypeTitle = ref(getTextValueByKey('advanced.tracking.overlay.title', [], 'Verkehrsmittel auswählen'))
		const trackTypeCancel = ref(getTextValueByKey('advanced.tracking.overlay.cancel', [], 'Abbrechen'))
		const selectedType = ref();
		const {
			getTrackingTypeAdvanced_Data_Map,
			getFullTrackingTypeList,
		} = useTracking();

		function selectTrackType(trackType: string) {
			selectedType.value = trackType;
			emit('selectTrackType', trackType);
			close();
		}

		function close() {
			emit('closeOverlay');
		}

		function handleBackdropClick(e) {
			if (e.target.tagName.toLowerCase() == 'ion-content' || e.target.tagName.toLowerCase() == 'ion-row' || e.target.tagName.toLowerCase() == 'ion-col') {
				close();
			}
		}

		return {
			close,
			selectedType,
			icons,
			handleBackdropClick,
			selectTrackType,
			getTrackingTypeAdvanced_Data_Map,
			getFullTrackingTypeList,
			trackTypeTitle,
			trackTypeCancel
		}
	}
})
</script>

<style scoped>
.back-drop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    --background: rgba(var(--ion-color-medium-rgb), .85);
    --padding-top: 4em;
    --padding-bottom: 4em;
    z-index: 9;
    backdrop-filter: blur(3px);
}

.ion-footer {
    position: absolute;
    bottom: 4em;
}

.back-drop ion-footer {
    position: absolute;
    bottom: 4em;
}

@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
        .back-drop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            --background: rgba(var(--ion-color-medium-rgb), .85);
            --padding-top: 1em;
            --padding-bottom: 0em;
            z-index: 9;
            backdrop-filter: blur(3px);
        }

        .back-drop ion-footer {
            position: absolute;
            bottom: 1em;
        }
    }
}

.adv-grid {
    overflow: auto;
    height: 100%;
    max-height: 78vh;
	padding-bottom: 3em;
}

.overlay-track-icon-size {
    font-size: 2.3em;
}

.fullscreenModal {
    --width: 100% !important;
    --height: 100% !important;
    --backdrop-opacity: rgba(var(--ion-color-medium-rgb), .85);
}

.overlay-footer {
    bottom: 0;
    height: auto;
    width: 100%;
    position: fixed;
    /*padding-bottom: 0;*/
    padding-top: 23px;
}
</style>