<template>
	<advanced-tracking-overlay v-show="showMoreTypes === true" @selectTrackType="startNewTracking"
							   @closeOverlay="toggleShowMoreTypes"></advanced-tracking-overlay>
	<div :class="[fabButtonClicked ? 'back-drop-tracking-button' : '']" @click="handleBackdropClick" ref="trackingButtonBackdrop">
		<div class="fixed">
			<ion-fab ref="fabButtonRef"
					 slot="fixed" activated="false" class="" edge horizontal="end"
					 vertical="bottom" @click="fabButtonClicked = !fabButtonClicked">
				<ion-fab-button class="trackFabButton" color="success">
					<ion-icon :icon="icons.add"></ion-icon>
				</ion-fab-button>

				<ion-fab-list side="start">
					<ion-fab-button v-for="trackingTrackType in getTrackingTypeMinimal_Data_Map()"
									:key="trackingTrackType.code" color="light" class="trackFabButton trackFabIcon"
									@click="startNewTracking(trackingTrackType.code)" :style="trackingTrackType.style">
						<ion-icon :icon="trackingTrackType.getTrackTypeIcon()" class="track-fab-icon-size"></ion-icon>
					</ion-fab-button>
				</ion-fab-list>
				<ion-fab-list side="top" v-if="showMoreTypes === true">
					<ion-fab-button class="trackFabButton trackFabIcon"
									@click="toggleShowMoreTypes">
						<ion-icon :icon="icons.arrowDown"></ion-icon>
					</ion-fab-button>
					<ion-fab-button v-for="trackingTrackType in getTrackingTypeAdvanced_Data_Map()"
									:key="trackingTrackType.code" class="trackFabButton trackFabIcon"
									@click="startNewTracking(trackingTrackType.code)" :style="trackingTrackType.style">
						<ion-icon :icon="trackingTrackType.getTrackTypeIcon()" class="track-fab-icon-size"></ion-icon>
					</ion-fab-button>
				</ion-fab-list>
				<ion-fab-list side="top" v-else>
					<ion-fab-button class="trackFabButton trackFabIcon"
									@click="toggleShowMoreTypes">
						<ion-icon :icon="icons.arrowUp"></ion-icon>
					</ion-fab-button>
				</ion-fab-list>
			</ion-fab>
		</div>
	</div>
</template>

<script lang="ts">
import {ref} from "vue";
import {IonFab, IonFabButton, IonFabList, IonIcon, loadingController} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useToastMessage from "@/components/core/ToastMessage.vue";
import {
	EVENT_TYPE_START_TRACK,
	EVENT_TYPE_START_TRACKING
} from "@/mobipoints/queue/event/abstract_event.type";
import useEvent from "@/composable/useEvent";
import useMainData from "@/composable/useMainData";
import useTracking from "@/composable/useTracking";
import useTrackingFactory from "@/composable/useTrackingFactory";
import AdvancedTrackingOverlay from "@/components/tracking/AdvancedTrackingOverlay.vue";
import useSystem from "@/composable/useSystem";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import useData from "@/composable/useData";

export default {
	name: 'TrackingButton',
	components: {AdvancedTrackingOverlay, IonFab, IonFabButton, IonFabList, IonIcon},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const selectedType = ref();
		const {openToast} = useToastMessage();
		const {getFirstUuid, removeUuid} = useMainData();
		const {addEventByType, addCurrentPositionEvent} = useEvent();
		const {getTrackingFactory} = useTrackingFactory();
		const {
			createTrackingAndSetAsActiveTracking,
			hasActiveTracking,
			addTrackToCurrentTracking,
			resetCurrentTracking,
			startBackgroundGeolocation,
			getTrackingType_Data_Map,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			checkGeolocationPermissions,
			setCurrentPosition,
			jumpToActiveCard
		} = useTracking();
		const {getTextValueByKey} = useData();
		const {isIosPlatform} = useSystem();

		const fabButtonClicked = ref(false);
		const trackingButtonBackdrop: any | HTMLDivElement = ref(null);
		const fabButtonRef: any = ref(null);
		const showMoreTypes = ref(false);

		function handleBackdropClick(e) {
			if (e.target.classList.contains('back-drop-tracking-button')) {
				fabButtonClicked.value = !fabButtonClicked.value;
				if (!fabButtonClicked.value) {
					fabButtonRef.value.$el.close();
				}
			}
		}

		async function sendStartEvent() {
			let firstUuid;
			try {
				if (!await checkGeolocationPermissions()) {
					return;
				}
				firstUuid = await getFirstUuid();
				createTrackingAndSetAsActiveTracking(firstUuid);
				const track = getTrackingFactory().createTrackByType(firstUuid, selectedType.value, selectedType.value + " - Tracking");
				addTrackToCurrentTracking(track);
				addEventByType(EVENT_TYPE_START_TRACKING, selectedType.value, firstUuid);
				addEventByType(EVENT_TYPE_START_TRACK, selectedType.value, firstUuid);
				if (!useSystem().isNativePlatform() || useData().getSystemValueByKey('feature_flag_tracking_start_send_first_geolocation', true)) {
					const loading = await loadingController
						.create({
							cssClass: 'my-custom-class',
							message: useData().getTextValueByKey('tracking.start.locate.first.gps.position', [], 'Bitte warte kurz, wir versuchen deine aktuelle Position zu ermitteln.📍'),
						});
					try {
						await loading.present();
						const currentPos: MobiPointsTrackingCoordinateCoordinateInterface|undefined = await getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition();
						if (currentPos && currentPos.longitude && currentPos.latitude) {
							setCurrentPosition(currentPos);
						} else {
							addCurrentPositionEvent(firstUuid, "", 0, true);
						}
					} catch (e) {
						console.error(e);
						useSystem().addLog(JSON.stringify(e));
					} finally {
						await loading.dismiss();
					}
				}
				await startBackgroundGeolocation();
				jumpToActiveCard();
			} catch (error) {
				console.error(error);
				openToast('Error! ' + error, 'danger', 'top', true, 5000);
				await resetCurrentTracking()
			} finally {
				if (firstUuid) {
					removeUuid(firstUuid);
				}
			}
		}

		function startNewTracking(type: string) {
			if (hasActiveTracking.value) {
				openToast(getTextValueByKey('tracking.start_new_tracking.tracking_already_started', [], 'Bitte stoppe zuerst dein aktives Tracking bevor du mit einen neuen beginnst!'), 'danger', 'top', true, 5000);
				return;
			} else {
				selectedType.value = type;
				sendStartEvent();
			}
		}

		function toggleShowMoreTypes() {
			showMoreTypes.value = !showMoreTypes.value;
		}

		return {
			close,
			selectedType,
			startNewTracking,
			hasActiveTracking,
			fabButtonClicked,
			trackingButtonBackdrop,
			handleBackdropClick,
			fabButtonRef,
			icons,
			showMoreTypes,
			toggleShowMoreTypes,
			getTrackingType_Data_Map,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			isIosPlatform
		}
	}
}
</script>

<style scoped>
.back-drop-tracking-button {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 9;
    backdrop-filter: blur(1em);
    background: rgba(var(--ion-color-dark-rgb), .15);
}

.fixed {
    position: fixed !important;
    bottom: 6em;
    right: .5em;
    z-index: 2;
}

.trackFabButton {
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    box-shadow: 0 0 .35em .175em rgba(var(--ion-color-dark-rgb), .33);
}

.trackFabIcon {
    padding: 0;
}
</style>
