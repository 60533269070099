import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {MobiPointsDataInterface} from "@/mobipoints/data/data/data.interface";

interface DataGetResponse extends MobiPointsApiGetResponse {
	payload: Array<MobiPointsDataInterface>;
}

class DataResponseError extends ResponseError {
}

const MobiPointsApiData = {
	getData: async function (): Promise<AxiosResponse<DataGetResponse>> {
		return MobiPointsApiClient.get('/data');
	},
}

export {MobiPointsApiData, DataGetResponse, DataResponseError};