import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {MobiPointsTrackingCoordinateCoordinateList} from "@/mobipoints/tracking/coordinate/coordinate_list.type";
import {
	MobiPointsTrackingTrackAbstractTrack,
	TRACK_STATE_DONE,
	TRACK_STATE_PAUSE
} from "@/mobipoints/tracking/track/abstract_track.type";

export class DistanceCalculation {

	public headquarterSaazCoordinate: MobiPointsTrackingCoordinateCoordinateInterface = {
		latitude: 46.957230,
		longitude: 15.850660,
		timestamp: 0,
		locationSimulated: false,
	};
	public officeGrazCoordinate: MobiPointsTrackingCoordinateCoordinateInterface = {
		latitude: 47.070862,
		longitude: 15.428080,
		timestamp: 0,
		locationSimulated: false,
	};

	public calculateDistance(startCoordinates: MobiPointsTrackingCoordinateCoordinateInterface, destinationCoordinates: MobiPointsTrackingCoordinateCoordinateInterface, resultInMeters = false) {
		if (startCoordinates.latitude == destinationCoordinates.latitude && startCoordinates.longitude == destinationCoordinates.longitude) {
			return 0;
		}

		const radLat1 = (Math.PI * startCoordinates.latitude) / 180;
		const radLat2 = (Math.PI * destinationCoordinates.latitude) / 180;

		const theta = startCoordinates.longitude - destinationCoordinates.longitude;
		const radTheta = (Math.PI * theta) / 180;

		let dist =
			Math.sin(radLat1) * Math.sin(radLat2) +
			Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

		if (dist > 1) {
			dist = 1;
		}

		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		dist = dist * 1.609344; //convert miles to km

		if (resultInMeters) {
			dist = dist * 1000;
		}
		return dist;
	}

	public isCoordinateCloseToPosition(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, positionToCheck: MobiPointsTrackingCoordinateCoordinateInterface, maxDistanceInMeter: number): boolean {
		const distanceInKm = this.calculateDistance(coordinate, positionToCheck);
		const distanceInMeter = distanceInKm * 1000;
		return distanceInMeter <= maxDistanceInMeter;
	}

	public getTotalDistance_for_CoordinatesList(coordinateList: MobiPointsTrackingCoordinateCoordinateList, lastTrack: MobiPointsTrackingTrackAbstractTrack|any = null): string
	{
		const coordinates = coordinateList.getItems().filter((coordinate) => {
			if (coordinate.latitude && coordinate.longitude) {
				return true;
			}
		});

		let totalDistance = 0;

		if (!coordinates) {
			return '0';
		}

		if (coordinates.length < 2) {
			if (lastTrack && (lastTrack.state === TRACK_STATE_PAUSE || lastTrack.state === TRACK_STATE_DONE) ) {
				const last_coordinates_for_Track = lastTrack.getCoordinates();
				if (last_coordinates_for_Track && last_coordinates_for_Track.getItems().length > 0) {
					const lastCoordinate = last_coordinates_for_Track.getItem(last_coordinates_for_Track.getItems().length-1);
					coordinates.unshift(lastCoordinate);
				} else {
					return '0';
				}
			} else {
				return '0';
			}
		}

		for (let i = 0; i < coordinates.length; i++) {
			const startCoordinate = coordinates[i] ?? null;
			const endCoordinate = coordinates[i + 1] ?? null;

			if (startCoordinate && endCoordinate) {
				if (startCoordinate.latitude !== endCoordinate.latitude || startCoordinate.longitude !== endCoordinate.longitude) {
					totalDistance += this.calculateDistance(startCoordinate, endCoordinate);
				}
			}
		}

		return totalDistance.toFixed(2);
	}

}