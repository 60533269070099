import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_google_map = _resolveComponent("google-map")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_back_button),
    _createVNode(_component_ion_buttons, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_button, {
          "item-start": "",
          color: "danger",
          expand: "block",
          shape: "round",
          onClick: $setup.getCurrentPosition
        }, {
          default: _withCtx(() => [
            _createTextVNode("Wo bin ich?")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ion_button, {
          slot: "end",
          "item-end": "",
          color: "danger",
          expand: "block",
          shape: "round",
          onClick: $setup.createTrackRouteByCoordinates
        }, {
          default: _withCtx(() => [
            _createTextVNode("Track Route erstellen")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_google_map, {
      "api-key": $setup.googleMapsKey,
      style: {"width":"100%","height":"100%"},
      center: $setup.centerLatLng,
      zoom: 15
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.markers, (marker) => {
          return (_openBlock(), _createBlock(_component_Marker, {
            key: marker,
            options: marker.options
          }, null, 8, ["options"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["api-key", "center"])
  ], 64))
}