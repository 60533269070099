import {WalletList} from '@/mobipoints/wallet/wallet_list.type';
import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';
import useWalletFactory from '@/composable/useWalletFactory';
import {
	WalletMobiPoints
} from '@/mobipoints/wallet/mobi_points/wallet_mobi_points.type';
import {
	WalletCarbonEmissions
} from '@/mobipoints/wallet/carbon_emissions/wallet_carbon_emissions.type';
import {
	WalletTreasures
} from '@/mobipoints/wallet/treasures/wallet_treasures.type';

export class WalletFactory {
	/**
	 *
	 * @param wallets
	 */
	createWalletListByResponse(wallets: Record<string, any>): WalletList {
		const walletList = this.createEmptyWalletList();

		for (const [key, item] of Object.entries(wallets)) {
			walletList.push(this.createWalletByResponse(item));
		}

		return walletList;
	}

	/**
	 *
	 * @param item
	 */
	createWalletByResponse(item: Record<string, any>): AbstractWallet {
		const uuid = item.uuid;
		const user_reference = item.user_reference;
		const type = item.type;
		const state = item.state;
		const points = item.points ?? undefined;

		let walletItem_List: WalletItemList | undefined;
		if (item.items && item.items.length > 0) {
			walletItem_List = useWalletFactory().getWalletItemFactory().createWalletItemListByResponse(item.items, type);
		}

		let wallet;
		switch (type) {
			case WalletType.MOBI_POINTS:
				wallet = new WalletMobiPoints(uuid, state, type, user_reference, walletItem_List, points);
				break;
			case WalletType.CARBON_EMISSIONS:
				wallet = new WalletCarbonEmissions(uuid, state, type, user_reference, walletItem_List, points);
				break;
			case WalletType.TREASURES:
				wallet = new WalletTreasures(uuid, state, type, user_reference, walletItem_List, points);
				break;
			default:
				throw new Error('Invalid Wallet: ' + JSON.stringify(item));
		}

		return wallet;
	}

	createEmptyWalletList(): WalletList {
		return new WalletList([]);
	}
}