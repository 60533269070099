interface User {
	username: string;
	givenName?: string;
	email: string;
	profileImage: string;
	token: string;
}

const user = {
	namespaced: true,
	state: () => ({
		userData: {} as () => User,
		foo: String,
	}),
	mutations: {
		username(state: any, name: string) {
			state.userData.username = name;
		},
		givenName(state: any, givenName: string) {
			state.userData.givenName = givenName;
		},
		email(state: any, email: string) {
			state.userData.email = email;
		},
		profileImage(state: any, profileImage: string) {
			state.userData.profileImage = profileImage;
		},
		token(state: any, token: string) {
			state.userData.token = token;
		},
		initUser(state: any, data: any) {
			state.userData.username = data.name;
			state.userData.email = data.email;
			state.userData.profileImage = data.profileImage;
			// state.userData.token = data.token;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		fullName: (state: any) => {
			let result = state.userData.username ?? "";
			if (!result || result.length === 0) {
				if (state.userData.givenName) {
					result = state.userData.givenName;
				} else {
					result = '';
				}
			}

			return result;
		},

		// eslint-disable-next-line
		getEmail: (state: any) => {
			return state.userData.email;
		},

		// eslint-disable-next-line
		getGivenName: (state: any) => {
			return state.userData.givenName;
		},

		// eslint-disable-next-line
		getProfileImage: (state: any) => {
			return state.userData.profileImage;
		},

		// eslint-disable-next-line
		getUserAbbr: (state: any) => {
			try {
				let name = state.userData.username;
				if (!name) {
					name = state.userData.givenName;
				}
				let nameAbbr = '';
				const nameAbbrList = name.split(" ").map((n)=>n[0]).join("");
				if (nameAbbrList.length > 1) {
					nameAbbr = nameAbbrList.substring(0, 1) + nameAbbrList.slice(nameAbbrList.length -1);
				} else {
					nameAbbr = nameAbbrList;
				}
				return nameAbbr;
			} catch (error) {
				return "";
			}
		},

		// eslint-disable-next-line
		getToken: (state: any) => {
			return state.userData.token;
		},

		getUserByToken: (state: any) => (token: string) => {
			if(state.userData.token === token){
				return state.userData;
			}
		}
	}
}

export default user;