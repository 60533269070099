<template>
	<div v-if="hasActiveTracking">
		<add-current-position-to-user-location-dialog :coordinate-to-check="coordinateToCheck"
													  :show-dialog="showAddCurrentPositionToUserLocationDialog"
													  @locationAdded="stopActiveTracking($event, false)"
													  @cancel="stopActiveTracking($event, true)"
													  @locationAddError="stopActiveTracking($event, true)"></add-current-position-to-user-location-dialog>
		<advanced-tracking-overlay v-show="showMoreTypes === true" @selectTrackType="triggerTrack"
								   @closeOverlay="toggleShowMoreTypes"></advanced-tracking-overlay>
		<h1 class="ion-padding activeTrackingTitle">Aktuelles Tracking</h1>
		<ion-card class="activeTrackingCard">
			<div :class="[changeTrackButtonClicked ? 'back-drop-active-tracking' : '']"
				 @click="handleBackdropClick"></div>
			<WeatherIcon :load-current-weather="false" :weather="currentTracking.weatherItem"
						 :use-current-tracking-latest-position="false"></WeatherIcon>
			<disabled-map :disableMarkerUpdateEvent="true" :zoom="15" :street-view-control="false"
						  :init-cords="{lat: getCurrentPosition.latitude, lng: getCurrentPosition.longitude}"
						  :show-path="true"
						  height="14em"></disabled-map>

			<ion-card-header class="activeTrackingCardHeader">
				<ion-grid>
					<ion-row>
						<ion-col size="4.5">
							<ion-card-title class="activeTrackingCardHeaderTitle">{{ trackingName }}</ion-card-title>
						</ion-col>
						<ion-col>
							<div class="activeTrackingCardHeaderSubTitle">
								<div style="min-width: 60%;float: left">
									<ion-icon :icon="icons.location"/>
									<tracking-distance></tracking-distance>
									<span style="padding-left: 5px; padding-right: 5px">|</span>
								</div>
								<div style="min-width: 40%; float: left">
									<ion-icon style="float: left" :icon="icons.timeOutline"/>
									<div style="padding-left: 15px;">
										<tracking-duration></tracking-duration>
									</div>
								</div>
							</div>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-card-header>
			<ion-card-content class="activeTrackingCardHeader">
				<ion-grid>
					<ion-row>
						<ion-col size="4">
							<ion-card-subtitle>
								<ion-icon :icon="icons.location"/>
								<span v-if="startAddress && startAddress !== null">{{ startAddress.city }}</span>
								<span v-else>Unbekannt</span>
							</ion-card-subtitle>
							<ion-card-subtitle>
								<ion-icon :icon="currentTrackIcon"/>
								{{ currentTrackingStateName }}
							</ion-card-subtitle>
						</ion-col>
						<ion-col style="padding-top: 15px">
							<ion-grid>
								<ion-row>
									<ion-col>
										<ion-fab v-if="hasActiveTrack"
												 :class="['buttonsPosition', changeTrackButtonClicked ? 'showMoreTrackingTypeZIndexVisible' : 'showMoreTrackingTypeZIndexHidden']"
												 activated="false" edge
												 horizontal="end" vertical="bottom" ref="fabButtonChangeTrackRef"
												 @click="changeTrackButtonClicked = !changeTrackButtonClicked">
											<ion-fab-button
													:class="['activeTrackingButton',  duration.toString().length > 5 ? 'smallFont' : '']"
													color="success">
												<ion-icon style="font-size: 50px;"
														  :icon="currentTrackIcon"></ion-icon>
											</ion-fab-button>
											<ion-fab-list side="start" class="ion-padding-end">
												<ion-fab-button
														v-for="trackingTrackType in getTrackingTypeMinimal_Data_Map()"
														:key="trackingTrackType.code"
														class="trackFabButton trackFabIcon activeTrackingButton"
														@click="triggerTrack(trackingTrackType.code)"
														:style="trackingTrackType.style">
													<ion-icon :icon="trackingTrackType.getTrackTypeIcon()"
															  class="track-fab-icon-size-sm"></ion-icon>
												</ion-fab-button>
											</ion-fab-list>
											<ion-fab-list side="top">
												<ion-fab-button
														class="activeTrackingButton moreTrackingTypeButtonPadding"
														@click="toggleShowMoreTypes">
													<ion-icon :icon="icons.arrowUp"></ion-icon>
												</ion-fab-button>
											</ion-fab-list>
										</ion-fab>

										<ion-fab v-if="!hasActiveTrack" activated="false" edge
												 :class="['buttonsPosition', changeTrackButtonClicked ? 'showMoreTrackingTypeZIndexVisible' : 'showMoreTrackingTypeZIndexHidden']"
												 horizontal="end" vertical="bottom" ref="fabButtonChangeTrackRef"
												 @click="changeTrackButtonClicked = !changeTrackButtonClicked">
											<ion-fab-button
													:class="['continueTrackingButton activeTrackingButton',  duration.toString().length > 5 ? 'smallFont' : '']"
													color="success">
												<ion-icon style="font-size: 50px;padding-left: 5px"
														  :icon="icons.playOutline"></ion-icon>
											</ion-fab-button>
											<ion-fab-list side="start">
												<ion-fab-button
														:key="currentTrackingTrackType"
														class="trackFabButton trackFabIcon activeTrackingButton continueWithCurrentTrackType"
														@click="continueTracking">
													<ion-item>
														<ion-icon size="large" :icon="currentTrackIcon"
																  class=""></ion-icon>
														<ion-label
																class="ion-text-wrap continueCurrentTrackTypeFontSize"
																style="margin-left: .5rem;">
															{{ getCurrentTrackingTrackTypeText() }}
														</ion-label>
													</ion-item>
													<ion-ripple-effect></ion-ripple-effect>
												</ion-fab-button>
											</ion-fab-list>
											<ion-fab-list side="top">
												<ion-fab-button
														class="activeTrackingButton moreTrackingTypeButtonPadding"
														@click="toggleShowMoreTypes">
													<ion-icon :icon="icons.arrowUp"></ion-icon>
												</ion-fab-button>
											</ion-fab-list>
										</ion-fab>
										<ion-fab-button v-if="hasActiveTrack" class="stopActiveTrackingButton"
														color="danger" @click="stopActiveTracking($event, false)">
											<ion-icon :icon="icons.stop"></ion-icon>
										</ion-fab-button>
										<ion-fab-button v-if="hasActiveTrack" class="pauseActiveTrackingButton"
														color="warning" @click="pauseActiveTracking">
											<ion-icon :icon="icons.pause"></ion-icon>
										</ion-fab-button>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-card-content>
		</ion-card>
	</div>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonIcon,
  IonRow,
  IonRippleEffect,
  IonItem,
  IonLabel, loadingController, alertController, IonicSafeString,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import useToastMessage from "@/components/core/ToastMessage.vue";
import DisabledMap from "@/components/map/DisabledMap.vue";
import TrackingDuration from "@/components/tracking/TrackingDuration.vue";
import TrackingDistance from "@/components/tracking/TrackingDistance.vue";
import AdvancedTrackingOverlay from "@/components/tracking/AdvancedTrackingOverlay.vue";
import useTrackingFactory from "@/composable/useTrackingFactory";
import useData from "@/composable/useData";
import AddCurrentPositionToUserLocationDialog
	from "@/components/profile/userLocation/AddCurrentPositionToUserLocationDialog.vue";
import useGeoCodingFactory from "@/composable/useGeoCodingFactory";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import useMainData from "@/composable/useMainData";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {TRACK_STATE_DONE, TRACK_STATE_PAUSE} from "@/mobipoints/tracking/track/abstract_track.type";
import WeatherIcon from "@/components/weather/WeatherIcon.vue";
import useEvent from "@/composable/useEvent";
import {EVENT_TYPE_END_TRACK} from "@/mobipoints/queue/event/abstract_event.type";
import store from "@/store";
import useSystem from "@/composable/useSystem";
import useUserProfile from "@/composable/useUserProfile";

export default {
	name: 'ActiveTrackingCard',
	components: {
		WeatherIcon,
		AddCurrentPositionToUserLocationDialog,
		AdvancedTrackingOverlay,
		TrackingDistance,
		DisabledMap,
		TrackingDuration,
		IonCardContent,
		IonCard,
		IonCardHeader,
		IonIcon,
		IonCardTitle,
		IonCardSubtitle,
		IonFab,
		IonFabButton,
		IonFabList,
		IonGrid,
		IonRow,
		IonCol,
		IonRippleEffect,
		IonItem,
		IonLabel,
	},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const {openToast} = useToastMessage();
		const {
			hasActiveTracking,
			hasActiveTrack,
			currentTracking,
			getLatestTrack,
			stopCurrentTracking,
			pauseCurrentTracking,
			startCurrentTrackingTrackBy_TrackType,
			continueCurrentTracking,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			getTrackIconByType,
			getCurrentTrackingType,
			getCurrentTrackingSubType,
			getCurrentPosition,
			getCurrentTrackingTrackType,
			getDistance,
			startAddress,
			jumpToLastActiveCard
		} = useTracking();
		const {getTrackingFactory} = useTrackingFactory();
		const {getTextValueByKey} = useData();
    const {isTrackingStopConfirm} = useUserProfile();

    const changeTrackButtonClicked = ref(false);
		const duration = ref("00:00");
		const distance = ref(0.0);
		const trackingName = ref("Aktives Tracking");
		const trackingState = ref("");
		const fabButtonChangeTrackRef: any = ref(null);
		const showMoreTypes = ref(false);
		const showAddCurrentPositionToUserLocationDialog = ref(false);
		const coordinateToCheck: any | null | MobiPointsTrackingCoordinateCoordinateInterface = ref(null);

		const currentTrackingStateName = ref("Aktiv");
		const currentTrackIcon: any = ref(currentTracking.value ? currentTracking.value.getLatestTrackingIcon() : '');
		const currentTrackingTrackType: any = ref(currentTracking.value ? currentTracking.value.getLatestTrack()?.type : '');

		function setCurrentTrackingStateName(stateName = 'Aktiv') {
			currentTrackingStateName.value = stateName;
		}

		function getCurrentTrackingTrackTypeText(): string {
			return getTextValueByKey('activeTracking.pause.continue.type.' + currentTrackingTrackType.value + '.title', [], 'Tracking fortsetzen');
		}

		async function triggerTrack(trackType: string) {
			if (trackType) {
				let isAllowedToPauseTracking = true;
				try {
					isAllowedToPauseTracking = getLatestTrack.value?.state !== TRACK_STATE_PAUSE;
				} catch (e) {
					//
				}
				const loading = await loadingController
					.create({
						cssClass: '',
						message: useData().getTextValueByKey('change_tracking.transport.loading.text', [], 'Dein Verkehrsmittel wird gewechselt...'),
					});
				try {
					setTimeout(function() {
						loading.dismiss()
					}, 10000);
					await loading.present();
					if (hasActiveTracking.value && isAllowedToPauseTracking) {
						try {
							if (await pauseCurrentTracking(false, TRACK_STATE_DONE)) {
								currentTrackIcon.value = getTrackIconByType(trackType);
								currentTrackingTrackType.value = trackType;
								await startTrack(trackType);
							}
						} catch (error) {
							useSystem().addLogByException(error);
							await openToast(getTextValueByKey('tracking.trigger.track.error', [], 'Fehler beim wechseln des Track Type'), 'danger', 'bottom', true, 5000);
						}
					} else {
						try {
							currentTrackIcon.value = getTrackIconByType(trackType);
							currentTrackingTrackType.value = trackType;
							setCurrentTrackingStateName();
							await continueCurrentTracking(trackType);
						} catch (error) {
							useSystem().addLogByException(error);
							await openToast('Error pause current tracking!', 'danger', 'bottom', true, 5000);
						}
					}
				} catch (e) {
					useSystem().addLogByException(e);
					throw e;
				} finally {
					await loading.dismiss();
				}
			}
		}

		async function startTrack(trackType: string) {
			if (hasActiveTracking.value) {
				try {
					setCurrentTrackingStateName();
					await startCurrentTrackingTrackBy_TrackType(trackType, true);
				} catch (error) {
					console.log(error);
					await openToast(getTextValueByKey('tracking.start.error', [], 'Fehler, Tracking konnte nicht gestartet werden'), 'danger', 'top', true, 5000);
				}
			}
		}

		function checkIfHomeLocationIsInDistance(): boolean {
			// eslint-disable-next-line
			let isStartAddressCloseToCompanyLocation = false;
			let isStartAddressCloseToHomeLocation = false;
			const maxDistanceInMeter = Number(useData().getSystemValueByKey('home_max_tolerance_for_distance', useMainData().userProfileSettings.value.maxHomeDistanceTolerance ?? 250));
			coordinateToCheck.value = null;
			const startCoordinate = currentTracking.value?.getFirstCoordinate();
			if (startCoordinate && startCoordinate?.latitude && startCoordinate?.longitude) {
				isStartAddressCloseToCompanyLocation = startCoordinate && useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(startCoordinate, [MobiPointsLocationTypes.COMPANY_LOCATION], maxDistanceInMeter);
				isStartAddressCloseToHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(startCoordinate, [MobiPointsLocationTypes.HOME_LOCATION], maxDistanceInMeter);
				if (!isStartAddressCloseToCompanyLocation && !isStartAddressCloseToHomeLocation) {
					coordinateToCheck.value = startCoordinate;
					return false;
				} else if (isStartAddressCloseToHomeLocation) {
					return true;
				}
			}
			if (isStartAddressCloseToCompanyLocation || isStartAddressCloseToHomeLocation) {
				const isCurrentLocationCloseToCompanyLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(getCurrentPosition.value, [MobiPointsLocationTypes.COMPANY_LOCATION], maxDistanceInMeter);
				if (!isCurrentLocationCloseToCompanyLocation) {
					const isCurrentLocationCloseToHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(getCurrentPosition.value, [MobiPointsLocationTypes.HOME_LOCATION], maxDistanceInMeter);
					if (isCurrentLocationCloseToHomeLocation) {
						coordinateToCheck.value = getCurrentPosition.value;
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}
			}
			return true;
		}

		function showAddCurrentPositionToUserHomeLocation() {
			showAddCurrentPositionToUserLocationDialog.value = true;
		}

		async function stopActiveTracking(event = null, ignoreHomeLocationCheck = false) {
			if (hasActiveTracking.value) {
				try {
					//TODO Maximal 4 Wohnadressen - Config
					//TODO Meldung
					showAddCurrentPositionToUserLocationDialog.value = false;
					if (!ignoreHomeLocationCheck) {
						if (!checkIfHomeLocationIsInDistance()) {
							showAddCurrentPositionToUserHomeLocation();
							return false;
						}
					}

          if (isTrackingStopConfirm.value) {
            const alert = await alertController.create({
              header: getTextValueByKey('tracking.stopped.confirm.title', [], 'Tracking stoppen?'),
              message: new IonicSafeString(getTextValueByKey('tracking.stopped.confirm.message', [], `<span class="text-lg">Möchtest du das Tracking wirklich stoppen? 📍</span>`)).value,
              buttons: [
                {
                  text: new IonicSafeString(getTextValueByKey('tracking.stopped.confirm.cancel', [], `Abbrechen`)).value,
                  role: 'cancel',
                  cssClass: 'tracking-stop-confirm-cancel-btn text-sm',
                },
                {
                  text: new IonicSafeString(getTextValueByKey('tracking.stopped.confirm.success', [], `Tracking stoppen ✅`)).value,
                  role: 'confirm',
                  cssClass: 'tracking-stop-confirm-confirm-btn text-xxl font-bold',
                  handler: async function () {
                    await stopCurrentTracking();
                    openToast(getTextValueByKey('tracking.stopped.successfully', [], 'Tracking wurde eroflgreich beendet!'), 'success', 'bottom', true, 5000, undefined, true);
                    jumpToLastActiveCard();
                  }
                }
              ]
            });
            alert.present();
          } else {
            await stopCurrentTracking();
            await openToast(getTextValueByKey('tracking.stopped.successfully', [], 'Tracking wurde eroflgreich beendet!'), 'success', 'bottom', true, 5000, undefined, true);
            jumpToLastActiveCard();
          }
				} catch (error) {
					console.log(error);
					openToast(getTextValueByKey('tracking.stopped.error', [], 'Fehler, Tracking konnte nicht beendet werden'), 'danger', 'bottom', true, 5000, undefined, true);
				}
			}
		}

		function pauseActiveTracking() {
			if (hasActiveTracking.value) {
				try {
					setCurrentTrackingStateName('Pausiert');
					pauseCurrentTracking();
					openToast(getTextValueByKey('tracking.pause.successfully', [], 'Tracking wurde pausiert'), 'success', 'bottom', true, 5000);
				} catch (error) {
					console.log(error);
					openToast(getTextValueByKey('tracking.pause.error', [], 'Fehler, Tracking konnte nicht pausiert werden'), 'danger', 'bottom', true, 5000);
				}
			}
		}

		function continueTracking() {
			if (!hasActiveTrack.value) {
				try {
					setCurrentTrackingStateName();
					continueCurrentTracking();
				} catch (error) {
					console.log(error);
					openToast('Error pause current tracking!', 'danger', 'bottom', true, 5000);
				}
			}
		}

		function toggleShowMoreTypes() {
			showMoreTypes.value = !showMoreTypes.value;
		}

		function handleBackdropClick(e) {
			if (e.target.classList.contains('back-drop-active-tracking')) {
				changeTrackButtonClicked.value = !changeTrackButtonClicked.value;
				if (!changeTrackButtonClicked.value && fabButtonChangeTrackRef.value) {
					fabButtonChangeTrackRef.value.$el.close();
				}
			}
		}

		onMounted(() => {
			currentTrackIcon.value = getTrackingFactory().getTrackingIconByType(currentTrackingTrackType.value);
		});

		return {
			icons,
			duration,
			distance,
			hasActiveTracking,
			hasActiveTrack,
			trackingName,
			triggerTrack,
			stopActiveTracking,
			continueTracking,
			pauseActiveTracking,
			trackingState,
			currentTracking,
			currentTrackingStateName,
			changeTrackButtonClicked,
			fabButtonChangeTrackRef,
			handleBackdropClick,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			toggleShowMoreTypes,
			showMoreTypes,
			currentTrackIcon,
			getCurrentTrackingType,
			getCurrentTrackingSubType,
			currentTrackingTrackType,
			getCurrentPosition,
			getCurrentTrackingTrackType,
			getDistance,
			startAddress,
			showAddCurrentPositionToUserLocationDialog,
			coordinateToCheck,
			getCurrentTrackingTrackTypeText
		}
	}
}
</script>

<style scoped>
.triggerTrackButton {
    height: 3rem;
    width: auto;
    font-size: 0.9rem;
}

ion-card-subtitle {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}

.activeTrackingCardHeaderTitle {
    font-size: 13px;
    line-height: 18px;
}

.activeTrackingCardHeaderSubTitle {
    font-size: 15px;
    font-weight: normal;
    text-align: right;
    line-height: 18px;
}

.buttonsPosition {
    position: relative;
    float: right;
    padding-left: 7px;
    padding-right: 0;
    bottom: 10px;
    right: 0;
}

.activeTrackingButton {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    box-shadow: -2px 3px 19px 5px rgba(62, 100, 255, 0.3);
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.continueTrackingButton {
    float: right;
}

.stopActiveTrackingButton {
    float: right;
    margin-left: 7px;
}

.pauseActiveTrackingButton {
    float: right;
}

.changeTrackButton {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    box-shadow: -2px 3px 19px 5px rgba(62, 100, 255, 0.3);
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}


.smallFont {
    font-size: 16px;
}

.activeTrackingCard {
    min-height: 22em;
}

.activeTrackingCardHeader {
    padding: 0;
}

.activeTrackingTitle {
    padding-bottom: 0;
}

.back-drop-active-tracking {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(var(--ion-color-medium-rgb), .85);
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 9;
    backdrop-filter: blur(3px);
}

.moreTrackingTypeButtonPadding {
    margin-bottom: 0.6em;
}

.continueWithCurrentTrackType {
    width: 14.5em;
    --border-radius: 11px;
    padding-right: 0.4em;
    font-size: 14px;
    box-shadow: 0px 0px 0px 0px rgb(62 100 255 / 30%);
}

.showMoreTrackingTypeZIndexVisible {
    z-index: 10;
}

.showMoreTrackingTypeZIndexHidden {
    z-index: 8;
}

.continueCurrentTrackTypeFontSize {
    font-size: 0.8em;
}

.tracking-stop-confirm-cancel-btn {
  @apply bg-gray-700;
}

.tracking-stop-confirm-confirm-btn {
  border-radius: 4px;
  background-color: var(--ion-color-success);
}
</style>
