import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

import Tabs from '../views/Tabs.vue'
import Login from '../views/Login.vue';
import Register from "@/views/Register.vue";

// import guest from './middleware/guest'
// import auth from './middleware/auth'
import authMiddleware from './middleware/auth';
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store";
import Terms from "@/views/Company/Terms.vue";
import Impress from "@/views/Company/Impress.vue";
import VerifyAccount from "@/views/Auth/VerifyAccount.vue";
import Logout from "@/views/Logout.vue";
import SetNewPassword from "@/views/Auth/SetNewPassword.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import RedeemVoucher from "@/views/Voucher/RedeemVoucher.vue";
import MobiPointsWallet from '@/views/Wallet/MobiPointsWallet.vue';
import Tutorial from '@/views/Tutorial.vue';
import UserTrackingTemplate from '@/views/Profile/UserTrackingTemplate.vue';
import UserLocation from '@/views/Profile/UserLocation.vue';
import AddUserLocation
  from '@/components/profile/userLocation/AddUserLocation.vue';
import DetailUserLocation
  from '@/components/profile/userLocation/DetailUserLocation.vue';
import HomeTab from '@/views/Tabs/HomeTab.vue';
import DetailTab from '@/views/Tabs/DetailTab.vue';
import Co2Tab from '@/views/Tabs/Co2Tab.vue';
import TreasureHuntTab from '@/views/Tabs/TreasureHuntTab.vue';
import ProfileTab from '@/views/Tabs/ProfileTab.vue';
import UserTrackingListTab from '@/views/Tabs/UserTrackingListTab.vue';
import ChallengesTab from '@/views/Tabs/ChallengesTab.vue';
import ChallengeDetail from '@/components/challenge/ChallengeDetail.vue';
import RedeemPointsTab from '@/views/Tabs/RedeemPointsTab.vue';
import Landing from '@/views/Landing.vue';
import Privacy from "@/views/Company/Privacy.vue";
import PrivacyNew from '@/views/Company/PrivacyNew.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/earth',
    name: 'earth',
    component: Landing,
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: Tutorial,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/user/email/verify',
    name: 'Account verifizieren',
    component: VerifyAccount,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },

  {
    path: '/user/password/reset',
    name: 'Passwort zurücksetzen',
    component: SetNewPassword,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/privacy-crudafoch',
    name: 'Datenschutz',
    component: {
      beforeRouteEnter(to, from, next) {
        const url = 'https://www.crudafoch.com/privacy.html';
        window.open(url, '_blank');
        next(false);
      }
    }
  },
  {
    path: '/privacy-modal',
    name: 'Datenschutz',
    component: Privacy,
    meta: {},
  },
  {
    path: '/privacy',
    name: 'Datenschutz',
    component: PrivacyNew,
    meta: {},
  },
  {
    path: '/terms',
    name: 'AGB',
    component: Terms,
    meta: {},
  },
  {
    path: '/impress',
    name: 'Impressum',
    component: Impress,
    meta: {},
  },
  {
    path: '/redeem/voucher',
    name: 'Redeem Voucher',
    component: RedeemVoucher,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/mobipoints/transactions',
    name: 'MobiPoints History',
    component: MobiPointsWallet,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/home',
    redirect: '/tabs/home',
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userTrackingTemplate',
    name: 'UserTrackingTemplateOverview',
    component: UserTrackingTemplate,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userTrackingTemplate/detail/:id',
    name: 'UserTrackingTemplateDetail',
    component: UserTrackingTemplate,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userTrackingTemplate/edit/:id',
    name: 'UserTrackingTemplateEdit',
    component: UserTrackingTemplate,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userTrackingTemplate/add',
    name: 'UserTrackingTemplateAdd',
    component: UserTrackingTemplate,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userLocation',
    name: 'UserLocationOverview',
    component: UserLocation,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userLocation/add',
    name: 'UserLocationAdd',
    component: AddUserLocation,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/profile/userLocation/detail/:id',
    component: DetailUserLocation,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
  },
  {
    path: '/tabs/',
    component: Tabs,
    meta: {
      middleware: [
        authMiddleware
      ]
    },
    children: [
      {
        path: '',
        redirect: '/tabs/home',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'home',
        component: HomeTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'detail',
        component: DetailTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '',
        redirect: '/tabs/co2',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'co2',
        component: Co2Tab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '',
        redirect: '/tabs/treasurehunt',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'treasurehunt',
        component: TreasureHuntTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '',
        redirect: '/tabs/profile',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'profile',
        component: ProfileTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '',
        redirect: '/tabs/userTrackingList',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'userTrackingList',
        component: UserTrackingListTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '',
        redirect: '/tabs/challenges',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'challenges',
        name: 'Challenges',
        component: ChallengesTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: '/challenges/challenge/detail/:uuid',
        name: 'ChallengeDetail',
        component: ChallengeDetail,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      // {
      //   path: 'trackings',
      //   name: 'Trackings',
      //   component: () => import('@/views/Tabs/TrackingsTab.vue'),
      //   meta: {
      //     middleware: [
      //       authMiddleware
      //     ]
      //   },
      // },
      // {
      //   path: '/trackings/tracking/detail/:uuid',
      //   name: 'Tracking Detail',
      //   component: () => import('@/components/user/tracking/UserTrackingDetail.vue'),
      //   meta: {
      //     middleware: [
      //       authMiddleware
      //     ]
      //   },
      // },
      {
        path: '',
        redirect: '/tabs/redeemPoints',
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
      {
        path: 'redeemPoints',
        component: RedeemPointsTab,
        meta: {
          middleware: [
            authMiddleware
          ]
        },
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (!to.meta.middleware || !(to.meta.middleware instanceof Array) || to.meta.middleware.length < 1) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store
  }

  return middlewarePipeline(context, to.meta.middleware, 0)();
})

export default router
