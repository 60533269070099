<template>
	<div class="qr-input">
		<ion-button v-if="!isQrScanActive" color="dark" expand="block" @click="scanQrCode()" size="large">
			<ion-icon slot="end" :icon="icons.qrCodeOutline" style="margin-right: .25em;"/>
			<span slot="end">QR Code Scannen</span>
		</ion-button>
		<div v-show="isQrScanActive">
			<div class="scan-background">
				<!-- this background simulates the camera view -->
			</div>
			<div class="container">
				<div class="barcode-scanner--area--container">
					<div class="relative">
						<p>Richte die Kamera auf einen Barcode</p>
					</div>
					<div class="square surround-cover">
						<div class="barcode-scanner--area--outer surround-cover">
							<div class="barcode-scanner--area--inner"></div>
						</div>
					</div>
				</div>
			</div>
			<ion-button color="danger" expand="full" fill="outline" style="z-index: 99999" @click="cancelScan()">Scan
				beenden
			</ion-button>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onDeactivated, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {BarcodeScanner, SupportedFormat} from '@capacitor-community/barcode-scanner';
import * as icons from 'ionicons/icons';
import {IonButton, IonIcon} from '@ionic/vue';
import {Base64} from "@/mobipoints/core/encoding";
import {MobiPointsQueueEventTypeRedeemVoucherType} from "@/mobipoints/queue/event/type/redeem_voucher_event.type";
import {UrlHelper} from "@/mobipoints/core/url";

export interface QrCodeSubmitData {
	code: any,
	type: any,
	data: any
}

export default defineComponent({
	name: 'QrInput',
	components: {IonButton, IonIcon},
	emits: ['submitScan', 'startScan', 'cancelScan', 'errorScan'],
	props: {
		autoSendSubmit: {
			type: Boolean,
			default: true,
		},
		inputLength: {
			type: Number,
			default: 6,
		},
		inputType: {
			type: String,
			default: 'text', //number...
		},
		inputPattern: {
			type: String,
			default: '\\d*', //[A-Z0-9]
		},
		allowedKeyList: {
			type: Array,
			default: (): string[] => ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		},
		inputValue: {
			type: String,
			default: '',
		},
		isQrCodeBase64Encoded: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, {emit}) {
		const isQrScanActive = ref(false);

		function prepare() {
			BarcodeScanner.prepare();
		}

		async function startScan() {
			emit('startScan', true);
			await didUserGrantPermission();
			isQrScanActive.value = true;
			// Check camera permission
			// This is just a simple example, check out the better checks below
			await BarcodeScanner.checkPermission({force: true});

			document.body.style.opacity = "0.7";
			document.body.style.background = "transparent";
			// make background of WebView transparent
			// note: if you are using ionic this might not be enough, check below
			BarcodeScanner.hideBackground();

			const result = await BarcodeScanner.startScan({targetedFormats: [SupportedFormat.QR_CODE]}); // start scanning and wait for a result

			// if the result has content
			if (result.hasContent) {
				document.body.style.background = "";
				document.body.style.opacity = "1";

				try {
					const qrCodeSubmitData: QrCodeSubmitData = {code: null, type: null, data: null};
					if (props.isQrCodeBase64Encoded) {
						let content: any = result.content;
						if (UrlHelper.isValidUrl(content)) {
							const url = new URL(content);
							content = url.searchParams.get('data');
						}

						const qrData = JSON.parse(Base64.decode(content));
						qrCodeSubmitData.code = qrData.code ?? null;
						qrCodeSubmitData.type = qrData.type ?? MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE;
					} else {
						qrCodeSubmitData.code = result.content;
						qrCodeSubmitData.type = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE;
					}
					emit('submitScan', qrCodeSubmitData.code, qrCodeSubmitData.type, qrCodeSubmitData.data);
				} catch (e) {
					console.error(JSON.stringify(e));
					stopScan();
					emit('errorScan', e, result.content)
				}
			}
			isQrScanActive.value = false;
		}

		function scanQrCode() {
			prepare();
			startScan();
		}

		function stopScan(): void {
			BarcodeScanner.showBackground();
			BarcodeScanner.stopScan();
			document.body.style.background = "";
			document.body.style.opacity = "1";
			isQrScanActive.value = false;
		}

		function cancelScan() {
			stopScan();
			emit('cancelScan', true);
		}

		async function didUserGrantPermission() {
			// check if user already granted permission
			const status = await BarcodeScanner.checkPermission({force: false});

			if (status.granted) {
				// user granted permission
				return true;
			}

			if (status.denied) {
				const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
				if (c) {
					BarcodeScanner.openAppSettings();
				}
				return false;
			}

			if (status.asked) {
				// system requested the user for permission during this call
				// only possible when force set to true
			}

			if (status.neverAsked) {
				// user has not been requested this permission before
				// it is advised to show the user some sort of prompt
				// this way you will not waste your only chance to ask for the permission
				const c = confirm('We need your permission to use your camera to be able to scan barcodes');
				if (!c) {
					return false;
				}
			}

			if (status.restricted || status.unknown) {
				// ios only
				// probably means the permission has been denied
				return false;
			}

			// user has not denied permission
			// but the user also has not yet granted the permission
			// so request it
			const statusRequest = await BarcodeScanner.checkPermission({force: true});

			if (statusRequest.asked) {
				// system requested the user for permission during this call
				// only possible when force set to true
			}

			if (statusRequest.granted) {
				// the user did grant the permission now
				return true;
			}

			// user did not grant the permission, so he must have declined the request
			return false;
		}

		watch(() => props.inputValue, (newValue: any) => {
			if (newValue && newValue.length > 0) {
				const dataToPaste = newValue.trim().split("");

				if (dataToPaste) {
					const re = new RegExp(props.inputPattern);
					for (const num of dataToPaste) {
						if (!re.test(num)) {
							return false;
						}
					}
				}
			}
		});

		onDeactivated(() => {
			stopScan();
		});

		onBeforeUnmount(() => {
			stopScan();
		});

		onMounted(() => {
			// didUserGrantPermission();
		});

		return {
			startScan,
			scanQrCode,
			isQrScanActive,
			icons,
			cancelScan
		};
	}
})
</script>

<style>
* {
    box-sizing: border-box;
}

p {
    color: #fff;
    font-family: sans-serif;
    text-align: center;
    font-weight: 600;
}

html,
body,
.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.container {
    display: flex;
}

.relative {
    position: relative;
    z-index: 1;
}

.square {
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}

.square:after {
    content: '';
    top: 0;
    display: block;
    padding-bottom: 100%;
}

.square > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.surround-cover {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}

.barcode-scanner--area--container {
    width: 80%;
    max-width: min(500px, 80vh);
    margin: auto;
}

.barcode-scanner--area--outer {
    display: flex;
    border-radius: 1em;
}

.barcode-scanner--area--inner {
    width: 100%;
    margin: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 0.5),
    inset 0 0 2px 1px rgb(0 0 0 / 0.5);
    border-radius: 1rem;
}

.scan-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background: linear-gradient(45deg, #bbbbbb, transparent) no-repeat 45% 50%;
    animation: shake 5s infinite;
}

.barcode-scanner--area--inner {
    width: 100%;
    margin: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 0.5),
    inset 0 0 2px 1px rgb(0 0 0 / 0.5);
    border-radius: 1rem;
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }
    20% {
        transform: translate(5px, 5px) rotate(-1deg) scale(1.05);
    }
    40% {
        transform: translate(5px, 5px) rotate(-2deg) scale(1.07);
    }
    60% {
        transform: translate(2px, 2px) rotate(0deg) scale(1.04);
    }
    80% {
        transform: translate(-1px, -1px) rotate(-2deg) scale(1.05);
    }
    100% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }
}

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
}
</style>
