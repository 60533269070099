<template>
	<div v-if="hasLastTracking">
		<h1 class="ion-padding activeTrackingTitle">{{ trackingName }}</h1>
		<ion-card class="activeTrackingCard">
			<WeatherIcon :load-current-weather="false" :weather="lastTracking.weatherItem"></WeatherIcon>
			<disabled-map :disableMarkerUpdateEvent="true" :zoom="mapsZoom" :street-view-control="false" :zoom-control="true"
						  :init-cords="{lat: lastTrackingCoordinate?.latitude, lng: lastTrackingCoordinate?.longitude}"
						  :show-path="true" :path-coordinates="lastTracking.getAllCoordinatesForMap()"
						  height="14em"></disabled-map>

			<ion-card-header class="activeTrackingCardHeader">
				<ion-grid>
					<ion-row>
						<ion-col size="4.5">
							<ion-card-title class="last-tracking-title">{{ trackingName }}</ion-card-title>
						</ion-col>
						<ion-col>
							<div class="activeTrackingCardHeaderSubTitle">
								<div style="min-width: 60%;float: left">
									<ion-icon :icon="icons.location"/>
									{{distanceFormatted}} km
									<span style="padding-left: 5px; padding-right: 5px">|</span>
								</div>
								<div style="min-width: 40%; float: left">
									<ion-icon style="float: left" :icon="icons.timeOutline"/>
									<div style="padding-left: 15px;">
										{{duration}}
									</div>
								</div>
							</div>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-card-header>
			<ion-card-content class="activeTrackingCardHeader">
				<ion-grid>
					<ion-row>
						<ion-col>
							<ion-card-subtitle>
								{{lastTrackTypeName}} <ion-icon :icon="lastTrackIcon"/>
							</ion-card-subtitle>
							<ion-card-subtitle>
								<ion-icon :icon="icons.location"/>
								<span v-if="startAddress && startAddress !== null">{{startAddress.city}}</span>
								<span v-else>{{getStartAddress()}}</span>
								<span> - </span>
								<span v-if="endAddress && endAddress !== null">{{endAddress.city}}</span>
								<span v-else>{{getEndAddress()}}</span>
							</ion-card-subtitle>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-card-content>
		</ion-card>
	</div>
</template>

<script lang="ts">
import {ref, onMounted} from "vue";
import {
	IonCardContent,
	IonCard,
	IonCardHeader,
	IonIcon,
	IonCardTitle,
	IonCardSubtitle,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import DisabledMap from "@/components/map/DisabledMap.vue";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import {formatSecondsNice} from "@/mobipoints/core/date";
import useData from "@/composable/useData";
import WeatherIcon from "@/components/weather/WeatherIcon.vue";

export default {
	name: 'LastTrackingCard',
	components: {
		WeatherIcon,
		DisabledMap,
		IonCardContent,
		IonCard,
		IonCardHeader,
		IonIcon,
		IonCardTitle,
		IonCardSubtitle,
		IonGrid,
		IonRow,
		IonCol,
	},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const {
			hasLastTracking,
			lastTracking,
		} = useTracking();
		const {getTrackingFactory} = useTrackingFactory();
		const {getTextValueByKey} = useData();

		const duration = ref("00:00");
		const distance = ref(0.0);
		const distanceFormatted = ref('0.0');
		const trackingName = ref(getTextValueByKey('last.tracking.title', [], 'Dein letztes Tracking'));
		const trackingState = ref("");
		const lastTrackingStateName = ref("Abgeschlossen");
		const lastTrackTypeName: any = ref('Unbekannt');
		const lastTrackIcon: any = ref(lastTracking.value ? lastTracking.value.getLatestTrackingIcon() : '');
		const lastTrackingTrackType: any = ref(lastTracking.value ? lastTracking.value.getLatestTrack()?.type : '');
		const lastTrackingCoordinate: any = ref(lastTracking.value ? lastTracking.value.getLastCoordinate() : '');
		const startAddress: MobiPointsGeoCodingAddressInterface|any = ref({});
		const endAddress: MobiPointsGeoCodingAddressInterface|any = ref({});
		const mapsZoom: any = ref(15);

		function getStartAddress(): string
		{
			return getTextValueByKey('last.tracking.start_address.default', [],'Unbekannt')
		}

		function getEndAddress(): string
		{
			return getTextValueByKey('last.tracking.end_address.default', [],'Unbekannt')
		}

		onMounted(() => {
			if (lastTracking.value) {
				if (!lastTracking.value.distance) {
					lastTracking.value.calculateDistance();
				}
				if (!lastTracking.value.duration) {
					lastTracking.value.calculateDuration();
				}
				distance.value = lastTracking.value.distance;
				if (lastTracking.value.duration != 0) {
					duration.value = formatSecondsNice(lastTracking.value.duration ?? 0);
				} else {
					duration.value = '0';
				}
				startAddress.value = lastTracking.value.startAddress;
				endAddress.value = lastTracking.value.endAddress;
				const distanceAsNumber = Number(distance.value);
				if (distanceAsNumber > 2 && distanceAsNumber < 4) {
					mapsZoom.value = 13;
				} else if (distanceAsNumber > 4 && distanceAsNumber < 10) {
					mapsZoom.value = 12;
				} else if (distanceAsNumber > 10 && distanceAsNumber < 50) {
					mapsZoom.value = 10;
				} else if (distanceAsNumber > 50) {
					mapsZoom.value = 8;
				}
				const distanceFormattedAsString = distance.value ?? 0;
				if (distanceFormattedAsString != 0) {
					distanceFormatted.value = distanceFormattedAsString.toFixed(2);
				}
			}
			lastTrackTypeName.value = getTextValueByKey('lastTracking.type.'+lastTrackingTrackType.value+'.title', [], 'Unbekanntes Tracking');
			lastTrackIcon.value = getTrackingFactory().getTrackingIconByType(lastTrackingTrackType.value);
		});

		return {
			icons,
			duration,
			distance,
			trackingName,
			trackingState,
			lastTrackingStateName,
			lastTrackIcon,
			hasLastTracking,
			lastTracking,
			lastTrackingCoordinate,
			startAddress,
			endAddress,
			lastTrackTypeName,
			mapsZoom,
			distanceFormatted,
			getStartAddress,
			getEndAddress,
		}
	}
}
</script>

<style scoped>
ion-card-subtitle {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}

.activeTrackingCardHeaderSubTitle {
    font-size: 15px;
    font-weight: normal;
    text-align: right;
    line-height: 18px;
}

.activeTrackingCard {
    min-height: 22em;
}

.activeTrackingCardHeader {
    padding: 0;
}

.activeTrackingTitle {
    padding-bottom: 0;
}

.last-tracking-title {
	font-size: 1.3em;
}
</style>