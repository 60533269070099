import {News} from "@/mobipoints/news/news/news.type";
import {NewsGetResponse} from "@/mobipoints/api/news";
import {MobiPointsNewsInterface} from "@/mobipoints/news/news/news.interface";

export class NewsFactory {
	createNews(title: string, type: string): News {
		return new News(title, type);
	}

	createNewsListByApiResponse(response: NewsGetResponse): News[] {
		const list: News[] = [];

		response.payload.forEach((data) => {
			list.push(this.createNewsByApiResponse(data));
		});

		return list;
	}

	createNewsByApiResponse(response: MobiPointsNewsInterface): News {
		const news = this.createNews(response.title, response.type);
		news.description = response.description;
		news.image = response.image;
		news.show = response.show;
		news.showButton = response.show_button;
		news.buttonType = response.button_type;
		news.buttonLink = response.button_link;
		news.buttonText = response.button_text;
		news.buttonColor = response.button_color;
		news.startDate = response.start_date ? new Date(response.start_date * 1000) : undefined;
		news.endDate = response.end_date ? new Date(response.end_date * 1000) : undefined;
		news.fullscreen = response.fullscreen === true;
		news.specificShowType = response.specificShowType;

		return news;
	}
}
