import useData from '@/composable/useData';
import {
	WalletItemPayout
} from '@/mobipoints/wallet/item/payout/wallet_item_payout.type';

export class TreasureWalletItemPayout extends WalletItemPayout{
	getBadgeColor(): string {
		return 'danger';
	}

	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.payout.name', [], 'Auszahlung');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount: string;
		if (!this.amount) {
			amount =  '0';
		} else {
			amount = this.amount?.toFixed(0);
		}
		if (withSuffix) {
			if (this.amount == 1) {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.payout.amount.suffix', [], 'Los ausgegeben');
			} else {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.payout.amount.suffix', [], 'Lose ausgegeben');
			}
		}
		return amount;
	}
}