import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";

export class MobiPointsTrackingCoordinateCoordinate implements MobiPointsTrackingCoordinateCoordinateInterface {
	latitude: number;
	longitude: number;
	timestamp: number;
	locationSimulated: boolean|null;

	constructor(latitude: number, longitude: number, timestamp: number, locationSimulated: boolean|null = false) {
		this.latitude = latitude;
		this.longitude = longitude;
		this.timestamp = timestamp;
		this.locationSimulated = locationSimulated;
	}
}