import {Plugins} from "@capacitor/core";
import {NativeGeocoderOptions, NativeGeocoderResult} from '@ionic-native/native-geocoder/ngx';
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import useSystem from "@/composable/useSystem";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import useGeoCodingFactory from "@/composable/useGeoCodingFactory";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {GoogleMapsAddressData} from "@/mobipoints/geocoding/google_maps_address_data.interface";
import useMainData from "@/composable/useMainData";
import useData from "@/composable/useData";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";

const { NativeGeocoder } = Plugins;

export class MobiPointsGeoCoding {
	private nativeGeocoderOptions: NativeGeocoderOptions = {
		useLocale: true,
		maxResults: 5
	};

	getAddressFromCoordinates(coordinates: MobiPointsTrackingCoordinateCoordinate): MobiPointsGeoCodingAddressInterface|null
	{
		let result: MobiPointsGeoCodingAddressInterface|null = null;

		if (coordinates){
			const latitude = coordinates.latitude;
			const longitude = coordinates.longitude;

			try {
				if (useSystem().isNativePlatform()) {
					result = NativeGeocoder.reverseGeocode(latitude, longitude, this.nativeGeocoderOptions)
						.then((res: NativeGeocoderResult[]) => {
							const prettifyAddressData = res[0];
							return useGeoCodingFactory().getGeoCodingFactory().createAddressByData(
								prettifyAddressData.locality,
								prettifyAddressData.subLocality,
								prettifyAddressData.administrativeArea,
								prettifyAddressData.locality,
								"",
								prettifyAddressData.countryCode,
								prettifyAddressData.countryName,
								prettifyAddressData.postalCode
							);
						})
						.catch((error: any) => {
							console.log(error);
						});
				} else {
					this.getAddressByGoogleMapsGeocoding(latitude, longitude)
						.then(addressData => {
							const firstAddress = addressData.results[0];
							const googleMapsAddressData = this.getGoogleMapsAddressData(firstAddress);
							return useGeoCodingFactory().getGeoCodingFactory().createAddressByData(
								googleMapsAddressData.route,
								googleMapsAddressData.streetNumber,
								googleMapsAddressData.city,
								googleMapsAddressData.locality,
								googleMapsAddressData.political,
								googleMapsAddressData.country,
								googleMapsAddressData.countryName,
								googleMapsAddressData.postalCode,
								googleMapsAddressData.adminArea1,
								googleMapsAddressData.adminArea2,
								googleMapsAddressData.formattedAddress
							);
						})
						.catch((error: any) => {
							console.log(error);
						});
					// result = this.getAddressByGoogleMapsGeocoding(latitude, longitude)
					// 	.then(response => response.json())
					// 	.then(addressData => {
					// 		const firstAddress = addressData.results[0];
					// 		const googleMapsAddressData = this.getGoogleMapsAddressData(firstAddress);
					// 		return useGeoCodingFactory().getGeoCodingFactory().createAddressByData(
					// 			googleMapsAddressData.route,
					// 			googleMapsAddressData.streetNumber,
					// 			googleMapsAddressData.city,
					// 			googleMapsAddressData.locality,
					// 			googleMapsAddressData.political,
					// 			googleMapsAddressData.country,
					// 			googleMapsAddressData.countryName,
					// 			googleMapsAddressData.postalCode,
					// 			googleMapsAddressData.adminArea1,
					// 			googleMapsAddressData.adminArea2,
					// 			googleMapsAddressData.formattedAddress
					// 		);
					// 	})
					// 	.catch((error: any) => {
					// 		console.log(error);
					// 	});
				}
			} catch (error: any) {
				console.log(error);
			}
		}
		return result;
	}

	getAddressByGoogleMapsGeocoding(latitude: number, longitude: number)
	{
		try {
			const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + '&key=' + process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
			return fetch(url, {
				method: "GET",
				mode: 'cors',
				headers: {
					'Accept-Language': '*',
				},
			})
				.then(response => response.json())
				.then(data => {
					return data;
				});
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	getGoogleMapsAddressData(addressData: any = {}): GoogleMapsAddressData{
		const googleMapsAddressData = {} as GoogleMapsAddressData;
		if (addressData && addressData.address_components){
			googleMapsAddressData.formattedAddress = addressData.formatted_address;
			addressData.address_components.forEach((addressData) => {
				if (addressData && addressData.types){
					if (addressData.types && Array.isArray(addressData.types)){
						addressData.types.forEach((type) => {
							switch(type) {
								case "route": {
									googleMapsAddressData.route = addressData.short_name;
									break;
								}
								case "street_number": {
									googleMapsAddressData.streetNumber = addressData.short_name;
									break;
								}
								case "city": {
									googleMapsAddressData.city = addressData.short_name;
									break;
								}
								case "locality": {
									if (googleMapsAddressData.city === undefined){
										googleMapsAddressData.city = addressData.short_name;
									}
									googleMapsAddressData.locality = addressData.short_name;
									break;
								}
								case "political": {
									googleMapsAddressData.political = addressData.short_name;
									break;
								}
								case "administrative_area_level_2": {
									googleMapsAddressData.adminArea1 = addressData.short_name;
									break;
								}
								case "administrative_area_level_1": {
									googleMapsAddressData.adminArea2 = addressData.short_name;
									break;
								}
								case "country": {
									googleMapsAddressData.country = addressData.short_name;
									googleMapsAddressData.countryName = addressData.long_name;
									break;
								}
								case "postal_code": {
									googleMapsAddressData.postalCode = addressData.short_name;
									break;
								}
								default: {
									break;
								}
							}
						})
					}
				}
			});
		}
		return googleMapsAddressData;
	}

	getNearestLocationNameByCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinate, useShortName = false, maxDistanceInMeter = 200): string
	{
		let result = useData().getTextValueByKey('geocoding.nearest.location', [], 'Unbekannt');

		const nearestLocationAddress = this.getNearestLocationByCoordinate(coordinate, maxDistanceInMeter);
		if (nearestLocationAddress !== null) {
			if (useShortName) {
				result = nearestLocationAddress.city;
			} else {
				result = nearestLocationAddress.formattedAddress;
			}
		}

		return result;
	}

	isCoordinateCloseToLocations(coordinate: MobiPointsTrackingCoordinateCoordinate, locationTypes: Array<MobiPointsLocationTypes> = [MobiPointsLocationTypes.HOME_LOCATION, MobiPointsLocationTypes.USER_LOCATION, MobiPointsLocationTypes.COMPANY_LOCATION], maxDistanceInMeter = 200) {
		let result = false;
		const distanceCalculation = useTrackingFactory().getTrackingFactory().createDistanceCalculation();
		useMainData().userLocations.value.forEach((location) => {
			if (locationTypes.includes(location.type.code)) {
				const positionToCheck = useTrackingFactory().getTrackingFactory().createCoordinate(location.latitude, location.longitude);
				if (distanceCalculation.isCoordinateCloseToPosition(coordinate, positionToCheck, maxDistanceInMeter)) {
					result = true;
					return;
				}
			}
		});
		return result;
	}

	getNearestLocationByCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinate, maxDistanceInMeter = 200): MobiPointsGeoCodingAddressInterface|null
	{
		let result: MobiPointsGeoCodingAddressInterface|null = null;
		const distanceCalculation = useTrackingFactory().getTrackingFactory().createDistanceCalculation();
		useMainData().userLocations.value.forEach((location) => {
			const positionToCheck = useTrackingFactory().getTrackingFactory().createCoordinate(location.latitude, location.longitude);
			if (distanceCalculation.isCoordinateCloseToPosition(coordinate, positionToCheck, maxDistanceInMeter)) {
				result = useGeoCodingFactory().getGeoCodingFactory().createAddressByData(location.street, location.streetNumber, location.city, '', '', location.country.code, location.country.name, location.postalCode, '', '', location.getFormattedAddress(), coordinate.latitude, coordinate.longitude);
				return;
			}
		});

		if (!result) {
			useMainData().userCompanyLocations.value.forEach((location) => {
				const positionToCheck = useTrackingFactory().getTrackingFactory().createCoordinate(location.latitude, location.longitude);
				if (distanceCalculation.isCoordinateCloseToPosition(coordinate, positionToCheck, maxDistanceInMeter)) {
					result = useGeoCodingFactory().getGeoCodingFactory().createAddressByData(location.street, location.streetNumber, location.city, '', '', location.country.code, location.country.name, location.postalCode, '', '', location.getFormattedAddress(), coordinate.latitude, coordinate.longitude);
					return;
				}
			});
		}

		return result;
	}
}