import {computed, ComputedRef, onBeforeMount, ref} from 'vue';
import store from '@/store';
import {UserTrackingTrackType} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.type";
import {MobiPointApiProfile, ResponseError} from "@/mobipoints/api/profile";
import {AxiosError} from "axios";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {MobiPointApiEvent} from "@/mobipoints/api/events";
import {MobiPointsLocationTypeInterface, MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import * as icons from 'ionicons/icons';
import {MobiPointsProfileUserSettingsInterface} from "@/mobipoints/profile/userSettings/user_settings.interface";
import {UserSettings} from "@/mobipoints/profile/userSettings/user_settings.type";

export default function useMainData() {
	const userTrackingTrackTypes: ComputedRef<Array<UserTrackingTrackType>> = computed(() => store.getters['mainData/userTrackingTrackTypes']);
	const userLocations: ComputedRef<Array<UserLocation>> = computed(() => store.getters['mainData/userLocations']);
	const userCompanyLocations: ComputedRef<Array<UserLocation>> = computed(() => store.getters['mainData/userCompanyLocations']);
	const userLocationsEditable: ComputedRef<Array<UserLocation>> = computed(() => {
		return userLocations.value.filter((userLocation) => userLocation.isEditable);
	});
	const locationTypes: ComputedRef<Array<MobiPointsLocationTypeInterface>> = computed(() => store.getters['mainData/locationTypes']);
	const locationTypesSelectable: ComputedRef<Array<MobiPointsLocationTypeInterface>> = computed(() => {
		return locationTypes.value.filter((locationType) => MobiPointsLocationTypes.COMPANY_LOCATION !== locationType.code);
	});
	const uuids: ComputedRef<Array<string>> = computed(() => store.getters['mainData/uuids']);
	const userProfileSettings: ComputedRef<MobiPointsProfileUserSettingsInterface|UserSettings> = computed(() => store.getters['mainData/userProfileSettings']);

	const isLoading = ref(false);

	const loadUserTrackingTrackTypes = async function () {
		try {
			const response = await MobiPointApiProfile.getUserTrackingTrackTypes();
			return response.data.payload;
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	};

	const loadUserLocations = async function () {
		try {
			const response = await MobiPointApiProfile.getUserLocation();
			return response.data.locations;
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	};

	const loadUuids = async function () {
		try {
			const response = await MobiPointApiEvent.getUuids(10);
			return response.data.payload;
		} catch (error: any | AxiosError) {
			console.log("LOAD UUID ERROR", error)
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	};

	const loadUserProfileSettings = async function () {
		try {
			return new UserSettings();
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	};


	const initUserTrackingTrackTypes = async function(forceRefresh = false) {
		if (userTrackingTrackTypes.value.length < 1 || forceRefresh) {
			loadUserTrackingTrackTypes().then((result) => {
				store.commit('mainData/userTrackingTrackTypes', result);
			});
		}
	}

	let isUserLocationsLoading = false;
	const initUserLocations = async function(forceRefresh = false) {
		if (!isUserLocationsLoading && (userLocations.value.length < 1 || forceRefresh) ) {
			isUserLocationsLoading = true;
			await loadUserLocations().then((result) => {
				const list: Array<MobiPointsProfileUserLocationInterface> = [];

				result.user.forEach((item) => {
					item.isEditable = true;
				});
				result.userGroups.forEach((item) => {
					item.isEditable = false;
				});

				list.push(...(result.user || []));
				list.push(...(result.userGroups || []));

				store.commit('mainData/userLocations', list);
			}).finally(() => {
				isUserLocationsLoading = false;
			});
		}
	}

	const initLocationTypes = async function(forceRefresh = false) {
		if (locationTypes.value.length === undefined || locationTypes.value.length < 1 || forceRefresh) {
			store.commit('mainData/locationTypes', [
				{code: MobiPointsLocationTypes.COMPANY_LOCATION, name: 'Firmenstandort', icon: icons.businessOutline},
				{code: MobiPointsLocationTypes.HOME_LOCATION, name: 'Wohnort', icon: icons.homeOutline},
				{code: MobiPointsLocationTypes.USER_LOCATION, name: 'Adresse', icon: icons.locationOutline},
			]);
		}
	}

	const getLocationTypeByCode = function(code: MobiPointsLocationTypes): MobiPointsLocationTypeInterface | null {
		return store.getters['mainData/locationTypes'].find((locationType) => code === locationType.code) || null;
	}

	const getLocationById = function(id: number): UserLocation | null {
		return userLocations.value.find((userLocation) => id === userLocation.id) || null;
	}

	const initUuids = async function (forceRefresh = false) {
		if (uuids.value.length === undefined || uuids.value.length < 1 || forceRefresh) {
			await loadUuids().then((result) => {
				store.commit('mainData/uuids', result);
			});
		}
	}

	const initUserProfileSettings = async function (forceRefresh = false) {
		if (!(userProfileSettings.value instanceof UserSettings) || forceRefresh) {
			loadUserProfileSettings().then((result) => {
				store.commit('mainData/userProfileSettings', result);
			});
		}
	}

	const reloadUserLocations = async function (force = false) {
		store.commit('mainData/resetUserLocations');
		await initUserLocations(force);
	}

	const initMainData = async function (forceRefresh = false) {
		if (isLoading.value && !forceRefresh) {
			return;
		}
		await initUserTrackingTrackTypes(forceRefresh);
		await initUserLocations(forceRefresh);
		await initLocationTypes(forceRefresh);
		await initUuids(forceRefresh);
		await initUserProfileSettings(forceRefresh);
	};

	async function getFirstUuid(forceRefresh = false): Promise<string> {
		if (!uuids.value || (uuids.value && uuids.value.length < 2) || forceRefresh) {
			await initUuids(true);
		}
		const uuid = uuids.value[0];
		if (!uuid) {
			throw new Error("UUID not found!");
		}
		removeUuid(uuid);
		return uuid;
	}

	function removeUuid(uuid: string) {
		store.commit('mainData/removeUuidFromList', uuid)
	}

	function setEnableHighAccuracy(value: boolean) {
		store.commit('mainData/setEnableHighAccuracy', value)
	}

	onBeforeMount(() => {
		if (!isLoading.value) {
			isLoading.value = true;
			initMainData().then(() => {
				isLoading.value = false;
			});
		}
	});

	return {
		userTrackingTrackTypes,
		userLocations,
		userLocationsEditable,
		uuids,
		getFirstUuid,
		removeUuid,
		initMainData,
		initUserLocations,
		initUserTrackingTrackTypes,
		getLocationTypeByCode,
		locationTypes,
		reloadUserLocations,
		getLocationById,
		locationTypesSelectable,
		userProfileSettings,
		setEnableHighAccuracy,
		userCompanyLocations
	}
}