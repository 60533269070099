import {MobiPointsPointsInterface} from "@/mobipoints/points/points.interface";

const points = {
	namespaced: true,
	state: () => ({
		 currentPoints: {
			 points: 0,
		 } as MobiPointsPointsInterface,
	}),
	mutations: {
		currentPoints(state: any, value: MobiPointsPointsInterface) {
			state.currentPoints = value;
		},
	},
	actions: {},
	getters: {
		currentPoints: (state: any) => {
			return state.currentPoints.points;
		},
	}
}

export default points;