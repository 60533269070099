<script lang="ts">
import { toastController} from '@ionic/vue';
import * as icons from "ionicons/icons";

export default function useToastMessage(){
	async function openToast(message: string, color = 'primary', positionToast: 'top' | 'bottom' | 'middle' = 'top', animated = true, duration = 2000, icon: string | undefined = undefined, showCloseButton = false) {
		let buttons: any  = [];
		if (showCloseButton) {
			buttons = [
				{
					side: 'end',
					text: 'Schließen',
					role: 'cancel',
					icon: icons.close,
				}
			]
		}
		const toast = await toastController
			.create({
				message: message,
				color: color,
				position: positionToast,
				animated: animated,
				duration: duration,
				buttons: buttons,
        		icon: icon
			})
		return toast.present();
	}

	return {openToast}
}
</script>