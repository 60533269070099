<template>
	<svg class="animated" id="freepik_stories-global-warming" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
		 version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
		<g id="freepik--background-simple--inject-55" class="animable" style="transform-origin: 258.681px 290.703px;">
			<path d="M211.6,468.82A108.48,108.48,0,0,0,262,476C366.39,466,469,378.86,457.73,263.5c-5.21-53.32-30.2-117.69-81.54-142.92-41.64-20.46-90.11-16.76-133-11.7-27,3.18-54.12,9.29-81.59,9.12-35.63-.21-80.39,3.48-94.48,41.76-19.19,52.16-1.9,112.73,18,164.66C106.74,380.76,143,435.61,198,462.83Q204.69,466.13,211.6,468.82Z"
				  style="fill: rgb(255, 114, 94); transform-origin: 258.681px 290.703px;" id="ell6h3w5wi7mt"
				  class="animable"></path>
			<g id="elfoxpnki3o1m">
				<path d="M211.6,468.82A108.48,108.48,0,0,0,262,476C366.39,466,469,378.86,457.73,263.5c-5.21-53.32-30.2-117.69-81.54-142.92-41.64-20.46-90.11-16.76-133-11.7-27,3.18-54.12,9.29-81.59,9.12-35.63-.21-80.39,3.48-94.48,41.76-19.19,52.16-1.9,112.73,18,164.66C106.74,380.76,143,435.61,198,462.83Q204.69,466.13,211.6,468.82Z"
					  style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 258.681px 290.703px;"
					  class="animable"></path>
			</g>
		</g>
		<g id="freepik--Sun--inject-55" class="animable" style="transform-origin: 348.105px 77.07px;">
			<path d="M348.11,26.89a.5.5,0,0,1-.5-.5V18a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v8.36A.51.51,0,0,1,348.11,26.89Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 348.11px 22.195px;" id="elsyqvuqqyxce"
				  class="animable"></path>
			<path d="M348.11,136.64a.5.5,0,0,1-.5-.5v-6.92a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v6.92A.5.5,0,0,1,348.11,136.64Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 348.11px 132.68px;" id="els2xcs2crkhq"
				  class="animable"></path>
			<path d="M407.15,78.3h-7.63a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h7.63a.5.5,0,0,1,.5.5A.5.5,0,0,1,407.15,78.3Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 403.335px 77.8px;" id="elp545kruitr"
				  class="animable"></path>
			<path d="M296.69,78.3h-7.63a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h7.63a.51.51,0,0,1,.5.5A.5.5,0,0,1,296.69,78.3Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 292.875px 77.8px;" id="elrtn7vcnq3j"
				  class="animable"></path>
			<path d="M384.46,42a.5.5,0,0,1-.35-.15.51.51,0,0,1,0-.71l5.75-5.75a.5.5,0,1,1,.71.71l-5.75,5.75A.51.51,0,0,1,384.46,42Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 387.342px 38.6208px;" id="elqeqc9ry0tft"
				  class="animable"></path>
			<path d="M306.71,119.71a.49.49,0,0,1-.35-.15.5.5,0,0,1,0-.71l5-5a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7l-5,5A.51.51,0,0,1,306.71,119.71Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 309.217px 116.706px;" id="el27oecteks1j"
				  class="animable"></path>
			<path d="M311.75,42a.51.51,0,0,1-.36-.15l-5.75-5.75a.5.5,0,0,1,.71-.71l5.75,5.75a.5.5,0,0,1,0,.71A.49.49,0,0,1,311.75,42Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 308.871px 38.6221px;" id="elibh07mkpt4p"
				  class="animable"></path>
			<path d="M389.5,119.71a.47.47,0,0,1-.35-.15l-5-5a.5.5,0,0,1,0-.7.5.5,0,0,1,.71,0l5,5a.5.5,0,0,1,0,.71A.47.47,0,0,1,389.5,119.71Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 387.008px 116.711px;" id="elwzvsudb2txa"
				  class="animable"></path>
			<circle cx="348.11" cy="77.09" r="20.72"
					style="fill: rgb(255, 114, 94); transform-origin: 348.11px 77.09px;" id="eljex47i1vgo"
					class="animable"></circle>
			<path d="M348.11,98.31a21.22,21.22,0,1,1,21.22-21.22A21.24,21.24,0,0,1,348.11,98.31Zm0-41.44a20.22,20.22,0,1,0,20.22,20.22A20.24,20.24,0,0,0,348.11,56.87Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 348.11px 77.09px;" id="el147s9kq13fvb"
				  class="animable"></path>
			<path d="M348.11,52.11a.5.5,0,0,1-.5-.5V36.37a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V51.61A.51.51,0,0,1,348.11,52.11Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 348.11px 43.99px;" id="elym59mgyyg0k"
				  class="animable"></path>
			<path d="M348.11,119.75a.5.5,0,0,1-.5-.5V104a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v15.25A.51.51,0,0,1,348.11,119.75Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 348.11px 111.625px;" id="el6caho7mfgid"
				  class="animable"></path>
			<path d="M389.55,78.31H374.3a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h15.25a.5.5,0,0,1,.5.5A.51.51,0,0,1,389.55,78.31Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 381.925px 77.81px;" id="elqxfhbkylv2b"
				  class="animable"></path>
			<path d="M321.91,78.31H306.67a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h15.24a.5.5,0,0,1,.5.5A.51.51,0,0,1,321.91,78.31Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 314.29px 77.81px;" id="el301s8t6x2zz"
				  class="animable"></path>
			<path d="M366.63,59.78a.5.5,0,0,1-.35-.85l10.78-10.78a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.71L367,59.63A.47.47,0,0,1,366.63,59.78Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 372.022px 53.8896px;" id="elwkev0cre4mh"
				  class="animable"></path>
			<path d="M318.8,107.61a.5.5,0,0,1-.35-.15.51.51,0,0,1,0-.71L329.23,96a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.7l-10.78,10.78A.51.51,0,0,1,318.8,107.61Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 324.195px 101.731px;" id="els5rd3628nig"
				  class="animable"></path>
			<path d="M329.58,59.78a.49.49,0,0,1-.35-.15L318.45,48.86a.51.51,0,0,1,0-.71.5.5,0,0,1,.71,0l10.78,10.78a.5.5,0,0,1,0,.7A.51.51,0,0,1,329.58,59.78Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 324.195px 53.8911px;" id="elco98m5djinf"
				  class="animable"></path>
			<path d="M377.41,107.61a.47.47,0,0,1-.35-.15L366.28,96.68a.49.49,0,0,1,.7-.7l10.78,10.77a.5.5,0,0,1,0,.71A.47.47,0,0,1,377.41,107.61Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 372.021px 101.723px;" id="elzvhdhcvsjea"
				  class="animable"></path>
		</g>
		<g id="freepik--smoke-clouds--inject-55" class="animable" style="transform-origin: 253.312px 167.901px;">
			<g id="el5h2ak1fnx5f">
				<path d="M273,117.76a16.38,16.38,0,0,0-12-8.51c-8.51-1.16-12,7.35-14.7,12s-8.51-1.16-15.09-.77-7.35,4.25-9.67,7-8.9-.39-12.38,1.55-3.87,4.64-3.87,4.64h97.49s3.1-10.83-5-12-10.44,3.09-13.15,2.32-1.55-5.8-5.42-7A6.84,6.84,0,0,0,273,117.76Z"
					  style="opacity: 0.19; transform-origin: 254.326px 121.406px;" class="animable"></path>
			</g>
			<g id="elc8spu69qpw4">
				<path d="M176.84,142.28a16.38,16.38,0,0,0-12-8.51c-8.51-1.16-12,7.35-14.7,12s-8.51-1.16-15.09-.77-7.35,4.25-9.67,7-8.9-.39-12.38,1.55-3.87,4.64-3.87,4.64h97.5s3.09-10.83-5-12-10.45,3.09-13.16,2.32-1.54-5.8-5.41-7A6.84,6.84,0,0,0,176.84,142.28Z"
					  style="opacity: 0.19; transform-origin: 158.17px 145.926px;" class="animable"></path>
			</g>
			<g id="eloigvay718w">
				<path d="M358.86,142.28a16.38,16.38,0,0,0-12-8.51c-8.51-1.16-12,7.35-14.7,12s-8.51-1.16-15.09-.77-7.35,4.25-9.67,7-8.9-.39-12.38,1.55-3.87,4.64-3.87,4.64h97.49s3.1-10.83-5-12-10.44,3.09-13.15,2.32-1.55-5.8-5.42-7A6.84,6.84,0,0,0,358.86,142.28Z"
					  style="opacity: 0.19; transform-origin: 340.186px 145.926px;" class="animable"></path>
			</g>
			<g id="el0ttzlsap6qi">
				<path d="M406,218.14a8.82,8.82,0,0,0-6.45-4.58c-4.57-.62-6.44,3.95-7.9,6.45s-4.57-.63-8.1-.42-3.95,2.29-5.2,3.74-4.78-.2-6.65.84a3.79,3.79,0,0,0-2.08,2.49H422s1.67-5.82-2.7-6.44-5.61,1.66-7.07,1.24-.83-3.12-2.91-3.74A3.66,3.66,0,0,0,406,218.14Z"
					  style="opacity: 0.19; transform-origin: 395.967px 220.081px;" class="animable"></path>
			</g>
			<g id="elzoib9q097d">
				<path d="M120.69,218.14a8.82,8.82,0,0,0-6.44-4.58c-4.57-.62-6.44,3.95-7.9,6.45s-4.57-.63-8.11-.42-3.95,2.29-5.19,3.74-4.79-.2-6.66.84a3.79,3.79,0,0,0-2.08,2.49H136.7s1.66-5.82-2.7-6.44-5.62,1.66-7.07,1.24-.83-3.12-2.91-3.74A3.68,3.68,0,0,0,120.69,218.14Z"
					  style="opacity: 0.19; transform-origin: 110.66px 220.081px;" class="animable"></path>
			</g>
		</g>
		<g id="freepik--buildings-2--inject-55" class="animable" style="transform-origin: 335.67px 179.345px;">
			<g id="el13vqhuk6aaf">
				<rect x="342.7" y="125.34" width="16.61" height="52.56"
					  style="fill: rgb(38, 50, 56); transform-origin: 351.005px 151.62px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M340.18,177.65l-13.33-11.5,35-40.57,13.33,11.5ZM328.26,166l11.82,10.2,33.66-39.06L361.92,127Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 351.015px 151.615px;" id="elfhmtmij94k4"
				  class="animable"></path>
			<g id="elgh02fhlrwnq">
				<rect x="356.54" y="148.02" width="16.43" height="48.66"
					  style="fill: rgb(38, 50, 56); transform-origin: 364.755px 172.35px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M355.14,196.84l-13.2-11.37,32.42-37.61,13.2,11.37Zm-11.79-11.48L355,195.43l31.11-36.09-11.68-10.07Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 364.75px 172.35px;" id="elzct2o9zt68h"
				  class="animable"></path>
			<polygon
					points="284.49 174.93 292.36 165.8 299.86 172.27 317.52 151.78 363.99 191.83 370.95 183.75 379.03 190.72 366.35 205.43 371.25 209.66 352.43 231.49 284.49 174.93"
					style="fill: rgb(38, 50, 56); transform-origin: 331.76px 191.635px;" id="elfac1tf5c6wm"
					class="animable"></polygon>
			<path d="M352.49,232.19,283.78,175l8.53-9.9,7.5,6.47,17.66-20.49,46.46,40.05,7-8.08,8.84,7.62-12.69,14.72,4.91,4.23ZM285.2,174.87l67.18,55.92,18.17-21.08-4.91-4.22,12.69-14.72L371,184.45l-7,8.09-46.46-40.06L299.91,173l-7.5-6.47Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 331.775px 191.635px;" id="el5bb26f4t55"
				  class="animable"></path>
			<g id="elfn7a2ttg91">
				<rect x="333.11" y="154.74" width="18.38" height="58.8"
					  style="fill: rgb(255, 255, 255); transform-origin: 342.3px 184.14px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M330.11,213.12l-14.68-12.66,39.05-45.29,14.68,12.65Zm-13.27-12.76L330,211.71l37.74-43.78-13.16-11.35Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 342.295px 184.145px;" id="elhi7btugsaon"
				  class="animable"></path>
			<g id="el61591x4ard">
				<rect x="337.62" y="158.63" width="18.38" height="58.8"
					  style="fill: rgb(255, 255, 255); transform-origin: 346.81px 188.03px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M334.63,217,320,204.35l39-45.29,14.68,12.65Zm-13.27-12.76,13.16,11.35,37.74-43.78L359.1,160.47Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 346.84px 188.03px;" id="elt6g16p42paa"
				  class="animable"></path>
			<g id="el4u9fa2e92kl">
				<rect x="350.72" y="161.22" width="27.15" height="3.97"
					  style="fill: rgb(255, 255, 255); transform-origin: 364.295px 163.205px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M373.33,174.28,352,155.9l3.25-3.77,21.32,18.38ZM353.42,155.8l19.81,17.07,1.94-2.25-19.81-17.08Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 364.285px 163.205px;" id="elqdwvzefta5"
				  class="animable"></path>
			<g id="elfa4eirdow3k">
				<rect x="356.05" y="163.2" width="21.09" height="3.97"
					  style="fill: rgb(255, 255, 255); transform-origin: 366.595px 165.185px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<path d="M373.33,174.28,356.6,159.86l3.25-3.77,16.73,14.42ZM358,159.75l15.22,13.12,1.94-2.25L360,157.5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 366.59px 165.185px;" id="elia2jzlpdrmh"
				  class="animable"></path>
			<g id="elx3vrta8s3n">
				<rect x="354.87" y="164.55" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 356.69px 167.365px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el375jtk7p73s">
				<rect x="359.63" y="168.65" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 361.45px 171.465px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el43ohfzfdumj">
				<rect x="364.4" y="172.76" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 366.22px 175.575px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="ele2u8qzl3gxp">
				<rect x="350.8" y="159.92" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 351.33px 162.735px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elqha0dyqmzfa">
				<rect x="352.19" y="161.12" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 352.72px 163.935px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eleelp064b6bf">
				<rect x="345.16" y="166.46" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 345.69px 169.275px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elby8fzwwhda">
				<rect x="346.55" y="167.66" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 347.08px 170.475px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elfqp91bf2tme">
				<rect x="339.53" y="173" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 340.06px 175.815px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elmkbyqb0zm8">
				<rect x="340.91" y="174.2" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 341.44px 177.015px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elgxky6q8ko7o">
				<rect x="333.89" y="179.54" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 334.42px 182.355px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elmyt027rygz">
				<rect x="335.28" y="180.73" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 335.81px 183.545px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elio1hezlpb1">
				<rect x="328.26" y="186.08" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 328.79px 188.895px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="ele93y2uf4kr5">
				<rect x="329.64" y="187.27" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 330.17px 190.085px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elfwuyvngo92r">
				<rect x="349.23" y="171.08" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 351.05px 173.895px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elxmbey8tlv49">
				<rect x="354" y="175.19" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 355.82px 178.005px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elluo5pbwrso">
				<rect x="358.76" y="179.3" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 360.58px 182.115px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elie90tmxx5fc">
				<rect x="343.6" y="177.62" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 345.42px 180.435px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el7so5wgidxa">
				<rect x="348.36" y="181.73" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 350.18px 184.545px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elzpsooo6owdf">
				<rect x="353.13" y="185.84" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 354.95px 188.655px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<g id="elrmqohy2wwa">
				<rect x="337.96" y="184.16" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 339.78px 186.975px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elg1si17jy66s">
				<rect x="342.73" y="188.27" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 344.55px 191.085px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="els3agir0f2sn">
				<rect x="347.49" y="192.37" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 349.31px 195.185px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elf9os9jmv4si">
				<rect x="332.33" y="190.7" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 334.15px 193.515px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elylweu9o3lyb">
				<rect x="337.09" y="194.8" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 338.91px 197.615px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elcyvvtcld9rf">
				<rect x="341.85" y="198.91" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 343.67px 201.725px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elm68cn3pl1t">
				<rect x="306.97" y="152.21" width="22.34" height="43.04"
					  style="fill: rgb(255, 255, 255); transform-origin: 318.14px 173.73px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M312.61,198l-17.68-15.24,28.75-33.36,17.68,15.24Zm-16.27-15.35,16.16,13.94L340,164.78l-16.17-13.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 318.145px 173.7px;" id="elsvlxmwsxghk"
				  class="animable"></path>
			<g id="elxwa1n5fq3yh">
				<rect x="312.46" y="156.94" width="22.34" height="43.04"
					  style="fill: rgb(255, 255, 255); transform-origin: 323.63px 178.46px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M318.09,202.76l-17.68-15.24,28.76-33.36,17.68,15.24Zm-16.27-15.35L318,201.35l27.45-31.85-16.17-13.93Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 323.63px 178.46px;" id="eld4ysf9ygtwp"
				  class="animable"></path>
			<g id="el31f8hq1vhi6">
				<rect x="318.84" y="157.9" width="33" height="2.91"
					  style="fill: rgb(255, 255, 255); transform-origin: 335.34px 159.355px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M346.94,171.93l-25.75-22.2,2.55-3L349.49,169Zm-24.34-22.3,24.24,20.89,1.24-1.45-24.23-20.89Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 335.34px 159.33px;" id="elbig489mixyk"
				  class="animable"></path>
			<g id="eluf7m0ul302">
				<rect x="325.32" y="160.3" width="25.63" height="2.91"
					  style="fill: rgb(255, 255, 255); transform-origin: 338.135px 161.755px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M346.94,171.93l-20.17-17.39,2.55-3L349.49,169Zm-18.76-17.49,18.66,16.08,1.24-1.45L329.42,153Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 338.13px 161.735px;" id="el510cipx3ulo"
				  class="animable"></path>
			<g id="eluv04m01bow">
				<rect x="326.44" y="159.37" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 328.655px 161.43px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el724g7ug954c">
				<rect x="332.23" y="164.36" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 334.445px 166.42px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elc0fdu6hqkz">
				<rect x="338.03" y="169.35" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 340.245px 171.41px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el5hyn0m9fx4w">
				<rect x="321.5" y="153.75" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 322.145px 155.81px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elbl7qvoy75qr">
				<rect x="323.18" y="155.2" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 323.825px 157.26px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elhkjr7zmzosi">
				<rect x="317.37" y="158.54" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 318.015px 160.6px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el9teza5r7ua">
				<rect x="319.06" y="159.99" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 319.705px 162.05px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elofmsatwjc6">
				<rect x="313.25" y="163.32" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 313.895px 165.38px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el4nw8dhes8io">
				<rect x="314.93" y="164.77" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 315.575px 166.83px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elfjbmcs49qtd">
				<rect x="309.12" y="168.11" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 309.765px 170.17px; transform: rotate(40.77deg);"
					  class="animable"></rect>
			</g>
			<g id="elvnuraktj0k">
				<rect x="310.81" y="169.56" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 311.455px 171.62px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eleqw3lm0rndq">
				<rect x="305" y="172.89" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 305.645px 174.95px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elxemadpsvtbh">
				<rect x="306.68" y="174.34" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 307.325px 176.4px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el3d0y806vpry">
				<rect x="322.32" y="164.15" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 324.535px 166.21px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el3887vn7uooh">
				<rect x="328.11" y="169.14" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 330.325px 171.2px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elpkvxfela0d">
				<rect x="333.9" y="174.14" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 336.115px 176.2px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elmu5hd4d991l">
				<rect x="318.19" y="168.94" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 320.405px 171px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elhvjm63rb45">
				<rect x="323.98" y="173.93" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 326.195px 175.99px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<g id="el6fec123t5nl">
				<rect x="329.78" y="178.92" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 331.995px 180.98px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eldfmzyir49e">
				<rect x="314.07" y="173.72" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 316.285px 175.78px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el04gjt409jd4l">
				<rect x="319.86" y="178.71" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 322.075px 180.77px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elxksrvi2nejq">
				<rect x="325.65" y="183.71" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 327.865px 185.77px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eldt4bkattr3j">
				<rect x="309.94" y="178.51" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 312.155px 180.57px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eleki2v3czu4k">
				<rect x="315.73" y="183.5" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 317.945px 185.56px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elsu9908f41i">
				<rect x="321.53" y="188.49" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 323.745px 190.55px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elu1yo8hdj44g">
				<rect x="342.18" y="182.56" width="22.34" height="43.04"
					  style="fill: rgb(255, 255, 255); transform-origin: 353.35px 204.08px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M347.82,228.38l-17.68-15.24,28.75-33.36L376.57,195ZM331.55,213,347.71,227l27.45-31.84L359,181.19Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 353.355px 204.08px;" id="elqvxz79oyzq"
				  class="animable"></path>
			<g id="elihv53gavr3n">
				<rect x="347.67" y="187.29" width="22.34" height="43.04"
					  style="fill: rgb(255, 255, 255); transform-origin: 358.84px 208.81px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M353.3,233.11l-17.68-15.24,28.76-33.36,17.68,15.24ZM337,217.76,353.2,231.7l27.45-31.84-16.17-13.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 358.84px 208.81px;" id="elc44b66pptpj"
				  class="animable"></path>
			<g id="elchm8q9nxzgp">
				<rect x="354.05" y="188.25" width="33" height="2.91"
					  style="fill: rgb(255, 255, 255); transform-origin: 370.55px 189.705px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M382.15,202.28l-25.75-22.2,2.55-3,25.75,22.2ZM357.81,180l24.24,20.89,1.24-1.44-24.23-20.89Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 370.55px 189.68px;" id="elzgrqa4825zd"
				  class="animable"></path>
			<g id="elhuxe5m29v6o">
				<rect x="360.53" y="190.65" width="25.63" height="2.91"
					  style="fill: rgb(255, 255, 255); transform-origin: 373.345px 192.105px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<path d="M382.15,202.28,362,184.89l2.55-3,20.17,17.39Zm-18.76-17.49,18.66,16.08,1.24-1.44-18.65-16.09Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 373.36px 192.085px;" id="els3xr15an2g"
				  class="animable"></path>
			<g id="elzdvz4pexldj">
				<rect x="361.65" y="189.72" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 363.865px 191.78px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="eltj6qodk865k">
				<rect x="367.45" y="194.71" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 369.665px 196.77px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elb7771qr9of">
				<rect x="373.24" y="199.7" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 375.455px 201.76px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<g id="elp4xk02kdksb">
				<rect x="356.71" y="184.1" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 357.355px 186.16px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elb8g4j641q4b">
				<rect x="358.39" y="185.56" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 359.035px 187.62px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elaxq7v160ypl">
				<rect x="352.58" y="188.89" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 353.225px 190.95px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elq52oxxfav7">
				<rect x="354.27" y="190.34" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 354.915px 192.4px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el99e0o0bxvp">
				<rect x="348.46" y="193.67" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 349.105px 195.73px; transform: rotate(40.77deg);"
					  class="animable"></rect>
			</g>
			<g id="elu5lv3klzj7">
				<rect x="350.14" y="195.13" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 350.785px 197.19px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elnji9gosxjv9">
				<rect x="344.33" y="198.46" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 344.975px 200.52px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elyzo6eler1q">
				<rect x="346.02" y="199.91" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 346.665px 201.97px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="ellv0855km73">
				<rect x="340.21" y="203.25" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 340.855px 205.31px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elekzq76lrjyj">
				<rect x="341.89" y="204.7" width="1.29" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 342.535px 206.76px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el3sgygom1h52">
				<rect x="357.53" y="194.51" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 359.745px 196.57px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elj5yoj3j8n9c">
				<rect x="363.32" y="199.5" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 365.535px 201.56px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elulw5iz7ki5">
				<rect x="369.11" y="204.49" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 371.325px 206.55px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<g id="elgaoxf9ftmk8">
				<rect x="353.4" y="199.29" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 355.615px 201.35px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elpwb0rik5jy">
				<rect x="359.2" y="204.28" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 361.415px 206.34px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el9l30qbtb3i">
				<rect x="364.99" y="209.27" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 367.205px 211.33px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="el54vv0r2i51m">
				<rect x="349.28" y="204.08" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 351.495px 206.14px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elwrdwg5khd3">
				<rect x="355.07" y="209.07" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 357.285px 211.13px; transform: rotate(40.5392deg);"
					  class="animable"></rect>
			</g>
			<g id="elulmoi1ad8q">
				<rect x="360.86" y="214.06" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 363.075px 216.12px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elutn3dlc5s2s">
				<rect x="345.15" y="208.86" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 347.365px 210.92px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="ele4f4nhslyfs">
				<rect x="350.95" y="213.85" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 353.165px 215.91px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
			<g id="elrjsrmzacojo">
				<rect x="356.74" y="218.84" width="4.43" height="4.12"
					  style="fill: rgb(38, 50, 56); transform-origin: 358.955px 220.9px; transform: rotate(40.76deg);"
					  class="animable"></rect>
			</g>
		</g>
		<g id="freepik--buildings-1--inject-55" class="animable" style="transform-origin: 166.875px 190.34px;">
			<polygon
					points="149.66 236.61 141.34 227.89 148.51 221.05 129.83 201.48 174.21 159.13 166.85 151.41 174.57 144.05 187.98 158.1 192.67 153.63 212.56 174.48 149.66 236.61"
					style="fill: rgb(38, 50, 56); transform-origin: 171.195px 190.33px;" id="elncks3hhpp2k"
					class="animable"></polygon>
			<path d="M149.65,237.32l-9-9.45L147.8,221l-18.67-19.57,44.38-42.35-7.37-7.72,8.44-8L188,157.4l4.68-4.47,20.59,21.56-.35.35Zm-7.6-9.42,7.62,8,62.19-61.43-19.21-20.13L188,158.81l-13.42-14.06-7,6.68,7.36,7.72-44.38,42.34,18.68,19.57Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 171.2px 190.34px;" id="elbdu8ikvc1qp"
				  class="animable"></path>
			<g id="el3nkqwyl787">
				<rect x="144.54" y="209.43" width="14.84" height="19.45"
					  style="fill: rgb(255, 255, 255); transform-origin: 151.96px 219.155px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M153.29,232l-14.12-14.8,11.46-10.93,14.12,14.79Zm-12.7-14.76,12.73,13.35,10-9.56L150.6,207.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 151.96px 219.135px;" id="elvac1b9xr91"
				  class="animable"></path>
			<g id="el07pu8hux0oq7">
				<rect x="150.6" y="207" width="7.81" height="19.45"
					  style="fill: rgb(255, 255, 255); transform-origin: 154.505px 216.725px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M158.38,227.17l-14.12-14.8,6.37-6.08,14.12,14.79ZM145.67,212.4l12.74,13.35,4.93-4.7L150.6,207.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 154.505px 216.73px;" id="ell79g50yb2a"
				  class="animable"></path>
			<polygon points="124.62 167.87 130.38 179.17 167.29 217.85 177.57 208.04 132.27 160.57 124.62 167.87"
					 style="fill: rgb(255, 255, 255); transform-origin: 151.095px 189.21px;" id="eldrdg3e3qlyc"
					 class="animable"></polygon>
			<path d="M167.28,218.56l-37.34-39.17L124,167.77l8.29-7.91,46,48.2Zm-36.49-39.69,36.52,38.27,9.55-9.12-44.6-46.75-7,6.71Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 151.145px 189.21px;" id="el36334znhhcf"
				  class="animable"></path>
			<polygon points="121.1 171.23 126.86 182.52 163.78 221.21 174.06 211.4 128.75 163.92 121.1 171.23"
					 style="fill: rgb(255, 255, 255); transform-origin: 147.58px 192.565px;" id="elvcnxc0e4sqp"
					 class="animable"></polygon>
			<path d="M163.76,221.91l-37.34-39.16-5.94-11.63,8.29-7.9,46,48.19Zm-36.48-39.68,36.51,38.27,9.56-9.12-44.61-46.75-7,6.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 147.625px 192.565px;" id="el0fii8i7xgd4"
				  class="animable"></path>
			<line x1="126.71" y1="166.39" x2="171.76" y2="213.59"
				  style="fill: rgb(255, 255, 255); transform-origin: 149.235px 189.99px;" id="ellbr33q4woo"
				  class="animable"></line>
			<g id="elgl2t53xr4v">
				<rect x="148.73" y="157.37" width="1" height="65.25"
					  style="fill: rgb(38, 50, 56); transform-origin: 149.23px 189.995px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<line x1="124.51" y1="175.72" x2="129.38" y2="171.08"
				  style="fill: rgb(255, 255, 255); transform-origin: 126.945px 173.4px;" id="elhijdssty96h"
				  class="animable"></line>
			<g id="el3hvn7q9pxkv">
				<rect x="123.58" y="172.9" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 126.945px 173.4px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="128.38" y1="179.78" x2="133.25" y2="175.14"
				  style="fill: rgb(255, 255, 255); transform-origin: 130.815px 177.46px;" id="elg1nqec9razl"
				  class="animable"></line>
			<g id="el6ld90r2msf8">
				<rect x="127.45" y="176.96" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 130.815px 177.46px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="132.26" y1="183.84" x2="137.12" y2="179.19"
				  style="fill: rgb(255, 255, 255); transform-origin: 134.69px 181.515px;" id="eldv3mzyp2nyw"
				  class="animable"></line>
			<g id="elqdd83zsk0k">
				<rect x="131.32" y="181.02" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 134.685px 181.52px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="136.13" y1="187.9" x2="141" y2="183.25"
				  style="fill: rgb(255, 255, 255); transform-origin: 138.565px 185.575px;" id="el2t9msajdnrv"
				  class="animable"></line>
			<g id="elxn2kwpcynyf">
				<rect x="135.2" y="185.07" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 138.565px 185.57px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="140" y1="191.95" x2="144.87" y2="187.31"
				  style="fill: rgb(255, 255, 255); transform-origin: 142.435px 189.63px;" id="elay3gsysomhp"
				  class="animable"></line>
			<g id="elib6gkeshie7">
				<rect x="139.07" y="189.13" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 142.435px 189.63px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="143.87" y1="196.01" x2="148.74" y2="191.37"
				  style="fill: rgb(255, 255, 255); transform-origin: 146.305px 193.69px;" id="eldcyqjx3t517"
				  class="animable"></line>
			<g id="el4lhoatzj6my">
				<rect x="142.94" y="193.19" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 146.305px 193.69px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="147.74" y1="200.07" x2="152.61" y2="195.42"
				  style="fill: rgb(255, 255, 255); transform-origin: 150.175px 197.745px;" id="el4pqfdmuk74t"
				  class="animable"></line>
			<g id="el5urmpsp39zf">
				<rect x="146.81" y="197.25" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 150.175px 197.75px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="151.61" y1="204.13" x2="156.48" y2="199.48"
				  style="fill: rgb(255, 255, 255); transform-origin: 154.045px 201.805px;" id="elzaq68x3dpg"
				  class="animable"></line>
			<g id="el1430cvxy3bao">
				<rect x="150.68" y="201.3" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 154.045px 201.8px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="155.49" y1="208.18" x2="160.35" y2="203.54"
				  style="fill: rgb(255, 255, 255); transform-origin: 157.92px 205.86px;" id="elum3lst5dsq"
				  class="animable"></line>
			<g id="el5uvu1rwsiod">
				<rect x="154.56" y="205.36" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 157.925px 205.86px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="159.36" y1="212.24" x2="164.23" y2="207.6"
				  style="fill: rgb(255, 255, 255); transform-origin: 161.795px 209.92px;" id="elql78fenwsib"
				  class="animable"></line>
			<g id="eldhy4vsirqh">
				<rect x="158.43" y="209.42" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 161.795px 209.92px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<line x1="163.23" y1="216.3" x2="168.1" y2="211.65"
				  style="fill: rgb(255, 255, 255); transform-origin: 165.665px 213.975px;" id="elm7vtpl7n5j"
				  class="animable"></line>
			<g id="ellnullmjv6zf">
				<rect x="162.3" y="213.48" width="6.73" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 165.665px 213.98px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<g id="elbxndakdwzoe">
				<rect x="155.26" y="150.57" width="18.38" height="58.8"
					  style="fill: rgb(255, 255, 255); transform-origin: 164.45px 179.97px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M178.08,208.29,136.8,165l14-13.39,41.29,43.27Zm-39.87-43.23,39.91,41.82,12.57-12-39.9-41.82Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 164.445px 179.95px;" id="elxpjwb6ghlqe"
				  class="animable"></path>
			<g id="ele9as2svzyl">
				<rect x="159.57" y="146.45" width="18.38" height="58.8"
					  style="fill: rgb(255, 255, 255); transform-origin: 168.76px 175.85px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M182.4,204.18l-41.29-43.27,14-13.38,41.29,43.27Zm-39.87-43.24,39.9,41.83,12.58-12L155.1,148.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 168.755px 175.855px;" id="elgk1wgt3y7z"
				  class="animable"></path>
			<g id="eliycdx2yhtmf">
				<rect x="132.18" y="154.05" width="27.15" height="3.97"
					  style="fill: rgb(255, 255, 255); transform-origin: 145.755px 156.035px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M137.29,167.55l-3.43-3.6,20.36-19.43,3.44,3.6Zm-2-3.56,2,2.15,18.92-18-2.05-2.15Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 145.76px 156.035px;" id="el0tk57860aes"
				  class="animable"></path>
			<g id="el5giz75qh0q4">
				<rect x="137.41" y="151.96" width="21.09" height="3.97"
					  style="fill: rgb(255, 255, 255); transform-origin: 147.955px 153.945px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M141.68,163.37l-3.44-3.6,16-15.25,3.44,3.6Zm-2-3.57,2.05,2.16,14.53-13.87-2.05-2.15Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 147.96px 153.945px;" id="el1z90l0whs4b"
				  class="animable"></path>
			<g id="ellq0h8ayd2se">
				<rect x="147.33" y="161.2" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 149.15px 164.015px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elhbuoaevkqe7">
				<rect x="151.88" y="156.86" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 153.7px 159.675px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="el5zq9wbgsh2r">
				<rect x="156.43" y="152.51" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 158.25px 155.325px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elqe1g30ba8d">
				<rect x="143.5" y="166.08" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 144.03px 168.895px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elz0l7iqdd9cp">
				<rect x="144.82" y="164.82" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 145.35px 167.635px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="eli4sjcvauds">
				<rect x="149.46" y="172.33" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 149.99px 175.145px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elb1ceuq8glr8">
				<rect x="150.78" y="171.07" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 151.31px 173.885px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="el7yynuqtyjs6">
				<rect x="155.42" y="178.57" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 155.95px 181.385px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elfsm7rofmix8">
				<rect x="156.74" y="177.31" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 157.27px 180.125px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elfz4bwx7pfu7">
				<rect x="161.38" y="184.82" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 161.91px 187.635px; transform: rotate(-43.7811deg);"
					  class="animable"></rect>
			</g>
			<g id="el1h2mdxsyxno">
				<rect x="162.7" y="183.56" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 163.23px 186.375px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elg20cp8hq2i">
				<rect x="167.34" y="191.06" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 167.87px 193.875px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elfuei6is4fx">
				<rect x="168.66" y="189.8" width="1.06" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 169.19px 192.615px; transform: rotate(-43.7811deg);"
					  class="animable"></rect>
			</g>
			<g id="elggqgj6g5tkd">
				<rect x="153.29" y="167.44" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 155.11px 170.255px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elvublcv9yhuc">
				<rect x="157.84" y="163.1" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 159.66px 165.915px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elbh5kvj0fth">
				<rect x="162.39" y="158.76" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 164.21px 161.575px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elvv3ptethd8n">
				<rect x="159.25" y="173.69" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 161.07px 176.505px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elyvua5nymghg">
				<rect x="163.8" y="169.35" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 165.62px 172.165px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elatson7ed6l">
				<rect x="168.35" y="165" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 170.17px 167.815px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elzg926uohpem">
				<rect x="165.21" y="179.93" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 167.03px 182.745px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="eltt9f3g87tdb">
				<rect x="169.76" y="175.59" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 171.58px 178.405px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elgmdmxvd8lzh">
				<rect x="174.31" y="171.25" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 176.13px 174.065px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="el4pgk08xo57l">
				<rect x="171.16" y="186.18" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 172.98px 188.995px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elcarmelso0ef">
				<rect x="175.72" y="181.84" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 177.54px 184.655px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elwhfzbm86vd">
				<rect x="180.27" y="177.49" width="3.64" height="5.63"
					  style="fill: rgb(38, 50, 56); transform-origin: 182.09px 180.305px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<g id="elo5lycs4qxd">
				<rect x="185.03" y="176.29" width="18.05" height="12.29"
					  style="fill: rgb(255, 255, 255); transform-origin: 194.055px 182.435px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M191.76,193.82l-9.18-9.62,13.78-13.15,9.18,9.62ZM184,184.23l7.8,8.17,12.33-11.77-7.79-8.17Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 194.06px 182.435px;" id="elvubnr0szler"
				  class="animable"></path>
			<g id="elu12z65yb2co">
				<rect x="187.99" y="175.1" width="14.62" height="12.29"
					  style="fill: rgb(255, 255, 255); transform-origin: 195.3px 181.245px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M194.24,191.45l-9.18-9.62,11.3-10.78,9.18,9.62Zm-7.77-9.58,7.8,8.17,9.85-9.41-7.79-8.17Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 195.3px 181.25px;" id="elucrj3m5cs8s"
				  class="animable"></path>
			<g id="elc9veri935sp">
				<rect x="177.71" y="174.83" width="21.69" height="3.65"
					  style="fill: rgb(255, 255, 255); transform-origin: 188.555px 176.655px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M182,186.16l-3.21-3.36,16.42-15.66,3.2,3.36Zm-1.79-3.33,1.82,1.92,15-14.28-1.83-1.92Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 188.6px 176.65px;" id="elbum85xxaobw"
				  class="animable"></path>
			<g id="ely7wiw9akm3">
				<rect x="180.82" y="173.58" width="18.08" height="3.65"
					  style="fill: rgb(255, 255, 255); transform-origin: 189.86px 175.405px; transform: rotate(-43.66deg);"
					  class="animable"></rect>
			</g>
			<path d="M184.56,183.67l-3.21-3.36,13.81-13.17,3.2,3.36Zm-1.79-3.33,1.82,1.92L197,170.47l-1.83-1.92Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 189.855px 175.405px;" id="elg310y64pflr"
				  class="animable"></path>
			<g id="elutkydlxj48p">
				<rect x="187.15" y="178.24" width="11.36" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 192.83px 178.74px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<g id="eldxgncyhdnbe">
				<rect x="188.72" y="179.88" width="11.36" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 194.4px 180.38px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<g id="eldu8c8zwgdj">
				<rect x="190.29" y="181.53" width="11.36" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 195.97px 182.03px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
			<g id="el3sv22rhoyx">
				<rect x="191.86" y="183.17" width="11.36" height="1"
					  style="fill: rgb(38, 50, 56); transform-origin: 197.54px 183.67px; transform: rotate(-43.65deg);"
					  class="animable"></rect>
			</g>
		</g>
		<g id="freepik--Factory--inject-55" class="animable" style="transform-origin: 216.5px 121.296px;">
			<g id="elrzm70djartc">
				<path d="M247.7,94.93s.52,2,1.43-10.36-7.25-14.79-12.08-11.77-6.64.6-11.77-7.85-18.41-13.88-24.14-8.15S193.89,67.37,190,64.35s-9.06-10.87-16-9.66-8.75,10.86-11.47,12.07-9.05-7.85-16.3-3.92-7.24,7.85-8.15,9.36-4.22-3.93-9.35-3.32-6.95,5.73-6.95,5.73H218A42.13,42.13,0,0,1,230.11,80c6.64,4.23,10.26,1.29,12.37,1s1.78,13.9,1.78,13.9Z"
					  style="opacity: 0.19; transform-origin: 185.49px 74.7407px;" class="animable"></path>
			</g>
			<g id="elxooeugtlyns">
				<path d="M261,94.93s.52,2,1.42-10.36-7.21-14.79-12-11.77-6.6.6-11.71-7.85-18.32-13.88-24-8.15-7.2,10.57-11.11,7.55-9-10.87-15.91-9.66-8.71,10.86-11.42,12.07-9-7.85-16.21-3.92-7.21,7.85-8.11,9.36-4.21-3.93-9.31-3.32-6.91,5.73-6.91,5.73h95.8a41.84,41.84,0,0,1,12,5.43c6.6,4.23,10.2,1.29,12.3,1s1.77,13.9,1.77,13.9Z"
					  style="opacity: 0.19; transform-origin: 199.11px 74.7407px;" class="animable"></path>
			</g>
			<g id="elemlr7ayo2s5">
				<path d="M274.86,94.93s.51,2,1.41-10.36-7.2-14.79-12-11.77-6.61.6-11.71-7.85-18.32-13.88-24-8.15-7.21,10.57-11.11,7.55-9-10.87-15.92-9.66-8.71,10.86-11.41,12.07-9-7.85-16.22-3.92-7.21,7.85-8.11,9.36-4.2-3.93-9.31-3.32-6.91,5.73-6.91,5.73h95.81a41.72,41.72,0,0,1,12,5.43c6.61,4.23,10.21,1.29,12.31,1s1.77,13.9,1.77,13.9Z"
					  style="opacity: 0.19; transform-origin: 212.955px 74.7407px;" class="animable"></path>
			</g>
			<polygon points="248.37 94.75 243.23 94.75 240.66 158.68 250.93 158.68 248.37 94.75"
					 style="fill: rgb(255, 114, 94); transform-origin: 245.795px 126.715px;" id="elgsdr8b173hp"
					 class="animable"></polygon>
			<path d="M251.46,159.18H240.14l2.61-64.93h6.1Zm-10.28-1h9.23l-2.52-62.93h-4.18Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 126.715px;" id="elf3et0dk24z"
				  class="animable"></path>
			<path d="M248.33,95.31h-5.07a.55.55,0,0,1-.55-.55h0a.55.55,0,0,1,.55-.55h5.07a.54.54,0,0,1,.55.55h0A.55.55,0,0,1,248.33,95.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 245.795px 94.7599px;" id="elg62epkj49jv"
				  class="animable"></path>
			<path d="M248.33,95.81h-5.07a1.05,1.05,0,1,1,0-2.1h5.07a1,1,0,0,1,0,2.1Zm-5.07-1.1v.1h5.07a0,0,0,0,0,0,0Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.794px 94.76px;" id="el7pq1e5gqibo"
				  class="animable"></path>
			<polygon points="243.11 97.68 243.02 99.99 248.58 99.99 248.48 97.68 243.11 97.68"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 98.835px;" id="elfkm2p3ziio"
					 class="animable"></polygon>
			<path d="M249.1,100.49h-6.6l.13-3.31H249Zm-5.56-1h4.52L248,98.18h-4.41Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 98.835px;" id="ellqua94gf79c"
				  class="animable"></path>
			<polygon points="250.58 149.88 250.49 147.57 241.11 147.57 241.02 149.88 250.58 149.88"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 148.725px;" id="elpx8ym6ertjn"
					 class="animable"></polygon>
			<path d="M251.1,150.38H240.5l.13-3.31H251Zm-9.56-1h8.52l-.05-1.31h-8.42Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 148.725px;" id="elmqea6cjrz8g"
				  class="animable"></path>
			<polygon points="241.68 133.32 241.59 135.62 250.01 135.62 249.92 133.32 241.68 133.32"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 134.47px;" id="eluyahjngps1"
					 class="animable"></polygon>
			<path d="M250.53,136.12h-9.46l.13-3.3h9.2Zm-8.42-1h7.38l-.05-1.3h-7.28Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 134.47px;" id="el0gwg05dxbxx5"
				  class="animable"></path>
			<polygon points="241.4 140.44 241.3 142.75 250.29 142.75 250.2 140.44 241.4 140.44"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.795px 141.595px;" id="el325eyuvsvg4"
					 class="animable"></polygon>
			<path d="M250.82,143.25h-10l.14-3.31h9.76Zm-9-1h8l-.05-1.31h-7.84Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.82px 141.595px;" id="el6hw9c4ipn8l"
				  class="animable"></path>
			<polygon points="241.97 126.19 241.88 128.5 249.72 128.5 249.63 126.19 241.97 126.19"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 127.345px;" id="elhz0rtmkhyzl"
					 class="animable"></polygon>
			<path d="M250.24,129h-8.88l.13-3.31h8.62Zm-7.84-1h6.8l0-1.31h-6.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 127.345px;" id="elh17ns63hgnl"
				  class="animable"></path>
			<polygon points="242.83 104.81 242.73 107.11 248.86 107.11 248.77 104.81 242.83 104.81"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.795px 105.96px;" id="elizh7ylrgdy9"
					 class="animable"></polygon>
			<path d="M249.38,107.61h-7.17l.14-3.3h6.9Zm-6.13-1h5.09l-.05-1.3h-5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.795px 105.96px;" id="elzsqrld7zpw"
				  class="animable"></path>
			<polygon points="242.25 119.06 242.16 121.37 249.44 121.37 249.34 119.06 242.25 119.06"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 120.215px;" id="ellsj9crrb86f"
					 class="animable"></polygon>
			<path d="M250,121.87h-8.32l.13-3.31h8Zm-7.28-1h6.24l-.06-1.31h-6.13Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.84px 120.215px;" id="eljrota0u1b4s"
				  class="animable"></path>
			<polygon points="242.54 111.94 242.45 114.24 249.15 114.24 249.06 111.94 242.54 111.94"
					 style="fill: rgb(255, 255, 255); transform-origin: 245.8px 113.09px;" id="elvabsxwil67b"
					 class="animable"></polygon>
			<path d="M249.67,114.74h-7.74l.13-3.3h7.48Zm-6.7-1h5.66l0-1.3H243Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.8px 113.09px;" id="el96wgaxakoab"
				  class="animable"></path>
			<polygon points="262.2 94.75 257.07 94.75 254.5 158.68 264.77 158.68 262.2 94.75"
					 style="fill: rgb(255, 114, 94); transform-origin: 259.635px 126.715px;" id="elbz6xfzlf9q"
					 class="animable"></polygon>
			<path d="M265.29,159.18H254l2.61-64.93h6.09Zm-10.27-1h9.23l-2.53-62.93h-4.17Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.645px 126.715px;" id="elolc42f3zb4"
				  class="animable"></path>
			<path d="M262.17,95.31H257.1a.55.55,0,0,1-.55-.55h0a.54.54,0,0,1,.55-.55h5.07a.55.55,0,0,1,.55.55h0A.55.55,0,0,1,262.17,95.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 259.635px 94.7599px;" id="elys1dy7dwiy"
				  class="animable"></path>
			<path d="M262.17,95.81H257.1a1,1,0,0,1,0-2.1h5.07a1,1,0,1,1,0,2.1Zm-5.07-1.1v.1h5.07a0,0,0,0,0,.05,0Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 94.76px;" id="el8pvia4llct"
				  class="animable"></path>
			<polygon points="256.95 97.68 256.86 99.99 262.41 99.99 262.32 97.68 256.95 97.68"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 98.835px;" id="elu7sch2t8lrp"
					 class="animable"></polygon>
			<path d="M262.93,100.49h-6.59l.13-3.31h6.33Zm-5.55-1h4.51l-.05-1.31h-4.41Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 98.835px;" id="elgnmf7jsdudf"
				  class="animable"></path>
			<polygon points="264.42 149.88 264.32 147.57 254.94 147.57 254.85 149.88 264.42 149.88"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 148.725px;" id="elawp5ejo0mvq"
					 class="animable"></polygon>
			<path d="M264.94,150.38H254.33l.13-3.31H264.8Zm-9.57-1h8.53l-.06-1.31h-8.42Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 148.725px;" id="elnwrsj1feyvj"
				  class="animable"></path>
			<polygon points="255.52 133.32 255.42 135.62 263.84 135.62 263.75 133.32 255.52 133.32"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.63px 134.47px;" id="elsnt0nkmpv0o"
					 class="animable"></polygon>
			<path d="M264.36,136.12H254.9l.14-3.3h9.19Zm-8.42-1h7.38l-.05-1.3H256Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.63px 134.47px;" id="el08fz0n0mvpiv"
				  class="animable"></path>
			<polygon points="255.23 140.44 255.14 142.75 264.13 142.75 264.04 140.44 255.23 140.44"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 141.595px;" id="el1ol1wr13a68"
					 class="animable"></polygon>
			<path d="M264.65,143.25h-10l.13-3.31h9.77Zm-9-1h8l-.05-1.31h-7.85Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.65px 141.595px;" id="el70fp8mgzyxp"
				  class="animable"></path>
			<polygon points="255.8 126.19 255.71 128.5 263.56 128.5 263.46 126.19 255.8 126.19"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 127.345px;" id="elb9nuunkr1cg"
					 class="animable"></polygon>
			<path d="M264.08,129h-8.89l.13-3.31h8.62Zm-7.85-1H263l-.06-1.31h-6.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 127.345px;" id="elblrm3of8tvf"
				  class="animable"></path>
			<polygon points="256.66 104.81 256.57 107.11 262.7 107.11 262.61 104.81 256.66 104.81"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 105.96px;" id="elv0mlkvtiyab"
					 class="animable"></polygon>
			<path d="M263.22,107.61h-7.17l.13-3.3h6.91Zm-6.13-1h5.09l-.05-1.3h-5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 105.96px;" id="elemgz52yozg6"
				  class="animable"></path>
			<polygon points="256.09 119.06 256 121.37 263.27 121.37 263.18 119.06 256.09 119.06"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.635px 120.215px;" id="el5belwnqxaqe"
					 class="animable"></polygon>
			<path d="M263.79,121.87h-8.31l.13-3.31h8.05Zm-7.27-1h6.23l-.05-1.31h-6.13Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 120.215px;" id="el6em0jtoo9h4"
				  class="animable"></path>
			<polygon points="256.38 111.94 256.28 114.24 262.98 114.24 262.89 111.94 256.38 111.94"
					 style="fill: rgb(255, 255, 255); transform-origin: 259.63px 113.09px;" id="elzcr3u4jfa4p"
					 class="animable"></polygon>
			<path d="M263.51,114.74h-7.75l.14-3.3h7.47Zm-6.71-1h5.66l0-1.3h-5.55Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 259.635px 113.09px;" id="elcdg70zqln5o"
				  class="animable"></path>
			<polygon points="276.04 94.75 270.9 94.75 268.33 158.68 278.6 158.68 276.04 94.75"
					 style="fill: rgb(255, 114, 94); transform-origin: 273.465px 126.715px;" id="ellrr12mx4ur"
					 class="animable"></polygon>
			<path d="M279.12,159.18H267.81l2.61-64.93h6.1Zm-10.27-1h9.23l-2.52-62.93h-4.18Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.465px 126.715px;" id="el5xyqxu242jt"
				  class="animable"></path>
			<path d="M276,95.31h-5.07a.55.55,0,0,1-.55-.55h0a.55.55,0,0,1,.55-.55H276a.54.54,0,0,1,.55.55h0A.55.55,0,0,1,276,95.31Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 273.465px 94.7599px;" id="eloyp3927hyb"
				  class="animable"></path>
			<path d="M276,95.81h-5.07a1,1,0,0,1,0-2.1H276a1,1,0,1,1,0,2.1Zm-5.07-1.1v.1H276a0,0,0,0,0,.05,0Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.465px 94.76px;" id="ellqd7epty9qd"
				  class="animable"></path>
			<polygon points="270.78 97.68 270.69 99.99 276.25 99.99 276.15 97.68 270.78 97.68"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 98.835px;" id="elbclod15db3m"
					 class="animable"></polygon>
			<path d="M276.77,100.49h-6.6l.13-3.31h6.33Zm-5.56-1h4.52l-.06-1.31h-4.41Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.47px 98.835px;" id="el141qat2nrhn"
				  class="animable"></path>
			<polygon points="278.25 149.88 278.16 147.57 268.78 147.57 268.69 149.88 278.25 149.88"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 148.725px;" id="elmj92tne9qss"
					 class="animable"></polygon>
			<path d="M278.77,150.38h-10.6l.13-3.31h10.34Zm-9.56-1h8.52l-.05-1.31h-8.42Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.47px 148.725px;" id="el18tsv29ttos"
				  class="animable"></path>
			<polygon points="269.35 133.32 269.26 135.62 277.68 135.62 277.59 133.32 269.35 133.32"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 134.47px;" id="elqvyq4bgxo6n"
					 class="animable"></polygon>
			<path d="M278.2,136.12h-9.46l.13-3.3h9.2Zm-8.42-1h7.38l-.05-1.3h-7.28Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.47px 134.47px;" id="elqdlwh96ex1"
				  class="animable"></path>
			<polygon points="269.06 140.44 268.97 142.75 277.96 142.75 277.87 140.44 269.06 140.44"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.465px 141.595px;" id="elhkhj0ne4vb"
					 class="animable"></polygon>
			<path d="M278.48,143.25h-10l.14-3.31h9.76Zm-9-1h7.95l-.05-1.31h-7.84Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.48px 141.595px;" id="elrxfggs4i9h"
				  class="animable"></path>
			<polygon points="269.64 126.19 269.55 128.5 277.39 128.5 277.3 126.19 269.64 126.19"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 127.345px;" id="elzbs1uheb6w"
					 class="animable"></polygon>
			<path d="M277.91,129H269l.14-3.31h8.62Zm-7.84-1h6.8l-.05-1.31h-6.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.455px 127.345px;" id="elhhttw14gjof"
				  class="animable"></path>
			<polygon points="270.5 104.81 270.4 107.11 276.53 107.11 276.44 104.81 270.5 104.81"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.465px 105.96px;" id="elhe66w2p5k5"
					 class="animable"></polygon>
			<path d="M277.05,107.61h-7.17l.14-3.3h6.9Zm-6.13-1H276l-.05-1.3h-5Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.465px 105.96px;" id="el5q14lk5p0f2"
				  class="animable"></path>
			<polygon points="269.92 119.06 269.83 121.37 277.11 121.37 277.01 119.06 269.92 119.06"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 120.215px;" id="eln0psy361ufh"
					 class="animable"></polygon>
			<path d="M277.63,121.87h-8.32l.13-3.31h8.05Zm-7.28-1h6.24l-.06-1.31H270.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.47px 120.215px;" id="elkk0h48wqqb"
				  class="animable"></path>
			<polygon points="270.21 111.94 270.12 114.24 276.82 114.24 276.73 111.94 270.21 111.94"
					 style="fill: rgb(255, 255, 255); transform-origin: 273.47px 113.09px;" id="elw71wzk4ruds"
					 class="animable"></polygon>
			<path d="M277.34,114.74H269.6l.13-3.3h7.48Zm-6.7-1h5.66l-.05-1.3h-5.56Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 273.47px 113.09px;" id="eln34zv3p3uf"
				  class="animable"></path>
			<rect x="199.42" y="144.06" width="20.44" height="43.6"
				  style="fill: rgb(38, 50, 56); transform-origin: 209.64px 165.86px;" id="elniw5ib90tyi"
				  class="animable"></rect>
			<path d="M219.86,188.16H199.42a.5.5,0,0,1-.5-.5v-43.6a.5.5,0,0,1,.5-.5h20.44a.5.5,0,0,1,.5.5v43.6A.51.51,0,0,1,219.86,188.16Zm-19.94-1h19.44v-42.6H199.92Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 209.64px 165.86px;" id="el0loh3fzl9ah"
				  class="animable"></path>
			<polygon points="218.94 138.24 230.01 144.71 227.88 144.71 216.89 139.21 218.94 138.24"
					 style="fill: rgb(255, 255, 255); transform-origin: 223.45px 141.475px;" id="elaoa53iaqea4"
					 class="animable"></polygon>
			<path d="M231.86,145.21h-4.09l-12-6,3.23-1.52Zm-3.86-1h.16l-9.24-5.4-.89.41Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 223.815px 141.45px;" id="elrxyjqw0mx9"
				  class="animable"></path>
			<polygon points="228.85 187.66 208.41 187.66 208.41 144.06 218.94 138.24 228.85 144.06 228.85 187.66"
					 style="fill: rgb(255, 114, 94); transform-origin: 218.63px 162.95px;" id="el4vv8t3u6hns"
					 class="animable"></polygon>
			<path d="M228.85,188.16H208.41a.51.51,0,0,1-.5-.5v-43.6a.51.51,0,0,1,.26-.44l10.53-5.81a.5.5,0,0,1,.5,0l9.9,5.82a.48.48,0,0,1,.25.43v43.6A.5.5,0,0,1,228.85,188.16Zm-19.94-1h19.44V144.35l-9.41-5.53-10,5.53Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 218.63px 162.952px;" id="elpzzc81q2cs"
				  class="animable"></path>
			<polygon points="197.84 145 206.84 145 218.94 138.24 209.83 138.24 197.84 145"
					 style="fill: rgb(143, 143, 143); transform-origin: 208.39px 141.62px;" id="elvme84bti1d"
					 class="animable"></polygon>
			<path d="M206.84,145.5h-9a.5.5,0,0,1-.49-.37.49.49,0,0,1,.24-.56l12-6.76a.55.55,0,0,1,.25-.07H219a.51.51,0,0,1,.48.38.49.49,0,0,1-.24.56l-12.11,6.76A.48.48,0,0,1,206.84,145.5Zm-7.09-1h7L217,138.74H210Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 208.415px 141.621px;" id="ela4fsc23k3b"
				  class="animable"></path>
			<rect x="217.22" y="142.02" width="3.3" height="2.99"
				  style="fill: rgb(38, 50, 56); transform-origin: 218.87px 143.515px;" id="el4w0ddtjd8rc"
				  class="animable"></rect>
			<path d="M221,145.5h-4.3v-4H221Zm-3.3-1H220v-2h-2.3Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 218.85px 143.5px;" id="el89katz2rkqx"
				  class="animable"></path>
			<rect x="211.68" y="150.83" width="66.08" height="36.82"
				  style="fill: rgb(38, 50, 56); transform-origin: 244.72px 169.24px;" id="elw6snfxcm26"
				  class="animable"></rect>
			<path d="M277.76,188.16H211.68a.5.5,0,0,1-.5-.5V150.83a.5.5,0,0,1,.5-.5h66.08a.51.51,0,0,1,.5.5v36.83A.51.51,0,0,1,277.76,188.16Zm-65.58-1h65.08V151.33H212.18Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 244.72px 169.245px;" id="elfv3cpwrp5z"
				  class="animable"></path>
			<rect x="240.76" y="150.83" width="69.96" height="36.82"
				  style="fill: rgb(255, 255, 255); transform-origin: 275.74px 169.24px;" id="elho2pcnky47"
				  class="animable"></rect>
			<path d="M310.72,188.16h-70a.5.5,0,0,1-.5-.5V150.83a.5.5,0,0,1,.5-.5h70a.51.51,0,0,1,.5.5v36.83A.51.51,0,0,1,310.72,188.16Zm-69.46-1h69V151.33h-69Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.72px 169.245px;" id="el2faat6q00qo"
				  class="animable"></path>
			<rect x="249.71" y="164.94" width="11.53" height="22.71"
				  style="fill: rgb(255, 255, 255); transform-origin: 255.475px 176.295px;" id="eldw39lw0blnn"
				  class="animable"></rect>
			<path d="M261.24,188.16H249.71a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5h11.53a.5.5,0,0,1,.5.5v22.72A.5.5,0,0,1,261.24,188.16Zm-11-1h10.53V165.44H250.21Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 255.475px 176.3px;" id="el4t8v3p9nwef"
				  class="animable"></path>
			<rect x="249.71" y="164.94" width="9.64" height="22.71"
				  style="fill: rgb(38, 50, 56); transform-origin: 254.53px 176.295px;" id="ellan46vjzg3q"
				  class="animable"></rect>
			<path d="M259.35,188.16h-9.64a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5h9.64a.51.51,0,0,1,.5.5v22.72A.51.51,0,0,1,259.35,188.16Zm-9.14-1h8.64V165.44h-8.64Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 254.53px 176.3px;" id="ellqnbutnd78h"
				  class="animable"></path>
			<rect x="270.36" y="164.94" width="11.53" height="22.71"
				  style="fill: rgb(255, 255, 255); transform-origin: 276.125px 176.295px;" id="ellrocfu8n2vo"
				  class="animable"></rect>
			<path d="M281.89,188.16H270.36a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5h11.53a.51.51,0,0,1,.5.5v22.72A.51.51,0,0,1,281.89,188.16Zm-11-1h10.53V165.44H270.86Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 276.125px 176.3px;" id="el5mnhgcnlk7u"
				  class="animable"></path>
			<rect x="270.36" y="164.94" width="9.64" height="22.71"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.18px 176.295px;" id="el96fxt2yco1u"
				  class="animable"></rect>
			<path d="M280,188.16h-9.64a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5H280a.51.51,0,0,1,.5.5v22.72A.51.51,0,0,1,280,188.16Zm-9.14-1h8.64V165.44h-8.64Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.18px 176.3px;" id="elvjjcjyjrhw"
				  class="animable"></path>
			<rect x="291.01" y="164.94" width="11.53" height="22.71"
				  style="fill: rgb(255, 255, 255); transform-origin: 296.775px 176.295px;" id="eln9uy4kcbmc"
				  class="animable"></rect>
			<path d="M302.54,188.16H291a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5h11.53a.51.51,0,0,1,.5.5v22.72A.51.51,0,0,1,302.54,188.16Zm-11-1H302V165.44H291.51Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 296.765px 176.3px;" id="elzo111mhqipq"
				  class="animable"></path>
			<rect x="291.01" y="164.94" width="9.64" height="22.71"
				  style="fill: rgb(38, 50, 56); transform-origin: 295.83px 176.295px;" id="eleco932d8kmg"
				  class="animable"></rect>
			<path d="M300.64,188.16H291a.5.5,0,0,1-.5-.5V164.94a.5.5,0,0,1,.5-.5h9.63a.5.5,0,0,1,.5.5v22.72A.5.5,0,0,1,300.64,188.16Zm-9.13-1h8.63V165.44h-8.63Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 295.815px 176.3px;" id="elwbb9y2yvxtr"
				  class="animable"></path>
			<rect x="214.33" y="155.05" width="6.29" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 217.475px 157.935px;" id="el5eqc69qnswv"
				  class="animable"></rect>
			<path d="M220.62,161.32h-6.29a.51.51,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h6.29a.5.5,0,0,1,.5.5v5.77A.5.5,0,0,1,220.62,161.32Zm-5.79-1h5.29v-4.77h-5.29Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 217.475px 157.935px;" id="el9w08aexckmq"
				  class="animable"></path>
			<rect x="222.84" y="155.05" width="6.29" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 225.985px 157.935px;" id="elhwviggbro0l"
				  class="animable"></rect>
			<path d="M229.13,161.32h-6.29a.5.5,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h6.29a.5.5,0,0,1,.5.5v5.77A.51.51,0,0,1,229.13,161.32Zm-5.79-1h5.29v-4.77h-5.29Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 225.985px 157.935px;" id="elvk2izuon2sa"
				  class="animable"></path>
			<rect x="231.34" y="155.05" width="6.29" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 234.485px 157.935px;" id="elc9wkaty8tnm"
				  class="animable"></rect>
			<path d="M237.63,161.32h-6.29a.5.5,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h6.29a.5.5,0,0,1,.5.5v5.77A.51.51,0,0,1,237.63,161.32Zm-5.79-1h5.29v-4.77h-5.29Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 234.485px 157.935px;" id="ellzvhxu52w9p"
				  class="animable"></path>
			<rect x="250.64" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 255.335px 157.935px;" id="elo9chodfoixo"
				  class="animable"></rect>
			<path d="M260,161.32h-9.39a.5.5,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5H260a.5.5,0,0,1,.5.5v5.77A.5.5,0,0,1,260,161.32Zm-8.89-1h8.39v-4.77h-8.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 255.305px 157.935px;" id="el0pinjjdcje7g"
				  class="animable"></path>
			<rect x="250.1" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 254.795px 157.935px;" id="elueah8toi59"
				  class="animable"></rect>
			<path d="M259.5,161.32h-9.4a.51.51,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h9.4a.5.5,0,0,1,.5.5v5.77A.51.51,0,0,1,259.5,161.32Zm-8.9-1H259v-4.77h-8.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 254.8px 157.935px;" id="el4tpz4sr2nm4"
				  class="animable"></path>
			<rect x="271.27" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 275.965px 157.935px;" id="eltlqbfvztd1"
				  class="animable"></rect>
			<path d="M280.66,161.32h-9.39a.5.5,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h9.39a.5.5,0,0,1,.5.5v5.77A.5.5,0,0,1,280.66,161.32Zm-8.89-1h8.39v-4.77h-8.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.965px 157.935px;" id="el4cveho18b3h"
				  class="animable"></path>
			<rect x="270.73" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 275.425px 157.935px;" id="elp687yjalchr"
				  class="animable"></rect>
			<path d="M280.13,161.32h-9.4a.51.51,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h9.4a.5.5,0,0,1,.5.5v5.77A.51.51,0,0,1,280.13,161.32Zm-8.9-1h8.4v-4.77h-8.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 275.43px 157.935px;" id="elsomq3tjv62h"
				  class="animable"></path>
			<rect x="291.9" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 296.595px 157.935px;" id="el6evkcg9xfo9"
				  class="animable"></rect>
			<path d="M301.29,161.32H291.9a.5.5,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h9.39a.5.5,0,0,1,.5.5v5.77A.5.5,0,0,1,301.29,161.32Zm-8.89-1h8.39v-4.77H292.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 296.595px 157.935px;" id="elr1v2e7ijwac"
				  class="animable"></path>
			<rect x="291.36" y="155.05" width="9.39" height="5.77"
				  style="fill: rgb(255, 255, 255); transform-origin: 296.055px 157.935px;" id="elirzp6sxr1b9"
				  class="animable"></rect>
			<path d="M300.76,161.32h-9.4a.51.51,0,0,1-.5-.5v-5.77a.5.5,0,0,1,.5-.5h9.4a.5.5,0,0,1,.5.5v5.77A.5.5,0,0,1,300.76,161.32Zm-8.9-1h8.4v-4.77h-8.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 296.06px 157.935px;" id="eliv11ibnc4m"
				  class="animable"></path>
			<rect x="202.39" y="160.75" width="8.37" height="4.85"
				  style="fill: rgb(255, 114, 94); transform-origin: 206.575px 163.175px;" id="eln1dn92xxm1"
				  class="animable"></rect>
			<path d="M210.76,166.1h-8.37a.51.51,0,0,1-.5-.5v-4.85a.51.51,0,0,1,.5-.5h8.37a.5.5,0,0,1,.5.5v4.85A.5.5,0,0,1,210.76,166.1Zm-7.87-1h7.37v-3.85h-7.37Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 206.575px 163.175px;" id="elsqqbofcvlxm"
				  class="animable"></path>
			<rect x="205.03" y="160.75" width="8.37" height="4.85"
				  style="fill: rgb(255, 114, 94); transform-origin: 209.215px 163.175px;" id="elfmd6tsb6vig"
				  class="animable"></rect>
			<path d="M213.4,166.1H205a.5.5,0,0,1-.5-.5v-4.85a.5.5,0,0,1,.5-.5h8.37a.51.51,0,0,1,.5.5v4.85A.51.51,0,0,1,213.4,166.1Zm-7.87-1h7.37v-3.85h-7.37Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 209.185px 163.175px;" id="elxd3yyhqdc1"
				  class="animable"></path>
			<rect x="200.36" y="163.48" width="20.63" height="24.18"
				  style="fill: rgb(38, 50, 56); transform-origin: 210.675px 175.57px;" id="el1wgslayvbsb"
				  class="animable"></rect>
			<path d="M221,188.16H200.36a.5.5,0,0,1-.5-.5V163.48a.5.5,0,0,1,.5-.5H221a.5.5,0,0,1,.5.5v24.18A.5.5,0,0,1,221,188.16Zm-20.13-1h19.63V164H200.86Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 210.68px 175.57px;" id="el2yonc3jsw0a"
				  class="animable"></path>
			<rect x="215.67" y="163.48" width="20.63" height="24.18"
				  style="fill: rgb(255, 114, 94); transform-origin: 225.985px 175.57px;" id="elg10sqcqvkoq"
				  class="animable"></rect>
			<path d="M236.3,188.16H215.67a.5.5,0,0,1-.5-.5V163.48a.5.5,0,0,1,.5-.5H236.3a.5.5,0,0,1,.5.5v24.18A.51.51,0,0,1,236.3,188.16Zm-20.13-1H235.8V164H216.17Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 225.985px 175.57px;" id="el2jt7andvdcr"
				  class="animable"></path>
			<rect x="223.21" y="178.78" width="5.55" height="8.87"
				  style="fill: rgb(38, 50, 56); transform-origin: 225.985px 183.215px;" id="elotjjhcosd8d"
				  class="animable"></rect>
			<path d="M228.75,188.16h-5.54a.5.5,0,0,1-.5-.5v-8.88a.5.5,0,0,1,.5-.5h5.54a.5.5,0,0,1,.5.5v8.88A.5.5,0,0,1,228.75,188.16Zm-5-1h4.54v-7.88h-4.54Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 225.98px 183.22px;" id="elre7gn4pk2v"
				  class="animable"></path>
			<rect x="210.24" y="166.36" width="3.43" height="16.42"
				  style="fill: rgb(255, 114, 94); transform-origin: 211.955px 174.57px;" id="eltjsr3yohkmb"
				  class="animable"></rect>
			<path d="M213.67,183.28h-3.43a.5.5,0,0,1-.5-.5V166.36a.5.5,0,0,1,.5-.5h3.43a.51.51,0,0,1,.5.5v16.42A.51.51,0,0,1,213.67,183.28Zm-2.93-1h2.43V166.86h-2.43Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 211.955px 174.57px;" id="elb9pui04ow5d"
				  class="animable"></path>
			<rect x="205.85" y="166.36" width="3.43" height="16.42"
				  style="fill: rgb(255, 114, 94); transform-origin: 207.565px 174.57px;" id="ely0r6cl1sbin"
				  class="animable"></rect>
			<path d="M209.28,183.28h-3.43a.51.51,0,0,1-.5-.5V166.36a.51.51,0,0,1,.5-.5h3.43a.5.5,0,0,1,.5.5v16.42A.5.5,0,0,1,209.28,183.28Zm-2.93-1h2.43V166.86h-2.43Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 207.565px 174.57px;" id="el3v2w1kb6z2d"
				  class="animable"></path>
			<rect x="201.47" y="166.36" width="3.43" height="16.42"
				  style="fill: rgb(255, 114, 94); transform-origin: 203.185px 174.57px;" id="eldcel5v53u69"
				  class="animable"></rect>
			<path d="M204.9,183.28h-3.43a.5.5,0,0,1-.5-.5V166.36a.5.5,0,0,1,.5-.5h3.43a.5.5,0,0,1,.5.5v16.42A.5.5,0,0,1,204.9,183.28Zm-2.93-1h2.43V166.86H202Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 203.185px 174.57px;" id="eltuoczz6yrx"
				  class="animable"></path>
			<rect x="243.06" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 255, 255); transform-origin: 245.53px 171.05px;" id="elgwj0w55qfo6"
				  class="animable"></rect>
			<path d="M248,188h-4.93a.5.5,0,0,1-.5-.5V154.59a.5.5,0,0,1,.5-.5H248a.5.5,0,0,1,.5.5v32.92A.5.5,0,0,1,248,188Zm-4.43-1h3.93V155.09h-3.93Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 245.535px 171.045px;" id="eli7o3g2jlrmg"
				  class="animable"></path>
			<rect x="243.84" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 114, 94); transform-origin: 246.31px 171.05px;" id="elwuykdn98yg"
				  class="animable"></rect>
			<path d="M249.28,188h-5.94V154.09h5.94Zm-4.94-1h3.94V155.09h-3.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 246.31px 171.045px;" id="el3onw7gxeyjo"
				  class="animable"></path>
			<rect x="263.2" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 255, 255); transform-origin: 265.67px 171.05px;" id="elbkpy9bv7b8"
				  class="animable"></rect>
			<path d="M268.14,188H263.2a.51.51,0,0,1-.5-.5V154.59a.51.51,0,0,1,.5-.5h4.94a.5.5,0,0,1,.5.5v32.92A.5.5,0,0,1,268.14,188Zm-4.44-1h3.94V155.09H263.7Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 265.67px 171.045px;" id="elw2mwwe031or"
				  class="animable"></path>
			<rect x="263.98" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 114, 94); transform-origin: 266.45px 171.05px;" id="eloww7f72c1tg"
				  class="animable"></rect>
			<path d="M269.41,188h-5.93V154.09h5.93Zm-4.93-1h3.93V155.09h-3.93Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 266.445px 171.045px;" id="eluka3tkec5x"
				  class="animable"></path>
			<rect x="283.82" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 255, 255); transform-origin: 286.29px 171.05px;" id="elwo4cvb2vb2l"
				  class="animable"></rect>
			<path d="M288.76,188h-4.94a.51.51,0,0,1-.5-.5V154.59a.51.51,0,0,1,.5-.5h4.94a.5.5,0,0,1,.5.5v32.92A.5.5,0,0,1,288.76,188Zm-4.44-1h3.94V155.09h-3.94Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 286.29px 171.045px;" id="eln0wqyea0jec"
				  class="animable"></path>
			<rect x="284.6" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 114, 94); transform-origin: 287.07px 171.05px;" id="elk4jjmzxwlp"
				  class="animable"></rect>
			<path d="M290,188H284.1V154.09H290Zm-4.94-1H289V155.09H285.1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 287.05px 171.045px;" id="elmqfifm9y53r"
				  class="animable"></path>
			<rect x="304.47" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 255, 255); transform-origin: 306.94px 171.05px;" id="elf0hzx178d6"
				  class="animable"></rect>
			<path d="M309.41,188h-4.94a.5.5,0,0,1-.5-.5V154.59a.5.5,0,0,1,.5-.5h4.94a.51.51,0,0,1,.5.5v32.92A.51.51,0,0,1,309.41,188ZM305,187h3.94V155.09H305Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 306.94px 171.045px;" id="el8ux763o80eh"
				  class="animable"></path>
			<rect x="305.25" y="154.59" width="4.94" height="32.92"
				  style="fill: rgb(255, 114, 94); transform-origin: 307.72px 171.05px;" id="elfdnbbs5t6ni"
				  class="animable"></rect>
			<path d="M310.68,188h-5.93V154.09h5.93Zm-4.93-1h3.93V155.09h-3.93Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 307.715px 171.045px;" id="elqg5sl60dtl"
				  class="animable"></path>
		</g>
		<g id="freepik--Shadow--inject-55" class="animable" style="transform-origin: 260.67px 454.59px;">
			<ellipse cx="260.67" cy="454.59" rx="61.38" ry="5.56"
					 style="fill: rgb(38, 50, 56); transform-origin: 260.67px 454.59px;" id="elo0raxsc02ld"
					 class="animable"></ellipse>
		</g>
		<g id="freepik--Earth--inject-55" class="animable" style="transform-origin: 258.12px 298.27px;">
			<g id="el9yz8e40tch">
				<circle cx="258.12" cy="298.27" r="125.54"
						style="fill: rgb(255, 114, 94); transform-origin: 258.12px 298.27px; transform: rotate(-45deg);"
						class="animable"></circle>
			</g>
			<polygon
					points="237.47 243.76 250.42 245.56 255.07 249.79 260.8 250.28 262.96 255.55 261.88 259.28 258.13 261.31 248.54 260.39 248.21 259.24 252.48 255.82 249.01 250.31 240.46 249.71 235.69 246.14 237.47 243.76"
					style="fill: rgb(255, 255, 255); transform-origin: 249.325px 252.535px;" id="elrcmlw7qznf"
					class="animable"></polygon>
			<path d="M348.17,354.8l-10.24-1L325.61,353l-8.87,2.59-8.79.71L299.38,361l-5.06,1.8-3.15.92-5,5.18-5.05,4-9.14-2.6-.34-8.59-5.45-.58-5.72,1.67-1.61-2.32-.93-5.32-2.84-2.27-3.84,1.74-.3,3.2-4.78,1.7-5.8-3.89-3.72-6.36L238.6,340l8.46-1.86,2.87,1.34,3.43-1,3.54-3.83,4.05.06,7.74,7.36,1.92-.87-2.49-6.41-2.72-2.93-1-5.6,9.24-7.67-1.66-11,3.49-5.05,1.79-7.67,2.69-1.41,5.33-6.21-.39-2.37-4.49,1.62-1.53-2-3.14.92-4.53,2.56,1.2-4.38,9.17-5.79,1.54-3.24,5.84,5.12,3.33,1.82,3.67-2.32-2.8-3.21-7.26-2.54-9.13-5.71-8.57-2.77-7.19.86-4.44-2.43-4.91.2-.24,1.31,1.86,8.46,2,2.52.1,9.91-7.06-2.91-.31-4.25-6.84-1.1-3.5-2.4L237,267.5l6.54-8.43-1.43-7-3.07,1.2-2.75-2-2.11-4-2.09.29.2,4.91-3,1.5-4.36-9.59-2.71-.77-4.5-.54-3.91,3.63,7.31,3.76,2.57,4.52-12.56-4.71-9.4-5.63L198.88,237l-8.11-.12-4.63,5.39a46.18,46.18,0,0,1-3.62,3.55c-1.26,1-6.53,3.15-6.53,3.15l.28,5.19-6.49,2.21,1.49,3L182.35,258l7.19,4.42,2.93,10-.56,9.78,3.33,9.27-3.9,16.36,4.38,21.37,6.09,11.26,6.39,8.06,4.46-.68-2.77-4.15-3.29-2.77-3.27-5.87,2.41-1.33,7,9.13,5.54,8.31,2.17,7.44,7.36,5,11.81,4.3,16-.34,5.83,2.95,4.77,3.57,6.07,1.64,6.79-.12,6.21-2.13s.55,2.95,1,4.38-2.17,7.46-2.17,7.46L282.67,392l4.13,5.62,7,.73,11.54,6.56,4.51.55,1.25,4.29,1.09,1.85A125.18,125.18,0,0,0,364,365.78l-.46-2.11,3.62-16.27-4.47-1.49Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 268.47px 324.24px;" id="eles78bh763tk"
				  class="animable"></path>
			<path d="M344.88,233.15l-4.74-4.52-5.84-5.12-3.65-8.24,5.4-.65-.55-2.94-3.81.8,2.73-2.35,2.85-3-.84-2.86-4.77-3.57-2.53-5.94a125.62,125.62,0,0,0-151.43,7.09l8,2.74-2.5,6.32-5.1,2.73,1.69,7.89-2,1.53-1.94,4,1.8,5.07,5-.85-.58,5.44,4.87-1.42,5.19-13-.48-4.82,7.84-2.91,1-4,8-3.28-.56-5.12,5.92-2s3.62-3.54,4.48-3.79a36.43,36.43,0,0,1,5.28,0l5.29,2.17,6-1.75,4.23-4.65,6.74-1.35a7.35,7.35,0,0,0,3.48.22c1.43-.42,5.13-3.67,5.13-3.67l9.8,2.72,4.19,1.57,1.25,4.29,4.46-.69.68-3,4.21.63.39,2.37-7.91,4.8,6.7,4.87,12,2.71,6,4.46,6.46-1.27.59-3.27,2.12-1.24-9.11-3.55-4.74.77,3.38-2.23L290,197.35l4.78-1.71,7.29,1.59,3.44,6.45,4.23,2.8,5,7.54,7.63,2.73,2.76,9.44,7.83,2.36.63,3.23.23,4,3.23,4.65,1.32,2.4,5.11-.56.87-5.53Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 260.365px 207.79px;" id="el8xvm0ix1om9"
				  class="animable"></path>
			<path d="M340.17,203.25l.48,1.64-.09,5,2,3.75,3.48.22,1.59,5.44-3.85-.43,3.65,15.69-1.59,7.3,4.63,7.34,4.72,2.34L359.74,267,367,277.91l6.68,8,7.08-.21.61-6.39.72-1A127.76,127.76,0,0,0,378.61,263,125,125,0,0,0,340.17,203.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 361.13px 244.58px;" id="elh81qdkjle3a"
				  class="animable"></path>
			<polygon
					points="265.74 347.86 264.93 349.34 274.26 349.4 278.87 352.4 284.47 351.39 285.55 347.66 278.64 348.44 265.74 347.86"
					style="fill: rgb(255, 255, 255); transform-origin: 275.24px 350.03px;" id="elaukibai88at"
					class="animable"></polygon>
			<polygon
					points="292.78 345.85 287.28 349.32 288.8 351.36 296.24 349.19 297 346.48 294.51 347.52 292.78 345.85"
					style="fill: rgb(255, 255, 255); transform-origin: 292.14px 348.605px;" id="ely3yf3cbg0ys"
					class="animable"></polygon>
			<g id="eln2b2aztxod">
				<path d="M351.52,372.09A125.54,125.54,0,0,1,212.19,181.44a125.53,125.53,0,1,0,150,187C358.74,369.79,355.17,371,351.52,372.09Z"
					  style="opacity: 0.19; transform-origin: 247.381px 302.621px;" class="animable"></path>
			</g>
			<path d="M258.08,417.62a118.17,118.17,0,0,1-17.53-1.3.5.5,0,0,1,.14-1,117.31,117.31,0,0,0,20.51,1.25.51.51,0,0,1,.51.49.5.5,0,0,1-.49.51C260.18,417.61,259.13,417.62,258.08,417.62Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 250.913px 416.468px;" id="el4qvegmtkq48"
				  class="animable"></path>
			<path d="M235.55,415.45h-.1c-3.56-.7-7.13-1.56-10.62-2.58a.5.5,0,0,1-.34-.62.51.51,0,0,1,.62-.34c3.46,1,7,1.87,10.53,2.55a.51.51,0,0,1,.4.59A.5.5,0,0,1,235.55,415.45Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 230.259px 413.671px;" id="elughi8kja3i"
				  class="animable"></path>
			<path d="M220.57,411.51a.44.44,0,0,1-.16,0,119.41,119.41,0,0,1-79-138.24.51.51,0,0,1,.6-.38.49.49,0,0,1,.38.59,118.4,118.4,0,0,0,78.33,137.08.5.5,0,0,1-.16,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 179.958px 342.223px;" id="eltnly6tp28ms"
				  class="animable"></path>
			<path d="M146,259.46a.47.47,0,0,1-.17,0,.51.51,0,0,1-.31-.64,120.31,120.31,0,0,1,9.67-20.86.5.5,0,0,1,.68-.18.51.51,0,0,1,.18.69,119,119,0,0,0-9.58,20.68A.52.52,0,0,1,146,259.46Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 150.806px 248.591px;" id="el11doulo4hahc"
				  class="animable"></path>
			<path d="M258.17,424.32a126.08,126.08,0,1,1,35.32-5.08A126.24,126.24,0,0,1,258.17,424.32Zm.12-251.14a125.07,125.07,0,1,0,119.84,90,125.23,125.23,0,0,0-119.84-90Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 258.062px 298.24px;" id="elcwe4iyjev3i"
				  class="animable"></path>
			<path d="M239.2,334.28c.08,6-3.38,11-7.73,11s-7.94-4.8-8-10.84,3.39-11,7.74-11S239.13,328.23,239.2,334.28Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 231.335px 334.36px;" id="elrtolwpvy53m"
				  class="animable"></path>
			<path d="M236,329.25c.31,2.33-.57,4.38-2,4.57s-2.79-1.55-3.11-3.89.57-4.38,2-4.57S235.72,326.91,236,329.25Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 233.443px 329.59px;" id="elyfpo7geg8f"
				  class="animable"></path>
			<path d="M293.27,333.06c.08,6.05-3.38,11-7.73,11.05s-7.94-4.8-8-10.84,3.38-11,7.73-11S293.19,327,293.27,333.06Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 285.405px 333.19px;" id="els5gtlwlgof"
				  class="animable"></path>
			<path d="M289.8,327.42c.31,2.34-.57,4.38-2,4.57s-2.79-1.55-3.11-3.88.57-4.38,2-4.57S289.48,325.09,289.8,327.42Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 287.243px 327.765px;" id="el1wuzakytxfr"
				  class="animable"></path>
			<path d="M289.74,385.81a1.5,1.5,0,0,1-1.47-1.23c-2.56-13.79-14.33-23.93-28-24.1h-.37A29,29,0,0,0,232,382.35a1.5,1.5,0,0,1-2.9-.77,32,32,0,0,1,30.77-24.11h.41c15.08.19,28.08,11.36,30.9,26.55a1.5,1.5,0,0,1-1.2,1.75Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 260.128px 371.64px;" id="el9k8ihgt9tea"
				  class="animable"></path>
			<path d="M212.43,325.14a4,4,0,0,1-.53-8h0c.22,0,21.52-3,34.29-12.65a4,4,0,1,1,4.83,6.38c-14.47,10.95-37.1,14.07-38.06,14.2Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 230.385px 314.414px;" id="elddj8opf3yz"
				  class="animable"></path>
			<path d="M303.8,330a3.82,3.82,0,0,1-1.15-.17c-19.09-5.73-37.37-19.06-38.14-19.62a4,4,0,0,1,4.74-6.45c.17.13,18,13.09,35.7,18.41A4,4,0,0,1,303.8,330Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 285.337px 316.491px;" id="eljxkstfctep"
				  class="animable"></path>
		</g>
		<g id="freepik--Drops--inject-55" class="animable" style="transform-origin: 271.275px 330.575px;">
			<path d="M344.6,297.17a9,9,0,1,1-18.07,0c0-5.2,9-20.78,9-20.78S344.6,292,344.6,297.17Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 335.565px 291.298px;" id="elzivo0e0are"
				  class="animable"></path>
			<path d="M335.56,307.1a9.75,9.75,0,0,1-9.53-9.93c0-5.28,8.73-20.39,9.1-21l.43-.75.44.75c.37.64,9.1,15.75,9.1,21A9.76,9.76,0,0,1,335.56,307.1Zm0-29.7c-1.79,3.17-8.53,15.41-8.53,19.77a8.54,8.54,0,1,0,17.07,0C344.1,292.81,337.36,280.57,335.56,277.4Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 335.565px 291.26px;" id="elmxf6b0y6nk7"
				  class="animable"></path>
			<path d="M358.48,317a6.14,6.14,0,1,1-12.27,0c0-3.53,6.13-14.11,6.13-14.11S358.48,313.45,358.48,317Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 352.345px 313.139px;" id="eldx9wrx8hfjo"
				  class="animable"></path>
			<path d="M352.34,323.88a6.78,6.78,0,0,1-6.63-6.9c0-3.63,6-13.93,6.2-14.36l.43-.75.44.75c.25.43,6.2,10.73,6.2,14.36A6.79,6.79,0,0,1,352.34,323.88Zm0-20c-1.38,2.47-5.63,10.27-5.63,13.1A5.64,5.64,0,1,0,358,317C358,314.15,353.73,306.35,352.34,303.88Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 352.345px 312.875px;" id="elei07czxa3nu"
				  class="animable"></path>
			<path d="M196.34,358.93a6.14,6.14,0,1,1-12.27,0c0-3.53,6.13-14.11,6.13-14.11S196.34,355.4,196.34,358.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 190.205px 355.069px;" id="el0dx9yitnb2nb"
				  class="animable"></path>
			<path d="M190.2,365.83a6.77,6.77,0,0,1-6.63-6.9c0-3.62,6-13.92,6.2-14.36l.43-.74.43.74c.26.44,6.21,10.74,6.21,14.36A6.78,6.78,0,0,1,190.2,365.83Zm0-20c-1.39,2.48-5.63,10.28-5.63,13.1a5.64,5.64,0,1,0,11.27,0C195.84,356.11,191.59,348.31,190.2,345.83Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 190.205px 354.83px;" id="elsr13grwaj3"
				  class="animable"></path>
			<path d="M293.42,246.55a6.14,6.14,0,1,1-12.27,0c0-3.54,6.13-14.11,6.13-14.11S293.42,243,293.42,246.55Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 287.285px 242.689px;" id="elncw3mxlhcqq"
				  class="animable"></path>
			<path d="M287.28,253.45a6.78,6.78,0,0,1-6.63-6.9c0-3.63,6-13.93,6.2-14.37l.43-.74.44.74c.25.44,6.2,10.74,6.2,14.37A6.79,6.79,0,0,1,287.28,253.45Zm0-20c-1.38,2.47-5.63,10.27-5.63,13.1a5.64,5.64,0,1,0,11.27,0C292.92,243.72,288.67,235.92,287.28,233.45Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 287.285px 242.445px;" id="elr19usm3nlol"
				  class="animable"></path>
			<path d="M223.62,233.43a6.14,6.14,0,1,1-12.27,0c0-3.54,6.14-14.11,6.14-14.11S223.62,229.89,223.62,233.43Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 217.485px 229.569px;" id="elg5b734mxcef"
				  class="animable"></path>
			<path d="M217.49,240.33a6.79,6.79,0,0,1-6.64-6.9c0-3.63,6-13.93,6.2-14.37l.44-.74.43.74c.25.44,6.2,10.74,6.2,14.37A6.78,6.78,0,0,1,217.49,240.33Zm0-20c-1.39,2.47-5.64,10.27-5.64,13.1a5.64,5.64,0,1,0,11.27,0C223.12,230.6,218.87,222.8,217.49,220.33Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 217.485px 229.325px;" id="elm1qmb04k5ee"
				  class="animable"></path>
			<path d="M270.06,435.93a6.14,6.14,0,1,1-12.27,0c0-3.53,6.14-14.11,6.14-14.11S270.06,432.4,270.06,435.93Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 263.925px 432.069px;" id="elq7v4jfecd"
				  class="animable"></path>
			<path d="M263.93,442.83a6.78,6.78,0,0,1-6.64-6.9c0-3.62,5.95-13.92,6.2-14.36l.44-.74.43.74c.25.44,6.2,10.74,6.2,14.36A6.78,6.78,0,0,1,263.93,442.83Zm0-20c-1.39,2.48-5.64,10.28-5.64,13.1a5.64,5.64,0,1,0,11.27,0C269.56,433.11,265.31,425.31,263.93,422.83Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 263.925px 431.83px;" id="elpv8dq0xzzsr"
				  class="animable"></path>
			<path d="M198.87,293.49a6.14,6.14,0,1,1-12.27,0c0-3.54,6.13-14.12,6.13-14.12S198.87,290,198.87,293.49Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 192.735px 289.624px;" id="elreq9oxykks"
				  class="animable"></path>
			<path d="M192.73,300.38a6.77,6.77,0,0,1-6.63-6.89c0-3.63,6-13.93,6.2-14.37l.43-.74.43.74c.26.44,6.21,10.74,6.21,14.37A6.78,6.78,0,0,1,192.73,300.38Zm0-20c-1.39,2.47-5.63,10.27-5.63,13.1a5.64,5.64,0,1,0,11.27,0C198.37,290.66,194.12,282.86,192.73,280.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 192.735px 289.38px;" id="elikikz6rqhn"
				  class="animable"></path>
		</g>
		<g id="freepik--Thermometer--inject-55" class="animable" style="transform-origin: 109.257px 315.915px;">
			<path d="M119.58,355.19V250.35a9.81,9.81,0,0,0-9.8-9.81h0a9.81,9.81,0,0,0-9.8,9.81V354.59a19.54,19.54,0,1,0,19.6.6Z"
				  style="fill: rgb(255, 255, 255); transform-origin: 109.263px 315.932px;" id="elw9mgpfpzqur"
				  class="animable"></path>
			<path d="M109.31,391.78a23.26,23.26,0,0,1-2.34-.13,20,20,0,0,1-7.49-37.36V250.35a10.3,10.3,0,1,1,20.6,0V354.92a20,20,0,0,1-10.77,36.86ZM109.78,241a9.32,9.32,0,0,0-9.3,9.31V354.88l-.26.15a19,19,0,1,0,19.1.59l-.24-.15V250.35A9.32,9.32,0,0,0,109.78,241Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 109.257px 315.915px;" id="elkdpeo0sldxe"
				  class="animable"></path>
			<path d="M113.33,357.19V273.08h-7.1v83.85a15.17,15.17,0,1,0,7.1.26Z"
				  style="fill: rgb(255, 114, 94); transform-origin: 109.24px 330.024px;" id="elasob6kkgzt"
				  class="animable"></path>
			<path d="M109.28,387.38a15.63,15.63,0,0,1-3.55-30.85V272.58h8.1v84.24a15.62,15.62,0,0,1-4.55,30.56Zm-2.55-113.8v83.76l-.4.08a14.66,14.66,0,1,0,6.87.25l-.37-.1v-84Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 109.274px 329.98px;" id="elw6jpbqi29hj"
				  class="animable"></path>
			<circle cx="103.16" cy="366.01" r="4.21"
					style="fill: rgb(255, 255, 255); transform-origin: 103.16px 366.01px;" id="el0bnnkm0mqaid"
					class="animable"></circle>
			<path d="M108.13,262h-5.36a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,262Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 261.5px;" id="elz4f9o375ebr"
				  class="animable"></path>
			<path d="M108.13,270h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,270Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 269.5px;" id="el4vlb8jqhykj"
				  class="animable"></path>
			<path d="M108.13,278.08h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,278.08Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 277.58px;" id="el6h3db7e90d8"
				  class="animable"></path>
			<path d="M108.13,286.12h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,286.12Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 285.62px;" id="eljfjoup69kf"
				  class="animable"></path>
			<path d="M108.13,294.16h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,294.16Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 293.66px;" id="elmzklahf8abc"
				  class="animable"></path>
			<path d="M108.13,302.2h-5.36a.5.5,0,1,1,0-1h5.36a.5.5,0,0,1,0,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 301.7px;" id="elasmvrbr4j5u"
				  class="animable"></path>
			<path d="M108.13,310.24h-5.36a.5.5,0,1,1,0-1h5.36a.5.5,0,0,1,0,1Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 309.74px;" id="elg2jv0bh8zxl"
				  class="animable"></path>
			<path d="M108.13,318.28h-5.36a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,318.28Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 317.78px;" id="elsnc8j3eoht"
				  class="animable"></path>
			<path d="M108.13,326.32h-5.36a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,326.32Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 325.82px;" id="elg1bog8bbsg"
				  class="animable"></path>
			<path d="M108.13,334.36h-5.36a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,334.36Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 333.86px;" id="elu7nmjmh3apo"
				  class="animable"></path>
			<path d="M108.13,342.39h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,342.39Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 341.89px;" id="elj2hoah0ad8a"
				  class="animable"></path>
			<path d="M108.13,350.43h-5.36a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h5.36a.5.5,0,0,1,.5.5A.5.5,0,0,1,108.13,350.43Z"
				  style="fill: rgb(38, 50, 56); transform-origin: 105.45px 349.93px;" id="elbtz2v2mq38"
				  class="animable"></path>
		</g>
		<g id="freepik--Arrow--inject-55" class="animable" style="transform-origin: 84.805px 312.935px;">
			<polygon
					points="96.37 290.95 84.8 270.92 73.24 290.95 81.55 290.95 81.55 354.95 88.06 354.95 88.06 290.95 96.37 290.95"
					style="fill: rgb(38, 50, 56); transform-origin: 84.805px 312.935px;" id="elb97wmglkev"
					class="animable"></polygon>
		</g>
		<defs>
			<filter id="active" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
			<filter id="hover" height="200%">
				<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
				<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
				<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
				<feMerge>
					<feMergeNode in="OUTLINE"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
				<feColorMatrix type="matrix"
							   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
			</filter>
		</defs>
	</svg>
</template>

<script lang="ts">

export default {
	name: "GlobalWarmingAnimation",
	components: {},
	props: {
		height: String,
	},
	setup() {
		return {}
	}
}
</script>

<style scoped>

svg#freepik_stories-global-warming:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-global-warming.animated #freepik--background-simple--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Sun--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--smoke-clouds--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--buildings-2--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--buildings-1--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Factory--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Shadow--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Earth--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Drops--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Thermometer--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-global-warming.animated #freepik--Arrow--inject-55 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(-2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>