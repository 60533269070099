<template>
  <span v-if="weatherObj && weatherObj.weatherType" :class="[useDefaultDesign ? 'weather-default' : '', customCss, size, alignIcon, bringToFront ? 'bring-icon-to-front' : '']" :title="weatherObj.weatherDescription" :style="customStyle">
        <ion-icon :icon="weatherObj.getWeatherIcon()" :style="'color:'+weatherObj.getWeatherIconColor()" class="weather-icon"></ion-icon>
		<ion-badge class="temperature-div" v-if="showTemperature && weatherObj.temperature.measuredTemperature" color="dark"
				   slot="start">
			<ion-label
					class="temperature-text ion-no-padding">{{ temperatureRoundPrecision > 0 ? weatherObj.temperature.measuredTemperature.toFixed(temperatureRoundPrecision) : Math.round(weatherObj.temperature.measuredTemperature) }}<span>°</span></ion-label>
		</ion-badge>
  </span>
</template>

<script lang="ts">
import {ref, onMounted, watch} from "vue";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";
import {MobiPointsApiWeather} from "@/mobipoints/api/weather";
import useTracking from "@/composable/useTracking";
import useWeatherFactory from "@/composable/useWeatherFactory";
import {IonIcon, IonBadge, IonLabel} from '@ionic/vue';
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsTrackingWeatherWeatherInterface} from "@/mobipoints/tracking/weather/weather.interface";

export default {
	name: 'WeatherIcon',
	components: {IonIcon, IonBadge, IonLabel},
	props: {
		weather: {
			type: Object as () => MobiPointsTrackingWeatherWeatherInterface,
			default: null,
		},
		customCss: {
			type: String,
			default: ''
		},
		customStyle: {
			type: String,
			default: ''
		},
		bringToFront: {
			type: Boolean,
			default: true
		},
		alignIcon: {
			type: String,
			default: 'align-right',
		},
		loadCurrentWeather: {
			type: Boolean,
			default: false
		},
		useDefaultDesign: {
			type: Boolean,
			default: true
		},
		useCurrentTrackingLatestPosition: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'icon-size-xl',
		},
		showTemperature: {
			type: Boolean,
			default: true,
		},
		temperatureRoundPrecision: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const weatherObj: any | MobiPointsTrackingWeatherWeather = ref(null);
		const {getCurrentPosition} = useTracking();
		const {getTrackingFactory} = useTrackingFactory();
		const {getWeatherFactory} = useWeatherFactory();

		async function loadCurrentWeatherData() {
			try {
				const currentPosition = getCurrentPosition.value;
				if ((!props.useCurrentTrackingLatestPosition) || (props.useCurrentTrackingLatestPosition && !currentPosition)) {
					getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition().then(currentPosition => {
						if (!currentPosition?.latitude || !currentPosition.longitude) {
							currentPosition = getCurrentPosition.value;
						}
						MobiPointsApiWeather.getWeather(currentPosition.latitude, currentPosition.longitude).then(response => {
							weatherObj.value = getWeatherFactory().createSingleWeatherDataByApiResponse(response.data);
						});
					})
				} else {
					MobiPointsApiWeather.getWeather(currentPosition.latitude, currentPosition.longitude).then(response => {
						weatherObj.value = getWeatherFactory().createSingleWeatherDataByApiResponse(response.data);
					});
				}

			} catch (error: any) {
				console.log('Weather Response Error', error)
			}
		}

    watch(() => props.weather, (newWeatherObj: any) => {
      if (!props.loadCurrentWeather && newWeatherObj && newWeatherObj instanceof MobiPointsTrackingWeatherWeather && newWeatherObj.timestamp !== weatherObj.value?.timestamp) {
        weatherObj.value = newWeatherObj;
      }
    });

    onMounted(() => {
			if ( props.loadCurrentWeather || (!props.loadCurrentWeather && !props.weather)) {
				loadCurrentWeatherData();
			} else {
				weatherObj.value = props.weather;
			}
		})

		return {weatherObj}
	}
}
</script>

<style scoped>
.weather-default {
    background-color: #ffffff;
    border-radius: 80px 45px 75px;
    margin: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
}

.bring-icon-to-front {
	z-index: 9999999;
    position: absolute;
	padding: 5px;
}

.align-right {
    right: 0;
}

.align-left {
    left: 0;
}

.icon-size-lg {
    font-size: large;
}

.icon-size-xl {
    font-size: x-large;
}

.weather-icon {
    z-index: 1;
}

.temperature-div {
    position: relative;
    top: 0;
    margin-top: 2px;
    height: 28px;
    width: 28px;
    border-radius: 28px;
    right: 0;
    font-size: 8px;
    margin-bottom: 8px;
    margin-left: -9px;
    z-index: -1;
}

.temperature-text {
    position: relative;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    white-space: break-spaces;
    text-align: center;
    left: 0;
    font-size: 1.3em;
    margin-right: -10px;
    min-width: 100%;
}
</style>
