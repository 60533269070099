import {computed, ComputedRef} from 'vue';
import store from '@/store';

export default function useCustomStore() {
	const hasTutorialCompleted: ComputedRef<boolean> = computed(() => store.getters['custom/hasTutorialCompleted']);
	const hasSeenCo2ChallengeDialog: ComputedRef<boolean> = computed(() => store.getters['custom/hasSeenCo2ChallengeDialog']);
	const hasSeenTreasureHuntChallengeDialog: ComputedRef<boolean> = computed(() => store.getters['custom/hasSeenTreasureHuntChallengeDialog']);

	function setTutorialCompleted(value: boolean) {
		store.commit('custom/setTutorialCompleted', value)
	}

	function setHasSeenCo2ChallengeDialog(value: boolean) {
		store.commit('custom/setHasSeenCo2ChallengeDialog', value)
	}

	function setHasSeenTreasureHuntChallengeDialog(value: boolean) {
		store.commit('custom/setHasSeenTreasureHuntChallengeDialog', value)
	}

	return {
		hasTutorialCompleted,
		setTutorialCompleted,
		hasSeenCo2ChallengeDialog,
		setHasSeenCo2ChallengeDialog,
		setHasSeenTreasureHuntChallengeDialog,
		hasSeenTreasureHuntChallengeDialog
	}
}
