<template>
<ion-card>
  <ion-card-header>
    <ion-card-title>
      Tracking Vorlage Details
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-item>
      <ion-icon slot="start" :icon="icons.pricetag"></ion-icon>
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>Name</h3>
        </ion-text>
        <ion-text>
          {{ userTrackingTemplate.name }}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" :icon="userTrackingTemplate.userTrackingTrackType && userTrackingTemplate.userTrackingTrackType.getTrackTypeIcon()"></ion-icon>
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>Typ</h3>
        </ion-text>
        <ion-text>
          {{ userTrackingTemplate.userTrackingTrackType && userTrackingTemplate.userTrackingTrackType.name }}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" :icon="icons.pin"></ion-icon>
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>Startpunkt</h3>
        </ion-text>
        <ion-text v-if="userTrackingTemplate.startLocation !== null && userTrackingTemplate.startLocation.id !== null">
          {{ userTrackingTemplate.startLocation.getFormattedAddress() }}
        </ion-text>
        <ion-text v-else>-</ion-text>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon slot="start" :icon="icons.pin"></ion-icon>
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>Endpunkt</h3>
        </ion-text>
        <ion-text v-if="userTrackingTemplate.endLocation !== null && userTrackingTemplate.endLocation.id !== null">
          {{ userTrackingTemplate.endLocation.getFormattedAddress() }}
        </ion-text>
        <ion-text v-else>-</ion-text>
      </ion-label>
    </ion-item>
  </ion-card-content>
</ion-card>
</template>

<script setup lang="ts">
import {defineExpose, onMounted, defineProps, ref, computed, onUpdated} from "vue";
import {IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, toastController} from "@ionic/vue";
import {MobiPointApiProfile, ResponseError} from "@/mobipoints/api/profile";
import {UserTrackingTemplate} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.type";
import router from "@/router";
import * as icons from 'ionicons/icons';
import {AxiosError, AxiosResponse} from "axios";
import useProfileFactory from "@/composable/useProfileFactory";

const props = defineProps({
  userTrackingTemplateId: {
    type: Number,
    required: true,
  }
});

const {getProfileFactory} = useProfileFactory();

const userTrackingTemplate = ref<UserTrackingTemplate>(getProfileFactory().createEmptyUserTrackingTemplate());
const userTrackingTrackTypeIcon = computed(() => {
  return userTrackingTemplate.value.userTrackingTrackType && userTrackingTemplate.value.userTrackingTrackType.getTrackTypeIcon();
});

onMounted(() => {
  loadUserTrackingTemplate(props.userTrackingTemplateId).then((response) => {
    if (null === response) {
      router.back();
    }
  });
});


const refreshUserTrackingTemplateDetail = async function (): Promise<boolean> {
  return !!(await loadUserTrackingTemplate(props.userTrackingTemplateId));
}


const loadUserTrackingTemplate = async function (id: number) {
  let response: AxiosResponse | null = null;

  try {
    response = await MobiPointApiProfile.getUserTrackingTemplate(id);
    userTrackingTemplate.value = getProfileFactory().createUserTrackingTemplateListByApiResponse(response.data).shift() ?? userTrackingTemplate.value;
  } catch (error: any | AxiosError) {
    // TODO create a custom toast controller to handle this kind of toasts (display error and go back)
    const toast = await toastController.create({
      message: `Could not load user tracking template ${id}`,
      position: 'bottom',
      duration: 3000,
      icon: icons.warning,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          icon: icons.close
        }
      ]
    });
    // toast.onWillDismiss().then(router.back);
    await toast.present();

    return response;
  }
}

defineExpose({
  refreshUserTrackingTemplateDetail
});
</script>

<style scoped>

</style>