import {computed, ComputedRef} from "vue";
import store from "@/store";
import {isPlatform} from '@ionic/vue';
import {MobiPointsCoreLogInterface} from "@/mobipoints/core/log.interface";
import useUserRepository from "@/components/user/UserRepository.vue";
import {MobiPointsCoreOsDeviceTypeInterface} from "@/mobipoints/core/os_device_type.interface";
import {MobiPointApiSystem} from '@/mobipoints/api/system';

export default function useSystem() {
	const internetConnection: ComputedRef<boolean> = computed(() => store.getters['system/getInternetConnection']);
	const appVersion: ComputedRef<string|null> = computed(() => store.getters['system/appVersion']);
	const appActiveState: ComputedRef<boolean> = computed(() => store.getters['system/appActiveState']);
	const logs: ComputedRef<Array<MobiPointsCoreLogInterface>> = computed(() => store.getters['system/getLogs']);
	const logsAsString: ComputedRef<Array<MobiPointsCoreLogInterface>> = computed(() => store.getters['system/getLogsAsString']);
	const backgroundTrackingActive: ComputedRef<boolean> = computed(() => store.getters['system/backgroundTrackingActive']);
	const hasDeveloperMode: ComputedRef<boolean> = computed(() => store.getters['system/hasDeveloperMode']);

	const {getUserEmail} = useUserRepository();

	const logsSortedDesc: ComputedRef<Array<MobiPointsCoreLogInterface>> = computed(() => {
		if (logs.value) {
			return logs.value.sort(
				(objA, objB) => objB.timestamp - objA.timestamp,
			);
		} else {
			return [];
		}
	});

	function hasInternetConnection(): boolean {
		return internetConnection.value === true;
	}

	function setInternetConnection(connectionState: boolean) {
		store.commit('system/setInternetConnection', connectionState)
	}

	function setAppVersion(appVersion: string) {
		store.commit('system/appVersion', appVersion)
	}

	function setAppActiveState(activeState: boolean) {
		store.commit('system/appActiveState', activeState)
	}

	function setBackgroundTrackingActive(activeState: boolean) {
		store.commit('system/backgroundTrackingActive', activeState)
	}

	function setDeveloperMode(value: boolean) {
		store.commit('system/setDeveloperMode', value)
	}

	function addLogByError(error: Error, preMessage = '') {
		const log: MobiPointsCoreLogInterface = {
			message: preMessage + error.message,
			timestamp: Date.now(),
			error: error,
			trace: error.stack
		}
		store.commit('system/addLog', log);
	}

	function getPing() {
		return MobiPointApiSystem.ping();
	}


	function addLog(message: string) {
		const log: MobiPointsCoreLogInterface = {
			message: message,
			timestamp: Date.now()
		}
		store.commit('system/addLog', log);
	}

	function addLogByException(exception: any, methodName = '') {
		let message = '';
		const stack = '';
		if (exception instanceof Error) {
			// stack = String(exception.stack);
			message = exception.toString();
		} else {
			message = JSON.stringify(exception)
		}
		const log: MobiPointsCoreLogInterface = {
			message: message + ', Method: ' + methodName,
			timestamp: Date.now(),
			trace: stack,
		}
		store.commit('system/addLog', log);
	}

	function resetLogs() {
		store.commit('system/resetLogs');
	}

	function isDeveloper(): boolean {
		let result = false;
		try {
			const developerList = ['martin.laundl@gmail.com', 'andreas.maurer@niceshops.com', 'martin.laundl@niceshops.com'];
			result = getUserEmail && developerList.includes(getUserEmail.value);
			if (!result && hasDeveloperMode.value) {
				result = true;
			}
		} catch (error) {
			//
		}
		return result;
	}

	function isDevelopmentEnv(): boolean {
		return process.env.VUE_APP_ENV === 'development'
	}

	function isNativePlatform(): boolean
	{
		let result = false;
		try {
			result = isPlatform('capacitor');
		} catch (error: any) {
			console.log(error);
		}
		return result;
	}

	function isIosOrAndroid(): boolean
	{
		return isIosPlatform() ||isAndroidPlatform();
	}

	function isIosPlatform(): boolean
	{
		let result = false;
		try {
			result = isPlatform('ios');
		} catch (error: any) {
			console.log(error);
		}
		return result;
	}


	function isAndroidPlatform(): boolean
	{
		let result = false;
		try {
			result = isPlatform('android');
		} catch (error: any) {
			console.log(error);
		}
		return result;
	}

	function initVersion() {
		if (appVersion.value !== process.env.VUE_APP_VERSION) {
			setAppVersion(process.env.VUE_APP_VERSION)
		}
	}

	function getOs(): string {
		let os = 'web';
		if (isAndroidPlatform()) {
			os = 'android';
		} else if (isIosPlatform()) {
			os = 'ios';
		}
		return os;
	}

	function getPlatformType(): string {
		let platform = 'web';
		if (isNativePlatform()) {
			platform = 'native';
		}
		return platform;
	}

	function getOsDeviceType(): string
	{
		const os = getOs();
		const native = isNativePlatform();
		const osConcatType = os + '_' + ( native ? 'native' : 'web' );
		switch (osConcatType) {
			case MobiPointsCoreOsDeviceTypeInterface.ANDROID_WEB:
				return MobiPointsCoreOsDeviceTypeInterface.ANDROID_WEB;
			case MobiPointsCoreOsDeviceTypeInterface.IOS_WEB:
				return MobiPointsCoreOsDeviceTypeInterface.IOS_WEB;
			case MobiPointsCoreOsDeviceTypeInterface.ANDROID_NATIVE:
				return MobiPointsCoreOsDeviceTypeInterface.ANDROID_NATIVE;
			case MobiPointsCoreOsDeviceTypeInterface.IOS_NATIVE:
				return MobiPointsCoreOsDeviceTypeInterface.IOS_NATIVE;
			case MobiPointsCoreOsDeviceTypeInterface.IOS:
				return MobiPointsCoreOsDeviceTypeInterface.IOS;
			case MobiPointsCoreOsDeviceTypeInterface.ANDROID:
				return MobiPointsCoreOsDeviceTypeInterface.ANDROID;
			default:
				return osConcatType;
		}
	}

	return {
		internetConnection,
		appVersion,
		hasInternetConnection,
		setInternetConnection,
		isNativePlatform,
		setAppVersion,
		initVersion,
		addLogByError,
		addLog,
		addLogByException,
		isDevelopmentEnv,
		logs,
		logsAsString,
		resetLogs,
		isIosPlatform,
		isAndroidPlatform,
		isDeveloper,
		appActiveState,
		setAppActiveState,
		backgroundTrackingActive,
		setBackgroundTrackingActive,
		setDeveloperMode,
		hasDeveloperMode,
		getOs,
		getOsDeviceType,
		getPlatformType,
		logsSortedDesc,
		isIosOrAndroid,
		getPing
	}
}
