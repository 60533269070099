import {MobiPointsCoreLogFactory} from "@/mobipoints/core/log_factory";

export default function useLogFactory() {
	let logFactory: MobiPointsCoreLogFactory | null = null;
	const getLogFactory = (): MobiPointsCoreLogFactory => {
		if (!logFactory) {
			logFactory = new MobiPointsCoreLogFactory();
		}

		return logFactory;
	}

	return {
		getLogFactory,
	}
}