import useChallengeFactory from "@/composable/useChallengeFactory";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import {MobiPointsChallengeChallengeInterface} from "@/mobipoints/challenge/challenge/challenge.interface";
import {
	USER_CHALLENGE_STATE_ACCEPTED,
	USER_CHALLENGE_STATE_CANCELLED, USER_CHALLENGE_STATE_DECLINED
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import {MobiPointsApiChallenge} from "@/mobipoints/api/challenge";
import {AxiosError} from "axios";


interface ChallengeStateContainer<T> {
	loaded: boolean,
	data: T,
}

const resetChallenges = (state: any) => {
	state.challenges.loaded = false;
	state.challenges.data = [];
};

const setUserChallengeState = (state: any, uuid: string, userChallengeState: string) => {
	const challenge = state.challenges.data.find((challenge) => uuid === challenge.uuid) || null;
	if (null !== challenge) {
		if (challenge.userChallenges.length < 1) {
			const userChallenge = {
				user_id: 0,
				challenge_uuid: uuid,
				state: {code: userChallengeState, name: userChallengeState},
				progress: 0
			};
			challenge.userChallenges.push(userChallenge);
		}
		challenge.userChallenges.forEach((userChallenge) => {
			userChallenge.state.code = userChallengeState;
		});
	}
}

const {getChallengeFactory} = useChallengeFactory();

const challenge = {
	namespaced: true,
	state: () => ({
		challenges: {
			loaded: false,
			data: []
		} as ChallengeStateContainer<Array<Challenge | MobiPointsChallengeChallengeInterface>>,
	}),
	mutations: {
		challenges(state: any, value: Array<MobiPointsChallengeChallengeInterface>) {
			state.challenges.loaded = true;
			state.challenges.data = value;
		},
		resetChallenges(state: any) {
			resetChallenges(state);
		},
		acceptChallenge(state: any, uuid: string) {
			setUserChallengeState(state, uuid, USER_CHALLENGE_STATE_ACCEPTED);
			console.log("accept challenge", uuid);
		},
		cancelChallenge(state: any, uuid: string) {
			setUserChallengeState(state, uuid, USER_CHALLENGE_STATE_CANCELLED);
			console.log("cancel challenge", uuid);
		},
		declineChallenge(state: any, uuid: string) {
			setUserChallengeState(state, uuid, USER_CHALLENGE_STATE_DECLINED);
			console.log("decline challenge", uuid);
		},
	},
	actions: {
		async acceptChallenge(context: any, uuid: string): Promise<string|null> {
			try {
				const response = await MobiPointsApiChallenge.acceptChallenge(uuid);
				if (200 === response.status) {
					context.commit('acceptChallenge', uuid);
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
		async cancelChallenge(context: any, uuid: string) {
			try {
				const response = await MobiPointsApiChallenge.cancelChallenge(uuid);
				if (200 === response.status) {
					context.commit('cancelChallenge', uuid);
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
		async declineChallenge(context: any, uuid: string) {
			try {
				const response = await MobiPointsApiChallenge.declineChallenge(uuid);
				if (200 === response.status) {
					context.commit('declineChallenge', uuid);
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
	},
	getters: {
		challenges: (state: any) => {
			return state.challenges.data.map((data) => {
				if (!(data instanceof Challenge)) {
					return getChallengeFactory().createChallengeByApiResponse(data);
				}
			});
		},
	}
}

export default challenge;