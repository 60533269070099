import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b08b048"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = {
  style: {"margin-top":"2rem"},
  class: "text-center text-slate-950 dark:text-slate-500"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "text-center text-slate-900 dark:text-slate-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Transaktionen")
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.wallet?.items, (walletItem) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            key: walletItem.uuid
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_badge, {
                style: {"z-index":"9","width":"96%"},
                class: "text-base",
                color: walletItem.getBadgeColor(),
                slot: "start"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(walletItem.getAmountFormatted(true)), 1)
                ]),
                _: 2
              }, 1032, ["color"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_2, _toDisplayString(walletItem.getItemName()), 1),
                  _createElementVNode("h3", {
                    class: "text-center text-slate-900 dark:text-slate-400",
                    innerHTML: walletItem.getItemDescription()
                  }, null, 8, _hoisted_3),
                  _createElementVNode("h3", _hoisted_4, _toDisplayString(walletItem.getDateFormatted()), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 512), [
      [_vShow, $props.wallet?.items && $props.wallet?.items.length > 0]
    ]),
    _withDirectives(_createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, {
          size: "large",
          icon: $setup.icons.informationCircleOutline
        }, null, 8, ["icon"]),
        _createVNode(_component_ion_label, {
          class: "ion-text-wrap",
          style: {"margin-left":".5rem"}
        }, {
          default: _withCtx(() => [
            _createTextVNode("Es sind noch kein Einträge in deinem Wallet vorhanden! ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, $props.wallet?.items && $props.wallet?.items.length < 1]
    ]),
    _createVNode(_component_ion_infinite_scroll, {
      onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => ($setup.loadMoreWalletItems($event))),
      threshold: "100px",
      id: "infinite-scroll"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_infinite_scroll_content, {
          "loading-spinner": "dots",
          "loading-text": "Loading more wallet items..."
        })
      ]),
      _: 1
    })
  ]))
}