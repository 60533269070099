<template>
	<div class="p-2">
		<h3 v-show="title.length > 0" class="text-base font-semibold leading-6">{{title}}</h3>
		<dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow md:grid-cols-3 md:divide-x md:divide-y-0">
			<div class="px-4 py-5 sm:p-6">
				<dt class="text-base font-normal text-gray-900">{{ name }}</dt>
				<dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
					<div class="flex items-baseline text-2xl font-semibold text-indigo-600">
						{{ stat }}
						<span class="ml-2 text-sm font-medium text-gray-500">{{statFromName}} {{ previousStat }}</span>
					</div>

					<div :class="[changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0']">
						<ArrowUpIcon v-if="changeType === 'increase'"
									 class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
									 aria-hidden="true"/>
						<ArrowDownIcon v-else class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
									   aria-hidden="true"/>
						<span class="sr-only"> {{
								changeType === 'increase' ? 'Increased' : 'Decreased'
							}} {{statByName}} </span>
						{{ change }}
					</div>
				</dd>
			</div>
		</dl>
	</div>
</template>
<script>
import {ref} from 'vue';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/20/solid';
import * as icons from 'ionicons/icons';
import useData from '@/composable/useData';

export default {
	name: 'DefaultStat',
	components: {ArrowDownIcon, ArrowUpIcon},
	props: {
		title: {
			type: String,
			default: "Letzten 30 Tage"
		},
		name: String,
		stat: String,
		previousStat: String,
		change: String,
		changeType: String, //TODO ENUM increase, decrease
	},
	setup() {
		const {getTextValueByKey} = useData();
		const statFromName = ref(getTextValueByKey('stat.default.from', [], 'von'))
		const statByName = ref(getTextValueByKey('stat.default.by', [], 'by'))

		return {
			icons,
			statFromName,
			statByName,
		};
	},
};
</script>

<style scoped>
</style>