import {MobiPointsChallengeChallengeTypeInterface} from "@/mobipoints/challenge/challenge/challenge_type.interface";
import {MobiPointsChallengeChallengeStateInterface} from "@/mobipoints/challenge/challenge/challenge_state.interface";
import {MobiPointsChallengeUserChallengeInterface} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";

export const CHALLENGE_STATE_NULLIFY = 'nullify';
export const CHALLENGE_STATE_NEW = 'new';
export const CHALLENGE_STATE_IN_PROGRESS = 'in_progress';
export const CHALLENGE_STATE_CANCELLED = 'cancelled';
export const CHALLENGE_STATE_FINISHED = 'finished';

export interface MobiPointsChallengeChallengeDataInterface {
	[key: string]: any;
	points?: number;
	img?: string;
}

export interface MobiPointsChallengeChallengeInterface {
	uuid: string;
	startTimestamp: number | null;
	endTimestamp: number | null;
	title: string;
	description?: string | null;
	data: MobiPointsChallengeChallengeDataInterface | null;
	type: MobiPointsChallengeChallengeTypeInterface;
	state: MobiPointsChallengeChallengeStateInterface;
	userChallenges: MobiPointsChallengeUserChallengeInterface[];
}