import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-584e8afd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = ["src", "alt"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["href"]
const _hoisted_20 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.activeBannerNews && _ctx.activeBannerNews.length > 0 || _ctx.activeCardNews && _ctx.activeCardNews.length > 0 || _ctx.activePopupNews && _ctx.activePopupNews.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showBanner && _ctx.activeBannerNews && _ctx.activeBannerNews.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeBannerNews, (bannerNews, idx) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: idx,
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", {
                            innerHTML: bannerNews.title
                          }, null, 8, _hoisted_2),
                          _createElementVNode("p", {
                            innerHTML: bannerNews.description
                          }, null, 8, _hoisted_3)
                        ]),
                        _: 2
                      }, 1024),
                      (bannerNews.showButton && bannerNews.buttonType === 'internal')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: '/'+bannerNews.buttonLink
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                color: bannerNews.buttonColor,
                                expand: "block",
                                class: "ripple-parent"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    innerHTML: bannerNews.buttonText
                                  }, null, 8, _hoisted_4),
                                  _createVNode(_component_ion_ripple_effect)
                                ]),
                                _: 2
                              }, 1032, ["color"])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (bannerNews.showButton && bannerNews.buttonType === 'external')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: bannerNews.buttonLink
                          }, [
                            _createVNode(_component_ion_button, {
                              color: bannerNews.buttonColor,
                              expand: "block",
                              class: "ripple-parent"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: bannerNews.buttonText
                                }, null, 8, _hoisted_6),
                                _createVNode(_component_ion_ripple_effect)
                              ]),
                              _: 2
                            }, 1032, ["color"])
                          ], 8, _hoisted_5))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showCard && _ctx.activeCardNews && _ctx.activeCardNews.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeCardNews, (cardNews, idx) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  key: idx,
                  class: "ion-margin"
                }, {
                  default: _withCtx(() => [
                    (cardNews.image)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "slide-image favoriteImg",
                          src: cardNews.image,
                          alt: cardNews.image
                        }, null, 8, _hoisted_8))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_card_header, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_title, {
                          class: "ion-align-items-center ion-justify-content-start",
                          style: {"display":"flex","color":"var(--ion-color-warning)"}
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              innerHTML: cardNews.title
                            }, null, 8, _hoisted_9)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          innerHTML: cardNews.description
                        }, null, 8, _hoisted_10),
                        (cardNews.showButton && cardNews.buttonType === 'internal')
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: '/'+cardNews.buttonLink
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  color: cardNews.buttonColor,
                                  expand: "block",
                                  class: "ripple-parent"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      innerHTML: cardNews.buttonText
                                    }, null, 8, _hoisted_11),
                                    _createVNode(_component_ion_ripple_effect)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : _createCommentVNode("", true),
                        (cardNews.showButton && cardNews.buttonType === 'external')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              href: cardNews.buttonLink
                            }, [
                              _createVNode(_component_ion_button, {
                                color: cardNews.buttonColor,
                                expand: "block",
                                class: "ripple-parent"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    innerHTML: cardNews.buttonText
                                  }, null, 8, _hoisted_13),
                                  _createVNode(_component_ion_ripple_effect)
                                ]),
                                _: 2
                              }, 1032, ["color"])
                            ], 8, _hoisted_12))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.showPopup && _ctx.activePopupNews && _ctx.activePopupNews.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activePopupNews, (popupNews, idx) => {
                return (_openBlock(), _createElementBlock("div", { key: idx }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(popupNews.fullscreen ? 'back-drop-news-popup-fullscreen' : 'back-drop-news-popup-inline')
                  }, [
                    _createVNode(_component_ion_card, { class: "ion-margin" }, {
                      default: _withCtx(() => [
                        (popupNews.image)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: "slide-image favoriteImg",
                              src: popupNews.image,
                              alt: popupNews.image
                            }, null, 8, _hoisted_15))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_title, {
                              class: "ion-align-items-center ion-justify-content-start",
                              style: {"display":"flex","color":"var(--ion-color-warning)"}
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: popupNews.title
                                }, null, 8, _hoisted_16)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_card_content, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              innerHTML: popupNews.description
                            }, null, 8, _hoisted_17),
                            (popupNews.showButton && popupNews.buttonType === 'internal')
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  onClick: ($event: any) => (popupNews.show=false),
                                  to: '/'+popupNews.buttonLink
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_button, {
                                      color: popupNews.buttonColor,
                                      expand: "block",
                                      class: "ripple-parent"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", {
                                          innerHTML: popupNews.buttonText
                                        }, null, 8, _hoisted_18),
                                        _createVNode(_component_ion_ripple_effect)
                                      ]),
                                      _: 2
                                    }, 1032, ["color"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick", "to"]))
                              : _createCommentVNode("", true),
                            (popupNews.showButton && popupNews.buttonType === 'external')
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  href: popupNews.buttonLink
                                }, [
                                  _createVNode(_component_ion_button, {
                                    color: popupNews.buttonColor,
                                    expand: "block",
                                    class: "ripple-parent"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        innerHTML: popupNews.buttonText
                                      }, null, 8, _hoisted_20),
                                      _createVNode(_component_ion_ripple_effect)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"])
                                ], 8, _hoisted_19))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ], 2)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}