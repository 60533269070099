import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';
import {
	AbstractWalletItem,
} from '@/mobipoints/wallet/item/abstract_wallet_item.type';
import {WalletItemType} from '@/mobipoints/wallet/item/wallet_item.interface';
import {
	WalletItemDeposit,
} from '@/mobipoints/wallet/item/deposit/wallet_item_deposit.type';
import {
	WalletItemPayout,
} from '@/mobipoints/wallet/item/payout/wallet_item_payout.type';
import {
	WalletItemCorrection,
} from '@/mobipoints/wallet/item/correction/wallet_item_correction.type';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import {
	TreasureWalletItemDeposit
} from '@/mobipoints/wallet/treasures/deposit/wallet_item_deposit.type';
import {
	TreasureWalletItemPayout
} from '@/mobipoints/wallet/treasures/payout/wallet_item_payout.type';
import {
	TreasureWalletItemCorrection
} from '@/mobipoints/wallet/treasures/correction/wallet_item_correction.type';

export class WalletItemFactory {
	/**
	 *
	 * @param wallet_items
	 */
	createWalletItemListByResponse(wallet_items: Record<string, any>, walletType: WalletType|undefined = undefined): WalletItemList {
		const walletItemList = this.createEmptyWalletItemList();

		for (const [key, item] of Object.entries(wallet_items)) {
			walletItemList.push(this.createWalletItemsByResponse(item, walletType));
		}

		return walletItemList;
	}

	/**
	 *
	 * @param item
	 */
	createWalletItemsByResponse(item: Record<string, any>, walletType: WalletType|undefined = undefined): AbstractWalletItem {
		const uuid = item.uuid;
		const user_wallet_uuid = item.user_wallet_uuid;
		const type = item.type;
		const state = item.state;
		const subtype = item.subtype;
		const amount = item.amount;
		const reference = item.reference;
		const data = item.data;
		const timestamp = item.timestamp;

		let walletItem: AbstractWalletItem;
		switch (walletType) {
			case WalletType.TREASURES:
				switch (type) {
					case WalletItemType.DEPOSIT:
						walletItem = new TreasureWalletItemDeposit(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					case WalletItemType.PAYOUT:
						walletItem = new TreasureWalletItemPayout(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					case WalletItemType.CORRECTION:
						walletItem = new TreasureWalletItemCorrection(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					default:
						throw new Error('Invalid Wallet Item: ' + JSON.stringify(item));
				}
				break;
			default:
				switch (type) {
					case WalletItemType.DEPOSIT:
						walletItem = new WalletItemDeposit(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					case WalletItemType.PAYOUT:
						walletItem = new WalletItemPayout(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					case WalletItemType.CORRECTION:
						walletItem = new WalletItemCorrection(uuid, user_wallet_uuid, type, state, subtype, amount, reference, data, timestamp);
						break;
					default:
						throw new Error('Invalid Wallet Item: ' + JSON.stringify(item));
				}
		}

		return walletItem;
	}

	createEmptyWalletItemList(): WalletItemList {
		return new WalletItemList([]);
	}
}