<template>
	<div :class="[!isNativePlatform() ? 'center-animation' : '']">
		<svg class="animated" :style="getAnimationStyle()" id="freepik_stories-oh-no" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
			 version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
			<g id="freepik--Plant--inject-5" class="animable" style="transform-origin: 434.965px 343.09px;">
				<g id="freepik--Plants--inject-5" class="animable" style="transform-origin: 434.965px 343.09px;">
					<path d="M419.2,399.72a.51.51,0,0,1-.24,0l-6.71-2.57a.59.59,0,0,1-.37-.44c-.05-.19-4.26-19.35,10.93-43.07,10.84-16.92,26.22-27,35-23a10.51,10.51,0,0,1,6.36,8.21,12.29,12.29,0,0,1-4.79,10.91c-.78.64-2,1.49-3.51,2.58-9.44,6.7-31.55,22.39-36.14,46.9a.63.63,0,0,1-.29.42A.59.59,0,0,1,419.2,399.72ZM413,396.07l5.74,2.21c5-24.59,27-40.25,36.49-47,1.51-1.07,2.7-1.92,3.45-2.53A11.08,11.08,0,0,0,463,339a9.17,9.17,0,0,0-5.67-7.23c-8.27-3.8-23,6.09-33.53,22.53C410,375.87,412.51,393.51,413,396.07Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 437.765px 364.75px;" id="eltkomtsa1ha"
						  class="animable"></path>
					<path d="M415.39,398.26h-.08a.62.62,0,0,1-.55-.67c2.28-29,24.32-54.7,37.91-59a.59.59,0,0,1,.75.41.62.62,0,0,1-.39.77C439.59,344,418.11,370.24,416,397.7A.6.6,0,0,1,415.39,398.26Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 434.103px 368.411px;" id="elio0kto6v5dg"
						  class="animable"></path>
					<path d="M412.49,397.15a.58.58,0,0,1-.26,0l-4.53-2a.6.6,0,0,1-.36-.44c0-.28-5.62-28.79,4-65.33,4.91-18.61,17.39-35.93,29.66-41.18,5.9-2.53,11.44-2.32,16.46.61,2.84,1.65,4.51,3.82,5,6.45,1.25,7.24-6.83,16.95-15.39,27.23-6,7.19-12.17,14.63-15.33,21.44a.59.59,0,0,1-.8.29.63.63,0,0,1-.28-.82c3.22-7,9.47-14.47,15.51-21.72,8.36-10.05,16.27-19.54,15.12-26.22-.39-2.26-1.82-4.09-4.37-5.58-4.69-2.73-9.88-2.92-15.42-.54-12,5.12-24.14,22.1-29,40.38-9,34.18-4.64,61.22-4.06,64.43l4.23,1.83a.63.63,0,0,1,.32.81A.59.59,0,0,1,412.49,397.15Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 434.148px 341.805px;" id="elicoqiedrow"
						  class="animable"></path>
					<path d="M413,379.44h0a.61.61,0,0,1-.58-.64c.79-45.09,28.38-78.26,36.12-83.63a.59.59,0,0,1,.83.17.64.64,0,0,1-.16.86c-7.63,5.29-34.82,38-35.6,82.63A.6.6,0,0,1,413,379.44Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 430.945px 337.254px;" id="elhy5mst68lmm"
						  class="animable"></path>
				</g>
			</g>
			<g id="freepik--Shadow--inject-5" class="animable" style="transform-origin: 325.602px 404.408px;">
				<path id="freepik--shadow--inject-5"
					  d="M258.05,365.41c-37.3,21.54-37.3,56.46,0,78s97.79,21.53,135.1,0,37.3-56.46,0-78S295.36,343.87,258.05,365.41Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 325.602px 404.408px;" class="animable"></path>
			</g>
			<g id="freepik--Character--inject-5" class="animable" style="transform-origin: 317.96px 253.708px;">
				<g id="freepik--character--inject-5" class="animable" style="transform-origin: 317.96px 253.708px;">
					<g id="freepik--Bottom--inject-5" class="animable" style="transform-origin: 324.6px 402.261px;">
						<path d="M286.27,434.06s-27.66-10-36.67-24.58c.83-16.2,2.39-45.29,2.63-45.09l52.13,5.39-11,45.71Z"
							  style="fill: rgb(55, 71, 79); transform-origin: 276.98px 399.224px;" id="el89dctxrbo8p"
							  class="animable"></path>
						<path d="M286.27,427.61v6.45c19.37,7.94,55.46,8.1,78.88.45,15.9-5.19,29.72-14,34.45-19.43l-1.78-45.29h-141c0,6.91,4.26,27.23,9.14,35.69S286.27,427.61,286.27,427.61Z"
							  style="fill: rgb(69, 90, 100); transform-origin: 328.21px 404.962px;" id="elabchf1col9b"
							  class="animable"></path>
					</g>
					<g id="freepik--Top--inject-5" class="animable" style="transform-origin: 317.96px 240.296px;">
						<g id="freepik--Arm--inject-5" class="animable" style="transform-origin: 215.285px 170.488px;">
							<path d="M269,195.93c-13.75,0-22,2.52-34,2.57-30.83.13-47.87,1.93-47.87,1.93s-40,11.31-37.17,21.13c3.68,12.89,28.32,21.59,60.51,26.27,33.25,4.83,53.83,7.44,53.83,7.44Z"
								  style="fill: rgb(55, 71, 79); transform-origin: 209.408px 225.6px;" id="elzgpsbozz8"
								  class="animable"></path>
							<path d="M277.06,111.38c1.09-4.4,2.23-8.78,3.32-13.18a25.57,25.57,0,0,0,1-5.61,8.82,8.82,0,0,0-1.44-5.42c-.79-1.1-2.41-2-3.45-1.09a3,3,0,0,0-.7,1q-5.22,10-10.43,20a53.1,53.1,0,0,1-3.68,6.32c-1.37,1.94-3.07,3.76-3.92,6s-4.12,8.31-5.22,10.43c-2.35,4.55-6.23,9.7-14.33,15.27-12.67,8.7-32.42,15.18-62.82,31-10,5.18-17.58,16.68-22.37,26.76-8.3,17.45-2.43,26.26,10.51,26.12,10.33-.12,24.48-2.85,39.19-13.58C227,197.64,242.31,177.22,255,169.12c3.14-2.31,8.67-3.65,10.64-5.32,3.86-3.28,5.61-6.78,6.54-11.75s.61-10.09.83-15.13c.19-4.12.08-8,.94-12.06C274.88,120.35,276,115.86,277.06,111.38Z"
								  style="fill: rgb(255, 168, 167); transform-origin: 215.285px 157.344px;"
								  id="elajkun36wf3"
								  class="animable"></path>
							<path d="M264.41,108.84h0l.93-1.77,10.43-20a3,3,0,0,1,.7-1c1-.87,2.66,0,3.45,1.09a8.82,8.82,0,0,1,1.44,5.42,25.57,25.57,0,0,1-1,5.61c-1.09,4.4-2.23,8.78-3.32,13.18s-2.18,9-3.13,13.48c-.86,4.07-.75,7.94-.94,12.06-.22,5,.1,10.15-.83,15.13s-2.68,8.47-6.54,11.75a21.81,21.81,0,0,1-5,2.47c-4.08-8.23-1.93-21.8-.17-34C261.91,122.08,258.45,117.67,264.41,108.84Z"
								  style="fill: rgb(242, 143, 143); transform-origin: 269.822px 125.987px;"
								  id="elaby12z8c9hl" class="animable"></path>
						</g>
						<g id="freepik--Chest--inject-5" class="animable" style="transform-origin: 325.2px 240.296px;">
							<path d="M252.24,258.07c.27-43.92,19.45-56.38,27.05-59.72l31-6.9c9.54.85,50.34,12.12,61.57,14.64,7.23,1.63,19.09,5.81,23.22,28.78,3.36,18.66,3.85,26.19,1.65,60.11a460.41,460.41,0,0,0,2.2,89.54c-49,16.13-103.15,22.7-140.39-4.6.73-6.18-4.12-57.45-4.76-72.59S252.16,270,252.24,258.07Z"
								  style="fill: rgb(250, 250, 250); transform-origin: 325.583px 294.982px;"
								  id="elbtfycmkzlad" class="animable"></path>
							<path d="M296.92,197c-4.06,2.14-22.51,20-24.9,49.58-4.38,54.48-4.29,102.68-4.29,149.82-6.49-3.16-17.11-10.67-20.25-19.17,1-18.57,1-56.79,1.3-69.85.47-18.6,1-48.77,2.13-60.73,1.47-15.91,4.93-39.56,18-50.67,14.07-1.42,27.41-2.52,27.41-2.52Z"
								  style="fill: rgb(206, 79, 79); transform-origin: 272.2px 294.93px;" id="ell44p0gxnk3e"
								  class="animable"></path>
							<g id="freepik--Head--inject-5" class="animable"
							   style="transform-origin: 305.838px 146.156px;">
								<path d="M336.76,178.26c1.78-10.64,12.33-21.76,17.08-31,4.57-9,7.29-15.9,6.74-24.3-.32-5-3.47-11.72-7.17-13.12,4.23-18.52-9.68-27.07-17.06-28.81a78.73,78.73,0,0,1-10.21-2.81c-4-1.67-4.93-4.89-8.17-7.63-5.57-4.71-15-4.79-18.5,2.37-3.71-4.78-8.74-4.65-14.54-3.16-5.53,1.43-8.14,6.12-7.56,11.22-7.77-4.32-14.88,1.18-15.58,5.74-.52,3.37.2,6.21-2,9.23-2.47,3.4-7.16,3.36-8.59.62-.79,4.78,1.08,8.42,6.27,8.14a10.87,10.87,0,0,0-1.33,9.13,9.9,9.9,0,0,0,9,6.81c.55,2.63.67,5,2.33,7.29a17.22,17.22,0,0,0,6.11,5.49c7.86,3.72,16.55,4.91,25,6.64l10,2.06c.59.11,3.62.29,4,.81Z"
									  style="fill: rgb(38, 50, 56); transform-origin: 305.838px 122.771px;"
									  id="el0cgcye1svj9" class="animable"></path>
								<path d="M349.61,164.47c-7.46,4.49-10.9-.37-10.9-.37l.85,38.84s-16,20.66-44.65,22.09c-2.06-6.27-2-17.3-.45-20.74l-.34-15.6a101.55,101.55,0,0,1-12.65-.53c-8.34-1.12-15.4-7.7-17.81-16.72-2.51-9.43-3.23-24.86-1.21-36.18a31.4,31.4,0,0,0,.43-8.76c-.83-7.75.56-12.27,2.54-18.79,9.16-7.41,35.12-9,54.94,3-4.22,10.1-1.53,20.36,7.05,24.82-.39,3.18-.77,11.22,4.46,11,3.77-.11,4-4.84,7.86-9s12.87-5.31,16.38.81C361,146.91,357.79,159.56,349.61,164.47Z"
									  style="fill: rgb(255, 168, 167); transform-origin: 309.849px 163.476px;"
									  id="elen5ud5i1f2p" class="animable"></path>
								<path d="M294.12,188.69c13.23-1.6,23.27-3.06,30.14-12.66,4.3-6,7.93-15.7,7.93-15.7S330.87,172,326.57,180c-3.31,6.13-11.48,11.25-32.21,13.09Z"
									  style="fill: rgb(242, 143, 143); transform-origin: 313.155px 176.71px;"
									  id="el7evxsgr83x7" class="animable"></path>
								<path d="M299.16,136.34a4.1,4.1,0,1,0,4-4.33A4.15,4.15,0,0,0,299.16,136.34Z"
									  style="fill: rgb(38, 50, 56); transform-origin: 303.255px 136.109px;"
									  id="el5c76u6um1jv" class="animable"></path>
								<path d="M309.34,125.62l-7.95-6.07a5.26,5.26,0,0,0,1,7.2A4.88,4.88,0,0,0,309.34,125.62Z"
									  style="fill: rgb(38, 50, 56); transform-origin: 304.868px 123.64px;"
									  id="eljpmsm3pf4ol" class="animable"></path>
								<path d="M276.91,118.48l-7.78,6.41a4.84,4.84,0,0,0,7,.84A5.3,5.3,0,0,0,276.91,118.48Z"
									  style="fill: rgb(38, 50, 56); transform-origin: 273.563px 122.669px;"
									  id="elfsfrvedc1ge" class="animable"></path>
								<path d="M269,135.46a4.1,4.1,0,1,0,4-4.34A4.16,4.16,0,0,0,269,135.46Z"
									  style="fill: rgb(38, 50, 56); transform-origin: 273.094px 135.219px;"
									  id="elhchs59ggrut" class="animable"></path>
								<path d="M276,148.56l13,5.71,3.93-25.4C288,127.16,281.86,138.85,276,148.56Z"
									  style="fill: rgb(242, 143, 143); transform-origin: 284.465px 141.486px;"
									  id="elt5zuk8laox" class="animable"></path>
								<path d="M288.68,168.2c2.12,1.4,3.41-.72,7.12-2.26s7.21.29,6.71-2.79c-.52-3.26-6.25-5.14-9.95-3.6S286.27,166.6,288.68,168.2Z"
									  style="fill: rgb(177, 102, 104); transform-origin: 295.151px 163.831px;"
									  id="eln430ajqiq7m" class="animable"></path>
								<path d="M295.51,165.63a9.4,9.4,0,0,0-1,.52,4.61,4.61,0,0,1,3.93-5,3.78,3.78,0,0,1,3.18.75,3.54,3.54,0,0,1,.48,1C302.87,165.9,299.22,164.09,295.51,165.63Z"
									  style="fill: rgb(242, 143, 143); transform-origin: 298.346px 163.606px;"
									  id="elm6e686ln4ur" class="animable"></path>
							</g>
							<path d="M391.48,209.86l-21.69-13.93-30.42-1.73s-8,18.11-8.51,80.11c-.55,67.44,1.26,139,1.26,139s51.48-.11,70.8-29c0,0-2.17-20.55-3.29-54.52-.66-20.07-1.56-33.52.61-50.7C404.29,247.16,401,226.85,391.48,209.86Z"
								  style="fill: rgb(206, 79, 79); transform-origin: 366.838px 303.755px;"
								  id="elpwa3fx30xi"
								  class="animable"></path>
							<path d="M333.33,225.62l.18-29.14s4.91-5.66,5.71-8.93l-.11-5.15s2.63-.05,3,3.6.62,9.61.62,9.61Z"
								  style="fill: rgb(55, 71, 79); transform-origin: 338.03px 204.01px;" id="elkgfdsq9f1o"
								  class="animable"></path>
							<path d="M278.08,222l3.44-24.38s6-6.49,9.7-8.91h2.9l.23,10.33A87.3,87.3,0,0,0,278.08,222Z"
								  style="fill: rgb(55, 71, 79); transform-origin: 286.215px 205.355px;"
								  id="elhgibdj5rph"
								  class="animable"></path>
							<path d="M281.7,117.36a5,5,0,0,0-1-1.3,3.9,3.9,0,0,0-2.89-1.11c-.48,0-.19.74.16.79s.48.11.71.17h0s0,0,0,0l.15.06.13.06.06,0c.17.09.33.19.5.3l0,0s0,0,0,0l.13.1.22.21.07.07.07.08a2,2,0,0,1,.18.23l.16.23.05.09.06.12.12.27.06.15s.05.15,0,.09l0,.09a.83.83,0,0,0,0,.15,4,4,0,0,0,.18.68.84.84,0,0,0,.29.33c.11.12.32.27.46.14C282.38,118.91,282,118,281.7,117.36Z"
								  style="fill: rgb(242, 143, 143); transform-origin: 279.807px 117.178px;"
								  id="elpkdttjsp79"
								  class="animable"></path>
							<path d="M300.6,116.24a3.39,3.39,0,0,0-1.36,1,7.36,7.36,0,0,0-.9,1.18,7,7,0,0,0-.66,1.34,3.52,3.52,0,0,0-.21.77,2.3,2.3,0,0,0,0,.37,2.09,2.09,0,0,0,0,.25,2.35,2.35,0,0,0,.14.45c.06.23.48.6.67.28a3.77,3.77,0,0,0,.28-.48c0-.14.11-.27.16-.41s.15-.45.24-.67a7.54,7.54,0,0,1,.57-1.22,7.79,7.79,0,0,1,.76-1,6.07,6.07,0,0,0,1-1.14C301.47,116.52,300.94,116.14,300.6,116.24Z"
								  style="fill: rgb(242, 143, 143); transform-origin: 299.394px 119.114px;"
								  id="elb2v1qfg45ib" class="animable"></path>
						</g>
						<g id="freepik--arm--inject-5" class="animable" style="transform-origin: 412.38px 180.968px;">
							<path d="M369.79,195.93c25.86-.31,34.81,2.74,54.34,8.08,9.26,2.53,36.86,9.13,36.86,9.13l25.29,28.05L479.92,256c-8.83,4.87-32.8,1.75-59,4.67-10.46,1.17-16.85,5.16-20.05,12.6-1.1,2.57-18.69,4.72-27-16C366.35,238.5,364.8,214.93,369.79,195.93Z"
								  style="fill: rgb(55, 71, 79); transform-origin: 426.591px 235.39px;"
								  id="el0cwz2omvfzot"
								  class="animable"></path>
							<path d="M381.11,151.41c-1-5.35-.17-12.88-.83-18s-3.52-13.1-4-19.57-1.39-7.12-3.86-6.58c-2,.44-4.69,4.82-3.71,11.69C369.39,124,373.53,147.88,381.11,151.41Z"
								  style="fill: rgb(242, 143, 143); transform-origin: 374.806px 129.26px;"
								  id="elr17h2p5925"
								  class="animable"></path>
							<path d="M366.5,111.44c1.71,3.57,4.73,7,6.26,10.66,1.61,3.81,1.46,5.64,2.84,13.1,1.08,5.81,1,11,7.91,18,12.28,12.46,56.2,29.84,82.58,51.26,10.07,8.16,15.64,15.63,19.14,28,4.61,16.32-1.85,25.35-11.73,24.73-11.9-.75-32.75-6.8-47.77-21.47-24.84-24.26-48.38-48.31-61.5-59.65-3.2-3.15-7.52-6.87-9.49-9.1-3.69-4.17-5.17-10.34-5.72-15.88-.92-9.17-4.6-16.16-4.29-20.33-.68-3.93-3-10-5.05-16.29-1.19-3.57-2.49-6.66-1-8.48a5.24,5.24,0,0,1,1.2-.9s-.13-.47-.16-.67a19.59,19.59,0,0,1-.58-4,5.24,5.24,0,0,1,1.06-3.66,3.28,3.28,0,0,1,1.13-.78s-.13-.36-.28-.84a9.46,9.46,0,0,1-.42-4,5,5,0,0,1,1.95-3.47,3.31,3.31,0,0,1,3.82,0,5.57,5.57,0,0,1,1,1.15l2.28,3.12c1.6-1.23,2.64-.43,4.06.79,1.74,1.5,3.21,3.28,4.8,4.92a13.91,13.91,0,0,1,3.39,4.84C363.44,105.43,365.05,108.42,366.5,111.44Z"
								  style="fill: rgb(255, 168, 167); transform-origin: 412.38px 172.142px;"
								  id="eleiz9s8587kq"
								  class="animable"></path>
							<path d="M354.74,167c-4.54-4.63-5.74-11.48-6.4-17.64s-3.7-12.21-3.61-18.57a17,17,0,0,0,.88,4.63c1.41,4.54,3,9,3.64,13.81.61,6.22,1.57,12.67,5.49,17.77Z"
								  style="fill: rgb(177, 102, 104); transform-origin: 349.734px 148.895px;"
								  id="eld6a49kv9inj" class="animable"></path>
							<path d="M349.73,91.84c1.89,2.38,6.22,7.58,8,10,2.51,3.45,3.41,7.87,4.92,11.82l1.3,4a6.8,6.8,0,0,0,.75,1.92,9.58,9.58,0,0,0,1.6,1.33,7.6,7.6,0,0,1-1.79-1.18c-.89-1.15-1.91-4.52-2.53-5.87-1.29-3.28-2.46-6.62-3.84-9.85-2.53-4-5.94-8-8.37-12.16Z"
								  style="fill: rgb(177, 102, 104); transform-origin: 358.015px 106.375px;"
								  id="elw0tohhvx6oe" class="animable"></path>
							<path d="M341.36,96a3.12,3.12,0,0,1,2.11-.2c.74.22,1.19,1,1.56,1.49.8,1.13,1.66,2.25,2.5,3.37a29.31,29.31,0,0,1,4.36,7.28c1.81,4.57,3.79,9.13,4.87,13.94a6,6,0,0,1,.18,2.1,14.23,14.23,0,0,0-1.11-4c-2.7-6.44-4.73-13.18-8.88-18.89-.77-1.17-1.56-2.33-2.31-3.53-.35-.65-.63-1.24-1.26-1.51a3.31,3.31,0,0,0-2,0Z"
								  style="fill: rgb(177, 102, 104); transform-origin: 349.162px 109.841px;"
								  id="elue5i8fo2db"
								  class="animable"></path>
							<path d="M339.91,105.1c2.27-.75,3.38,2.32,4.35,3.82,2.63,4.45,4.06,9.46,5.61,14.36a7,7,0,0,1,.11,3.11c.1-2.1-1-3.88-1.71-5.81s-1.47-3.83-2.2-5.75-1.63-3.73-2.45-5.59c-.85-1.51-1.52-4.48-3.71-4.14Z"
								  style="fill: rgb(177, 102, 104); transform-origin: 345.007px 115.687px;"
								  id="elnsp1dch93jt" class="animable"></path>
						</g>
					</g>
				</g>
			</g>
			<g id="freepik--speech-bubble-1--inject-5" class="animable" style="transform-origin: 153.56px 102px;">
				<g id="freepik--speech-bubble--inject-5" class="animable" style="transform-origin: 153.56px 102px;">
					<g id="freepik--speech-bubble--inject-5" class="animable" style="transform-origin: 153.56px 102px;">
						<path d="M206.59,148.25c15.37-11.5,25-27.94,25-46.19,0-34.79-34.94-63-78-63s-78,28.2-78,63,34.93,63,78,63a93.9,93.9,0,0,0,30.88-5.13c13.3,9,36.82,5.29,44.58-6.22C219.72,155.72,211.16,153.71,206.59,148.25Z"
							  style="fill: rgb(255, 255, 255); transform-origin: 153.59px 102.06px;" id="elheczhnwin6f"
							  class="animable"></path>
						<path d="M153.56,166c-43.58,0-79-28.7-79-64s35.45-64,79-64,79,28.71,79,64c0,17.46-8.93,34.29-24.54,46.33,4.43,4.52,12.26,6.18,20.75,4.33a1,1,0,0,1,1,1.53c-3.83,5.68-11.65,9.88-20.9,11.22s-18.19-.28-24.65-4.45A94.88,94.88,0,0,1,153.56,166Zm0-126c-42.48,0-77,27.81-77,62s34.55,62,77,62A92.68,92.68,0,0,0,184.11,159a1,1,0,0,1,.89.11c6,4,14.84,5.7,23.66,4.42,7.55-1.1,14-4.16,17.93-8.36-8.65,1.21-16.46-1.09-20.77-6.25a1,1,0,0,1-.22-.76,1,1,0,0,1,.39-.68c15.64-11.7,24.61-28.25,24.61-45.39C230.6,67.88,196,40.07,153.56,40.07Z"
							  style="fill: rgb(69, 90, 100); transform-origin: 153.56px 102px;" id="el9ot92typylh"
							  class="animable"></path>
					</g>
					<path d="M88.2,97.31a16.16,16.16,0,0,1,1-4.86,10.92,10.92,0,0,1,2.51-4,11.84,11.84,0,0,1,4.14-2.67,15.89,15.89,0,0,1,5.89-1,16.22,16.22,0,0,1,5.85,1,11.56,11.56,0,0,1,4.15,2.67,11.92,11.92,0,0,1,2.53,4,15.7,15.7,0,0,1,1,4.88c0,.58,0,1.23.07,1.94s0,1.43,0,2.18,0,1.48,0,2.19,0,1.35-.07,1.93a16.16,16.16,0,0,1-1,4.86,11.06,11.06,0,0,1-2.51,4,11.66,11.66,0,0,1-4.16,2.65,18.52,18.52,0,0,1-11.77,0,11.66,11.66,0,0,1-4.14-2.65,10.92,10.92,0,0,1-2.51-4,16.16,16.16,0,0,1-1-4.86c0-.58-.06-1.22-.07-1.93s0-1.44,0-2.19,0-1.48,0-2.18S88.17,97.89,88.2,97.31Zm20.86.23a6.81,6.81,0,0,0-7.33-7,6.79,6.79,0,0,0-7.32,7c-.06,1.17-.09,2.47-.09,3.89s0,2.73.09,3.89a7.19,7.19,0,0,0,2.14,5.11,8.35,8.35,0,0,0,10.36,0,7.16,7.16,0,0,0,2.15-5.11c.06-1.16.09-2.46.09-3.89S109.12,98.71,109.06,97.54Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 101.738px 101.408px;" id="elbp7nykr173"
						  class="animable"></path>
					<path d="M142.25,116.28a1.25,1.25,0,0,1-1.24,1.24h-3.5a1.25,1.25,0,0,1-1.24-1.24V104.59a7,7,0,0,0-1.29-4.42,4.69,4.69,0,0,0-4-1.66,4.77,4.77,0,0,0-3.85,1.66,6.62,6.62,0,0,0-1.4,4.42v11.69a1.19,1.19,0,0,1-.37.88,1.22,1.22,0,0,1-.87.36H121a1.25,1.25,0,0,1-1.25-1.24V86.08A1.26,1.26,0,0,1,121,84.83h3.5a1.23,1.23,0,0,1,.87.37,1.19,1.19,0,0,1,.37.88v9.8a10.6,10.6,0,0,1,2.81-2,8.68,8.68,0,0,1,3.82-.78,11.08,11.08,0,0,1,4.7.88,8,8,0,0,1,3,2.34,9,9,0,0,1,1.64,3.43,17.31,17.31,0,0,1,.48,4.13Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 131px 101.175px;" id="elofpouiny1l"
						  class="animable"></path>
					<path d="M179,116.28a1.25,1.25,0,0,1-1.24,1.24h-3.5a1.23,1.23,0,0,1-1.24-1.24V104.59a7,7,0,0,0-1.29-4.42,4.69,4.69,0,0,0-4-1.66,4.77,4.77,0,0,0-3.85,1.66,6.62,6.62,0,0,0-1.4,4.42v11.69a1.19,1.19,0,0,1-.37.88,1.22,1.22,0,0,1-.87.36h-3.5a1.22,1.22,0,0,1-.88-.36,1.19,1.19,0,0,1-.37-.88V94.82a1.26,1.26,0,0,1,1.25-1.24h3.5a1.26,1.26,0,0,1,1.24,1.24v1.06a10.6,10.6,0,0,1,2.81-2,8.68,8.68,0,0,1,3.82-.78,11.08,11.08,0,0,1,4.7.88,8,8,0,0,1,3,2.34,9,9,0,0,1,1.64,3.43,17.31,17.31,0,0,1,.48,4.13Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 167.745px 105.307px;" id="elcelfqc3omiv"
						  class="animable"></path>
					<path d="M194.56,93.12a12.79,12.79,0,0,1,4.58.78,11.07,11.07,0,0,1,3.55,2.17,10.86,10.86,0,0,1,2.39,3.22,10.65,10.65,0,0,1,1,4,10.42,10.42,0,0,1,0,1.06v2.56a9.91,9.91,0,0,1,0,1,11.73,11.73,0,0,1-1.06,4,10.3,10.3,0,0,1-2.37,3.2,11,11,0,0,1-3.55,2.14,13.84,13.84,0,0,1-9.16,0,11,11,0,0,1-3.55-2.14,10,10,0,0,1-2.37-3.2,11.5,11.5,0,0,1-1.06-4c0-.27-.05-.62-.07-1s0-.84,0-1.27,0-.86,0-1.29,0-.78.07-1.06a10.47,10.47,0,0,1,1-4,10.69,10.69,0,0,1,2.39-3.22A11.07,11.07,0,0,1,190,93.9,12.75,12.75,0,0,1,194.56,93.12Zm5.57,10.36a5.87,5.87,0,0,0-.67-2.37,4.47,4.47,0,0,0-1.31-1.5,4.91,4.91,0,0,0-1.7-.76,9.19,9.19,0,0,0-1.89-.2,9,9,0,0,0-1.89.2,4.91,4.91,0,0,0-1.7.76,4.5,4.5,0,0,0-1.32,1.5,6,6,0,0,0-.66,2.37c0,.25-.06.55-.07.92s0,.75,0,1.15,0,.79,0,1.18a8.26,8.26,0,0,0,.07.9,6,6,0,0,0,.66,2.37,4.46,4.46,0,0,0,1.32,1.49,4.73,4.73,0,0,0,1.7.76,8.42,8.42,0,0,0,1.89.21,8.55,8.55,0,0,0,1.89-.21,4.73,4.73,0,0,0,1.7-.76,4.44,4.44,0,0,0,1.31-1.49,5.87,5.87,0,0,0,.67-2.37,6.86,6.86,0,0,0,.05-.9V104.4A7.69,7.69,0,0,0,200.13,103.48Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 194.487px 105.574px;" id="elwm97uvbge8k"
						  class="animable"></path>
					<path d="M215.28,111a1.25,1.25,0,0,1,1.24,1.24v4a1.25,1.25,0,0,1-1.24,1.24h-4a1.25,1.25,0,0,1-1.25-1.24v-4a1.25,1.25,0,0,1,1.25-1.24Zm1-4a1.25,1.25,0,0,1-1.24,1.24h-3.5A1.25,1.25,0,0,1,210.3,107V86.54a1.26,1.26,0,0,1,1.25-1.25h3.5a1.21,1.21,0,0,1,.87.37,1.19,1.19,0,0,1,.37.88Z"
						  style="fill: rgb(206, 79, 79); transform-origin: 213.275px 101.385px;" id="eli6lrpba2gbm"
						  class="animable"></path>
				</g>
			</g>
			<g id="freepik--Lines--inject-5" class="animable" style="transform-origin: 412.012px 120.096px;">
				<path id="freepik--lines--inject-5"
					  d="M417.4,92.3c.35-1.14-1.41-1.19-2-.91-2.21,1-3.95,3.59-5.54,5.35q-3,3.36-6.13,6.68c-3.77,4.06-7.68,8-11.53,12A1.42,1.42,0,0,0,392,117a1.9,1.9,0,0,0,1.12,1,2,2,0,0,0,2.22-.35c3.91-4.13,7.68-8.38,11.49-12.6q2.82-3.12,5.6-6.27C414.13,96.91,416.64,94.74,417.4,92.3Zm6.37,30.43c2.45-.6,5.74-1,7.75-2.53.94-.73-.48-1.77-1.15-1.9-2.37-.46-5.29.69-7.6,1.23-2.95.68-5.89,1.35-8.84,2-5.42,1.18-10.88,2.19-16.31,3.26a1.41,1.41,0,0,0-1,1.27,1.89,1.89,0,0,0,.36,1.44,2,2,0,0,0,2,1c5.57-1.16,11.09-2.5,16.63-3.79C418.32,124.05,421.05,123.39,423.77,122.73ZM399.13,140c5.42,1.74,10.88,3.3,16.34,4.91,2.68.8,5.37,1.57,8.07,2.34,2.42.68,5.46,2,8,1.63,1.18-.18.46-1.78-.06-2.23-1.83-1.57-5-2-7.22-2.68-2.9-.86-5.79-1.73-8.68-2.63-5.29-1.64-10.54-3.46-15.79-5.21a1.43,1.43,0,0,0-1.54.6,1.92,1.92,0,0,0-.39,1.43A2,2,0,0,0,399.13,140Z"
					  style="fill: rgb(224, 224, 224); transform-origin: 412.012px 120.096px;" class="animable"></path>
			</g>
			<defs>
				<filter id="active" height="200%">
					<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
					<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
					<feMerge>
						<feMergeNode in="OUTLINE"></feMergeNode>
						<feMergeNode in="SourceGraphic"></feMergeNode>
					</feMerge>
				</filter>
				<filter id="hover" height="200%">
					<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
					<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
					<feMerge>
						<feMergeNode in="OUTLINE"></feMergeNode>
						<feMergeNode in="SourceGraphic"></feMergeNode>
					</feMerge>
					<feColorMatrix type="matrix"
								   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
				</filter>
			</defs>
		</svg>
	</div>
</template>

<script lang="ts">

import useSystem from "@/composable/useSystem";

export default {
	name: "InvalidAnimation",
	components: {},
	props: {
		maxHeight: {
			type: String,
			default: '25em',
		},
	},
	setup(props) {
		const {isNativePlatform} = useSystem();

		function getAnimationStyle(): any
		{
			return {
				'max-height': props.maxHeight
			}
		}

		return {getAnimationStyle, isNativePlatform}
	}
}
</script>

<style scoped>
svg#freepik_stories-oh-no:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-oh-no.animated #freepik--Plant--inject-5 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-oh-no.animated #freepik--Shadow--inject-5 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-oh-no.animated #freepik--Character--inject-5 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-oh-no.animated #freepik--speech-bubble-1--inject-5 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-oh-no.animated #freepik--Lines--inject-5 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(-2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.center-animation {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>