import {
    MobiPointsTrackingWeatherWeatherCoordinatesInterface,
    MobiPointsTrackingWeatherWeatherInterface,
    MobiPointsTrackingWeatherWeatherTemperatureInterface, MobiPointsTrackingWeatherWeatherWindInterface,
    MobiPointsWeatherType
} from "@/mobipoints/tracking/weather/weather.interface";
import * as icons from "ionicons/icons";

export class MobiPointsTrackingWeatherWeather implements MobiPointsTrackingWeatherWeatherInterface {
    private _weatherDataUuid :string|null = null;
    private _weatherType :MobiPointsWeatherType = MobiPointsWeatherType.WEATHER_TYPE_SUNNY;
    private _weatherDescription = '';
    private _cityName = '';
    private _country = '';
    private _clouds: number | null = null;
    private _wind: MobiPointsTrackingWeatherWeatherWindInterface | null = null;
    private _sunrise: number | null = null;
    private _sunset: number | null = null;
    private _timestamp;
    private _timezone;
    private _coordinates: MobiPointsTrackingWeatherWeatherCoordinatesInterface | null = null;
    private _temperature: MobiPointsTrackingWeatherWeatherTemperatureInterface | null = null;


    constructor(weatherType: MobiPointsWeatherType, timestamp: number, timezone: number, weatherDataUuid : string|null = null, weatherDescription = '', coordinates: MobiPointsTrackingWeatherWeatherCoordinatesInterface | null = null, temperature: MobiPointsTrackingWeatherWeatherTemperatureInterface | null = null, sunrise: number | null = null, sunset: number | null = null, wind: MobiPointsTrackingWeatherWeatherWindInterface | null = null, clouds: number | null = null, cityName = '', country = '') {
        this.weatherDataUuid = weatherDataUuid;
        this.weatherType = weatherType;
        this.weatherDescription = weatherDescription;
        this.coordinates = coordinates;
        this.temperature = temperature;
        this.cityName = cityName;
        this.country = country;
        this.wind = wind;
        this.clouds = clouds;
        this.sunrise = sunrise;
        this.sunset = sunset;
        this.timestamp = timestamp;
        this.timezone = timezone;
    }

    get weatherDataUuid(): string|null {
        return this._weatherDataUuid;
    }

    set weatherDataUuid(value: string|null) {
        this._weatherDataUuid = value;
    }

    get weatherType(): MobiPointsWeatherType {
        return this._weatherType;
    }

    set weatherType(value: MobiPointsWeatherType) {
        this._weatherType = value;
    }

    get weatherDescription(): string {
        return this._weatherDescription;
    }

    set weatherDescription(value: string) {
        this._weatherDescription = value;
    }

    get cityName(): string {
        return this._cityName;
    }

    set cityName(value: string) {
        this._cityName = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get clouds(): number | null {
        return this._clouds;
    }

    set clouds(value: number | null) {
        this._clouds = value;
    }

    get wind(): MobiPointsTrackingWeatherWeatherWindInterface | null {
        return this._wind;
    }

    set wind(value: MobiPointsTrackingWeatherWeatherWindInterface | null) {
        this._wind = value;
    }

    get sunrise(): number | null {
        return this._sunrise;
    }

    set sunrise(value: number | null) {
        this._sunrise = value;
    }

    get sunset(): number | null {
        return this._sunset;
    }

    set sunset(value: number | null) {
        this._sunset = value;
    }

    get timestamp(): number {
        return this._timestamp;
    }

    set timestamp(value: number) {
        this._timestamp = value;
    }

    get timezone(): number {
        return this._timezone;
    }

    set timezone(value: number) {
        this._timezone = value;
    }

    get coordinates(): MobiPointsTrackingWeatherWeatherCoordinatesInterface | null {
        return this._coordinates;
    }

    set coordinates(value: MobiPointsTrackingWeatherWeatherCoordinatesInterface | null) {
        this._coordinates = value;
    }

    get temperature(): MobiPointsTrackingWeatherWeatherTemperatureInterface | null {
        return this._temperature;
    }

    set temperature(value: MobiPointsTrackingWeatherWeatherTemperatureInterface | null) {
        this._temperature = value;
    }

    getWeatherIcon(): string
    {
        switch (this.weatherType) {
            case MobiPointsWeatherType.WEATHER_TYPE_RAIN:
            case MobiPointsWeatherType.WEATHER_TYPE_LIGHT_RAIN:
            case MobiPointsWeatherType.WEATHER_TYPE_HEAVY_RAIN:
                return icons.rainy;
            case MobiPointsWeatherType.WEATHER_TYPE_SNOW:
            case MobiPointsWeatherType.WEATHER_TYPE_LIGHT_SNOW:
            case MobiPointsWeatherType.WEATHER_TYPE_HEAVY_SNOW:
                return icons.snow;
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS:
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS_FEW:
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS_OVERCAST:
                return icons.cloudy;
            case MobiPointsWeatherType.WEATHER_TYPE_THUNDERSTORM:
                return icons.thunderstorm;
            case MobiPointsWeatherType.WEATHER_TYPE_SUNNY:
            default:
                return icons.sunny;
        }
    }

    getWeatherIconColor(): string
    {
        //TODO add snowy, thunder, mixed
        switch (this.weatherType) {
            case MobiPointsWeatherType.WEATHER_TYPE_RAIN:
            case MobiPointsWeatherType.WEATHER_TYPE_LIGHT_RAIN:
            case MobiPointsWeatherType.WEATHER_TYPE_HEAVY_RAIN:
                return '#0960b6';
            case MobiPointsWeatherType.WEATHER_TYPE_SNOW:
            case MobiPointsWeatherType.WEATHER_TYPE_LIGHT_SNOW:
            case MobiPointsWeatherType.WEATHER_TYPE_HEAVY_SNOW:
                return '#33a5f6';
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS:
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS_FEW:
            case MobiPointsWeatherType.WEATHER_TYPE_CLOUDS_OVERCAST:
                return '#a8a8a8';
            case MobiPointsWeatherType.WEATHER_TYPE_THUNDERSTORM:
                return '#fced45';
            case MobiPointsWeatherType.WEATHER_TYPE_SUNNY:
            default:
                return '#DDA500';
        }
    }

    normalizeData()
    {
        return {
            'weatherDataUuid': this._weatherDataUuid,
            'weatherType': this._weatherType,
            'weatherDescription': this._weatherDescription,
            'cityName': this._cityName,
            'country': this._country,
            'clouds': this._clouds,
            'wind': this._wind,
            'sunrise': this._sunrise,
            'sunset': this._sunset,
            'timestamp': this._timestamp,
            'timezone': this._timezone,
            'coordinates': this._coordinates,
            'temperature': this._temperature,
        }
    }
}
