<template>
	<ion-page>
		<ion-header translucent>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button :text="backButtonText" defaultHref="/profile/userLocation"></ion-back-button>
				</ion-buttons>
				<ion-title>{{addUserLocationTitle}}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content fullscreen>
			<div style="padding-top: 10px">
				<form autocomplete="off">
					<div class="ion-padding">
						<ion-label position="stacked">Typ</ion-label>
						<ion-input :value="userAddressData.type.name" disabled autocomplete="off"></ion-input>
						<div class="ion-align-items-center ion-justify-content-start location-type-selection">
							<ion-button v-for="locationType in locationTypes" :key="locationType.code"
										:value="locationType.code"
										:color="locationType.code === userAddressData.type.code ? 'primary' : 'medium'"
										@click="locationTypeChanged">
								<ion-icon :icon="locationType.icon" slot="icon-only" :title="locationType.name"
										  style="padding: .5em"></ion-icon>
								<ion-ripple-effect/>
							</ion-button>
						</div>
					</div>

					<div>
						<vue-google-autocomplete id="searchUserLocation" autocomplete="off"
												 classname="form-control"
												 :placeholder="searchInputPlaceholder" enable-geolocation="true"
												 v-on:placechanged="getAddressData"
						/>
					</div>
				</form>

				<div style="height: 300px; width: 100%">
					<!--				<default-map :init-cords="coords" height="100%" width="100%"/>-->
					<disabled-map :init-cords="coords" height="100%" width="100%" update-address-on-click="true" show-map-type-control="true"
								  @onMarkerUpdated="updateAddressData"></disabled-map>
				</div>
				<!--			<google-maps-search-input></google-maps-search-input>-->
				<ion-list class="ion-no-margin" lines="full">
					<ion-item-divider>
						<ion-label>
							{{addressOverviewTitle}}
							<ion-chip>
								<ion-icon :icon="icons.pin" color="primary"></ion-icon>
								<ion-label class="ion-text-wrap address-chip-text">{{userAddressData.street}} {{userAddressData.streetNumber}}, {{userAddressData.city}} {{userAddressData.postalCode}} <span v-if="userAddressData.province && userAddressData.country.code === 'IT'">{{userAddressData.province}}</span>, {{userAddressData.country.code}}</ion-label>
							</ion-chip>
							<div class="ion-padding-bottom">
								<p>{{userAddressData.street}} {{userAddressData.streetNumber}}</p>
								<p>{{userAddressData.city}} {{userAddressData.postalCode}}</p>
								<p v-if="userAddressData.province && userAddressData.country.code === 'IT'">{{userAddressData.province}}</p>
								<p>{{userAddressData.country.name}}</p>
								<p>Lat: {{userAddressData.latitude}}, Lng: {{userAddressData.longitude}}</p>
							</div>
						</ion-label>
					</ion-item-divider>
				</ion-list>
			</div>
		</ion-content>
		<ion-footer>
			<ion-toolbar>
				<ion-button slot="start" color="medium" class="ion-margin-start" @click="cancelNewUserLocation">
					<ion-icon :icon="icons.close"></ion-icon>
					<ion-label style="margin-left: .25rem;">{{cancelButtonText}}</ion-label>
					<ion-ripple-effect></ion-ripple-effect>
				</ion-button>
				<ion-button slot="end" class="ion-margin-end" @click="createNewUserLocation">
					<ion-icon :icon="icons.save"></ion-icon>
					<ion-label style="margin-left: .5rem;">{{saveButtonText}}</ion-label>
					<ion-ripple-effect></ion-ripple-effect>
				</ion-button>
			</ion-toolbar>
		</ion-footer>

	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonChip,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonInput,
	IonItemDivider,
	IonLabel,
	IonList,
	IonPage,
	IonRippleEffect,
	IonTitle,
	IonToolbar,
} from '@ionic/vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import DisabledMap from '@/components/map/DisabledMap.vue';
import {AddressInterface, useCoordinate} from '@/composable/useCoordinate';
import router from '@/router';
import {MobiPointApiProfile} from '@/mobipoints/api/profile';
import {UserLocation} from '@/mobipoints/profile/userLocation/user_location.type';
import {ProfileFactory} from '@/mobipoints/profile/profile_factory';
import useToastMessage from '@/components/core/ToastMessage.vue';
import useMainData from '@/composable/useMainData';
import store from '@/store';
import * as icons from 'ionicons/icons';
import {
	MobiPointsLocationCountryInterface,
	MobiPointsLocationTypeInterface,
	MobiPointsLocationTypes,
} from '@/mobipoints/location/location_type.interface';
import useData from '@/composable/useData';
import {StringHelper} from '@/mobipoints/core/string_helper';

export default defineComponent({
	name: 'AddUserLocationList',
	components: {
		// DefaultMap,
		DisabledMap,
		// GoogleMapsSearchInput,
		IonList,
		IonLabel,
		IonContent,
		IonHeader,
		IonToolbar,
		IonTitle,
		IonInput,
		VueGoogleAutocomplete,
		IonPage,
		IonButton,
		IonButtons,
		IonBackButton,
		IonRippleEffect,
		IonFooter,
		IonIcon,
	  	IonChip,
	  IonItemDivider,
	},
	props: {},
	setup() {
		const userAddressData = ref<UserLocation>((new ProfileFactory()).createEmptyProfileUserLocation());
		const coords = ref({lat: 0, lng: 0});
		const {getCurrentPosition} = useCoordinate();
		const {openToast} = useToastMessage();
		const {getTextValueByKey} = useData();

		const addUserLocationTitle = ref(getTextValueByKey('add.user.location.title', [], 'Neue Adresse'));
		const cancelButtonText = ref(getTextValueByKey('add.user.location.cancel.btn', [], 'Abbrechen'));
		const saveButtonText = ref(getTextValueByKey('add.user.location.save.btn', [], 'Speichern'));
		const searchLabelText = ref(getTextValueByKey('add.user.location.search.label', [], 'Gib deine Adresse ein'));
		const searchInputPlaceholder = ref(getTextValueByKey('add.user.location.search.placeholder', [], '📍 Gib deine Adresse ein'));
		const addressOverviewTitle = ref(getTextValueByKey('add.user.location.overview.title', [], 'Adresse'));
		const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

		const mainData = useMainData();

		const locationTypes = ref<MobiPointsLocationTypeInterface[]>(mainData.locationTypesSelectable.value);

		onMounted(() => {
			getCurrentPosition().then(function (currentPosition) {
				coords.value.lat = currentPosition.latitude;
				coords.value.lng = currentPosition.longitude;
			})
		})

		function cancelNewUserLocation() {
			userAddressData.value = new ProfileFactory().createEmptyProfileUserLocation();
			router.back();
		}

		function validateUserAddressData() {
			userAddressData.value.latitude = coords.value.lat
			userAddressData.value.longitude = coords.value.lng
			delete userAddressData.value.name;
		}

		function createNewUserLocation() {
			validateUserAddressData();
			MobiPointApiProfile.createUserLocation(userAddressData.value).then((response) => {
				if (response) {
					store.commit('mainData/resetUserLocations');
					mainData.initUserLocations();
					openToast("Der neue Standort wurde erfolgreich gespeichert!", "success", "top", true, 2500, undefined, true);
					userAddressData.value = new ProfileFactory().createEmptyProfileUserLocation();
					router.back();
				}
			}).catch(err => {
				openToast("Leider ist ein Fehler aufgetreten! Bitte nocheinmal probieren :)", "danger", "top", true, 5000, undefined, true)
				console.log(err)
			});
		}

		function updateAddressData(addressData: AddressInterface) {
			userAddressData.value.street = addressData.street;
			userAddressData.value.streetNumber = addressData.streetNumber;
			userAddressData.value.postalCode = addressData.postalCode;
			userAddressData.value.country = {code: addressData.country, name: addressData.countryName};
			userAddressData.value.province = addressData.adminArea2 || addressData.adminArea1 || '';
			userAddressData.value.city = addressData.city;
			userAddressData.value.latitude = coords.value.lat;
			userAddressData.value.longitude = coords.value.lng;
		}


		function getCountryCodeFromPlaceResult(result: any): MobiPointsLocationCountryInterface | null {
			const country = (result.address_components as Array<any>).find((addressComponent) => {
				return !!(addressComponent.types as Array<string>).find((type) => 'country' === type);
			});

			return country && {code: country.short_name, name: country.long_name} || null;
		}


		function getPostalTownFromPlaceResult(result: any): string | undefined {
			const postalTownResult: any = (result.address_components as Array<any>).find((addressComponent) => {
					return !!(addressComponent.types as Array<string>).find((type) => 'postal_town' === type);
				});

		  	if (!postalTownResult) {
				return;
			}

			let postalTownObj: any = postalTownResult;

			if (typeof postalTownResult === 'string') {
			  if (!StringHelper.isJSON(postalTownResult)) {
				return
			  }
			  postalTownObj = JSON.parse(postalTownResult);
			}

			if (postalTownObj && postalTownObj.long_name) {
				return postalTownObj.long_name;
			}  else if (postalTownObj && postalTownObj.short_name) {
				return postalTownObj.short_name;
			}
		}

		function getAddressData(addressData, placeResultData, id) {
			coords.value.lat = addressData.latitude;
			coords.value.lng = addressData.longitude;

			userAddressData.value.street = addressData.route;
			userAddressData.value.streetNumber = addressData.street_number;
			userAddressData.value.postalCode = addressData.postal_code;
			userAddressData.value.country = getCountryCodeFromPlaceResult(placeResultData) || addressData.country;

			let city = addressData.locality ?? undefined;
			if (!city) {
				city = getPostalTownFromPlaceResult(placeResultData) || addressData.administrative_area_level_1;
			}
			addressData.city = city;
			userAddressData.value.city = city;
			userAddressData.value.province = addressData.administrative_area_level_1;
			userAddressData.value.latitude = coords.value.lat;
			userAddressData.value.longitude = coords.value.lng;
		}

		function locationTypeChanged(e: any) {
			const newType: MobiPointsLocationTypes = (e.target as typeof IonButton).getAttribute('value');
			userAddressData.value.type = mainData.getLocationTypeByCode(newType) as MobiPointsLocationTypeInterface;
		}

		return {
			getAddressData,
			coords,
			userAddressData,
			updateAddressData,
			cancelNewUserLocation,
			createNewUserLocation,
			icons,
			locationTypes,
			locationTypeChanged,
			addUserLocationTitle,
			cancelButtonText,
			saveButtonText,
			searchLabelText,
			searchInputPlaceholder,
			addressOverviewTitle,
			backButtonText
		}
	}
})
</script>

<style scoped>
#searchUserLocation:focus {
    border-color: var(--ion-color-primary);
    box-shadow: 0 0 8px 0 var(--ion-color-primary);
}

#searchUserLocation {
    width: 100%;
    padding: .75em;
    background-color: rgba(var(--ion-color-primary-rgb), .9);
    /*background-color: rgba(var(--ion-text-color-rgb), .9);*/
    /*color: var(--ion-text-color);*/
	color: var(--ion-color-white);
    border: 2px solid transparent;
    box-sizing: border-box;
    outline: 0;
    border-radius: 10px;
    appearance: none;
    transition: all var(--search-dur) var(--search-bez);
    transition-property: width, border-radius;
    z-index: 1;
    position: relative;
	text-align: left;
    box-shadow: 0px 0.15em 0.5em 1px grey;
}

#searchUserLocation::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--ion-color-white);
    opacity: .85;
}
#searchUserLocation::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--ion-color-white);
    opacity: .85;
}
#searchUserLocation::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--ion-color-white);
    opacity: .85;
}

.address-chip-text {
	font-size: 10px;
}

.location-type-selection {
    display: flex;
    width: 100%;
}

.location-type-selection ion-button {
    height: 3em;
    margin-right: .25em;
}

.location-type-selection ion-button:last-child {
    margin-right: 0;
}
</style>