import {nanoid} from 'nanoid';
import {MobiPointsTrackingTrackTrackInterface} from "@/mobipoints/tracking/track/track.interface";
import {MobiPointsTrackingCoordinateCoordinateList} from "@/mobipoints/tracking/coordinate/coordinate_list.type";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import {MobiPointsQueueEventFactory} from "@/mobipoints/queue/event/event_factory.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import {MobiPointsTrackingWeatherWeatherList} from "@/mobipoints/tracking/weather/weather_list.type";

export const TRACK_TYPE_BICYCLE = "by_bicycle";
export const TRACK_TYPE_BUS = "by_bus";
export const TRACK_TYPE_CARPOOL = "by_carpool";
export const TRACK_TYPE_E_BIKE = "by_e_bike";
export const TRACK_TYPE_E_CAR = "by_e_car";
export const TRACK_TYPE_CAR = "by_car";
export const TRACK_TYPE_E_MOTORCYCLE = "by_e_motorcycle";
export const TRACK_TYPE_E_SCOOTER = "by_e_scooter";
export const TRACK_TYPE_FOOT = "by_foot";
export const TRACK_TYPE_SUBWAY = "by_subway";
export const TRACK_TYPE_TRAIN = "by_train";
export const TRACK_TYPE_TRAM = "by_tram";

export const TRACK_STATE_NEW = "new";
export const TRACK_STATE_DONE = "done";
export const TRACK_STATE_PAUSE = "pause";
export const TRACK_STATE_INVALID = "invalid";
export const TRACK_STATE_NULLIFY = "nullify";
export const TRACK_STATE_VERIFIED = "verified";

export abstract class MobiPointsTrackingTrackAbstractTrack implements MobiPointsTrackingTrackTrackInterface {
	private eventFactory: MobiPointsQueueEventFactory;

	id: string;
	uuid: string;
	name: string;
	type: string;
	subType: string;
	state: string;
	coordinates: MobiPointsTrackingCoordinateCoordinateList;
	payload: PayloadInterface;
	timestamp: number;
	endTimestamp?: number;
	active: boolean;
	private _weatherData: MobiPointsTrackingWeatherWeatherList|null = null;

	protected constructor(uuid: string, trackName: string, timestamp: number, state: string = TRACK_STATE_NEW, payload: PayloadInterface = {data: {}}, endTimestamp?: number) {
		this.id = nanoid();
		this.uuid = uuid;
		this.name = trackName;
		this.type = this.getTrackType();
		this.subType = this.getTrackSubType();
		this.state = state;
		this.coordinates = new MobiPointsTrackingCoordinateCoordinateList();
		this.payload = payload;
		this.timestamp = timestamp;
		this.active = true;
		this.eventFactory = new MobiPointsQueueEventFactory();
		this.endTimestamp = endTimestamp;
	}

	abstract getTrackType(): string;

	abstract getTrackSubType(): string;

	getEventFactory(): MobiPointsQueueEventFactory {
		return this.eventFactory;
	}

	getCurrentTimeStampInSeconds(): number
	{
		return Math.floor(Date.now() / 1000)
	}

	addCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinate) {
		this.getCoordinates().add(coordinate);
	}

	setCoordinates(coordinates: MobiPointsTrackingCoordinateCoordinateList) {
		this.coordinates = coordinates;
	}

	getCoordinates(): MobiPointsTrackingCoordinateCoordinateList {
		return this.coordinates;
	}

	get weatherData(): MobiPointsTrackingWeatherWeatherList | null {
		return this._weatherData;
	}

	set weatherData(value: MobiPointsTrackingWeatherWeatherList | null) {
		this._weatherData = value;
	}
}
