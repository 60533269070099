<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button defaultHref="/profile" :text="backButtonText"></ion-back-button>
				</ion-buttons>
				<ion-title>Meine Adressen</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-fab vertical="bottom" horizontal="end" slot="fixed" size="large">
				<ion-fab-button class="ion-margin addUserLocationFabButton" router-link="/profile/userLocation/add" router-direction="back" >
					<ion-icon size="large" :icon="add"></ion-icon>
				</ion-fab-button>
			</ion-fab>

			<user-location-list></user-location-list>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonFab, IonFabButton, IonIcon, IonButtons} from '@ionic/vue';
import UserLocationList from "@/components/profile/userLocation/UserLocationList.vue";
import {
	add,
} from 'ionicons/icons';
import {ref} from "vue";
import useData from "@/composable/useData";

export default {
	name: 'UserLocation',
	components: {UserLocationList, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonFab, IonFabButton, IonIcon, IonButtons},
	setup() {
		const {getTextValueByKey} = useData();
		const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

		return {add, backButtonText}
	}
}
</script>

<style>
ion-fab-button {
    width: 80px;
    height: 80px;
}

.addUserLocationFabButton {
}
</style>