import {MobiPointsDataInterface} from "@/mobipoints/data/data/data.interface";
import {MobiPointsDataItemInterface} from "@/mobipoints/data/data/data_item.interface";

export const DATA_TYPE_TEXT = 'text';
export const DATA_TYPE_SYSTEM_SETTINGS = 'system_settings';

export class Data implements MobiPointsDataInterface {
	private _type = DATA_TYPE_TEXT;
	private _items: Array<MobiPointsDataItemInterface> = [];

	constructor(type: string = DATA_TYPE_TEXT, dataItems: Array<MobiPointsDataItemInterface>) {
		this.type = type;
		this.items = dataItems;
	}

	get type(): string {
		return this._type;
	}

	set type(value: any) {
		this._type = value;
	}

	get items(): MobiPointsDataItemInterface[] {
		return this._items;
	}

	set items(dataItems: Array<MobiPointsDataItemInterface>) {
		this._items = dataItems;
	}
}