import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!

  return (_openBlock(), _createBlock(_component_LottieAnimation, {
    animationData: $setup.animationData,
    loop: $props.loop,
    autoplay: $props.autoplay,
    fullscreen: $props.fullscreen,
    width: $props.width,
    height: $props.height,
    "start-time": $props.startTime,
    speed: "0.5",
    "z-index": 9999,
    "sound-src": $setup.animationSound
  }, null, 8, ["animationData", "loop", "autoplay", "fullscreen", "width", "height", "start-time", "sound-src"]))
}