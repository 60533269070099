import {MobiPointsChallengeUserChallengeStateInterface} from "@/mobipoints/challenge/userChallenge/user_challenge_state.interface";

export const USER_CHALLENGE_STATE_NULLIFY = 'nullify';
export const USER_CHALLENGE_STATE_ACCEPTED = 'accepted';
export const USER_CHALLENGE_STATE_DECLINED = 'declined';
export const USER_CHALLENGE_STATE_CANCELLED = 'cancelled';
export const USER_CHALLENGE_STATE_FINISHED = 'finished';

export interface MobiPointsChallengeUserChallengeDataInterface {
	[key: string]: any;
}

export interface MobiPointsChallengeUserChallengeInterface {
	progress: number;
	data: MobiPointsChallengeUserChallengeDataInterface | null;
	user_id: number;
	challenge_uuid: string;
	state: MobiPointsChallengeUserChallengeStateInterface;
}