import {MobiPointsQueueQueue} from "@/mobipoints/queue/queue.type";
import {computed, ComputedRef} from "vue";
import store from "@/store";

export default function useQueue() {
	const queueInterval: ComputedRef<null|number> = computed(() => store.getters['queue/queueInterval']);
	const isSending: ComputedRef<boolean> = computed(() => store.getters['queue/isSending']);

	let mobiPointsQueue: MobiPointsQueueQueue | null = null;
	const getQueue = (): MobiPointsQueueQueue => {
		if (!mobiPointsQueue) {
			mobiPointsQueue = new MobiPointsQueueQueue();
		}
		return mobiPointsQueue;
	}

	function setIsSending(value: boolean) {
		store.commit('queue/setIsSending', value)
	}

	function setQueueInterval(queueInterval: number|null) {
		store.commit('queue/setQueueInterval', queueInterval)
	}

	return {
		queueInterval,
		getQueue,
		isSending,
		setIsSending,
		setQueueInterval
	}
}
