import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {
	MobiPointsLocationCountryInterface,
	MobiPointsLocationTypeInterface
} from "@/mobipoints/location/location_type.interface";

export class UserLocation implements MobiPointsProfileUserLocationInterface {
	id: number | null;
	city: string;
	country: MobiPointsLocationCountryInterface
	latitude: number;
	longitude: number;
	name?: string;
	postalCode: string;
	province?: string;
	street: string;
	streetNumber: string;
	tags?: any[];
	state?: string;
	type: MobiPointsLocationTypeInterface;
	isEditable: boolean;

	/**
	 *
	 * @param id
	 * @param type
	 * @param name
	 * @param latitude
	 * @param longitude
	 * @param country
	 * @param city
	 * @param street
	 * @param streetNumber
	 * @param postalCode
	 * @param province
	 * @param tags
	 */
	constructor(id: number|null = null, type: MobiPointsLocationTypeInterface, name: string, latitude: number, longitude: number, country: MobiPointsLocationCountryInterface, city: string, street: string, streetNumber: string, postalCode: string, province = "", tags: any = []) {
		this.id = id;
		this.type = type;
		this.name = name;
		this.latitude = latitude;
		this.longitude = longitude;
		this.country = country;
		this.city = city;
		this.street = street;
		this.streetNumber = streetNumber;
		this.postalCode = postalCode;
		this.province = province;
		this.tags = tags;
		this.isEditable = false;
	}

	getFormattedAddressList(): string[] {
		const result: any[] = [];
		if (this.street) {
			if (this.streetNumber) {
				const formattedStreet = this.street + " " + this.streetNumber;
				result.push(formattedStreet);
			} else {
				result.push(this.street);
			}
		}
		if (this.city) {
			if (this.postalCode) {
				const formattedCity = this.city + " " + this.postalCode;
				result.push(formattedCity);
			} else {
				result.push(this.city);
			}
		}
		if (this.country) {
			result.push(this.country.name);
		}

		return result;
	}

	getFormattedAddress(separator = ', '): string {
		return this.getFormattedAddressList().join(separator);
	}
}