import {MobiPointsApiAuth} from "@/mobipoints/api/auth";
import router from "@/router";
import useToastMessage from "@/components/core/ToastMessage.vue";
import {MobiPointsQueueQueue} from "@/mobipoints/queue/queue.type";
import useMainData from "@/composable/useMainData";
import useAuth from "@/composable/useAuth";
import store from "@/store";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import usePoints from "@/composable/usePoints";
import useChallenge from "@/composable/useChallenge";
import {actionSheetController, loadingController} from '@ionic/vue';
import useTracking from "@/composable/useTracking";
import * as icons from 'ionicons/icons';
import useUserProfile from "@/composable/useUserProfile";

export class UserAuth {

	private queue:MobiPointsQueueQueue;

	constructor() {
		this.queue = new MobiPointsQueueQueue();
	}

	public async loginPostHook() {
		useUserProfile().checkToggleMode();
	}

	public async login(loginData: any) {
		const result = await MobiPointsApiAuth.signIn(loginData);
		if (useAuth().isAuthenticated.value) {
			await this.queue.start();
			await this.loadData();
			await this.loginPostHook();
		}

		return result;
	}

	public async loginBasic(loginData: any = {email: '', password: ''}) {
		const result = await MobiPointsApiAuth.loginUser(loginData);
		if (useAuth().isAuthenticated.value) {
			await this.queue.start();
			await this.loadData();
			await this.loginPostHook();
		}

		return result;
	}

	protected async loadData() {
		try {
			await useMainData().initMainData(false);
			await useData().initData();
			await useChallenge().initChallenges();
			await usePoints().initPoints(true);
			await useUserProfile().initUserProfile(true);
		} catch (error) {
			console.log(JSON.stringify(error));
		}
	}

	public async registerBasic(registerData: any = {name: '', email: '', password: ''}) {
		return await MobiPointsApiAuth.registerUser(registerData);
	}

	public async forgotPassword(forgotPasswordData: any = {email: ''}) {
		return await MobiPointsApiAuth.forgotPassword(forgotPasswordData);
	}

	public async resetAndVerifyNewPassword(uuid: string, newPasswordData: any = {hash: '', password: ''}) {
		return await MobiPointsApiAuth.resetAndVerifyNewPassword(uuid, newPasswordData);
	}

	public async resendResetNewPasswordLink(uuid: string) {
		return await MobiPointsApiAuth.resendNewPasswordLink(uuid);
	}

	public async verifyAccount(uuid: string, hash: string) {
		return await MobiPointsApiAuth.verifyAccount(uuid, hash);
	}

	public async resendVerifyLink(uuid: string) {
		return await MobiPointsApiAuth.resendVerifyLink(uuid);
	}

	protected async openActiveTrackingDialog(): Promise<boolean | null> {
		let modalResult: boolean|null = null;
		const actionSheet = await actionSheetController
			.create({
				header: useData().getTextValueByKey('logout.check_active_tracking.title', [], 'Du hast noch ein aktives Tracking!'),
				cssClass: 'my-custom-class',
				buttons: [
					{
						text: useData().getTextValueByKey('logout.check_active_tracking.title', [], 'Tracking stoppen'),
						role: 'destructive',
						icon: icons.stopOutline,
						cssClass: 'delete',
						handler: () => {
							modalResult = true;
						},
					},
					{
						text: useData().getTextValueByKey('logout.check_active_tracking.title', [], 'Tracking weiterlaufen lassen und abmelden'),
						icon: icons.navigateOutline,
						handler: () => {
							modalResult = false;
						},
					},
					{
						text: useData().getTextValueByKey('logout.check_active_tracking.title', [], 'Abbrechen'),
						role: 'destructive',
						icon: icons.close,
						handler: () => {
							modalResult = null;
						},
					},
				],
			});
		await actionSheet.present();
		await actionSheet.onDidDismiss();
		return modalResult;
	}

	public async logout(redirect = true, showToastMessage = true) {
		let result = false;
		try {
			let allowedToLogout: null|boolean = null;
			try {
				if (useTracking().hasActiveTracking.value) {
					allowedToLogout = await this.openActiveTrackingDialog();
					if (allowedToLogout === null) {
						return false;
					}
				}
			} catch(e) {
				console.error(JSON.stringify(e));
			}


			const loading = await loadingController
			.create({
				cssClass: '',
				message: useData().getTextValueByKey('logout.loading.text', [], 'Du wirst abgemeldet...'),
				duration: 60000,
			});

			setTimeout(function () {
				loading.dismiss()
			}, 60000);
			loading.present().then(async () => {
				if (allowedToLogout) {
					try {
						await useTracking().stopCurrentTracking();
					} catch (e) {
						console.error(JSON.stringify(e));
					}
				}
				if (this.queue.getEvents().length > 0) {
					try {
						this.queue.sendEvents().then( () => {
							result = this.logoutAndClear(redirect, showToastMessage);
						});
					} catch (e) {
						useSystem().addLog(JSON.stringify(e))
						result = this.logoutAndClear(redirect, showToastMessage);
					}
				} else {
					result = this.logoutAndClear(redirect, showToastMessage);
				}
				await loading.dismiss();
			});
		} catch(error) {
			console.error(JSON.stringify(error));
			result = this.logoutAndClear(redirect, showToastMessage);
		}

		return result;
	}

	protected logoutAndClear(redirect = true, showToastMessage = true) {
		try {
			try {
				MobiPointsApiAuth.logout();
			} catch(e) {
				console.error('Cannot logout from system!');
			}
			this.queue.destroy();

			store.reset();

			if (redirect) {
				router.push('/login');
			}
			if (showToastMessage) {
				useToastMessage().openToast(useData().getTextValueByKey('logout.success.title', [], 'Du wurdest abgemeldet, bis zum nächsten Mal 👋'), 'success', 'bottom', true, 5000, undefined, true);
			}

			useSystem().initVersion();
		} catch(e) {
			console.error(JSON.stringify(e));
			return false;
		}
		return true;
	}
}
