import useData from '@/composable/useData';
import {
	WalletItemCorrection
} from '@/mobipoints/wallet/item/correction/wallet_item_correction.type';

export class TreasureWalletItemCorrection extends WalletItemCorrection{
	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.correction.name', [], 'Korrektur');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount: string;
		if (!this.amount) {
			amount =  '0';
		} else {
			amount = this.amount?.toFixed(0);
		}
		if (withSuffix) {
			if (this.amount == 1) {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.correction.amount.suffix', [], 'Los korrigiert');
			} else {
				amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.treasure.correction.amount.suffix', [], 'Lose korrigiert');
			}
		}
		return amount;
	}
}