import {
	MobiPointsQueueEventAbstractEvent,
	EVENT_TYPE_START_TRACKING
} from "@/mobipoints/queue/event/abstract_event.type";

export class MobiPointsQueueEventTypeStartTrackingEvent extends MobiPointsQueueEventAbstractEvent
{

	getEventType(): string {
		return EVENT_TYPE_START_TRACKING;
	}

}