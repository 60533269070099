import {MobiPointsQueueEventFactory} from "@/mobipoints/queue/event/event_factory.type";

export default function useEventFactory() {

	let eventFactory: MobiPointsQueueEventFactory | null = null;
	const getEventFactory = (): MobiPointsQueueEventFactory => {
		if (!eventFactory) {
			eventFactory = new MobiPointsQueueEventFactory();
		}
		return eventFactory;
	}

	return {
		getEventFactory,
	}
}