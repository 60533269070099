import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import {MobiPointsGeoCoding} from "@/mobipoints/geocoding/geocoding.type";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";

export class MobiPointsGeoCodingFactory {

	getNearestLocationByCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, maxDistanceInMeter = 250): MobiPointsGeoCodingAddressInterface|null
	{
		const geoCoding = this.createGeoCoding();
		return geoCoding.getNearestLocationByCoordinate(coordinate, maxDistanceInMeter);
	}

	isCoordinateCloseToAddress(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, locationTypes: Array<MobiPointsLocationTypes> = [MobiPointsLocationTypes.HOME_LOCATION, MobiPointsLocationTypes.USER_LOCATION, MobiPointsLocationTypes.COMPANY_LOCATION], maxDistanceInMeter = 250): boolean
	{
		const geoCoding = this.createGeoCoding();
		return geoCoding.isCoordinateCloseToLocations(coordinate, locationTypes, maxDistanceInMeter);
	}

	getNearestLocationNameByCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, useShortName = false, maxDistanceInMeter = 250): string
	{
		const geoCoding = this.createGeoCoding();
		return geoCoding.getNearestLocationNameByCoordinate(coordinate, useShortName, maxDistanceInMeter);
	}

	createAddressByCoordinate(coordinate: MobiPointsTrackingCoordinateCoordinateInterface): MobiPointsGeoCodingAddressInterface | null {
		const geoCoding = this.createGeoCoding();
		return geoCoding.getAddressFromCoordinates(coordinate);
	}

	createAddressByData(
		street = "",
		streetNumber = "",
		city = "",
		locality = "",
		political = "",
		country = "",
		countryName = "",
		postalCode = "",
		adminArea1 = "",
		adminArea2 = "",
		formattedAddress = "",
		latitude?: number,
		longitude?: number,
	): MobiPointsGeoCodingAddressInterface {
		const address = {} as MobiPointsGeoCodingAddressInterface;
		address.street = street;
		address.streetNumber = streetNumber;
		address.city = city;
		address.locality = locality;
		address.political = political;
		address.country = country;
		address.countryName = countryName;
		address.postalCode = postalCode;
		address.adminArea1 = adminArea1;
		address.adminArea2 = adminArea2;
		address.formattedAddress = formattedAddress;
		address.latitude = latitude;
		address.longitude = longitude;

		if (formattedAddress.length === 0) {
			address.formattedAddress = street + ' ' + streetNumber + ', ' + city + ' ' + postalCode + ', ' + country;
		}
		return address;
	}

	createGeoCoding(): MobiPointsGeoCoding {
		return new MobiPointsGeoCoding();
	}
}