import {ChallengeGetResponse, ChallengeHighscoreGetResponse} from "@/mobipoints/api/challenge";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import {MobiPointsChallengeChallengeStateInterface} from "@/mobipoints/challenge/challenge/challenge_state.interface";
import {MobiPointsChallengeChallengeTypeInterface} from "@/mobipoints/challenge/challenge/challenge_type.interface";
import {UserChallenge} from "@/mobipoints/challenge/userChallenge/user_challenge.type";
import {
	MobiPointsChallengeUserChallengeStateInterface
} from "@/mobipoints/challenge/userChallenge/user_challenge_state.interface";
import {
	MobiPointsChallengeUserChallengeDataInterface
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import {MobiPointsChallengeChallengeInterface} from "@/mobipoints/challenge/challenge/challenge.interface";
import {
	MobiPointsChallengeHighscoreEntryInterface
} from "@/mobipoints/challenge/challenge/highscore/highscore.interface";
import {ChallengeHighscoreEntry} from "@/mobipoints/challenge/challenge/highscore/highscore_entry.type";

export class ChallengeFactory {
	createChallenge(uuid: string, state: MobiPointsChallengeChallengeStateInterface, type: MobiPointsChallengeChallengeTypeInterface): Challenge {
		return new Challenge(uuid, state, type);
	}

	createUserChallenge(user_id: number, challenge_uuid: string, state: MobiPointsChallengeUserChallengeStateInterface, progress: number, data: MobiPointsChallengeUserChallengeDataInterface | null = null): UserChallenge {
		const userChallenge = new UserChallenge(user_id, challenge_uuid, state, progress);
		userChallenge.data = data;

		return userChallenge;
	}

	createChallengeListByApiResponse(response: ChallengeGetResponse): Challenge[] {
		const list: Challenge[] = [];

		response.payload.forEach((data) => {
			list.push(this.createChallengeByApiResponse(data));
		});

		return list;
	}


	createChallengeByApiResponse(response: MobiPointsChallengeChallengeInterface): Challenge {
		const challenge = this.createChallenge(response.uuid, response.state, response.type);
		challenge.startTimestamp = response.startTimestamp;
		challenge.endTimestamp = response.endTimestamp;
		challenge.title = response.title;
		challenge.description = response.description || null;
		challenge.data = response.data;

		response.userChallenges.forEach((data) => {
			challenge.userChallenges.push(this.createUserChallenge(data.user_id, data.challenge_uuid, data.state, data.progress, data.data));
		});

		return challenge;
	}


	createChallengeHighscoreEntryByResponseData(data: MobiPointsChallengeHighscoreEntryInterface): ChallengeHighscoreEntry {
		return new ChallengeHighscoreEntry(data.position, data.user_id, data.user_name, data.user_email, data.user_avatar, data.progress, data.text);
	}


	createChallengeHighscoreEntryListByApiResponse(response: ChallengeHighscoreGetResponse): ChallengeHighscoreEntry[] {
		const list: ChallengeHighscoreEntry[] = [];

		response.payload.list.forEach((data) => {
			list.push(this.createChallengeHighscoreEntryByResponseData(data));
		});

		return list;
	}
}