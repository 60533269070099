import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d5d2330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_alert = _resolveComponent("ion-alert")!

  return (!$setup.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_alert, {
          "is-open": $props.showDialog,
          header: $setup.alertTitle,
          "sub-header": $setup.alertSubTitle,
          message: $setup.getAlertMessage(),
          "css-class": "my-custom-class",
          buttons: $setup.getDialogButtons(),
          backdropDismiss: false,
          onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => ($setup.setDialogOpen(false)))
        }, null, 8, ["is-open", "header", "sub-header", "message", "buttons"])
      ]))
    : _createCommentVNode("", true)
}