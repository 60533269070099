import {nanoid} from 'nanoid';
import {
	MobiPointsTrackingTrackAbstractTrack,
	TRACK_STATE_DONE,
	TRACK_STATE_NEW, TRACK_STATE_PAUSE
} from "@/mobipoints/tracking/track/abstract_track.type";
import {MobiPointsTrackingCoordinateCoordinateList} from "@/mobipoints/tracking/coordinate/coordinate_list.type";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {
	EVENT_TYPE_END_TRACK, EVENT_TYPE_END_TRACKING,
} from "@/mobipoints/queue/event/abstract_event.type";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsTrackingTrackTrackList} from "@/mobipoints/tracking/track/track_list";
import {getDateDiffInSeconds} from "@/mobipoints/core/date";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";

export const TRACKING_STATE_NEW = "new";
export const TRACKING__STATE_IN_PROGRESS = "in_progress";
export const TRACKING__STATE_INVALID = "invalid";
export const TRACKING__STATE_NULLIFY = "nullify";
export const TRACKING__STATE_VERIFIED = "verified";
export const TRACKING__STATE_CONVERTED = "converted";
export const TRACKING__STATE_ERROR = "error";

export class MobiPointsTrackingTracking {

	private tracks: MobiPointsTrackingTrackAbstractTrack[] = [];

	id: string;
	uuid: string;
	name: string;
	type: string|undefined;
	state: string;
	startTracking: Date;
	endTracking?: number | null;
	active: boolean;
	distance: number;
	duration: number;
	startTimestamp: number;
	startAddress?: MobiPointsGeoCodingAddressInterface;
	startCoordinate?: MobiPointsTrackingCoordinateCoordinateInterface;
	endAddress?: MobiPointsGeoCodingAddressInterface;
	endCoordinate?: MobiPointsTrackingCoordinateCoordinateInterface;
	weatherItem?: MobiPointsTrackingWeatherWeather;

	constructor(uuid: string, trackingName: string, state: string = TRACKING_STATE_NEW, startTracking: Date = new Date()) {
		this.id = nanoid();
		this.uuid = uuid;
		this.name = trackingName;
		this.state = state;
		this.startTracking = startTracking;
		this.startTimestamp = useTrackingFactory().getTrackingFactory().createCurrentTimeStamp();
		this.endTracking = null;
		this.active = true;
		this.distance = 0.0;
		this.duration = 0.0;
	}

	addTrack(track: MobiPointsTrackingTrackAbstractTrack) {
		console.log("PUSH NEW TRACK", track)
		this.tracks.push(track);
	}

	resetTracks() {
		this.tracks = [];
	}

	setTracks(tracks: MobiPointsTrackingTrackTrackList) {
		this.resetTracks();
		this.tracks.push(...tracks);
	}

	getLatestTrackingIcon(): string
	{
		let result = "";
		const latestTrack = this.getLatestTrack();
		if (latestTrack) {
			result = useTrackingFactory().getTrackingFactory().getTrackingIconByType(latestTrack.type)
		}
		return result;
	}

	removeTrack(index: number) {
		this.tracks.splice(index, 1);
	}

	removeAllTracks() {
		const itemCount = this.tracks.length;
		this.tracks.splice(itemCount, 1);
	}

	getFirstTrack(): MobiPointsTrackingTrackAbstractTrack|undefined {
		let firstTrack: MobiPointsTrackingTrackAbstractTrack|undefined;
		if (this.tracks && this.tracks.length > 0) {
			firstTrack = this.tracks[0]
		}
		return firstTrack;
	}

	getLatestTrack(): MobiPointsTrackingTrackAbstractTrack|undefined {
		let latestTrack: MobiPointsTrackingTrackAbstractTrack|undefined;
		if (this.tracks && this.tracks.length > 0) {
			latestTrack = this.tracks[this.tracks.length - 1]
		}
		return latestTrack;
	}

	getTrackDesc_by_Order(order = 1): MobiPointsTrackingTrackAbstractTrack|undefined {
		let latestTrack: MobiPointsTrackingTrackAbstractTrack|undefined;
		if (this.tracks && this.tracks.length > 0) {
			latestTrack = this.tracks[this.tracks.length - order]
		}
		return latestTrack;
	}

	getLatestTrackType(): string {
		let trackType = "";
		if (this.tracks && this.tracks.length > 0) {
			const latestTrack: MobiPointsTrackingTrackAbstractTrack = this.tracks[this.tracks.length - 1]
			trackType = latestTrack.type;
		}
		return trackType;
	}

	getTrackCount(): number
	{
		let result = 0;
		if (this.tracks) {
			result = this.tracks.length;
		}

		return result;
	}

	isLatestTrackStopped(): boolean {
		let result = false;
		const latestTrackType = this.getLatestTrackType();
		if (latestTrackType) {
			result = this.type === EVENT_TYPE_END_TRACKING || this.type === EVENT_TYPE_END_TRACK;
		}
		return result;
	}

	addCoordinateToTheLatestTrack(coordinate: MobiPointsTrackingCoordinateCoordinateInterface) {
		const latestTrack = this.getLatestTrack();
		if (latestTrack) {
			latestTrack.addCoordinate(coordinate);
			this.calculateDistance();
		}
	}

	getTrack(index: number): MobiPointsTrackingTrackAbstractTrack {
		return this.tracks[index];
	}

	getTracks(): MobiPointsTrackingTrackAbstractTrack[] {
		return this.tracks;
	}

	getFirstCoordinate(): MobiPointsTrackingCoordinateCoordinate|null {
		let firstCoordinate: MobiPointsTrackingCoordinateCoordinate|null = null;
		const firstTrack = this.getFirstTrack();
		if (firstTrack) {
			const coordinates_for_Track = firstTrack.getCoordinates();
			if (coordinates_for_Track && coordinates_for_Track.getItems().length > 0) {
				firstCoordinate = coordinates_for_Track.getItem(0);
			}
		}
		return firstCoordinate;
	}

	getLastCoordinate_by_TrackList(): MobiPointsTrackingCoordinateCoordinate|null {
		const coordinateList = this.getAllCoordinatesForMap();
		const lastCoordinate = coordinateList.sortItems(true, 1);
		if (lastCoordinate instanceof Array) {
			console.log("ERROR DID NOT FIND UNIQUE COORDINATE!");
			return null;
		}
		return lastCoordinate;
	}

	getLastCoordinate(): MobiPointsTrackingCoordinateCoordinate|null {
		let lastCoordinate: MobiPointsTrackingCoordinateCoordinate|null = null;

		for (let i = 1; i < this.getTrackCount(); i++) {
			const lastTrack = this.getTrackDesc_by_Order(i);
			if (lastTrack) {
				const coordinates_for_Track = lastTrack.getCoordinates();
				if (coordinates_for_Track && coordinates_for_Track.getItems().length > 0) {
					lastCoordinate = coordinates_for_Track.getItem(coordinates_for_Track.getItems().length-1);
					break;
				}
			}
		}

		if (!lastCoordinate) {
			lastCoordinate = this.getLastCoordinate_by_TrackList();
		}

		return lastCoordinate;
	}

	getAllCoordinatesForMap(): MobiPointsTrackingCoordinateCoordinateList {
		const coordinatesList = new MobiPointsTrackingCoordinateCoordinateList();
		for (const track of this.getTracks()) {
			const coordinates_for_Track = track.getCoordinates();
			if (coordinates_for_Track && coordinates_for_Track.getItems().length > 0) {
				coordinates_for_Track.getItems().forEach(coordinate => coordinatesList.push(coordinate));
			}
		}
		return coordinatesList;
	}

	calculateDistance() {
		let distance = 0.0;
		let lastTrack: MobiPointsTrackingTrackAbstractTrack|any = null;
		for (const track of this.getTracks()) {
			const coordinates_for_Track = track.getCoordinates();
			if (coordinates_for_Track && coordinates_for_Track.getItems().length > 0) {
				distance += Number(useTrackingFactory().getTrackingFactory().createDistanceCalculation().getTotalDistance_for_CoordinatesList(coordinates_for_Track, lastTrack));
			}
			lastTrack = track;
		}
		this.distance = distance;
		return distance;
	}

	getDistance(): number {
		return this.distance;
	}

	calculateDuration() {
		let startTimestamp = this.startTimestamp;
		let endTimestamp;
		let duration = 0.0;
		for (const track of this.getTracks()) {
			if (track.timestamp) {
				startTimestamp = track.timestamp;
			}
			if (track && (track.state === TRACK_STATE_DONE || track.state === TRACK_STATE_PAUSE)) {
				if (!track.endTimestamp) {
					const coordinates_for_Track = track.getCoordinates();
					if (coordinates_for_Track && coordinates_for_Track.getItems().length > 0) {
						const lastCoordinate = coordinates_for_Track.getItem(coordinates_for_Track.getItems().length-1);
						if (lastCoordinate) {
							endTimestamp = lastCoordinate.timestamp;
						} else {
							endTimestamp = null;
						}
					}
				} else {
					endTimestamp = track.endTimestamp;
				}
			} else if(track && track.state === TRACK_STATE_NEW) {
				endTimestamp = useTrackingFactory().getTrackingFactory().createCurrentTimeStamp();
			} else {
				endTimestamp = null;
			}
			if (startTimestamp && endTimestamp && startTimestamp != endTimestamp) {
				const dateDiffInSeconds = getDateDiffInSeconds(endTimestamp, startTimestamp);
				duration += dateDiffInSeconds;
			}
		}
		this.duration = duration;
		return this.duration;
	}

	getDuration(): number {
		return this.duration;
	}
}