import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39b3aff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_advanced_tracking_overlay = _resolveComponent("advanced-tracking-overlay")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_advanced_tracking_overlay, {
      onSelectTrackType: $setup.startNewTracking,
      onCloseOverlay: $setup.toggleShowMoreTypes
    }, null, 8, ["onSelectTrackType", "onCloseOverlay"]), [
      [_vShow, $setup.showMoreTypes === true]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([$setup.fabButtonClicked ? 'back-drop-tracking-button' : '']),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.handleBackdropClick && $setup.handleBackdropClick(...args))),
      ref: "trackingButtonBackdrop"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_fab, {
          ref: "fabButtonRef",
          slot: "fixed",
          activated: "false",
          class: "",
          edge: "",
          horizontal: "end",
          vertical: "bottom",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.fabButtonClicked = !$setup.fabButtonClicked))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              class: "trackFabButton",
              color: "success"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: $setup.icons.add
                }, null, 8, ["icon"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_fab_list, { side: "start" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getTrackingTypeMinimal_Data_Map(), (trackingTrackType) => {
                  return (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: trackingTrackType.code,
                    color: "light",
                    class: "trackFabButton trackFabIcon",
                    onClick: ($event: any) => ($setup.startNewTracking(trackingTrackType.code)),
                    style: _normalizeStyle(trackingTrackType.style)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: trackingTrackType.getTrackTypeIcon(),
                        class: "track-fab-icon-size"
                      }, null, 8, ["icon"])
                    ]),
                    _: 2
                  }, 1032, ["onClick", "style"]))
                }), 128))
              ]),
              _: 1
            }),
            ($setup.showMoreTypes === true)
              ? (_openBlock(), _createBlock(_component_ion_fab_list, {
                  key: 0,
                  side: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, {
                      class: "trackFabButton trackFabIcon",
                      onClick: $setup.toggleShowMoreTypes
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: $setup.icons.arrowDown
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getTrackingTypeAdvanced_Data_Map(), (trackingTrackType) => {
                      return (_openBlock(), _createBlock(_component_ion_fab_button, {
                        key: trackingTrackType.code,
                        class: "trackFabButton trackFabIcon",
                        onClick: ($event: any) => ($setup.startNewTracking(trackingTrackType.code)),
                        style: _normalizeStyle(trackingTrackType.style)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: trackingTrackType.getTrackTypeIcon(),
                            class: "track-fab-icon-size"
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick", "style"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_fab_list, {
                  key: 1,
                  side: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, {
                      class: "trackFabButton trackFabIcon",
                      onClick: $setup.toggleShowMoreTypes
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: $setup.icons.arrowUp
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 512)
      ])
    ], 2)
  ], 64))
}