<template>
	<ion-page>
		<ion-content class="ion-padding">
			<div v-if="!showSuccessMessage">
				<p style="text-align: center">{{registerTitle}}</p>
				<div>
					<form @submit.prevent="register">
						<ion-card class="ion-no-padding ion-no-margin">
							<ion-item>
								<ion-label position="stacked">Name</ion-label>
								<ion-input v-model="registerData.name" placeholder="Name" autocomplete="name"
										   type="text" requried="true" tabindex="1"></ion-input>
							</ion-item>
							<span class="error ion-padding" v-if="nameError">{{ nameError }}</span>
							<ion-item>
								<ion-label position="stacked">E-Mail</ion-label>
								<ion-input v-model="registerData.email" placeholder="E-Mail" type="email"
										   autocomplete="email" required="true" tabindex="2"></ion-input>
							</ion-item>
							<span class="error ion-padding" v-if="emailError">{{ emailError }}</span>
							<ion-item>
								<ion-label position="stacked">Passwort</ion-label>
								<ion-input v-model="registerData.password" placeholder="Passwort" type="password"
										   required="true" tabindex="3"></ion-input>
							</ion-item>
							<ion-item>
								<ion-label position="stacked">Passwort bestätigen</ion-label>
								<ion-input v-model="registerData.passwordConfirm" placeholder="Passwort bestätigen"
										   type="password" required="true" tabindex="4"></ion-input>
							</ion-item>
							<span class="error ion-padding" v-if="passwordError"
								  id="password-guidelines-hover">{{ passwordError }} <button
									@click="handlePasswordHover">ℹ️</button></span>

							<ion-button class="basic-login-btn" expand="block" type="submit"
										style="min-height: 65px; margin-bottom: -5px; margin-top: 20px">
								<p class="basic-login-text">Account erstellen</p>
							</ion-button>
						</ion-card>
					</form>
				</div>

				<div style="text-align: center" class="ion-padding">
					<p>Bereits registriert?
						<router-link to="/login" class="link-text-color underline">
							Melde dich an
						</router-link>
					</p>
				</div>
			</div>
			<div v-if="showSuccessMessage">
				<div style="text-align: center" class="ion-padding">
					<h1 v-html="registerSuccessTitle"/>
					<login-success-animation style="max-height: 30rem; width: 100%"></login-success-animation>
					<p v-html="getRegisterSuccessBody()"/>
					<p>{{registerGoBackTo}}
						<router-link to="/login" class="link-text-color underline">
							Login
						</router-link>
					</p>
				</div>
			</div>
		</ion-content>

		<ion-footer class="ion-padding">
			<p style="margin: auto;text-align: center;">
				<span>
					<router-link to="/privacy-crudafoch">
						<a style="cursor: pointer" class="ion-color-medium">Datenschutz</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/terms">
						<a style="cursor: pointer">AGB</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/impress">
						<a style="cursor: pointer">Impressum</a>
					</router-link>
				</span>
			</p>
			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 7px">
				Version: {{ appVersion }}
			</p>
			<mobility-logo></mobility-logo>
		</ion-footer>
	</ion-page>
</template>

<script lang="ts">
import {ref} from "vue";
import {
	IonContent,
	IonFooter,
	IonPage,
	IonInput,
	IonLabel,
	IonCard,
	loadingController,
	onIonViewWillEnter,
	popoverController
} from '@ionic/vue';
import MobilityLogo from "@/components/logo/MobilityLogo.vue";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import useAuth from "@/composable/useAuth";
import router from "@/router";
import {BasicAuthError} from "@/mobipoints/api/auth";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import useToastMessage from "@/components/core/ToastMessage.vue";
import LoginSuccessAnimation from "@/components/animation/LoginSuccessAnimation.vue";

export default {
	name: 'Register',
	components: {
		LoginSuccessAnimation, IonCard,
		MobilityLogo, IonContent, IonPage, IonFooter, IonInput, IonLabel
	},
	setup() {
		const {getTextValueByKey} = useData();
		const {appVersion} = useSystem();
		const loginTitle = ref(getTextValueByKey('login.title', [], 'Willkommen bei den MobiPoints'));
		const {isAuthenticated} = useAuth();
		const loadingTimeout = ref(1000);
		const nameError = ref("");
		const emailError = ref("");
		const passwordError = ref("");
		const passwordHover = ref(false);
		const currentPopover = ref();
		const registerData: any = ref({name: '', email: "", password: "", passwordConfirm: ""});
		const showSuccessMessage = ref(false);
		const showErrorMessage = ref(false);
		const {openToast} = useToastMessage();
		const registerSuccessTitle = ref(getTextValueByKey('register.success.title', [], 'ERFOLGREICH REGISTRIERT'));
		const registerGoBackTo = ref(getTextValueByKey('register.success.go_back', [], 'Zurück zum'));
		const registerTitle = ref(getTextValueByKey('register.title', [], 'Registriere dich mit deiner E-Mail Adresse und Passwort'));

		function getRegisterSuccessBody(): string {
			return getTextValueByKey('register.success.body', [{
				key: 'Email',
				value: registerData.value.email
			}], 'Es wurde ein Link an deine E-Mail Adresse <i>{Email}</i> gesendet.<br>Bitte verifiziere deine E-Mail Adresse und schon kanns losgehen!')
		}

		async function presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: '',
					message: 'Bitte warten...wir versuchen deinen Account zu erstellen!',
					duration: loadingTimeout.value,
				});

			await loading.present();

			setTimeout(function () {
				loading.dismiss()
			}, loadingTimeout.value);
		}

		onIonViewWillEnter(() => {
			if (isAuthenticated.value) {
				router.push('home');
			}
		});

		function hasPasswordUppercase(): boolean {
			const result = registerData.value.password.match(/[A-Z]/);
			return !!result;
		}

		function hasPasswordLowercase(): boolean {
			const result = registerData.value.password.match(/[a-z]/);
			return !!result;
		}


		function hasPasswordNumber(): boolean {
			const result = registerData.value.password.match(/[0-9]/);
			return !!result;
		}


		function hasPasswordMinLength(): boolean {
			return registerData.value.password.length >= 8;
		}


		function isPasswordConfirmCorrect(): boolean {
			return registerData.value.password === registerData.value.passwordConfirm;
		}

		async function handlePasswordHover(ev) {
			const popover = await popoverController.create({
				component: 'password-guideline-popover',
				event: ev,
				translucent: true,
			});
			currentPopover.value = popover;
			return popover.present();
		}

		customElements.define(
			'password-guideline-popover',
			class ModalContent extends HTMLElement {
				connectedCallback() {
					this.innerHTML = `
          <ion-list>
            <ion-list-header>PASSWORT RICHTLINIEN</ion-list-header>
            <ion-item button>MINDESTENS 8 ZEICHEN</ion-item>
            <ion-item button>1 GROßBUCHSTABE</ion-item>
            <ion-item button>1 KLEINBUCHSTABE</ion-item>
            <ion-item button>MINDESTENS EINE ZAHL</ion-item>
          </ion-list>
        `;
				}
			}
		);

		async function handleRegisterError(error: any | BasicAuthError) {
			try {
				console.log("ERROR MESSAGE", error)
				if (error instanceof BasicAuthError) {
					console.log("ERRROR - OK", error);
					// {"error":{"fields":[{"name":"email","message":"Email address has invalid domain name!"}],"message":""}}
					if (error.errorFields) {
						error.errorFields.forEach((errorField: any = {name: '', message: ''}) => {
							if (errorField.name == 'name') {
								nameError.value = errorField.message;
							}
							if (errorField.name == 'email') {
								emailError.value = errorField.message;
							}
							if (errorField.name == 'password') {
								passwordError.value = errorField.message;
							}
						});
					}
					if (error.message) {
						await openToast(error.message, 'danger', 'top', true, 10000, undefined, true);
					}
				} else {
					await openToast(getTextValueByKey('login.basic.general.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
				}
			} catch (error) {
				await openToast(getTextValueByKey('login.basic.general.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
			}
		}

		function hasValidRegisterData(): boolean {
			let result = true;
			nameError.value = "";
			emailError.value = "";
			passwordError.value = "";
			if (!registerData.value.name) {
				nameError.value = 'Bitte trag einen Namen ein!';
				result = false;
			}
			if (!registerData.value.email) {
				emailError.value = 'Bitte trag eine gültige E-Mail Adresse ein!';
				result = false;
			}
			if (!registerData.value.password) {
				passwordError.value = 'Bitte trag ein gültiges Passwort ein!';
				result = false;
			}
			if (!registerData.value.passwordConfirm && registerData.value.password) {
				passwordError.value = 'Bitte bestätige dein Passwort!';
				result = false;
			}
			if (registerData.value.password && registerData.value.passwordConfirm && registerData.value.password !== registerData.value.passwordConfirm) {
				passwordError.value = 'Passwörter stimmen nicht überein';
				result = false;
			}

			return result;
		}

		async function register(): Promise<void> {
			try {
				if (hasValidRegisterData()) {
					await presentLoading();
					const userAuth = new UserAuth();
					const userRegisterData = {
						name: registerData.value.name,
						email: registerData.value.email,
						password: registerData.value.password,
					}
					const result = await userAuth.registerBasic(userRegisterData);
					console.log("FOO")
					if (result) {
						showSuccessMessage.value = true;
					} else {
						showSuccessMessage.value = false;
						showErrorMessage.value = true;
					}
				}
			} catch (error: any | BasicAuthError) {
				console.log(error);
				await handleRegisterError(error);
			}
		}

		return {
			appVersion,
			loginTitle,
			registerData,
			nameError,
			emailError,
			passwordError,
			passwordHover,
			handlePasswordHover,
			showSuccessMessage,
			showErrorMessage,
			hasPasswordNumber,
			hasPasswordLowercase,
			hasPasswordUppercase,
			isPasswordConfirmCorrect,
			hasPasswordMinLength,
			registerSuccessTitle,
			getRegisterSuccessBody,
			registerGoBackTo,
			register,
			registerTitle,
		}
	}
}
</script>

<style>
.loginHeader {
    text-align: center;
    color: var(--ion-text-color);
}

.basic-login-text {
    font-family: Rubik, sans-serif !important;
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.basic-login-btn {
    background-color: transparent;
    box-shadow: none;
    margin-left: -2px;
    margin-right: -2px;
    /*box-shadow: 0 0 .35em .175em rgba(var(--ion-color-dark-rgb), .33);*/
    /*-webkit-box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
    /*box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
}

.link-text-color {
    cursor: pointer; 
	font-weight: bold;
	color: var(--ion-color-dark);
}

.error {
    color: #e74848;
}

.noErrorMargin {
    margin-top: -3px;
}

.addTopMargin {
    margin-top: 20px
}
</style>