<template>
	<ion-page>
		<ion-modal :is-open="true" :show-backdrop="true" :swipe-to-close="true" :keyboardClose="true" @didDismiss="closeModalAndGoBack">
			<ion-header translucent>
				<ion-toolbar>
					<ion-title>📜 Allgemeine Geschäftsbedingungen</ion-title>
					<ion-buttons slot="end">
						<ion-button @click="dismissModal()">Schließen</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>
			<ion-content fullscreen>
				<div style="padding: 10px">
					<p>
						Einleitung
					</p>
					<p>
						Diese Nutzungsbedingungen stellen eine rechtsverbindliche Vereinbarung zwischen Ihnen, ob
						persönlich oder im Namen einer juristischen Person ("Sie", "Benutzer", "Du" oder "User") und
						crudafoch ("Unternehmen", "Wir", "Uns" oder "Unsere") dar, die Ihren Zugang zu und Ihre Nutzung
						der mobi-points.nice-mobility.app-Website sowie jeder anderen Medienform, jedem Medienkanal,
						jeder mobilen Website oder mobilen Anwendung, die damit in Verbindung steht, verlinkt oder
						anderweitig damit verbunden ist (zusammenfassend als "Site" bezeichnet). Mit dem Zugriff auf die
						Website erklären Sie, dass Sie alle diese Nutzungsbedingungen gelesen und verstanden haben und
						sich mit ihnen einverstanden erklären. WENN SIE NICHT MIT ALLEN DIESEN NUTZUNGSBEDINGUNGEN
						EINVERSTANDEN SIND, IST ES IHNEN AUSDRÜCKLICH UNTERSAGT, DIE SITE ZU NUTZEN, UND SIE MÜSSEN DIE
						NUTZUNG SOFORT EINSTELLEN.

						Alle diese zusätzlichen Bedingungen, Richtlinien und Regeln werden durch Verweis in diese
						Bedingungen aufgenommen.

						Zusätzliche Bestimmungen und Bedingungen oder Dokumente, die von Zeit zu Zeit auf der Website
						veröffentlicht werden, werden hiermit ausdrücklich durch Verweis in diese Bedingungen
						aufgenommen. Wir behalten uns das Recht vor, nach eigenem Ermessen jederzeit und ohne Angabe von
						Gründen Änderungen an diesen Nutzungsbedingungen vorzunehmen. Wir werden Sie über alle
						Änderungen informieren, indem wir das Datum der letzten Aktualisierung dieser
						Nutzungsbedingungen aktualisieren, und Sie verzichten auf das Recht, über jede derartige
						Änderung gesondert informiert zu werden. Es liegt in Ihrer Verantwortung, diese
						Nutzungsbedingungen regelmäßig zu überprüfen, um über Aktualisierungen informiert zu sein. Durch
						Ihre fortgesetzte Nutzung der Website nach dem Datum der Veröffentlichung der überarbeiteten
						Nutzungsbedingungen unterliegen Sie den Änderungen dieser Nutzungsbedingungen und es wird davon
						ausgegangen, dass Sie diese zur Kenntnis genommen und akzeptiert haben. Wenn wir wesentliche
						Änderungen vornehmen, können wir Sie benachrichtigen, indem wir Ihnen eine E-Mail an die letzte
						uns von Ihnen zur Verfügung gestellte E-Mail-Adresse schicken und/oder indem wir die Änderungen
						deutlich sichtbar auf unserer Website bekannt geben. Sie sind dafür verantwortlich, uns Ihre
						aktuellste E-Mail-Adresse mitzuteilen. Für den Fall, dass die letzte von Ihnen angegebene
						E-Mail-Adresse ungültig ist, gilt die Zusendung der E-Mail mit dieser Mitteilung dennoch als
						wirksame Mitteilung der in der Mitteilung beschriebenen Änderungen. Jegliche Änderungen dieser
						Bedingungen werden frühestens dreißig (30) Kalendertage nach dem Versand einer
						E-Mail-Benachrichtigung an Sie oder dreißig (30) Kalendertage nach der Veröffentlichung einer
						Mitteilung über die Änderungen auf unserer Website wirksam. Diese Änderungen sind für neue
						Benutzer unserer Website sofort wirksam. Die fortgesetzte Nutzung unserer Website nach
						Bekanntgabe solcher Änderungen bedeutet, dass Sie diese Änderungen anerkennen und sich mit den
						Bedingungen dieser Änderungen einverstanden erklären.

						Die auf der Website bereitgestellten Informationen sind nicht für die Verbreitung an oder die
						Nutzung durch natürliche oder juristische Personen in Rechtsordnungen oder Ländern bestimmt, in
						denen eine solche Verbreitung oder Nutzung gegen Gesetze oder Vorschriften verstößt oder uns
						einer Registrierungspflicht in einer solchen Rechtsordnung oder einem solchen Land unterwerfen
						würde. Dementsprechend tun Personen, die von anderen Orten aus auf die Website zugreifen, dies
						auf eigene Initiative und sind allein für die Einhaltung der lokalen Gesetze verantwortlich,
						sofern und soweit diese anwendbar sind.

						Wenn eine Bestimmung dieser Bedingungen für ungültig oder nicht durchsetzbar gehalten wird,
						bleiben die anderen Bestimmungen dieser Bedingungen davon unberührt und die ungültige oder nicht
						durchsetzbare Bestimmung gilt als so geändert, dass sie im größtmöglichen gesetzlich zulässigen
						Umfang gültig und durchsetzbar ist.

						Die Website ist nicht auf die Einhaltung branchenspezifischer Vorschriften (Health Insurance
						Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA)
						usw.) zugeschnitten, so dass Sie diese Website nicht nutzen dürfen, wenn Ihre Interaktionen
						unter solche Gesetze fallen würden. Sie dürfen die Website nicht in einer Weise nutzen, die
						gegen den Gramm-Leach-Bliley Act (GLBA) verstößt.

						Die Website ist für Benutzer bestimmt, die mindestens 13 Jahre alt sind. Alle Nutzer, die in dem
						Land, in dem sie wohnen, minderjährig sind (im Allgemeinen unter 18 Jahren), müssen die
						Erlaubnis ihrer Eltern oder ihres Vormunds haben und von diesen direkt beaufsichtigt werden, um
						die Website zu nutzen. Wenn Sie minderjährig sind, müssen Sie Ihre Eltern oder
						Erziehungsberechtigten dazu bringen, diese Nutzungsbedingungen zu lesen und ihnen zuzustimmen,
						bevor Sie die Website nutzen.

						Geistige Eigentumsrechte
						Sofern nicht anders angegeben, ist die Website unser Eigentum, und der gesamte Quellcode, die
						Datenbanken, die Funktionalität, die Software, die Website-Designs, die Audio- und Videodateien,
						der Text, die Fotos und die Grafiken auf der Website (zusammen der "Content" oder "Inhalt")
						sowie die darin enthaltenen Marken, Dienstleistungsmarken und Logos (die darin enthalten sind
						(die Marken), sind unser Eigentum oder werden von uns oder dem Benutzer kontrolliert, sind an
						uns oder den Benutzer lizenziert und sind durch Urheberrechts- und Markengesetze und
						verschiedene andere geistige Eigentumsrechte und Gesetze gegen unlauteren Wettbewerb der
						Vereinigten Staaten, internationale Urheberrechtsgesetze und internationale Konventionen
						geschützt. Der Inhalt und die Marken werden auf der Website \u201cAS IS\u201d nur zu Ihrer
						Information und persönlichen Nutzung bereitgestellt. Sofern nicht ausdrücklich in diesen
						Nutzungsbedingungen vorgesehen, darf kein Teil der Website und kein Inhalt und keine Marke ohne
						unsere ausdrückliche vorherige schriftliche Genehmigung kopiert, reproduziert, zusammengefasst,
						neu veröffentlicht, hochgeladen, gepostet, öffentlich ausgestellt, kodiert, übersetzt,
						übertragen, verteilt, verkauft, lizenziert oder anderweitig für kommerzielle Zwecke jeglicher
						Art genutzt werden.

						Sofern Sie zur Nutzung der Website berechtigt sind, erhalten Sie eine eingeschränkte Lizenz für
						den Zugriff auf die Website und deren Nutzung sowie für das Herunterladen oder Ausdrucken einer
						Kopie des Inhalts, auf den Sie ordnungsgemäß Zugriff erhalten haben, ausschließlich für Ihren
						persönlichen, nicht kommerziellen Gebrauch. Wir behalten uns alle Rechte an der Website, dem
						Inhalt und den Marken vor, die Ihnen nicht ausdrücklich gewährt werden.

						Benutzerinformationen
						Durch die Nutzung der Website erklären und garantieren Sie, dass:

						alle Registrierungsinformationen, die Sie übermitteln, wahr, genau, aktuell und vollständig
						sind;
						Sie werden die Richtigkeit dieser Informationen aufrechterhalten und diese
						Registrierungsinformationen bei Bedarf unverzüglich aktualisieren;
						Sie sind geschäftsfähig und stimmen zu, diese Nutzungsbedingungen einzuhalten;
						Sie sind nicht unter 13 Jahre alt;
						Sie sind in dem Land, in dem Sie wohnen, nicht minderjährig, oder wenn Sie minderjährig sind,
						haben Sie die Erlaubnis Ihrer Eltern, die Website zu nutzen;
						Sie greifen nicht über automatisierte oder nicht-menschliche Mittel auf die Website zu, sei es
						über einen Bot, ein Skript oder anderweitig;
						Sie werden die Website nicht für illegale oder unbefugte Zwecke nutzen; und
						Ihre Nutzung der Website verstößt nicht gegen geltende Gesetze oder Vorschriften.
						Wenn Sie unwahre, ungenaue, nicht aktuelle oder unvollständige Angaben machen, haben wir das
						Recht, Ihr Konto auszusetzen oder zu kündigen und jegliche aktuelle oder zukünftige Nutzung der
						Website (oder eines Teils davon) zu verweigern.

						Es kann erforderlich sein, dass Sie sich auf der Website registrieren. Sie verpflichten sich,
						Ihr Passwort vertraulich zu behandeln und sind für die Nutzung Ihres Kontos und Passworts
						verantwortlich. Wir behalten uns das Recht vor, einen von Ihnen gewählten Benutzernamen zu
						entfernen, zurückzufordern oder zu ändern, wenn wir nach eigenem Ermessen feststellen, dass ein
						solcher Benutzername unangemessen, obszön oder anderweitig zu beanstanden ist.

						Zahlungen
						Wir akzeptieren die folgenden Zahlungsarten:

						PayPal
						Visa
						Kryptowährung
						Es kann sein, dass Sie für den Zugriff auf einige unserer Dienste eine Gebühr bezahlen müssen.
						Sie verpflichten sich, aktuelle, vollständige und genaue Kauf- und Kontoinformationen für alle
						über die Website getätigten Käufe anzugeben. Sie erklären sich ferner damit einverstanden, Ihre
						Konto- und Zahlungsinformationen, einschließlich E-Mail-Adresse, Zahlungsmethode und Ablaufdatum
						der Zahlungskarte, umgehend zu aktualisieren, damit wir Ihre Transaktionen abschließen und Sie
						bei Bedarf kontaktieren können. Wir stellen Ihnen über ein Online-Abrechnungskonto die über die
						Website getätigten Einkäufe in Rechnung. Die Umsatzsteuer wird auf den Preis der Einkäufe
						aufgeschlagen, wenn wir dies für erforderlich halten. Wir können die Preise jederzeit ändern.

						Sie erklären sich damit einverstanden, alle Kosten oder Gebühren zu den dann gültigen Preisen
						für Ihre Einkäufe zu zahlen, und Sie ermächtigen uns, den von Ihnen gewählten Zahlungsanbieter
						mit solchen Beträgen zu belasten, wenn Sie Ihren Einkauf tätigen.

						Wir behalten uns das Recht vor, Fehler oder Irrtümer bei der Preisgestaltung zu korrigieren,
						auch wenn wir bereits eine Zahlung angefordert oder erhalten haben. Wir behalten uns außerdem
						das Recht vor, jede über die Website aufgegebene Bestellung abzulehnen.

						Alle Käufe sind nicht erstattungsfähig. Sie können Ihr Abonnement jederzeit kündigen, indem Sie
						sich bei Ihrem Konto anmelden. Ihre Kündigung wird zum Ende der aktuellen bezahlten Laufzeit
						wirksam.

						Wenn Sie mit unseren Dienstleistungen unzufrieden sind, senden Sie uns bitte eine E-Mail an
						officeatZeichencrudafoch.com.

						Sie dürfen auf die Website nur zu dem Zweck zugreifen oder sie nutzen, für den wir sie zur
						Verfügung stellen. Die Website darf nicht in Verbindung mit kommerziellen Unternehmungen genutzt
						werden, es sei denn, diese werden von uns ausdrücklich gebilligt oder genehmigt.

						Nutzungsvereinbarung
						Sie entbinden hiermit das Unternehmen und unsere leitenden Angestellten, Mitarbeiter, Vertreter,
						Nachfolger und Bevollmächtigten für immer von allen vergangenen, gegenwärtigen und zukünftigen
						Streitigkeiten, Ansprüchen, Kontroversen, Forderungen, Rechten, Verpflichtungen, Haftungen,
						Klagen und Klagegründen jeder Art, die direkt oder indirekt aus der Website entstanden sind oder
						sich direkt oder indirekt auf die Website beziehen, und verzichten auf diese. Wenn Sie in
						Kalifornien ansässig sind, verzichten Sie hiermit auf den Abschnitt 1542 des kalifornischen
						Zivilgesetzbuches in Verbindung mit dem Vorgenannten, der besagt: eine allgemeine Freigabe
						erstreckt sich nicht auf Ansprüche, von denen der Gläubiger zum Zeitpunkt der Ausfertigung der
						Freigabe nicht weiß oder vermutet, dass sie zu seinen Gunsten bestehen, die aber, wenn sie ihm
						bekannt gewesen wären, seinen Vergleich mit dem Schuldner wesentlich beeinflusst hätten.

						Wir sind nicht für Benutzerinhalte verantwortlich, unabhängig davon, ob diese von Ihnen oder von
						anderen bereitgestellt wurden. Sie erklären sich damit einverstanden, dass das Unternehmen nicht
						für Verluste oder Schäden verantwortlich ist, die aus solchen Interaktionen resultieren. Sollte
						es zu einem Streit zwischen Ihnen und einem Nutzer der Website kommen, sind wir nicht
						verpflichtet, uns einzuschalten.

						Als Nutzer der Website verpflichten Sie sich, nicht:

						Jegliche unbefugte Nutzung der Website, einschließlich des Sammelns von Benutzernamen und/oder
						E-Mail-Adressen von Nutzern auf elektronischem oder anderem Wege zum Zwecke des Versendens
						unerwünschter E-Mails oder des Erstellens von Nutzerkonten mit automatisierten Mitteln oder
						unter Vorspiegelung falscher Tatsachen
						Die Website zu nutzen, um für Waren und Dienstleistungen zu werben oder sie zum Verkauf
						anzubieten, die nicht Teil des Vertrags zwischen Ihnen und uns sind.
						Einen Einkäufer oder Einkaufsagenten benutzen, um auf der Website einzukaufen.
						Sicherheitsrelevante Funktionen der Website zu umgehen, zu deaktivieren oder anderweitig zu
						stören, einschließlich Funktionen, die die Nutzung oder das Kopieren von Inhalten verhindern
						oder einschränken oder Beschränkungen für die Nutzung der Website und/oder der darin enthaltenen
						Inhalte durchsetzen
						Uns und andere Benutzer auszutricksen, zu betrügen oder in die Irre zu führen, insbesondere bei
						dem Versuch, sensible Kontoinformationen wie Benutzerpasswörter zu erfahren.
						Sich an einer automatisierten Nutzung des Systems zu beteiligen, wie z.B. die Verwendung von
						Skripten zum Senden von Kommentaren oder Nachrichten oder die Verwendung von Data-Mining,
						Robotern oder ähnlichen Tools zum Sammeln und Extrahieren von Daten.
						Löschen Sie den Hinweis auf das Urheberrecht oder andere Eigentumsrechte von jeglichen Inhalten.
						Kopieren oder Anpassen der Software der Website, einschließlich, aber nicht beschränkt auf PHP,
						HTML, JavaScript oder anderen Code.
						Sie dürfen die Website nicht verkaufen, vermieten, verleasen, übertragen, abtreten, verteilen,
						hosten oder anderweitig kommerziell verwerten.
						Es ist Ihnen nicht gestattet, die Website zu verändern, davon abgeleitete Werke zu erstellen, zu
						disassemblieren, zurückzukompilieren oder Teile der Website zurückzuentwickeln.
						Sie dürfen nicht auf die Website zugreifen, um eine ähnliche oder konkurrierende Website zu
						erstellen.
						Sofern nicht ausdrücklich angegeben, darf kein Teil der Website kopiert, reproduziert, verteilt,
						neu veröffentlicht, heruntergeladen, angezeigt, gepostet oder in irgendeiner Form oder mit
						irgendwelchen Mitteln übertragen werden, sofern nicht anders angegeben, jede zukünftige Version,
						Aktualisierung oder andere Ergänzung der Funktionalität der Website unterliegt diesen
						Bedingungen.
						Benutzergenerierte Beiträge
						Sie sind ausschließlich für Ihre Benutzerinhalte verantwortlich. Sie tragen alle Risiken, die
						mit der Verwendung Ihrer Benutzerinhalte verbunden sind. Die Website kann Sie dazu einladen, zu
						chatten, Beiträge zu leisten oder an Blogs, Message Boards, gemeinsam genutzten Inhalten und
						anderen Funktionen teilzunehmen, und kann Ihnen die Möglichkeit bieten, Inhalte und Materialien
						an uns oder auf der Website zu erstellen, einzureichen, zu posten, darzustellen, zu übertragen,
						auszuführen, zu veröffentlichen, zu verbreiten oder zu senden, einschließlich, aber nicht
						beschränkt auf Texte, Schriften, Videos, Audios, Fotos, Grafiken, Kommentare, Vorschläge oder
						persönliche Informationen oder andere Materialien (zusammenfassend als "Beiträge" bezeichnet).
						Die Beiträge können von anderen Nutzern der Website und über Websites Dritter eingesehen werden.
						Als solche können alle von Ihnen übermittelten Beiträge als nicht vertraulich und nicht
						urheberrechtlich geschützt behandelt werden. Wenn Sie Beiträge erstellen oder zur Verfügung
						stellen, sichern Sie damit zu und gewährleisten, dass:

						Die Erstellung, der Vertrieb, die Übertragung, die öffentliche Darstellung oder Aufführung sowie
						der Zugriff, das Herunterladen oder das Kopieren Ihrer Beiträge verletzen nicht die
						Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken,
						Geschäftsgeheimnisse oder moralische Rechte Dritter.
						Sie sind der Urheber und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte,
						Zustimmungen, Freigaben und Erlaubnisse, um uns, die Website und andere Nutzer der Website zu
						ermächtigen, Ihre Beiträge in der auf der Website und in diesen Nutzungsbedingungen vorgesehenen
						Weise zu nutzen.
						Sie haben die schriftliche Zustimmung, Freigabe und/oder Erlaubnis jeder einzelnen
						identifizierbaren Person in Ihren Beiträgen, den Namen oder das Bildnis jeder einzelnen
						identifizierbaren Person zu verwenden, um die Aufnahme und Verwendung Ihrer Beiträge auf jede
						von der Website und diesen Nutzungsbedingungen vorgesehene Weise zu ermöglichen.
						Ihre Beiträge sind nicht falsch, ungenau oder irreführend.
						Ihre Beiträge sind keine unaufgeforderte oder unerlaubte Werbung, Werbematerialien,
						Schneeballsysteme, Kettenbriefe, Spam, Massenmailings oder andere Formen der Aufforderung.
						Ihre Beiträge sind nicht obszön, unzüchtig, lasziv, schmutzig, gewalttätig, belästigend,
						beleidigend, verleumderisch oder anderweitig anstößig (wie von uns bestimmt).
						Ihre Beiträge machen niemanden lächerlich, verhöhnen, verunglimpfen, einschüchtern oder
						beleidigen.
						Ihre Beiträge werden nicht verwendet, um eine andere Person zu belästigen oder zu bedrohen (im
						rechtlichen Sinne dieser Begriffe) und um Gewalt gegen eine bestimmte Person oder eine Gruppe
						von Personen zu fördern.
						Ihre Beiträge verstoßen nicht gegen geltende Gesetze, Vorschriften oder Regeln.
						Ihre Beiträge verletzen nicht die Datenschutz- oder Publizitätsrechte eines Dritten.
						Ihre Beiträge enthalten kein Material, das persönliche Informationen von Personen unter 18
						Jahren erfragt oder Personen unter 18 Jahren in sexueller oder gewalttätiger Weise ausbeutet.
						Ihre Beiträge verstoßen nicht gegen geltendes Recht in Bezug auf Kinderpornografie oder andere
						Maßnahmen zum Schutz der Gesundheit oder des Wohlergehens von Minderjährigen.
						Ihre Beiträge enthalten keine beleidigenden Kommentare, die sich auf Rasse, nationale Herkunft,
						Geschlecht, sexuelle Vorlieben oder körperliche Behinderungen beziehen.
						Ihre Beiträge verletzen nicht anderweitig eine Bestimmung dieser Nutzungsbedingungen oder ein
						anwendbares Gesetz oder eine Vorschrift und verlinken nicht auf Material, das gegen diese
						verstößt.
						Hochladen, Übertragen oder Verteilen von Software auf oder über die Website, die dazu bestimmt
						ist, ein Computersystem oder Daten zu beschädigen oder zu verändern.
						Die Website zu nutzen, um Informationen oder Daten über andere Benutzer ohne deren Zustimmung zu
						sammeln, zu erfassen oder zusammenzustellen.
						Server oder Netzwerke, die mit der Website verbunden sind, zu stören, zu unterbrechen oder
						unangemessen zu belasten oder die Vorschriften, Richtlinien oder Verfahren solcher Netzwerke zu
						verletzen
						Der Versuch, sich unbefugten Zugang zur Website zu verschaffen, sei es durch Passwort-Mining
						oder andere Mittel.
						Andere Benutzer zu belästigen oder deren Nutzung der Website zu stören.
						Software oder automatisierte Agenten oder Skripte verwenden, um mehrere Konten auf der Website
						zu erstellen oder um automatisierte Suchvorgänge, Anfragen oder Abfragen auf der Website zu
						generieren.
						Jegliche Nutzung der Website, die gegen die vorstehenden Bestimmungen verstößt, verstößt gegen
						diese Nutzungsbedingungen und kann unter anderem zur Kündigung oder Aussetzung Ihrer Rechte zur
						Nutzung der Website führen.

						Wir behalten uns das Recht vor, alle Benutzerinhalte zu überprüfen und zu untersuchen und/oder
						nach unserem alleinigen Ermessen geeignete Maßnahmen gegen Sie zu ergreifen, wenn Sie gegen die
						Benutzervereinbarung oder eine andere Bestimmung dieser Bedingungen verstoßen oder uns oder
						anderen Personen auf andere Weise eine Haftung auferlegen. Zu diesen Maßnahmen kann es gehören,
						Ihre Benutzerinhalte zu entfernen oder zu ändern, Ihr Konto zu kündigen und/oder Sie den
						Strafverfolgungsbehörden zu melden.

						Indem Sie Ihre Beiträge auf einem beliebigen Teil der Website veröffentlichen oder Beiträge auf
						der Website zugänglich machen, indem Sie Ihr Konto von der Website mit einem Ihrer Konten in
						sozialen Netzwerken verknüpfen, gewähren Sie uns automatisch ein uneingeschränktes,
						unbegrenztes, unwiderrufliches, unbefristetes, nicht ausschließliches, übertragbares,
						gebührenfreies, voll bezahltes, weltweites Recht und die Lizenz, die Beiträge zu hosten, zu
						verwenden, zu kopieren, zu vervielfältigen, offenzulegen, zu archivieren, zu speichern, zu
						übersetzen, zu übertragen, auszugsweise (ganz oder teilweise) darzustellen, umzugestalten, zu
						verkaufen, weiterzuverkaufen, zu veröffentlichen, auszustrahlen, neu zu veröffentlichen, zu
						archivieren, zu speichern, zu übersetzen, zu übertragen, zu übermitteln und zu veröffentlichen,
						zu verkaufen, weiterzuverkaufen, zu veröffentlichen, auszustrahlen, neu zu betiteln, zu
						archivieren, zu speichern, zu zwischenspeichern, öffentlich vorzuführen, öffentlich
						auszustellen, umzuformatieren, zu übersetzen, zu übertragen, (ganz oder teilweise) zu
						exzerpieren und solche Beiträge (einschließlich, aber nicht beschränkt auf Ihr Bild und Ihre
						Stimme) für jeden kommerziellen, werbetechnischen oder sonstigen Zweck zu verbreiten und davon
						abgeleitete Werke zu erstellen oder solche Beiträge in andere Werke einzubeziehen sowie
						Unterlizenzen für das Vorstehende zu gewähren und zu genehmigen. Die Nutzung und Verbreitung
						kann in allen Medienformaten und über alle Medienkanäle erfolgen.

						Diese Lizenz gilt für jede Form, jedes Medium und jede Technologie, die heute bekannt ist oder
						in Zukunft entwickelt wird, und schließt die Verwendung Ihres Namens, Firmennamens und
						Franchisenamens sowie aller Marken, Dienstleistungsmarken, Handelsnamen, Logos und persönlichen
						und kommerziellen Bilder, die Sie zur Verfügung stellen, durch uns ein. Sie verzichten auf alle
						Urheberpersönlichkeitsrechte an Ihren Beiträgen und garantieren, dass die
						Urheberpersönlichkeitsrechte an Ihren Beiträgen nicht anderweitig geltend gemacht worden sind.

						Wir machen keine Eigentumsrechte an Ihren Beiträgen geltend. Sie behalten das volle Eigentum an
						allen Ihren Beiträgen und allen geistigen Eigentumsrechten oder anderen Eigentumsrechten, die
						mit Ihren Beiträgen verbunden sind. Wir sind nicht haftbar für Aussagen oder Darstellungen in
						Ihren Beiträgen, die Sie in irgendeinem Bereich der Website zur Verfügung stellen. Sie tragen
						die alleinige Verantwortung für Ihre Beiträge auf der Website und erklären sich ausdrücklich
						damit einverstanden, uns von jeglicher Verantwortung freizustellen und von jeglichen rechtlichen
						Schritten gegen uns in Bezug auf Ihre Beiträge abzusehen.

						Wir haben das Recht, in unserem alleinigen und absoluten Ermessen,

						Die Erstellung, der Vertrieb, die Übertragung, die öffentliche Darstellung oder Aufführung sowie
						der Zugriff, das Herunterladen oder das Kopieren Ihrer Beiträge verletzen nicht die
						Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken,
						Geschäftsgeheimnisse oder moralische Rechte Dritter.
						Sie sind der Urheber und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte,
						Zustimmungen, Freigaben und Erlaubnisse, um uns, die Website und andere Nutzer der Website zu
						ermächtigen, Ihre Beiträge in der auf der Website und in diesen Nutzungsbedingungen vorgesehenen
						Weise zu nutzen.
						Sie haben die schriftliche Zustimmung, Freigabe und/oder Erlaubnis jeder einzelnen
						identifizierbaren Person in Ihren Beiträgen, den Namen oder das Bildnis jeder einzelnen
						identifizierbaren Person zu verwenden, um die Aufnahme und Verwendung Ihrer Beiträge auf jede
						von der Website und diesen Nutzungsbedingungen vorgesehene Weise zu ermöglichen.
						Wir können Ihnen auf der Website Bereiche zur Verfügung stellen, in denen Sie Rezensionen oder
						Bewertungen abgeben können. Wenn Sie eine Bewertung abgeben, müssen Sie die folgenden Kriterien
						einhalten:

						Die Erstellung, der Vertrieb, die Übertragung, die öffentliche Darstellung oder Aufführung sowie
						der Zugriff, das Herunterladen oder das Kopieren Ihrer Beiträge verletzen nicht die
						Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken,
						Geschäftsgeheimnisse oder moralische Rechte Dritter.
						Sie sind der Urheber und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte,
						Zustimmungen, Freigaben und Erlaubnisse, um uns, die Website und andere Nutzer der Website zu
						ermächtigen, Ihre Beiträge in der auf der Website und in diesen Nutzungsbedingungen vorgesehenen
						Weise zu nutzen.
						Sie haben die schriftliche Zustimmung, Freigabe und/oder Erlaubnis jeder einzelnen
						identifizierbaren Person in Ihren Beiträgen, den Namen oder das Bildnis jeder einzelnen
						identifizierbaren Person zu verwenden, um die Aufnahme und Verwendung Ihrer Beiträge auf jede
						von der Website und diesen Nutzungsbedingungen vorgesehene Weise zu ermöglichen.
						Ihre Beiträge sind nicht falsch, ungenau oder irreführend.
						Ihre Beiträge sind keine unaufgeforderte oder unerlaubte Werbung, Werbematerialien,
						Schneeballsysteme, Kettenbriefe, Spam, Massenmailings oder andere Formen der Aufforderung.
						Ihre Beiträge sind nicht obszön, unzüchtig, lasziv, schmutzig, gewalttätig, belästigend,
						beleidigend, verleumderisch oder anderweitig anstößig (wie von uns bestimmt).
						Ihre Beiträge machen niemanden lächerlich, verhöhnen, verunglimpfen, einschüchtern oder
						beleidigen.
						Ihre Beiträge werden nicht verwendet, um eine andere Person zu belästigen oder zu bedrohen (im
						rechtlichen Sinne dieser Begriffe) und um Gewalt gegen eine bestimmte Person oder eine Gruppe
						von Personen zu fördern.
						Wir können nach eigenem Ermessen Bewertungen annehmen, ablehnen oder entfernen. Wir sind in
						keiner Weise verpflichtet, Bewertungen zu prüfen oder zu löschen, selbst wenn jemand die
						Bewertungen für anstößig oder ungenau hält. Rezensionen werden nicht von uns unterstützt und
						geben nicht unbedingt unsere Meinung oder die Meinung einer unserer Tochtergesellschaften oder
						Partner wieder. Wir übernehmen keine Haftung für eine Bewertung oder für Ansprüche,
						Verbindlichkeiten oder Verluste, die sich aus einer Bewertung ergeben. Indem Sie eine Bewertung
						veröffentlichen, gewähren Sie uns ein unbefristetes, nicht-exklusives, weltweites,
						gebührenfreies, voll bezahltes, abtretbares und unterlizenzierbares Recht und die Lizenz, alle
						Inhalte im Zusammenhang mit Bewertungen zu vervielfältigen, zu modifizieren, zu übersetzen, auf
						beliebige Weise zu übertragen, anzuzeigen, auszuführen und/oder zu verbreiten.

						Als Teil der Funktionalität der Website können Sie Ihr Konto mit Online-Konten verknüpfen, die
						Sie bei Drittanbietern haben (jedes dieser Konten ist ein Drittanbieterkonto), indem Sie
						entweder:

						Die Erstellung, der Vertrieb, die Übertragung, die öffentliche Darstellung oder Aufführung sowie
						der Zugriff, das Herunterladen oder das Kopieren Ihrer Beiträge verletzen nicht die
						Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken,
						Geschäftsgeheimnisse oder moralische Rechte Dritter.
						Sie sind der Urheber und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte,
						Zustimmungen, Freigaben und Erlaubnisse, um uns, die Website und andere Nutzer der Website zu
						ermächtigen, Ihre Beiträge in der auf der Website und in diesen Nutzungsbedingungen vorgesehenen
						Weise zu nutzen.
						Indem Sie uns Zugang zu Konten von Drittanbietern gewähren, verstehen Sie, dass

						Die Erstellung, der Vertrieb, die Übertragung, die öffentliche Darstellung oder Aufführung sowie
						der Zugriff, das Herunterladen oder das Kopieren Ihrer Beiträge verletzen nicht die
						Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken,
						Geschäftsgeheimnisse oder moralische Rechte Dritter.
						Sie sind der Urheber und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte,
						Zustimmungen, Freigaben und Erlaubnisse, um uns, die Website und andere Nutzer der Website zu
						ermächtigen, Ihre Beiträge in der auf der Website und in diesen Nutzungsbedingungen vorgesehenen
						Weise zu nutzen.
						Je nach den von Ihnen gewählten Drittanbieterkonten und vorbehaltlich der
						Datenschutzeinstellungen, die Sie in diesen Drittanbieterkonten festgelegt haben, können
						personenbezogene Daten, die Sie in Ihren Drittanbieterkonten veröffentlichen, auf und über Ihr
						Konto auf der Website verfügbar sein. Bitte beachten Sie, dass, wenn ein Drittanbieterkonto oder
						ein zugehöriger Dienst nicht mehr verfügbar ist oder unser Zugang zu einem solchen
						Drittanbieterkonto vom Drittanbieter gekündigt wird, die Inhalte des sozialen Netzwerks
						möglicherweise nicht mehr auf der Website und über die Website verfügbar sind. Sie haben die
						Möglichkeit, die Verbindung zwischen Ihrem Konto auf der Website und Ihren Konten bei
						Drittanbietern jederzeit zu deaktivieren. BITTE BEACHTEN SIE, DASS IHRE BEZIEHUNG ZU DEN
						DRITTANBIETERN, DIE MIT IHREN KONTEN BEI DRITTEN VERBUNDEN SIND, AUSSCHLIESSLICH DURCH IHRE
						VEREINBARUNG(EN) MIT DIESEN DRITTANBIETERN GEREGELT WIRD. Wir bemühen uns nicht, die Inhalte
						sozialer Netzwerke zu überprüfen, insbesondere auf Richtigkeit, Rechtmäßigkeit oder
						Nichtverletzung von Rechten, und wir sind nicht für die Inhalte sozialer Netzwerke
						verantwortlich. Sie erkennen an und erklären sich damit einverstanden, dass wir auf Ihr
						E-Mail-Adressbuch, das mit einem Drittanbieter-Konto verknüpft ist, und auf Ihre Kontaktliste,
						die auf Ihrem Mobilgerät oder Tablet-Computer gespeichert ist, ausschließlich zu dem Zweck
						zugreifen dürfen, um Sie über die Kontakte, die sich ebenfalls für die Nutzung der Website
						registriert haben, zu informieren. Sie können die Verbindung zwischen der Website und Ihrem
						Konto eines Drittanbieters deaktivieren, indem Sie uns über die unten stehenden
						Kontaktinformationen oder über Ihre Kontoeinstellungen (falls zutreffend) kontaktieren. Wir
						werden versuchen, alle auf unseren Servern gespeicherten Informationen zu löschen, die über ein
						solches Drittanbieterkonto erlangt wurden, mit Ausnahme des Benutzernamens und des Profilbildes,
						die mit Ihrem Konto verknüpft werden.

						Sie erkennen an und erklären sich damit einverstanden, dass alle Fragen, Kommentare, Vorschläge,
						Ideen, Rückmeldungen oder andere Informationen bezüglich der Website ("Beiträge"), die Sie uns
						zur Verfügung stellen, nicht vertraulich sind und in unser alleiniges Eigentum übergehen. Wir
						verfügen über die ausschließlichen Rechte, einschließlich aller Rechte am geistigen Eigentum,
						und sind zur uneingeschränkten Nutzung und Verbreitung dieser Beiträge für jeden rechtmäßigen
						Zweck, ob kommerziell oder anderweitig, berechtigt, ohne dass Sie dafür eine Anerkennung oder
						Vergütung erhalten. Sie verzichten hiermit auf alle moralischen Rechte an solchen Einsendungen,
						und Sie garantieren hiermit, dass solche Einsendungen von Ihnen stammen oder dass Sie das Recht
						haben, solche Einsendungen einzureichen. Sie erklären sich damit einverstanden, dass gegen uns
						kein Regressanspruch wegen einer angeblichen oder tatsächlichen Verletzung oder widerrechtlichen
						Aneignung von Eigentumsrechten an Ihren Einsendungen besteht.

						Websites und Inhalte von Dritten
						Die Website kann Links zu anderen Websites ("Websites Dritter") sowie Artikel, Fotografien,
						Texte, Grafiken, Bilder, Designs, Musik, Töne, Videos, Informationen, Anwendungen, Software und
						andere Inhalte oder Gegenstände enthalten (oder Ihnen über die Website zugesandt werden), die
						Dritten gehören oder von ihnen stammen ('Inhalte Dritter'). Solche Websites Dritter und Inhalte
						Dritter werden von uns nicht untersucht, überwacht oder auf Richtigkeit, Angemessenheit oder
						Vollständigkeit geprüft, und wir sind nicht verantwortlich für Websites Dritter, auf die über
						die Website zugegriffen wird, oder für Inhalte Dritter, die auf der Website veröffentlicht
						werden, über sie verfügbar sind oder von ihr installiert werden, einschließlich des Inhalts, der
						Richtigkeit, der Beleidigung, der Meinungen, der Zuverlässigkeit, der Datenschutzpraktiken oder
						anderer Richtlinien, die auf den Websites Dritter oder in den Inhalten Dritter enthalten sind.
						Die Einbeziehung von, die Verlinkung zu oder die Erlaubnis zur Nutzung oder Installation von
						Websites Dritter oder von Inhalten Dritter bedeutet nicht, dass wir diese genehmigen oder
						unterstützen. Wenn Sie sich entscheiden, die Website zu verlassen und auf die Websites Dritter
						zuzugreifen oder Inhalte Dritter zu nutzen oder zu installieren, tun Sie dies auf eigenes Risiko
						und sollten sich bewusst sein, dass diese Nutzungsbedingungen nicht mehr gelten. Sie sollten die
						geltenden Bedingungen und Richtlinien, einschließlich der Datenschutz- und
						Datenerfassungspraktiken, jeder Website prüfen, zu der Sie von der Website aus navigieren oder
						die sich auf Anwendungen bezieht, die Sie von der Website aus nutzen oder installieren. Alle
						Käufe, die Sie über Websites von Dritten tätigen, erfolgen über andere Websites und von anderen
						Unternehmen, und wir übernehmen keinerlei Verantwortung in Bezug auf solche Käufe, die
						ausschließlich zwischen Ihnen und dem betreffenden Dritten erfolgen. Sie erklären sich damit
						einverstanden und erkennen an, dass wir die auf den Websites Dritter angebotenen Produkte oder
						Dienstleistungen nicht befürworten, und Sie halten uns von jeglichem Schaden frei, der durch den
						Kauf solcher Produkte oder Dienstleistungen entsteht. Darüber hinaus stellen Sie uns von
						jeglichen Verlusten frei, die Sie erleiden, oder von Schäden, die Ihnen im Zusammenhang mit
						Inhalten von Drittanbietern oder durch den Kontakt mit Websites von Drittanbietern entstehen.

						Wir gestatten Werbetreibenden, ihre Werbung und andere Informationen in bestimmten Bereichen der
						Website anzuzeigen, z. B. in der Seitenleiste oder in Werbebannern. Wenn Sie ein Werbetreibender
						sind, übernehmen Sie die volle Verantwortung für alle von Ihnen auf der Website platzierten
						Anzeigen und alle auf der Website angebotenen Dienstleistungen oder über diese Anzeigen
						verkauften Produkte. Als Werbetreibender sichern Sie außerdem zu, dass Sie alle Rechte und
						Befugnisse besitzen, um auf der Website Werbung zu schalten, einschließlich, aber nicht
						beschränkt auf geistige Eigentumsrechte, Werberechte und vertragliche Rechte. Wir stellen
						lediglich den Platz für die Schaltung solcher Anzeigen zur Verfügung und stehen in keiner
						anderen Beziehung zu den Werbetreibenden.

						Wir gestatten Werbetreibenden, ihre Werbung und andere Informationen in bestimmten Bereichen der
						Website anzuzeigen, z. B. in der Seitenleiste oder in Werbebannern. Wenn Sie ein Werbetreibender
						sind, übernehmen Sie die volle Verantwortung für alle von Ihnen auf der Website platzierten
						Anzeigen und alle auf der Website angebotenen Dienstleistungen oder über diese Anzeigen
						verkauften Produkte. Als Werbetreibender sichern Sie außerdem zu, dass Sie alle Rechte und
						Befugnisse besitzen, um auf der Website Werbung zu schalten, einschließlich, aber nicht
						beschränkt auf geistige Eigentumsrechte, Werberechte und vertragliche Rechte. Wir stellen
						lediglich den Platz für die Schaltung solcher Anzeigen zur Verfügung und stehen in keiner
						anderen Beziehung zu den Werbetreibenden.

						Bei unseren Dienstleistungen handelt es sich um "commercial items" bzw. "Handelsartikel", wie in
						der Federal Acquisition Regulation ("FAR") 2.101 definiert. Wenn unsere Dienstleistungen von
						oder im Namen einer Behörde erworben werden, die nicht dem Verteidigungsministerium angehört
						("DOD"), unterliegen unsere Dienstleistungen den Bedingungen dieser Nutzungsbedingungen gemäß
						FAR 12.212 (für Computersoftware) und FAR 12.211 (für technische Daten). Wenn unsere
						Dienstleistungen von oder im Namen einer Behörde des Verteidigungsministeriums erworben werden,
						unterliegen unsere Dienstleistungen den Bedingungen dieser Nutzungsbedingungen in
						Übereinstimmung mit der Defense Federal Acquisition Regulation ("DFARS") 227.7202\u20113.
						Darüber hinaus gilt DFARS 252.227\u20117015 für technische Daten, die vom
						Verteidigungsministerium erworben wurden. Diese Klausel über die Rechte der US-Regierung tritt
						an die Stelle aller anderen FAR-, DFARS- oder sonstigen Klauseln oder Bestimmungen, die sich mit
						den Rechten der Regierung an Computersoftware oder technischen Daten im Rahmen dieser
						Nutzungsbedingungen befassen, und hat Vorrang vor diesen.

						Datenschutzrichtlinie
						Datenschutz und Datensicherheit sind uns wichtig. Bitte lesen Sie unsere Datenschutzrichtlinie.
						Durch die Nutzung der Website erklären Sie sich mit unserer Datenschutzrichtlinie einverstanden,
						die Bestandteil dieser Nutzungsbedingungen ist. Bitte beachten Sie, dass die Website in
						Deutschland gehostet wird. Wenn Sie auf die Website von einer anderen Region der Welt aus
						zugreifen, in der andere Gesetze oder andere Vorschriften für die Erhebung, Verwendung oder
						Weitergabe personenbezogener Daten gelten als in Deutschland, übertragen Sie durch die
						fortgesetzte Nutzung der Website Ihre Daten nach Deutschland und erklären sich damit
						einverstanden, dass Ihre Daten nach Deutschland übertragen und dort verarbeitet werden. Darüber
						hinaus nehmen wir nicht wissentlich Informationen von Kindern an, fordern sie an oder bitten sie
						um Informationen, und wir werben auch nicht wissentlich bei Kindern. Daher werden wir in
						Übereinstimmung mit dem U.S. Children'\s Online Privacy Protection Act diese Daten so schnell
						wie möglich von der Website löschen, wenn wir tatsächliche Kenntnis davon erhalten, dass eine
						Person unter 13 Jahren uns persönliche Daten ohne die erforderliche und überprüfbare elterliche
						Zustimmung zur Verfügung gestellt hat.

						Copyright-Verletzungen
						Wir respektieren die geistigen Eigentumsrechte anderer. Wenn Sie der Meinung sind, dass
						Material, das auf oder über die Website verfügbar ist, gegen ein Urheberrecht verstößt, das Sie
						besitzen oder kontrollieren, benachrichtigen Sie uns bitte unverzüglich über die unten
						angegebenen Kontaktinformationen (eine Meldung). Eine Kopie Ihrer Meldung wird an die Person
						gesandt, die das in der Meldung genannte Material eingestellt oder gespeichert hat.

						Wenn Sie der Meinung sind, dass einer unserer Nutzer durch die Nutzung unserer Website
						rechtswidrig gegen das/die Urheberrecht(e) eines Werks verstößt, und Sie möchten, dass das
						angeblich rechtsverletzende Material entfernt wird, müssen die folgenden Informationen in Form
						einer schriftlichen Benachrichtigung (gemäß 17 U.S.C. 512(c)) an den von uns benannten
						Urheberrechtsbeauftragten übermittelt werden:

						Ihre physische oder elektronische Unterschrift;
						Identifizierung der urheberrechtlich geschützten Werke, die Ihrer Ansicht nach verletzt wurden;
						Identifizierung des Materials in unseren Diensten, das Ihrer Ansicht nach gegen das Urheberrecht
						verstößt und das Sie von uns entfernen lassen möchten;
						ausreichende Informationen, die es uns ermöglichen, dieses Material zu lokalisieren;
						Ihre Adresse, Telefonnummer und E-Mail-Adresse;
						eine Erklärung, dass Sie in gutem Glauben davon ausgehen, dass die Verwendung des beanstandeten
						Materials nicht durch den Urheberrechtsinhaber, seinen Vertreter oder durch das Gesetz genehmigt
						ist;
						eine Erklärung, dass die Informationen in der Benachrichtigung korrekt sind, und unter Androhung
						von Strafe an Eides statt, dass Sie entweder der Inhaber des Urheberrechts sind, das angeblich
						verletzt wurde, oder dass Sie befugt sind, im Namen des Urheberrechtsinhabers zu handeln.
						Wenn Sie der Meinung sind, dass einer unserer Nutzer durch die Nutzung unserer Website
						rechtswidrig gegen das/die Urheberrecht(e) eines Werks verstößt, und Sie möchten, dass das
						angeblich rechtsverletzende Material entfernt wird, müssen die folgenden Informationen in Form
						einer schriftlichen Benachrichtigung (gemäß 17 U.S.C. 512(c)) an den von uns benannten
						Urheberrechtsbeauftragten übermittelt werden:

						Bitte beachten Sie, dass gemäß 17 U.S.C. § 512(f) jede falsche Darstellung wesentlicher
						Tatsachen in einer schriftlichen Benachrichtigung die beschwerdeführende Partei automatisch der
						Haftung für alle Schäden, Kosten und Anwaltsgebühren unterwirft, die uns im Zusammenhang mit der
						schriftlichen Benachrichtigung und der Behauptung einer Urheberrechtsverletzung entstanden sind.
						Wenn Sie also nicht sicher sind, dass Material, das sich auf der Website befindet oder mit der
						Website verlinkt ist, Ihr Urheberrecht verletzt, sollten Sie sich zunächst an einen Anwalt
						wenden.

						Diese Nutzungsbedingungen bleiben in vollem Umfang in Kraft, solange Sie die Website nutzen.
						OHNE EINSCHRÄNKUNG ANDERER BESTIMMUNGEN DIESER NUTZUNGSBEDINGUNGEN BEHALTEN WIR UNS DAS RECHT
						VOR, NACH UNSEREM ALLEINIGEN ERMESSEN UND OHNE ANKÜNDIGUNG ODER HAFTUNG DEN ZUGANG ZUR UND DIE
						NUTZUNG DER SITE ZU VERWEIGERN (EINSCHLIESSLICH DER SPERRUNG BESTIMMTER IP-ADRESSEN), UND ZWAR
						FÜR JEDE PERSON, AUS WELCHEM GRUND AUCH IMMER, EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF DIE
						VERLETZUNG VON ZUSICHERUNGEN, GARANTIEN ODER VERPFLICHTUNGEN, DIE IN DIESEN NUTZUNGSBEDINGUNGEN
						ENTHALTEN SIND, ODER VON GELTENDEN GESETZEN ODER VORSCHRIFTEN. WIR KÖNNEN IHRE NUTZUNG ODER
						TEILNAHME AN DER SITE BEENDEN ODER IHR KONTO UND ALLE INHALTE ODER INFORMATIONEN, DIE SIE
						VERÖFFENTLICHT HABEN, JEDERZEIT UND OHNE VORWARNUNG NACH UNSEREM ERMESSEN LÖSCHEN.

						Modifikationen und Unterbrechungen
						Wir behalten uns das Recht vor, den Inhalt der Website jederzeit und ohne Angabe von Gründen
						nach eigenem Ermessen und ohne vorherige Ankündigung zu ändern, zu modifizieren oder zu
						entfernen. Wir sind jedoch nicht verpflichtet, Informationen auf unserer Website zu
						aktualisieren. Wir behalten uns außerdem das Recht vor, die Website jederzeit und ohne
						Vorankündigung ganz oder teilweise zu ändern oder einzustellen. Wir haften weder Ihnen noch
						Dritten gegenüber für Änderungen, Preisänderungen, Aussetzungen oder die Einstellung der
						Website.

						Wir können nicht garantieren, dass die Website jederzeit verfügbar sein wird. Es können
						Hardware-, Software- oder andere Probleme auftreten oder wir müssen Wartungsarbeiten im
						Zusammenhang mit der Website durchführen, was zu Unterbrechungen, Verzögerungen oder Fehlern
						führen kann. Wir behalten uns das Recht vor, die Website jederzeit und ohne Angabe von Gründen
						zu ändern, zu überarbeiten, zu aktualisieren, auszusetzen, einzustellen oder anderweitig zu
						modifizieren, ohne Sie darüber zu informieren. Sie erklären sich damit einverstanden, dass wir
						keinerlei Haftung für Verluste, Schäden oder Unannehmlichkeiten übernehmen, die dadurch
						entstehen, dass Sie während einer Ausfallzeit oder Einstellung der Website nicht auf die Website
						zugreifen oder sie nutzen können. Nichts in diesen Nutzungsbedingungen ist so auszulegen, dass
						wir verpflichtet sind, die Website zu warten und zu unterstützen oder Korrekturen,
						Aktualisierungen oder Veröffentlichungen in Verbindung damit bereitzustellen.

						Diese Bedingungen unterliegen österreichischem Recht und werden nach diesem ausgelegt, wobei die
						Anwendung des Übereinkommens der Vereinten Nationen über Verträge über den internationalen
						Warenkauf ausdrücklich ausgeschlossen wird. Wenn Sie Ihren gewöhnlichen Aufenthalt in der EU
						haben und Verbraucher sind, genießen Sie zusätzlich den Schutz, der Ihnen durch die zwingenden
						Bestimmungen des Rechts Ihres Wohnsitzlandes gewährt wird. MobiPoints und Sie unterwerfen sich
						der nicht ausschließlichen Zuständigkeit der steirischen Gerichte, was bedeutet, dass Sie Ihre
						Verbraucherschutzrechte in Bezug auf diese Nutzungsbedingungen in Österreich oder in dem
						EU-Land, in dem Sie Ihren Wohnsitz haben, geltend machen können.

						Alle Streitigkeiten, die sich aus den Beziehungen zwischen den Vertragsparteien ergeben, werden
						von einem Schiedsrichter entschieden, der gemäß der Schiedsgerichtsordnung und der Internen
						Schiedsgerichtsordnung des Europäischen Schiedsgerichtshofs, der Teil des Europäischen Zentrums
						für Schiedsgerichtsbarkeit mit Sitz in Straßburg ist, in der zum Zeitpunkt der Einreichung des
						Antrags auf ein Schiedsverfahren geltenden Fassung ausgewählt wird, und dessen Annahme dieser
						Klausel eine Annahme darstellt. Der Sitz des Schiedsgerichts ist Graz, Österreich. Die
						Verfahrenssprache ist Deutsch. Anwendbares materielles Recht ist das Recht Österreichs.

						Beschränkungen
						Die Parteien vereinbaren, dass jedes Schiedsverfahren auf die Streitigkeit zwischen den
						einzelnen Parteien beschränkt sein soll. Soweit gesetzlich zulässig, (a) darf kein
						Schiedsverfahren mit einem anderen Verfahren verbunden werden; (b) besteht kein Recht und keine
						Befugnis, eine Streitigkeit auf der Grundlage einer Sammelklage zu schlichten oder ein
						Sammelklageverfahren anzuwenden; und (c) besteht kein Recht und keine Befugnis, eine
						Streitigkeit in einer angeblich repräsentativen Eigenschaft im Namen der Allgemeinheit oder
						anderer Personen vorzubringen,

						Ausnahmen von der Schiedsgerichtsbarkeit
						Die Parteien vereinbaren, dass die folgenden Streitigkeiten nicht den oben genannten
						Bestimmungen über ein verbindliches Schiedsverfahren unterliegen: (a) alle Streitigkeiten, die
						die Durchsetzung oder den Schutz oder die Gültigkeit von Rechten des geistigen Eigentums einer
						Partei zum Gegenstand haben; (b) alle Streitigkeiten, die mit dem Vorwurf des Diebstahls, der
						Piraterie, der Verletzung der Privatsphäre oder der unbefugten Nutzung zusammenhängen oder sich
						daraus ergeben; und (c) alle Ansprüche auf Unterlassungsklagen. Sollte sich diese Bestimmung als
						rechtswidrig oder nicht durchsetzbar erweisen, wird sich keine der Parteien dafür entscheiden,
						eine Streitigkeit zu schlichten, die unter den Teil dieser Bestimmung fällt, der sich als
						rechtswidrig oder nicht durchsetzbar erweist, und eine solche Streitigkeit wird von einem
						zuständigen Gericht innerhalb der oben aufgeführten Gerichtsbarkeiten entschieden, und die
						Parteien erklären sich damit einverstanden, sich der persönlichen Zuständigkeit dieses Gerichts
						zu unterwerfen.

						Berichtigungen
						Es kann Informationen auf der Website geben, die Druckfehler, Ungenauigkeiten oder Auslassungen
						enthalten, einschließlich Beschreibungen, Preise, Verfügbarkeit und verschiedene andere
						Informationen. Wir behalten uns das Recht vor, jegliche Fehler, Ungenauigkeiten oder
						Auslassungen zu korrigieren und die Informationen auf der Website jederzeit und ohne vorherige
						Ankündigung zu ändern oder zu aktualisieren.

						Disclaimer
						DIE SEITE WIRD AUF EINER AS-IS UND AS-AVAILABLE BASIS BEREITGESTELLT. SIE STIMMEN ZU, DASS DIE
						NUTZUNG DER WEBSITE UND UNSERER DIENSTE AUF IHR EIGENES RISIKO ERFOLGT. IM GRÖSSTMÖGLICHEN
						GESETZLICH ZULÄSSIGEN UMFANG LEHNEN WIR ALLE AUSDRÜCKLICHEN ODER STILLSCHWEIGENDEN GARANTIEN IN
						VERBINDUNG MIT DER WEBSITE UND IHRER NUTZUNG AB, EINSCHLIESSLICH UND OHNE EINSCHRÄNKUNG DER
						STILLSCHWEIGENDEN GARANTIEN DER MARKTGÄNGIGKEIT, DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK UND DER
						NICHTVERLETZUNG VON RECHTEN. WIR ÜBERNEHMEN KEINE GEWÄHRLEISTUNGEN ODER ZUSICHERUNGEN ÜBER DIE
						GENAUIGKEIT ODER VOLLSTÄNDIGKEIT DER INHALTE DER WEBSITE ODER DER INHALTE VON WEBSITES, DIE MIT
						DER WEBSITE VERLINKT SIND, UND WIR ÜBERNEHMEN KEINE HAFTUNG ODER VERANTWORTUNG FÜR JEDE

						ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
						PERSONEN- ODER SACHSCHÄDEN JEGLICHER ART, DIE SICH AUS IHREM ZUGRIFF AUF DIE WEBSITE UND DEREN
						NUTZUNG ERGEBEN,
						JEGLICHER UNBEFUGTER ZUGRIFF AUF ODER DIE NUTZUNG UNSERER SICHEREN SERVER UND/ODER ALLER DARIN
						GESPEICHERTEN PERSÖNLICHEN DATEN UND/ODER FINANZDATEN,
						JEGLICHE UNTERBRECHUNG ODER BEENDIGUNG DER ÜBERTRAGUNG ZU ODER VON DER WEBSITE,
						JEGLICHE BUGS, VIREN, TROJANISCHE PFERDE ODER ÄHNLICHES, DIE VON DRITTEN AUF DIE ODER ÜBER DIE
						WEBSITE ÜBERTRAGEN WERDEN KÖNNEN, UND/ODER
						FÜR JEGLICHE FEHLER ODER AUSLASSUNGEN IN INHALTEN UND MATERIALIEN ODER FÜR VERLUSTE ODER SCHÄDEN
						JEGLICHER ART, DIE DURCH DIE NUTZUNG VON INHALTEN ENTSTEHEN, DIE AUF DER WEBSITE VERÖFFENTLICHT,
						ÜBERTRAGEN ODER ANDERWEITIG ZUR VERFÜGUNG GESTELLT WERDEN.
						WIR ÜBERNEHMEN KEINE GEWÄHRLEISTUNG, UNTERSTÜTZUNG, GARANTIE ODER VERANTWORTUNG FÜR PRODUKTE
						ODER DIENSTLEISTUNGEN, DIE VON DRITTEN ÜBER DIE WEBSITE, EINE VERLINKTE WEBSITE ODER EINE IN
						EINEM BANNER ODER EINER ANDEREN WERBUNG DARGESTELLTE WEBSITE ODER MOBILE ANWENDUNG BEWORBEN ODER
						ANGEBOTEN WERDEN, UND WIR SIND WEDER EINE PARTEI NOCH IN IRGENDEINER WEISE FÜR DIE ÜBERWACHUNG
						VON TRANSAKTIONEN ZWISCHEN IHNEN UND DRITTEN ANBIETERN VON PRODUKTEN ODER DIENSTLEISTUNGEN
						VERANTWORTLICH. WIE BEIM KAUF EINES PRODUKTS ODER EINER DIENSTLEISTUNG ÜBER EIN BELIEBIGES
						MEDIUM ODER IN EINER BELIEBIGEN UMGEBUNG SOLLTEN SIE IHR URTEIL NACH BESTEM WISSEN ABGEBEN UND
						GEGEBENENFALLS VORSICHT WALTEN LASSEN.

						Haftungsbeschränkungen
						IN KEINEM FALL HAFTEN WIR ODER UNSERE GESCHÄFTSFÜHRER, MITARBEITER ODER VERTRETER IHNEN ODER
						DRITTEN GEGENÜBER FÜR DIREKTE, INDIREKTE, FOLGE-, EXEMPLARISCHE, ZUFÄLLIGE, BESONDERE ODER
						STRAFENDE SCHÄDEN, EINSCHLIESSLICH ENTGANGENEN GEWINNS, ENTGANGENEN EINKOMMENS, DATENVERLUSTS
						ODER ANDERER SCHÄDEN, DIE SICH AUS IHRER NUTZUNG DER WEBSITE ERGEBEN, SELBST WENN WIR AUF DIE
						MÖGLICHKEIT SOLCHER SCHÄDEN HINGEWIESEN WURDEN. UNGEACHTET ALLER GEGENTEILIGEN ANGABEN IN DIESER
						VEREINBARUNG IST UNSERE HAFTUNG IHNEN GEGENÜBER, AUS WELCHEM GRUND AUCH IMMER UND UNABHÄNGIG VON
						DER FORM DER KLAGE, JEDERZEIT AUF DEN VON IHNEN AN UNS GEZAHLTEN BETRAG BESCHRÄNKT. BESTIMMTE
						US-STAATLICHE UND INTERNATIONALE GESETZE ERLAUBEN KEINE EINSCHRÄNKUNG DER STILLSCHWEIGENDEN
						GARANTIEN ODER DEN AUSSCHLUSS ODER DIE BESCHRÄNKUNG BESTIMMTER SCHÄDEN. WENN DIESE GESETZE AUF
						SIE ZUTREFFEN, GELTEN EINIGE ODER ALLE DER OBEN GENANNTEN HAFTUNGSAUSSCHLÜSSE ODER
						BESCHRÄNKUNGEN MÖGLICHERWEISE NICHT FÜR SIE, UND SIE HABEN MÖGLICHERWEISE ZUSÄTZLICHE RECHTE.

						Entschädigung
						Sie erklären sich damit einverstanden, uns zu verteidigen, zu entschädigen und schadlos zu
						halten, einschließlich unserer Tochtergesellschaften, verbundenen Unternehmen und aller unserer
						jeweiligen leitenden Angestellten, Vertreter, Partner und Mitarbeiter, von und gegen jeglichen
						Verlust, Schaden, Haftung, Anspruch oder Forderung, einschließlich angemessener Anwaltsgebühren
						und -kosten, die von einer dritten Partei aufgrund von oder in Folge von:

						Ihre Beiträge;
						der Nutzung der Website;
						Verstoß gegen diese Nutzungsbedingungen;
						jegliche Verletzung Ihrer Zusicherungen und Gewährleistungen, die in diesen Nutzungsbedingungen
						dargelegt sind;
						Ihre Verletzung der Rechte Dritter, einschließlich, aber nicht beschränkt auf geistige
						Eigentumsrechte; oder
						jede offenkundig schädigende Handlung gegenüber einem anderen Nutzer der Website, mit dem Sie
						über die Website verbunden sind.
						Ungeachtet des Vorstehenden behalten wir uns das Recht vor, auf Ihre Kosten die ausschließliche
						Verteidigung und Kontrolle über alle Angelegenheiten zu übernehmen, für die Sie uns entschädigen
						müssen, und Sie stimmen zu, auf Ihre Kosten mit unserer Verteidigung gegen solche Ansprüche zu
						kooperieren. Wir werden uns in angemessener Weise bemühen, Sie über solche Ansprüche, Klagen
						oder Verfahren, die Gegenstand dieser Entschädigung sind, zu informieren, sobald wir davon
						Kenntnis erlangen.

						Benutzerdaten
						Wir speichern bestimmte Daten, die Sie an die Website übertragen, um die Leistung der Website zu
						verwalten, sowie Daten, die sich auf Ihre Nutzung der Website beziehen. Obwohl wir regelmäßig
						routinemäßige Datensicherungen durchführen, sind Sie allein für alle Daten verantwortlich, die
						Sie übertragen oder die sich auf Aktivitäten beziehen, die Sie auf der Website durchgeführt
						haben. Sie erklären sich damit einverstanden, dass wir Ihnen gegenüber keine Haftung für den
						Verlust oder die Beschädigung solcher Daten übernehmen, und Sie verzichten hiermit auf jedes
						Klagerecht gegen uns, das sich aus einem solchen Verlust oder einer solchen Beschädigung solcher
						Daten ergibt.

						Kalifornische Benutzer und Einwohner
						Wenn eine Beschwerde bei uns nicht zufriedenstellend gelöst wird, können Sie sich schriftlich an
						die Complaint Assistance Unit of the Division of Consumer Services of the California Department
						of Consumer Affairs unter 1625 North Market Blvd, Suite N 112, Sacramento, California 95834 oder
						telefonisch unter (800) 952-5210 oder (916) 445-1254 wenden.

						GDPR
						Bitte werfen Sie einen Blick auf unsere Datenschutzrichtlinie, um Ihre Rechte und die Verwendung
						Ihrer Daten zu verstehen.

						Elektronische Kommunikation, Transaktionen und Unterschriften
						Der Besuch der Website, das Senden von E-Mails an uns und das Ausfüllen von Online-Formularen
						stellen elektronische Mitteilungen dar. Sie erklären sich damit einverstanden, elektronische
						Mitteilungen zu erhalten, und Sie stimmen zu, dass alle Vereinbarungen, Mitteilungen,
						Offenlegungen und andere Mitteilungen, die wir Ihnen elektronisch, per E-Mail und auf der
						Website zur Verfügung stellen, alle gesetzlichen Anforderungen an die Schriftform solcher
						Mitteilungen erfüllen. SIE STIMMEN HIERMIT DER VERWENDUNG VON ELEKTRONISCHEN UNTERSCHRIFTEN,
						VERTRÄGEN, BESTELLUNGEN UND ANDEREN AUFZEICHNUNGEN SOWIE DER ELEKTRONISCHEN ZUSTELLUNG VON
						BENACHRICHTIGUNGEN, RICHTLINIEN UND AUFZEICHNUNGEN VON TRANSAKTIONEN ZU, DIE VON UNS ODER ÜBER
						DIE SITE EINGELEITET ODER ABGESCHLOSSEN WURDEN. Sie verzichten hiermit auf alle Rechte oder
						Anforderungen, die sich aus Gesetzen, Vorschriften, Regeln, Verordnungen oder anderen Gesetzen
						in irgendeiner Gerichtsbarkeit ergeben, die eine Originalunterschrift oder die Zustellung oder
						Aufbewahrung von nicht elektronischen Aufzeichnungen erfordern, oder auf Zahlungen oder die
						Gewährung von Krediten mit anderen als elektronischen Mitteln.

						Verschiedenes
						Diese Nutzungsbedingungen und alle von uns auf der Website oder in Bezug auf die Website
						veröffentlichten Richtlinien oder Betriebsregeln stellen die gesamte Vereinbarung und
						Übereinkunft zwischen Ihnen und uns dar. Unser Versäumnis, ein Recht oder eine Bestimmung dieser
						Nutzungsbedingungen auszuüben oder durchzusetzen, gilt nicht als Verzicht auf dieses Recht oder
						diese Bestimmung. Diese Nutzungsbedingungen gelten im größtmöglichen gesetzlich zulässigen
						Umfang. Wir können unsere Rechte und Pflichten jederzeit ganz oder teilweise an Dritte abtreten.
						Wir sind nicht verantwortlich oder haftbar für Verluste, Schäden, Verzögerungen oder
						Untätigkeit, die durch eine Ursache außerhalb unserer Kontrolle verursacht werden. Sollte sich
						eine Bestimmung oder ein Teil einer Bestimmung dieser Nutzungsbedingungen als rechtswidrig,
						nichtig oder nicht durchsetzbar erweisen, gilt diese Bestimmung oder dieser Teil der Bestimmung
						als von diesen Nutzungsbedingungen abtrennbar und berührt nicht die Gültigkeit und
						Durchsetzbarkeit der übrigen Bestimmungen. Zwischen Ihnen und uns entsteht durch diese
						Nutzungsbedingungen oder die Nutzung der Website kein Joint-Venture-, Partnerschafts-, Arbeits-
						oder Vertretungsverhältnis. Sie erklären sich damit einverstanden, dass diese
						Nutzungsbedingungen nicht gegen uns ausgelegt werden können, nur weil wir sie verfasst haben.
						Sie verzichten hiermit auf alle Einreden, die Sie aufgrund der elektronischen Form dieser
						Nutzungsbedingungen und der fehlenden Unterzeichnung dieser Nutzungsbedingungen durch die
						Vertragsparteien geltend machen könnten.

						Kontaktieren Sie uns
						Um eine Beschwerde in Bezug auf die Website zu klären oder weitere Informationen zur Nutzung der
						Website zu erhalten, kontaktieren Sie uns bitte unter:

						crudafoch

						Service "MobiPoints"

						Lödersdorf I 96b/7

						8334 Riegersburg

						E-Mail: office@crudafoch.com

						Support oder Wartung
						Sie erklären sich damit einverstanden, dass das Unternehmen nicht verpflichtet ist, Ihnen in
						Verbindung mit der Website Unterstützung zu gewähren.
					</p>
					<p>Vielen Dank an <a href="https://storyset.com/people">People illustrations by Storyset</a></p>
					<br>
				</div>
			</ion-content>
		</ion-modal>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonModal, IonContent, modalController, IonButtons, IonButton, IonTitle, IonHeader, IonToolbar} from '@ionic/vue';
import router from "@/router";

export default {
	name: 'Terms',
	components: {IonPage, IonModal, IonContent, IonButtons, IonButton, IonTitle, IonHeader, IonToolbar},
	setup() {
		function dismissModal() {
			modalController.dismiss();
		}

		function closeModalAndGoBack() {
			router.go(-1)
		}

		return {dismissModal, closeModalAndGoBack}
	}
}
</script>

<style>
</style>