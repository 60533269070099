import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {MobiPointsPointsInterface} from "@/mobipoints/points/points.interface";

interface PointsGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsPointsInterface;
}

class PointsResponseError extends ResponseError {
}

const MobiPointsApiPoints = {
	getPoints: async function (): Promise<AxiosResponse<PointsGetResponse>> {
		return MobiPointsApiClient.get('/points');
	},
}

export {MobiPointsApiPoints, PointsGetResponse,PointsResponseError};