import {ChallengeFactory} from "@/mobipoints/challenge/challenge_factory";

export default function useChallengeFactory() {
	let challengeFactory: ChallengeFactory | null = null;
	const getChallengeFactory = (): ChallengeFactory => {
		if (!challengeFactory) {
			challengeFactory = new ChallengeFactory();
		}

		return challengeFactory;
	}

	return {
		getChallengeFactory,
	}
}