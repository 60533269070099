<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>Challenges</ion-title>
				<avatar-menu :show-points="true"></avatar-menu>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true" class="">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Challenges</ion-title>
				</ion-toolbar>
			</ion-header>

			<challenge-news></challenge-news>

			<active-challenge-list></active-challenge-list>

			<ExploreContainer name="Tab 1 page"></ExploreContainer>
		</ion-content>
	</ion-page>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar
} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import AvatarMenu from "@/components/menu/AvatarMenu.vue";
import ChallengeNews from "@/components/challenge/ChallengeNews.vue";
import ActiveChallengeList from "@/components/challenge/ActiveChallengeList.vue";
import useChallenge from "@/composable/useChallenge";

const challengeFacade = useChallenge();

onMounted(() => {
  challengeFacade.reloadChallenges();
});
</script>

<style>
</style>