import {
	MobiPointsQueueEventAbstractEvent,
	EVENT_STATE_NEW,
	EVENT_TYPE_ADD_COORDINATES,
	EVENT_TYPE_START_TRACK,
	EVENT_TYPE_START_TRACKING,
	EVENT_TYPE_END_TRACK,
	EVENT_TYPE_END_TRACKING,
	EVENT_TYPE_WEATHER_DATA,
	EVENT_TYPE_REDEEM_VOUCHER
} from "@/mobipoints/queue/event/abstract_event.type";
import {MobiPointsQueueEventTypeStartTrackEvent} from "@/mobipoints/queue/event/type/start_track_event.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import {MobiPointsQueueEventTypeStartTrackingEvent} from "@/mobipoints/queue/event/type/start_tracking_event.type";
import {MobiPointsQueueEventTypeCoordinatesEvent} from "@/mobipoints/queue/event/type/coordinates_event.type";
import {MobiPointsQueueEventTypeEndTrackEvent} from "@/mobipoints/queue/event/type/end_track_event.type";
import {MobiPointsQueueEventTypeEndTrackingEvent} from "@/mobipoints/queue/event/type/end_tracking_event.type";
import {MobiPointsQueueEventTypeWeatherDataEvent} from "@/mobipoints/queue/event/type/weather_data_event.type";
import {MobiPointsQueueEventTypeRedeemVoucherEvent} from "@/mobipoints/queue/event/type/redeem_voucher_event.type";

export class MobiPointsQueueEventFactory {

	createNewEventByType(userTrackingUuid: string|null, eventType: string, eventName: string, timestamp: number, subType: string, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {}}): MobiPointsQueueEventAbstractEvent {
		switch (eventType) {
			case EVENT_TYPE_START_TRACKING:
				return new MobiPointsQueueEventTypeStartTrackingEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_START_TRACK:
				return new MobiPointsQueueEventTypeStartTrackEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_ADD_COORDINATES:
				return new MobiPointsQueueEventTypeCoordinatesEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_END_TRACK:
				return new MobiPointsQueueEventTypeEndTrackEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_END_TRACKING:
				return new MobiPointsQueueEventTypeEndTrackingEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_WEATHER_DATA:
				return new MobiPointsQueueEventTypeWeatherDataEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			case EVENT_TYPE_REDEEM_VOUCHER:
				return new MobiPointsQueueEventTypeRedeemVoucherEvent(userTrackingUuid, eventName, timestamp, subType, state, payload);
			default:
				throw new Error("Type " + eventType + " not allowed!");
		}
	}
}
