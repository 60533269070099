import {MobiPointsProfileUserSettingsInterface} from "@/mobipoints/profile/userSettings/user_settings.interface";

export class UserSettings implements MobiPointsProfileUserSettingsInterface {
	private _geoLocationInterval: number | null = null;
	private _enableHighAccuracy = true;
	private _trackingDistanceFilter: number | null = null;
	private _maxHomeDistanceTolerance: number | null = null;

	constructor(geoLocationInterval: number|null = null, enableHighAccuracy = true, trackingDistanceFilter: number|null = null, maxHomeDistanceTolerance: number|null = null) {
		if (!geoLocationInterval) {
			if (process.env.VUE_APP_GEOLOCATION_INTERVAL_IN_SECONDS) {
				this.geoLocationInterval = Number(process.env.VUE_APP_GEOLOCATION_INTERVAL_IN_SECONDS);
			} else {
				this.geoLocationInterval = 8;
			}
		} else {
			this.geoLocationInterval = geoLocationInterval;
		}
		if (!trackingDistanceFilter) {
			if (process.env.VUE_APP_GEOLOCATION_TRACKING_DISTANCE_FILTER) {
				this.trackingDistanceFilter = Number(process.env.VUE_APP_GEOLOCATION_TRACKING_DISTANCE_FILTER);
			} else {
				this.trackingDistanceFilter = 15;
			}
		} else {
			this.trackingDistanceFilter = trackingDistanceFilter;
		}
		if (!maxHomeDistanceTolerance) {
			this.maxHomeDistanceTolerance = 250;
		} else {
			this.maxHomeDistanceTolerance = maxHomeDistanceTolerance;
		}
		this.enableHighAccuracy = enableHighAccuracy;
	}

	get geoLocationInterval(): number | null {
		return this._geoLocationInterval;
	}

	set geoLocationInterval(value: number | null) {
		this._geoLocationInterval = value;
	}

	get enableHighAccuracy(): boolean {
		return this._enableHighAccuracy;
	}

	set enableHighAccuracy(value: boolean) {
		this._enableHighAccuracy = value;
	}

	get trackingDistanceFilter(): number | null {
		return this._trackingDistanceFilter;
	}

	set trackingDistanceFilter(value: number | null) {
		this._trackingDistanceFilter = value;
	}

	get maxHomeDistanceTolerance(): number | null {
		return this._maxHomeDistanceTolerance;
	}

	set maxHomeDistanceTolerance(value: number | null) {
		this._maxHomeDistanceTolerance = value;
	}
}