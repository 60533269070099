<template>
	<div>
		<form @submit.prevent="login" class="space-y-6" action="#" method="POST">
			<div>
				<label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email Adresse</label>
				<div class="mt-2">
					<input id="email" name="email" type="email" autocomplete="email" tabindex="1" v-model="loginData.email" placeholder="E-Mail" required="required" class="dark:text-white block w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
				</div>
				<span class="error ion-padding" v-if="emailError">
					  {{ emailError }}
				</span>
			</div>

			<div>
				<label for="password" class="block text-sm font-medium leading-6 text-gray-900">Passwort</label>
				<div class="mt-2">
					<input id="password" name="password" type="password" v-model="loginData.password" placeholder="Passwort" tabindex="2" autocomplete="current-password" required="required" class="block dark:text-white w-full ps-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
				</div>
				<span class="error ion-padding" v-if="passwordError">
					  {{ passwordError }}
				</span>
			</div>

			<div class="flex items-center justify-between">
<!--								<div class="flex items-center">-->
<!--									<input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />-->
<!--									<label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-900">Remember me</label>-->
<!--								</div>-->

				<div class="text-sm leading-6">
					<router-link class="font-semibold text-gray-700 hover:text-black w-full" to="/forgotPassword">
						{{forgotPassword}}
					</router-link>
				</div>
			</div>

			<div>
				<button type="submit" class="flex w-full h-11 items-center justify-center rounded-md bg-gr-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gr-primary3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Anmelden</button>
			</div>
		</form>
	</div>
</template>

<script lang="ts">
import {ref} from 'vue'
import {loadingController} from '@ionic/vue';
import useUserRepository from "@/components/user/UserRepository.vue";
import useToastMessage from "@/components/core/ToastMessage.vue";
import router from "@/router";
import {UserAuth} from '@/mobipoints/auth/user_auth';
import useData from "@/composable/useData";
import {BasicAuthError} from "@/mobipoints/api/auth";

export default {
	name: 'BasicLogin',
	components: {},
	props: {
		provider: {
			type: String,
			default: "google"
		}
	},
	setup(props: any) {
		const {setUserName, setGivenName, setEmail, setProfileImage} = useUserRepository();
		const {openToast} = useToastMessage();
		const {getTextValueByKey} = useData();
		const loadingTimeout = ref(1000);
		const loginData = ref({email: "", password: ""})
		const emailError = ref("");
		const passwordError = ref("");
		const forgotPassword = ref(getTextValueByKey('login.forgot_Password.title', [], 'Passwort vergessen?'));

		async function presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: '',
					message:  getTextValueByKey('login.basic.loading.text', [], 'Bitte warten... Wir versuchen dich einzuloggen!'),
					duration: loadingTimeout.value,
				});

			await loading.present();

			setTimeout(function () {
				loading.dismiss()
			}, loadingTimeout.value);
		}

		async function handleLoginError(error: any|BasicAuthError) {
			try {
				if (error instanceof BasicAuthError) {
					if (error.errorFields) {
						error.errorFields.forEach((errorField: any = {name: '', message: ''}) => {
							if (errorField.name == 'email') {
								emailError.value = errorField.message;
							}
							if (errorField.name == 'password') {
								passwordError.value = errorField.password;
							}
						});
					}
					if (error.message) {
						await openToast(error.message, 'danger', 'top', true, 10000, undefined, true);
					}
				} else {
					await openToast(getTextValueByKey('login.basic.general.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
				}
			} catch (error) {
				await openToast(getTextValueByKey('login.basic.general.error', [], 'Login Error'), 'danger', 'top', true, 5000, undefined, true);
			}
		}

		function hasValidLoginData(): boolean {
			let result = true;
			emailError.value = "";
			passwordError.value = "";
			if (!loginData.value.email) {
				emailError.value = getTextValueByKey('login.basic.error.email', [], 'Bitte trag eine gültige E-Mail Adresse ein!');
				result = false;
			}
			if (!loginData.value.password) {
				passwordError.value = getTextValueByKey('login.basic.error.password', [], 'Bitte trag ein gültiges Passwort ein!');
				result = false;
			}

			return result;
		}

		function resetLoginData() {
			loginData.value = {email: "", password: ""};
		}

		async function login(): Promise<void> {
			try {
				if (hasValidLoginData()) {
					await presentLoading();
					const userAuth = new UserAuth();
					const result = await userAuth.loginBasic(loginData.value);
					if (result) {
						setUserName(result.data.name ?? loginData.value.email);
						setGivenName(result.data.name ?? loginData.value.email);
						setEmail(result.data.email ?? loginData.value.email);
						setProfileImage(result.data.imageUrl ?? null);
						await router.push('home');
						resetLoginData();
					}
				}
			} catch (error: any|BasicAuthError) {
				console.log(error);
				await handleLoginError(error);
			}
		}

		return {login, loginData, emailError, passwordError, forgotPassword}
	},
}
</script>

<style scoped>
.basic-login-text {
    font-family: Rubik, sans-serif !important;
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.basic-login-btn {
	background-color: transparent;
	box-shadow: none;
	margin-left: -2px;
	margin-right: -2px;
    /*box-shadow: 0 0 .35em .175em rgba(var(--ion-color-dark-rgb), .33);*/
    /*-webkit-box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
    /*box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
}

.error {
	color: #e74848;
}

.noErrorMargin {
	margin-top: -3px;
}

.addTopMargin {
    margin-top: 20px
}
</style>