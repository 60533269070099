import {MobiPointsTrackingTrackAbstractTrack} from "@/mobipoints/tracking/track/abstract_track.type";

export class MobiPointsTrackingTrackTrackList extends Array<MobiPointsTrackingTrackAbstractTrack>
{
	private items: MobiPointsTrackingTrackAbstractTrack[] = [];

	constructor(items: MobiPointsTrackingTrackAbstractTrack[]|null = null) {
		super();
		if (items !== null) {
			this.items.push(...items);
		}
	}
	add(items: MobiPointsTrackingTrackAbstractTrack) {
		this.items.push(items);
	}
	remove(index: number) {
		this.items.splice(index, 1);
	}
	removeAll() {
		const itemCount = this.items.length;
		this.items.splice(itemCount, 1);
	}
	getItems(): MobiPointsTrackingTrackAbstractTrack[] {
		return this.items;
	}
	getItem(index: number): MobiPointsTrackingTrackAbstractTrack {
		return this.items[index];
	}
}