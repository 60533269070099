<template>
	<ion-page>
		<ion-modal :is-open="true" @didDismiss="closeModalAndGoBack" :swipe-to-close="true" :keyboardClose="true">
			<ion-header translucent>
				<ion-toolbar>
					<ion-title>🏢 Impressum</ion-title>
					<ion-buttons slot="end">
						<ion-button @click="dismissModal()">Schließen</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>
			<ion-content fullscreen>
				<div style="padding: 10px">
					<p>
						Impressum
					</p>
					<p>
						Informationen gemäß §5 des E-Commerce-Gesetzes, §14 des Unternehmensgesetzbuches, §63 des
						Handelsgesetzbuches und Offenlegungspflichten gemäß §25 des Mediengesetzes.


						crudafoch

						Lödersdorf I 96b/7,

						8334 Riegersburg

						Österreich

						Der Dienst nice-mobility.app wird von crudafoch herausgegeben

						Company-Website:

						Objekt des Unternehmens: Dienstleistungen in der automatischen Datenverarbeitung und
						Informationstechnologie

						Umsatzsteuer-Identifikationsnummer:

						GLN:

						GISA:

						Firmenbuchnummer:

						Firmenbuchgericht:

						Firmensitz:

						E-Mail: officeatZeichencrudafoch.com

						<!--					Mitglied von: WKO, Steiermark-->

						Berufsgesetze: Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik

						Aufsichts-/Gewerbebehörde: Bezirkshauptmannschaft Graz-Umgebung

						Verleihendes Land: Österreich

						Datenschutzkontrolleur

						DatenschutzbeiZeichennice-mobility.app

						Vertretungsberechtigt: Andreas Maurer / Martin Laundl

						EU-Streitbeilegung

						Gemäß der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (OS-Verordnung)
						möchten wir Sie über die Plattform zur Online-Streitbeilegung (OS-Plattform) informieren.
						Verbraucher haben die Möglichkeit, Beschwerden bei der Online-Streitbeilegungsplattform der
						Europäischen Kommission unter http://ec.europa.eu/odr?tid=121740985 einzureichen. Die hierfür
						notwendigen Kontaktdaten finden Sie oben in unserem Impressum. Wir weisen jedoch darauf hin,
						dass wir nicht bereit oder verpflichtet sind, an einem Streitbeilegungsverfahren vor einer
						Verbraucherschlichtungsstelle teilzunehmen.

						Haftung für die Inhalte dieser Website

						Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns, korrekte und aktuelle
						Informationen bereitzustellen. Für die Richtigkeit aller Inhalte dieser Website, insbesondere
						für die von Dritten bereitgestellten Inhalte, können wir jedoch keine Gewähr übernehmen. Wir
						sind als Diensteanbieter jedoch nicht verpflichtet, die von ihnen übermittelten oder
						gespeicherten fremden Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
						rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
						Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen
						Anordnungen bleiben hiervon unberührt, auch im Falle unserer Nichtverantwortlichkeit. Sollten
						Ihnen problematische oder rechtswidrige Inhalte auffallen, setzen Sie sich bitte umgehend mit
						uns in Verbindung, damit wir die rechtswidrigen Inhalte entfernen können.

						Haftung für Links auf dieser Website

						Unsere Website enthält Links zu anderen Websites, für deren Inhalt wir nicht verantwortlich
						sind. Wir haften nicht für verlinkte Websites, da wir keine Kenntnis von rechtswidrigen
						Handlungen hatten und haben, uns keine derartigen rechtswidrigen Handlungen bekannt sind und wir
						Links sofort entfernen würden, wenn wir von rechtswidrigen Handlungen Kenntnis erlangen. Sollten
						Sie rechtswidrige Links auf unserer Website bemerken, wenden Sie sich bitte an uns.

						Urheberrechtshinweis

						Alle Inhalte dieser Website (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte
						fragen Sie uns, bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten,
						wie z.B. auf anderen Websites wieder veröffentlichen. Gegebenenfalls werden wir die unerlaubte
						Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen. Wenn Sie auf dieser Website
						Inhalte finden, die gegen das Urheberrecht verstoßen, kontaktieren Sie uns bitte.


					</p>
					<br>
				</div>
			</ion-content>
		</ion-modal>
	</ion-page>
</template>

<script lang="ts">
import {
	IonPage,
	IonModal,
	IonContent,
	modalController,
	IonButtons,
	IonButton,
	IonTitle,
	IonHeader,
	IonToolbar
} from '@ionic/vue';
import router from "@/router";

export default {
	name: 'Impress',
	components: {IonPage, IonModal, IonContent, IonButtons, IonButton, IonTitle, IonHeader, IonToolbar},
	setup() {
		function dismissModal() {
			modalController.dismiss();
		}

		function closeModalAndGoBack() {
			router.go(-1)
		}

		return {dismissModal, closeModalAndGoBack}
	}
}
</script>

<style>
</style>