import {
	MobiPointsApiGetResponse,
	ResponseError,
} from '@/mobipoints/api/api.interfaces';
import {AxiosResponse} from 'axios';
import MobiPointsApiClient from '@/mobipoints/api/client';
import {
	WalletInterface,
} from '@/mobipoints/wallet/wallet.interface';
import {
	WalletItemInterface, WalletItemSubType, WalletItemType,
} from '@/mobipoints/wallet/item/wallet_item.interface';

interface WalletGetResponse extends MobiPointsApiGetResponse {
	payload: {wallets: WalletInterface};
}

interface WalletItemGetResponse extends MobiPointsApiGetResponse {
	payload: {list: WalletItemInterface};
}

class WalletResponseError extends ResponseError {
}

const MobiPointsApiWallet = {
	getWallets: async function(): Promise<AxiosResponse<WalletGetResponse>> {
		return MobiPointsApiClient.get('/wallets');
	},

	getWalletItems: async function(wallet_uuid: string,  count: number | null = null, offset = 0): Promise<AxiosResponse<WalletItemGetResponse>> {
		return MobiPointsApiClient.get(`/wallet/${wallet_uuid}/items?offset=${offset}${null !== count ? `&count=${count}` : ''}`);
	},

	filterWalletItems: async function(wallet_uuid: string,  dateFrom: string | null = null, dateTo: string | null = null, types: Array<WalletItemType>|null = null, subTypes: Array<WalletItemSubType>|null = null): Promise<AxiosResponse<WalletItemGetResponse>> {
		let url = `/wallet/${wallet_uuid}/items`;

		if (dateFrom !== null) {
			url += `?dateFrom=${dateFrom}`;
		}
		if (dateTo !== null) {
			url += `${dateFrom !== null ? '&' : '?'}dateTo=${dateTo}`;
		}
		if (types !== null && types.length > 0) {
			url += `${(dateFrom !== null || dateTo !== null) ? '&' : '?'}types=${types.join(',')}`;
		}
		if (subTypes !== null && subTypes.length > 0) {
			url += `${(dateFrom !== null || dateTo !== null || (types !== null && types.length > 0)) ? '&' : '?'}subTypes=${subTypes.join(',')}`;
		}

		return MobiPointsApiClient.get(url);
	},
};

export {MobiPointsApiWallet, WalletGetResponse, WalletResponseError};