import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-split-form" }
const _hoisted_2 = ["type", "pattern", "id", "onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code, (n, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        class: "input-split",
        key: index,
        type: _ctx.inputType,
        pattern: _ctx.inputPattern,
        id: 'input_' + index,
        maxlength: "1",
        "onUpdate:modelValue": ($event: any) => ((_ctx.code[index]) = $event),
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.isValidByEvent && _ctx.isValidByEvent(...args))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["delete"])),
        onPaste: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onPaste && _ctx.onPaste(...args)))
      }, null, 40, _hoisted_2)), [
        [_vModelDynamic, _ctx.code[index]]
      ])
    }), 128))
  ]))
}