import {
	MobiPointsChallengeChallengeDataInterface,
	MobiPointsChallengeChallengeInterface
} from "@/mobipoints/challenge/challenge/challenge.interface";
import {MobiPointsChallengeChallengeStateInterface} from "@/mobipoints/challenge/challenge/challenge_state.interface";
import {MobiPointsChallengeChallengeTypeInterface} from "@/mobipoints/challenge/challenge/challenge_type.interface";
import {UserChallenge} from "@/mobipoints/challenge/userChallenge/user_challenge.type";
import {getDateDiffAsDatePartString, getDateDiffAsText} from "@/mobipoints/core/date";

export const enum MobiPointsChallengeType {
	CHALLENGE_TYPE_BUSINESS_TRIP = 'business_trip',
	CHALLENGE_TYPE_CASH_VOUCHER = 'cash_voucher',
}

export class Challenge implements MobiPointsChallengeChallengeInterface {
	private _uuid = '';
	private _startTimestamp: number | null = null;
	private _endTimestamp: number | null = null;
	private _title = '';
	private _description: string | null = null;
	private _data: MobiPointsChallengeChallengeDataInterface | null = null;
	private _state: MobiPointsChallengeChallengeStateInterface | null = null;
	private _type: MobiPointsChallengeChallengeTypeInterface | null = null;
	private _userChallenges: UserChallenge[] = [];


	constructor(uuid: string, state: MobiPointsChallengeChallengeStateInterface, type: MobiPointsChallengeChallengeTypeInterface) {
		this.uuid = uuid;
		this.state = state;
		this.type = type;
	}


	get uuid(): string {
		return this._uuid;
	}

	set uuid(value: string) {
		this._uuid = value;
	}

	get startTimestamp(): number | null {
		return this._startTimestamp;
	}

	set startTimestamp(value: number | null) {
		this._startTimestamp = value;
	}

	get endTimestamp(): number | null {
		return this._endTimestamp;
	}

	set endTimestamp(value: number | null) {
		this._endTimestamp = value;
	}

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}

	get description(): string | null {
		return this._description;
	}

	set description(value: string | null) {
		this._description = value;
	}

	get data(): MobiPointsChallengeChallengeDataInterface | null {
		return this._data;
	}

	set data(value: MobiPointsChallengeChallengeDataInterface | null) {
		this._data = value;
	}

	get state(): MobiPointsChallengeChallengeStateInterface {
		return this._state as MobiPointsChallengeChallengeStateInterface;
	}

	set state(value: MobiPointsChallengeChallengeStateInterface) {
		this._state = value;
	}

	get type(): MobiPointsChallengeChallengeTypeInterface {
		return this._type as MobiPointsChallengeChallengeTypeInterface;
	}

	set type(value: MobiPointsChallengeChallengeTypeInterface) {
		this._type = value;
	}

	get userChallenges(): UserChallenge[] {
		return this._userChallenges;
	}

	set userChallenges(value: UserChallenge[]) {
		this._userChallenges = value;
	}

	getTimesAsString(short = false): string {
		let result = '';

		const currentDate = new Date();
		if (this.startTimestamp) {
			const startDate = new Date(this.startTimestamp * 1000);
			if (currentDate.getTime() <= startDate.getTime()) {
				result = 'startet' + getDateDiffAsText(startDate, currentDate, short);
			} else {
				if(this.endTimestamp && this.endTimestamp - currentDate.getTime() / 1000 <= 60 * 60 * 24) {
					result = 'endet ' + getDateDiffAsText(new Date(this.endTimestamp * 1000), currentDate, short);
				} else {
					result = 'gestartet ' + getDateDiffAsText(startDate, currentDate, short);
				}
			}
		} else if(this.endTimestamp) {
			const endDate = new Date(this.endTimestamp * 1000);
			if (currentDate.getTime() <= endDate.getTime()) {
				result = 'endet ' + getDateDiffAsText(endDate, currentDate, short);
			} else {
				result = 'beendet ' + getDateDiffAsText(currentDate, endDate, short);
			}
		}

		return result;
	}

	getTimeLeftAsString(delimiter = ', ', lastDelimiter: string | null = ' und '): string {
		let result = '';

		const currentDate = new Date();
		if (this.endTimestamp) {

			const endDate = new Date(this.endTimestamp * 1000);
			if (currentDate.getTime() <= endDate.getTime()) {
				result = getDateDiffAsDatePartString(endDate, currentDate, delimiter, lastDelimiter);
			}
		}

		return result;
	}

	getImage(): string {
		return this.data?.img || '';
	}
}