import {
	AbstractWalletItem
} from '@/mobipoints/wallet/item/abstract_wallet_item.type';
import useData from '@/composable/useData';

export class WalletItemCorrection extends AbstractWalletItem{
	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.correction.name', [], 'Korrektur');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount = super.getAmountFormatted(withSuffix);
		if (withSuffix) {
			amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.correction.amount.suffix', [], 'Punkte korrigiert');
		}
		return amount;
	}
}