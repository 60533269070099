<template>
	<img id="MobilityLogo_Grn" src="../../../public/assets/logo/MobilityLogo_Grn.png" srcset="../../../public/assets/logo/MobilityLogo_Grn.png 1x, ../../../public/assets/logo/MobilityLogo_Grn@2x.png 2x" alt="Mobility by niceshops">
</template>

<script lang="ts">

export default {
	name: 'MobilityLogo',
	components: {},
	props: {
		provider: {
			type: String,
			default: "google"
		}
	},
}
</script>

<style scoped>
#MobilityLogo_Grn {
    position: relative;
    overflow: visible;
    height: auto !important;
    width: auto !important;
    align-items: center;
    margin: auto;
    display: block;
}
</style>