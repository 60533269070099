export const enum MobiPointsWeatherType {
	WEATHER_TYPE_SUNNY = 'sunny',
	WEATHER_TYPE_CLOUDS = 'clouds',
	WEATHER_TYPE_CLOUDS_FEW = 'cloudsFew',
	WEATHER_TYPE_CLOUDS_OVERCAST = 'cloudsOvercast',
	WEATHER_TYPE_THUNDERSTORM = 'thunderstorm',
	WEATHER_TYPE_DRIZZLE = 'drizzle', //Nieselregen
	WEATHER_TYPE_RAIN = 'rain',
	WEATHER_TYPE_LIGHT_RAIN = 'lightRain',
	WEATHER_TYPE_HEAVY_RAIN = 'heavyRain',
	WEATHER_TYPE_SNOW = 'snow',
	WEATHER_TYPE_LIGHT_SNOW = 'lightSnow',
	WEATHER_TYPE_HEAVY_SNOW = 'heavySnow',
	WEATHER_TYPE_ATMOSPHERE = 'atmosphere',
	WEATHER_TYPE_CLEAR = 'clear',
}

export const enum MobiPointsWeatherTemperatureUnit {
	TEMPERATURE_UNIT_CELSIUS = 'celsius',
	TEMPERATURE_UNIT_KELVIN = 'kelvin',
}

export const enum MobiPointsWeatherWindUnit {
	WIND_UNIT_METER_PER_SECOND = 'meter_per_second',
}

export interface MobiPointsTrackingWeatherWeatherTemperatureInterface {
	measuredTemperature: number,
	feelsLikeTemperature: number,
	humidity: number,
	unit: MobiPointsWeatherTemperatureUnit,
}

export interface MobiPointsTrackingWeatherWeatherWindInterface {
	speed: number,
	direction: number,
	gust: number,
	unit: MobiPointsWeatherWindUnit,
}

export interface MobiPointsTrackingWeatherWeatherCoordinatesInterface {
	lat: number,
	lng: number
}

export interface MobiPointsTrackingWeatherWeatherInterface {
	weatherDataUuid: string|null,
	weatherType: MobiPointsWeatherType,
	weatherDescription: string,
	temperature: MobiPointsTrackingWeatherWeatherTemperatureInterface | null,
	coordinates: MobiPointsTrackingWeatherWeatherCoordinatesInterface | null,
	wind: MobiPointsTrackingWeatherWeatherWindInterface | null,
	clouds: number | null,
	country: string,
	cityName: string,
	sunrise: number | null,
	sunset: number | null,
	timestamp: number;
	timezone: number;
}
