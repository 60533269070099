import {
    MobiPointsTrackingWeatherWeatherInterface,
    MobiPointsWeatherType
} from "@/mobipoints/tracking/weather/weather.interface";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";
import {WeatherGetResponse} from "@/mobipoints/api/weather";

export class MobiPointsTrackingWeatherWeatherFactory {
    createWeather(weatherType: MobiPointsWeatherType, timestamp: number, timezone: number): MobiPointsTrackingWeatherWeather {
        return new MobiPointsTrackingWeatherWeather(weatherType, timestamp, timezone);
    }

    createSingleWeatherDataByApiResponse(response: WeatherGetResponse): MobiPointsTrackingWeatherWeather {
        let data = response.payload;
        if (data instanceof Array) {
            data = data[0];
        }

        return this.createWeatherDataByApiResponse(data)
    }

    createWeatherDataListByApiResponse(response: WeatherGetResponse): MobiPointsTrackingWeatherWeather[] {
        const list: MobiPointsTrackingWeatherWeather[] = [];

        let responseList = response.payload;
        if (!(responseList instanceof Array)) {
            responseList = [responseList];
        }

        responseList.forEach((data) => {
            list.push(this.createWeatherDataByApiResponse(data));
        });

        return list;
    }


    createWeatherDataByApiResponse(response: MobiPointsTrackingWeatherWeatherInterface): MobiPointsTrackingWeatherWeather {
        const weather = this.createWeather(response.weatherType, response.timestamp, response.timezone);
        weather.weatherDescription = response.weatherDescription ?? '';
        weather.temperature = response.temperature;
        weather.coordinates = response.coordinates;
        weather.wind = response.wind ?? null;
        weather.clouds = response.clouds ?? null;
        weather.country = response.country;
        weather.cityName = response.cityName;
        weather.sunrise = response.sunrise ?? null;
        weather.sunset = response.sunset ?? null;

        return weather;
    }


    createWeatherItemByProperties(data: any): MobiPointsTrackingWeatherWeather {
        if (!data) {
            throw new Error('invalid data!');
        }
        const weather = this.createWeather(data._weatherType, data._timestamp, data._timezone);
        weather.weatherDescription = data._weatherDescription ?? '';
        weather.temperature = data._temperature
        weather.coordinates = data._coordinates;
        weather.wind = data._wind ?? null;
        weather.clouds = data._clouds ?? null;
        weather.country = data._country;
        weather.cityName = data._cityName;
        weather.sunrise = data._sunrise ?? null;
        weather.sunset = data._sunset ?? null;

        return weather;
    }
}
