<template>
	<ion-page class="bg-gradient-to-r from-teal-200 to-lime-200">
		<ion-content class="ion-padding loginBackground">
			<div class="flex min-h-full flex-1 flex-col justify-center pb-12 sm:px-6 lg:px-8">
				<div class="sm:mx-auto sm:w-full sm:max-w-md">
					<div class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						<h2>{{loginTitle}}</h2>
						<h3>{{loginWithBasic}}</h3>
					</div>
				</div>

				<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div class=" py-16 sm:rounded-lg sm:px-12 rounded-xl bg-gray-800 bg-opacity-50 px-16 shadow-lg backdrop-blur-md max-sm:px-4 backdrop-filter-none">
						<img class="mx-auto h-14 w-auto -mt-24 z-10" src="../assets/icons/icon.png" alt="MobiPoints" />

						<basic-login></basic-login>

						<div>
							<div class="relative mt-10">
								<div class="absolute inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-200" />
								</div>
								<div class="relative flex justify-center text-sm font-medium leading-6">
									<span class="bg-white px-6 text-gray-900 rounded-xl">{{orLoginWithGoogle}}</span>
								</div>
							</div>

							<div class="mt-6">
								<div class="w-full items-center justify-center shadow rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
									<google-login id="google-login-component"></google-login>
								</div>
							</div>

							<p class="mt-7 text-center text-sm text-gray-800">
								{{noAccount}}
								{{ ' ' }}
								<br>
								<router-link to="/register" class="font-semibold leading-6 text-green-50 hover:text-black w-full">
									{{registerHere}}
								</router-link>
							</p>
						</div>
					</div>
				</div>
			</div>


<!--			<p style="text-align: center">{{loginWithBasic}}</p>-->
<!--			<basic-login class=""></basic-login>-->
<!--			<div style="text-align: right">-->
<!--				<p>-->
<!--					<router-link to="/forgotPassword">-->
<!--						<a class="link-text-color">{{forgotPassword}}</a>-->
<!--					</router-link>-->
<!--				</p>-->
<!--			</div>-->
<!--			<div style="text-align: center">-->
<!--				<p>{{noAccount}}<br>-->
<!--					<router-link to="/register">-->
<!--						<a class="link-text-color">{{registerHere}}</a>-->
<!--					</router-link>-->
<!--				</p>-->
<!--			</div>-->
<!--			<div class="strike ion-padding ion-padding-bottom" @click="jumpToGoogleLogin">-->
<!--				<span>{{orLoginWithGoogle}}</span>-->
<!--			</div>-->
<!--			<google-login id="google-login-component"></google-login>-->
		</ion-content>

		<ion-footer class="ion-padding">
			<p style="margin: auto;text-align: center;">
				<span>
					<router-link to="/privacy-crudafoch">
						<a style="cursor: pointer" class="ion-color-medium">Datenschutz</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/terms">
						<a style="cursor: pointer">AGB</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/impress">
						<a style="cursor: pointer">Impressum</a>
					</router-link>
				</span>
			</p>
			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 7px">
				Version: {{ appVersion }}
			</p>
			<mobility-logo></mobility-logo>
		</ion-footer>

	</ion-page>
</template>

<script lang="ts">
import {ref} from "vue";
import {
	IonContent,
	IonPage,
	IonFooter,
	onIonViewWillLeave,
	onIonViewWillEnter,
} from '@ionic/vue';
import GoogleLogin from "@/components/auth/GoogleLoginNew.vue";
import MobilityLogo from "@/components/logo/MobilityLogo.vue";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import useAuth from "@/composable/useAuth";
import router from "@/router";
import BasicLogin from "@/components/auth/BasicLogin.vue";

export default {
	name: 'Login',
	components: {
		BasicLogin,
		MobilityLogo, GoogleLogin, IonContent, IonPage, IonFooter
	},
	setup() {
		const {getTextValueByKey} = useData();
		const {appVersion, isIosPlatform} = useSystem();
		const loginTitle = ref(getTextValueByKey('login.title', [], 'Willkommen bei den MobiPoints'));
		const loginWithBasic = ref(getTextValueByKey('login.with.basic', [], 'Logge dich ein'));
		const orLoginWithGoogle = ref(getTextValueByKey('login.with.google', [], 'Oder melde dich mit Google an'));
		const registerHere = ref(getTextValueByKey('register.here.title', [], 'Registriere dich hier!'));
		const noAccount = ref(getTextValueByKey('register.no_account.title', [], 'Hast du keinen Account?'));
		const forgotPassword = ref(getTextValueByKey('login.forgot_Password.title', [], 'Passwort vergessen?'));
		const {isAuthenticated} = useAuth();

		onIonViewWillLeave(() => {
			if (!isAuthenticated.value) {
				// console.log("NOT AUTH .....")
			}
		});

		onIonViewWillEnter(() => {
			if (isAuthenticated.value) {
				router.push('home');
			}
		});

		function jumpToGoogleLogin() {
			const element = document.getElementById("google-login-component");
			if (element) {
				element.scrollIntoView();
			}
		}

		function loginWithProvider(provider: string) {
			window.location.href = (process.env.VUE_APP_API_OAUTH + '/' + provider);
		}

		return {
			loginWithProvider,
			appVersion,
			loginTitle,
			orLoginWithGoogle,
			registerHere,
			noAccount,
			loginWithBasic,
			jumpToGoogleLogin,
			isIosPlatform,
			forgotPassword
		}
	}
}
</script>

<style>
.loginHeader {
	text-align: center;
	color: var(--ion-text-color);
}

.loginBackground {
    --ion-background-color: none;
    //--color:var(--ion-color-primary-contrast);
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
	color: var(--ion-color-dark);
}

.strike > span {
    position: relative;
    display: inline-block;
    /*background-color: #e5e5e5;*/
    background-color: var(--ion-color-white);
    /*background-color: var(--ion-background-color);*/
    /*background-color: var(--ion-color-light);*/
    border-radius: 15px;
	padding: 12px;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #adadad;
}

.strike > span:before {
    right: 100%;
    margin-right: 5px;
}

.strike > span:after {
    left: 100%;
    margin-left: 5px;
}

.link-text-color {
    cursor: pointer;
    font-weight: bold;
    color: var(--ion-color-dark);
}
</style>