import useSystem from "@/composable/useSystem";
import useMainData from "@/composable/useMainData";
import {Geolocation} from '@capacitor/geolocation';
import {Plugins} from "@capacitor/core";
const {BackgroundGeolocation} = Plugins;
import { Dialog } from '@capacitor/dialog';
import { NativeSettings, AndroidSettings } from 'capacitor-native-settings';

export class MobiPointsTrackingGeolocationBackgroundGeolocationPermission {

	public async checkPermissions(autoHandlePermission = false)
	{
		let permissionResult = false;

		const checkedPermission = await Geolocation.checkPermissions();
		permissionResult = checkedPermission.location == 'granted';
		if (!permissionResult) {
			permissionResult = checkedPermission.coarseLocation == 'granted';
		}
		if (!permissionResult && autoHandlePermission) {
			permissionResult = await this.handlePermission();
		}
		if (permissionResult && useSystem().isNativePlatform() && useSystem().isAndroidPlatform()) {
			if (!await this.hasCurrentPosition())
			{
				permissionResult = false;
			}
		}
		useSystem().addLog('Check  Perm: ' + JSON.stringify(permissionResult))
		return permissionResult;
	}

	public async handlePermission(){
		let permissionResult = false;
		let permissionGranted = false;

		if (useSystem().isNativePlatform()) {
			const requestPermission = await Geolocation.requestPermissions();
			useSystem().addLog('Permission CHECK - ' + JSON.stringify(requestPermission))
			let enableHighAccuracy = false;
			if (requestPermission.location == 'granted') {
				enableHighAccuracy = true;
				permissionResult = true;
				permissionGranted = true;
			} else {
				permissionResult = false;
			}
			if (!permissionResult) {
				permissionResult = requestPermission.coarseLocation == 'granted';
			}
			useMainData().setEnableHighAccuracy(enableHighAccuracy);

			if (permissionResult && useSystem().isAndroidPlatform() && !await this.hasCurrentPosition())
			{
				permissionResult = false;
				await NativeSettings.openAndroid({
					option: AndroidSettings.Location,
				});
			}

			if (!permissionResult && !permissionGranted) {
				const { value } = await Dialog.confirm({
					title: 'Standort erforderlich',
					message: `MobiPoints braucht deinen Standort, hat aber keine Erlaubnis\n\nEinstellungen jetzt öffnen?`,
				});
				if (value) {
					try {
						const checkResponse = await BackgroundGeolocation.openSettings();
						useSystem().addLog('RESPONSE DIALOG ' + JSON.stringify(checkResponse))
						if (!checkResponse) {
							permissionResult = false;
						}
					} catch (error) {
						useSystem().addLog('checkResponse from open Settings error, ' + JSON.stringify(error));
						permissionResult = false;
					}
				}
			}
		} else {
			const requestPermission = await navigator.permissions.query({name:'geolocation'});
			if (requestPermission.state == 'granted') {
				permissionResult = true;
			} else if (requestPermission.state == 'prompt') {
				permissionResult = await this.hasCurrentPosition();
			} else if (requestPermission.state == 'denied') {
				permissionResult = await this.hasCurrentPosition();
			}
			requestPermission.onchange = function() {
				console.log('geolocation permission state has changed to ', this.state);
			};
			requestPermission.addEventListener('change', () => {
				if (requestPermission.state == 'granted') {
					useSystem().addLog('GPS PERM GRANTED');
				} else {
					permissionResult = false;
				}
			});
		}

		return permissionResult;
	}

	protected async hasCurrentPosition()
	{
		let result = false;
		try {
			const currentPosition = await Geolocation.getCurrentPosition();
			result = !!(currentPosition && currentPosition.coords.latitude && currentPosition.coords.longitude);
		} catch (error) {
			console.log('current position error', error)
		}
		return result;
	}

}