import {MobiPointsGeoCodingFactory} from "@/mobipoints/geocoding/geocoding_factory.type";

export default function useGeoCodingFactory() {
	let geoCodingFactory: MobiPointsGeoCodingFactory | null = null;
	const getGeoCodingFactory = (): MobiPointsGeoCodingFactory => {
		if (!geoCodingFactory) {
			geoCodingFactory = new MobiPointsGeoCodingFactory();
		}

		return geoCodingFactory;
	}

	return {
		getGeoCodingFactory,
	}
}