import MobiPointsApiClient from "./client";
import {AxiosError} from "axios";

class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const MobiPointApiSystem = {
	ping: async function() {
		try {
			return MobiPointsApiClient.get("/ping");
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	pong: async function() {
		try {
			// console.log("Test-CSRF" + await Api.getCsrfToken());
			MobiPointsApiClient.setHeader();
			//Api.testResetAuth();
			return MobiPointsApiClient.get("/pong");
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	}
}

export { MobiPointApiSystem, ResponseError };