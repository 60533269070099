import {Geolocation, ClearWatchOptions} from '@capacitor/geolocation';
import useTracking from "@/composable/useTracking";
import useMainData from "@/composable/useMainData";
import useSystem from "@/composable/useSystem";
import {
	maxCachedLocationInSeconds,
	MobiPointsTrackingGeolocationAbstractBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/abstract_background_geolocation";
import {
	formatTimestamp,
	getDateDiffInSeconds
} from "@/mobipoints/core/date";
import useData from "@/composable/useData";

export class MobiPointsTrackingGeolocationWebBackgroundGeolocation extends MobiPointsTrackingGeolocationAbstractBackgroundGeolocation{
	//Wartezeit in Millisekunden, bis die Positionsbestimmung abgebrochen und, so gegeben, die Funktion error aufgerufen wird
	private watchMaximumAge = 5000;
	private watchTimeout = 15000;

	public checkPermissions() {
		let permissionResult = false;
		Geolocation.checkPermissions().then(result => {
			permissionResult = result.location == 'granted';
			if (!permissionResult) {
				permissionResult = result.coarseLocation == 'granted';
			}
			if (!permissionResult) {
				this.handlePermission();
			}
		}).catch(err => {
			// useSystem().addLogByError(err);
		});
		return true;
	}

	public handlePermission() {
		let permissionResult = true;
		if (useSystem().isNativePlatform()) {
			useSystem().addLog('NATIVE CHECK')
			Geolocation.requestPermissions().then(result => {
				useSystem().addLog('RESULT CHECK - ' + JSON.stringify(result))
				let enableHighAccuracy = false;
				if (result.location == 'granted') {
					enableHighAccuracy = true;
					permissionResult = true;
				} else {
					permissionResult = false;
				}
				if (!permissionResult) {
					permissionResult = result.coarseLocation == 'granted';
				}
				useMainData().setEnableHighAccuracy(enableHighAccuracy);
			}).catch(err => {
				permissionResult = false;
				useSystem().addLogByError(err);
			});
		} else {
			navigator.permissions.query({name:'geolocation'}).then(result => {
				if (result.state == 'granted') {
					this.report(result.state);
					permissionResult = true;
					setTimeout(() => {
						if (!this.getPositionWatchId()) {
							this.startGeolocationTracking(true);
						}
					}, 10000);
				} else if (result.state == 'prompt') {
					this.report(result.state);
					Geolocation.getCurrentPosition();
					permissionResult = true;
				} else if (result.state == 'denied') {
					this.report(result.state);
					permissionResult = false;
				}
				result.onchange = function() {
					console.log('geolocation permission state has changed to ', this.state);
				};
				result.addEventListener('change', () => {
					if (result.state == 'granted') {
						useSystem().addLog('GPS PERM GRANTED');
						this.startGeolocationTracking(true).then(result => console.log(result));
					} else {
						permissionResult = false;
					}
					this.report(result.state);
				});
			});
		}

		return permissionResult;
	}

	protected report(state: string) {
		console.log('Permission ' + state);
		useSystem().addLog('Permission ' + state)
	}

	protected isPositionValid(position: any): boolean {
		return !!((position && position.coords && position.timestamp) || position instanceof GeolocationPosition);
	}

	//TODO calculate maximumAge by track type!

	protected getWatchCallbackOptions(): any {
		if (useSystem().isNativePlatform()) {
			// return {};
			return {
				enableHighAccuracy: useMainData().userProfileSettings.value.enableHighAccuracy ?? true,
				timeout: this.watchTimeout,
				maximumAge: this.watchMaximumAge //0
				// maximumAge: 0
				// maximumAge: 0 //Infinity
			}
		} else {
			return {
				enableHighAccuracy: useMainData().userProfileSettings.value.enableHighAccuracy ?? true,
				timeout: this.watchTimeout,
				maximumAge: this.watchMaximumAge //Infinity
				// maximumAge: Infinity
				// maximumAge: 0 //Infinity
			}
		}
	}

	/**
	 *
	 * @protected
	 */
	protected async startNativeGeolocation() {
		if (this.hasEmptyOrInvalidPositionWatchId()) {
			const callbackOptions = this.getWatchCallbackOptions();
			useSystem().addLog('CALLBACK OPTIONS: ' + JSON.stringify(callbackOptions))
			const watchId = await Geolocation.watchPosition(
				callbackOptions,
				async (position, error) => {
					// console.log("POSITION", position, error)
					this.checkPermissions();
					if (this.isPositionValid(position)) {
						const diffLocationTime = Number(getDateDiffInSeconds((new Date()).getTime(), position?.timestamp ? position.timestamp : 0, false));
						if (useData().getSystemValueByKey('feature_flag_check_location_timestamp_age', true) && diffLocationTime > maxCachedLocationInSeconds) {
							console.log("TIME WEB, DIFF ( " + diffLocationTime + " ) : ," + position?.timestamp + ", " + formatTimestamp(position?.timestamp ? position.timestamp : 0), formatTimestamp((new Date()).getTime()));
							/*
							It's probably because you have enableHighAccuracy set to true. When you're in a building, the phone fails to accurately locate you and times out.
							What I did is:

							First fetch the user position in high accuracy and a 2 seconds timeout. (2000)
							Catch the exception, and retry with high accuracy disabled (enableHighAccuracy: false)
							 */
							const options = {
								enableHighAccuracy: false,
								timeout: 20000,
								maximumAge: 1000,
							};
							position = await Geolocation.getCurrentPosition(options);
							useSystem().setBackgroundTrackingActive(false);
							await this.startTrackingAutoStopWatcher();
							this.setCurrentPosition_by_Position(position);
						} else {
							useSystem().setBackgroundTrackingActive(false);
							await this.startTrackingAutoStopWatcher();
							this.setCurrentPosition_by_Position(position);
						}
					} else {
						console.log("POS ERROR", position);
						useSystem().addLogByError(error);
						this.positionError(error);
					}
				});
			if (watchId) {
				this.setPositionWatchId(Number(watchId));
			}
		}
	}


	protected setCurrentPosition_by_Position(position: GeolocationPosition|any) {
		if (this.getPositionWatchId() === null || !this.getPositionWatchId()) {
			return false;
		}
		this.updateCurrentPosition(
			position.coords.latitude,
			position.coords.longitude,
			position.coords.accuracy,
			position.coords.altitude,
			position.coords.altitudeAccuracy,
			position.coords.heading,
			position.coords.speed,
			position.timestamp
		);
	}


	protected positionError(error) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.error("User denied the request for Geolocation.");
				this.stopGeolocationTracking();
				break;

			case error.POSITION_UNAVAILABLE:
				console.error("Location information is unavailable.");
				break;

			case error.TIMEOUT:
				console.error("The request to get user location timed out.");
				this.stopGeolocationTracking();
				break;

			case error.UNKNOWN_ERROR:
				console.error("An unknown error occurred.");
				this.stopGeolocationTracking();
				break;

			default:
				console.error(error);
				useSystem().addLog(JSON.stringify(error));
				this.startGeolocationTracking(true);
				break
		}
	}

	protected updateCurrentPosition(latitude: number, longitude: number, accuracy?: number, altitude?: number | null, altitudeAccuracy?: number | null, heading?: number | null, speed?: number | null, timestamp?: number | null) {
		const coordinate = this.getTrackingFactory().createCoordinate(latitude, longitude, timestamp);
		if (useTracking().isPositionCloseToLatestPosition(coordinate, 30)) {
			return;
		}
		if (useTracking().hasCurrentPositionChanged(coordinate)) {
			this.setCurrentPosition(coordinate);
		}
	}

	public async startGeolocationTracking(forceStart = false) {
		if (this.getPositionWatchId() && forceStart) {
			await this.stopGeolocationTracking();
		}
		super.startGeolocationTracking();
		await this.startNativeGeolocation();
	}


	public async stopGeolocationTracking() {
		super.stopGeolocationTracking();
		if (this.getPositionWatchId() === null && this.getPositionWatchId() !== undefined) {
			return false;
		}
		const opt: ClearWatchOptions = {id: this.getPositionWatchId()};
		await Geolocation.clearWatch(opt);
		this.setPositionWatchId(null);
		return true;
	}
}
