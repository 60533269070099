import {
	MobiPointsQueueEventAbstractEvent,
	EVENT_STATE_NEW, EVENT_TYPE_WEATHER_DATA,
} from "@/mobipoints/queue/event/abstract_event.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";

export class MobiPointsQueueEventTypeWeatherDataEvent extends MobiPointsQueueEventAbstractEvent {

	constructor(userTrackingUuid: string|null, eventName: string, timestamp: number, subType: string, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {weatherData: MobiPointsTrackingWeatherWeather}}) {
		super(userTrackingUuid, eventName, timestamp, subType, state, payload);
	}

	getEventType(): string {
		return EVENT_TYPE_WEATHER_DATA;
	}
}
