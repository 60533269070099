import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "ion-text-center ion-padding-bottom" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qr_input = _resolveComponent("qr-input")!
  const _component_input_split = _resolveComponent("input-split")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_treasure_found_animation = _resolveComponent("treasure-found-animation")!
  const _component_check_animation = _resolveComponent("check-animation")!
  const _component_check_mark_animation = _resolveComponent("check-mark-animation")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_invalid_animation = _resolveComponent("invalid-animation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.submitSend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("h2", null, "Bitte gib deinen Gutschein Code ein!", 512), [
            [_vShow, !_ctx.isQrScanStarted]
          ]),
          _withDirectives(_createVNode(_component_qr_input, {
            onSubmitScan: _ctx.submit,
            onErrorScan: _ctx.errorQrScan,
            onStartScan: _ctx.startQrScan,
            onCancelScan: _ctx.cancelQrScan,
            class: "ion-padding-top"
          }, null, 8, ["onSubmitScan", "onErrorScan", "onStartScan", "onCancelScan"]), [
            [_vShow, _ctx.isNativePlatform()]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _withDirectives(_createVNode(_component_input_split, {
              onSubmitPinInput: _ctx.submit,
              onHandleInputCount: _ctx.handlePinInputCount,
              "input-length": $props.inputLength,
              class: "ion-padding-top ion-padding-bottom"
            }, null, 8, ["onSubmitPinInput", "onHandleInputCount", "input-length"]), [
              [_vShow, !_ctx.isQrScanStarted]
            ]),
            _withDirectives(_createVNode(_component_ion_button, {
              color: "success",
              expand: "block",
              onClick: _ctx.submit
            }, {
              default: _withCtx(() => [
                _createTextVNode("Einlösen")
              ]),
              _: 1
            }, 8, ["onClick"]), [
              [_vShow, _ctx.inputPinCount === $props.inputLength]
            ]),
            _withDirectives(_createVNode(_component_ion_button, {
              color: "danger",
              expand: "block",
              fill: "outline",
              class: _normalizeClass([_ctx.isQrScanStarted ? 'showButtonAtBottom' : '']),
              onClick: _ctx.goBackToStartPage
            }, {
              default: _withCtx(() => [
                _createTextVNode("Abbrechen ")
              ]),
              _: 1
            }, 8, ["class", "onClick"]), [
              [_vShow, !_ctx.isQrScanStarted]
            ])
          ], 512), [
            [_vShow, $props.showInputField && $props.voucherType !== _ctx.treasureHuntVoucherType]
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.submitSend && _ctx.submitResult)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ($props.voucherType === _ctx.treasureHuntVoucherType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_treasure_found_animation)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_check_animation),
                      _createVNode(_component_check_mark_animation)
                    ])),
                _createVNode(_component_ion_text, { "v-html": _ctx.submitMessage }, null, 8, ["v-html"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.submitSend && !_ctx.submitResult)
            ? (_openBlock(), _createBlock(_component_invalid_animation, { key: 1 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.submitResult)
              ? (_openBlock(), _createBlock(_component_ion_text, {
                  key: 0,
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Juhuu! "),
                    _createElementVNode("span", { innerHTML: _ctx.submitMessage }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_text, {
                  key: 1,
                  color: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ohh! "),
                    _createElementVNode("span", { innerHTML: _ctx.errorMessage }, null, 8, _hoisted_8)
                  ]),
                  _: 1
                }))
          ]),
          (_ctx.submitResult)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_ion_button, {
                  expand: "block",
                  fill: "outline",
                  onClick: _ctx.redeemAnotherVoucher
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Weiteren Gutschein Code eingeben ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.submitResult)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      class: "ion-margin-top",
                      color: "danger",
                      expand: "block",
                      fill: "outline",
                      onClick: _ctx.goBackToStartPage
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Zurück zur Startseite ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_ion_button, {
                  expand: "block",
                  fill: "outline",
                  onClick: _ctx.redeemAnotherVoucher
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Probier es nochmal!")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_ion_button, {
                  class: "ion-margin-top",
                  color: "danger",
                  expand: "block",
                  fill: "outline",
                  onClick: _ctx.goBackToStartPage
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Abbrechen ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
        ]))
  ]))
}