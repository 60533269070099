import useTracking from "@/composable/useTracking";
import useData from "@/composable/useData";
const {getTextValueByKey} = useData();
const {currentTracking, hasActiveTrack, hasActiveTracking, getLatestTrack, startCoordinate, stopCurrentTracking, jumpToLastActiveCard} = useTracking();
import {subMinutes} from "@/mobipoints/core/date";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import useToastMessage from "@/components/core/ToastMessage.vue";
import {DistanceCalculation} from "@/mobipoints/tracking/geolocation/distance_calculation";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import useGeoCodingFactory from "@/composable/useGeoCodingFactory";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import {TRACK_STATE_DONE, TRACK_STATE_PAUSE} from "@/mobipoints/tracking/track/abstract_track.type";
import useSystem from "@/composable/useSystem";
const {openToast} = useToastMessage();
const {getTrackingFactory} = useTrackingFactory();

export class MobiPointsTrackingGeolocationWatcherStopPositionWatcher {
	//TODO check last positions 5 minutes and stop if pos is same and min one other coordinate is exisiting outside of the range
	//TODO check every minute

    protected getMaxDistanceInMeter(): number
    {
        return Number(useData().getSystemValueByKey('tracking.tracking_auto_stop.max_distance_in_meter', 110));
    }

    public async check()
    {
        if (!this.hasTrackingActiveTracking()) {
            return false;
        }
        if (!this.checkTime()) {
            return false;
        }
        if (!this.isFirstPositionDifferentThanLastPosition()) {
            return false;
        }
        return this.isPositionOnSameLocation();
    }

    protected hasTrackingActiveTracking(): boolean
    {
        if (hasActiveTrack.value) {
            const latestTrackState = getLatestTrack.value?.state;
            if (latestTrackState) {
                const activeTrackState_List = [TRACK_STATE_DONE, TRACK_STATE_PAUSE];
                return !activeTrackState_List.includes(latestTrackState);
            } else {
                return true;
            }
        }
        return false;
    }

    protected checkTime(): boolean
    {
        if (!currentTracking.value) {
            return false;
        }

        const minDuration = useData().getSystemValueByKey('tracking.tracking_auto_stop_min_time', 60*5); //min_time in seconds
        const duration =  currentTracking.value.calculateDuration();
        if (!duration || duration < 0) {
            console.log('Invalid duration!');
            return false;
        }

        return duration > minDuration;
    }

    protected isFirstPositionDifferentThanLastPosition(): boolean
    {
        if (startCoordinate.value && currentTracking.value?.getLastCoordinate_by_TrackList()) {
            const endCoordinate = currentTracking.value?.getLastCoordinate_by_TrackList();
            const locationTypes = [MobiPointsLocationTypes.HOME_LOCATION, MobiPointsLocationTypes.COMPANY_LOCATION];
            const isStartPositionCloseToCompanyOrHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(startCoordinate.value, locationTypes, this.getMaxDistanceInMeter())
            if (isStartPositionCloseToCompanyOrHomeLocation && endCoordinate) {
                const isEndPositionCloseToCompanyOrHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(endCoordinate, locationTypes, this.getMaxDistanceInMeter())
                if (isEndPositionCloseToCompanyOrHomeLocation) {
                    const distanceBetweenStartAndEndCoordinate = this.getDistanceCalculation().calculateDistance(startCoordinate.value, endCoordinate, true);
                    const maxDistanceBetweenStartAndLastPosition = Number(useData().getSystemValueByKey('tracking.tracking_auto_stop.max_distance_between_first_and_last_position_in_meter', 80));
                    if (distanceBetweenStartAndEndCoordinate > maxDistanceBetweenStartAndLastPosition) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    protected isPositionOnSameLocation(): boolean
    {
        const coordinateMap = currentTracking.value?.getAllCoordinatesForMap();
        if (coordinateMap) {
            let firstPosition: null|MobiPointsTrackingCoordinateCoordinateInterface = null;
            const startDate = subMinutes(5);
            let isFirstPositionCloseToCompanyOrHomeLocation: null|boolean = null;
            const locationTypes = [MobiPointsLocationTypes.HOME_LOCATION, MobiPointsLocationTypes.COMPANY_LOCATION];
            const minStayAtTheSamePlaceInPercent = 90;
            let percentageStayOfTheLastMinutes: any = 0;
            let coordinateCurrentStayCount = 0;
            const coordinateList = coordinateMap.getItemsForTimeRange(startDate);
            const coordinateCount = coordinateList.length;
            const minCoordinatesAtSameRange = 1;
            return coordinateList.some((coordinate: MobiPointsTrackingCoordinateCoordinate) => {
                if (!firstPosition) {
                    isFirstPositionCloseToCompanyOrHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(coordinate, locationTypes, this.getMaxDistanceInMeter())
                    if (isFirstPositionCloseToCompanyOrHomeLocation) {
                        firstPosition = coordinate;
                        coordinateCurrentStayCount++;
                    } else {
                        return false;
                    }
                } else {
                    const isPositionCloseToCompanyOrHomeLocation = useGeoCodingFactory().getGeoCodingFactory().isCoordinateCloseToAddress(coordinate, locationTypes, this.getMaxDistanceInMeter())
                    if (isPositionCloseToCompanyOrHomeLocation) {
                        coordinateCurrentStayCount++;
                    }
                }
                percentageStayOfTheLastMinutes = parseFloat(((coordinateCurrentStayCount*100)/coordinateCount).toFixed(2));
                if (percentageStayOfTheLastMinutes >= minStayAtTheSamePlaceInPercent && coordinateCount >= minCoordinatesAtSameRange) {
                    return true;
                }
            });
        }

        return false;
    }

    public async stopActiveTracking() {
        if (hasActiveTracking.value) {
            try {
                //TODO add addtional info to tracking card - stopped automatically
                await stopCurrentTracking();
                await openToast(getTextValueByKey('tracking.auto.stopped.successfully', [], 'Tracking wurde automatisch beendet!'), 'success', 'bottom', true, 5000, undefined, true);
                jumpToLastActiveCard();
            } catch (error) {
                console.log(error);
                useSystem().addLog('Error stopping track automatically, message: ' + JSON.stringify(error));
                await openToast(getTextValueByKey('tracking.auto.stopped.error', [], 'Fehler, Tracking konnte nicht automatisch beendet werden'), 'danger', 'bottom', true, 5000, undefined, true);
            }
        }
    }

    protected getDistanceCalculation(): DistanceCalculation {
        return getTrackingFactory().createDistanceCalculation();
    }
}
