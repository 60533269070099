import {UserTrackingTrackType} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.type";
import useProfileFactory from "@/composable/useProfileFactory";
import {
	MobiPointsProfileUserTrackingTrackTypeInterface
} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.interface";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {
	MobiPointsLocationTypeInterface,
	MobiPointsLocationTypes
} from "@/mobipoints/location/location_type.interface";
import {AxiosError} from "axios";
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import {UserSettings} from "@/mobipoints/profile/userSettings/user_settings.type";
import {MobiPointsProfileUserSettingsInterface} from "@/mobipoints/profile/userSettings/user_settings.interface";

interface MainDataStateContainer<T> {
	loaded: boolean,
	data: T,
}

const resetUserTrackingTrackTypes = (state: any) => {
	state.userTrackingTrackTypes.loaded = false;
	state.userTrackingTrackTypes.data = [];
};

const resetUserLocations = (state: any) => {
	state.userLocations.loaded = false;
	state.userLocations.data = [];
};

const resetUuids = (state: any) => {
	state.uuidList.loaded = false;
	state.uuidList.data = [];
};

const resetProfileSettings = (state: any) => {
	state.userProfileSettings.loaded = false;
	state.userProfileSettings.data = {};
};


const {getProfileFactory} = useProfileFactory();

const mainData = {
	namespaced: true,
	state: () => ({
		userTrackingTrackTypes: {
			loaded: false,
			data: []
		} as MainDataStateContainer<Array<UserTrackingTrackType | MobiPointsProfileUserTrackingTrackTypeInterface>>,
		userLocations: {
			loaded: false,
			data: []
		} as MainDataStateContainer<Array<UserLocation | MobiPointsProfileUserLocationInterface>>,
		uuidList: {
			loaded: false,
			data: []
		} as MainDataStateContainer<Array<string>>,
		userProfileSettings: {
			loaded: false,
			data: {}
		} as MainDataStateContainer<UserSettings | MobiPointsProfileUserSettingsInterface>,
		locationTypes: {
			loaded: false,
			data: []
		} as MainDataStateContainer<Array<MobiPointsLocationTypes>>
	}),
	mutations: {
		userTrackingTrackTypes(state: any, value: Array<MobiPointsProfileUserTrackingTrackTypeInterface>) {
			state.userTrackingTrackTypes.loaded = true;
			state.userTrackingTrackTypes.data = value;
		},
		userLocations(state: any, value: Array<MobiPointsProfileUserLocationInterface>) {
			state.userLocations.loaded = true;
			state.userLocations.data = value;
		},
		locationTypes(state: any, value: Array<MobiPointsLocationTypeInterface>) {
			state.locationTypes.loaded = true;
			state.locationTypes.data = value;
		},
		removeUserLocation(state: any, id: number) {
			state.userLocations.data = state.userLocations.data.filter((userLocation) => id !== userLocation.id);
		},
		updateUserLocation(state: any, userLocation: UserLocation) {
			const idx = state.userLocations.data.findIndex((val) => userLocation.id === val.id);
			console.log("update user location at index", idx, userLocation.id, idx >= 0 && state.userLocations.data[idx]);
			if (idx >= 0) {
				for (const prop in userLocation) {
					state.userLocations.data[idx][prop] = userLocation[prop];
				}
			}
		},
		uuids(state: any, value: Array<string>) {
			state.uuidList.loaded = true;
			state.uuidList.data = value;
		},
		userProfileSettings(state: any, value: MobiPointsProfileUserSettingsInterface) {
			state.userProfileSettings.loaded = true;
			state.userProfileSettings.data = value;
		},
		setEnableHighAccuracy(state: any, value: boolean) {
			state.userProfileSettings.data.enableHighAccuracy = value;
		},
		setGeoLocationInterval(state: any, value: number|null) {
			state.userProfileSettings.data.geoLocationInterval = value;
		},
		resetUserTrackingTrackTypes(state: any) {
			resetUserTrackingTrackTypes(state);
		},
		resetUserLocations(state: any) {
			resetUserLocations(state);
		},
		resetUuids(state: any) {
			resetUuids(state);
		},
		resetProfileSettings(state: any) {
			resetProfileSettings(state);
		},
		removeUuidFromList(state: any, value: string) {
			const removeUuidIndex = state.uuidList.data.indexOf(value);
			if (removeUuidIndex !== -1) {
				state.uuidList.data.splice(removeUuidIndex, 1);
			}
		},
		resetAll(state: any) {
			resetUserTrackingTrackTypes(state);
			resetUserLocations(state);
		}
	},
	actions: {
		async removeUserLocation(context: any, id: number): Promise<string | null> {
			try {
				const response = await MobiPointApiProfile.deleteUserLocation(id);
				if (200 === response.status) {
					context.commit('removeUserLocation', id);
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
		async updateUserLocation(context: any, userLocation: UserLocation): Promise<string | null> {
			try {
				const response = await MobiPointApiProfile.updateUserLocation(userLocation);
				if (200 === response.status) {
					context.commit('updateUserLocation', userLocation);
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		}
	},
	getters: {
		userTrackingTrackTypes: (state: any) => {
			return state.userTrackingTrackTypes.data.map((data) => {
				if (!(data instanceof UserTrackingTrackType)) {
					return getProfileFactory().createUserTrackingTrackType(data.code, data.name);
				}

				return data;
			});
		},
		userLocations: (state: any) => {
			return state.userLocations.data.map((data) => {
				if (!(data instanceof UserLocation)) {
					return getProfileFactory().createProfileUserLocationByApiResponse(data);
				}
			});
		},
		userCompanyLocations: (state: any) => {
			const companyLocations: Array<UserLocation | MobiPointsProfileUserLocationInterface> = [];
			const companySaaz: MobiPointsProfileUserLocationInterface = {
				id: 1,
				type: {code: MobiPointsLocationTypes.COMPANY_LOCATION, name: 'Firmenstandort', icon: ''},
				latitude: 46.957230,
				longitude: 15.850660,
				country: { code: 'AT', name: 'Österreich'},
				city: 'Paldau',
				street: 'Saaz',
				streetNumber: '99',
				postalCode: '8341',
			};
			const companyGraz: MobiPointsProfileUserLocationInterface = {
				id: 2,
				type: {code: MobiPointsLocationTypes.COMPANY_LOCATION, name: 'Firmenstandort', icon: ''},
				latitude: 47.070862,
				longitude: 15.428080,
				country: { code: 'AT', name: 'Österreich'},
				city: 'Graz',
				street: 'Annenstraße',
				streetNumber: '23',
				postalCode: '8020',
			};
			const companyVienna: MobiPointsProfileUserLocationInterface = {
				id: 3,
				type: {code: MobiPointsLocationTypes.COMPANY_LOCATION, name: 'Firmenstandort', icon: ''},
				latitude: 48.212140,
				longitude: 16.361660,
				country: { code: 'AT', name: 'Österreich'},
				city: 'Wien',
				street: 'Universitätsring',
				streetNumber: '8',
				postalCode: '1010',
			};
			const companyUlm: MobiPointsProfileUserLocationInterface = {
				id: 4,
				type: {code: MobiPointsLocationTypes.COMPANY_LOCATION, name: 'Firmenstandort', icon: ''},
				latitude: 48.431370,
				longitude: 9.983600,
				country: { code: 'DE', name: 'Deutschland'},
				city: 'Ulm',
				street: 'Franzenhauserweg',
				streetNumber: '15',
				postalCode: '89081',
			};
			companyLocations.push(
				getProfileFactory().createProfileUserLocationByApiResponse(companySaaz),
				getProfileFactory().createProfileUserLocationByApiResponse(companyGraz),
				getProfileFactory().createProfileUserLocationByApiResponse(companyVienna),
				getProfileFactory().createProfileUserLocationByApiResponse(companyUlm)
			)

			return companyLocations;
		},
		uuids: (state: any) => {
			return state.uuidList.data.map((data) => {
				return data;
			});
		},
		locationTypes: (state: any) => {
			return state.locationTypes.data;
		},
		userProfileSettings: (state: any) => {
			if (!(state.userProfileSettings.data instanceof UserSettings)) {
				return getProfileFactory().createUserSetting(state.userProfileSettings.data);
			}
			return state.userProfileSettings.data;
			// return state.userProfileSettings.data.map((data) => {
			// 	if (!(data instanceof UserSettings)) {
			// 		return getProfileFactory().createUserSetting(data);
			// 	}
			// });
			// return state.userProfileSettings.data;
		}
	}
}

export default mainData;