import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae8341d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-margin" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "text-margin" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-margin" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "text-margin" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_GlobalWarmingAnimation = _resolveComponent("GlobalWarmingAnimation")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_HappyEarthAnimation = _resolveComponent("HappyEarthAnimation")!
  const _component_WalkingAnimation = _resolveComponent("WalkingAnimation")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_BicycleAnimation = _resolveComponent("BicycleAnimation")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_WinnerAnimation = _resolveComponent("WinnerAnimation")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { "no-border": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tutorialTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_ion_button, {
                    color: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.endTutorial()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Skip")
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, !$setup.showSkip]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_swiper, {
            ref: "tutorialSlides",
            onSlideNextTransitionStart: _cache[2] || (_cache[2] = ($event: any) => ($setup.activeIndex++)),
            onSlidePrevTransitionStart: _cache[3] || (_cache[3] = ($event: any) => ($setup.activeIndex--)),
            onSlideChange: _cache[4] || (_cache[4] = ($event: any) => ($setup.onSlideChangeStart($event))),
            pager: "true",
            style: {"height":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_swiper_slide, null, {
                default: _withCtx(() => [
                  ($setup.activeIndex === 0)
                    ? (_openBlock(), _createBlock(_component_GlobalWarmingAnimation, {
                        key: 0,
                        class: "animationSize"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h2", {
                      class: "slide-title",
                      innerHTML: $setup.tutorialSlide1Title
                    }, null, 8, _hoisted_2),
                    _createElementVNode("p", { innerHTML: $setup.tutorialSlide1Description }, null, 8, _hoisted_3)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_swiper_slide, null, {
                default: _withCtx(() => [
                  ($setup.activeIndex === 1)
                    ? (_openBlock(), _createBlock(_component_HappyEarthAnimation, {
                        key: 0,
                        class: "animationSize"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", {
                      class: "slide-title",
                      innerHTML: $setup.tutorialSlide2Title
                    }, null, 8, _hoisted_5),
                    _createElementVNode("p", { innerHTML: $setup.tutorialSlide2Description }, null, 8, _hoisted_6)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_swiper_slide, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              ($setup.activeIndex === 2)
                                ? (_openBlock(), _createBlock(_component_WalkingAnimation, {
                                    key: 0,
                                    class: "twoAnimationsSize"
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              ($setup.activeIndex === 2)
                                ? (_openBlock(), _createBlock(_component_BicycleAnimation, {
                                    key: 0,
                                    class: "twoAnimationsSize"
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h2", {
                      class: "slide-title",
                      innerHTML: $setup.tutorialSlide3Title
                    }, null, 8, _hoisted_8),
                    _createElementVNode("p", { innerHTML: $setup.tutorialSlide3Description }, null, 8, _hoisted_9)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_swiper_slide, null, {
                default: _withCtx(() => [
                  ($setup.activeIndex === 3)
                    ? (_openBlock(), _createBlock(_component_WinnerAnimation, {
                        key: 0,
                        class: "animationSize"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("h2", {
                      class: "slide-title",
                      innerHTML: $setup.tutorialSlide4Title
                    }, null, 8, _hoisted_11),
                    _createVNode(_component_ion_button, {
                      fill: "clear",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.endTutorial()))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", { innerHTML: $setup.tutorialSlide4Description }, null, 8, _hoisted_12),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          name: "arrow-forward"
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}