import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { fullscreen: "" }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_ion_list, {
        lines: "full",
        inset: "true"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userLocationListData, (userLocation) => {
            return (_openBlock(), _createBlock(_component_ion_item_sliding, {
              key: userLocation.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, {
                  onClick: ($event: any) => (_ctx.showDetailLocation(userLocation.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: userLocation.type.icon,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userLocation.getFormattedAddressList(), (addressItem, idx) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: idx,
                            style: {"display":"block"}
                          }, [
                            (idx === 0)
                              ? (_openBlock(), _createElementBlock("strong", _hoisted_1, _toDisplayString(addressItem), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(addressItem), 1))
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_ion_item_options, { side: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item_option, {
                      color: "danger",
                      onClick: ($event: any) => (_ctx.deleteUserLocation(userLocation.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Delete ")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.userLocationListData.length > 0]
      ]),
      _withDirectives(_createVNode(_component_ion_list_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Keine Addressen gefunden")
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.userLocationListData.length === 0]
      ])
    ]),
    _: 1
  }))
}