import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import useData from '@/composable/useData';
import * as icons from 'ionicons/icons';

export class WalletTreasures extends AbstractWallet{
	getWalletTitle(): string {
		return useData().getTextValueByKey('mobipoints.wallet.title.treasures', [], 'Meine Treasure Hunt Lose');
	}

	getWalletPointsName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.treasures', [], 'Lose');
	}

	getEmptyWalletPointsMessage(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.treasures.empty', [], 'Du hast noch keine Lose gesammelt<br>Mach jetzt mit und sichere dir höhere Chancen auf einen Gewinn bei der Verlosung!');
	}

	getWalletImage(): string {
		return icons.ribbonOutline;
	}
}