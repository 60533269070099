import {
	MobiPointsChallengeUserChallengeDataInterface,
	MobiPointsChallengeUserChallengeInterface
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import {
	MobiPointsChallengeUserChallengeStateInterface
} from "@/mobipoints/challenge/userChallenge/user_challenge_state.interface";

export class UserChallenge implements MobiPointsChallengeUserChallengeInterface {
	private _user_id: number | null = null;
	private _challenge_uuid: string | null = null;
	private _state: MobiPointsChallengeUserChallengeStateInterface | null = null;
	private _progress: number | null = null;
	private _data: MobiPointsChallengeUserChallengeDataInterface | null = null;

	constructor(user_id: number, challenge_uuid: string, state: MobiPointsChallengeUserChallengeStateInterface, progress: number) {
		this.user_id = user_id;
		this.challenge_uuid = challenge_uuid;
		this.state = state;
		this.progress = progress;
	}


	get user_id(): number {
		return this._user_id as number;
	}

	set user_id(value: number) {
		this._user_id = value;
	}

	get challenge_uuid(): string {
		return this._challenge_uuid as string;
	}

	set challenge_uuid(value: string) {
		this._challenge_uuid = value;
	}

	get state(): MobiPointsChallengeUserChallengeStateInterface {
		return this._state as MobiPointsChallengeUserChallengeStateInterface;
	}

	set state(value: MobiPointsChallengeUserChallengeStateInterface) {
		this._state = value;
	}

	get data(): MobiPointsChallengeUserChallengeDataInterface | null {
		return this._data;
	}

	set data(value: MobiPointsChallengeUserChallengeDataInterface | null) {
		this._data = value;
	}

	get progress(): number {
		return this._progress as number;
	}

	set progress(value: number) {
		this._progress = value;
	}
}