import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import {MobiPointsTrackingGeolocationWebBackgroundGeolocation} from "@/mobipoints/tracking/geolocation/web/web_background_geolocation";
import {DistanceCalculation} from "@/mobipoints/tracking/geolocation/distance_calculation";
import {MobiPointsTrackingTracking, TRACKING_STATE_NEW} from "@/mobipoints/tracking/tracking.type";
import {EVENT_STATE_NEW} from "@/mobipoints/queue/event/abstract_event.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import * as icons from 'ionicons/icons';
import { addIcons } from 'ionicons';

import {
	MobiPointsTrackingTrackAbstractTrack,
	TRACK_TYPE_BICYCLE,
	TRACK_TYPE_BUS, TRACK_TYPE_CAR,
	TRACK_TYPE_CARPOOL, TRACK_TYPE_E_BIKE, TRACK_TYPE_E_CAR, TRACK_TYPE_E_MOTORCYCLE, TRACK_TYPE_E_SCOOTER,
	TRACK_TYPE_FOOT,
	TRACK_TYPE_SUBWAY,
	TRACK_TYPE_TRAIN,
	TRACK_TYPE_TRAM
} from "@/mobipoints/tracking/track/abstract_track.type";
import {MobiPointsTrackingTrackTypeBicycleTrack} from "@/mobipoints/tracking/track/type/bicycle_track.type";
import {MobiPointsTrackingTrackTrackList} from "@/mobipoints/tracking/track/track_list";
import {MobiPointsTrackingCoordinateCoordinateList} from "@/mobipoints/tracking/coordinate/coordinate_list.type";
import {MobiPointsTrackingTrackTypeFootTrack} from "@/mobipoints/tracking/track/type/foot_track.type";
import {MobiPointsTrackingTrackTypeTrainTrack} from "@/mobipoints/tracking/track/type/train_track.type";
import {MobiPointsTrackingTrackTypeBusTrack} from "@/mobipoints/tracking/track/type/bus_track.type";
import {MobiPointsTrackingTrackTypeTramTrack} from "@/mobipoints/tracking/track/type/tram_track.type";
import {MobiPointsTrackingTrackTypeSubwayTrack} from "@/mobipoints/tracking/track/type/subway_track.type";
import {MobiPointsTrackingTrackTypeEBikeTrack} from "@/mobipoints/tracking/track/type/ebike_track.type";
import {MobiPointsTrackingTrackTypeECarTrack} from "@/mobipoints/tracking/track/type/ecar_track.type";
import {MobiPointsTrackingTrackTypeCarTrack} from "@/mobipoints/tracking/track/type/car_track.type";
import {MobiPointsTrackingTrackTypeEScooterTrack} from "@/mobipoints/tracking/track/type/escooter_track.type";
import {MobiPointsTrackingTrackTypeEMotorcycleTrack} from "@/mobipoints/tracking/track/type/emotorcycle_track.type";
import {MobiPointsTrackingTrackTypeCarpoolTrack} from "@/mobipoints/tracking/track/type/carpool_track.type";
import useGeoCodingFactory from "@/composable/useGeoCodingFactory";
import {
	MobiPointsTrackingGeolocationNativeBackgroundGeolocation
} from "@/mobipoints/tracking/geolocation/native/native_background_geolocation";
import {
	MobiPointsTrackingGeolocationBackgroundGeolocationFacade
} from "@/mobipoints/tracking/geolocation/background_geolocation_facade";
import {
	MobiPointsTrackingGeolocationBackgroundGeolocationPermission
} from "@/mobipoints/tracking/geolocation/background_geolocation_permission";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import bimOutline from '@/assets/images/track_types/bim.svg';
import {isTimestampInMilliSeconds, convertTimeStampToSeconds} from "@/mobipoints/core/date";
import useWeatherFactory from "@/composable/useWeatherFactory";
const {getWeatherFactory} = useWeatherFactory();


addIcons({
	'bimOutline': 'assets/img/track_types/bim.svg',
});

export class MobiPointsTrackingTrackingFactory {
	private distanceCalculation: DistanceCalculation | null = null;

	createTracking(uuid: string, trackingName = "", state: string = TRACKING_STATE_NEW, startTrackingDate = new Date()): MobiPointsTrackingTracking {
		return new MobiPointsTrackingTracking(uuid, trackingName, state, startTrackingDate)
	}

	createCoordinate(latitude: number, longitude: number, timestamp: number | null = null, ignoreTimestampCheck = false, locationSimulated: boolean | null = false): MobiPointsTrackingCoordinateCoordinate {
		if (timestamp === null) {
			timestamp = this.createCurrentTimeStamp();
		} else {
			if (!ignoreTimestampCheck && isTimestampInMilliSeconds(timestamp)) {
				timestamp = convertTimeStampToSeconds(timestamp);
			}
		}
		return new MobiPointsTrackingCoordinateCoordinate(latitude, longitude, timestamp, locationSimulated)
	}

	createCurrentTimeStamp(): number {
		return Math.floor(Date.now() / 1000)
	}

	createBackgroundGeolocationFacade(): MobiPointsTrackingGeolocationBackgroundGeolocationFacade {
		return new MobiPointsTrackingGeolocationBackgroundGeolocationFacade();
	}

	createBackgroundGeolocationPermission(): MobiPointsTrackingGeolocationBackgroundGeolocationPermission {
		return new MobiPointsTrackingGeolocationBackgroundGeolocationPermission();
	}

	createWebBackgroundGeolocation(): MobiPointsTrackingGeolocationWebBackgroundGeolocation {
		return new MobiPointsTrackingGeolocationWebBackgroundGeolocation();
	}

	createNativeBackgroundGeolocation(): MobiPointsTrackingGeolocationNativeBackgroundGeolocation {
		return new MobiPointsTrackingGeolocationNativeBackgroundGeolocation();
	}

	createBackgroundGeolocation(): MobiPointsTrackingGeolocationWebBackgroundGeolocation {
		return new MobiPointsTrackingGeolocationWebBackgroundGeolocation();
	}

	createDistanceCalculation(): DistanceCalculation {
		if (!this.distanceCalculation) {
			this.distanceCalculation = new DistanceCalculation();
		}
		return this.distanceCalculation;
	}

	createTrack(uuid: string, trackingName = "", state: string = TRACKING_STATE_NEW, startTrackingDate = new Date()) {
		return new MobiPointsTrackingTracking(uuid, trackingName, state, startTrackingDate)
	}

	createTrackByType(userTrackingUuid: string, trackType: string, trackingName: string, timestamp: number | null = null, subType: string | null = null, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {}}, endTimestamp?: number): MobiPointsTrackingTrackAbstractTrack {
		if (timestamp === null) {
			timestamp = this.createCurrentTimeStamp();
		}

		switch (trackType) {
			case TRACK_TYPE_BICYCLE:
				return new MobiPointsTrackingTrackTypeBicycleTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_FOOT:
				return new MobiPointsTrackingTrackTypeFootTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_TRAIN:
				return new MobiPointsTrackingTrackTypeTrainTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_BUS:
				return new MobiPointsTrackingTrackTypeBusTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_TRAM:
				return new MobiPointsTrackingTrackTypeTramTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_SUBWAY:
				return new MobiPointsTrackingTrackTypeSubwayTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_CARPOOL:
				return new MobiPointsTrackingTrackTypeCarpoolTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_E_BIKE:
				return new MobiPointsTrackingTrackTypeEBikeTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_E_CAR:
				return new MobiPointsTrackingTrackTypeECarTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_CAR:
				return new MobiPointsTrackingTrackTypeCarTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_E_SCOOTER:
				return new MobiPointsTrackingTrackTypeEScooterTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			case TRACK_TYPE_E_MOTORCYCLE:
				return new MobiPointsTrackingTrackTypeEMotorcycleTrack(userTrackingUuid, trackingName, timestamp, state, payload, endTimestamp);
			default:
				throw new Error("Track Type " + trackType + " not allowed!");
		}
	}

	createCoordinateList(): MobiPointsTrackingCoordinateCoordinateList {
		return new MobiPointsTrackingCoordinateCoordinateList();
	}

	createCoordinateListByData(coordinates: MobiPointsTrackingCoordinateCoordinateList | any): MobiPointsTrackingCoordinateCoordinateList {
		const coordinateList = this.createCoordinateList();
		if (coordinates && coordinates.items) {
			for (const coordinateData of coordinates.items) {
				const latitude = coordinateData.latitude;
				const longitude = coordinateData.longitude;
				const timestamp = coordinateData.timestamp;
				const coordinate = this.createCoordinate(latitude, longitude, timestamp);
				coordinateList.push(coordinate)
			}
		}

		return coordinateList;
	}

	createTrackList(): MobiPointsTrackingTrackTrackList {
		return new MobiPointsTrackingTrackTrackList();
	}

	createTrackListByArray(arrTrackList: Array<MobiPointsTrackingTrackAbstractTrack | any>): MobiPointsTrackingTrackTrackList {
		const trackList = this.createTrackList();

		for (const trackData of arrTrackList) {
			const uuid = trackData.uuid;
			const type = trackData.type;
			const subType = trackData.subType ?? null;
			const trackName = trackData.trackName;
			const timestamp = trackData.timestamp;
			const state = trackData.state;
			const payload = trackData.payload;
			const coordinates = trackData.coordinates;
			const endTimestamp = trackData.endTimestamp;
			const track = this.createTrackByType(uuid, type, trackName, timestamp, subType, state, payload, endTimestamp);
			const coordinateList = this.createCoordinateListByData(coordinates)
			track.setCoordinates(coordinateList);
			trackList.push(track);
		}

		return trackList;
	}

	createTrackingByObject(trackingObject: MobiPointsTrackingTracking | any): MobiPointsTrackingTracking {
		const uuid = trackingObject.uuid;
		const trackingName = trackingObject.name;
		const type = trackingObject.type;
		const state = trackingObject.state;
		const startTrackingDate = trackingObject.startTrackingDate ?? null;
		const tracksData = trackingObject.tracks ?? null;
		const tracking = this.createTracking(uuid, trackingName, state, startTrackingDate)
		this.enhanceTrackingData(tracking, type);
		const tracks = this.createTrackListByArray(tracksData);
		tracking.setTracks(tracks);
		MobiPointsTrackingTrackingFactory.enhanceTrackingStartAndEndAddress(trackingObject, tracking);
		this.enhanceWeatherData(trackingObject, tracking);
		return tracking;
	}

	enhanceTrackingData(tracking: MobiPointsTrackingTracking, type: string | undefined) {
		tracking.type = type;
	}

	getTrackingTypInfoList(): Array<{type: string, name: string, shortName: string, image: string, icon: string}> {
		return [
			{type: TRACK_TYPE_FOOT, name: 'Zu Fuß', shortName: 'Fuß', image: "/assets/img/backgrounds/favorites/by_foot.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_FOOT)},
			{type: TRACK_TYPE_BICYCLE, name: 'Fahrrad', shortName: 'Bike', image: "/assets/img/backgrounds/favorites/take_the_bike.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_BICYCLE)},
			{type: TRACK_TYPE_TRAIN, name: 'Zug', shortName: 'Zug', image: "/assets/img/backgrounds/favorites/train.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_TRAIN)},
			{type: TRACK_TYPE_TRAM, name: 'Bim', shortName: 'Bim', image: "/assets/img/backgrounds/favorites/tram.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_TRAM)},
			{type: TRACK_TYPE_BUS, name: 'Bus', shortName: 'Bus', image: "/assets/img/backgrounds/favorites/bus.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_BUS)},
			{type: TRACK_TYPE_SUBWAY, name: 'U-Bahn', shortName: 'U-Bahn', image: "/assets/img/backgrounds/favorites/subway.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_SUBWAY)},
			{type: TRACK_TYPE_CARPOOL, name: 'Fahrgemeinschaft', shortName: 'Carpool', image: "/assets/img/backgrounds/favorites/ecar.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_CARPOOL)},
			{type: TRACK_TYPE_E_BIKE, name: 'E-Bike', shortName: 'E-Bike', image: "/assets/img/backgrounds/favorites/ebike.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_E_BIKE)},
			{type: TRACK_TYPE_E_CAR, name: 'E-Auto', shortName: 'E-Auto', image: "/assets/img/backgrounds/favorites/ecar.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_E_CAR)},
			{type: TRACK_TYPE_CAR, name: 'Auto', shortName: 'Auto', image: "/assets/img/backgrounds/favorites/car.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_CAR)},
			// {type: TRACK_TYPE_E_SCOOTER, name: 'E-Scooter', image: "/assets/img/backgrounds/favorites/escooter.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_E_SCOOTER)},
			// {type: TRACK_TYPE_E_MOTORCYCLE, name: 'E-Moped', image: "/assets/img/backgrounds/favorites/escooter.jpg", icon: this.getTrackingIconByType(TRACK_TYPE_E_MOTORCYCLE)},
		]
	}

	getTrackingTypeList(): Array<string> {
		return [
			TRACK_TYPE_BICYCLE,
			TRACK_TYPE_FOOT,
			TRACK_TYPE_TRAIN,
			TRACK_TYPE_TRAM,
			TRACK_TYPE_BUS,
			TRACK_TYPE_SUBWAY,
			TRACK_TYPE_CARPOOL,
			TRACK_TYPE_E_BIKE,
			TRACK_TYPE_E_CAR,
			TRACK_TYPE_CAR,
			TRACK_TYPE_E_SCOOTER,
		]
	}

	getTrackingTypeMinimalList(): Array<string> {
		return [
			TRACK_TYPE_BICYCLE,
			TRACK_TYPE_FOOT,
			TRACK_TYPE_TRAIN,
		]
	}

	getFullTrackingTypeList(): Array<{type: string, name: string}> {
		return [
			{type: TRACK_TYPE_BICYCLE, name: 'Fahrrad'},
			{type: TRACK_TYPE_FOOT, name: 'Zu Fuß'},
			{type: TRACK_TYPE_TRAIN, name: 'Zug'},
			{type: TRACK_TYPE_TRAM, name: 'Straßenbahn'},
			{type: TRACK_TYPE_BUS, name: 'Bus'},
			{type: TRACK_TYPE_SUBWAY, name: 'U-Bahn'},
			{type: TRACK_TYPE_CARPOOL, name: 'Fahrgemeinschaft'},
			{type: TRACK_TYPE_E_BIKE, name: 'E-Bike'},
			{type: TRACK_TYPE_E_CAR, name: 'E-Auto'},
			{type: TRACK_TYPE_CAR, name: 'Auto'},
			// {type: TRACK_TYPE_E_SCOOTER, name: 'E-Scooter'},
			// {type: TRACK_TYPE_E_MOTORCYCLE, name: 'E-Moped'},
		]
	}

	getTrackingTypeAdvancedList(): Array<{type: string, name: string}> {
		return [
			{type: TRACK_TYPE_TRAM, name: 'Straßenbahn'},
			{type: TRACK_TYPE_BUS, name: 'Bus'},
			{type: TRACK_TYPE_SUBWAY, name: 'U-Bahn'},
			{type: TRACK_TYPE_CARPOOL, name: 'Fahrgemeinschaft'},
			{type: TRACK_TYPE_E_BIKE, name: 'E-Bike'},
			{type: TRACK_TYPE_E_CAR, name: 'E-Auto'},
			{type: TRACK_TYPE_CAR, name: 'Auto'},
			// {type: TRACK_TYPE_E_SCOOTER, name: 'E-Scooter'},
			// {type: TRACK_TYPE_E_MOTORCYCLE, name: 'E-Moped'},
		]
	}

	getTrackingIconByType(trackType: string): string {
		switch (trackType) {
			case TRACK_TYPE_BICYCLE:
			case TRACK_TYPE_E_BIKE:
				return icons.bicycle;

			case TRACK_TYPE_BUS:
				return icons.bus;

			case TRACK_TYPE_CARPOOL:
				return icons.shareSocial;

			case TRACK_TYPE_E_CAR:
				return icons.carSport;

			case TRACK_TYPE_CAR:
				return icons.car;

			case TRACK_TYPE_FOOT:
				return icons.walk;

			case TRACK_TYPE_SUBWAY:
				return icons.subway;

			case TRACK_TYPE_TRAIN:
				return icons.train;
			case TRACK_TYPE_TRAM:
				return bimOutline;

			case TRACK_TYPE_E_MOTORCYCLE:
			case TRACK_TYPE_E_SCOOTER:
			default:
				return icons.help;
		}
	}

	private static enhanceTrackingStartAndEndAddress(trackingObject: MobiPointsTrackingTracking | any, tracking: MobiPointsTrackingTracking) {
		let startAddress = trackingObject.startAddress ?? null;
		if (!startAddress) {
			const firstCoordinate = tracking.getFirstCoordinate();
			if (firstCoordinate) {
				startAddress = useGeoCodingFactory().getGeoCodingFactory().getNearestLocationByCoordinate(firstCoordinate);
			}
		}
		tracking.startAddress = startAddress;

		let startCoordinate = trackingObject.startCoordinate ?? null;
		if (!startCoordinate) {
			startCoordinate = tracking.getFirstCoordinate();
		}
		tracking.startCoordinate = startCoordinate;

		let endAddress = trackingObject.endAddress ?? null;
		if (!endAddress) {
			const lastCoordinate = tracking.getLastCoordinate();
			if (lastCoordinate) {
				endAddress = useGeoCodingFactory().getGeoCodingFactory().getNearestLocationByCoordinate(lastCoordinate);
			}
		}
		tracking.endAddress = endAddress;

		let endCoordinate = trackingObject.endCoordinate ?? null;
		if (!endCoordinate) {
			endCoordinate = tracking.getLastCoordinate();
		}
		tracking.endCoordinate = endCoordinate;
	}

	private enhanceWeatherData(trackingObject: MobiPointsTrackingTracking | any, tracking: MobiPointsTrackingTracking) {
		if (trackingObject.weatherItem) {
			tracking.weatherItem = getWeatherFactory().createWeatherItemByProperties(trackingObject.weatherItem);
		}
	}
}
