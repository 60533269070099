import {computed, ComputedRef, onBeforeMount} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {News, NEWS_TYPE_BANNER, NEWS_TYPE_CARD, NEWS_TYPE_POPUP} from "@/mobipoints/news/news/news.type";
import {MobiPointsApiNews, NewsResponseError} from "@/mobipoints/api/news";
import useSystem from "@/composable/useSystem";

export default function useNews() {
	const news: ComputedRef<Array<News>> = computed(() => store.getters['news/news']);
	const activeNews: ComputedRef<Array<News>> = computed(() => {
		if (news.value) {
			const currentDate = new Date();
			return news.value.filter((news: News) => {
				let result = false;
				if (!news.show) {
					result = isDateActive(currentDate, news.startDate, news.endDate);
				}
				if (result) {
					result = isSpecificShowTypeVisible(news);
				}
				return result;
			});
		} else {
			return [];
		}
	});
	const activeBannerNews: ComputedRef<Array<News>> = computed(() => {
		if (news.value) {
			const currentDate = new Date();
			return news.value.filter((news: News) => {
				let result = false;
				if (news.show && news.type == NEWS_TYPE_BANNER) {
					result = isDateActive(currentDate, news.startDate, news.endDate);
				}
				if (result) {
					result = isSpecificShowTypeVisible(news);
				}
				return result;
			});
		} else {
			return [];
		}
	});
	const activeCardNews: ComputedRef<Array<News>> = computed(() => {
		if (news.value) {
			const currentDate = new Date();
			return news.value.filter((news: News) => {
				let result = false;
				if (news.show && news.type == NEWS_TYPE_CARD) {
					result = isDateActive(currentDate, news.startDate, news.endDate);
				}
				if (result) {
					result = isSpecificShowTypeVisible(news);
				}
				return result;
			});
		} else {
			return [];
		}
	});
	const activePopupNews: ComputedRef<Array<News>> = computed(() => {
		if (news.value) {
			const currentDate = new Date();
			return news.value.filter((news: News) => {
				let result = false;
				if (news.show && news.type == NEWS_TYPE_POPUP) {
					result = isDateActive(currentDate, news.startDate, news.endDate);
				}
				if (result) {
					result = isSpecificShowTypeVisible(news);
				}
				return result;
			});
		} else {
			return [];
		}
	});

	function isDateActive(currentDate: Date, startDate: Date|null|undefined, endDate: Date|null|undefined): boolean
	{
		let result = false;
		const currentTimestamp = currentDate.getTime();

		if (startDate && (currentTimestamp >= startDate.getTime()) ) {
			if ( (endDate && currentTimestamp <= endDate.getTime()) || !endDate) {
				result = true;
			}
		} else if (!startDate && endDate && (currentTimestamp <= endDate.getTime()) ) {
			result = true;
		} else if (!startDate && !endDate) {
			result = true;
		}

		return result;
	}

	function isSpecificShowTypeVisible(news: News): boolean
	{
		let result = true;

		const specificShowType = news.specificShowType;
		if (!specificShowType) {
			return result;
		}
		const systemDeviceType = useSystem().getOsDeviceType();
		const systemOsType = useSystem().getOs();
		const platformType = useSystem().getPlatformType();

		const regexString = '/'+systemOsType+'_.*'+platformType+'|'+systemOsType+'.*'+platformType+'/';
		const regex = new RegExp(regexString);

		if (specificShowType && specificShowType === systemDeviceType) {
			result = true;
		} else if (specificShowType && specificShowType === systemOsType) {
			result = true;
		} else if (specificShowType && specificShowType === platformType) {
			result = true;
		} else if (specificShowType && regex.test(specificShowType)) {
			result = true;
		} else if (specificShowType && specificShowType !== useSystem().getOsDeviceType()) {
			result = false;
		}

		return result;
	}

	const loadNews = async function () {
		try {
			const response = await MobiPointsApiNews.getNews();
			return response.data.payload;
		} catch (error: any | AxiosError) {
			throw new NewsResponseError(error.status, error.error.message);
		}
	}

	const initNews = async function (force = false) {
		if (force || !news.value || news.value.length < 1) {
			store.commit('news/news', await loadNews());
		}
	}

	const reloadNews = async function () {
		store.commit('news/resetNews');
		await initNews();
	}

	onBeforeMount(() => {
		initNews().then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		news,
		activeNews,
		activeBannerNews,
		activeCardNews,
		activePopupNews,
		initNews,
		reloadNews,
	}
}