import {
	AbstractWalletItem
} from '@/mobipoints/wallet/item/abstract_wallet_item.type';

export class WalletItemList extends Array<AbstractWalletItem> {

	constructor(items: Array<AbstractWalletItem> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractWalletItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractWalletItem {
		return this[index];
	}

	getItemById(item_uuid: string): AbstractWalletItem | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getTotalAmount(): number {
		let totalAmount = 0;
		for (const item of this) {
			if (item.amount) {
				totalAmount += item.amount;
			}
		}
		return totalAmount;
	}
}