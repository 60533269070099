import {MobiPointsTrackingWeatherWeatherFactory} from "@/mobipoints/tracking/weather/weather_factory";

export default function useWeatherFactory() {
    let weatherFactory: MobiPointsTrackingWeatherWeatherFactory | null = null;
    const getWeatherFactory = (): MobiPointsTrackingWeatherWeatherFactory => {
        if (!weatherFactory) {
            weatherFactory = new MobiPointsTrackingWeatherWeatherFactory();
        }

        return weatherFactory;
    }

    return {
        getWeatherFactory,
    }
}
