import {MobiPointsCoreLogInterface} from "@/mobipoints/core/log.interface";

export class MobiPointsCoreLog implements MobiPointsCoreLogInterface {
	private _error?: Error | undefined = undefined;
	private _message?: string | undefined = undefined;
	private _timestamp: number;
	private _trace?: string | undefined = undefined;

	constructor(timestamp: number) {
		this._timestamp = timestamp;
	}

	get error(): Error | undefined {
		return this._error;
	}

	set error(value: Error | undefined) {
		this._error = value;
	}

	get message(): string | undefined {
		return this._message;
	}

	set message(value: string | undefined) {
		this._message = value;
	}

	get timestamp(): number {
		return this._timestamp;
	}

	set timestamp(value: number) {
		this._timestamp = value;
	}

	get trace(): string | undefined {
		return this._trace;
	}

	set trace(value: string | undefined) {
		this._trace = value;
	}
}