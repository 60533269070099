<template>
	<ion-back-button></ion-back-button>
	<ion-buttons>
		<ion-button item-start color="danger" expand="block" shape="round" @click="getCurrentPosition">Wo bin ich?</ion-button>
		<ion-button slot="end" item-end color="danger" expand="block" shape="round" @click="createTrackRouteByCoordinates">Track Route erstellen</ion-button>
	</ion-buttons>
	<google-map
			:api-key="googleMapsKey"
			style="width: 100%; height: 100%"
			:center="centerLatLng"
			:zoom="15"
	>
		<Marker v-for="marker in markers" :key="marker" :options="marker.options"></Marker>
	</google-map>
</template>

<script lang="ts">
import { ref, onBeforeMount, onMounted, watch, computed } from "vue";
// import { useStore } from 'vuex'
import store from '@/store';
import {IonButton, IonIcon, IonBackButton, IonButtons} from '@ionic/vue';
import "@codetrix-studio/capacitor-google-auth";
import { GoogleMap, Marker } from 'vue3-google-map'
import { Plugins} from "@capacitor/core";
import {isPlatform, getPlatforms} from '@ionic/vue';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';

export default {
	name: 'DefaultMap',
	// eslint-disable-next-line
	components: {IonButton, IonBackButton, GoogleMap, Marker, IonButtons},
	// components: {IonButton, IonBackButton, GoogleIcon, Geolocation, NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions},
	props: {
		initCords: { lat: Number, lng: Number },
		zoom: Number,
		mapType: String,
		disableUI: Boolean
	},
	setup(props: any) {
		// const store = useStore();
		// the google map object
		const { Geolocation} = Plugins;
		const { NativeGeocoder} = Plugins;

		const markers = ref([]) as any;
		const mapRef = ref(null);
		const centerLatLng = ref(props.initCords);
		const address = ref("");
		const waitPosition = ref();
		const activeTrack = ref();

		const googleMapsKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

		markers.value.push({ options: {position: centerLatLng, label: 'M', title: 'MOBI POINTS'} });

		const nativeGeocoderOptions: NativeGeocoderOptions = {
			useLocale: true,
			maxResults: 5
		};

		// address
		function pretifyAddress(addressToPretify: NativeGeocoderResult[]){
			const obj = [] as any;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			let data = "";
			for (const key in addressToPretify) {
				obj.push(addressToPretify[key]);
			}
			obj.reverse();
			for (const val in obj) {
				if(obj[val].length)
					data += obj[val]+', ';
			}
			return addressToPretify.slice(0, -2).toString();
		}

		// get address using coordinate
		function getAddressFromCurrentLocation(){
			if((isPlatform('android') || isPlatform('ios')) || isPlatform('hybrid')) { //@todo create a helper function
				NativeGeocoder.reverseGeocode(centerLatLng.value.lat, centerLatLng.value.lng, nativeGeocoderOptions)
					.then((res: NativeGeocoderResult[]) => {
						address.value = pretifyAddress(res.slice(0));
						// address.value = pretifyAddress(res[0]);
					})
					.catch((error: any) => {
						alert('Error getting location' + JSON.stringify(error));
					});
			} else {
				console.log("USE Google MAPS API to get address by location in a web browser!")
			}
		}


		function createNewLatitude(latitude: number, distance: number){
			const earth = 6378.137,  //radius of the earth in kilometer
				pi = Math.PI,
				m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

			return latitude + (distance * m);
		}

		function createNewLongitude(longitude: number, latitude: number, distance: number){
			const earth = 6378.137,  //radius of the earth in kilometer
				pi = Math.PI,
				cos = Math.cos,
				m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

			return longitude + (distance * m) / cos(latitude * (pi / 180));
		}

		function getRndInteger(min: any, max: any) {
			return Math.floor(Math.random() * (max - min) ) + min;
		}

		// function createNewTrack()
		// {
		// 	const timestamp = Date.now();
		// 	const newTrack = new StartTrack(timestamp + "-1", timestamp);
		// 	console.log("NEW TRACK", newTrack)
		// 	store.commit('tracks/addTrack', newTrack)
		// }
		//
		// function hasActiveTrack() {
		// 	return store.getters['tracks/hasActiveTrack'] || store.getters['tracks/hasTracks'];
		// }
		//
		// function addCoordinatesToTrackList(longitude: number, latitude: number){
		// 	if(!hasActiveTrack()){
		// 		console.log("no active track found, try to create new one")
		// 		createNewTrack();
		// 	}
		// 	console.log("BAR", store.getters['tracks/getTrackList']);
		// 	// activeTrack.value = store.getters['tracks/getActiveTrack'];
		// 	// activeTrack.value = computed(() => store.getters['tracks/getActiveTrack']);
		// 	const newCoordinates = new Coordinates(latitude, latitude, Date.now());
		// 	console.log("NEW COORDS", newCoordinates);
		// 	// const myTrack = JSON.parse(JSON.stringify(store.getters['tracks/getActiveTrack']));
		// 	// myTrack.coordinate.push(newCoordinates);
		// 	// activeTrack.value = JSON.stringify(myTrack);
		// 	// console.log(activeTrack)
		// }
		//
		// //todo only for testing
		async function getCurrentPosition() {
			const coordinates = await Geolocation.getCurrentPosition();
			centerLatLng.value = {lat: coordinates.coords.latitude, lng: coordinates.coords.longitude};

			// addCoordinatesToTrackList(coordinate.coords.longitude,coordinate.coords.latitude);

			//marker with own icon
			// markers.value.push({ options: {icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/390px-Map_marker.svg.png', position: centerLatLng.value, label: 'I🧔', title: 'Mein aktueller Standort'} });
			markers.value.push({ options: {position: centerLatLng.value, label: 'I🧔', title: 'Mein aktueller Standort'} });

			console.log("In addition, an extra marker is set starting from my current location");
			const newLat = createNewLatitude(coordinates.coords.latitude, getRndInteger(50,900));
			const newLng = createNewLongitude(coordinates.coords.longitude,coordinates.coords.latitude, getRndInteger(50,900));
			const newCenterLatLng = {lat: newLat, lng: newLng}
			markers.value.push({ options: {position: newCenterLatLng, label: 'M', title: 'MOBI POINTS'} });
			console.log(markers);

			getAddressFromCurrentLocation();
			console.log(address.value);
		}

		function createTrackRouteByCoordinates() {
			console.log("FOO");
		}

		// watch(centerLatLng.value.longitude, () => {
		// 	/* eslint-disable */
		// 	if (mapRef.value && mapRef.value?.ready) {
		// 		mapRef.value.map.panTo({ lat: 0, lng: centerLatLng.value.lng })
		// 	}
		// 	/* eslint-enable */
		// })

		return {
			mapRef, centerLatLng, googleMapsKey, waitPosition, getCurrentPosition, markers, createTrackRouteByCoordinates
		};
	},
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 300px;
    background-color: azure;
}

#map_canvas {
    width: 90%;
    height: 80%;
    border: 1px solid red;
}


#address {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
}

#map {
    width: 100%;
    height: 70vh;
}

.map-wrapper {
    position: relative;
}

.map-wrapper #map_center {
    position: absolute;
    z-index: 99;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    margin-left: -21px;
    margin-top: -41px;
}
</style>