import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import useData from '@/composable/useData';
import * as icons from 'ionicons/icons';

export class WalletCarbonEmissions extends AbstractWallet{
	getWalletTitle(): string {
		return useData().getTextValueByKey('mobipoints.wallet.title.carbon_emissions', [], 'Mein CO2 Wallet');
	}

	getWalletPointsName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.carbon_emissions', [], 'CO2 / gramm');
	}

	getEmptyWalletPointsMessage(): string {
		return useData().getTextValueByKey('mobipoints.wallet.points.carbon_emissions.empty', [], 'Du hast noch kein CO2 eingespart<br>Starte gleich mit deinem ersten Tracking!');
	}

	getWalletImage(): string {
		return icons.earthOutline;
	}
}