<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>Mein Profil</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Mein Profil</ion-title>
				</ion-toolbar>
			</ion-header>
			<UserProfile></UserProfile>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonHeader, IonToolbar, IonTitle, IonContent} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import UserProfile from "@/components/profile/UserProfile.vue";

export default {
	name: 'ProfileTab',
	components: {UserProfile, IonHeader, IonToolbar, IonTitle, IonContent, IonPage},
	setup(props: any) {
		return {}
	}
}
</script>