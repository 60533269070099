import {
	MobiPointsProfileUserTrackingTemplateInterface
} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.interface";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {UserTrackingTrackType} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.type";

export class UserTrackingTemplate implements MobiPointsProfileUserTrackingTemplateInterface {
	private _id: number | null = null;
	private _name = '';
	private _sort = 0;
	private _state: string | null = null;
	private _userTrackingTrackType: UserTrackingTrackType | null = null;
	private _startLocationId: number | null = null;
	private _endLocationId: number | null = null;

	private _startLocation: UserLocation | null = null;
	private _endLocation: UserLocation | null = null;

	constructor(id: number | null, name: string, state: string | null = null, userTrackingTrackType: UserTrackingTrackType | null = null, startLocation: UserLocation | null = null, endLocation: UserLocation | null = null) {
		this.id = id;
		this.name = name;
		this.state = state;
		this.userTrackingTrackType = userTrackingTrackType;
		this.startLocation = startLocation;
		this.endLocation = endLocation;
	}


	get id(): number | null {
		return this._id;
	}

	set id(value: number | null) {
		this._id = value;
	}

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	get sort(): number {
		return this._sort;
	}

	set sort(value: number) {
		this._sort = value;
	}

	get endLocationId(): number | null {
		return this._endLocationId;
	}

	set endLocationId(value: number | null) {
		this._endLocationId = value;
	}

	get startLocationId(): number | null {
		return this._startLocationId;
	}

	set startLocationId(value: number | null) {
		this._startLocationId = value;
	}

	get userTrackingTrackType(): UserTrackingTrackType | null {
		return this._userTrackingTrackType;
	}

	set userTrackingTrackType(value: UserTrackingTrackType | null) {
		this._userTrackingTrackType = value;
	}

	get state(): string | null {
		return this._state;
	}

	set state(value: string | null) {
		this._state = value;
	}

	get startLocation(): UserLocation | null {
		return this._startLocation;
	}

	set startLocation(value: UserLocation | null) {
		this._startLocation = value;
	}

	get endLocation(): UserLocation | null {
		return this._endLocation;
	}

	set endLocation(value: UserLocation | null) {
		this._endLocation = value;
	}

	getFormattedStartAndEndLocation(): Array<string> {
		const output: Array<string> = [];

		const hasStartLocation = null !== this.startLocation && null !== this.startLocation.id;
		const hasEndLocation = null !== this.endLocation && null !== this.endLocation.id;

		if (!hasEndLocation && !hasStartLocation) {
			return [];
		} else if (!hasEndLocation) {
			return [this.startLocation?.getFormattedAddress() ?? ''];
		} else if (!hasStartLocation) {
			return [this.endLocation?.getFormattedAddress() ?? ''];
		}

		if (null !== this.startLocation && null !== this.endLocation) {
			if (this.startLocation.country !== this.endLocation.country) {
				output.push((this.startLocation.city + ", " + this.startLocation.country).trim());
				output.push((this.endLocation.city + ", " + this.endLocation.country).trim());
			} else if (this.startLocation.city !== this.endLocation.city) {
				output.push((this.startLocation.city + ", " + this.startLocation.street + " " + this.startLocation.streetNumber).trim());
				output.push(
					(this.endLocation.city + ", " + this.endLocation.street + " " + this.endLocation.streetNumber).trim()
					+ " (" + this.startLocation.country + ")"
				);
			} else if (this.startLocation.street !== this.endLocation.street) {
				output.push((this.startLocation.street + " " + this.startLocation.streetNumber).trim());
				output.push(
					(this.endLocation.street + " " + this.endLocation.streetNumber).trim()
					+ " (" + this.startLocation.city + ")"
				);
			} else {
				output.push((this.startLocation.street + this.startLocation.streetNumber).trim());
				output.push(
					(this.endLocation.streetNumber).trim()
					+ " (" + this.startLocation.city + ")"
				);
			}
		}

		return output;
	}
}