<template>
	<div class="ion-padding">
		<h1>{{favoriteTitle}}</h1>
		<swiper v-if="isPlatform('ios') || isPlatform('android')" :height="200"
					:options="{ slidesPerView: 2.2, loop: false, spaceBetween: 0 }">
			<swiper-slide v-for="favoriteTrack in favoriteImageMap" :key="favoriteTrack.type"
					   @click="triggerTrack(favoriteTrack.type)">
				<ion-card class="ion-activatable ripple-parent">
					<ion-img class="slide-image" :src="favoriteTrack.image" :alt="favoriteTrack.image"/>
					<ion-icon :icon="favoriteTrack.icon"></ion-icon>
					<ion-card-header>
						<ion-card-title :class="['favorite-title',  isIosPlatform() ? 'ios-font-size' : '']">{{ favoriteTrack.name }}</ion-card-title>
					</ion-card-header>
					<ion-card-content>
					</ion-card-content>
					<ion-ripple-effect></ion-ripple-effect>
				</ion-card>
			</swiper-slide>
		</swiper>

		<swiper v-else :height="200" :slides-per-view="3.2" :loop="false" :space-between="5">
			<swiper-slide v-for="favoriteTrack in favoriteImageMap" :key="favoriteTrack.type"
					   @click="triggerTrack(favoriteTrack.type)">
				<ion-card>
					<ion-img class="slide-image" :src="favoriteTrack.image" :alt="favoriteTrack.image"/>
					<ion-icon :icon="favoriteTrack.icon"></ion-icon>
					<ion-card-header>
						<ion-card-title>{{ favoriteTrack.name }}</ion-card-title>
					</ion-card-header>
					<ion-card-content>
					</ion-card-content>
				</ion-card>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script lang="ts">
import {ref} from "vue";
import {isPlatform} from '@ionic/vue';
import {
	IonCardContent,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonRippleEffect,
	IonImg, IonIcon
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import useToastMessage from "@/components/core/ToastMessage.vue";
import useTrackingFactory from "@/composable/useTrackingFactory";
import useMainData from "@/composable/useMainData";
import {EVENT_TYPE_START_TRACKING} from "@/mobipoints/queue/event/abstract_event.type";
import useEvent from "@/composable/useEvent";
import useData from "@/composable/useData";
import useSystem from "@/composable/useSystem";
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
	name: 'FavoriteTrackList',
	components: {
		IonCard, IonCardHeader, IonCardTitle, IonCardContent, Swiper, SwiperSlide, IonRippleEffect, IonImg, IonIcon
	},
	props: {
		state: String,
		type: String,
	},
	setup() {
		const {openToast} = useToastMessage();
		const {
			hasActiveTracking,
			startCurrentTrackingTrackBy_TrackType,
			createTrackingAndSetAsActiveTracking,
			addTrackToCurrentTracking,
			checkGeolocationPermissions,
			resetCurrentTracking,
			jumpToActiveCard,
		} = useTracking();
		const {getFirstUuid, removeUuid} = useMainData();
		const {getTrackingFactory} = useTrackingFactory();
		const {addEventByType} = useEvent();
		const favoriteImageMap = ref(getTrackingFactory().getTrackingTypInfoList());
		const {getTextValueByKey} = useData();
		const {isIosPlatform} = useSystem();
		const favoriteTitle = ref(getTextValueByKey('dashboard.start.tracking.shortcut.title', [], 'Wie möchtest du dein Tracking starten?'));

		async function triggerTrack(trackType: string) {
			if (!hasActiveTracking.value && trackType) {
				let firstUuid;
				try {
					if (!await checkGeolocationPermissions()) {
						return;
					}

					firstUuid = await getFirstUuid();
					createTrackingAndSetAsActiveTracking(firstUuid);
					addEventByType(EVENT_TYPE_START_TRACKING, trackType, firstUuid);
					const result = await startCurrentTrackingTrackBy_TrackType(trackType);
					if (!result) {
						await openToast('Error track type ' + trackType + ' not included yet!!', 'danger', 'top', true, 5000);
					} else {
						await openToast('Track started!', 'success', 'top', true, 5000);
						jumpToActiveCard();
					}
				} catch (error) {
					console.log(error);
					await openToast('Error start current tracking!', 'danger', 'top', true, 5000);
					await resetCurrentTracking();
				} finally {
					if (firstUuid) {
						removeUuid(firstUuid);
					}
				}
			} else {
				await openToast('Es läuft bereits ein aktives Tracking! Bitte stoppe diesen zuerst! ', 'danger', 'top', true, 5000);
			}
		}

		return {
			icons,
			favoriteImageMap,
			isPlatform,
			triggerTrack,
			favoriteTitle,
			isIosPlatform
		}
	}
}
</script>

<style scoped>
.ios-font-size {
	font-size: 1.5em;
}

.slide-image {
    width: 100%;
    height: 6em;
    object-fit: cover;
    object-position: center center;
}

.favorite-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

ion-card > ion-icon {
    position: absolute;
    top: 2.9em;
    font-size: 1.5em;
    right: .1em;
    opacity: .66;
}
</style>