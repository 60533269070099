import {MobiPointsProfileUser} from "@/mobipoints/profile/userProfile/user_profile.type";
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import useProfileFactory from "@/composable/useProfileFactory";
import {AxiosError} from "axios";
import {MobiPointsProfileUserInterface} from "@/mobipoints/profile/userProfile/user_profile.interface";

const {getProfileFactory} = useProfileFactory();

interface UserProfileStateContainer<T> {
	loaded: boolean,
	data: T,
}

const resetProfile = (state: any) => {
	state.profile.loaded = false;
	state.profile.data = {};
};

const userProfile = {
	namespaced: true,
	state: () => ({
		profile: {
			loaded: false,
			data: {}
		} as UserProfileStateContainer<MobiPointsProfileUser | MobiPointsProfileUserInterface>,
		isLoading: false as boolean,
	}),
	mutations: {
		profile(state: any, value: any) {
			state.profile.loaded = true;
			state.profile.data = value;
		},
		darkMode(state: any, active: boolean) {
			state.profile.data.profile.darkMode = active;
		},
		trackingAutoStop(state: any, active: boolean) {
			state.profile.data.profile.trackingAutoStop = active;
		},
		trackingStopConfirm(state: any, active: boolean) {
			state.profile.data.profile.trackingStopConfirm = active;
		},
		isLoading(state: any, value: boolean) {
			state.isLoading = value;
		},
	},
	actions: {
		async updateUserProfile(context: any, userProfile: MobiPointsProfileUser): Promise<string | null> {
			try {
				const response = await MobiPointApiProfile.updateUserProfile(userProfile);
				if (200 === response.status) {
					return null;
				} else {
					return `Response answered with status ${response.status}!`;
				}
			} catch (error: any | AxiosError) {
				return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
		resetProfile(state: any) {
			resetProfile(state);
		},
	},
	getters: {
		profile: (state: any) => {
			if (!(state.profile.data instanceof MobiPointsProfileUser)) {
				return getProfileFactory().createUserProfileByApiResponse(state.profile.data);
			}
			return state.profile.data;
		},
		isDarkMode: (state: any, getters: any) => {
			try {
				return getters.profile.profile.darkMode ?? false;
			} catch (e) {
				return false;
			}
		},
		isTrackingAutoStop: (state: any, getters: any) => {
			try {
				return getters.profile.profile.trackingAutoStop ?? false;
			} catch (e) {
				return false;
			}
		},
		isTrackingStopConfirm: (state: any, getters: any) => {
			try {
				return getters.profile.profile.trackingStopConfirm ?? true;
			} catch (e) {
				return false;
			}
		},
		isLoading: (state: any, getters: any) => {
			try {
				return getters.isLoading ?? false;
			} catch (e) {
				return false;
			}
		},
	}
}

export default userProfile;
