import {computed, ComputedRef} from 'vue';
import store from '@/store';
import {UserAuth} from "@/mobipoints/auth/user_auth";

export default function useAuth() {
	const isAuthenticated: ComputedRef<boolean> = computed(() => store.getters['auth/isAuthenticated']);
	const isTokenLifeTimeExpired: ComputedRef<boolean> = computed(() => store.getters['auth/isTokenLifeTimeExpired']);

	function checkIsAuthenticated() {
		if ((!isAuthenticated.value || isTokenLifeTimeExpired.value)) {
			const userAuth = new UserAuth();
			userAuth.logout();
		}
	}

	return {
		isAuthenticated,
		isTokenLifeTimeExpired,
		checkIsAuthenticated
	}
}
