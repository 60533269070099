import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../public/assets/logo/MobilityLogo_Grn.png'
import _imports_1 from '../../../public/assets/logo/MobilityLogo_Grn@2x.png'


const _withScopeId = n => (_pushScopeId("data-v-2c584041"),n=n(),_popScopeId(),n)
const _hoisted_1 = _imports_0 + ' 1x, ' + _imports_1 + ' 2x'
const _hoisted_2 = {
  id: "MobilityLogo_Grn",
  src: _imports_0,
  srcset: _hoisted_1,
  alt: "Mobility by niceshops"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _hoisted_2))
}