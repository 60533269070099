<template>
  <ion-card v-if="null !== challenge" class="ion-activatable ripple-parent challenge-card"
            @click="navigateToChallengeDetail(challenge.uuid)">
    <ion-img class="challenge-img" v-if="challenge.getImage()" :src="challenge.getImage()" :alt="challenge.title"/>
    <ion-card-header class="cardNewsHeader">
      <ion-card-subtitle>{{ challenge.getTimesAsString(false) }}</ion-card-subtitle>
      <ion-card-title>{{ challenge.title }}</ion-card-title>
    </ion-card-header>
    <div v-if="showProgressInfo" class="ion-align-items-center ion-justify-content-between progress-info">
      <span>{{challengeProgress}}%</span>
      <ion-icon v-show="challengeProgress >= 100" :icon="icons.checkmarkOutline" style="margin-left: .25em;"></ion-icon>
      <ion-icon v-show="challengeCancelled" :icon="icons.pauseOutline" style="margin-left: .25em;"></ion-icon>
    </div>
    <ion-progress-bar v-if="showProgressBar" color="secondary" :value="challengeProgress / 100"></ion-progress-bar>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-card>
</template>

<script setup lang="ts">
import {computed, ComputedRef, defineProps, ref, watch} from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonRippleEffect,
  IonImg, IonIcon,
  IonProgressBar
} from '@ionic/vue';
import useChallenge from "@/composable/useChallenge";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import router from "@/router";
import * as icons from 'ionicons/icons';
import {
  USER_CHALLENGE_STATE_CANCELLED,
  USER_CHALLENGE_STATE_NULLIFY
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";

const props = defineProps({
  uuid: {
    type: String,
    required: true
  },
  showProgress: {
    type: String,
    required: false,
    default: ''
  }
});

const challengeFacade = useChallenge();

const challenge = ref<Challenge>(challengeFacade.getChallenge(props.uuid) as Challenge);

const showProgressBar: ComputedRef<boolean> = computed(() => {
  return userJoinedTheChallenge.value && ('bar' === props.showProgress || 'full' === props.showProgress);
});

const showProgressInfo: ComputedRef<boolean> = computed(() => {
  return userJoinedTheChallenge.value && ('info' === props.showProgress || 'full' === props.showProgress);
});

const challengeProgress: ComputedRef<number> = computed(() => {
  let progress = 0;
  challenge.value.userChallenges.forEach((userChallenge) => {
    progress = userChallenge.progress;
  });
  return progress;
});

const challengeCancelled: ComputedRef<boolean> = computed(() => {
  return challenge.value.userChallenges.length > 0 && challenge.value.userChallenges.every((userChallenge) => {
    return USER_CHALLENGE_STATE_CANCELLED === userChallenge.state.code;
  });
});

const userJoinedTheChallenge: ComputedRef<boolean> = computed(() => {
  return challenge.value.userChallenges.length > 0 && challenge.value.userChallenges.every((userChallenge) => {
    return USER_CHALLENGE_STATE_NULLIFY !== userChallenge.state.code;
  });
});

watch(() => challengeFacade.getChallenge(props.uuid), async (newChallenge) => {
  if (props.uuid === newChallenge?.uuid) {
    challenge.value = newChallenge;
  }
});

const navigateToChallengeDetail = function (challengeUuid: string) {
  router.push({
    name: 'ChallengeDetail',
    params: {uuid: challengeUuid}
  });
}
</script>

<style scoped>
.cardNewsHeader {
  background-color: rgba(var(--ion-color-dark-rgb), .15);
  backdrop-filter: blur(3px);
}

.challenge-card {
}

.challenge-card .challenge-img {
  position: relative;
}
.challenge-card .challenge-img:after {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  content: "";

  background: linear-gradient(0deg, rgba(var(--ion-color-dark-rgb), .75) 0%, rgba(var(--ion-color-dark-rgb), 0) 50%);
}

.challenge-card .challenge-img + ion-card-header ion-card-subtitle {
  margin-top: -2.5rem;
  --color: rgba(var(--ion-color-white-rgb), .85);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.challenge-card .challenge-img + ion-card-header ion-card-subtitle + ion-card-title {
  padding-top: .75rem;
}

.challenge-card ion-card-header ion-card-subtitle {
  text-align: right;
}

.progress-info {
  display: flex;
  padding: .5em 1em .35em;
  line-height: 1em;
  border-top: 1px solid rgba(var(--ion-color-white-rgb), .5);
  background-color: rgba(var(--ion-color-dark-rgb), .15);
}

</style>