import {nanoid} from 'nanoid';
import {MobiPointsQueueEventInterface} from "@/mobipoints/queue/event/event.interface";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";

export const EVENT_TYPE_START_TRACKING = "tracking_start";
export const EVENT_TYPE_END_TRACKING = "tracking_end";
export const EVENT_TYPE_ABORT_TRACKING = "tracking_abort";
export const EVENT_TYPE_START_TRACK = "track_start";
export const EVENT_TYPE_ADD_COORDINATES = "geo_location";
export const EVENT_TYPE_END_TRACK = "track_end";
export const EVENT_TYPE_ABORT_TRACK = "track_abort";
export const EVENT_TYPE_WEATHER_DATA = "weather_data";
export const EVENT_TYPE_REDEEM_VOUCHER = "redeem_voucher";

export const EVENT_STATE_NEW = "new";
export const EVENT_STATE_SENDING = "sending";
export const EVENT_STATE_ERROR = "error";

export abstract class MobiPointsQueueEventAbstractEvent implements MobiPointsQueueEventInterface {
	id: string;
	userTrackingUuid: string|null;
	name: string;
	type: string;
	subType: string;
	state: string;
	payload: PayloadInterface;
	timestamp: number;
	active: boolean;

	abstract getEventType(): string;

	/**
	 *
	 * @param userTrackingUuid
	 * @param eventName
	 * @param timestamp
	 * @param subType
	 * @param state
	 * @param payload
	 */
	constructor(userTrackingUuid: string|null, eventName: string, timestamp: number, subType: string, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {}}) {
		this.id = nanoid();
		this.userTrackingUuid = userTrackingUuid;
		this.name = eventName;
		this.type = this.getEventType();
		this.subType = subType;
		this.state = state;
		this.payload = payload;
		this.timestamp = timestamp;
		this.active = true;
		this.validateEvent();
	}

	/**
	 *
	 * @param data
	 */
	setPayloadData(data: any) {
		this.payload.data = data;
	}

	validateEvent() {
		if (this.getEventType() !== EVENT_TYPE_REDEEM_VOUCHER && !this.userTrackingUuid) {
			console.log('No UserTrackingUuid for Event type ' + this.getEventType() + ' found!');
			throw new Error('No UserTrackingUuid for Event type ' + this.getEventType() + ' found!')
		}
	}

}
