<template>
  <ion-list lines="full" v-show="userTrackingTemplates.length > 0">
    <ion-reorder-group :disabled="isReorderDisabled" @ionItemReorder="doReorderUserTrackingTemplates($event)">
      <ion-item-sliding detail
                        v-for="userTrackingTemplate in userTrackingTemplates" :key="userTrackingTemplate.id">
        <ion-item @click="showUserTrackingTemplateDetail(userTrackingTemplate.id)">
          <ion-icon slot="start" :icon="userTrackingTemplate.userTrackingTrackType.getTrackTypeIcon()"></ion-icon>
          <ion-label>
            <h2>{{ userTrackingTemplate.name }}</h2>
            <p class="ion-text-wrap">
              {{ userTrackingTemplate.getFormattedStartAndEndLocation().shift() }}
              <span v-if="userTrackingTemplate.getFormattedStartAndEndLocation().length > 1">
            <br>{{ userTrackingTemplate.getFormattedStartAndEndLocation().pop() }}
          </span>
            </p></ion-label>
          <ion-reorder slot="end"></ion-reorder>
        </ion-item>
        <ion-item-options side="end">
          <ion-item-option color="danger" @click="deleteUserTrackingTemplate(userTrackingTemplate.id)">
            <ion-icon slot="start" :icon="icons.closeOutline"></ion-icon>
            <ion-label>löschen</ion-label>
          </ion-item-option>
        </ion-item-options>

      </ion-item-sliding>
    </ion-reorder-group>
  </ion-list>
  <ion-list-header lines="full" v-show="userTrackingTemplates.length === 0">
    <ion-label>Es sind noch keine Tracking Vorlagen vorhanden!</ion-label>
  </ion-list-header>
  <ion-toolbar v-if="$props.showScrollToTop">
    <ion-buttons slot="end">
      <ion-button @click="scrollToTop">Scroll to Top</ion-button>
    </ion-buttons>
  </ion-toolbar>

</template>

<script setup lang="ts">
import {onMounted, defineExpose, defineProps, ref} from 'vue';
import {
  IonList,
  IonItem,
  IonListHeader,
  IonToolbar,
  IonLabel,
  IonButtons,
  IonButton,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonIcon,
  IonReorder,
  IonReorderGroup,
  toastController, ItemReorderCustomEvent
} from "@ionic/vue";
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import {UserTrackingTemplate} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.type";
import router from "@/router";
import * as icons from 'ionicons/icons';
import useProfileFactory from "@/composable/useProfileFactory";
import {AxiosError, AxiosResponse} from "axios";

const props = defineProps({
  showScrollToTop: {
    type: Boolean,
    default: false,
  },
  reorderDisabled: {
    type: Boolean,
    default: true,
  }
});

onMounted(() => {
  loadUserTrackingTemplates();
});

const getContent = () => {
  return document.querySelector('ion-content');
};

const showUserTrackingTemplateDetail = (id: number) => {
  router.replace({
    name: 'UserTrackingTemplateDetail',
    params: {id: id}
  });
};

const { getProfileFactory } = useProfileFactory();

const scrollToTop = () => {
  const content = getContent();
  content && content.scrollToTop();
};

const userTrackingTemplates = ref<UserTrackingTemplate[]>([]);
const isReorderDisabled = ref<boolean>(props.reorderDisabled);

const loadUserTrackingTemplates = async function () {
  try {
    const response = await MobiPointApiProfile.getActiveUserTrackingTemplates();
    if (response.status !== 200) {
      throw new Error(response.data.error || 'Could not load user tracking templates');
    }

    userTrackingTemplates.value = getProfileFactory().createUserTrackingTemplateListByApiResponse(response.data);
  } catch (e) {
    console.log(e);
  }

};

const deleteUserTrackingTemplate = async function(id: number) {
  console.log(`delete user tracking template`,id);

  let response: AxiosResponse | null = null;
  let message = '';
  try {
    response = await MobiPointApiProfile.deleteUserTrackingTemplate(id);
    if (!response.data.status || response.data.status !== 'error') {
      userTrackingTemplates.value = userTrackingTemplates.value.filter((item) => item.id !== id);
      return;
    }

    message = `Could not delete tracking template error: ${response.data.message || 'Unkown'}` || message;
  } catch (error: any | AxiosError) {
    message = `Could not delete tracking template! Please try again :)`;
  }

  const toast = await toastController.create({
    message: message,
    position: 'bottom',
    duration: 3000,
    icon: icons.warning,
    buttons: [
      {
        side: 'end',
        role: 'cancel',
        icon: icons.close,
      }
    ],
  });

  return toast.present();
}

const doReorderUserTrackingTemplates = (event: ItemReorderCustomEvent) => {
  event.detail.complete(userTrackingTemplates.value);

  let sort = 1;
  userTrackingTemplates.value.forEach((val) => {
    val.sort = sort++;
  });

  MobiPointApiProfile.updateUserTrackingTemplateSorting(userTrackingTemplates.value);
}

const toggleReorderDisabled = () => {
  isReorderDisabled.value = !isReorderDisabled.value;
}

defineExpose({
  getContent,
  scrollToTop,
  toggleReorderDisabled,
});

</script>

<style scoped>

</style>