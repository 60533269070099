import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ac1ac89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"-5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Polyline = _resolveComponent("Polyline")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_google_map = _resolveComponent("google-map")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.googleMapsKey)
      ? _withDirectives((_openBlock(), _createBlock(_component_google_map, {
          key: 0,
          "api-key": $setup.googleMapsKey,
          style: _normalizeStyle('width:' +  $props.width + '; height:' + $setup.newHeight),
          styles: $props.mapTheme,
          center: $setup.centerLatLng,
          mapTypeControl: $props.showMapTypeControl,
          fullscreenControl: false,
          rotateControl: false,
          scaleControl: false,
          zoomControl: $props.zoomControl,
          streetViewControl: $props.streetViewControl,
          disableUI: true,
          zoom: $props.zoom,
          mapId: $setup.getMapID_by_ThemeType(),
          onClick: $setup.setMarker,
          id: "map",
          ref: "mapRef"
        }, {
          default: _withCtx(() => [
            ($props.showPath && $setup.mapIsReady)
              ? (_openBlock(), _createBlock(_component_Polyline, {
                  key: 0,
                  ref: "polylineRef",
                  options: $setup.routePath
                }, null, 8, ["options"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.markers, (marker) => {
              return (_openBlock(), _createBlock(_component_Marker, {
                key: marker,
                options: marker.options
              }, null, 8, ["options"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["api-key", "style", "styles", "center", "mapTypeControl", "zoomControl", "streetViewControl", "zoom", "mapId", "onClick"])), [
          [_vShow, $setup.mapIsReady]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ion_skeleton_text, {
        style: _normalizeStyle('width:' +  $props.width + '; height:' + $props.height),
        animated: ""
      }, null, 8, ["style"])
    ], 512), [
      [_vShow, !$setup.mapIsReady]
    ])
  ], 64))
}