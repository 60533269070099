import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d2df36f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-top":"10px"} }
const _hoisted_2 = { autocomplete: "off" }
const _hoisted_3 = { class: "ion-padding" }
const _hoisted_4 = { class: "ion-align-items-center ion-justify-content-start location-type-selection" }
const _hoisted_5 = { style: {"height":"300px","width":"100%"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "ion-padding-bottom" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_vue_google_autocomplete = _resolveComponent("vue-google-autocomplete")!
  const _component_disabled_map = _resolveComponent("disabled-map")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.backButtonText,
                    defaultHref: "/profile/userLocation"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.addUserLocationTitle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Typ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  value: _ctx.userAddressData.type.name,
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["value"]),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationTypes, (locationType) => {
                    return (_openBlock(), _createBlock(_component_ion_button, {
                      key: locationType.code,
                      value: locationType.code,
                      color: locationType.code === _ctx.userAddressData.type.code ? 'primary' : 'medium',
                      onClick: _ctx.locationTypeChanged
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: locationType.icon,
                          slot: "icon-only",
                          title: locationType.name,
                          style: {"padding":".5em"}
                        }, null, 8, ["icon", "title"]),
                        _createVNode(_component_ion_ripple_effect)
                      ]),
                      _: 2
                    }, 1032, ["value", "color", "onClick"]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_vue_google_autocomplete, {
                  id: "searchUserLocation",
                  autocomplete: "off",
                  classname: "form-control",
                  placeholder: _ctx.searchInputPlaceholder,
                  "enable-geolocation": "true",
                  onPlacechanged: _ctx.getAddressData
                }, null, 8, ["placeholder", "onPlacechanged"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_disabled_map, {
                "init-cords": _ctx.coords,
                height: "100%",
                width: "100%",
                "update-address-on-click": "true",
                "show-map-type-control": "true",
                onOnMarkerUpdated: _ctx.updateAddressData
              }, null, 8, ["init-cords", "onOnMarkerUpdated"])
            ]),
            _createVNode(_component_ion_list, {
              class: "ion-no-margin",
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item_divider, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.addressOverviewTitle) + " ", 1),
                        _createVNode(_component_ion_chip, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.icons.pin,
                              color: "primary"
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, { class: "ion-text-wrap address-chip-text" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.userAddressData.street) + " " + _toDisplayString(_ctx.userAddressData.streetNumber) + ", " + _toDisplayString(_ctx.userAddressData.city) + " " + _toDisplayString(_ctx.userAddressData.postalCode) + " ", 1),
                                (_ctx.userAddressData.province && _ctx.userAddressData.country.code === 'IT')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.userAddressData.province), 1))
                                  : _createCommentVNode("", true),
                                _createTextVNode(", " + _toDisplayString(_ctx.userAddressData.country.code), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.userAddressData.street) + " " + _toDisplayString(_ctx.userAddressData.streetNumber), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.userAddressData.city) + " " + _toDisplayString(_ctx.userAddressData.postalCode), 1),
                          (_ctx.userAddressData.province && _ctx.userAddressData.country.code === 'IT')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.userAddressData.province), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("p", null, _toDisplayString(_ctx.userAddressData.country.name), 1),
                          _createElementVNode("p", null, "Lat: " + _toDisplayString(_ctx.userAddressData.latitude) + ", Lng: " + _toDisplayString(_ctx.userAddressData.longitude), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                slot: "start",
                color: "medium",
                class: "ion-margin-start",
                onClick: _ctx.cancelNewUserLocation
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.icons.close
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, { style: {"margin-left":".25rem"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_ripple_effect)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                slot: "end",
                class: "ion-margin-end",
                onClick: _ctx.createNewUserLocation
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.icons.save
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, { style: {"margin-left":".5rem"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_ripple_effect)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}