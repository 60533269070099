<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-title>Gutschein einlösen</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Gutschein einlösen</ion-title>
				</ion-toolbar>
			</ion-header>
			<div class="ion-padding" v-if="isComponentReady">
				<redeem-voucher-component :voucher-code="voucherCode" :voucher-type="voucherType"></redeem-voucher-component>
			</div>
			<div v-else class="redeem-voucher-loading-spinner">
				<ion-spinner></ion-spinner>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSpinner} from '@ionic/vue';
import {
	add,
} from 'ionicons/icons';
import {onMounted, ref} from "vue";
import useData from "@/composable/useData";
import useSystem from "@/composable/useSystem";
import RedeemVoucherComponent from "@/components/voucher/RedeemVoucherComponent.vue";
import {useRoute} from "vue-router";
import {MobiPointsQueueEventTypeRedeemVoucherType} from "@/mobipoints/queue/event/type/redeem_voucher_event.type";
import {Base64} from "@/mobipoints/core/encoding";
import useToastMessage from "@/components/core/ToastMessage.vue";

export default {
	name: 'RedeemVoucher',
	components: {
		RedeemVoucherComponent,
		IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonSpinner
	},
	setup() {
		const {openToast} = useToastMessage();
		const {getTextValueByKey} = useData();
		const {isNativePlatform} = useSystem();
		const voucherCode = ref<string|null>(null);
		const voucherType = ref<string|null>(null);
		const isComponentReady = ref(false);
		const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));

		onMounted(() => {
			try {
				const currentRoute = useRoute();
				const currentRouteParams = currentRoute.query || {};
				const voucherDataHash: string|any = currentRouteParams.data ?? null;
				if (voucherDataHash) {
					// console.log("TEST DATA, eyJjb2RlIjoiQUJDMTIzIiwidHlwZSI6ImRlZmF1bHQifQ==" );
					// const voucherDataHash = 'eyJjb2RlIjoiRDM2NkQ4IiwidHlwZSI6Im5pY2VzaG9wczp0cmVhc3VyZV9odW50In0='; //TREASUREHUNT
					const voucherData: any = JSON.parse(Base64.decode(voucherDataHash));
					voucherCode.value = voucherData.code ?? null;
					voucherType.value = voucherData.type ?? MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE;
				}
			} catch (e) {
				openToast(getTextValueByKey('voucher.redeem.error.general', [], 'Code konnte nicht überprüft werden'), 'danger', 'bottom', true, 10000, undefined, true);
				console.error(JSON.stringify(e));
			}

			isComponentReady.value = true;
		})

		return {add, backButtonText, isNativePlatform, voucherCode, voucherType, isComponentReady}
	}
}
</script>

<style scoped>
ion-spinner {
	position: absolute;
    width: 5em;
    height: 5em;
    --color: #25c977;
}

.redeem-voucher-loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>