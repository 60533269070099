import {
	AbstractWalletItem
} from '@/mobipoints/wallet/item/abstract_wallet_item.type';
import useData from '@/composable/useData';

export class WalletItemDeposit extends AbstractWalletItem{
	getBadgeColor(): string {
		return 'success';
	}

	getItemName(): string {
		return useData().getTextValueByKey('mobipoints.wallet.item.deposit.name', [], 'Einzahlung');
	}

	getAmountFormatted(withSuffix = false): string {
		let amount = super.getAmountFormatted(withSuffix);
		if (withSuffix) {
			amount += ' ' + useData().getTextValueByKey('mobipoints.wallet.item.deposit.amount.suffix', [], 'Punkte erhalten');
		}
		return amount;
	}
}