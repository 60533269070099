<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ title }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list class="ion-no-margin" lines="full">
        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input v-model="userTrackingTemplate.name" placeholder="Name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Typ</ion-label>
          <ion-select v-model="userTrackingTemplate.userTrackingTrackType.code" interface="popover">
            <ion-select-option v-for="userTrackingTrackType in mainData.userTrackingTrackTypes.value"
                               :key="userTrackingTrackType.code" :value="userTrackingTrackType.code">
              {{ userTrackingTrackType.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Startpunkt</ion-label>
          <ion-select v-model="userTrackingTemplate.startLocation.id" interface="popover">
            <ion-select-option :value="null">- kein Startpunkt -</ion-select-option>
            <ion-select-option v-for="location in mainData.userLocations.value"
                               :key="location.id" :value="location.id">
              {{ location.getFormattedAddress() }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-buttons slot="end">
            <ion-button @click="openAddLocation" color="primary">
              <ion-icon :icon="icons.add"></ion-icon>
              <ion-label style="margin-left: .5rem;">Standort hinzufügen</ion-label>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Endpunkt</ion-label>
          <ion-select v-model="userTrackingTemplate.endLocation.id" interface="popover">
            <ion-select-option :value="null">- kein Endpunkt -</ion-select-option>
            <ion-select-option v-for="location in mainData.userLocations.value"
                               :key="location.id" :value="location.id">
              {{ location.getFormattedAddress() }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-buttons slot="end">
            <ion-button @click="openAddLocation" color="primary">
              <ion-icon :icon="icons.add"></ion-icon>
              <ion-label style="margin-left: .5rem;">Standort hinzufügen</ion-label>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script setup lang="ts">
import {defineExpose, defineProps, ref, onMounted, onUpdated} from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonSelect, IonSelectOption,
  IonButton, IonButtons,
  IonGrid, IonRow, IonCol,
  toastController, onIonViewDidEnter
} from "@ionic/vue";
import {UserTrackingTemplate} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.type";
import {MobiPointApiProfile} from "@/mobipoints/api/profile";
import router from "@/router";
import * as icons from 'ionicons/icons';
import {AxiosError, AxiosResponse} from "axios";
import useProfileFactory from "@/composable/useProfileFactory";
import useMainData from "@/composable/useMainData";

const props = defineProps({
  userTrackingTemplateId: {
    type: Number,
    required: false,
  }
});

const { getProfileFactory } = useProfileFactory();

const userTrackingTemplate = ref<UserTrackingTemplate>(getProfileFactory().createEmptyUserTrackingTemplate());
const title = ref<string>('');
const mainData = useMainData();

onMounted(() => {
  if (props.userTrackingTemplateId) {
    loadUserTrackingTemplate(props.userTrackingTemplateId);
    title.value = 'Tracking Vorlage bearbeiten';
  } else {
    userTrackingTemplate.value = getProfileFactory().createEmptyUserTrackingTemplate();
    title.value = 'Tracking Vorlage hinzufügen';
  }
});

const loadUserTrackingTemplate = async function (id: number) {
  try {
    const response = await MobiPointApiProfile.getUserTrackingTemplate(id);
    userTrackingTemplate.value = getProfileFactory().createUserTrackingTemplateListByApiResponse(response.data).shift() ?? userTrackingTemplate.value;
  } catch (error: any | AxiosError) {
    // TODO create a custom toast controller to handle this kind of toasts (display error and go back)
    const toast = await toastController.create({
      message: `Could not load user tracking template ${id}`,
      position: 'bottom',
      duration: 3000,
      icon: icons.warning,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          icon: icons.close
        }
      ]
    });
    toast.onWillDismiss().then(router.back);
    return toast.present();
  }
}

const validateUserTrackingTemplate = async function (): Promise<boolean> {
  let message: string | null = null;

  if (userTrackingTemplate.value.name.length < 1) {
    message = 'Please enter a name for the tracking template!';
  } else if (!userTrackingTemplate.value.userTrackingTrackType || !userTrackingTemplate.value.userTrackingTrackType.code) {
    message = 'Please define a track type for the tracking template!';
  }

  if (null !== message) {
    const toast = await toastController.create({
      message: message,
      position: 'bottom',
      duration: 3000,
      icon: icons.warning,
      buttons: [
        {
          side: 'end',
          role: 'cancel',
          icon: icons.close
        }
      ]
    });

    await toast.present();

    return false;
  }

  return true;
}

const saveUserTrackingTemplate = async function (): Promise<AxiosResponse<string> | null> {
  let message = `User tracking successfully saved!`;
  let icon = icons.checkmarkCircleOutline;
  let duration = 2000;
  let response: AxiosResponse | null = null;
  try {
    if (null === userTrackingTemplate.value.id) {
      response = await MobiPointApiProfile.createUserTrackingTemplate(userTrackingTemplate.value);
    } else {
      response = await MobiPointApiProfile.updateUserTrackingTemplate(userTrackingTemplate.value);
    }
  } catch (error: any | AxiosError) {
    message = `Could not save user tracking template ${userTrackingTemplate.value.id}`;
    icon = icons.warning;
    duration = 3000;
  }

  const toast = await toastController.create({
    message: message,
    position: 'bottom',
    duration: duration,
    icon: icon,
    buttons: [
      {
        side: 'end',
        role: 'cancel',
        icon: icons.close
      }
    ]
  });
  await toast.present();

  return response;
}

const openAddLocation = () => {
  router.push({
    name: 'UserLocationAdd',
  });
};

defineExpose({
  validateUserTrackingTemplate,
  saveUserTrackingTemplate
});
</script>

<style scoped>

</style>