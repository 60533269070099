import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {MobiPointsNewsInterface} from "@/mobipoints/news/news/news.interface";

interface NewsGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsNewsInterface;
}

class NewsResponseError extends ResponseError {
}

const MobiPointsApiNews = {
	getNews: async function (): Promise<AxiosResponse<NewsGetResponse>> {
		return MobiPointsApiClient.get('/news');
	},
}

export {MobiPointsApiNews, NewsGetResponse,NewsResponseError};