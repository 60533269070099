<template>
	<ion-card class="filter-card">
		<ion-card-content>
			<!-- Collapse/Expand Icon -->
			<div class="flex justify-end cursor-pointer" @click="toggleCollapse">
				<span class="text-lg font-semibold mr-3">Filter</span>
				<ion-icon :icon="!collapsed ? icons.chevronDownOutline : icons.chevronUpOutline" class="text-primary"></ion-icon>
			</div>

			<!-- Filter Content -->
			<div v-show="collapsed">
				<!-- Filter Label -->
				<ion-grid class="ion-no-padding ion-no-margin mt-1.5">
					<ion-row class="ion-align-items-center ion-no-padding ion-no-margin">
						<!-- Date From -->
						<ion-col size="12" size-md="6">
							<ion-item>
								<ion-label position="stacked">Datum von</ion-label>
								<ion-input v-model="dateFrom" type="date" @input="handleInput"></ion-input>
							</ion-item>
						</ion-col>

						<!-- Date To -->
						<ion-col size="12" size-md="6">
							<ion-item>
								<ion-label position="stacked">Datum bis</ion-label>
								<ion-input v-model="dateTo" type="date" @input="handleInput"></ion-input>
							</ion-item>
						</ion-col>

						<!-- Type -->
						<ion-col size="6" size-md="6">
							<ion-item>
								<ion-label position="stacked">Transaktions Typ</ion-label>
								<ion-select v-model="selectedType" @ionChange="handleSelectChange">
									<ion-select-option value="">Typ auswählen</ion-select-option>
									<ion-select-option v-for="type in types" :key="type" :value="type">{{ type }}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>

						<!-- Subtype -->
						<ion-col size="6" size-md="6">
							<ion-item>
								<ion-label position="stacked">Transaktions Sub-Typ</ion-label>
								<ion-select v-model="selectedSubtype" @ionChange="handleSelectChange">
									<ion-select-option value="">Sub-Typ auswählen</ion-select-option>
									<ion-select-option v-for="subtype in subTypes" :key="subtype" :value="subtype">{{ subtype }}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>

						<!-- Submit Button -->
						<ion-col size="8" size-md="10" class="mt-2">
							<ion-button expand="block" color="success" fill="outline" @click="handleSubmit">
								<ion-icon slot="start" :icon="icons.filter"></ion-icon>
								Filtern
							</ion-button>
						</ion-col>

						<!-- Reset Button -->
						<ion-col size="4" size-md="2" class="mt-2">
							<ion-button expand="block" fill="outline" color="danger" @click="handleReset">
								<ion-icon :icon="icons.trash"></ion-icon>
							</ion-button>
						</ion-col>

					</ion-row>
				</ion-grid>
			</div>
		</ion-card-content>
	</ion-card>
</template>

<script lang="ts">
import {ref} from 'vue';
import {WalletItemSubType, WalletItemType} from '@/mobipoints/wallet/item/wallet_item.interface';
import {IonCard, IonCardContent, IonGrid, IonIcon, IonRow, IonCol, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton} from '@ionic/vue';
import * as icons from 'ionicons/icons';

export interface FilterSubmit {
	dateFrom: string,
	dateTo: string,
	selectedType: WalletItemType,
	selectedSubtype: WalletItemSubType,
}

export default {
	name: 'UserWalletItemFilter',
	components: {
		IonCard, IonCardContent, IonGrid, IonIcon, IonRow, IonCol, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton
	},
	props: {
		types: {
			type: Array<WalletItemType>,
		},
		subTypes: {
			type: Array<WalletItemSubType>,
		},
	},
	emits: ['filter', 'reset'],
	setup(props: any, {emit}) {
			const dateFrom = ref('');
			const dateTo = ref('');
			const selectedType = ref('');
			const selectedSubtype = ref('');
			const collapsed = ref(false);

			const handleInput = () => {
				// Do nothing on input change
			};

			const handleSelectChange = () => {
				// Do nothing on select change
			};

			const handleSubmit = () => {
				emit('filter', {
					dateFrom: dateFrom.value,
					dateTo: dateTo.value,
					selectedType: selectedType.value,
					selectedSubtype: selectedSubtype.value
				});
			};

			const handleReset = () => {
				dateFrom.value = '';
				dateTo.value = '';
				selectedType.value = '';
				selectedSubtype.value = '';
				emit('reset');
			};

			const toggleCollapse = () => {
				collapsed.value = !collapsed.value;
			};

			return {
				dateFrom,
				dateTo,
				selectedType,
				selectedSubtype,
				handleInput,
				handleSelectChange,
				handleSubmit,
				handleReset,
				toggleCollapse,
				collapsed,
			  	icons,
			};
	},
};
</script>

<style scoped>
.filter-card {
    --ion-background-color: white;
    --ion-color-base: black;
    --ion-color-contrast: white;
}

@media (prefers-color-scheme: dark) {
    .filter-card {
        --ion-background-color: black;
        --ion-color-base: white;
        --ion-color-contrast: black;
    }
}
</style>
