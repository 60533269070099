import {MobiPointsProfileUserInterface} from "@/mobipoints/profile/userProfile/user_profile.interface";
import {
	MobiPointsProfileUserProfileSettingInterface
} from "@/mobipoints/profile/userProfile/user_profile_data.interface";

export class MobiPointsProfileUser implements MobiPointsProfileUserInterface {
	private _profile: MobiPointsProfileUserProfileSettingInterface;

	/**
	 *
	 * @param profile
	 */
	constructor(profile: MobiPointsProfileUserProfileSettingInterface) {
		this._profile = profile;
	}

	get profile(): MobiPointsProfileUserProfileSettingInterface {
		return this._profile;
	}

	set profile(value: MobiPointsProfileUserProfileSettingInterface) {
		this._profile = value;
	}
}
