import { Plugins } from '@capacitor/core';
import {ResponseError} from "@/mobipoints/api/system";
import {MobiPointsQueueQueue} from "@/mobipoints/queue/queue.type";
import {MobiPointsQueueEventAbstractEvent} from "@/mobipoints/queue/event/abstract_event.type";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {AxiosError, AxiosResponse} from "axios";
import useSystem from "@/composable/useSystem";
import {ref} from "vue";
import {
	MobiPointsProfileUserTrackingTrackTypeInterface
} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.interface";
const { App, BackgroundTask } = Plugins;

interface MobiPointsApiGetResponse {
	error: string|null,
	status: number
}

interface UuidGetResponse extends MobiPointsApiGetResponse{
	payload: string[]
}

class TestError extends Error {
	public status: number;

	constructor(status: number, message) {
		super(message); // (1)
		this.status = status; // (2)
		this.name = "ValidationError"; // (2)
	}
}

class TaskError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const csrfErrorCount = ref<number>(0);


const MobiPointApiEvent = {

	getUuids: async function(uuidCount = 1): Promise<AxiosResponse<UuidGetResponse>> {
		return MobiPointsApiClient.get('/events/uuid?count=' + uuidCount, true);
	},

	sendEventsFromQueue: async function() {
		try {
			const queue = new MobiPointsQueueQueue();

			for (const [key, event] of Object.entries(queue.getEvents())) {
				console.log(`${key} ${event}`); // "a 5", "b 7", "c 9"
				MobiPointsApiClient.setHeader();
				await MobiPointsApiClient.put("/events", event);
			}
			return true;
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.message || ''
			);
		}
	},

	async sendEvents(events: Array<MobiPointsQueueEventAbstractEvent>)
	{
		try {
			await MobiPointsApiClient.put("/events", {events: events});
			csrfErrorCount.value = 0;
			return true;
		} catch (error: any | Error | AxiosError) {
			console.log("SEND EVENTS ERROR", error)
			useSystem().addLog('SEND EVENTS ERROR, ' + JSON.stringify(error));
			if (error && ( (error?.status && error?.status == 412) || (error && error.response?.status === 412) ) && csrfErrorCount.value < 5) {
				useSystem().addLog('CSRF Error sending events.... try again!');
				csrfErrorCount.value++;
				return await this.sendEvents(events);
			}
			csrfErrorCount.value = 0;
			throw new ResponseError(
				error.status,
				error.message || ''
			);
		}
	},

	async sendEvent(event: MobiPointsQueueEventAbstractEvent)
	{
		try {
			// MobiPointsApiClient.setHeader();
			const response = await MobiPointsApiClient.put("/events", {events: [event]});
			console.log("Response from Event", response);
			return true;
		} catch (error: any | AxiosError) {
			console.log(error.response);
			throw new ResponseError(
				error.status,
				error.message || ''
			);
		}
	}

}

export { MobiPointApiEvent, TaskError };