import {computed, ComputedRef} from 'vue';
import store from '@/store';
import {
    EVENT_STATE_NEW,
    EVENT_TYPE_ADD_COORDINATES,
    EVENT_TYPE_END_TRACK,
    EVENT_TYPE_END_TRACKING, EVENT_TYPE_REDEEM_VOUCHER,
    EVENT_TYPE_START_TRACK,
    EVENT_TYPE_START_TRACKING, EVENT_TYPE_WEATHER_DATA,
    MobiPointsQueueEventAbstractEvent
} from "@/mobipoints/queue/event/abstract_event.type";
import useEventFactory from "@/composable/useEventFactory";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import useTracking from "@/composable/useTracking";
import useSystem from "@/composable/useSystem";
import useQueue from "@/composable/useQueue";
import useWeatherFactory from "@/composable/useWeatherFactory";
import useData from "@/composable/useData";
import {createCurrentTimeStamp, getDateDiffInSeconds} from "@/mobipoints/core/date";
import {MobiPointsApiWeather} from "@/mobipoints/api/weather";
import {AxiosError} from "axios";
import {useCoordinate} from "@/composable/useCoordinate";
import {
    MobiPointsQueueEventTypeRedeemVoucherType,
} from "@/mobipoints/queue/event/type/redeem_voucher_event.type";

export default function useEvent() {
    const getEventList: ComputedRef<Array<MobiPointsQueueEventAbstractEvent>> = computed(() => store.getters['queue/getEventList']);
    const getLastEventTimestamp: ComputedRef<number | null> = computed(() => store.getters['queue/getLastEventTimestamp']);
    const hasOpenEvents: ComputedRef<boolean> = computed(() => store.getters['queue/hasOpenEvents']);

    const {getEventFactory} = useEventFactory();
    const {getQueue} = useQueue();
    const {getTrackingFactory} = useTrackingFactory();
    const {getCurrentPosition, currentTracking, hasActiveTracking, setCurrentTrackingWeatherItem, getCurrentTrackingWeatherItem, getDistanceCalculation} = useTracking();
    const {appVersion} = useSystem();

    function enhancePayloadWithMetaData(payload: PayloadInterface = {data: {}}): PayloadInterface {
        payload.meta = {
            "version": appVersion.value,
            "os": useSystem().getOs(),
            "native": useSystem().isNativePlatform() ? 'true' : 'false',
        }
        return payload;
    }

    async function addCoordinateEvent(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, subType: string, uuid: string, eventName: string | null = null, state: string = EVENT_STATE_NEW) {
        const type = EVENT_TYPE_ADD_COORDINATES;
        if (!eventName) {
            eventName = type + "_" + subType;
        }
        let payload = <PayloadInterface>{data: {coordinate}};
        payload = enhancePayloadWithMetaData(payload);
        const eventTimestamp = !coordinate.timestamp ? getTrackingFactory().createCurrentTimeStamp() : coordinate.timestamp;
        const event = getEventFactory().createNewEventByType(uuid, type, eventName, eventTimestamp, subType, state, payload);
        addEvent(event);
        await useTracking().addCoordinateToLatestTrack(coordinate);
    }

    async function addRedeemVoucherEvent(voucherCode: string, voucherType: MobiPointsQueueEventTypeRedeemVoucherType = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE, eventName: string | null = null, state: string = EVENT_STATE_NEW) {
        const type = EVENT_TYPE_REDEEM_VOUCHER;
        if (!eventName) {
            eventName = type;
        }
        let payload = <PayloadInterface>{data: {'code': voucherCode, 'type': voucherType}};
        payload = enhancePayloadWithMetaData(payload);
        const eventTimestamp = getTrackingFactory().createCurrentTimeStamp();
        const event = getEventFactory().createNewEventByType(null, type, eventName, eventTimestamp, '', state, payload);
        await addEvent(event);
    }

    async function addEventByType(type: string, subType: string, uuid: string, eventName: string | null = null, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {}}, timestamp: number | null = null) {
        if (!eventName) {
            eventName = type + "_" + subType;
        }
        payload = enhancePayloadWithMetaData(payload);
        const eventTimestamp = !timestamp ? getTrackingFactory().createCurrentTimeStamp() : timestamp;
        const event = getEventFactory().createNewEventByType(uuid, type, eventName, eventTimestamp, subType, state, payload);
        await addEvent(event);
    }

    function addCurrentPositionEvent(uuid: string, subType = "", waitTime = 0, checkStartAddress = false) {
        setTimeout(function () {
            const currentPosition = getCurrentPosition.value;
            if (currentPosition && currentPosition.latitude && currentPosition.longitude) {
                const payload = <PayloadInterface>{data: {currentPosition}};
                addEventByType(EVENT_TYPE_ADD_COORDINATES, subType, uuid, null, EVENT_STATE_NEW, payload, currentPosition.timestamp);
                useTracking().addCoordinateToLatestTrack(currentPosition).then(r => console.log(r, 'add coordinate'));
                if (checkStartAddress) {
                    useTracking().setStartAddressByCurrentPositionToCurrentTracking();
                }
            }
        }, waitTime);
    }

    function isEventTypeAllowedToRunQueueImmediately(eventType: string): boolean {
        const allowedEventTypeList = [EVENT_TYPE_START_TRACKING, EVENT_TYPE_END_TRACKING];
        return allowedEventTypeList.includes(eventType);
    }

    async function runQueueImmediately() {
        return getQueue().sendEvents();
    }

    function isFirstCoordinate(event: MobiPointsQueueEventAbstractEvent): boolean {
        return (event.getEventType() === EVENT_TYPE_ADD_COORDINATES && (!currentTracking.value?.getAllCoordinatesForMap() || currentTracking.value?.getAllCoordinatesForMap().getItems().length < 1))
    }

    async function addEvent(event: MobiPointsQueueEventAbstractEvent) {
        await beforeAddEvent(event);
        store.commit('queue/addEvent', event);
        if (isEventTypeAllowedToRunQueueImmediately(event.getEventType()) || isFirstCoordinate(event) ) {
            console.log("RUN QUEUE IMMEDIATELY FOR TYPE " + event.getEventType());
            await runQueueImmediately();
        }
        await afterAddEvent(event);
    }

    function isAllowedToSendWeatherData(): boolean
    {
        return Boolean(useData().getSystemValueByKey('send_weather_data_on_start_and_end_track', true));
    }

    async function beforeAddEvent(event: MobiPointsQueueEventAbstractEvent) {
        if (event.getEventType() === EVENT_TYPE_END_TRACK) {
            await sendWeatherDataEvent(event.userTrackingUuid, event.subType);
        }
    }

    async function afterAddEvent(event: MobiPointsQueueEventAbstractEvent) {
        if (event.getEventType() === EVENT_TYPE_START_TRACK) {
            await sendWeatherDataEvent(event.userTrackingUuid, event.subType, true);
        }
    }

    function isAllowedToSendNewWeatherEvent(coordinate: MobiPointsTrackingCoordinateCoordinateInterface|null): boolean
    {
        try {
            if (hasActiveTracking.value) {
                const currentTrackingWeatherItem = getCurrentTrackingWeatherItem.value;
                if (!currentTrackingWeatherItem) {
                    return true;
                }

                if (!coordinate?.timestamp || !currentTrackingWeatherItem.timestamp)  {
                    return true;
                }
                const diffInSeconds = getDateDiffInSeconds(coordinate?.timestamp, currentTrackingWeatherItem.timestamp);
                if (diffInSeconds > (20*60))  { //Older than 20 minutes
                    return true;
                }

                const currentWeatherTrackingCoordinates = currentTrackingWeatherItem.coordinates;
                if ( (coordinate.latitude === null || coordinate.longitude === null) || (currentWeatherTrackingCoordinates === null) ) {
                    return true;
                }
                const weatherDataCoordinate = getTrackingFactory().createCoordinate(currentWeatherTrackingCoordinates.lat, currentWeatherTrackingCoordinates.lng, currentTrackingWeatherItem.timestamp);
                const distanceInKm = getDistanceCalculation().calculateDistance(weatherDataCoordinate, coordinate)
                if (distanceInKm > 8) {
                    return true;
                }

                return false;
            }
        } catch (e) {
            useSystem().addLogByException(e);
        }

        return true;
    }

    async function sendWeatherDataEvent(userTrackingUuid: string|null, eventSubType = '', loadCurrentPosition = false, ignoreWeatherExistingValidation = false) {
        if (!isAllowedToSendWeatherData()) {
            return;
        }
        try {
            let currentPosition: MobiPointsTrackingCoordinateCoordinateInterface|undefined;
            if (loadCurrentPosition) {
                currentPosition = await getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition();
            }
            if (!currentPosition) {
                currentPosition = getCurrentPosition.value;
            }
            if (!ignoreWeatherExistingValidation && !isAllowedToSendNewWeatherEvent(currentPosition)) {
                return;
            }
            const response = await MobiPointsApiWeather.getWeather(currentPosition.latitude, currentPosition.longitude);
            const weatherItem = useWeatherFactory().getWeatherFactory().createSingleWeatherDataByApiResponse(response.data);
            let payload = <PayloadInterface>{data: {'weatherItem': weatherItem.normalizeData()}};
            payload = enhancePayloadWithMetaData(payload);
            const eventName = EVENT_TYPE_WEATHER_DATA + "_" + weatherItem.weatherType;
            const weatherEvent = getEventFactory().createNewEventByType(userTrackingUuid, EVENT_TYPE_WEATHER_DATA, eventName, createCurrentTimeStamp(), eventSubType, EVENT_STATE_NEW, payload);
            await addEvent(weatherEvent);
            if (hasActiveTracking.value) {
                setCurrentTrackingWeatherItem(weatherItem);
            }
        } catch (error: any | AxiosError) {
            console.log('Weather Response Error', error)
        }
    }

    function removeEvent(event: MobiPointsQueueEventAbstractEvent) {
        store.commit('queue/removeEvent', event)
    }

    return {
        getEventList,
        getLastEventTimestamp,
        hasOpenEvents,
        addEventByType,
        addEvent,
        addCoordinateEvent,
        removeEvent,
        addCurrentPositionEvent,
        addRedeemVoucherEvent,
    }
}
