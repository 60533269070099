<template>
	<div>
		<div v-if="!submitSend">
			<h2 v-show="!isQrScanStarted">Bitte gib deinen Gutschein Code ein!</h2>
			<qr-input v-show="isNativePlatform()" @submitScan="submit" @errorScan="errorQrScan" @startScan="startQrScan"
					  @cancelScan="cancelQrScan" class="ion-padding-top"></qr-input>
			<div v-show="showInputField && voucherType !== treasureHuntVoucherType">
				<input-split v-show="!isQrScanStarted" @submitPinInput="submit" @handleInputCount="handlePinInputCount"
							 :input-length="inputLength"
							 class="ion-padding-top ion-padding-bottom"></input-split>
				<ion-button v-show="inputPinCount === inputLength" color="success" expand="block" @click="submit">Einlösen</ion-button>
				<ion-button v-show="!isQrScanStarted" color="danger" expand="block" fill="outline"
							:class="[isQrScanStarted ? 'showButtonAtBottom' : '']" @click="goBackToStartPage">Abbrechen
				</ion-button>
			</div>
		</div>
		<div v-else>
			<div v-if="submitSend && submitResult">
				<div v-if="voucherType === treasureHuntVoucherType">
					<treasure-found-animation></treasure-found-animation>
				</div>
				<div v-else>
					<check-animation></check-animation>
					<check-mark-animation></check-mark-animation>
				</div>
				<ion-text :v-html="submitMessage"/>
			</div>
			<invalid-animation v-if="submitSend && !submitResult"></invalid-animation>
			<div class="ion-text-center ion-padding-bottom">
				<ion-text v-if="submitResult" color="success">Juhuu! <span v-html="submitMessage"/></ion-text>
				<ion-text v-else color="danger">Ohh! <span v-html="errorMessage"/></ion-text>
			</div>
			<div v-if="submitResult">
				<ion-button expand="block" fill="outline" @click="redeemAnotherVoucher">Weiteren Gutschein Code
					eingeben
				</ion-button>
				<ion-button class="ion-margin-top" color="danger" expand="block" fill="outline"
							@click="goBackToStartPage" v-if="submitResult">Zurück zur Startseite
				</ion-button>
			</div>
			<div v-else>
				<ion-button expand="block" fill="outline" @click="redeemAnotherVoucher">Probier es nochmal!</ion-button>
				<ion-button class="ion-margin-top" color="danger" expand="block" fill="outline"
							@click="goBackToStartPage">Abbrechen
				</ion-button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {onDeactivated, onMounted, ref, watch} from 'vue';
import {IonButton, IonText} from '@ionic/vue';
import QrInput from '@/components/core/Input/QrInput.vue';
import InputSplit from '@/components/core/Input/InputSplit.vue';
import useSystem from '@/composable/useSystem';
import CheckMarkAnimation from '@/components/animation/CheckMarkAnimation.vue';
import CheckAnimation from '@/components/animation/CheckAnimation.vue';
import InvalidAnimation from '@/components/animation/InvalidAnimation.vue';
import router from '@/router';
import {MobiPointsQueueEventTypeRedeemVoucherType} from '@/mobipoints/queue/event/type/redeem_voucher_event.type';
import useEvent from '@/composable/useEvent';
import {MobiPointsApiVoucher} from '@/mobipoints/api/voucher';
import {MobiPointsVoucherReason} from '@/mobipoints/voucher/voucher.interface';
import useData from '@/composable/useData';
import TreasureFoundAnimation from '@/components/animation/TreasureFoundAnimation.vue';
import useWallet from '@/composable/useWallet';

export default {
	name: 'RedeemVoucherComponent',
	components: {
		TreasureFoundAnimation,
		InvalidAnimation,
		CheckAnimation,
		CheckMarkAnimation,
		InputSplit,
		QrInput,
		IonButton,
		IonText
	},
	props: {
		voucherCode: {
			type: String,
			default: null
		},
		voucherType: {
			type: String,
			default: MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE
		},
		redirectToStartPage: {
			type: Boolean,
			default: true
		},
		showInputField: {
			type: Boolean,
			default: true,
		},
		inputLength: {
			type: Number,
			default: 6,
		}
	},
	emits: ['cancel'],
	setup: function (props, {emit}) {
		//TODO checkState of voucher via API Request
		//TODO send voucher Event on success --> show animation --> close popover after x seconds
		const {isNativePlatform} = useSystem();
		const submitMessage = ref('');
		const errorMessage = ref('');
		const submitResult = ref(false);
		const submitSend = ref(false);
		const isQrScanStarted = ref(false);
		const inputPinCount = ref(0);
		const {initWallets} = useWallet();

		const treasureHuntVoucherType = ref(MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE);
		const defaultVoucherType = ref(MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE);

		//TODO add success sound or error
		function goBackToStartPage() {
			if (props.redirectToStartPage) {
				router.push('/');
			}
			resetData();
			emit('cancel');
		}

		function redeemAnotherVoucher() {
			resetData();
		}

		function startQrScan(value: boolean) {
			isQrScanStarted.value = value;
		}

		function cancelQrScan() {
			resetData();
			isQrScanStarted.value = false;
		}

		function errorQrScan(error, qrCodeResult) {
			errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.general', [], 'QR Code konnte nicht gescannt werden!');
			submitMessage.value = '';
			submitResult.value = false;
			submitSend.value = true;
			isQrScanStarted.value = false;
		}

		async function validateVoucherCode(voucherCode: string, voucherType = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE): Promise<boolean> {
			try {
				const response = await MobiPointsApiVoucher.getVoucherValidation(voucherCode, voucherType);
				const isValid = response.data.payload.valid;
				if (isValid) {
					const payloadSuccessMessage = response.data.payload.message;
					submitMessage.value = payloadSuccessMessage && payloadSuccessMessage?.length > 0 ? payloadSuccessMessage : useData().getTextValueByKey('voucher.redeem.'+voucherType+'.success', [], 'Gutschein wurde eingelöst!')
					return true;
				}

				const validateReason = response.data.payload.reason;
				const payloadErrorMessage = response.data.payload.message;
				switch (voucherType) {
					case MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE:
						switch (validateReason) {
							case MobiPointsVoucherReason.INVALID_CODE:
								errorMessage.value = payloadErrorMessage && payloadErrorMessage?.length > 0 ? payloadErrorMessage : useData().getTextValueByKey('voucher.redeem.treasure_hunt.error.invalid_code', [], 'Ungültiger Code!');
								return false;
							case MobiPointsVoucherReason.INVALID_DATE:
								errorMessage.value = payloadErrorMessage && payloadErrorMessage?.length > 0 ? payloadErrorMessage : useData().getTextValueByKey('voucher.redeem.treasure_hunt.error.invalid_date', [], 'Oh nein, das war wohl nichts 🥸! Probiere ein anderes Ei oder versuche es an einem anderen Tag.');
								return false;
							case MobiPointsVoucherReason.INVALID_STATE:
								errorMessage.value = payloadErrorMessage && payloadErrorMessage?.length > 0 ? payloadErrorMessage : useData().getTextValueByKey('voucher.redeem.treasure_hunt.error.invalid_state', [], 'Dieser Code ist leider nicht gültig️ ❗️️');
								return false;
							case MobiPointsVoucherReason.USED:
								errorMessage.value = payloadErrorMessage && payloadErrorMessage?.length > 0 ? payloadErrorMessage : useData().getTextValueByKey('voucher.redeem.treasure_hunt.error.used', [], 'Super gemacht! 🌟 Doch heute hast du bereits ein Ei gefunden und eingelöst. 🥚 Morgen kannst du ein weiteres einlösen. Bleib dran! 💪');
								return false;
						  default:
								errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.general', [], 'Code konnte nicht überprüft werden');
								return false;
						}
					default:
					  switch (validateReason) {
						  case MobiPointsVoucherReason.INVALID_CODE:
							  errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.invalid_code', [], 'Ungültiger Code!');
							  return false;
						  case MobiPointsVoucherReason.INVALID_DATE:
							  errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.invalid_date', [], 'Gutschein abgelaufen oder noch nicht gültig!!');
							  return false;
						  case MobiPointsVoucherReason.INVALID_STATE:
							  errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.invalid_state', [], 'Gutschein muss gültig sein!');
							  return false;
						  case MobiPointsVoucherReason.USED:
							  errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.used', [], 'Gutschein wurde bereits eingelöst!');
							  return false;
					  }
				}
			} catch (e) {
				errorMessage.value = useData().getTextValueByKey('voucher.redeem.error.general', [], 'Code konnte nicht überprüft werden');
				return false;
			}
		}

		async function submit(voucherCode: string, voucherType = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE, data: any = undefined) {
			isQrScanStarted.value = false;
			const isValidVoucher = await validateVoucherCode(voucherCode, voucherType);
			if (isValidVoucher) {
				try {
					await useEvent().addRedeemVoucherEvent(voucherCode, voucherType);
					submitResult.value = true;
					if (!submitMessage.value) {
						submitMessage.value = useData().getTextValueByKey('voucher.redeem.'+voucherType+'.success', [], 'Gutschein wurde eingelöst!')
					}
					if (voucherType === MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE) {
					  initWallets(true);
					}
				} catch (e) {
					console.error(e);
					submitResult.value = false;
				}
			} else {
				submitResult.value = false;
			}
			submitSend.value = true;
		}

		function handlePinInputCount(inputCount = 0) {
			if (inputCount === 6) {
				setTimeout(() => {
					inputPinCount.value = inputCount;
				}, 400)
			} else {
				inputPinCount.value = inputCount;
			}
		}

		function resetData() {
			submitSend.value = false;
			submitResult.value = false;
			submitMessage.value = '';
			errorMessage.value = '';
			inputPinCount.value = 0;
		}

		watch(() => props.voucherCode, (newVoucherCode: any) => {
			if (newVoucherCode) {
				submit(props.voucherCode, props.voucherType);
			}
		});

		onMounted(() => {
			inputPinCount.value = 0;
			if (props.voucherCode && props.voucherType) {
				submit(props.voucherCode, props.voucherType);
			}
		})

		onDeactivated(() => {
			resetData();
		})

		return {
			isNativePlatform,
			submit,
			startQrScan,
			cancelQrScan,
			errorQrScan,
			submitMessage,
			submitResult,
			submitSend,
			goBackToStartPage,
			redeemAnotherVoucher,
			isQrScanStarted,
			errorMessage,
			handlePinInputCount,
			inputPinCount,
			treasureHuntVoucherType,
			defaultVoucherType,
		}
	}
}
</script>

<style scoped>
</style>
