import {computed, ComputedRef, onBeforeMount, ref} from 'vue';
import store from '@/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from 'axios';
import {WalletList} from '@/mobipoints/wallet/wallet_list.type';
import {
	MobiPointsApiWallet,
	WalletResponseError,
} from '@/mobipoints/api/wallet';
import useWalletFactory from '@/composable/useWalletFactory';
import {WalletItemList} from '@/mobipoints/wallet/item/wallet_item_list.type';
import {
	WalletItemSubType,
	WalletItemType,
} from '@/mobipoints/wallet/item/wallet_item.interface';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';

export default function useWallet() {
	const wallets: ComputedRef<WalletList> = computed(
			() => store.getters['wallet/wallets']);

	const isWalletLoading = ref(false);
	const isWalletItemLoading = ref(false);

	const loadWallets = async function(): Promise<WalletList> {
		try {
			isWalletLoading.value = true;
			const response = await MobiPointsApiWallet.getWallets();
			isWalletLoading.value = false;
			return useWalletFactory().
			getWalletFactory().
			createWalletListByResponse(response.data.payload.wallets);
		} catch (error: any | AxiosError) {
			isWalletLoading.value = false;
			throw new WalletResponseError(error.status, error.error.message);
		}
	};

	const loadWalletItems = async function(
			wallet_Uuid: string, count: number | null = null,
			offset = 0, walletType: WalletType|undefined = undefined): Promise<WalletItemList> {
		try {
			isWalletItemLoading.value = true;
			const response = await MobiPointsApiWallet.getWalletItems(wallet_Uuid, count, offset);
			isWalletItemLoading.value = false;
			return useWalletFactory().
			getWalletItemFactory().
			createWalletItemListByResponse(response.data.payload.list, walletType);
		} catch (error: any | AxiosError) {
			isWalletItemLoading.value = false;
			throw new WalletResponseError(error.status, error.error.message);
		}
	};

	const filterWalletItems = async function(
			wallet_Uuid: string,
			dateFrom: string | null = null, dateTo: string | null = null,
			types: Array<WalletItemType> | null = null,
			subTypes: Array<WalletItemSubType> | null = null,
			saveToStorage = true): Promise<WalletItemList> {
		try {
			isWalletItemLoading.value = true;
			const response = await MobiPointsApiWallet.filterWalletItems(wallet_Uuid,
					dateFrom, dateTo, types, subTypes);
			isWalletItemLoading.value = false;
			const walletItemList = useWalletFactory().
			getWalletItemFactory().
			createWalletItemListByResponse(response.data.payload.list);
			if (saveToStorage) {
				setWalletItems(wallet_Uuid, walletItemList);
			}
			return walletItemList;
		} catch (error: any | AxiosError) {
			isWalletItemLoading.value = false;
			throw new WalletResponseError(error.status, error.error.message);
		}
	};

	const resetWallets = function() {
		store.commit('wallet/resetWallets');
	};

	const initWalletItems = async function(
			wallet_Uuid: string, count: number | null = 300, forceReload = false) {
		if (((!wallets.value.getItemById(wallet_Uuid)?.items ||
						!wallets.value.getItemById(wallet_Uuid)?.items?.length) ||
				forceReload) && !isWalletItemLoading.value) {
			const walletType = wallets.value.getItemById(wallet_Uuid)?.type;
			const walletItemList = await loadWalletItems(wallet_Uuid, count, 0, walletType);
			setWalletItems(wallet_Uuid, walletItemList);
			return walletItemList;
		}
	};

	const setWalletItems = function(
			wallet_Uuid: string, walletItemList: WalletItemList) {
			store.commit('wallet/setWalletItems',
					{wallet_uuid: wallet_Uuid, walletItem_List: walletItemList});
	};

	const initWallets = async function(forceReload = false) {
		if (((!wallets.value || !wallets.value.length) || forceReload) &&
				!isWalletLoading.value) {
			resetWallets();
			store.commit('wallet/wallets', await loadWallets());
		}
	};

	onBeforeMount(() => {
		// initWallets(true).then(() => {
		//	console.log("wallets loaded");
		// });
	});

	return {
		wallets,
		loadWallets,
		loadWalletItems,
		filterWalletItems,
		initWallets,
		initWalletItems,
		resetWallets,
		setWalletItems,
	};
}