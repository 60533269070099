import {MobiPointsApiGetResponse, ResponseError} from "@/mobipoints/api/api.interfaces";
import {AxiosResponse} from "axios";
import MobiPointsApiClient from "@/mobipoints/api/client";
import {MobiPointsVoucherValidationInterface} from "@/mobipoints/voucher/voucher.interface";
import {
    MobiPointsQueueEventTypeRedeemVoucherType
} from '@/mobipoints/queue/event/type/redeem_voucher_event.type';

interface VoucherGetResponse extends MobiPointsApiGetResponse {
    payload: MobiPointsVoucherValidationInterface;
}

class VoucherResponseError extends ResponseError {
}

const MobiPointsApiVoucher = {
    getVoucherValidation: async function (code: string, voucherType = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_DEFAULT_TYPE): Promise<AxiosResponse<VoucherGetResponse>> {
        return MobiPointsApiClient.get('/voucher/validation?code=' + code +'&type=' + voucherType);
    },
}

export {MobiPointsApiVoucher, VoucherGetResponse, VoucherResponseError};
