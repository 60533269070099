import MobiPointsApiClient from "./client";
import {AxiosError, AxiosResponse} from "axios";
import {
	MobiPointsProfileUserTrackingTemplateInterface
} from "@/mobipoints/profile/userTrackingTemplate/user_tracking_template.interface";
import {
	MobiPointsProfileUserTrackingTrackTypeInterface
} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.interface";
import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {MobiPointsApiGetResponse, MobiPointsApiJsonResponse} from "@/mobipoints/api/api.interfaces";
import {UserLocation} from "@/mobipoints/profile/userLocation/user_location.type";
import {MobiPointsProfileUser} from "@/mobipoints/profile/userProfile/user_profile.type";
import {MobiPointsProfileUserInterface} from "@/mobipoints/profile/userProfile/user_profile.interface";
import useSystem from "@/composable/useSystem";

interface UserTrackingTemplateGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsProfileUserTrackingTemplateInterface[]
}

interface UserTrackingTrackTypeGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsProfileUserTrackingTrackTypeInterface[]
}

interface UserTrackingTemplatePutResponse {
	id: number,
	name: string
}

interface UserLocationGetResponse extends MobiPointsApiGetResponse {
	locations: {
		user: MobiPointsProfileUserLocationInterface[],
		userGroups: MobiPointsProfileUserLocationInterface[],
	}
}

interface UserProfileGetResponse extends MobiPointsApiGetResponse {
	payload: MobiPointsProfileUserInterface
}

class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

class UserProfileResponseError extends ResponseError {
}

/**
 * @todo factory and create interface for userLocation
 */
const MobiPointApiProfile = {
	getUserProfile: async function (): Promise<AxiosResponse<UserProfileGetResponse>> {
		try {
			return MobiPointsApiClient.get("/user/profile");
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	updateUserProfile: async function (userProfile: MobiPointsProfileUser) {
		try {
			const profileData = {
				'darkMode': userProfile.profile.darkMode,
				'trackingAutoStop': userProfile.profile.trackingAutoStop,
			};
			return MobiPointsApiClient.post("/user/profile", profileData);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},

	getUserLocation: async function (): Promise<AxiosResponse<UserLocationGetResponse>> {
		try {
			return MobiPointsApiClient.get("/location");
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	getUserLocationById: async function (id) {
		try {
			return MobiPointsApiClient.get("/location/" + id);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	createUserLocation: async function (userLocationData: UserLocation) {
		try {
			return MobiPointsApiClient.put("/location", userLocationData);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	updateUserLocation: async function (userLocationData: any) {
		try {
			return MobiPointsApiClient.post("/location", userLocationData);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	deleteUserLocation: async function (id) {
		try {
			return MobiPointsApiClient.delete("/location/" + id);
		} catch (error: any | AxiosError) {
			useSystem().addLog(JSON.stringify(error))
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	getUserTrackingTemplates: async function (state: string | null = null): Promise<AxiosResponse<UserTrackingTemplateGetResponse>> {
		try {
			state = state || 'active';
			return MobiPointsApiClient.get(`/userTracking/tracking/template?state=${state}`, true);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	getActiveUserTrackingTemplates: async function (): Promise<AxiosResponse<UserTrackingTemplateGetResponse>> {
		return this.getUserTrackingTemplates('active');
	},
	getUserTrackingTemplate: async function (id: number): Promise<AxiosResponse<UserTrackingTemplateGetResponse>> {
		try {
			return MobiPointsApiClient.get(`/userTracking/tracking/template/${id}`);
		} catch (error: any | AxiosError) {
			throw new ResponseError(
				error.status,
				error.error.message
			);
		}
	},
	updateUserTrackingTemplate: async function (userTrackingTemplate: MobiPointsProfileUserTrackingTemplateInterface): Promise<AxiosResponse<string>> {
		return MobiPointsApiClient.post('/userTracking/tracking/template', {
			id: userTrackingTemplate.id,
			name: userTrackingTemplate.name,
			userTrackingTrackType: userTrackingTemplate.userTrackingTrackType?.code,
			startLocationId: userTrackingTemplate.startLocation?.id,
			endLocationId: userTrackingTemplate.endLocation?.id,
			sort: userTrackingTemplate.sort,
		});
	},
	updateUserTrackingTemplateSorting: async function (userTrackingTemplates: Array<MobiPointsProfileUserTrackingTemplateInterface>): Promise<AxiosResponse<string>> {
		return MobiPointsApiClient.post('/userTracking/tracking/template', userTrackingTemplates.map((val) => {
			return {id: val.id, sort: val.sort};
		}));
	},
	createUserTrackingTemplate: async function (userTrackingTemplate: MobiPointsProfileUserTrackingTemplateInterface): Promise<AxiosResponse<UserTrackingTemplatePutResponse>> {
		return MobiPointsApiClient.put('/userTracking/tracking/template', {
			name: userTrackingTemplate.name,
			userTrackingTrackType: userTrackingTemplate.userTrackingTrackType?.code,
			startLocationId: userTrackingTemplate.startLocation?.id,
			endLocationId: userTrackingTemplate.endLocation?.id,
			sort: userTrackingTemplate.sort,
		});
	},
	deleteUserTrackingTemplate: async function (id: number): Promise<AxiosResponse<string | MobiPointsApiJsonResponse>> {
		return MobiPointsApiClient.delete(`/userTracking/tracking/template/${id}`);
	},
	getUserTrackingTrackTypes: async function (): Promise<AxiosResponse<UserTrackingTrackTypeGetResponse>> {
		return MobiPointsApiClient.get('/userTracking/tracking/track/type');
	},
}

export {
	MobiPointApiProfile,
	ResponseError,
	UserTrackingTemplateGetResponse,
	UserProfileGetResponse,
	UserProfileResponseError
};
