import {
    MobiPointsQueueEventAbstractEvent,
    EVENT_TYPE_REDEEM_VOUCHER
} from "@/mobipoints/queue/event/abstract_event.type";

export const enum MobiPointsQueueEventTypeRedeemVoucherType {
    REDEEM_VOUCHER_DEFAULT_TYPE = 'default',
    REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE = 'niceshops:treasure_hunt',
    REDEEM_VOUCHER_NICESHOPS_VEGGIE_CHALLENGE = 'niceshops:veggie_challenge',
}

export class MobiPointsQueueEventTypeRedeemVoucherEvent extends MobiPointsQueueEventAbstractEvent {

    getEventType(): string {
        return EVENT_TYPE_REDEEM_VOUCHER;
    }

}
