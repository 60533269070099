import MobiPointsApiClient from "./client";
import {MobiPointApiToken} from "./token";
import {AxiosError, AxiosRequestConfig} from "axios";
import router from "@/router";
import {addSeconds, convertTimeStampToSeconds} from "@/mobipoints/core/date";

class AuthenticationError extends Error {
	errorCode: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

class BasicAuthError extends Error {
	errorCode: any;
	errorFields: any;

	constructor(errorCode: any, errorFields: any | undefined, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		if (errorFields != null) {
			this.errorFields = errorFields;
		}
		this.errorCode = errorCode;
	}
}

export interface MobiPointsAuthUserRegisterInterface {
	name: string;
	email: string;
	password: string;
}

export interface MobiPointsAuthUserLoginInterface {
	email: string;
	password: string;
}

export interface MobiPointsAuthUserForgotPasswordInterface {
	email: string;
}

export interface MobiPointsAuthUserSetNewPasswordInterface {
	hash: string;
	password: string;
}

export interface MobiPointsAuthUserVerifyInterface {
	hash: string;
}

const MobiPointsApiAuth = {

	getSignInUrl(): string {
		return process.env.VUE_APP_API + "/login/basic";
	},

	signIn: async function (signInData: any) {
		try {
			const response = await MobiPointsApiClient.post(this.getSignInUrl(), signInData);
			if (response.data.access_token) {
				const lifeTimeTimestamp = response.data.lifetime ?? null;
				MobiPointApiToken.saveToken(response.data.access_token);
				MobiPointApiToken.setTokenLifeTime(lifeTimeTimestamp);
				MobiPointsApiClient.setHeader();
				return response.data.access_token;
			} else {
				throw new AuthenticationError(400, response.data.message ?? 'Unknown Signin Error');
			}
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	getAuthUser() {
		return MobiPointsApiClient.get("/user").then(response => {
				return response.data;
			},
			error => {
				console.log("Cannot get Auth User", error);
			});
	},

	logout() {
		try {
			MobiPointApiToken.removeToken();
			MobiPointsApiClient.unmount401Interceptor();
			window.localStorage.clear();
			router.push('/login');
		} catch (error) {
			console.log(error);
			router.push('/login');
		}
		return true;
	},

	registerUser: async function (registerData: MobiPointsAuthUserRegisterInterface) {
		try {
			const response = await MobiPointsApiClient.put( "/user/register", registerData);
			if (response.status == 201) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Signin Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	forgotPassword: async function (forgotPasswordData: MobiPointsAuthUserForgotPasswordInterface) {
		try {
			const response = await MobiPointsApiClient.post( "/user/forgotPassword", forgotPasswordData);
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Forgot Password Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Forgot Password Error');
			} else {
				this.catchError(error);
			}
		}
	},

	resetAndVerifyNewPassword: async function (uuid: string, resetAndVerifyNewPasswordData: MobiPointsAuthUserSetNewPasswordInterface) {
		try {
			const response = await MobiPointsApiClient.post( "/user/" + uuid + "/password/reset", resetAndVerifyNewPasswordData);
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Forgot Password Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Forgot Password Error');
			} else {
				this.catchError(error);
			}
		}
	},

	resendNewPasswordLink: async function (uuid: string) {
		try {
			const response = await MobiPointsApiClient.post("/user/" + uuid + "/password/reset/resend", {});
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	loginUser: async function (loginData: MobiPointsAuthUserLoginInterface) {
		try {
			const response = await MobiPointsApiClient.post( "/user/login/basic", loginData);
			if (response.status == 200 && response.data.payload && response.data.payload.access_token) {
				let lifeTimeTimestamp = response.data.payload.lifetime ?? null;
				if (!lifeTimeTimestamp) {
					lifeTimeTimestamp = convertTimeStampToSeconds(addSeconds(new Date(), (3600 * 12) * 60).getTime());
				}
				MobiPointApiToken.saveToken(response.data.payload.access_token);
				MobiPointApiToken.setTokenLifeTime(lifeTimeTimestamp);
				MobiPointsApiClient.setHeader();
				return response.data.payload;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown Signin Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},


	verifyAccount: async function (uuid: string, hash: string) {
		try {
			const response = await MobiPointsApiClient.post( "/user/" + uuid + "/verify", {hash: hash});
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},


	resendVerifyLink: async function (uuid: string) {
		try {
			const response = await MobiPointsApiClient.post("/user/" + uuid + "/verify/resend", {});
			if (response.status == 200) {
				return true;
			} else {
				throw new BasicAuthError(response.status, response.data.error.fields ?? null,response.data.error.message ?? 'Unknown verify Error');
			}
		} catch (error: AxiosError|BasicAuthError|any) {
			if (error instanceof BasicAuthError) {
				throw error;
			} else if (error && error.response!.status === 400) {
				throw new BasicAuthError(error.response.status, error.response.data.error.fields ?? null,error.response.data.error.message ?? 'Unknown Signin Error');
			} else {
				this.catchError(error);
			}
		}
	},

	signup: async function (email: any, password: any, name: any) {
		const signupData: AxiosRequestConfig = {
			method: "post",
			headers: {"Content-Type": "application/json"},
			url: "/oauth/signup",
			data: {
				email: email,
				password: password,
				name: name
			}
		};

		try {
			return await MobiPointsApiClient.customRequest(signupData);
		} catch (error) {
			this.catchError(error);
		}
	},

	catchError: function (error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data.error_description ?? error.message;
		}

		throw new AuthenticationError(status, description);
	}
}

export {MobiPointsApiAuth, AuthenticationError, BasicAuthError};
