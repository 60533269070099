<template>
	<div :class="[!isNativePlatform() ? 'center-animation' : '']">
		<svg class="animated" :style="getAnimationStyle()" id="freepik_stories-treasure"
			 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500"
			 version="1.1" xmlns:svgjs="http://svgjs.com/svgjs">
			<g id="freepik--background-simple--inject-18" class="animable"
			   style="transform-origin: 257.042px 200.955px;">
				<path d="M72.32,204.15c23.69-36.5,62.8-18.65,106.36-44.8S219.76,70.63,284.44,54.13C327,43.29,375.51,62.8,406.21,90.39c42,37.77,54.4,93.29,42.25,143.87-9.69,40.33-35,77.54-73.22,100.13-54.48,32.17-108.5,6.55-161.42,10.17s-91.76,2.66-119.8-32S48.64,240.66,72.32,204.15Z"
					  style="fill: rgb(255, 193, 0); transform-origin: 257.042px 200.955px;" id="el7t2n0wtlvrh"
					  class="animable"></path>
				<g id="el7z1m0dxv055">
					<path d="M72.32,204.15c23.69-36.5,62.8-18.65,106.36-44.8S219.76,70.63,284.44,54.13C327,43.29,375.51,62.8,406.21,90.39c42,37.77,54.4,93.29,42.25,143.87-9.69,40.33-35,77.54-73.22,100.13-54.48,32.17-108.5,6.55-161.42,10.17s-91.76,2.66-119.8-32S48.64,240.66,72.32,204.15Z"
						  style="fill: rgb(255, 255, 255); opacity: 0.7; isolation: isolate; transform-origin: 257.042px 200.955px;"
						  class="animable"></path>
				</g>
			</g>
			<g id="freepik--Shadow--inject-18" class="animable" style="transform-origin: 247px 407.54px;">
				<ellipse cx="247" cy="407.54" rx="199.12" ry="36.01"
						 style="fill: rgb(255, 193, 0); transform-origin: 247px 407.54px;" id="eljq60dgkepk"
						 class="animable"></ellipse>
				<g id="elchqps9r5m3o">
					<ellipse cx="247" cy="407.54" rx="199.12" ry="36.01"
							 style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 247px 407.54px;"
							 class="animable"></ellipse>
				</g>
			</g>
			<g id="freepik--hole-and-shovel--inject-18" class="animable" style="transform-origin: 339.84px 286.336px;">
				<g id="freepik--Hole--inject-18" class="animable" style="transform-origin: 313.79px 297.663px;">
					<path d="M393.93,296.77c.13,1.75-.17,12.69-.25,15.65-12.65,6.83-38.79,11.87-66,11.42-3.18-.06-6.44-.2-9.75-.42-4.73-.31-9.56-.79-14.36-1.4-5.47-.7-10.93-1.58-16.21-2.59-5.59-1.07-11-2.31-16-3.64a137.85,137.85,0,0,1-16.62-5.48l-.38-.16c-8.24-3.46-13.35-7.17-13.29-10.64a4.59,4.59,0,0,1,.1-1c-9.35-3.58-15.21-7.45-15.15-11,.17-10.66,28.41-16.68,68.62-16s102.06,9.77,101.89,20.45c0,1.64-1,3.27-2.65,4.85Z"
						  style="fill: rgb(153, 153, 153); transform-origin: 311.275px 297.663px;" id="elh2jwufyvgth"
						  class="animable"></path>
					<path d="M401.56,303.85c-.05,3-2.92,6-7.89,8.65.09-3,.39-13.9.26-15.65h0s0,0,0,0C398.78,299.05,401.61,301.4,401.56,303.85Z"
						  style="fill: rgb(92, 92, 92); transform-origin: 397.615px 304.675px;" id="eli25j2ohisae"
						  class="animable"></path>
					<g id="el7uv111f4oq6">
						<path d="M246,305.82c.25.14-4.81-7.29-4.81-7.29S239.58,302.33,246,305.82Z"
							  style="opacity: 0.5; transform-origin: 243.51px 302.176px;" class="animable"></path>
					</g>
				</g>
				<g id="freepik--Shovel--inject-18" class="animable" style="transform-origin: 413.63px 286.336px;">
					<path d="M401.15,328.94,405,312.17l28.42,7-2.22,12.94a25.87,25.87,0,0,1-14.92,19.22c-5,2.26-9.53,4.35-8.84,4.33s-1.39-4.2-3.71-8.38A25.85,25.85,0,0,1,401.15,328.94Z"
						  style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 416.955px 333.915px;"
						  id="eltxg7m84ww8j" class="animable"></path>
					<path d="M427,216.36c.23,1.19,2,16.44,8.13,17.88s17.28-9.16,18.44-13.08C455.52,214.61,425.48,208.36,427,216.36Zm21.64,5.55c-.79,2.66-8.36,9.86-12.53,8.88s-5.38-11.35-5.53-12.15C429.55,213.2,450,217.45,448.65,221.91Z"
						  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 440.303px 223.483px;"
						  id="elzhhsdgkjxi" class="animable"></path>
					<path d="M434.06,212.59a3.74,3.74,0,0,0,0,3.36"
						  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 433.861px 214.27px;"
						  id="elxboik7dluh" class="animable"></path>
					<line x1="446.06" y1="214.8" x2="444.73" y2="218.04"
						  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 445.395px 216.42px;"
						  id="el6v4eung0pan" class="animable"></line>
					<polygon points="416.72 315.04 433.74 228.59 439.61 229.54 421.42 316.19 416.72 315.04"
							 style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 428.165px 272.39px;"
							 id="elwtfhz04ywin" class="animable"></polygon>
					<path d="M419.42,301l5,1.14s-3.67,16.94-4.17,18.84-3.33,3-3.33,3a5.13,5.13,0,0,1-1-4.51C416.63,316.69,419.42,301,419.42,301Z"
						  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 420.091px 312.49px;"
						  id="el5ypf162xjwj" class="animable"></path>
					<path d="M405.1,322.61c-6.09.92-11.76,5.65-11.12,11.41a7.9,7.9,0,0,0-8.43-.38,7.08,7.08,0,0,0-3.38,7.37c-4.24-.93-8.92,2.9-8.55,7s5.64,7,9.65,5.27c7.69,6.6,19.47,8.63,29.31,5.06l29.4-.49a44.29,44.29,0,0,0-11.79-8.14,7.57,7.57,0,0,0-.44-8.36,8.58,8.58,0,0,0-8.25-3C422.24,335.07,419.43,320.45,405.1,322.61Z"
						  style="fill: rgb(153, 153, 153); transform-origin: 407.79px 341.238px;" id="elp9gio40va3j"
						  class="animable"></path>
				</g>
			</g>
			<g id="freepik--Character--inject-18" class="animable" style="transform-origin: 324.387px 249.52px;">
				<line x1="257.2" y1="174.67" x2="256.82" y2="174.45"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 257.01px 174.56px;"
					  id="elu7s5pc7z2p" class="animable"></line>
				<path d="M336.71,298.55c-.82.48-18,10.39-37.26,7.13-16-2.7-21.06-11.46-21.06-11.46C297.61,303.13,326.29,300.39,336.71,298.55Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 307.55px 300.278px;"
					  id="elxidiwhg4eur" class="animable"></path>
				<path d="M323.46,199.64c-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89c.48.15.77.3.78.44Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 314.151px 202.823px;"
					  id="elike05f24m9d" class="animable"></path>
				<path d="M323.46,199.64c-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89.11-2,.23-4.19c12.67,3.7,15.63-8.12,15.63-8.12l.43,2.67Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 314.151px 195.668px;" id="el41t2mg9o7l9"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path--inject-18&quot;); transform-origin: 318.261px 200.09px;"
				   id="elslw8hqm9cn" class="animable">
					<path d="M316.75,194.47a22.24,22.24,0,0,0-3.9,4.64,1.46,1.46,0,0,1,2,.07,3.2,3.2,0,0,1,.86,2,9,9,0,0,1-1.15,5.73c1.64-1,3.41-1.84,5-2.93a9.44,9.44,0,0,0,3.73-4.33,5,5,0,0,0-1-5.37c-1.44-1.3-4.06-1.17-5.07.48.47-.5.94-1,1.4-1.49Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 318.261px 200.09px;" id="el8wha8iz7qk"
						  class="animable"></path>
					<g id="elzj6z8nwxyg9">
						<g style="opacity: 0.6; transform-origin: 318.261px 200.09px;" class="animable">
							<path d="M316.75,194.47a22.24,22.24,0,0,0-3.9,4.64,1.46,1.46,0,0,1,2,.07,3.2,3.2,0,0,1,.86,2,9,9,0,0,1-1.15,5.73c1.64-1,3.41-1.84,5-2.93a9.44,9.44,0,0,0,3.73-4.33,5,5,0,0,0-1-5.37c-1.44-1.3-4.06-1.17-5.07.48.47-.5.94-1,1.4-1.49Z"
								  style="fill: rgb(255, 255, 255); transform-origin: 318.261px 200.09px;"
								  id="elv0dknxa3axc" class="animable"></path>
						</g>
					</g>
				</g>
				<path d="M323.46,199.64c-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89.11-2,.23-4.19c12.67,3.7,15.63-8.12,15.63-8.12l.43,2.67Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 314.151px 195.668px;"
					  id="elozwy58r4wuk" class="animable"></path>
				<path d="M319.38,189.44S311.12,201.56,299,194c0,0,5.29,4.63,10.18,5.46S319.38,189.44,319.38,189.44Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 309.19px 194.473px;"
					  id="elc8ok7bhfj2l" class="animable"></path>
				<path d="M294.61,154.35c-2,1.87-6.77,12.45-3.09,28.87s20.85,18.81,28.87,4.6c6.33-11.23,8.6-22.74,4.76-30.36S306.91,143.09,294.61,154.35Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 308.598px 172.765px;" id="el65w4wnjtuci"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path-2--inject-18&quot;); transform-origin: 308.703px 173.457px;"
				   id="els90gqjr7y0m" class="animable">
					<path d="M297,151.56a11.28,11.28,0,0,0-6.11,8.28c1.74-3.49,7.25-4.23,9.85-1.32,2.1-2.22,5.83-2.31,8.43-.73a11.36,11.36,0,0,1,4.8,7.5,34,34,0,0,1,.25,9.12q-.39,5.91-.76,11.81c-.27,4.27-.8,9.05-4.11,11.76a27.62,27.62,0,0,0,6-47.75,7.28,7.28,0,0,0-7.53-.46C304.84,151.37,300.08,150.07,297,151.56Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 308.703px 173.457px;" id="elpq0p20cm6dq"
						  class="animable"></path>
					<g id="el1fki9rxlkyh">
						<path d="M297,151.56a11.28,11.28,0,0,0-6.11,8.28c1.74-3.49,7.25-4.23,9.85-1.32,2.1-2.22,5.83-2.31,8.43-.73a11.36,11.36,0,0,1,4.8,7.5,34,34,0,0,1,.25,9.12q-.39,5.91-.76,11.81c-.27,4.27-.8,9.05-4.11,11.76a27.62,27.62,0,0,0,6-47.75,7.28,7.28,0,0,0-7.53-.46C304.84,151.37,300.08,150.07,297,151.56Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 308.703px 173.457px;"
							  class="animable"></path>
					</g>
				</g>
				<path d="M294.61,154.35c-2,1.87-6.77,12.45-3.09,28.87s20.85,18.81,28.87,4.6c6.33-11.23,8.6-22.74,4.76-30.36S306.91,143.09,294.61,154.35Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 308.598px 172.765px;"
					  id="eldp9065hpqw" class="animable"></path>
				<line x1="383.15" y1="197.82" x2="382.7" y2="197.91"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 382.925px 197.865px;"
					  id="elc7eeqck1trl" class="animable"></line>
				<path d="M293.08,369.91c1.19-2.12,3.75-5.59,5.15-5.69s7.9,4.74,7.9,4.74-2.47,6.85,2.53,5.15c3.43-1.17,15.13,7.18,19.48,12.06,3.35,3.76,3.4,8.79-5,8.73S293.08,369.91,293.08,369.91Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 311.664px 379.559px;"
					  id="elliu8cawte4d" class="animable"></path>
				<path d="M292,388.63c0-.1,13.84,6.25,22.28,6.73s12.84.29,13.11-3.95-8.75-10.93-22.28-15.32l1,1.74c-1.79-3.66-5.86-8-11.05-7.44s-7.15,6.13-7.15,6.13Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 307.653px 382.942px;"
					  id="elbdmkhjzxmdi" class="animable"></path>
				<path d="M340.75,372.88c1.19-2.13,3.75-5.6,5.15-5.7s7.91,4.75,7.91,4.75-2.47,6.85,2.53,5.14c3.42-1.17,16,8.09,20.33,13,3.35,3.76,3.39,8.79-5,8.74S340.75,372.88,340.75,372.88Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 359.763px 382.994px;"
					  id="el74v36qjdp26" class="animable"></path>
				<path d="M338.79,389.89c.06-.1,15,3,20.8,10.17,5.32,6.56,15.39-.31,16-4.51s-9.58-11.48-22.68-17l.86,1.82c-1.47-3.8-5.15-8.45-10.37-8.37s-7.65,5.49-7.65,5.49Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 355.683px 387.386px;"
					  id="el7ck9piy69vs" class="animable"></path>
				<path d="M355.71,390s1,7.81,3.88,10"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 357.65px 395px;"
					  id="elisoh83hhghs" class="animable"></path>
				<path d="M273.58,302s-52.33,48-58.72,69.37-1.64,23.84-1.64,23.84,7.25,3.56,31.75,1.77a265.89,265.89,0,0,0,44.92-7.35s10.38-9.93.92-16.48l-43.3.63s37.12-39.23,45.56-41.24S292.18,307.3,273.58,302Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 253.862px 349.742px;"
					  id="elq8p4wfwulk" class="animable"></path>
				<path d="M333.49,329.3c-4.52-15.21-23.95-19.79-35.25-8.65-16,15.75-41.11,42.21-45.41,56.63-6.39,21.4-1.64,23.83-1.64,23.83s7.25,3.57,31.75,1.78,53.76-11.28,53.76-11.28,10.38-9.93.92-16.48l-52.14,4.56S314.89,353.3,331,338.45C333.44,336.25,334.88,334,333.49,329.3Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 295.545px 358.936px;"
					  id="elem8ral54ovh" class="animable"></path>
				<path d="M331.36,338.26a3,3,0,0,0,.89-1c1.84-3.3.13-10.84.13-10.84-6.23-6.36-25.23-1.71-36.18,1.7-4.58,1.42-7.76,2.63-8,2.72h0l-21.11-22.67,11.32-13.92c19.22,8.91,47.9,6.17,58.32,4.33h0C349.5,325.32,333.82,336.69,331.36,338.26Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 304.385px 316.255px;"
					  id="eluuflcu0lji9" class="animable"></path>
				<path d="M294.51,173.68c0,.92-.47,1.66-1.05,1.66s-1-.73-1.06-1.65.47-1.66,1.05-1.66S294.5,172.77,294.51,173.68Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 293.453px 173.685px;" id="elf7gw0ibg9us"
					  class="animable"></path>
				<path d="M305.89,174.23c0,.92-.46,1.66-1,1.67s-1.06-.74-1.06-1.66.47-1.66,1-1.66S305.89,173.32,305.89,174.23Z"
					  style="fill: rgb(38, 50, 56); transform-origin: 304.86px 174.24px;" id="elvd5z0k2g7c"
					  class="animable"></path>
				<path d="M300.72,181c-2.16,1.8-5.68-.27-5.56-2.12s2-1.86,3.16-2.73-.53-4.61-.53-4.61"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 297.939px 176.616px;"
					  id="elzdyr1q50fo" class="animable"></path>
				<path d="M303.86,191.32c-3.08,0-6.86-6.46-6.86-6.46a14,14,0,0,0,11.52-1.07S309.48,191.32,303.86,191.32Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 302.791px 187.555px;"
					  id="elg1sn1pye03d" class="animable"></path>
				<path d="M302.3,165c6.39-.76,8.07,4,8.07,4"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 306.335px 166.959px;"
					  id="el9vz8b15g6vo" class="animable"></path>
				<path d="M290.67,167.15c.49-.75,2-3.28,5.57-1.6"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 293.455px 166.068px;"
					  id="elc53gzqvzo3h" class="animable"></path>
				<path d="M304.53,196.79c-.22-.77-.45-1.53-.67-2.29a15.38,15.38,0,0,0,3.81-.5,2.54,2.54,0,0,0,3.31,2.08C309.12,196.87,306.55,196.84,304.53,196.79Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 307.42px 195.406px;"
					  id="elioik5g4usmq" class="animable"></path>
				<path d="M275.7,185.59l-18.5-10.92c-7.71-24.56-15.39-43.4-15.39-43.4a13.26,13.26,0,0,0,.9-3,21.27,21.27,0,0,0-1.44-12.2L238,106.46s-.08-6.19-1.45-6.55c-2.1-.54-1.16,5.61-1.16,5.61s1.16,7.39,1.16,7.48a25.23,25.23,0,0,1-2.93-10.42c-.22-2.28-.41-4.09-.51-5a2.64,2.64,0,0,0-.75-1.59c-.57-.55-1.34-.73-1.65,1.77a39.87,39.87,0,0,0,.3,9.41l1.61,6.63-.56-1.34L229.28,106s.76-5.5-.41-6.6-2.31,1.33-2,5.46,2.33,10.7,2.41,11l-2.8-4.21a11.18,11.18,0,0,0-.82-5.1c-.7-1.33-1.69,1-1.4,5.17.82,11.62,7.37,24.12,7.48,21.55l1,9.35A277.26,277.26,0,0,0,240.35,185h0s16.58,12.58,28.78,21.95C266.64,193.62,272.47,187.75,275.7,185.59Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 249.954px 151.299px;" id="elxge354p8ed"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path-3--inject-18&quot;); transform-origin: 252.795px 146.41px;"
				   id="elzp55g2nghie" class="animable">
					<path d="M236.24,118.35c-.52,2.51-1.05,5-1.57,7.53a5.92,5.92,0,0,1,4.22-3.88c-2,6.69-.89,13.95,1.21,20.61s5.16,13,7.1,19.72a21.68,21.68,0,0,1,1.07,8.73,9.52,9.52,0,0,1-4.52,7.24c2.85-2.88,7.74-1.39,11.21.7a48.75,48.75,0,0,1,14.65,13.7l6.24-8c-9.2-6.58-15.47-16.6-19.53-27.16s-6.11-21.75-8.38-32.83c-1.88-9.13-4.4-19-11.86-24.59,2.06,1.54.55,8.09-.15,10a15.56,15.56,0,0,1-6.19,7.57C232,117.08,234,117.77,236.24,118.35Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 252.795px 146.41px;" id="elpd4uvt9qxd8"
						  class="animable"></path>
					<g id="elpa3fwowcfc">
						<path d="M236.24,118.35c-.52,2.51-1.05,5-1.57,7.53a5.92,5.92,0,0,1,4.22-3.88c-2,6.69-.89,13.95,1.21,20.61s5.16,13,7.1,19.72a21.68,21.68,0,0,1,1.07,8.73,9.52,9.52,0,0,1-4.52,7.24c2.85-2.88,7.74-1.39,11.21.7a48.75,48.75,0,0,1,14.65,13.7l6.24-8c-9.2-6.58-15.47-16.6-19.53-27.16s-6.11-21.75-8.38-32.83c-1.88-9.13-4.4-19-11.86-24.59,2.06,1.54.55,8.09-.15,10a15.56,15.56,0,0,1-6.19,7.57C232,117.08,234,117.77,236.24,118.35Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 252.795px 146.41px;"
							  class="animable"></path>
					</g>
				</g>
				<path d="M275.7,185.59l-18.5-10.92c-7.71-24.56-15.39-43.4-15.39-43.4a13.26,13.26,0,0,0,.9-3,21.27,21.27,0,0,0-1.44-12.2L238,106.46s-.08-6.19-1.45-6.55c-2.1-.54-1.16,5.61-1.16,5.61s1.16,7.39,1.16,7.48a25.23,25.23,0,0,1-2.93-10.42c-.22-2.28-.41-4.09-.51-5a2.64,2.64,0,0,0-.75-1.59c-.57-.55-1.34-.73-1.65,1.77a39.87,39.87,0,0,0,.3,9.41l1.61,6.63-.56-1.34L229.28,106s.76-5.5-.41-6.6-2.31,1.33-2,5.46,2.33,10.7,2.41,11l-2.8-4.21a11.18,11.18,0,0,0-.82-5.1c-.7-1.33-1.69,1-1.4,5.17.82,11.62,7.37,24.12,7.48,21.55l1,9.35A277.26,277.26,0,0,0,240.35,185h0s16.58,12.58,28.78,21.95C266.64,193.62,272.47,187.75,275.7,185.59Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 249.954px 151.299px;"
					  id="elb0wc7r2te4s" class="animable"></path>
				<path d="M436.49,144.25c-1.52-.52-3.27,3.16-3.27,3.16l-4,3.3a62.44,62.44,0,0,0,5.1-8.74c1.64-3.8,1.36-6.48-.1-5.82s-2.57,6.1-2.57,6.1l-5.73,6.49-.09-.06,3.82-5.93a40.56,40.56,0,0,0,3.4-8.79c.52-2.47-.27-2.55-1-2.21a2.58,2.58,0,0,0-1.23,1.25c-.4.85-1.18,2.49-2.13,4.58a35.94,35.94,0,0,1-6.51,9.65l-.22,0,3.07-5s4.29-8.81,2.12-8.82-3.59,6.22-3.59,6.22L417,147.8a21.47,21.47,0,0,0-5.38,11h0a13,13,0,0,0-.14,3.17S383,197,382.7,197.91l-16,2.09c-2.89.37-5.8.65-8.7.87-1.42,5.35-3,15.16,1.12,24.19,15.6-2.69,36.09-11.86,36.09-11.86L420.49,167S431,155,433.4,151.6,438.64,145,436.49,144.25Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 396.865px 178.332px;" id="elutwyfaxh25"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path-4--inject-18&quot;); transform-origin: 389.649px 193.024px;"
				   id="elmn2pgjgjzv" class="animable">
					<path d="M415.72,166.64,397.07,195.1c-2.34,3.56-5.5,7.55-9.75,7.26,1.22,2.84.38,6.27-1.51,8.72a17.81,17.81,0,0,1-7.53,5.24,36.36,36.36,0,0,1-21.15,1.94c-1.3,1.52-.17,4.07,1.64,4.91s3.93.51,5.9.15c10.86-2,22.41-4.28,30.31-12,4.1-4,6.86-9.11,9.55-14.15l9.65-18c2.54-4.76,8-11.54,8.55-16.82C421.93,162.75,415.72,165.86,415.72,166.64Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 389.649px 193.024px;" id="el0zabh64uxlkn"
						  class="animable"></path>
					<g id="elizzv1iuwqgb">
						<path d="M415.72,166.64,397.07,195.1c-2.34,3.56-5.5,7.55-9.75,7.26,1.22,2.84.38,6.27-1.51,8.72a17.81,17.81,0,0,1-7.53,5.24,36.36,36.36,0,0,1-21.15,1.94c-1.3,1.52-.17,4.07,1.64,4.91s3.93.51,5.9.15c10.86-2,22.66-2.42,30.56-10.12,4.1-4,6.61-11,9.3-16l9.65-18c2.54-4.76,8-11.54,8.55-16.82C421.93,162.75,415.72,165.86,415.72,166.64Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 389.649px 193.039px;"
							  class="animable"></path>
					</g>
				</g>
				<path d="M436.49,144.25c-1.52-.52-3.27,3.16-3.27,3.16l-4,3.3a62.44,62.44,0,0,0,5.1-8.74c1.64-3.8,1.36-6.48-.1-5.82s-2.57,6.1-2.57,6.1l-5.73,6.49-.09-.06,3.82-5.93a40.56,40.56,0,0,0,3.4-8.79c.52-2.47-.27-2.55-1-2.21a2.58,2.58,0,0,0-1.23,1.25c-.4.85-1.18,2.49-2.13,4.58a35.94,35.94,0,0,1-6.51,9.65l-.22,0,3.07-5s4.29-8.81,2.12-8.82-3.59,6.22-3.59,6.22L417,147.8a21.47,21.47,0,0,0-5.38,11h0a13,13,0,0,0-.14,3.17S383,197,382.7,197.91l-16,2.09c-2.89.37-5.8.65-8.7.87-1.42,5.35-3,15.16,1.12,24.19,15.6-2.69,36.09-11.86,36.09-11.86L420.49,167S431,155,433.4,151.6,438.64,145,436.49,144.25Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 396.865px 178.332px;"
					  id="elnfrr70crs1" class="animable"></path>
				<path d="M360.89,200.63c-3.87.28-8.41,1-14.53.69-7.7-.38-15.31-.56-22.9-1.68-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89c-2.9-.91-12.93-1.87-12.93-1.87h0a18.44,18.44,0,0,0-13.52-10.26l-3.16-.55c-2.87,2.37-8.7,8.24-6.21,21.56,3.67,2.82,6.95,5.36,9.26,7.16,2.75,3.71,4.2,55.26,4.2,55.26l-4.2,24.86c19.21,8.91,47.9,6.17,58.32,4.33h0s-3.26-35.47-.89-46.24c.94-4.26,2.89-12.54,4.91-21a0,0,0,0,0,0,0c0-.12,8.29-2.89,18.37-6.23C357.4,219.93,359.19,209.89,360.89,200.63Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 314.708px 242.96px;" id="elvcw3qdg54i"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path-5--inject-18&quot;); transform-origin: 315.329px 223.861px;"
				   id="elc6ot2wz0zxw" class="animable">
					<path d="M344.6,204.69a44.86,44.86,0,0,1,11-.42,7.55,7.55,0,0,0,3.81-.5,2.59,2.59,0,0,0,1.37-3.22l-20.94.17a7.49,7.49,0,0,0-3.52.6,5,5,0,0,0-2.34,4.07c-.28,3.41.42,4.54,3.09,2.74A19.87,19.87,0,0,1,344.6,204.69Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 347.425px 204.765px;" id="el57bu5qg7ld8"
						  class="animable"></path>
					<g id="el0tnvg9uk3xz">
						<path d="M344.6,204.69a44.86,44.86,0,0,1,11-.42,7.55,7.55,0,0,0,3.81-.5,2.59,2.59,0,0,0,1.37-3.22l-20.94.17a7.49,7.49,0,0,0-3.52.6,5,5,0,0,0-2.34,4.07c-.28,3.41.42,4.54,3.09,2.74A19.87,19.87,0,0,1,344.6,204.69Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 347.425px 204.765px;"
							  class="animable"></path>
					</g>
					<path d="M350.24,225c-1,.51-2.13,1-3.07.49a2.2,2.2,0,0,1-1-2,6.35,6.35,0,0,1,.62-2.24A14.32,14.32,0,0,0,340,231.74a138.72,138.72,0,0,0,15.56-5,6.15,6.15,0,0,0,2.67-1.62c1.3-1.55.91-3.84-1.07-3.82S351.89,224.09,350.24,225Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 349.493px 226.495px;" id="elk9feypeil4o"
						  class="animable"></path>
					<g id="elp6zge9415li">
						<path d="M350.24,225c-1,.51-2.13,1-3.07.49a2.2,2.2,0,0,1-1-2,6.35,6.35,0,0,1,.62-2.24A14.32,14.32,0,0,0,340,231.74a138.72,138.72,0,0,0,15.56-5,6.15,6.15,0,0,0,2.67-1.62c1.3-1.55.91-3.84-1.07-3.82S351.89,224.09,350.24,225Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 349.493px 226.495px;"
							  class="animable"></path>
					</g>
					<path d="M301.64,243.14a19.86,19.86,0,0,0,13.41,5.26l0,6.22a50.27,50.27,0,0,1-7.86-4.21,16.66,16.66,0,0,1-5.81-6.63C301.06,244.08,301.64,243.14,301.64,243.14Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 308.167px 248.88px;" id="elclqx5gs674t"
						  class="animable"></path>
					<g id="elrm03srcuez">
						<g style="opacity: 0.6; transform-origin: 308.167px 248.88px;" class="animable">
							<path d="M301.64,243.14a19.86,19.86,0,0,0,13.41,5.26l0,6.22a50.27,50.27,0,0,1-7.86-4.21,16.66,16.66,0,0,1-5.81-6.63C301.06,244.08,301.64,243.14,301.64,243.14Z"
								  style="fill: rgb(255, 255, 255); transform-origin: 308.167px 248.88px;"
								  id="elo1mb56hjcfo" class="animable"></path>
						</g>
					</g>
					<path d="M295.33,220.29c-1.42,6.61-1.15,13.81,2.14,19.71a7.07,7.07,0,0,0-5.39-3c1.61,2.54,1.87,5.69,2.09,8.69q.57,8.18,1.16,16.36a17.86,17.86,0,0,1-6-9.89,43.84,43.84,0,0,1-.9-11.72c.24-9.23,1.3-18.58,4.4-27.32,1.42-4,3.26-8.95,6.14-12.18.86-1,2.78-2.64,3.71-.85.56,1.09-1,3.49-1.47,4.41C298.83,209.59,296.52,214.75,295.33,220.29Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 295.578px 230.671px;" id="elbg5p42tlffh"
						  class="animable"></path>
					<g id="elsork2dm0bi">
						<g style="opacity: 0.6; transform-origin: 295.578px 230.671px;" class="animable">
							<path d="M295.33,220.29c-1.42,6.61-1.15,13.81,2.14,19.71a7.07,7.07,0,0,0-5.39-3c1.61,2.54,1.87,5.69,2.09,8.69q.57,8.18,1.16,16.36a17.86,17.86,0,0,1-6-9.89,43.84,43.84,0,0,1-.9-11.72c.24-9.23,1.3-18.58,4.4-27.32,1.42-4,3.26-8.95,6.14-12.18.86-1,2.78-2.64,3.71-.85.56,1.09-1,3.49-1.47,4.41C298.83,209.59,296.52,214.75,295.33,220.29Z"
								  style="fill: rgb(255, 255, 255); transform-origin: 295.578px 230.671px;"
								  id="elze1izflybef" class="animable"></path>
						</g>
					</g>
					<path d="M269.73,192.69a13.11,13.11,0,0,1,16.41,9.83c1.66-2.09,3.33-4.18,5-6.28a2.92,2.92,0,0,0,.74-1.34,2.86,2.86,0,0,0-.86-2.14,20,20,0,0,0-11.72-6.92C274.17,184.88,271.67,188.13,269.73,192.69Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 280.806px 194.096px;" id="el43kpqxz4j9"
						  class="animable"></path>
					<g id="elvvr7wsmzp4k">
						<path d="M269.73,192.69a13.11,13.11,0,0,1,16.41,9.83c1.66-2.09,3.33-4.18,5-6.28a2.92,2.92,0,0,0,.74-1.34,2.86,2.86,0,0,0-.86-2.14,20,20,0,0,0-11.72-6.92C274.17,184.88,271.67,188.13,269.73,192.69Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 280.806px 194.096px;"
							  class="animable"></path>
					</g>
					<path d="M340.05,223.26c-1.92.11-4.05-.93-4.54-2.79-.19-.76-.37-1.84-1.15-1.82s-.91.81-1,1.44a26.68,26.68,0,0,0,4.07,17.29c.2.31.47.66.85.66.56,0,.78-.71.86-1.26.85-5.83,3.72-10.06,5.91-15.27C343.4,222,341.93,223.14,340.05,223.26Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 339.127px 228.345px;" id="el029kifgzdnjr"
						  class="animable"></path>
					<g id="elydpy89ecn5n">
						<path d="M340.05,223.26c-1.92.11-4.05-.93-4.54-2.79-.19-.76-.37-1.84-1.15-1.82s-.91.81-1,1.44a26.68,26.68,0,0,0,4.07,17.29c.2.31.47.66.85.66.56,0,.78-.71.86-1.26.85-5.83,3.72-10.06,5.91-15.27C343.4,222,341.93,223.14,340.05,223.26Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 339.127px 228.345px;"
							  class="animable"></path>
					</g>
					<path d="M322.63,200.73s-9.91,6.15-8.08,6.18,5.78,1.25,5.8,4S322.63,200.73,322.63,200.73Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 318.477px 206.012px;" id="el9fxifgxkb"
						  class="animable"></path>
					<g id="elb47oeani6z">
						<path d="M322.63,200.73s-9.91,6.15-8.08,6.18,5.78,1.25,5.8,4S322.63,200.73,322.63,200.73Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 318.477px 206.012px;"
							  class="animable"></path>
					</g>
				</g>
				<path d="M360.89,200.63c-3.87.28-8.41,1-14.53.69-7.7-.38-15.31-.56-22.9-1.68-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89c-2.9-.91-12.93-1.87-12.93-1.87h0a18.44,18.44,0,0,0-13.52-10.26l-3.16-.55c-2.87,2.37-8.7,8.24-6.21,21.56,3.67,2.82,6.95,5.36,9.26,7.16,2.75,3.71,4.2,55.26,4.2,55.26l-4.2,24.86c19.21,8.91,47.9,6.17,58.32,4.33h0s-3.26-35.47-.89-46.24c.94-4.26,2.89-12.54,4.91-21a0,0,0,0,0,0,0c0-.12,8.29-2.89,18.37-6.23C357.4,219.93,359.19,209.89,360.89,200.63Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 314.708px 242.96px;"
					  id="elq8b4nzfoq2g" class="animable"></path>
				<path d="M416.41,148.83a3.27,3.27,0,0,1,.61-1,8.84,8.84,0,0,0,1.71-5.46,1,1,0,0,0-1.73-.59c-.74.9-1.2,2.29-1.9,3.29-.47.67-1.12,1.19-1.63,1.83-1.46,1.81-2.92,11.44-2,15.11l4.61-.48c2.66-1.23,3.62-6.87.48-11A1.94,1.94,0,0,1,416.41,148.83Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 414.958px 151.735px;" id="elsq2wznz6mps"
					  class="animable"></path>
				<path d="M411.51,162c-1-3.67.5-13.3,2-15.11.51-.64,1.16-1.16,1.63-1.83.7-1,1.16-2.39,1.9-3.29a1,1,0,0,1,1.73.59A8.84,8.84,0,0,1,417,147.8a3.27,3.27,0,0,0-.61,1,1.94,1.94,0,0,0,.19,1.65c3.14,4.15,2.18,9.79-.48,11"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 414.979px 151.725px;"
					  id="elq424o8istg" class="animable"></path>
				<path d="M419.9,147.31s3.58-1.53,9.65,4.29"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 424.725px 149.364px;"
					  id="el9is0agssng" class="animable"></path>
				<path d="M424.45,157.86c-1.6.65-3.39,1.1-4.55,2.38"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 422.175px 159.05px;"
					  id="elsx1dcwhr3dj" class="animable"></path>
				<path d="M427.93,154.36a3.63,3.63,0,0,1-1.49,2.39"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 427.185px 155.555px;"
					  id="el90p26fj12r" class="animable"></path>
				<polygon points="314.15 300.28 294.39 300.28 294.39 261.34 314.15 264.08 314.15 300.28"
						 style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 304.27px 280.81px;"
						 id="elbn35d6zfg3m" class="animable"></polygon>
				<path d="M279.28,221.75c-2.65,9.24-3,13.37-5.88,28.35-.9,4.71-2.2,9.34-2.72,14.12-.37,3.36-.4,6.75-.44,10.13l.91,10.11c-.91,8.14,4.43,10.33,8.32,11.22,10.69,2.44,13.49,3,19,4.6.19-10.7-.66-15.85-1.08-28.38-.12-3.54-.33-9.22-2.84-11.86-.65-.69-3.6-4.6-4.11-5.45a4,4,0,0,1-.48-2.08c.35-21.81,2.93-42.76,12.53-54.69.64-.79-9.52-2.85-10.62-2.42-2.13.84-2.63,2.44-3.69,4.53A125.14,125.14,0,0,0,279.28,221.75Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 286.38px 247.811px;"
					  id="el5zeaw9qwmbw" class="animable"></path>
				<path d="M339,239c6.28,7.34,5,26,4.75,30.86a83.75,83.75,0,0,1-1.34,10.2l-2.63,15.18c-.86,5-5.94,7.24-11.52,7.32-8,.12-17.68,1.56-25.44-1.6q2.85-16.29,5.67-32.33c.61-3.52,1.71-7.6,5.34-8.86,1.05-.37.26.35,1.05-.37.55-.51,2-37.57,8.94-60.72.3-1,6-.94,7.19-.28,2.38,1.26,3.35,2.73,3.55,5.1C336.18,221.78,334.05,233.13,339,239Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 323.423px 250.453px;"
					  id="elia0mmyra77" class="animable"></path>
				<path d="M312.21,271.05c.39,1.4-2.75,25.56-2.75,25.56s12.51,3.27,21.59,1.83a6.64,6.64,0,0,0,5.66-6.53V271.83Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 323.085px 284.928px;"
					  id="eluz43ab6f9r" class="animable"></path>
				<path d="M312.21,271.05s5.65,6.76,11,6.94,13.46-6.16,13.46-6.16"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 324.44px 274.522px;"
					  id="el8gxolxn9sz7" class="animable"></path>
				<path d="M320.51,277.39s-3.66,13.85-1.66,20.76"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 319.381px 287.77px;"
					  id="elz55qqmi8zr9" class="animable"></path>
				<path d="M326.18,277.57s3.14,11,1.47,20.72"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 327.16px 287.93px;"
					  id="elss9jjmvcf9p" class="animable"></path>
				<path d="M271.18,266.92c.17.84-.22,7.59-2.15,14.39s2.46,12.34,7.84,13c5.13.65,11.15.69,15-1.59l2.74-24.05Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 281.588px 280.808px;"
					  id="eln61l9d0t8ml" class="animable"></path>
				<path d="M271.18,266.92s5,7.05,10.14,7.44,13.33-5.68,13.33-5.68"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 282.915px 270.649px;"
					  id="el1272px8x5ut" class="animable"></path>
				<path d="M278.74,273.65s-4.36,13.66-2.9,20.54"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 277.14px 283.92px;"
					  id="el3b71z1g3nsd" class="animable"></path>
				<path d="M284.15,274.06s2.23,11.11,0,20.6"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 284.646px 284.36px;"
					  id="el0sl6t04q0v6k" class="animable"></path>
				<path d="M306.33,153.48c10.39.88,12.54,14.87,12.5,18.51-.18,16.2-5.95,17.46-4.68,21.27.61,1.84,6.18,5.06,8.11,4.85.58,2.2,1.34,5.87,3.1,7.68,6.44,6.57,10.65-6.56,15-5.27,0,0-6.94-21.6-6.69-28.57s-.44-14.39-4.63-20a18.74,18.74,0,0,0-13.83-7.32c-5.13-.25-10.09,1.61-14.89,3.45a22.5,22.5,0,0,0-7,3.68,19.44,19.44,0,0,0-4.16,5.84,23.76,23.76,0,0,0-2.52,6.57c-.95,5.07.92,10.41-.33,15.42-.49,2-1.44,3.81-1.62,5.81s.77,4.36,2.73,4.82a3.5,3.5,0,0,1,1.59.49,2.64,2.64,0,0,1,.61,1.38A7.7,7.7,0,0,0,300.88,197a4.89,4.89,0,0,0,3.86,4.76c0-.93.21-3.67.21-3.67-7.95-4.06-8.47-5.83-9.22-6.54a10.84,10.84,0,0,1-2.46-3.85c-1.75-4.45-2.47-8.22-2.79-21.56-.06-2.18,2.07-9.79,3.79-11.14s4.54.43,5.85,2.2C300.27,156.16,302.7,153.18,306.33,153.48Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 312.514px 176.124px;"
					  id="elcc6pc0t5iol" class="animable"></path>
				<path d="M320,155.66a17.07,17.07,0,0,1,2,4,37.34,37.34,0,0,1,.34,21.21c-1.94,6.93-4.66,8.55-4.32,11.45.27,2.26,6.18,2.56,8.94,1.21a6.26,6.26,0,0,0,0,8.07c2,2.19,5.19,3.24,8-.86"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 326.475px 179.595px;"
					  id="el2xsghq7guso" class="animable"></path>
				<path d="M317.3,152.51a16.21,16.21,0,0,1,1.36,1.39"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 317.98px 153.205px;"
					  id="el7v2nv9hlust" class="animable"></path>
				<path d="M306,149a12.12,12.12,0,0,1,9.72,2.25"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 310.86px 150.008px;"
					  id="elfw0wlzv1y9u" class="animable"></path>
				<path d="M330.48,178.33a26.91,26.91,0,0,0,2.15,7.89"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 331.555px 182.275px;"
					  id="elgijiwcvb9bl" class="animable"></path>
				<path d="M328.64,159.65c1.11,5,1.07,10.47,1.51,15.69"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 329.395px 167.495px;"
					  id="elgpa7sxkc9e" class="animable"></path>
				<path d="M327.14,155.67a20.43,20.43,0,0,1,.88,2.19"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 327.58px 156.765px;"
					  id="elof7igrlw7s" class="animable"></path>
				<path d="M290.78,155.34a4,4,0,0,1,4.55-1.2,11.87,11.87,0,0,1,4.12,2.86"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 295.115px 155.434px;"
					  id="elg7b586oazzo" class="animable"></path>
				<path d="M346.32,221.29a21.48,21.48,0,0,0-6,11.23"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 343.32px 226.905px;"
					  id="elrzhhag4y308" class="animable"></path>
				<path d="M275.47,203.78a29.39,29.39,0,0,1,3.34,10.92"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 277.14px 209.24px;"
					  id="elwc8b947h7z" class="animable"></path>
				<path d="M295,242.91l2.61,5.49c2.55,5.4,5.34,11.06,10.33,14.34"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 301.47px 252.825px;"
					  id="elcn0hlorzwpg" class="animable"></path>
				<path d="M293.11,238.83l1.19,2.52"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 293.705px 240.09px;"
					  id="el2m50qqhfgl6" class="animable"></path>
				<path d="M305.25,248.49a32.82,32.82,0,0,0,9.44,6"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 309.97px 251.49px;"
					  id="els8s75wdd2cr" class="animable"></path>
				<path d="M300.65,243.36a33,33,0,0,0,3,3.53"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 302.15px 245.125px;"
					  id="elwyokufrij" class="animable"></path>
				<path d="M382.86,198.38a9.93,9.93,0,0,1,5.84,6.48"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 385.78px 201.62px;"
					  id="eldnzl7fn0xwh" class="animable"></path>
				<path d="M247.88,174.38a22.8,22.8,0,0,1,14.93,3.53l-.95-.41"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 255.345px 176.075px;"
					  id="el7isnku3h27a" class="animable"></path>
				<path d="M244.93,116.35c-.27-.76-.71-1.47-.93-2.26-.34-1.17-.31-2.64-.71-3.73a1,1,0,0,0-1.83,0,8.83,8.83,0,0,0-.19,5.72,3.41,3.41,0,0,1,.25,1.17,2,2,0,0,1-.73,1.5,9.54,9.54,0,0,0-2.07,1.86c-2.21,2.65-2.71,6.1-1.62,8l4.71,2.65C244.09,130.52,245.93,119.14,244.93,116.35Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 240.877px 120.512px;" id="elaank0oj6ine"
					  class="animable"></path>
				<g style="clip-path: url(&quot;#freepik--clip-path-6--inject-18&quot;); transform-origin: 241.52px 120.792px;"
				   id="elg28i19yw7ej" class="animable">
					<path d="M242.3,118.13a14.38,14.38,0,0,1-2.37,7.61,12,12,0,0,0-2,3.1c-.37,1.18.07,2.74,1.27,3s2.23-.71,2.91-1.69c3.9-5.59,4-13.3,1.15-19.5-.23-.53-.8-1.31-1.43-.76-.41.36,0,1.64.07,2.06A32.27,32.27,0,0,1,242.3,118.13Z"
						  style="fill: rgb(255, 193, 0); transform-origin: 241.52px 120.792px;" id="el0s02s3dg1s1"
						  class="animable"></path>
					<g id="elskknuco0du">
						<path d="M242.3,118.13a14.38,14.38,0,0,1-2.37,7.61,12,12,0,0,0-2,3.1c-.37,1.18.07,2.74,1.27,3s2.23-.71,2.91-1.69c3.9-5.59,4-13.3,1.15-19.5-.23-.53-.8-1.31-1.43-.76-.41.36,0,1.64.07,2.06A32.27,32.27,0,0,1,242.3,118.13Z"
							  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 241.52px 120.792px;"
							  class="animable"></path>
					</g>
				</g>
				<path d="M237.1,128.62c-1.09-1.92-.59-5.37,1.62-8a9.54,9.54,0,0,1,2.07-1.86,2,2,0,0,0,.73-1.5,3.41,3.41,0,0,0-.25-1.17,8.83,8.83,0,0,1,.19-5.72,1,1,0,0,1,1.83,0c.4,1.09.37,2.56.71,3.73.22.79.66,1.5.93,2.26,1,2.79-.84,14.17-3.12,14.92"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 240.877px 120.527px;"
					  id="elvetcrbp389g" class="animable"></path>
				<path d="M238,114.47a22.09,22.09,0,0,0-9.35,2.27"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 233.325px 115.605px;"
					  id="el4ayqbp3o7uo" class="animable"></path>
				<path d="M306.71,315.5a21.29,21.29,0,0,0-8.47,5.15c-9.25,9.13-21.6,21.86-31.17,33.76"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 286.89px 334.955px;"
					  id="eltd0jokf5kt" class="animable"></path>
				<path d="M314.15,314.5a21.19,21.19,0,0,0-3.73.17"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 312.285px 314.575px;"
					  id="el63ed2wi1mcc" class="animable"></path>
				<path d="M295.5,323.41a33.54,33.54,0,0,0-10.36-15.05"
					  style="fill: none; stroke: rgb(153, 153, 153); stroke-linecap: round; stroke-linejoin: round; transform-origin: 290.32px 315.885px;"
					  id="elv813gfqvzpd" class="animable"></path>
			</g>
			<g id="freepik--Treasure--inject-18" class="animable" style="transform-origin: 148.265px 306.475px;">
				<path d="M79.8,353.79c1.75-.91,13.44-80.2,13.44-80.2s126.22-23.37,128-23.47l-9.35,73.66Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 150.52px 301.955px;"
					  id="el7x2j97h1gvu" class="animable"></path>
				<g id="el41xj3vsyx0x">
					<path d="M211.92,323.42c0,.24,0,.36,0,.36l-132.07,30c1.75-.91,13.44-80.2,13.44-80.2s126.22-23.37,128-23.47a.15.15,0,0,1,.12.18C219.17,267.14,212.72,318.09,211.92,323.42Z"
						  style="stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; opacity: 0.5; transform-origin: 150.632px 301.945px;"
						  class="animable"></path>
				</g>
				<path d="M93.24,273.59S68.69,288.6,64,306.13,79.8,353.79,79.8,353.79s3.89-14.61,7.32-40.1C88.47,303.64,93.24,273.59,93.24,273.59Z"
					  style="fill: rgb(38, 50, 56); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 78.2711px 313.69px;"
					  id="el60edq7w2myq" class="animable"></path>
				<g id="elj3kvlds9zdl">
					<path d="M205.63,325c-2.63-3.82-16.8-22.8-10.47-37.53,11.33-26.39,19.92-27.57,23.79-34.27C219,253.22,206.43,319.69,205.63,325Z"
						  style="opacity: 0.5; transform-origin: 206.249px 289.1px;" class="animable"></path>
				</g>
				<polygon
						points="79.8 353.79 86.75 352.21 98.89 280.22 215.37 257.92 205.63 325.02 211.87 323.78 221.34 250.3 93.24 273.59 79.8 353.79"
						style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 150.57px 302.045px;"
						id="el6q4hmf0nmm5" class="animable"></polygon>
				<path d="M79.8,353.79l4.09,46.42H79.8l2,10.46s46.85,31.75,50.87,31.63c8.51-.24,123-36.63,123-36.63l1.16-8.18-2.92-1.56V353.79l-42.07-30Z"
					  style="fill: rgb(255, 193, 0); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 168.315px 383.045px;"
					  id="elgenfxzfqdz7" class="animable"></path>
				<g id="el7d7zda5iaaf">
					<polygon
							points="253.94 353.79 253.94 395.93 132.11 431.37 83.88 400.22 79.8 353.79 211.87 323.78 253.94 353.79"
							style="opacity: 0.5; transform-origin: 166.87px 377.575px;" class="animable"></polygon>
				</g>
				<g id="ela69463kexce">
					<polygon points="79.8 353.79 129.47 383.77 253.94 353.79 211.87 323.78 79.8 353.79"
							 style="opacity: 0.2; transform-origin: 166.87px 353.775px;" class="animable"></polygon>
				</g>
				<g id="elvphd9d6jyt">
					<polygon points="93.24 354.7 130.64 376.84 241.67 349.95 210.7 327.75 93.24 354.7"
							 style="opacity: 0.5; transform-origin: 167.455px 352.295px;" class="animable"></polygon>
				</g>
				<line x1="129.47" y1="383.77" x2="132.71" y2="442.3"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 131.09px 413.035px;"
					  id="elqk9zu2ff2qd" class="animable"></line>
				<line x1="83.89" y1="404.31" x2="132.24" y2="435.38"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 108.065px 419.845px;"
					  id="elvlmbft4x0i" class="animable"></line>
				<line x1="253.94" y1="399.24" x2="132.71" y2="435.38"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 193.325px 417.31px;"
					  id="elq4evlw5g44e" class="animable"></line>
				<g id="elfjfyliykvpg">
					<path d="M204.13,393.48c-8.51,3.61-17.2-.7-17.2-.7v-7a26.29,26.29,0,0,0,14.42,1.56c3.56-.54,4.27-2,4-3.35C207.41,391.72,204.13,393.48,204.13,393.48Z"
						  style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 196.48px 389.456px;"
						  class="animable"></path>
				</g>
				<g id="eldimv9sa2arp">
					<path d="M201.35,387.36a26.29,26.29,0,0,1-14.42-1.56l.06,0,16.31-5a7.56,7.56,0,0,1,2,3.26s0,0,0,0C205.62,385.4,204.91,386.82,201.35,387.36Z"
						  style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 196.149px 384.273px;"
						  class="animable"></path>
				</g>
				<path d="M204.13,393.48c-8.51,3.61-17.2-.7-17.2-.7v-7l.06,0,16.31-5a7.56,7.56,0,0,1,2,3.26s0,0,0,0C207.41,391.72,204.13,393.48,204.13,393.48Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 196.469px 387.851px;"
					  id="elhakaict0rff" class="animable"></path>
				<path d="M92.92,354.7l37.4,22.14,111-26.88-25.68-19.35h0a8.3,8.3,0,0,0-2.28-4.33,14.81,14.81,0,0,0-5.46-3.68l-1-.17s-10.29-23.51-31.06-30-92.7,49.17-82.91,62.28"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.955681px; transform-origin: 166.67px 334.373px;"
					  id="elrpienygm3d8" class="animable"></path>
				<path d="M124.41,310.56c.29.82-.52,1.59-1.2,2.13s-1.41,1.45-.95,2.2a2.87,2.87,0,0,0-3.15.16,2.82,2.82,0,0,0-1,3c.52,1.6,2.34,2.34,4,2.72a53,53,0,0,0,11,1c.53,0,1.22-.1,1.32-.62a.88.88,0,0,0-.63-.88c-.36-.15-.77-.17-1.14-.31a1.8,1.8,0,0,1-.69-2.67,1.5,1.5,0,0,1,.87-1.9,4.29,4.29,0,0,1,2.31-.15,1.08,1.08,0,0,0-.27-1.33,1.58,1.58,0,0,0,.37-1.79C134.07,309.4,126.71,310.21,124.41,310.56Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 126.694px 315.973px;" id="el61ek3oytbtt"
					  class="animable"></path>
				<path d="M133,313.86a42,42,0,0,1-7.2-1.29"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 129.4px 313.215px;"
					  id="eljwm8ksbgzs" class="animable"></path>
				<path d="M130.79,317a14.15,14.15,0,0,1-5.47-.8"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 128.055px 316.609px;"
					  id="ela5o1ozofnf" class="animable"></path>
				<path d="M130.76,319.83a37.81,37.81,0,0,1-10.82-1.66"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 125.35px 319px;"
					  id="elp4kx57vlco" class="animable"></path>
				<path d="M92.73,343.94c.29.82-.51,1.59-1.2,2.13s-1.4,1.45-.94,2.2a2.88,2.88,0,0,0-3.16.16,2.79,2.79,0,0,0-.94,3c.51,1.6,2.33,2.34,4,2.72a53.77,53.77,0,0,0,11.05,1c.53,0,1.23-.1,1.33-.62a.9.9,0,0,0-.64-.89c-.36-.14-.77-.16-1.13-.3a1.8,1.8,0,0,1-.7-2.67,1.51,1.51,0,0,1,.88-1.9,4.37,4.37,0,0,1,2.3-.16,1,1,0,0,0-.26-1.32,1.59,1.59,0,0,0,.36-1.8C102.39,342.77,95,343.58,92.73,343.94Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 95.0932px 349.349px;" id="el238qz4jrkd2"
					  class="animable"></path>
				<path d="M101.31,347.23a40.84,40.84,0,0,1-7.2-1.28"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 97.71px 346.59px;"
					  id="elyxdueorkor" class="animable"></path>
				<path d="M99.11,350.35a14.07,14.07,0,0,1-5.46-.79"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 96.38px 349.965px;"
					  id="eltjasomr0yik" class="animable"></path>
				<path d="M99.08,353.21a37.4,37.4,0,0,1-10.81-1.67"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 93.675px 352.375px;"
					  id="elfx9dmevg47s" class="animable"></path>
				<path d="M138.21,301.47c.21.85-.68,1.53-1.42,2s-1.55,1.29-1.17,2.08a2.88,2.88,0,0,0-3.16-.17,2.81,2.81,0,0,0-1.26,2.89c.35,1.65,2.08,2.58,3.67,3.13a54.17,54.17,0,0,0,10.88,2.15c.53.06,1.23,0,1.38-.48a.88.88,0,0,0-.53-.95c-.35-.18-.75-.24-1.1-.42a1.8,1.8,0,0,1-.4-2.73,1.51,1.51,0,0,1,1.07-1.8,4.33,4.33,0,0,1,2.31.09,1,1,0,0,0-.13-1.34,1.57,1.57,0,0,0,.55-1.75C147.94,301.34,140.54,301.37,138.21,301.47Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 140.066px 307.504px;" id="elzetkifmt4r"
					  class="animable"></path>
				<path d="M146.39,305.66a39.74,39.74,0,0,1-7-2"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 142.89px 304.66px;"
					  id="el7o10mipopl4" class="animable"></path>
				<path d="M143.88,308.53a13.84,13.84,0,0,1-5.36-1.37"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 141.2px 307.845px;"
					  id="eltg3xew1rjjq" class="animable"></path>
				<path d="M143.54,311.37A37.22,37.22,0,0,1,133,308.56"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 138.27px 309.965px;"
					  id="elh40kkkrcfyv" class="animable"></path>
				<path d="M197.4,304.47c-.09.82.81,1.35,1.56,1.71s1.6,1,1.33,1.81a2.71,2.71,0,0,1,2.94-.52,2.64,2.64,0,0,1,1.51,2.56c-.14,1.58-1.65,2.65-3.08,3.34a50.21,50.21,0,0,1-9.93,3.26c-.48.12-1.14.17-1.34-.29a.83.83,0,0,1,.39-.95c.3-.21.67-.31,1-.52a1.69,1.69,0,0,0,.07-2.6,1.41,1.41,0,0,0-1.2-1.56,4.22,4.22,0,0,0-2.15.35,1,1,0,0,1,0-1.27,1.51,1.51,0,0,1-.72-1.57C188.29,305.46,195.22,304.64,197.4,304.47Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 196.251px 310.591px;" id="elq4l4amywrf9"
					  class="animable"></path>
				<path d="M190.24,309.33a37.62,37.62,0,0,0,6.33-2.72"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 193.405px 307.97px;"
					  id="elld84o1acyr" class="animable"></path>
				<path d="M192.91,311.72a13.05,13.05,0,0,0,4.85-1.9"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 195.335px 310.77px;"
					  id="el5n7nn9wsrfh" class="animable"></path>
				<path d="M193.55,314.34a35.44,35.44,0,0,0,9.57-3.83"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 198.335px 312.425px;"
					  id="elxsjiil39kc" class="animable"></path>
				<path d="M219.41,330.83c0,.49.5.82,1,1s1,.63.81,1.11a1.64,1.64,0,0,1,1.79-.32,1.62,1.62,0,0,1,.92,1.56c-.09,1-1,1.61-1.87,2a30.38,30.38,0,0,1-6,2c-.3.08-.7.11-.82-.17a.51.51,0,0,1,.24-.58c.18-.13.41-.19.59-.32a1,1,0,0,0,.05-1.58.88.88,0,0,0-.74-.95,2.51,2.51,0,0,0-1.31.22.62.62,0,0,1,0-.78.92.92,0,0,1-.44-.95C213.87,331.43,218.08,330.93,219.41,330.83Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 218.775px 334.533px;" id="elgf9q0eey4la"
					  class="animable"></path>
				<path d="M215.05,333.78a23.84,23.84,0,0,0,3.86-1.65"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 216.98px 332.955px;"
					  id="elu2m75kxqm" class="animable"></path>
				<path d="M216.68,335.24a8,8,0,0,0,2.95-1.16"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 218.155px 334.66px;"
					  id="elxjevdec51ve" class="animable"></path>
				<path d="M217.07,336.83a21.75,21.75,0,0,0,5.82-2.33"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 219.98px 335.665px;"
					  id="elb757za0rzjq" class="animable"></path>
				<path d="M132.67,319.5a3.53,3.53,0,0,0,1.84,2.42,6.55,6.55,0,0,0,3,.7,7,7,0,0,0,4.72-1.36"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 137.45px 321.071px;"
					  id="el8jeogwuqvb9" class="animable"></path>
				<path d="M146.52,322.47a4.69,4.69,0,0,0,6,.76"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 149.52px 323.227px;"
					  id="elhja6z5vg8" class="animable"></path>
				<path d="M141.62,315.8a4.34,4.34,0,0,0-1.26.84,1.7,1.7,0,0,0-.49,1.39c.15,1,1.33,1.41,2.32,1.57a24.34,24.34,0,0,0,8-.19,3.32,3.32,0,0,0,1.19-.38,1.23,1.23,0,0,0,.65-1,.78.78,0,0,0-.8-.78"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 145.945px 317.821px;"
					  id="elfsit29rei9c" class="animable"></path>
				<path d="M153.56,322.33a12.78,12.78,0,0,0,13.75-.19.51.51,0,0,0,.26-.32"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 160.565px 323.032px;"
					  id="eltd02mdzkzmk" class="animable"></path>
				<path d="M177.9,319.81A18.09,18.09,0,0,0,191,317.87"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 184.45px 319.074px;"
					  id="el0l0hn7ocode" class="animable"></path>
				<path d="M197.82,314.91a9.68,9.68,0,0,0,5.25-2.18"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 200.445px 313.82px;"
					  id="el6b01t3knu1k" class="animable"></path>
				<path d="M157,296.21a1.73,1.73,0,1,1-1.72-1.72A1.72,1.72,0,0,1,157,296.21Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 155.27px 296.22px;"
					  id="elhnip81t509a" class="animable"></path>
				<path d="M158.74,297.93a1.73,1.73,0,1,1-1.73-1.72A1.73,1.73,0,0,1,158.74,297.93Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 157.01px 297.94px;"
					  id="elcnp84axl679" class="animable"></path>
				<path d="M160.92,299.1a1.73,1.73,0,1,1-1.73-1.72A1.72,1.72,0,0,1,160.92,299.1Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 159.19px 299.11px;"
					  id="elybvdvgzrfwh" class="animable"></path>
				<path d="M162.64,299.66a1.73,1.73,0,1,1-1.72-1.73A1.72,1.72,0,0,1,162.64,299.66Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 160.91px 299.66px;"
					  id="elq2qeqeiwcon" class="animable"></path>
				<path d="M165,300.19a1.72,1.72,0,1,1-1.72-1.72A1.73,1.73,0,0,1,165,300.19Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 163.28px 300.19px;"
					  id="el9er7g9muaha" class="animable"></path>
				<path d="M167.57,300.19a1.73,1.73,0,1,1-1.73-1.72A1.74,1.74,0,0,1,167.57,300.19Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 165.84px 300.2px;"
					  id="elulxey2l499q" class="animable"></path>
				<circle cx="168.33" cy="300.19" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 168.33px 300.19px;"
						id="elyxg2blq15fd" class="animable"></circle>
				<circle cx="170.69" cy="300.19" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 170.69px 300.19px;"
						id="el5qm2ti7vqp4" class="animable"></circle>
				<path d="M185.3,296.75a1.72,1.72,0,1,1-1.72-1.73A1.72,1.72,0,0,1,185.3,296.75Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 183.58px 296.74px;"
					  id="elqvcehcvr3is" class="animable"></path>
				<path d="M183.58,297.66a1.73,1.73,0,1,1-1.72-1.72A1.72,1.72,0,0,1,183.58,297.66Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 181.85px 297.67px;"
					  id="elad6rh14g3p" class="animable"></path>
				<circle cx="179.85" cy="298.79" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 179.85px 298.79px;"
						id="elo81gr9xubzj" class="animable"></circle>
				<circle cx="177.63" cy="299.66" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 177.63px 299.66px;"
						id="elgpm8x0ho6i" class="animable"></circle>
				<circle cx="175.57" cy="300.19" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 175.57px 300.19px;"
						id="elrfijwdc1ei" class="animable"></circle>
				<circle cx="173.08" cy="300.19" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 173.08px 300.19px;"
						id="elrkska75p9mg" class="animable"></circle>
				<circle cx="173.08" cy="300.19" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 173.08px 300.19px;"
						id="elh7l7khi9jwo" class="animable"></circle>
				<line x1="155.75" y1="306.85" x2="155.33" y2="305.89"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 155.54px 306.37px;"
					  id="el5rgxkzfryqg" class="animable"></line>
				<path d="M152.52,306.46c0,.14-.19.94-.33.94"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 152.355px 306.93px;"
					  id="elkqdequ121d" class="animable"></path>
				<line x1="152.35" y1="305.49" x2="151.14" y2="306.3"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 151.745px 305.895px;"
					  id="elbdcijeewsq7" class="animable"></line>
				<path d="M158.34,303.76l-2-1.2c-.25-.16-2.79,1.18-2.79,1.18s-2.85,2.57-2.42,2.56l.72,1.74s3.43-.59,4-.64,2.72-2.56,2.72-2.56"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 154.828px 305.293px;"
					  id="el5avi6qr83mp" class="animable"></path>
				<path d="M157,304.25a19.32,19.32,0,0,0-1.62,1.4l-1.9.51"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 155.24px 305.205px;"
					  id="elq1z98as2wvf" class="animable"></path>
				<line x1="167.62" y1="308.88" x2="167.3" y2="308.11"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 167.46px 308.495px;"
					  id="el1ygjb0acpo6" class="animable"></line>
				<path d="M165.11,308.57c0,.11-.15.74-.26.75"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 164.98px 308.945px;"
					  id="eljsmv4fhz8vc" class="animable"></path>
				<line x1="164.98" y1="307.8" x2="164.04" y2="308.44"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 164.51px 308.12px;"
					  id="el423ma6dbrni" class="animable"></line>
				<path d="M169.63,306.42l-1.55-1a9.51,9.51,0,0,0-2.16.94s-2.21,2.05-1.88,2l.56,1.39s2.66-.47,3.09-.51,2.12-2,2.12-2"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 166.908px 307.585px;"
					  id="elmajqiokd0p" class="animable"></path>
				<path d="M168.55,306.81a14.74,14.74,0,0,0-1.25,1.11l-1.48.41"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 167.185px 307.57px;"
					  id="elbr9dgegzez" class="animable"></path>
				<line x1="172.24" y1="315.37" x2="172.93" y2="315.99"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 172.585px 315.68px;"
					  id="elisf905i9b9" class="animable"></line>
				<path d="M171.66,318.12c-.11,0-.83-.12-.87,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 171.225px 318.093px;"
					  id="ely87cz6s517c" class="animable"></path>
				<line x1="172.43" y1="318.53" x2="171.41" y2="319.29"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 171.92px 318.91px;"
					  id="elrdsm3s8of7" class="animable"></line>
				<path d="M175.54,314.15s.39,1.72.45,2-1.76,1.93-1.76,1.93-2.94,1.59-2.82,1.24l-1.25-1.09s1.45-2.62,1.65-3.06,2.9-1.48,2.9-1.48"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 173.076px 316.53px;"
					  id="el7v4pv5rlsuq" class="animable"></path>
				<path d="M174.75,315.14a15.94,15.94,0,0,1-1.62.92l-1,1.41"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 173.44px 316.305px;"
					  id="elhpt0ltamu4v" class="animable"></path>
				<line x1="180.98" y1="310.89" x2="182.19" y2="309.71"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 181.585px 310.3px;"
					  id="elq24a2ceywh" class="animable"></line>
				<path d="M186,312.28c-.09.2-.29,1.5-.08,1.59"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 185.914px 313.075px;"
					  id="el7r1d2g1ibct" class="animable"></path>
				<line x1="186.85" y1="310.94" x2="188.16" y2="312.88"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 187.505px 311.91px;"
					  id="elesi6k3z5yx" class="animable"></line>
				<path d="M179,304.73s3.21-.48,3.68-.57,3.43,3.44,3.43,3.44,2.67,5.55,2,5.28L186,315s-4.73-2.95-5.53-3.37-2.48-5.46-2.48-5.46"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 183.104px 309.579px;"
					  id="elxyy4ye7x8ig" class="animable"></path>
				<path d="M180.77,306.29a30.84,30.84,0,0,1,1.56,3.07l2.52,1.92"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 182.81px 308.785px;"
					  id="el4ksk0ysqzpw" class="animable"></path>
				<path d="M167.36,292.88s2.53,2.94,3.18,3,4.67.75,4.67.75l.85-2c.5,0-2.84-3-2.84-3s-3-1.56-3.26-1.37-2.33,1.4-2.33,1.4l-.64.57Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 171.551px 293.437px;" id="elmw49j03huca"
					  class="animable"></path>
				<line x1="170.66" y1="295.24" x2="171.14" y2="294.11"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 170.9px 294.675px;"
					  id="eliiyhigr8gz" class="animable"></line>
				<path d="M174.44,294.78c0,.16.22,1.1.39,1.1"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 174.635px 295.33px;"
					  id="el7cxa9ijhocg" class="animable"></path>
				<line x1="174.64" y1="293.65" x2="176.06" y2="294.6"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 175.35px 294.125px;"
					  id="elwtqk1x79keh" class="animable"></line>
				<path d="M167.36,292.88s2.53,2.94,3.18,3,4.67.75,4.67.75l.85-2c.5,0-2.84-3-2.84-3s-3-1.56-3.26-1.37-2.33,1.4-2.33,1.4l-.64.57Z"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 171.551px 293.437px;"
					  id="eligxyfufrk8n" class="animable"></path>
				<path d="M169.25,292.19a22.78,22.78,0,0,1,1.89,1.64l2.24.6"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 171.315px 293.31px;"
					  id="elzyf2061vkra" class="animable"></path>
				<path d="M116.06,327.74s-3.48,3.36-4.3,3.36-5.88.4-5.88.4l-.82-2.62c-.63-.05,3.86-3.41,3.86-3.41s3.86-1.61,4.21-1.34,2.73,2,2.73,2l.73.78Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 110.795px 327.8px;" id="ele9p6ibr40fe"
					  class="animable"></path>
				<line x1="111.69" y1="330.29" x2="111.21" y2="328.83"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 111.45px 329.56px;"
					  id="elpk0cfvto9x8" class="animable"></line>
				<path d="M107,329.29c0,.2-.4,1.33-.61,1.32"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 106.695px 329.95px;"
					  id="elcfyewa6kebj" class="animable"></path>
				<line x1="106.93" y1="327.86" x2="105.06" y2="328.88"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 105.995px 328.37px;"
					  id="elzzswxcrph0h" class="animable"></line>
				<path d="M113.13,324.13c.34.27,2.73,2,2.73,2l.73.78-.53.82s-3.48,3.36-4.3,3.36-5.88.4-5.88.4l-.16-.5"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 111.155px 327.81px;"
					  id="eloqm3n4be9n" class="animable"></path>
				<path d="M113.79,326.66a27.25,27.25,0,0,0-2.55,1.82l-2.84.49"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 111.095px 327.815px;"
					  id="el3ck02q1jnjm" class="animable"></path>
				<path d="M227.42,344.72s3.47,3.36,4.3,3.36,5.87.4,5.87.4l.83-2.62c.62-.05-3.86-3.41-3.86-3.41s-3.87-1.61-4.21-1.34-2.74,2-2.74,2l-.73.78Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 232.679px 344.78px;" id="elnme42etu5ur"
					  class="animable"></path>
				<line x1="231.78" y1="347.27" x2="232.26" y2="345.81"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 232.02px 346.54px;"
					  id="eltxkoeeb6pbp" class="animable"></line>
				<path d="M236.43,346.27c0,.2.4,1.34.61,1.32"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 236.735px 346.93px;"
					  id="el0in0j7gdjcau" class="animable"></path>
				<line x1="236.55" y1="344.84" x2="238.42" y2="345.86"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 237.485px 345.35px;"
					  id="elfeutzu6d8mj" class="animable"></line>
				<path d="M230.35,341.11c-.34.27-2.74,2-2.74,2l-.73.78.54.82s3.47,3.36,4.3,3.36,5.87.4,5.87.4l.16-.49"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 232.315px 344.79px;"
					  id="eluhpg3bgcxdi" class="animable"></path>
				<path d="M229.69,343.64a28.32,28.32,0,0,1,2.54,1.83l2.84.48"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 232.38px 344.795px;"
					  id="elv3o04ott9bk" class="animable"></path>
				<circle cx="157.47" cy="315.37" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 157.47px 315.37px;"
						id="ely3l8e9jnjz" class="animable"></circle>
				<circle cx="158.38" cy="317.47" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 158.38px 317.47px;"
						id="el7n3148tjrk" class="animable"></circle>
				<circle cx="159.8" cy="318.06" r="1.72"
						style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 159.8px 318.06px;"
						id="elhlehenac5y8" class="animable"></circle>
				<path d="M163.77,319.29a1.73,1.73,0,1,1-1.72-1.73A1.72,1.72,0,0,1,163.77,319.29Z"
					  style="fill: rgb(255, 255, 255); stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 162.04px 319.29px;"
					  id="elal16kea5wsd" class="animable"></path>
				<circle cx="174.88" cy="325.13" r="1.72"
						style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 174.88px 325.13px;"
						id="el8tzkma7lxi5" class="animable"></circle>
				<circle cx="196.69" cy="319.78" r="1.72"
						style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 196.69px 319.78px;"
						id="elrvidlltnpq" class="animable"></circle>
				<circle cx="137.19" cy="343.72" r="2.4"
						style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 137.19px 343.72px;"
						id="elploxxcev1n" class="animable"></circle>
				<circle cx="143.31" cy="315.2" r="1.72"
						style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 143.31px 315.2px;"
						id="elrzkoqrosuz" class="animable"></circle>
				<path d="M215,336.26a7.21,7.21,0,0,1,3.9,2.8,6.8,6.8,0,0,0,1.05,1.38,14.21,14.21,0,0,0,2.45,1.18,4.23,4.23,0,0,1,1.28,5.6,6.37,6.37,0,0,1-5.34,3"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 219.621px 343.24px;"
					  id="eliff7byshoj" class="animable"></path>
				<path d="M183.33,348.54a4.73,4.73,0,0,0,7.12-.66,13.6,13.6,0,0,0,11.42.86,3.35,3.35,0,0,0,2.15-3h0a7.33,7.33,0,0,0,9-1.31,3.61,3.61,0,0,0,3.28-3.86"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 199.82px 345.213px;"
					  id="elew8rof39smt" class="animable"></path>
				<path d="M134.5,331.92c-.47,1.53.68,3.16,2.13,3.81a10,10,0,0,0,4.71.45,2.94,2.94,0,0,0,3.59,1.38"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 139.662px 334.827px;"
					  id="el17uhvapzfofj" class="animable"></path>
				<path d="M107,335.31c-1.54,0-2.63,1.82-2.2,3.29a4.06,4.06,0,0,0,3.48,2.6,9.82,9.82,0,0,0,4.48-.83,20.73,20.73,0,0,1,4.36-1.41c.4,1.63,2.24,2.58,3.92,2.55a11.27,11.27,0,0,0,4.75-1.57,5,5,0,0,0,6.55,1.61"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 118.523px 338.735px;"
					  id="elvvvtzxkkvct" class="animable"></path>
				<path d="M103.38,344.6a4,4,0,0,0-.68,6.19c1.58,1.56,4.18,1.51,6.3.83a14,14,0,0,0,5.48-3.3,6.92,6.92,0,0,0,2.18-3.76,3.52,3.52,0,0,0-1.77-3.71"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 109.126px 346.461px;"
					  id="el455u87dd00y" class="animable"></path>
				<path d="M114.13,349.44a15.45,15.45,0,0,0,8.11-1.4,11.11,11.11,0,0,0,3.35-2.32,3.23,3.23,0,0,0,.94-3.55"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 120.426px 345.843px;"
					  id="el6wa4a488v7c" class="animable"></path>
				<path d="M112.85,351.17a.93.93,0,0,0,0,1.09,3.15,3.15,0,0,0,1.38,1.22,6,6,0,0,0,5.6,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 116.252px 352.672px;"
					  id="el2to2ut3kpvf" class="animable"></path>
				<path d="M104.3,352.78a1.84,1.84,0,0,0-.16,2.65,6.17,6.17,0,0,0,3,1.77c3.72.67,6.6-.17,10.65-2.26"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 110.721px 355.103px;"
					  id="el77na06zyfg7" class="animable"></path>
				<path d="M119.75,349.06l.28.24a1.73,1.73,0,0,0,0,2,3.35,3.35,0,0,0,1.8,1.15,13.15,13.15,0,0,0,5.27.14l-.62.06"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 123.406px 350.926px;"
					  id="el0p5n77igu14" class="animable"></path>
				<path d="M118.26,358.16c-.05.71.7,1.19,1.37,1.39a6.68,6.68,0,0,0,3.63.05"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 120.759px 358.993px;"
					  id="elfz5dyts8jtw" class="animable"></path>
				<path d="M119.25,356c.47,1.46,1.94,1.77,3.26,2a10.66,10.66,0,0,0,4.62-.4"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 123.19px 357.047px;"
					  id="elfgrv9s8m1jj" class="animable"></path>
				<path d="M129.31,357.37c1.32.7,3.48,1,6.93.54A7.79,7.79,0,0,0,141,355.5a4.4,4.4,0,0,0,1.2-3.49,2.8,2.8,0,0,0-2.54-2.42"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 135.766px 353.86px;"
					  id="eledbuw29kw4i" class="animable"></path>
				<path d="M127.54,355.32a3.17,3.17,0,0,0,.71,1.25"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 127.895px 355.945px;"
					  id="eljlleos0g8co" class="animable"></path>
				<path d="M131.92,349.83a9.39,9.39,0,0,0-4,2.57,3.35,3.35,0,0,0-.5,2.17"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 129.659px 352.2px;"
					  id="elk0txxrag6ws" class="animable"></path>
				<path d="M141.29,349.26a3.11,3.11,0,0,1,1.86,3.88"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 142.297px 351.2px;"
					  id="elwdha6xxbiv8" class="animable"></path>
				<path d="M142.68,353.48a12.87,12.87,0,0,1,6.28,1.95,3.78,3.78,0,0,1,1.89,2.22,3,3,0,0,1-1.53,3,7.77,7.77,0,0,1-3.44.85,16.28,16.28,0,0,1-5.12-.24,7.16,7.16,0,0,1-4.25-2.71"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 143.692px 357.54px;"
					  id="el2nwt3bf74kd" class="animable"></path>
				<path d="M138.76,358.66c4.55,2.64,9,1.36,10.09.61"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 143.805px 359.454px;"
					  id="elun0t5kbkhg" class="animable"></path>
				<path d="M135.05,358.94a2.77,2.77,0,0,0-1,4.49,4.54,4.54,0,0,0,2.24,1.12,12.31,12.31,0,0,0,5.91,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 137.743px 361.925px;"
					  id="eln1153n09dsn" class="animable"></path>
				<path d="M142,363.21a4.46,4.46,0,0,0,2.27,1.66,4.55,4.55,0,0,0,4-.29,3,3,0,0,0,1.19-3.57"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 145.824px 363.106px;"
					  id="elu9i4w6ejf29" class="animable"></path>
				<path d="M143.87,363.35a3.34,3.34,0,0,0,3.83.36"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 145.785px 363.751px;"
					  id="el0mpn34upqxi" class="animable"></path>
				<path d="M134.56,361c-.4,1.37,1.11,2.39,3.21,2.75"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 136.133px 362.375px;"
					  id="elr2meg59c47" class="animable"></path>
				<path d="M121.81,350.56c.72,1.09,3.8.79,6.11.47"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 124.865px 350.943px;"
					  id="elzw0at8cvu0q" class="animable"></path>
				<path d="M119.53,354.93a3,3,0,0,1,3-2.15l-.26,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 121.03px 353.854px;"
					  id="el75n7f097ofr" class="animable"></path>
				<path d="M120.6,355.08a1.1,1.1,0,0,0,.57,1.08,2.85,2.85,0,0,0,1.25.31,19.27,19.27,0,0,0,4.36-.11"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 123.687px 355.812px;"
					  id="elzmrl3dq9owc" class="animable"></path>
				<path d="M103.19,349.53a10.05,10.05,0,0,0,10.86-4.18"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 108.62px 347.596px;"
					  id="ellflry1c5jg" class="animable"></path>
				<path d="M105.29,354.38c.32.73,1.73,1.09,2.52,1.22a8.29,8.29,0,0,0,5.33-.44"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 109.215px 355.115px;"
					  id="el5benfok1ha8" class="animable"></path>
				<path d="M114.22,351.65c.57,1,2.68,1.51,4.08,1.05"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 116.26px 352.26px;"
					  id="elcul1cppm4h" class="animable"></path>
				<path d="M116.38,347.46c3.32.27,8-2.36,8.54-4.24"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 120.65px 345.35px;"
					  id="elh3jorn4yz9a" class="animable"></path>
				<path d="M147.57,353.79a4.33,4.33,0,0,1,.16-7.48,11.58,11.58,0,0,1,6.37-1.1,7.45,7.45,0,0,1,5.42,2.81,5.08,5.08,0,0,1,.16,5.88"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 153.02px 349.519px;"
					  id="elfiww8n2pyft" class="animable"></path>
				<path d="M146.54,349.1c0,3.62,3.65,4.48,6.13,4.6s5.31-.17,6.83-3.23"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 153.02px 351.411px;"
					  id="elqiuxvnctxvh" class="animable"></path>
				<path d="M150.25,354.88a11.39,11.39,0,0,0,7.85,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 154.175px 355.229px;"
					  id="elyyfwbzk2oaf" class="animable"></path>
				<path d="M158,355.71a1.94,1.94,0,0,1,1.1,2.67"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 158.646px 357.045px;"
					  id="eloxae6mfspmn" class="animable"></path>
				<path d="M150.93,360c1.56.74,2.62.74,5.13.45"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 153.495px 360.315px;"
					  id="el18fs0a4b7zmh" class="animable"></path>
				<path d="M152,359a5.63,5.63,0,0,0,5.6-.72"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 154.8px 358.859px;"
					  id="elc4fcbnqr9a" class="animable"></path>
				<path d="M161.23,357.51a6,6,0,0,0,4.2.61"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 163.33px 357.886px;"
					  id="elrxv80us4k58" class="animable"></path>
				<path d="M159.45,354.65a1.24,1.24,0,0,1,1.28-.76"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 160.09px 354.266px;"
					  id="eldkk4q7z2qaf" class="animable"></path>
				<path d="M160.77,352.52a5.34,5.34,0,0,0,5.19,3.36"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 163.365px 354.202px;"
					  id="elvo96zyz1rmb" class="animable"></path>
				<path d="M167.41,356a10,10,0,0,0,5.47.2,5.57,5.57,0,0,0,3.88-3.62"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 172.085px 354.534px;"
					  id="eldj9dm9u46ss" class="animable"></path>
				<path d="M161.65,351.21a8.19,8.19,0,0,0,9.92.45,5.64,5.64,0,0,0,2.15-2.83,3.54,3.54,0,0,0-.66-3.39,5.1,5.1,0,0,0-3.83-1.25,17.29,17.29,0,0,0-4.63.63,6.49,6.49,0,0,0-2.93,1.52,2.92,2.92,0,0,0-.76,3.07"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 167.325px 348.644px;"
					  id="eln94zwbshtaq" class="animable"></path>
				<path d="M128.17,353.34c.19,1.37,1.59,2.23,2.93,2.61a10.24,10.24,0,0,0,9.62-2.36"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 134.445px 354.823px;"
					  id="eledxjlplqyl4" class="animable"></path>
				<path d="M167.73,355.1c2.57.7,6.66.47,8.37-3"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 171.915px 353.759px;"
					  id="el85q1qlbjjgt" class="animable"></path>
				<path d="M162.05,349.79a8.24,8.24,0,0,0,10.53-1.11"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 167.315px 349.938px;"
					  id="els4i1pu977ec" class="animable"></path>
				<path d="M174.58,349a2.33,2.33,0,0,1,2.43,2.25,2.45,2.45,0,0,1,1,4.69l.29,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 177.024px 352.469px;"
					  id="el5ejeq97zbce" class="animable"></path>
				<path d="M215.68,330.77a3.56,3.56,0,0,1-1,2.85c-1.39,1.37-3.67,1.33-5.53.74a12.27,12.27,0,0,1-4.8-2.9,6.09,6.09,0,0,1-1.91-3.29,3.08,3.08,0,0,1,1.55-3.26"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 209.043px 329.83px;"
					  id="elus6zbi5n7vj" class="animable"></path>
				<path d="M204.64,332.44a13.36,13.36,0,0,1-7.11-1.23,9.46,9.46,0,0,1-2.94-2,2.84,2.84,0,0,1-.83-3.11"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 199.118px 329.305px;"
					  id="el11isn6i8nu" class="animable"></path>
				<path d="M205.76,334a.83.83,0,0,1,0,1,2.74,2.74,0,0,1-1.2,1.06,5.29,5.29,0,0,1-4.91,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 202.789px 335.332px;"
					  id="el5ge1gqv2wxj" class="animable"></path>
				<path d="M213.25,335.37a1.6,1.6,0,0,1,.15,2.32,5.36,5.36,0,0,1-2.64,1.55c-3.26.59-5.8-.14-9.34-2"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 207.624px 337.405px;"
					  id="elhxywazsb2y" class="animable"></path>
				<path d="M199.71,332.11l-.25.21a1.49,1.49,0,0,1,0,1.77,2.93,2.93,0,0,1-1.57,1,11.62,11.62,0,0,1-4.63.12l.55.06"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 196.506px 333.748px;"
					  id="elayavs1mjqp4" class="animable"></path>
				<path d="M202.64,339.55c0,.62-.62,1-1.21,1.22a5.79,5.79,0,0,1-3.17,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 200.45px 340.271px;"
					  id="elzifioi7uybb" class="animable"></path>
				<path d="M200.15,338.16c-.41,1.28-1.7,1.55-2.87,1.72a9.39,9.39,0,0,1-4.05-.35"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 196.69px 339.061px;"
					  id="elu6q8zf7joch" class="animable"></path>
				<path d="M191.33,339.4c-1.16.61-3.06.9-6.08.47a6.9,6.9,0,0,1-4.15-2.11,3.88,3.88,0,0,1-1-3.07,2.46,2.46,0,0,1,2.23-2.12"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 185.702px 336.322px;"
					  id="el6h03sg5efjw" class="animable"></path>
				<path d="M189,332.78a8.34,8.34,0,0,1,3.52,2.25,3,3,0,0,1,.44,1.91"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 190.988px 334.86px;"
					  id="elich2xhn8r1b" class="animable"></path>
				<path d="M180.83,332.28a2.72,2.72,0,0,0-1.63,3.41"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 179.945px 333.985px;"
					  id="ellsv4i2tyn7o" class="animable"></path>
				<path d="M179.61,336a11.32,11.32,0,0,0-5.51,1.71,3.34,3.34,0,0,0-1.66,1.95,2.59,2.59,0,0,0,1.35,2.61,6.74,6.74,0,0,0,3,.75,14.08,14.08,0,0,0,4.49-.21,6.31,6.31,0,0,0,3.72-2.38"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 178.71px 339.555px;"
					  id="elhwhr5jc45hm" class="animable"></path>
				<path d="M183,340.53c-4,2.31-7.89,1.19-8.84.53"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 178.58px 341.225px;"
					  id="elxpjk2vnud7m" class="animable"></path>
				<path d="M186.3,340.77a2.5,2.5,0,0,1,1.47,1.74,2.46,2.46,0,0,1-.62,2.2,4.09,4.09,0,0,1-2,1,10.81,10.81,0,0,1-5.18,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 183.897px 343.397px;"
					  id="el3sj8a7noly8" class="animable"></path>
				<path d="M180.23,344.52a3.86,3.86,0,0,1-2,1.45,4,4,0,0,1-3.47-.25,2.6,2.6,0,0,1-1.05-3.13"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 176.885px 344.421px;"
					  id="el4dmpe9rnibr" class="animable"></path>
				<path d="M198.12,333.48a7.51,7.51,0,0,1-3.81.47"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 196.215px 333.75px;"
					  id="ele1yjgdu4ek" class="animable"></path>
				<path d="M199.9,337.26a2.65,2.65,0,0,0-2.63-1.89l.23,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 198.585px 336.314px;"
					  id="el9pvho0fv9r6" class="animable"></path>
				<path d="M198.37,337.41a.76.76,0,0,1-.4.75,2,2,0,0,1-.87.22,13.52,13.52,0,0,1-3-.08"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 196.237px 337.919px;"
					  id="eltenj9aau4t" class="animable"></path>
				<path d="M214.22,332.52a8.81,8.81,0,0,1-9.52-3.66"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 209.46px 330.827px;"
					  id="elkpkgf9epfk" class="animable"></path>
				<path d="M212.39,336.78c-.29.63-1.53,1-2.21,1.06a7.33,7.33,0,0,1-4.68-.38"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 208.945px 337.419px;"
					  id="elwqie7298dkf" class="animable"></path>
				<path d="M204.56,334.38c-.51.9-2.35,1.33-3.58.92"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 202.77px 334.918px;"
					  id="eltbz7oq93rwk" class="animable"></path>
				<path d="M202.66,330.7c-2.91.24-7-2.06-7.49-3.71"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 198.915px 328.854px;"
					  id="eln6x3y4yah6s" class="animable"></path>
				<path d="M175.32,336.26a3.81,3.81,0,0,0-.14-6.57,10.18,10.18,0,0,0-5.59-.95,6.5,6.5,0,0,0-4.75,2.46,4.47,4.47,0,0,0-.14,5.16"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 170.54px 332.517px;"
					  id="elgs00oqnjbzh" class="animable"></path>
				<path d="M176.22,332.15c0,3.17-3.2,3.93-5.37,4s-4.66-.15-6-2.84"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 170.535px 334.155px;"
					  id="elsode2e2cede" class="animable"></path>
				<path d="M173,337.21a10,10,0,0,1-6.88,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 169.56px 337.515px;"
					  id="elbc1vn99bqar" class="animable"></path>
				<path d="M166.14,337.94a1.69,1.69,0,0,0-1,2.34"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 165.556px 339.11px;"
					  id="elkxaobs3u3cc" class="animable"></path>
				<path d="M172.38,341.71c-1.37.65-2.31.65-4.51.39"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 170.125px 341.986px;"
					  id="elw4p4lq2axro" class="animable"></path>
				<path d="M170.7,340.79a5,5,0,0,1-4.86-1"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 168.27px 340.414px;"
					  id="elkhkcw9j72zc" class="animable"></path>
				<path d="M164.18,338.39a8,8,0,0,1-4.26.94"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 162.05px 338.867px;"
					  id="elhecd6rznjuo" class="animable"></path>
				<path d="M163.74,335.15a4.69,4.69,0,0,1-4.55,2.94"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 161.465px 336.622px;"
					  id="el3hqnr8v8gd5" class="animable"></path>
				<path d="M158.26,337.14a8.52,8.52,0,0,1-4.53.91,4.72,4.72,0,0,1-3.72-2.49"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 154.135px 336.817px;"
					  id="ela633jvn6fsb" class="animable"></path>
				<path d="M122.59,321.25a2.54,2.54,0,0,0,1.82,2.17,7.32,7.32,0,0,0,3,.2,2.92,2.92,0,0,0-1.34,3.68,4.74,4.74,0,0,0,3.24,2.62,13.2,13.2,0,0,0,4.3.22"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 128.1px 325.737px;"
					  id="elegi6h9aeezc" class="animable"></path>
				<path d="M163,334a7.16,7.16,0,0,1-8.69.4,4.94,4.94,0,0,1-1.89-2.48,3.09,3.09,0,0,1,.58-3,4.43,4.43,0,0,1,3.36-1.1,14.93,14.93,0,0,1,4,.55,5.71,5.71,0,0,1,2.58,1.34,2.54,2.54,0,0,1,.66,2.68"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 157.998px 331.74px;"
					  id="elik9yklkmg9" class="animable"></path>
				<path d="M191.37,336.14c-.13,1-1.14,1.61-2.1,1.88a7.36,7.36,0,0,1-6.93-1.7"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 186.855px 337.209px;"
					  id="elmo5mg9h9kcb" class="animable"></path>
				<path d="M162.62,332.75a7.19,7.19,0,0,1-9.23-1"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 158.005px 332.866px;"
					  id="el6dqy8io4kd" class="animable"></path>
				<path d="M151.64,332.09a2,2,0,0,0-2.13,2,2.14,2.14,0,0,0-.83,4.11l-.26,0"
					  style="fill: none; stroke: rgb(255, 193, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.716761px; transform-origin: 149.508px 335.143px;"
					  id="elhkk9tn9atp" class="animable"></path>
				<path d="M102.23,298.47c2.85-1.32,5.6-.47,7,2.36,1.94,4,1.94,4,3.31,9.94.14-10.58,3.68-15.1,10.3-18.4-9,2.65-10.66-7.68-10.66-7.68s-1.67,6.59-3.28,8.33A30.32,30.32,0,0,1,102.23,298.47Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 112.535px 297.73px;" id="el4cgf5pd34x8"
					  class="animable"></path>
				<path d="M183.62,287a3.19,3.19,0,0,1,4.49,1.51c1.25,2.55,1.25,2.55,2.13,6.39.09-6.79,2.36-9.7,6.61-11.82-5.81,1.71-6.85-4.92-6.85-4.92s-1.07,4.22-2.1,5.34A19.46,19.46,0,0,1,183.62,287Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 190.235px 286.53px;" id="elwve9b9i5spd"
					  class="animable"></path>
				<path d="M86.06,367.41a2.85,2.85,0,0,1,4,1.37A17.55,17.55,0,0,1,92,374.52c.08-6.11,2.12-8.72,5.95-10.63-5.23,1.53-6.16-4.44-6.16-4.44s-1,3.81-1.89,4.82A17.88,17.88,0,0,1,86.06,367.41Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 92.005px 366.985px;" id="el8suvbf20cj7"
					  class="animable"></path>
				<path d="M236,358.63a2.63,2.63,0,0,1,3.7,1.25,16.53,16.53,0,0,1,1.76,5.28c.08-5.61,2-8,5.47-9.76-4.8,1.4-5.66-4.08-5.66-4.08s-.88,3.5-1.74,4.42A16.06,16.06,0,0,1,236,358.63Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 241.465px 358.24px;" id="el6jwt8erul2i"
					  class="animable"></path>
				<path d="M159,392.91a4.6,4.6,0,0,1,6.49,2.19c1.81,3.69,1.81,3.69,3.08,9.25.13-9.84,3.42-14,9.58-17.11-8.41,2.47-9.91-7.14-9.91-7.14s-1.55,6.13-3.05,7.75A28.1,28.1,0,0,1,159,392.91Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 168.575px 392.225px;" id="elpr3zij1o32j"
					  class="animable"></path>
				<path d="M146.77,275.33c3-1.37,5.82-.49,7.26,2.45,2,4.12,2,4.12,3.44,10.33.15-11,3.82-15.69,10.7-19.12-9.39,2.76-11.08-8-11.08-8s-1.73,6.85-3.4,8.66A31.63,31.63,0,0,1,146.77,275.33Z"
					  style="fill: rgb(255, 255, 255); transform-origin: 157.47px 274.55px;" id="elb0xb5ym34z8"
					  class="animable"></path>
				<line x1="241.38" y1="235.93" x2="226.54" y2="247.82"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 233.96px 241.875px;"
					  id="elh2fw1oa6jdu" class="animable"></line>
				<line x1="247.49" y1="231.03" x2="243.45" y2="234.27"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 245.47px 232.65px;"
					  id="elgy5i51kggj" class="animable"></line>
				<line x1="224.21" y1="226.36" x2="212.48" y2="239.08"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 218.345px 232.72px;"
					  id="el2bpudwpa9e5" class="animable"></line>
				<line x1="215.09" y1="204.16" x2="198.43" y2="230.35"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 206.76px 217.255px;"
					  id="elia550bf338" class="animable"></line>
				<line x1="189.19" y1="209.33" x2="184.37" y2="221.62"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 186.78px 215.475px;"
					  id="elszhpz33buqd" class="animable"></line>
				<line x1="191.66" y1="203" x2="190.05" y2="207.11"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 190.855px 205.055px;"
					  id="el0ulzehf44ipb" class="animable"></line>
				<line x1="173.47" y1="196.1" x2="170.32" y2="212.89"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 171.895px 204.495px;"
					  id="eld8bbk585bhd" class="animable"></line>
				<line x1="174.14" y1="192.58" x2="173.47" y2="196.1"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 173.805px 194.34px;"
					  id="elc9tz91x6j5n" class="animable"></line>
				<line x1="156.52" y1="179.21" x2="156.26" y2="204.16"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 156.39px 191.685px;"
					  id="elkn3wqsvj4o9" class="animable"></line>
				<line x1="156.61" y1="170.65" x2="156.56" y2="175.32"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 156.585px 172.985px;"
					  id="elxnib4ocun0h" class="animable"></line>
				<line x1="58.07" y1="272.35" x2="67.59" y2="275.54"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 62.83px 273.945px;"
					  id="elad25cp4w05v" class="animable"></line>
				<line x1="53.46" y1="270.81" x2="55.81" y2="271.6"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 54.635px 271.205px;"
					  id="eloy12ozo0d2j" class="animable"></line>
				<line x1="39.7" y1="266.2" x2="48.81" y2="269.26"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 44.255px 267.73px;"
					  id="el4ryvca1xbum" class="animable"></line>
				<line x1="67.6" y1="256.72" x2="77.12" y2="262.41"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 72.36px 259.565px;"
					  id="elfrsqjixh4rc" class="animable"></line>
				<line x1="58.07" y1="251.03" x2="64.6" y2="254.93"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 61.335px 252.98px;"
					  id="elze4lrfpyf7" class="animable"></line>
				<line x1="74.97" y1="232.72" x2="91.13" y2="246.49"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 83.05px 239.605px;"
					  id="elhyryehmcni4" class="animable"></line>
				<line x1="66.34" y1="225.37" x2="71.31" y2="229.6"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 68.825px 227.485px;"
					  id="elxtvbhijbcsf" class="animable"></line>
				<line x1="94.6" y1="218.59" x2="106.54" y2="233.62"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 100.57px 226.105px;"
					  id="el63ebmm6g4w3" class="animable"></line>
				<line x1="122.28" y1="219.66" x2="123.82" y2="222.75"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 123.05px 221.205px;"
					  id="elxbcacr9xesp" class="animable"></line>
				<line x1="110.57" y1="196.1" x2="119.71" y2="214.49"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 115.14px 205.295px;"
					  id="eltqwjsbpawn" class="animable"></line>
				<line x1="133.55" y1="190.47" x2="138.37" y2="209.15"
					  style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 135.96px 199.81px;"
					  id="elyk3xwpe7j3" class="animable"></line>
			</g>
			<g id="freepik--Clouds--inject-18" class="animable" style="transform-origin: 237.96px 108.223px;">
				<path d="M168.76,140.83a7.4,7.4,0,0,1-2.88-3.85c-.93-4.28-.84-4.39-1.56-8.69-1.4-8.36-11.52-16.44-23.27-10.16,2.22-3.21-1.9-10.16-7.1-10.1a8.52,8.52,0,0,0-7.55,4s-6.43-9.54-19.69-4.53c-6.22,2.36-8.84,7.42-8.5,13.47a8.39,8.39,0,0,0-9.93.92,6.41,6.41,0,0,0,0,9,8.63,8.63,0,0,0-10.7.92,6.64,6.64,0,0,0,.2,9Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 122.34px 123.438px;"
					  id="elonmozc8cux" class="animable"></path>
				<path d="M102.7,118a9.33,9.33,0,0,0-.17,2.41"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 102.607px 119.205px;"
					  id="el5g7mpen41p" class="animable"></path>
				<path d="M118.45,110.1a13,13,0,0,0-11.79,1.69,10.5,10.5,0,0,0-3.43,4.47"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 110.84px 112.822px;"
					  id="el8vvrx9wqxec" class="animable"></path>
				<path d="M400,110.35c-3.52-1.7-8.7-5.51-12.31-14-1.67-3.91-6.46-6.82-10.44-5.3-6.32-13.78-18.49-5.2-18.49-5.2a20.78,20.78,0,0,0-38.43,13.79,1.48,1.48,0,1,0-2.51,1.47c-7-2.25-15.18,2.37-17.42,9.22Z"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 350.2px 92.9833px;"
					  id="el5yulaiul83r" class="animable"></path>
				<path d="M339,81.16a13.6,13.6,0,0,1,2.85.09"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 340.425px 81.1867px;"
					  id="eljvv84evw9n" class="animable"></path>
				<path d="M325.87,95.16a13.89,13.89,0,0,1,11.2-13.73"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 331.47px 88.295px;"
					  id="elfsrrk2a2tgd" class="animable"></path>
				<path d="M326.74,100.08A13.18,13.18,0,0,1,326,97"
					  style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 326.37px 98.54px;"
					  id="elowb3thkr75" class="animable"></path>
			</g>
			<defs>
				<filter id="active" height="200%">
					<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
					<feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
					<feMerge>
						<feMergeNode in="OUTLINE"></feMergeNode>
						<feMergeNode in="SourceGraphic"></feMergeNode>
					</feMerge>
				</filter>
				<filter id="hover" height="200%">
					<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
					<feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
					<feMerge>
						<feMergeNode in="OUTLINE"></feMergeNode>
						<feMergeNode in="SourceGraphic"></feMergeNode>
					</feMerge>
					<feColorMatrix type="matrix"
								   values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
				</filter>
			</defs>
			<defs>
				<clipPath id="freepik--clip-path--inject-18">
					<path d="M323.46,199.64c-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89.11-2,.23-4.19c12.67,3.7,15.63-8.12,15.63-8.12l.43,2.67Z"
						  style="fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
				</clipPath>
				<clipPath id="freepik--clip-path-2--inject-18">
					<path d="M294.61,154.35c-2,1.87-6.77,12.45-3.09,28.87s20.85,18.81,28.87,4.6c6.33-11.23,8.6-22.74,4.76-30.36S306.91,143.09,294.61,154.35Z"
						  style="fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
				</clipPath>
				<clipPath id="freepik--clip-path-3--inject-18">
					<path d="M275.7,185.59l-18.5-10.92c-7.71-24.56-15.39-43.4-15.39-43.4a13.26,13.26,0,0,0,.9-3,21.27,21.27,0,0,0-1.44-12.2L238,106.46s-.08-6.19-1.45-6.55c-2.1-.54-1.16,5.61-1.16,5.61s1.16,7.39,1.16,7.48a25.23,25.23,0,0,1-2.93-10.42c-.22-2.28-.41-4.09-.51-5a2.64,2.64,0,0,0-.75-1.59c-.57-.55-1.34-.73-1.65,1.77a39.87,39.87,0,0,0,.3,9.41l1.61,6.63-.56-1.34L229.28,106s.76-5.5-.41-6.6-2.31,1.33-2,5.46,2.33,10.7,2.41,11l-2.8-4.21a11.18,11.18,0,0,0-.82-5.1c-.7-1.33-1.69,1-1.4,5.17.82,11.62,7.37,24.12,7.48,21.55l1,9.35A277.26,277.26,0,0,0,240.35,185h0s16.58,12.58,28.78,21.95C266.64,193.62,272.47,187.75,275.7,185.59Z"
						  style="fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
				</clipPath>
				<clipPath id="freepik--clip-path-4--inject-18">
					<path d="M436.49,144.25c-1.52-.52-3.27,3.16-3.27,3.16l-4,3.3a62.44,62.44,0,0,0,5.1-8.74c1.64-3.8,1.36-6.48-.1-5.82s-2.57,6.1-2.57,6.1l-5.73,6.49-.09-.06,3.82-5.93a40.56,40.56,0,0,0,3.4-8.79c.52-2.47-.27-2.55-1-2.21a2.58,2.58,0,0,0-1.23,1.25c-.4.85-1.18,2.49-2.13,4.58a35.94,35.94,0,0,1-6.51,9.65l-.22,0,3.07-5s4.29-8.81,2.12-8.82-3.59,6.22-3.59,6.22L417,147.8a21.47,21.47,0,0,0-5.38,11h0a13,13,0,0,0-.14,3.17S383,197,382.7,197.91l-16,2.09c-2.89.37-5.8.65-8.7.87-1.42,5.35-3,15.16,1.12,24.19,15.6-2.69,36.09-11.86,36.09-11.86L420.49,167S431,155,433.4,151.6,438.64,145,436.49,144.25Z"
						  style="fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
				</clipPath>
				<clipPath id="freepik--clip-path-5--inject-18">
					<path d="M360.89,200.63c-3.87.28-8.41,1-14.53.69-7.7-.38-15.31-.56-22.9-1.68-1.31,2-4.15,4.37-7.27,6.59A7.21,7.21,0,0,1,304.85,200l.1-1.89c-2.9-.91-12.93-1.87-12.93-1.87h0a18.44,18.44,0,0,0-13.52-10.26l-3.16-.55c-2.87,2.37-8.7,8.24-6.21,21.56,3.67,2.82,6.95,5.36,9.26,7.16,2.75,3.71,4.2,55.26,4.2,55.26l-4.2,24.86c19.21,8.91,47.9,6.17,58.32,4.33h0s-3.26-35.47-.89-46.24c.94-4.26,2.89-12.54,4.91-21a0,0,0,0,0,0,0c0-.12,8.29-2.89,18.37-6.23C357.4,219.93,359.19,209.89,360.89,200.63Z"
						  style="fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
				</clipPath>
				<clipPath id="freepik--clip-path-6--inject-18">
					<path d="M244.93,116.35c-.27-.76-.71-1.47-.93-2.26-.34-1.17-.31-2.64-.71-3.73a1,1,0,0,0-1.83,0,8.83,8.83,0,0,0-.19,5.72,3.41,3.41,0,0,1,.25,1.17,2,2,0,0,1-.73,1.5,9.54,9.54,0,0,0-2.07,1.86c-2.21,2.65-2.71,6.1-1.62,8l4.71,2.65C244.09,130.52,245.93,119.14,244.93,116.35Z"
						  style="fill:#fff"></path>
				</clipPath>
			</defs>
		</svg>
	</div>
</template>

<script lang="ts">

import useSystem from '@/composable/useSystem';

export default {
	name: 'TreasureFoundAnimation',
	components: {},
	props: {
		maxHeight: {
			type: String,
			default: '25em',
		},
	},
	setup(props) {
		const {isNativePlatform} = useSystem();

		function getAnimationStyle(): any {
			return {
				'max-height': props.maxHeight,
			};
		}

		return {getAnimationStyle, isNativePlatform};
	},
};
</script>


<style scoped>
svg#freepik_stories-treasure:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-treasure.animated #freepik--background-simple--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-treasure.animated #freepik--Shadow--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-treasure.animated #freepik--hole-and-shovel--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-treasure.animated #freepik--Character--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-treasure.animated #freepik--Treasure--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-treasure.animated #freepik--Clouds--inject-18 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(-2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>