<template>
	<ion-page>
    <ion-header translucent>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/tabs/challenges" :text="backButtonText"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button>
            <ion-button @click="refresh()">
              <ion-icon :icon="refreshIcon" :color="refreshColor"></ion-icon>
            </ion-button>
          </ion-button>
        </ion-buttons>
        <ion-title>Challenges</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" v-if="null !== challenge">
      <section class="ion-padding-horizontal">
        <div>
          <h1>{{ challenge.title }}</h1>
          <div v-if="challenge.getImage()" class="challenge-image">
            <ion-img :alt="challenge.getImage()" :src="challenge.getImage()" />
            <div class="progress-info">
              <div class="ion-align-items-center ion-justify-content-between" style="display: flex">
                <span>{{ userChallengeProgress }}%</span>
                <ion-icon v-show="userChallengeProgress >= 100" :icon="icons.checkmarkOutline"
                          style="margin-left: .25em;"></ion-icon>
                <ion-icon v-show="hasUserChallengeCancelled" :icon="icons.pauseOutline"
                          style="margin-left: .25em;"></ion-icon>
              </div>
              <ion-progress-bar :value="userChallengeProgressPercentage" color="secondary"></ion-progress-bar>
            </div>
          </div>
          <div v-else>
            <div class="ion-align-items-center ion-justify-content-between" style="display: flex">
              <span>{{ userChallengeProgress }}%</span>
              <ion-icon v-show="userChallengeProgress >= 100" :icon="icons.checkmarkOutline"
                        style="margin-left: .25em;"></ion-icon>
              <ion-icon v-show="hasUserChallengeCancelled" :icon="icons.pauseOutline"
                        style="margin-left: .25em;"></ion-icon>
            </div>
            <ion-progress-bar :value="userChallengeProgressPercentage" color="secondary"></ion-progress-bar>
          </div>
        </div>
        <p v-html="timeInfo" v-if="hasUserChallengeAccepted" class="time-info"></p>
        <p v-html="challengeInfo"></p>
        <p v-if="showDefineHomeLocationInfo" class="location-info"
           v-html="getTextValueByKey('challenge.detail.createHomeLocationInfo')"></p>
        <div class="ion-margin-top buttons">
          <ion-button v-if="hasUserChallengeAccepted" color="warning" expand="block" @click="cancelChallenge()">
            <ion-icon slot="start" :icon="icons.pauseOutline" style="margin-right: .25em;"></ion-icon>
            <span slot="end">Challenge abbrechen</span>
          </ion-button>
          <ion-button v-else-if="hasUserChallengeCancelled || !hasUserChallengeDeclined" color="success" expand="block"
                      @click="acceptChallenge()" class="ion-margin-bottom">
            <ion-icon slot="start" :icon="icons.checkmarkOutline" size="large" style="margin-right: .25em;"></ion-icon>
            <span slot="end">An Challenge teilnehmen</span>
          </ion-button>
          <ion-button v-if="!hasUserChallengeDeclined && !hasUserChallenge" size="small" color="danger" expand="block"
                      @click="declineChallenge()">
            <ion-icon slot="start" :icon="icons.closeOutline" style="margin-right: .25em;"></ion-icon>
            <span slot="end">Challenge ablehnen</span>
          </ion-button>
        </div>

      </section>

      <challenge-highscore :uuid="challengeUuid"></challenge-highscore>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {ref, defineExpose, computed, onMounted, watch} from "vue";
import {
  IonButton, IonBackButton,
  IonButtons,
  IonIcon,
  IonProgressBar,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle, alertController, toastController,
  IonImg
} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useChallenge from "@/composable/useChallenge";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import {RouteParams, useRoute} from "vue-router";
import router from "@/router";
import {
  CHALLENGE_STATE_IN_PROGRESS
} from "@/mobipoints/challenge/challenge/challenge.interface";
import {
  USER_CHALLENGE_STATE_ACCEPTED,
  USER_CHALLENGE_STATE_CANCELLED, USER_CHALLENGE_STATE_DECLINED, USER_CHALLENGE_STATE_FINISHED
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import store from "@/store";
import ChallengeHighscore from "@/components/challenge/ChallengeHighscore.vue";
import {UserChallenge} from "@/mobipoints/challenge/userChallenge/user_challenge.type";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {ChallengeResponseError} from "@/mobipoints/api/challenge";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import useMainData from "@/composable/useMainData";
import useData from "@/composable/useData";


const currentRoute = useRoute();
const mainData = useMainData();
const {getTextValueByKey} = useData();

// const currentRouteName = ref<string>(String(currentRoute.name));
const currentRouteParams = ref<RouteParams>(currentRoute.params || {});
const backButtonText = ref(getTextValueByKey('overview.back.button.text', [], ''));


const hasHomeLocation = computed<boolean>(() => {
  const homeLocations = mainData.userLocationsEditable.value.filter((userLocation) => MobiPointsLocationTypes.HOME_LOCATION === userLocation.type.code);
  return homeLocations.length > 0;
});

const showDefineHomeLocationInfo = computed<boolean>(() => {
  return !hasHomeLocation.value;
});

const challengeUuid = computed<string>(() => {
  return String(currentRouteParams.value && currentRouteParams.value.uuid);
});

const hasUserChallengeAccepted = computed<boolean>(() => {
  return null !== userChallenge.value && (
    USER_CHALLENGE_STATE_ACCEPTED === userChallenge.value.state.code
    || USER_CHALLENGE_STATE_FINISHED === userChallenge.value.state.code
  );
});

const hasUserChallengeCancelled = computed<boolean>(() => {
  return null !== userChallenge.value && USER_CHALLENGE_STATE_CANCELLED === userChallenge.value.state.code;
});

const hasUserChallengeDeclined = computed<boolean>(() => {
  return null !== userChallenge.value && USER_CHALLENGE_STATE_DECLINED === userChallenge.value.state.code;
});

const hasUserChallenge = computed<boolean>(() => {
  return null !== userChallenge.value;
});

const userChallenge = computed<UserChallenge | null>(() => {
  return (challenge.value && challenge.value.userChallenges[0] as UserChallenge) || null;
});

const userChallengeProgress = computed<number>(() => {
  return userChallenge.value?.progress || 0;
});

const userChallengeProgressPercentage = computed<number>(() => {
  return userChallengeProgress.value / 100;
});

const challengeInfo = computed<string>(() => {
  const createHomeLocationInfo = getTextValueByKey('challenge.detail.createHomeLocationInfo');
  return (challenge.value && challenge.value.description || '').replace(createHomeLocationInfo, '');
});

const timeInfo = computed<string>(() => {
  const timeStr = challenge.value?.getTimeLeftAsString() || '';
  if (timeStr.length > 0) {
    return getTextValueByKey('challenge.detail.timeInfo', [{'key': 'time', 'value': timeStr}], '');
  } else {
    return '';
  }
});


const createToast = function (message: string, icon: string, duration = 3000, position: 'top' | 'bottom' | 'middle' = 'bottom'): Promise<any> {
  return toastController.create({
    message: message,
    position: position,
    duration: duration > 0 ? duration : 3000,
    icon: icon,
    buttons: [
      {
        side: 'end',
        role: 'cancel',
        icon: icons.close,
      }
    ],
  });
}


const acceptChallenge = async function () {
  if (null !== challenge.value) {
    const result: string | null = await store.dispatch('challenge/acceptChallenge', challenge.value.uuid);
    let message: string;
    let icon = icons.checkmarkOutline;
    if (null === result) {
      challenge.value = challengeFacade.getChallenge(challengeUuid.value);
      message = `Ab sofort nimmst du an der Challenge teil!`;
    } else {
      message = `Could not accept challenge: ${result || 'Unkown'}`;
      icon = icons.warning;
    }

    return (await createToast(message, icon)).present();
  }
}

const cancelChallenge = async function () {
  if (null !== challenge.value) {
    const result: string | null = await store.dispatch('challenge/cancelChallenge', challenge.value.uuid);
    let message: string;
    let icon = icons.checkmarkOutline;
    if (null === result) {
      challenge.value = challengeFacade.getChallenge(challengeUuid.value);
      message = `Du hast die Teilnahme an der Challenge abgebrochen!`;
    } else {
      message = `Could not cancel challenge: ${result || 'Unkown'}`;
      icon = icons.warning;
    }

    return (await createToast(message, icon)).present();
  }
}

const declineChallenge = async function () {
  if (null !== challenge.value) {
    const alert = await alertController.create({
      header: 'Sicherheitsabfrage:',
      message: 'Die Teilnahme an der Challenge wirklich ablehnen<br>(Eine spätere Teilnahme ist nicht mehr möglich)?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ja',
          cssClass: 'danger',
          handler: async function () {
            if (null !== challenge.value) {
              const result: string | null = await store.dispatch('challenge/declineChallenge', challenge.value.uuid);
              let message: string;
              let icon = icons.checkmarkOutline;
              if (null === result) {
                challenge.value = challengeFacade.getChallenge(challengeUuid.value);
                message = `Du hast die Teilnahme an der Challenge abgelehnt!`;
              } else {
                message = `Could not decline challenge: ${result || 'Unkown'}`;
                icon = icons.warning;
              }

              const toast = await createToast(message, icon);
              toast.present().then(router.replace({ name: 'Challenges' }));
            }
          }
        }
      ]
    });

    return alert.present();
  }
}

const challengeFacade = useChallenge();

onMounted(() => {
  if (null === challenge.value || CHALLENGE_STATE_IN_PROGRESS !== challenge.value.state.code) {
    router.back();
  }
  // challengeFacade.reloadChallenges().then(() => {
  //   challenge.value = challengeFacade.getChallenge(challengeUuid.value);
  // });
});


const challenge = ref<Challenge | null>(challengeFacade.getChallenge(challengeUuid.value));

const refreshTimeout = 10;  //  in seconds
let lastRefresh = Date.now();
let nextRefreshTO: number;
let resetRefreshColorTO: number;

const refreshIcon = ref<string>(icons.refreshOutline);
const refreshColor = ref<string>('');
const doRefresh = async function() {
  try {
    await challengeFacade.reloadChallenges();

    lastRefresh = Date.now();

    refreshIcon.value = icons.refreshOutline;
    refreshColor.value = 'success';

    clearTimeout(resetRefreshColorTO);
    resetRefreshColorTO = setTimeout(() => {
      refreshColor.value = '';
    }, refreshTimeout * 1000);

    const toast = await createToast(`Seite erfolgreich aktualisiert!`, icons.checkmarkOutline, 1500, 'bottom');
    return toast.present();
  } catch (error: any | ChallengeResponseError) {
    lastRefresh = Date.now();

    refreshColor.value = 'danger';

    const toast = await createToast(`Seite konnte nicht aktualisiert werden!`, icons.bugOutline, 3000, 'bottom');
    return toast.present();
  }
}
const refresh = async function () {
  const diff = (Date.now() - lastRefresh) / 1000;
  if (diff < refreshTimeout) {
    const toast = await createToast(`Noch ${Math.ceil(refreshTimeout - diff)} Sekunden bis zum nächsten Reload`, icons.informationOutline, 2000, 'top');

    clearTimeout(nextRefreshTO);
    nextRefreshTO = setTimeout(doRefresh, (refreshTimeout - diff) * 1000);
    refreshIcon.value = icons.timerOutline;
    refreshColor.value = 'primary';

    return toast.present();
  }
  await doRefresh();
}

watch(() => challengeFacade.getChallenge(challengeUuid.value), async (newChallenge) => {
  if (challengeUuid.value === newChallenge?.uuid) {
    challenge.value = newChallenge;
  }
});

defineExpose({});
</script>

<style scoped>
.challenge-image {
  position: relative;
}

.challenge-image .progress-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1em;
  background-color: rgba(var(--ion-color-white-rgb), .33);
}

div.buttons ion-button {
  margin-top: .75em;
}

div.buttons ion-button:first-child {
  margin-top: 0;
}

p.location-info {
  padding: .5rem 1rem;
  color: rgba(var(--ion-color-danger-rgb), .85);
  background-color: rgba(var(--ion-color-dark-rgb), .15);
}

p.time-info {
  padding: .5rem 1rem;
  color: rgba(var(--ion-color-primary-rgb), .85);
  background-color: rgba(var(--ion-color-dark-rgb), .15);
}
</style>