import {WalletFactory} from '@/mobipoints/wallet/wallet_factory.type';
import {
	WalletItemFactory
} from '@/mobipoints/wallet/item/wallet_item_factory.type';

export default function useWalletFactory() {
	let walletFactory: WalletFactory | null = null;
	const getWalletFactory = (): WalletFactory => {
		if (!walletFactory) {
			walletFactory = new WalletFactory();
		}

		return walletFactory;
	}

	let walletItemFactory: WalletItemFactory | null = null;
	const getWalletItemFactory = (): WalletItemFactory => {
		if (!walletItemFactory) {
			walletItemFactory = new WalletItemFactory();
		}

		return walletItemFactory;
	}

	return {
		getWalletFactory,
		getWalletItemFactory,
	}
}