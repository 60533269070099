import {MobiPointsTrackingTrackingFactory} from "@/mobipoints/tracking/tracking_factory.type";

export default function useTrackingFactory() {

	let trackingFactory: MobiPointsTrackingTrackingFactory | null = null;
	const getTrackingFactory = (): MobiPointsTrackingTrackingFactory => {
		if (!trackingFactory) {
			trackingFactory = new MobiPointsTrackingTrackingFactory();
		}
		return trackingFactory;
	}

	return {
		getTrackingFactory,
	}
}