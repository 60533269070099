import {
	WalletItemInterface, WalletItemState, WalletItemSubType, WalletItemType,
} from '@/mobipoints/wallet/item/wallet_item.interface';

import {
	formatDate,
	dateTimestampToMilliseconds,
	formatSecondsNice,
} from '@/mobipoints/core/date';
import useData from '@/composable/useData';
import useTrackingFactory from '@/composable/useTrackingFactory';
import {StringHelper} from '@/mobipoints/core/string_helper';
import {
	MobiPointsQueueEventTypeRedeemVoucherType
} from '@/mobipoints/queue/event/type/redeem_voucher_event.type';
import {MobiPointsChallengeType} from "@/mobipoints/challenge/challenge/challenge.type";

const {getTrackingFactory} = useTrackingFactory();

export abstract class AbstractWalletItem implements WalletItemInterface {
	uuid: string;
	user_wallet_uuid: string;
	type: WalletItemType;
	state?: WalletItemState;
	subtype?: WalletItemSubType;
	amount?: number;
	reference?: string;
	data? : {
		challenge : {
			type: MobiPointsChallengeType,
			uuid: string,
		};
		voucher : {
			code: string,
			type: MobiPointsQueueEventTypeRedeemVoucherType,
			uuid: string,
		},
		[key: string] : any,
	};
	timestamp?: number;

	constructor(
			uuid: string, user_wallet_uuid: string, type: WalletItemType,
			state?: WalletItemState | undefined,
			subtype?: WalletItemSubType | undefined, amount?: number | undefined,
			reference?: string | undefined, data?: any,
			timestamp?: number | undefined) {
		this.uuid = uuid;
		this.user_wallet_uuid = user_wallet_uuid;
		this.type = type;
		this.state = state;
		this.subtype = subtype;
		this.amount = amount;
		this.reference = reference;
		this.timestamp = timestamp;

		if (data && StringHelper.isJSON(data)) {
			this.data = JSON.parse(data);
		} else {
			this.data = data;
		}
	}

	getBadgeColor(): string {
		return 'medium';
	}

	getAmountFormatted(withSuffix = false): string {
		if (!this.amount) {
			return '0';
		}
		return this.amount?.toFixed(2);
	}

	getItemName(): string {
		return '';
	}

	getItemDescription(): string {
		if (!this.data) {
			return '';
		}

		if (this.hasVoucherData()) {
			return useData().
			getTextValueByKey('mobipoints.wallet.item.data.description.voucher',
					[{key: 'Code', value: this.data.voucher.code ?? ''}],
					'Gutschein ' + this.data.voucher.code ?? '');
		}
		if (this.hasTracking()) {
			return this.getTrackingDescription();
		}

		return '';
	}

	getVoucherType(): MobiPointsQueueEventTypeRedeemVoucherType|undefined {
		if (!this.data) {
			return;
		}

		if (this.hasVoucherData() && this.data.voucher.type) {
			return this.data.voucher.type;
		}

		return undefined;
	}

	getChallengeType(): MobiPointsChallengeType|undefined {
		return this.data?.challenge.type || undefined;
	}

	getTimestampAsDate(): Date | null {
		if (!this.timestamp) {
			return null;
		}
		return new Date(dateTimestampToMilliseconds(this.timestamp));
	}

	getDateFormatted(): string {
		if (!this.timestamp) {
			return '';
		}
		return formatDate(new Date(dateTimestampToMilliseconds(this.timestamp)));
	}

	protected hasVoucherData(): boolean {
		let result = false;
		if (this.data && this.data.voucher) {
			result = true;
		}
		return result;
	}

	protected hasTracking(): boolean {
		let result = false;
		if (this.data && this.data.path) {
			result = true;
		}
		return result;
	}

	protected getTrackingDescription() {
		const path = this.data?.path;

		if (!path) {
			return '';
		}

		const trackingTypeInfoList = getTrackingFactory().getTrackingTypInfoList();
		const trackingType_by_Data = path.type ?? undefined;
		if (!trackingType_by_Data) {
			return '';
		}
		const tracking = trackingTypeInfoList.find(
				(tracking) => tracking.type === trackingType_by_Data) || null;

		if (!tracking) {
			return '';
		}

		const trackingTypeName = tracking.name;
		const trackingTypeIcon =  `<div class="flex" style="height: 22px; padding-right: 5px"><img src="${tracking.icon}" alt="${trackingTypeName}"/></div>`;

		const duration = formatSecondsNice(path.duration ?? 0);
		const distance = ((path.distance ?? 0) / 1000).toFixed(2);
		// const startPosition = '';
		// if (this.data.weather)
		// const weatherItem = useWeatherFactory().getWeatherFactory().createSingleWeatherDataByApiResponse(response.data);

		const defaultText = '<div class="flex text-center justify-center">' + trackingTypeIcon + ' ' + '<span>' +
				trackingTypeName + ', ' + distance + ' km  🔛 ' + duration +
				'</span></div>';

		return useData().
		getTextValueByKey('mobipoints.wallet.item.data.description.tracking', [
			{key: 'trackingName', value: trackingTypeName},
			{key: 'trackingIcon', value: trackingTypeIcon},
			{key: 'duration', value: duration},
			{key: 'distance', value: distance}], defaultText);
	}
}