<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>{{mobiDashboardTitle}}</ion-title>
				<avatar-menu :show-points="true"></avatar-menu>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true" class="">
			<ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
				<ion-refresher-content></ion-refresher-content>
			</ion-refresher>

			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">{{mobiDashboardTitle}}</ion-title>
				</ion-toolbar>
			</ion-header>

			<news-info :show-banner="true" :show-card="false" :show-popup="false"></news-info>
			<news-info :show-banner="false" :show-card="false" :show-popup="true" :force-reload="false"></news-info>
			<welcome-profile-info></welcome-profile-info>

			<news-info :show-banner="false" :show-card="true" :show-popup="false" :force-reload="false"></news-info>
			<last-tracking-card v-if="!hasActiveTracking && hasLastTracking" id="last-active-tracking-component"></last-tracking-card>
			<active-tracking-card v-if="hasActiveTracking" id="active-tracking-component"></active-tracking-card>
			<favorite-track-list></favorite-track-list>

			<div v-if="!hasHomeLocation" style="margin: 0">
				<ion-card class="ion-margin">
					<ion-card-header>
						<ion-card-title class="ion-align-items-center ion-justify-content-start"
										style="display:flex; color: var(--ion-color-warning);">
							<ion-icon :icon="icons.homeOutline" style="margin-right: .25em"></ion-icon>
							<span>Wohnadresse definieren</span>
						</ion-card-title>
					</ion-card-header>
					<ion-card-content>
						<p>Du hast noch keine Wohnadresse angelegt!</p>
						<ion-button color="primary" @click="addAddress">
							<ion-icon :icon="icons.addOutline" style="margin-right: .25em"></ion-icon>
							<ion-label>Wohnadresse hinzufügen</ion-label>
						</ion-button>
						<ion-button v-if="hasLocations" color="medium" @click="openUserLocationOverview">
							<ion-icon :icon="icons.listOutline" style="margin-right: .25em"></ion-icon>
							<ion-label>Adressen verwalten</ion-label>
						</ion-button>
					</ion-card-content>
				</ion-card>
			</div>

			<div class="ion-margin" v-if="firstJoinedOrOpenChallenge" id="homeChallengeOverview">
				<h1>Challenges</h1>
				<challenge-teaser :uuid="firstJoinedOrOpenChallenge.uuid" show-progress="full"></challenge-teaser>
			</div>

			<statistic-overview v-show="false"></statistic-overview>
			<ExploreContainer name="Tab 1 page"></ExploreContainer>
		</ion-content>
		<ion-footer>
			<TrackingButton v-if="!hasActiveTracking"></TrackingButton>
		</ion-footer>
	</ion-page>
</template>

<script lang="ts">
import {computed, ComputedRef, onBeforeMount, ref} from 'vue'
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar, onIonViewDidEnter, useBackButton, useIonRouter,
	IonCard, IonCardHeader, IonCardContent, IonCardTitle,
	IonIcon,
	IonRefresher,
	IonRefresherContent,
	IonFooter,
} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import {useStore} from 'vuex'
import {walk, warning} from 'ionicons/icons';
import {MobiPointApiToken} from "@/mobipoints/api/token";
import AvatarMenu from "@/components/menu/AvatarMenu.vue";
import TrackingButton from "@/components/tracking/TrackingButton.vue";
import ActiveTrackingCard from "@/components/tracking/ActiveTrackingCard.vue";
import FavoriteTrackList from "@/components/track/FavoriteTrackList.vue";
import StatisticOverview from "@/components/user/StatisticOverview.vue";
import WelcomeProfileInfo from "@/components/profile/info/WelcomeProfileInfo.vue";
import ChallengeTeaser from "@/components/challenge/ChallengeTeaser.vue";
import useChallenge from "@/composable/useChallenge";
import {Challenge} from "@/mobipoints/challenge/challenge/challenge.type";
import {
	USER_CHALLENGE_STATE_ACCEPTED,
	USER_CHALLENGE_STATE_CANCELLED
} from "@/mobipoints/challenge/userChallenge/user_challenge.interface";
import useMainData from "@/composable/useMainData";
import {MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import NewsInfo from "@/components/news/NewsInfo.vue";
import LastTrackingCard from "@/components/tracking/LastTrackingCard.vue";
import useData from "@/composable/useData";
import useToastMessage from "@/components/core/ToastMessage.vue";
import usePoints from "@/composable/usePoints";
import useSystem from "@/composable/useSystem";
import useNews from "@/composable/useNews";
import {MobiPointsQueueQueue} from "@/mobipoints/queue/queue.type";
import {App} from "@capacitor/app";
import useUserProfile from "@/composable/useUserProfile";
import useAuth from "@/composable/useAuth";

const challengeFacade = useChallenge();
const mainData = useMainData();

export default {
	name: 'Tab1',
	components: {
		LastTrackingCard,
		NewsInfo,
		ChallengeTeaser,
		WelcomeProfileInfo,
		StatisticOverview,
		FavoriteTrackList,
		ActiveTrackingCard,
		TrackingButton,
		AvatarMenu,
		ExploreContainer,
		IonHeader,
		IonToolbar,
		IonTitle,
		IonContent,
		IonPage,
		IonCard, IonCardHeader, IonCardContent, IonCardTitle,
		IonIcon,
		IonRefresher,
		IonRefresherContent,
		IonFooter,
	},
	setup(props: any) {
		const pingData: any = ref();
		const store = useStore();
		const {getTextValueByKey} = useData();
		const {
			hasActiveTracking,
			hasLastTracking,
		} = useTracking();
		const fullName = computed(() => store.getters['user/fullName'])
		const userImage = computed(() => store.getters['user/getProfileImage'])
		const mobiDashboardTitle = getTextValueByKey('tabs.mobi.dashboard.title', [], 'Mobi Dashboard')
		const {openToast} = useToastMessage();
		const {hasInternetConnection} = useSystem();
		const {checkIsAuthenticated} = useAuth();
		const router = useIonRouter();
		const closeAppCounter = ref(0);
		const closeAppTimerId: any = ref(null);

		function saveToken() {
			MobiPointApiToken.saveToken("foo");
		}

		const closeApp = (force = false) => {
			return !router.canGoBack() || force;
		};

		onIonViewDidEnter(() => {
			//
		});

		useBackButton(10, () => {
			if (closeApp(true)) {
				if (closeAppCounter.value === 1) {
					closeAppTimerId.value = setTimeout( () => {
						closeAppCounter.value = 0;
					}, 3000);
					useToastMessage().openToast(getTextValueByKey('app.close.hint', [], 'Drücke nochmal auf "zurück" um die APP zu schließen'), 'medium', 'bottom', true, 3000, undefined, true);
				} else if (closeAppCounter.value > 1) {
					clearTimeout(closeAppTimerId.value);
					App.exitApp();
				}
				closeAppCounter.value++;
			}
		});

		const firstJoinedOrOpenChallenge: ComputedRef<Challenge | null> = computed(() => {
			let challenge: Challenge | null = null;
			if (challengeFacade.activeChallenges.value.length > 0) {
				challenge = challengeFacade.activeChallenges.value.find((activeChallenge) => {
					const foundUserChallenge = activeChallenge.userChallenges.find((userChallenge) => USER_CHALLENGE_STATE_ACCEPTED === userChallenge.state.code);
					return !!foundUserChallenge;
				}) || challenge;

				if (null === challenge) {
					challenge = challengeFacade.activeChallenges.value.find((activeChallenge) => {
						const foundUserChallenge = activeChallenge.userChallenges.find((userChallenge) => USER_CHALLENGE_STATE_CANCELLED === userChallenge.state.code);
						return !!foundUserChallenge;
					}) || challenge;
				}
			}

			if (null === challenge && challengeFacade.openChallenges.value.length > 0) {
				challenge = challengeFacade.openChallenges.value.find(() => true) || challenge;
			}

			return challenge;
		});

		const hasHomeLocation: ComputedRef<boolean> = computed(() => {
			const homeLocations = mainData.userLocationsEditable.value.filter((userLocation) => MobiPointsLocationTypes.HOME_LOCATION === userLocation.type.code);
			return homeLocations.length > 0;
		});

		const hasLocations: ComputedRef<boolean> = computed(() => mainData.userLocationsEditable.value.length > 0);

		const addAddress = function () {
			router.push({name: 'UserLocationAdd'});
		}

		const openUserLocationOverview = function () {
			router.push({name: 'UserLocationOverview'});
		}

		async function refreshData(event: CustomEvent|any){
			if (event && event.target && hasInternetConnection()) {
				try {
					setTimeout(() => {
						event.target.complete();
					}, 1500);
					await useMainData().initMainData(true);
					await useData().initData(true);
					await usePoints().initPoints(true);
					await useChallenge().initChallenges(true);
					await useNews().initNews(true);
					await useUserProfile().initUserProfile(true); //TODO
					const queue = new MobiPointsQueueQueue();
					await queue.startIfNotRunning();
					event.target.complete();
					await openToast(getTextValueByKey('refresh.data.success', [], 'Daten erfolgreich aktualisiert'), 'success', 'bottom', true, 1500, icons.refreshOutline, true);
				} catch (error) {
					event.target.complete();
					console.error('error refresh data', error);
					await openToast(getTextValueByKey('refresh.data.error', [], 'Fehler beim aktualisieren der Daten!'), 'danger', 'top', true, 1500, icons.bugOutline, true);
				}
			} else {
				event.target.complete();
			}
		}

		onBeforeMount(() => {
			challengeFacade.reloadChallenges();
			mainData.reloadUserLocations();
      		checkIsAuthenticated();
		});

		return {
			warning,
			walk,
			fullName,
			userImage,
			pingData,
			saveToken,
			firstJoinedOrOpenChallenge,
			hasHomeLocation,
			icons,
			hasLocations,
			addAddress,
			openUserLocationOverview,
			hasActiveTracking,
			hasLastTracking,
			mobiDashboardTitle,
			refreshData,
		}
	}
}
</script>

<style>
.delete {
    color: white !important;
    background-color: #ff909c !important;
}

.delete ion-icon {
    color: white !important;
}
</style>
