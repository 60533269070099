// import {MobiPointsApiAuth} from "@/mobipoints/api/auth";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import router from "@/router";

export default function authMiddleware({next, to, from, store}) {
	const toPath = to.path;
	const env = process.env.NODE_ENV;

	//TODO fix sobald cors im development mode Über localhost funktioniert
	if (env === "development") {
		if (window.location.host.includes("localhost")) {
			return next();
		}
	}

	if ( (store.getters['auth/authenticating'] && !store.getters['auth/isTokenLifeTimeExpired']) && (toPath === '/login' ||  toPath === '/register' || toPath === '/forgotPassword') ) {
		router.push('/tabs/home');
	} else if((!store.getters['auth/authenticating'] || store.getters['auth/isTokenLifeTimeExpired'])  && (toPath === '/login' ||  toPath === '/register' || toPath === '/forgotPassword'  ||  toPath === '/user/email/verify'  ||  toPath === '/user/password/reset') ) {
		return next();
	} else if ((!store.getters['auth/authenticating'] || store.getters['auth/isTokenLifeTimeExpired']) && (toPath !== '/login' ||  toPath !== '/register' || toPath !== '/forgotPassword' ||  toPath !== '/user/email/verify' || toPath !== '/user/password/reset')  ) {
		const userAuth = new UserAuth();
		userAuth.logout();
	}
	return next();
}
