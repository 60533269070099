import {MobiPointsTrackingTrackAbstractTrack} from "@/mobipoints/tracking/track/abstract_track.type";
import {
	MobiPointsTrackingTracking,
	TRACKING__STATE_CONVERTED,
	TRACKING__STATE_ERROR,
	TRACKING__STATE_IN_PROGRESS,
	TRACKING__STATE_INVALID,
	TRACKING__STATE_NULLIFY,
	TRACKING__STATE_VERIFIED,
	TRACKING_STATE_NEW
} from "@/mobipoints/tracking/tracking.type";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {EVENT_TYPE_END_TRACKING} from "@/mobipoints/queue/event/abstract_event.type";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";

const {getTrackingFactory} = useTrackingFactory();

const getLatestTrack = (state: any) => {
	if (state.currentTracking === null) {
		console.log("NO Active UserTracking found!");
	} else {
		if (state.currentTracking.tracks) {
			return state.currentTracking.tracks[state.currentTracking.tracks.length - 1] ?? null
		}
		return null;
	}
};

const tracking = {
	namespaced: true,
	state: () => ({
		trackings: [] as Array<MobiPointsTrackingTracking>,
		currentTracking: {} as MobiPointsTrackingTracking,
		initialCoordinateSend: false,
		currentPosition: {} as MobiPointsTrackingCoordinateCoordinateInterface,
		lastTrackings: [] as Array<MobiPointsTrackingTracking>,
		positionWatchId: null,
		trackingAutoStopIntervalId: null,
		userTrackingTrackStates: [
			TRACKING_STATE_NEW,
			TRACKING__STATE_IN_PROGRESS,
			TRACKING__STATE_INVALID,
			TRACKING__STATE_VERIFIED,
			TRACKING__STATE_NULLIFY,
			TRACKING__STATE_CONVERTED,
			TRACKING__STATE_ERROR,
		],
	}),
	mutations: {
		setCurrentTracking(state: any, tracking: MobiPointsTrackingTracking) {
			state.currentTracking = tracking;
		},
		setInitialCoordinateSend(state: any, value: boolean) {
			state.initialCoordinateSend = value;
		},
		addTrackToCurrentTracking(state: any, track: MobiPointsTrackingTrackAbstractTrack) {
			if (state.currentTracking === null) {
				console.log("NO Active UserTracking found!");
			} else {
				if (state.currentTracking.tracks === null) {
					state.currentTracking.tracks = [];
				}
				state.currentTracking.tracks.push(track);
			}
		},
		addCoordinateToLatestTrack(state: any, coordinate: MobiPointsTrackingCoordinateCoordinateInterface) {
			if (state.currentTracking === null) {
				console.log("NO Active UserTracking found!");
			} else {
				const latestTrack = state.currentTracking.tracks[state.currentTracking.tracks.length-1];
				if (latestTrack && latestTrack.coordinates === null) {
					latestTrack.coordinates = {};
					latestTrack.coordinates.items = [];
				}
				latestTrack.coordinates.items.push(coordinate);
			}
		},
		resetCurrentTracking(state: any) {
			state.currentTracking = {} as MobiPointsTrackingTracking;
		},
		addLastTracking(state: any, tracking: MobiPointsTrackingTracking) {
			if (state.lastTrackings === null || !state.lastTrackings) {
				state.lastTrackings = [];
			}
			state.lastTrackings.push(tracking);
		},
		resetLastTrackings(state: any) {
			state.lastTracking = [];
		},
		setCurrentPosition(state: any, currentPosition: MobiPointsTrackingCoordinateCoordinateInterface) {
			state.currentPosition = currentPosition;
		},
		setCurrentPositionTimestamp(state: any, timestamp: number) {
			if (state.currentPosition && state.currentPosition.timestamp) {
				state.currentPosition.timestamp = timestamp;
			}
		},
		setCurrentTrackingType(state: any, trackingType: string) {
			state.currentTracking.type = trackingType;
		},
		setCurrentTrackingTrackType(state, trackType: string) {
			state.currentTracking.tracks.type = trackType;
		},
		setCurrentTrackingWeatherItem(state, weatherItem: MobiPointsTrackingWeatherWeather) {
			state.currentTracking.weatherItem = weatherItem;
		},
		setCurrentTrackingTrackState(state, trackState: string) {
			// state.currentTracking.tracks.state = trackState;
			const latestTrack = getLatestTrack(state);
			if (latestTrack) {
				latestTrack.state = trackState;
			}
		},
		setCurrentTrackingTrackEndTimestamp(state, timestamp: number) {
			const latestTrack = getLatestTrack(state);
			if (latestTrack) {
				latestTrack.endTimestamp = timestamp;
			}
		},
		setCurrentTrackingEndDateTime(state, timestamp: number) {
			state.currentTracking.endTracking = timestamp;
		},
		setStartAddressToCurrentTracking(state, startAddress: MobiPointsGeoCodingAddressInterface|null) {
			if (startAddress) {
				state.currentTracking.startAddress = startAddress;
			}
		},
		setStartCoordinate(state, startCoordinate: MobiPointsTrackingCoordinateCoordinateInterface|null) {
			if (startCoordinate) {
				state.currentTracking.startCoordinate = startCoordinate;
			}
		},
		setEndAddressToCurrentTracking(state, endAddress: MobiPointsGeoCodingAddressInterface|null) {
			if (endAddress) {
				state.currentTracking.endAddress = endAddress;
			}
		},
		setEndCoordinate(state, endCoordinate: MobiPointsTrackingCoordinateCoordinateInterface|null) {
			if (endCoordinate) {
				state.currentTracking.endCoordinate = endCoordinate;
			}
		},
		setDuration(state: any, duration: number) {
			state.currentTracking.duration = duration;
		},
		setDistance(state: any, distance: number) {
			state.currentTracking.distance = distance;
		},
		setPositionWatchId(state: any, positionWatchId: number|null) {
			state.positionWatchId = positionWatchId;
		},
		setTrackingAutoStopIntervalId(state: any, trackingAutoStopIntervalId: number|null) {
			state.trackingAutoStopIntervalId = trackingAutoStopIntervalId;
		},
		trackings(state: any, value: Array<MobiPointsTrackingTracking>) {
			state.trackings.loaded = true;
			state.trackings.data = value;
		},
		resetTrackings(state: any) {
			// resetTrackings(state);
		},
	},
	actions: {
		//TODO check
		calculateDuration( { commit, state, getters }) {
			const duration = getters.getCurrentTracking.calculateDuration();
			commit('setDuration', duration);
		},
		calculateDistance( { commit, state, getters }) {
			const distance = getters.getCurrentTracking.calculateDistance();
			commit('setDistance', distance);
		}
	},
	getters: {
		getTrackings: (state: any) => {
			return state.trackings;
		},
		getCurrentTrackingType: (state: any) => {
			return state.currentTracking.type ?? null;
		},
		getCurrentTrackingTrackType: (state: any, getters) => {
			return getters.getCurrentTracking.getLatestTrack()?.type ?? null;
		},
		getCurrentTrackingSubType: (state: any, getters) => {
			return getters.getCurrentTracking.getLatestTrack()?.subType ?? null;
		},
		getCurrentTrackingWeatherItem: (state: any, getters) => {
			return getters.getCurrentTracking.weatherItem ?? null;
		},
		getUserTrackingUuid: (state: any) => {
			return state.currentTracking.uuid ?? null;
		},
		hasCurrentTrackingTracks: (state: any) => {
			return state.currentTracking.getTracks().length ?? false;
		},
		hasActiveTracking: (state: any, getters) => {
			return getters.getCurrentTracking && getters.getCurrentTracking.uuid && getters.getCurrentTracking.type !== EVENT_TYPE_END_TRACKING;
		},
		hasLastTracking: (state: any) => {
			return state.lastTrackings && state.lastTrackings.length > 0;
		},
		hasActiveTrack: (state: any, getters) => {
			return getters.getCurrentTracking && getters.getCurrentTracking.uuid && !getters.getCurrentTracking.isLatestTrackStopped();
		},
		hasStartAddress: (state: any) => {
			return state.currentTracking && state.currentTracking.startAddress;
		},
		hasEndAddress: (state: any) => {
			return state.currentTracking && state.currentTracking.endAddress;
		},
		hasInitialCoordinate: (state: any) => {
			return state.currentTracking && state.initialCoordinateSend;
		},
		getCurrentTracking: (state: any): MobiPointsTrackingTracking|null => {
			if (Object.keys(state.currentTracking).length === 0) {
				return null;
			} else {
				return getTrackingFactory().createTrackingByObject(state.currentTracking);
			}
		},
		getLastTrackings: (state: any): Array<MobiPointsTrackingTracking> => {
			const result: Array<MobiPointsTrackingTracking> = [];
			if (Object.keys(state.lastTrackings).length > 0) {
				for (const lastTracking of state.lastTrackings) {
					result.push(getTrackingFactory().createTrackingByObject(lastTracking));
				}
			}

			return result;
		},
		getLastTracking: (state: any): MobiPointsTrackingTracking|null => {
			if (state.lastTrackings && state.lastTrackings.length > 0) {
				const lastTracking = state.lastTrackings[state.lastTrackings.length - 1] ?? null;
				if (lastTracking) {
					return getTrackingFactory().createTrackingByObject(lastTracking);
				}
			}
			return null;
		},
		getLatestTrack: (state: any) => {
			if (state.currentTracking === null) {
				console.log("NO Active UserTracking found!");
			} else {
				if (state.currentTracking.tracks) {
					return state.currentTracking.tracks[state.currentTracking.tracks.length - 1] ?? null
				}
				return null;
			}
		},
		getCurrentTrackingOriginal: (state: any) => {
			if (Object.keys(state.currentTracking).length === 0) {
				return null;
			} else {
				return state.currentTracking;
			}
		},
		getDuration: (state: any, getters): number => {
			return state.currentTracking.duration ?? 0;
		},
		getDistance: (state: any, getters): number => {
			return state.currentTracking.distance ?? 0;
		},
		getDistanceFormatted: (state: any): number => {
			let distance = state.currentTracking.distance ?? 0;
			if (distance != 0) {
				distance = distance.toFixed(2);
			}
			return distance;
		},
		getStartAddress: (state: any): MobiPointsGeoCodingAddressInterface|null => {
			return state.currentTracking.startAddress ?? null;
		},
		getStartCoordinate: (state: any): MobiPointsTrackingCoordinateCoordinateInterface|null => {
			return state.currentTracking.startCoordinate ?? null;
		},
		getEndAddress: (state: any): MobiPointsGeoCodingAddressInterface|null => {
			return state.currentTracking.endAddress ?? null;
		},
		getEndCoordinate: (state: any): MobiPointsTrackingCoordinateCoordinateInterface|null => {
			return state.currentTracking.endCoordinate ?? null;
		},
		getUserTrackingByUuid: (state: any) => (uuid: string) => {
			return state.trackings.find((tracking: MobiPointsTrackingTracking) => tracking.uuid === uuid)
		},
		getCurrentPosition: (state: any) => {
			return state.currentPosition;
		},
		getPositionWatchId: (state: any): number|null => {
			return state.positionWatchId;
		},
		getTrackingAutoStopIntervalId: (state: any): number|null => {
			return state.trackingAutoStopIntervalId;
		},
		getUserTrackingTrackStates: (state: any) => {
			return state.userTrackingTrackStates;
		},
	}
}

export default tracking;