import {MobiPointsCoreLogInterface} from "@/mobipoints/core/log.interface";
import useLogFactory from "@/composable/useLogFactory";
import {MobiPointsCoreLog} from "@/mobipoints/core/log.type";

const {getLogFactory} = useLogFactory();


const system = {
	namespaced: true,
	state: () => ({
		internetConnection: true,
		appVersion: null as string|null,
		appActiveState: false as boolean,
		logs: [] as Array<MobiPointsCoreLogInterface>,
		backgroundTrackingActive: false as boolean,
		developerMode: false as boolean,
	}),
	mutations: {
		setInternetConnection(state: any, value: boolean) {
			state.internetConnection = value;
		},
		appVersion(state: any, value: string) {
			state.appVersion = value;
		},
		appActiveState(state: any, value: boolean) {
			state.appActiveState = value;
		},
		addLog(state: any, log: MobiPointsCoreLogInterface) {
			console.log(log);
			state.logs.push(log);
		},
		resetLogs(state: any) {
			state.logs = [];
		},
		backgroundTrackingActive(state: any, value: boolean) {
			state.backgroundTrackingActive = value;
		},
		setDeveloperMode(state:any, value: boolean) {
			state.developerMode = value;
		}
	},
	actions: {},
	getters: {
		getInternetConnection: (state: any) => {
			return state.internetConnection;
		},
		appVersion: (state: any) => {
			return state.appVersion;
		},
		appActiveState: (state: any) => {
			return state.appActiveState;
		},
		backgroundTrackingActive: (state: any) => {
			return state.backgroundTrackingActive;
		},
		getLogs: (state: any) => {
			return state.logs.map((data) => {
				if (!(data instanceof MobiPointsCoreLog)) {
					return getLogFactory().createLogByInterface(data);
				}
			});

		},
		getLogsAsString: (state: any) => {
			let result = "";
			state.logs.forEach((log: MobiPointsCoreLogInterface) => {
				result += 'TIME: ' + log.timestamp + ', MESSAGE: ' + log.message + ', TRACE: ' + log.trace + '\n\n';
			});
			return result;
		},
		hasDeveloperMode: (state: any) => {
			return state.developerMode;
		},
	}
}

export default system;