import {
	MobiPointsQueueEventAbstractEvent,
	EVENT_STATE_NEW,
	EVENT_TYPE_ADD_COORDINATES,
} from "@/mobipoints/queue/event/abstract_event.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";

export class MobiPointsQueueEventTypeCoordinatesEvent extends MobiPointsQueueEventAbstractEvent {

	constructor(userTrackingUuid: string|null, eventName: string, timestamp: number, subType: string, state: string = EVENT_STATE_NEW, payload: PayloadInterface = {data: {Coordinate: MobiPointsTrackingCoordinateCoordinate}}) {
		super(userTrackingUuid, eventName, timestamp, subType, state, payload);
		// this.setPayloadData({"coordinate": coordinate});
	}

	getEventType(): string {
		return EVENT_TYPE_ADD_COORDINATES;
	}

}
