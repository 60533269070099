<template>
	<div><button @click="logout">Abmelden</button></div>
</template>

<script lang="ts">
import {onBeforeMount} from "vue";
import {
} from '@ionic/vue';
import {UserAuth} from "@/mobipoints/auth/user_auth";

export default {
	name: 'Logout',
	components: {
	},
	setup() {
		function logout() {
			const userAuth = new UserAuth();
			userAuth.logout();
		}

		onBeforeMount(() => {
			logout();
		});

		return {logout}
	}
}
</script>

<style>
</style>