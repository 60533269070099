<template>
	<ion-page>
		<ion-content class="ion-padding">
			<div v-if="!showSuccessMessage">
				<p style="text-align: center">{{forgotPasswordTitle}}</p>
				<div>
					<form @submit.prevent="forgotPassword">
						<ion-card class="ion-no-padding ion-no-margin">
							<span class="error ion-padding" v-if="nameError">{{ nameError }}</span>
							<ion-item>
								<ion-label position="stacked">E-Mail</ion-label>
								<ion-input v-model="forgotPasswordData.email" placeholder="E-Mail" type="email"
										   autocomplete="email" required="true" tabindex="2" @keyup.enter="forgotPassword"></ion-input>
							</ion-item>
							<span class="error ion-padding" v-if="emailError">{{ emailError }}</span>
							<ion-button class="basic-login-btn" expand="block" type="submit"
										style="min-height: 65px; margin-bottom: -5px; margin-top: 20px">
								<p class="basic-login-text">Passwort vergessen</p>
							</ion-button>
						</ion-card>
					</form>
				</div>

				<div style="text-align: center" class="ion-padding">
					<p>Bereits registriert?
						<router-link to="/login">
							<a class="link-text-color">Melde dich an</a>
						</router-link>
					</p>
				</div>
			</div>
			<div v-if="showSuccessMessage">
				<div style="text-align: center" class="ion-padding">
					<h1 v-html="registerSuccessTitle"/>
					<login-success-animation style="max-height: 30rem; width: 100%"></login-success-animation>
					<p v-html="getRegisterSuccessBody()"/>
					<p>{{registerGoBackTo}}
						<router-link to="/login">
							<a class="link-text-color"> Login</a>
						</router-link>
					</p>
				</div>
			</div>
		</ion-content>

		<ion-footer class="ion-padding" v-if="!showSuccessMessage">
			<p style="margin: auto;text-align: center;">
				<span>
					<router-link to="/privacy-crudafoch">
						<a style="cursor: pointer" class="ion-color-medium">Datenschutz</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/terms">
						<a style="cursor: pointer">AGB</a>
					</router-link>
				</span>
				<span style="padding-left: 5px; padding-right: 5px">|</span>
				<span>
					<router-link to="/impress">
						<a style="cursor: pointer">Impressum</a>
					</router-link>
				</span>
			</p>
			<p style="margin: auto;text-align: center; padding-bottom: 15px; font-size: 7px">
				Version: {{ appVersion }}
			</p>
			<mobility-logo></mobility-logo>
		</ion-footer>
	</ion-page>
</template>

<script lang="ts">
import {ref} from "vue";
import {
	IonContent,
	IonFooter,
	IonPage,
	IonInput,
	IonLabel,
	loadingController,
	IonButton,
	IonCard,
	onIonViewWillEnter,
} from '@ionic/vue';
import MobilityLogo from "@/components/logo/MobilityLogo.vue";
import useSystem from "@/composable/useSystem";
import useData from "@/composable/useData";
import useAuth from "@/composable/useAuth";
import router from "@/router";
import {BasicAuthError} from "@/mobipoints/api/auth";
import {UserAuth} from "@/mobipoints/auth/user_auth";
import useToastMessage from "@/components/core/ToastMessage.vue";
import LoginSuccessAnimation from "@/components/animation/LoginSuccessAnimation.vue";

export default {
	name: 'ForgotPassword',
	components: {
		LoginSuccessAnimation,
		MobilityLogo, IonContent, IonPage, IonFooter, IonInput, IonLabel, IonButton, IonCard
	},
	setup() {
		const {getTextValueByKey} = useData();
		const {appVersion} = useSystem();
		const loginTitle = ref(getTextValueByKey('login.title', [], 'Willkommen bei den MobiPoints'));
		const {isAuthenticated} = useAuth();
		const loadingTimeout = ref(1000);
		const nameError = ref("");
		const emailError = ref("");
		const passwordError = ref("");
		const passwordHover = ref(false);
		const forgotPasswordData: any = ref({email: ""});
		const showSuccessMessage = ref(false);
		const showErrorMessage = ref(false);
		const {openToast} = useToastMessage();
		const registerSuccessTitle = ref(getTextValueByKey('forgot_password.success.title', [], 'PASSWORT ZURÜCKSETZTEN ANFRAGE ERFOLGREICH GESENDET'));
		const registerGoBackTo = ref(getTextValueByKey('forgot_password.success.go_back', [], 'Zurück zum'));
		const forgotPasswordTitle = ref(getTextValueByKey('forgot_password.title', [], 'Passwort vergessen?'));

		function getRegisterSuccessBody(): string {
			return getTextValueByKey('forgot_password.success.body', [{
				key: 'Email',
				value: forgotPasswordData.value.email
			}], 'Es wurde ein Link an deine E-Mail Adresse <i>{Email}</i> gesendet.<br>Bitte verifiziere, dass du dein Passwort zurücksetzten möchtest!')
		}

		async function presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: '',
					message: 'Bitte warten...wir überprüfen gerade deine E-Mail Adresse!',
					duration: loadingTimeout.value,
				});

			await loading.present();

			setTimeout(function () {
				loading.dismiss()
			}, loadingTimeout.value);
		}

		onIonViewWillEnter(() => {
			if (isAuthenticated.value) {
				router.push('home');
			}
		});

		async function handleForgotPasswordError(error: any | BasicAuthError) {
			try {
				if (error instanceof BasicAuthError) {
					if (error.errorFields) {
						error.errorFields.forEach((errorField: any = {name: '', message: ''}) => {
							if (errorField.name == 'email') {
								emailError.value = errorField.message;
							}
						});
					}
					if (error.message) {
						await openToast(error.message, 'danger', 'top', true, 10000, undefined, true);
					}
				} else {
					await openToast(getTextValueByKey('forgot_password.general.error', [], 'Unbekannter Fehler beim zurücksetzten!'), 'danger', 'top', true, 5000, undefined, true);
				}
			} catch (error) {
				await openToast(getTextValueByKey('forgot_password.general.error', [], 'Unbekannter Fehler beim zurücksetzten!'), 'danger', 'top', true, 5000, undefined, true);
			}
		}

		function hasValidForgotPasswordData(): boolean {
			let result = true;
			emailError.value = "";
			if (!forgotPasswordData.value.email) {
				emailError.value = 'Bitte trag eine gültige E-Mail Adresse ein!';
				result = false;
			}

			return result;
		}

		async function forgotPassword(): Promise<void> {
			try {
				if (hasValidForgotPasswordData()) {
					await presentLoading();
					const userAuth = new UserAuth();
					const userForgotPasswordData = {
						email: forgotPasswordData.value.email,
					}
					const result = await userAuth.forgotPassword(userForgotPasswordData);
					if (result) {
						showSuccessMessage.value = true;
					} else {
						showSuccessMessage.value = false;
						showErrorMessage.value = true;
					}
				}
			} catch (error: any | BasicAuthError) {
				console.log(error);
				await handleForgotPasswordError(error);
			}
		}

		return {
			appVersion,
			loginTitle,
			forgotPasswordData,
			nameError,
			emailError,
			passwordError,
			passwordHover,
			showSuccessMessage,
			showErrorMessage,
			registerSuccessTitle,
			getRegisterSuccessBody,
			registerGoBackTo,
			forgotPassword,
			forgotPasswordTitle,
		}
	}
}
</script>

<style>
.loginHeader {
    text-align: center;
    color: var(--ion-text-color);
}

.basic-login-text {
    font-family: Rubik, sans-serif !important;
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.basic-login-btn {
    background-color: transparent;
    box-shadow: none;
    margin-left: -2px;
    margin-right: -2px;
    /*box-shadow: 0 0 .35em .175em rgba(var(--ion-color-dark-rgb), .33);*/
    /*-webkit-box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
    /*box-shadow: 0px 29px 26px -1px rgba(0,0,0,0.77);*/
}

.link-text-color {
    cursor: pointer; 
	font-weight: bold;
	color: var(--ion-color-dark);
}

.error {
    color: #e74848;
}

.noErrorMargin {
    margin-top: -3px;
}

.addTopMargin {
    margin-top: 20px
}
</style>