<template>
	<span>{{formatDuration()}}</span>
</template>

<script lang="ts">
import {ref, watch, onMounted, onUnmounted} from "vue";
import useTracking from "@/composable/useTracking";
import {formatSecondsNice} from "@/mobipoints/core/date";
import useSystem from "@/composable/useSystem";
import {TRACK_STATE_NEW} from "@/mobipoints/tracking/track/abstract_track.type";

export default {
	name: 'TrackingDuration',
	components: {},
	props: {},
	setup() {
		const duration = ref(0.0);
		const timerInterval = ref();
		const {currentTracking, hasActiveTrack, getDuration, setDuration} = useTracking();

		const {appActiveState} = useSystem();

		function isTrackRunning(): boolean
		{
			let result = true;
			let latestTrackState: any|string = null;
			if (currentTracking.value && currentTracking.value?.getLatestTrack()) {
				latestTrackState = currentTracking.value?.getLatestTrack()?.state;
			}
			if (latestTrackState && latestTrackState !== TRACK_STATE_NEW) {
				result = false;
			}
			return result;
		}

		watch(() => appActiveState.value, (newValue: boolean, oldValue: boolean) => {
			if (newValue && currentTracking.value) {
				duration.value = currentTracking.value.calculateDuration();
				setDuration(duration.value);
				if (!timerInterval.value && isTrackRunning()) {
					startTimerInterval();
				}
			}
		});

		watch(() => hasActiveTrack.value, (newValue: boolean, oldValue: boolean) => {
			if (newValue && currentTracking.value) {
				duration.value = currentTracking.value.calculateDuration();
				setDuration(duration.value);
				if (!timerInterval.value && isTrackRunning()) {
					startTimerInterval();
				}
			} else {
				stopTimerInterval();
			}
		});

		function startTimerInterval() {
			timerInterval.value = setInterval(() =>  {
				duration.value++
			}, 1000)
		}

		function stopTimerInterval() {
			clearInterval(timerInterval.value);
			timerInterval.value = null;
		}

		function formatDuration(): string
		{
			return formatSecondsNice(duration.value);
		}

		onMounted(() => {
			if (currentTracking.value) {
				duration.value = currentTracking.value.calculateDuration();
				setDuration(duration.value);
				if (hasActiveTrack.value && isTrackRunning()) {
					startTimerInterval();
				}
			}
		})

		onUnmounted(() => {
			stopTimerInterval();
		})
		return {
			duration,
			formatDuration,
			getDuration,
			appActiveState
		}
	}
}
</script>

<style scoped>

</style>