import {
	MobiPointsTrackingTrackAbstractTrack,
	TRACK_STATE_NEW, TRACK_TYPE_E_CAR,
} from "@/mobipoints/tracking/track/abstract_track.type";
import {PayloadInterface} from "@/mobipoints/core/payload.interface";

export class MobiPointsTrackingTrackTypeECarTrack extends MobiPointsTrackingTrackAbstractTrack {

	constructor(uuid: string, trackName: string, timestamp: number, state: string = TRACK_STATE_NEW, payload: PayloadInterface = {data: {}}, endTimestamp?: number) {
		super(uuid, trackName, timestamp, state, payload, endTimestamp);
	}

	getTrackType(): string {
		return TRACK_TYPE_E_CAR;
	}

	getTrackSubType(): string {
		return "";
	}

}