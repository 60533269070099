import {
	MobiPointsQueueEventAbstractEvent,
	EVENT_TYPE_END_TRACK
} from "@/mobipoints/queue/event/abstract_event.type";

export class MobiPointsQueueEventTypeEndTrackEvent extends MobiPointsQueueEventAbstractEvent
{
	getEventType(): string {
		return EVENT_TYPE_END_TRACK;
	}

}