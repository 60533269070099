import {computed, ComputedRef} from 'vue';
import store from '@/store';
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsTrackingTracking} from "@/mobipoints/tracking/tracking.type";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {
	EVENT_TYPE_END_TRACK,
	EVENT_TYPE_END_TRACKING,
	EVENT_TYPE_START_TRACK
} from "@/mobipoints/queue/event/abstract_event.type";
import {
	MobiPointsTrackingTrackAbstractTrack, TRACK_STATE_DONE, TRACK_STATE_NEW, TRACK_STATE_PAUSE, TRACK_TYPE_BICYCLE,
} from "@/mobipoints/tracking/track/abstract_track.type";
import {UserTrackingTrackType} from "@/mobipoints/profile/userTrackingTrackType/user_tracking_track_type.type";
import useEvent from "@/composable/useEvent";
import {getDateDiffInSeconds} from "@/mobipoints/core/date";
import useMainData from "@/composable/useMainData";
import {MobiPointsGeoCodingAddressInterface} from "@/mobipoints/geocoding/address.interface";
import useGeoCodingFactory from "@/composable/useGeoCodingFactory";
import useToastMessage from "@/components/core/ToastMessage.vue";
import useData from "@/composable/useData";
import {loadingController} from '@ionic/vue';
import useSystem from "@/composable/useSystem";
import {MobiPointsTrackingWeatherWeather} from "@/mobipoints/tracking/weather/weather.type";
import {DistanceCalculation} from "@/mobipoints/tracking/geolocation/distance_calculation";

export default function useTracking() {
	const userTrackingTrackStates: ComputedRef<Array<string>> = computed(() => store.getters['tracking/getUserTrackingTrackStates']);
	const trackings: ComputedRef<Array<string>> = computed(() => store.getters['tracking/getTrackings']);
	const currentTracking: ComputedRef<MobiPointsTrackingTracking|null> = computed(() => store.getters['tracking/getCurrentTracking']);
	const lastTracking: ComputedRef<MobiPointsTrackingTracking|null> = computed(() => store.getters['tracking/getLastTracking']);
	const lastTrackings: ComputedRef<Array<MobiPointsTrackingTracking>|null> = computed(() => store.getters['tracking/getLastTrackings']);
	const getLatestTrack: ComputedRef<MobiPointsTrackingTrackAbstractTrack|null> = computed(() => store.getters['tracking/getLatestTrack']);
	const startAddress: ComputedRef<MobiPointsGeoCodingAddressInterface|null> = computed(() => store.getters['tracking/getStartAddress']);
	const startCoordinate: ComputedRef<MobiPointsTrackingCoordinateCoordinateInterface|null> = computed(() => store.getters['tracking/getStartCoordinate']);
	const endAddress: ComputedRef<MobiPointsGeoCodingAddressInterface|null> = computed(() => store.getters['tracking/getEndAddress']);
	const endCoordinate: ComputedRef<MobiPointsTrackingCoordinateCoordinateInterface|null> = computed(() => store.getters['tracking/getEndCoordinate']);
	const hasActiveTracking: ComputedRef<boolean> = computed(() => store.getters['tracking/hasActiveTracking']);
	const hasLastTracking: ComputedRef<boolean> = computed(() => store.getters['tracking/hasLastTracking']);
	const hasActiveTrack: ComputedRef<boolean> = computed(() => store.getters['tracking/hasActiveTrack']);
	const hasStartAddress: ComputedRef<boolean> = computed(() => store.getters['tracking/hasStartAddress']);
	const hasEndAddress: ComputedRef<boolean> = computed(() => store.getters['tracking/hasEndAddress']);
	const hasInitialCoordinate: ComputedRef<boolean> = computed(() => store.getters['tracking/hasInitialCoordinate']);
	const getDuration: ComputedRef<number> = computed(() => store.getters['tracking/getDuration']);
	const getDistance: ComputedRef<number> = computed(() => store.getters['tracking/getDistance']);
	const getDistanceFormatted: ComputedRef<number> = computed(() => store.getters['tracking/getDistanceFormatted']);
	const getCurrentPosition: ComputedRef<MobiPointsTrackingCoordinateCoordinateInterface> = computed(() => store.getters['tracking/getCurrentPosition']);
	const getCurrentTrackingWeatherItem: ComputedRef<MobiPointsTrackingWeatherWeather|null> = computed(() => store.getters['tracking/getCurrentTrackingWeatherItem']);
	const getCurrentTrackingType: ComputedRef<string> = computed(() => store.getters['tracking/getCurrentTrackingType']);
	const getCurrentTrackingSubType: ComputedRef<string> = computed(() => store.getters['tracking/getCurrentTrackingSubType']);
	const getCurrentTrackingTrackType: ComputedRef<string> = computed(() => store.getters['tracking/getCurrentTrackingTrackType']);
	const getPositionWatchId: ComputedRef<number|null> = computed(() => store.getters['tracking/getPositionWatchId']);
	const getTrackingAutoStopIntervalId: ComputedRef<number|null> = computed(() => store.getters['tracking/getTrackingAutoStopIntervalId']);

	const {getTrackingFactory} = useTrackingFactory();
	const {getGeoCodingFactory} = useGeoCodingFactory();

	function createTrackingAndSetAsActiveTracking(uuid: string, trackingName = "") {
		const tracking = getTrackingFactory().createTracking(uuid, trackingName);
		setCurrentTrackingInitialCoordinateSend(false);
		setStateToCurrentTrack(TRACK_STATE_NEW);
		return setCurrentTracking(tracking);
	}

	function setCurrentTracking(tracking: MobiPointsTrackingTracking) {
		store.commit('tracking/setCurrentTracking', tracking)
	}

	function setCurrentTrackingInitialCoordinateSend(value: boolean) {
		store.commit('tracking/setInitialCoordinateSend', value)
	}

	function addTrackToCurrentTracking(trackingTrack: MobiPointsTrackingTrackAbstractTrack) {
		store.commit('tracking/addTrackToCurrentTracking', trackingTrack);
	}

	function setCurrentPosition(currentPosition: MobiPointsTrackingCoordinateCoordinateInterface) {
		store.commit('tracking/setCurrentPosition', currentPosition)
	}

	function resetCurrentTracking() {
		store.commit('tracking/resetCurrentTracking');
		setCurrentTrackingInitialCoordinateSend(false);
	}

	function setStateToCurrentTrack(state: string) {
		store.commit('tracking/setCurrentTrackingTrackState', state);
	}

	function setEndTimestampToCurrentTrack() {
		store.commit('tracking/setCurrentTrackingTrackEndTimestamp', useTrackingFactory().getTrackingFactory().createCurrentTimeStamp());
	}

	function setCurrentTrackingEndDateTime() {
		store.commit('tracking/setCurrentTrackingEndDateTime', useTrackingFactory().getTrackingFactory().createCurrentTimeStamp());
	}

	function setCurrentTrackingWeatherItem(weatherItem: MobiPointsTrackingWeatherWeather) {
		store.commit('tracking/setCurrentTrackingWeatherItem', weatherItem);
	}

	function setStartAddressToCurrentTracking(startAddress: MobiPointsGeoCodingAddressInterface|null) {
		if (startAddress && startAddress.latitude && startAddress.longitude) {
			const startCoordinate = useTrackingFactory().getTrackingFactory().createCoordinate(startAddress.latitude, startAddress.longitude);
			setStartCoordinates(startCoordinate);
		}
		store.commit('tracking/setStartAddressToCurrentTracking', startAddress);
	}

	function setStartCoordinates(startCoordinate: MobiPointsTrackingCoordinateCoordinateInterface|null) {
		store.commit('tracking/setStartCoordinate', startCoordinate);
	}

	function setEndCoordinates(endCoordinate: MobiPointsTrackingCoordinateCoordinateInterface|null) {
		store.commit('tracking/setEndCoordinate', endCoordinate);
	}

	function setEndAddressToCurrentTracking(endAddress: MobiPointsGeoCodingAddressInterface|null) {
		if (endAddress && endAddress.latitude && endAddress.longitude) {
			const endCoordinate = useTrackingFactory().getTrackingFactory().createCoordinate(endAddress.latitude, endAddress.longitude);
			setEndCoordinates(endCoordinate);
		} else if (!endAddress) {
			setEndCoordinates(getCurrentPosition.value);
		}
		store.commit('tracking/setEndAddressToCurrentTracking', endAddress);
	}

	function setStartAddressByCurrentPositionToCurrentTracking(forceUpdate = false) {
		if ( (!hasStartAddress.value || forceUpdate) && hasValidCurrentPosition()) {
			const startAddress = getGeoCodingFactory().getNearestLocationByCoordinate(getCurrentPosition.value);
			setStartAddressToCurrentTracking(startAddress);
			if (!startAddress) {
				setStartCoordinates(getCurrentPosition.value);
			}
		}
	}

	function addCurrentTrackingToLastTrackings() {
		if (currentTracking.value) {
			const lastTracking = currentTracking.value;
			//TODO createLastTrackingByCurrentTracking -- useTrackingFactory().getTrackingFactory().createLastTrackingByCurrentTracking()
			//TODO calc duration, distance, from-to pos
			//TODO extend MobiPointsTrackingTracking with duration, distance and from - to
			store.commit('tracking/addLastTracking', lastTracking);
		}
	}

	function jumpToLastActiveCard() {
		try {
			const element = document.getElementById("last-active-tracking-component");
			if (element) {
				const elementPosition = element.offsetTop;
				window.scrollTo({
					top: (elementPosition-10),
					behavior: "smooth"
				});
			}
		} catch (e) {
			console.log(e)
		}
	}

	function jumpToActiveCard() {
		const element = document.getElementById("active-tracking-component");
		if (element) {
			const elementPosition = element.offsetTop;
			window.scrollTo({
				top: (elementPosition-10),
				behavior: "smooth"
			});
		}
	}

	async function hasGeolocationPermissions(autoHandlePermission = false)
	{
		return await getTrackingFactory().createBackgroundGeolocationPermission().checkPermissions(autoHandlePermission);
	}

	async function handleGeolocationPermission()
	{
		return await getTrackingFactory().createBackgroundGeolocationPermission().handlePermission();
	}

	async function checkGeolocationPermissions()
	{
		let result = true;
		const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: useData().getTextValueByKey('tracking.gps.check', [], 'Bitte warten, wir überprüfen gerade deine GPS Position...'),
			});
		setTimeout(function() {
			loading.dismiss()
		}, 15000);
		await loading.present();

		try {
			if (!await hasGeolocationPermissions()) {
				if (!await handleGeolocationPermission()) {
					await useToastMessage().openToast(useData().getTextValueByKey('tracking.gps.deactivated', [], 'Bitte aktiviere dein GPS!'), 'danger', 'top', true, 15000, undefined, true);
					result = false;
				}
			}
		} catch (error) {
			//
		} finally {
			loading.dismiss();
		}

		return result;
	}

	async function stopCurrentTracking() {
		if (currentTracking.value && currentTracking.value.getLatestTrackType()) {
			if (!useSystem().isNativePlatform() || useData().getSystemValueByKey('feature_flag_tracking_stop_send_last_geolocation', true)) {
				const loading = await loadingController
					.create({
						cssClass: 'my-custom-class',
						message: useData().getTextValueByKey('tracking.stop.locate.last.gps.position', [], 'Bitte warte kurz, wir versuchen deine letzte Position zu ermitteln.📍'),
					});
				try {
					await loading.present();
					const currentPos: MobiPointsTrackingCoordinateCoordinateInterface|undefined = await getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition(useData().getSystemValueByKey('feature_flag_tracking_stop_geolocation_maximum_age', Infinity));
					if (currentPos && currentPos.longitude && currentPos.latitude) {
						setCurrentPosition(currentPos);
					}
				} catch (e) {
					console.log(e);
					useSystem().addLog(JSON.stringify(e));
				} finally {
					await loading.dismiss();
				}
			}
			const loading = await loadingController
				.create({
					cssClass: '',
					message: useData().getTextValueByKey('stop.tracking.loading.text', [], 'Dein Tracking wird übertragen...'),
				});
			setTimeout(function() {
				loading.dismiss()
			}, 15000);
			try {
				await loading.present();
				await useEvent().addEventByType(EVENT_TYPE_END_TRACK, currentTracking.value.getLatestTrackType(), currentTracking.value.uuid);
				await useEvent().addEventByType(EVENT_TYPE_END_TRACKING, currentTracking.value.getLatestTrackType(), currentTracking.value.uuid);
				store.commit('tracking/setCurrentTrackingType', EVENT_TYPE_END_TRACKING);
				setStateToCurrentTrack(TRACK_STATE_DONE);
				setEndTimestampToCurrentTrack();
				setCurrentTrackingEndDateTime();
				if (!hasEndAddress.value) {
					const endAddress = getGeoCodingFactory().getNearestLocationByCoordinate(getCurrentPosition.value);
					setEndAddressToCurrentTracking(endAddress);
				}
				calculateDuration();
				calculateDistance_for_CurrentTrack();
				addCurrentTrackingToLastTrackings();
				resetCurrentTracking();
				return stopBackgroundGeolocation();
			} catch (e) {
				console.log(e);
				useSystem().addLog(JSON.stringify(e));
				throw e;
			} finally {
				await loading.dismiss();
			}
		}
	}

	async function pauseCurrentTracking(stopBackgroundTracking = true, changeCurrentTrackState = TRACK_STATE_PAUSE) {
		if (currentTracking.value && currentTracking.value.getLatestTrackType()) {
			await useEvent().addEventByType(EVENT_TYPE_END_TRACK, currentTracking.value.getLatestTrackType(), currentTracking.value.uuid);
			store.commit('tracking/setCurrentTrackingType', EVENT_TYPE_END_TRACK);
			setEndTimestampToCurrentTrack();
			setStateToCurrentTrack(changeCurrentTrackState);
			setEndTimestampToCurrentTrack();
			if (stopBackgroundTracking) {
				return stopBackgroundGeolocation();
			}
			return true;
		}
	}

	async function continueCurrentTracking(trackType: string|null = null) {
		if (currentTracking.value && (trackType ||currentTracking.value.getLatestTrackType()) ) {
			let continueTrackType;
			if (trackType) {
				continueTrackType = trackType;
			} else {
				continueTrackType = currentTracking.value.getLatestTrackType();
			}
			await useEvent().addEventByType(EVENT_TYPE_START_TRACK, continueTrackType, currentTracking.value.uuid);
			setStateToCurrentTrack(TRACK_STATE_DONE);
			store.commit('tracking/setCurrentTrackingType', EVENT_TYPE_START_TRACK);
			const track = getTrackingFactory().createTrackByType(currentTracking.value.uuid,  continueTrackType,  continueTrackType + " - Tracking");
			addTrackToCurrentTracking(track);
			setStateToCurrentTrack(TRACK_STATE_NEW);
			return startBackgroundGeolocation();
		}
	}

	async function startCurrentTrackingTrackBy_TrackType(trackType: string, ignoreCurrentGeolocationCheck = false) {
		if (trackType && getTrackingFactory().getTrackingTypeList().includes(trackType) && currentTracking.value) {
			const currentTrackingUuid = currentTracking.value.uuid;
			await useEvent().addEventByType(EVENT_TYPE_START_TRACK, trackType, currentTrackingUuid);
			store.commit('tracking/setCurrentTrackingType', EVENT_TYPE_START_TRACK);
			store.commit('tracking/setCurrentTrackingTrackType', trackType);
			const track = getTrackingFactory().createTrackByType(currentTrackingUuid,  trackType,  trackType + " - Tracking");
			addTrackToCurrentTracking(track);
			if ( (!useSystem().isNativePlatform() || useData().getSystemValueByKey('feature_flag_tracking_start_send_first_geolocation', true)) && !ignoreCurrentGeolocationCheck) {
				const loading = await loadingController
					.create({
						cssClass: 'my-custom-class',
						message: useData().getTextValueByKey('tracking.start.locate.first.gps.position', [], 'Bitte warte kurz, wir versuchen deine aktuelle Position zu ermitteln.📍'),
					});
				setTimeout(function() {
					loading.dismiss()
				}, 10000);
				try {
					await loading.present();
					const currentPos: MobiPointsTrackingCoordinateCoordinateInterface|undefined = await getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition();
					if (currentPos && currentPos.longitude && currentPos.latitude) {
						setCurrentPosition(currentPos);
					}
				} catch (e) {
					console.log(e);
					useSystem().addLog(JSON.stringify(e));
				} finally {
					await loading.dismiss();
				}
			}

			return startBackgroundGeolocation().then(response => {
				useSystem().addLog('AFTER START BACKGROUND ADD FIRST CURRENT POSITION TO TRACK')
				// useEvent().addCurrentPositionEvent(currentTrackingUuid, trackType, 0, true);
				return true;
			},
			error => {
				console.log("Error start background location", error);
			});
		}
		return false;
	}

	function calculateDuration() {
		store.dispatch('tracking/calculateDuration');
	}

	function setDuration(duration: number|null) {
		store.commit('tracking/setDuration', duration)
	}

	async function calculateDistance_for_CurrentTrack() {
		store.dispatch('tracking/calculateDistance');
	}

	async function addCoordinateToLatestTrack(coordinate: MobiPointsTrackingCoordinateCoordinateInterface) {
		if (currentTracking.value && coordinate) {
			store.commit('tracking/addCoordinateToLatestTrack', coordinate);
			if (!hasInitialCoordinate.value) {
				setCurrentTrackingInitialCoordinateSend(true);
			}
			await calculateDistance_for_CurrentTrack();
		}
	}

	function startBackgroundGeolocation(forceStart = false) {
		return getTrackingFactory().createBackgroundGeolocationFacade().startGeolocationTracking(forceStart);
	}

	function stopBackgroundGeolocation() {
		return getTrackingFactory().createBackgroundGeolocationFacade().stopGeolocationTracking();
	}

	function getTrackingType_Data_Map(): Array<UserTrackingTrackType>{
		const arrTrackingType_Data_Map: Array<UserTrackingTrackType> = [];
		getTrackingFactory().getTrackingTypeList().forEach((trackingType) => {
			let style = "";
			if (trackingType === TRACK_TYPE_BICYCLE) {
				style = 'margin-bottom: 10px';
			}
			const userTrackingTrackType = new UserTrackingTrackType(trackingType, trackingType, style);
			arrTrackingType_Data_Map.push(userTrackingTrackType);
		});

		return arrTrackingType_Data_Map;
	}

	function getTrackingTypeMinimal_Data_Map(): Array<UserTrackingTrackType>{
		const arrTrackingType_Data_Map: Array<UserTrackingTrackType> = [];
		getTrackingFactory().getTrackingTypeMinimalList().forEach((trackingType) => {
			let style = "";
			if (trackingType === TRACK_TYPE_BICYCLE) {
				style = 'margin-bottom: 10px';
			}
			const userTrackingTrackType = new UserTrackingTrackType(trackingType, trackingType, style);
			arrTrackingType_Data_Map.push(userTrackingTrackType);
		});

		return arrTrackingType_Data_Map;
	}

	function getTrackingTypeAdvanced_Data_Map(): Array<UserTrackingTrackType>{
		const arrTrackingType_Data_Map: Array<UserTrackingTrackType> = [];
		getTrackingFactory().getTrackingTypeAdvancedList().forEach((trackingTypeData) => {
			const userTrackingTrackType = new UserTrackingTrackType(trackingTypeData.type, trackingTypeData.name);
			arrTrackingType_Data_Map.push(userTrackingTrackType);
		});

		return arrTrackingType_Data_Map;
	}

	function getFullTrackingTypeList(): Array<UserTrackingTrackType>{
		const arrTrackingType_Data_Map: Array<UserTrackingTrackType> = [];
		getTrackingFactory().getFullTrackingTypeList().forEach((trackingTypeData) => {
			const userTrackingTrackType = new UserTrackingTrackType(trackingTypeData.type, trackingTypeData.name);
			arrTrackingType_Data_Map.push(userTrackingTrackType);
		});

		return arrTrackingType_Data_Map;
	}

	function getTrackIconByType(trackType: string) {
		return getTrackingFactory().getTrackingIconByType(trackType);
	}

	function setPositionWatchId(positionWatchId: number|null) {
		store.commit('tracking/setPositionWatchId', positionWatchId)
	}

	function setTrackingAutoStopIntervalId(trackingAutoStopIntervalId: number|null) {
		store.commit('tracking/setTrackingAutoStopIntervalId', trackingAutoStopIntervalId)
	}


	function getDistanceCalculation(): DistanceCalculation
	{
		return getTrackingFactory().createDistanceCalculation();
	}

	function isPositionCloseToLatestPosition(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, maxDistanceInMeter = 15): boolean {
		let result = false;
		if (getCurrentPosition.value && getCurrentPosition.value.latitude) {
			result = getDistanceCalculation().isCoordinateCloseToPosition(coordinate, getCurrentPosition.value, maxDistanceInMeter)
		}
		return result;
	}

	function hasValidCurrentPosition(): boolean
	{
		return Boolean(getCurrentPosition.value !== undefined && getCurrentPosition.value.timestamp && getCurrentPosition.value.latitude && getCurrentPosition.value.longitude);
	}

	function hasCurrentPositionChanged(coordinate: MobiPointsTrackingCoordinateCoordinateInterface, ignoreTime = false): boolean
	{
		if (!hasValidCurrentPosition()){
			return true;
		}
		const geolocationIntervalInSeconds = useMainData().userProfileSettings.value.geoLocationInterval ?? Number(process.env.VUE_APP_GEOLOCATION_INTERVAL_IN_SECONDS);
		let positionChanged = false;
		const currentPositionLat = Number(getCurrentPosition.value.latitude.toFixed(7));
		const currentPositionLng = Number(getCurrentPosition.value.longitude.toFixed(7));
		const currentPositionTimestamp = getCurrentPosition.value.timestamp;

		let coordinatePositionLat: number|null = null;
		let coordinatePositionLng: number|null = null;
		if (coordinate && coordinate.latitude && coordinate.longitude) {
			coordinatePositionLat = Number(coordinate.latitude.toFixed(7));
			coordinatePositionLng = Number(coordinate.longitude.toFixed(7));
		}

		// useSystem().addLog('CURRENT POS LAT LNG       , ' + currentPositionLat + ', ' + currentPositionLng);
		// useSystem().addLog('OLD COORDINATE POS LAT LNG, ' + coordinatePositionLat + ', ' + coordinatePositionLng);
		let hasNewValidTimestamp = true;
		if (!ignoreTime) {
			hasNewValidTimestamp = getCurrentPosition.value && currentPositionTimestamp !== coordinate.timestamp && getDateDiffInSeconds(Number(coordinate.timestamp), Number(currentPositionTimestamp)) > geolocationIntervalInSeconds;
		}
		if ( ( !getCurrentPosition.value && coordinate) || ( (currentPositionLat != coordinatePositionLat || currentPositionLng != coordinatePositionLng) && hasNewValidTimestamp) ) {
			positionChanged = true;
		}
		return positionChanged;
	}

	return {
		userTrackingTrackStates,
		trackings,
		currentTracking,
		hasActiveTracking,
		hasActiveTrack,
		getDuration,
		getDistance,
		getDistanceFormatted,
		calculateDuration,
		createTrackingAndSetAsActiveTracking,
		setCurrentTracking,
		setCurrentTrackingInitialCoordinateSend,
		setCurrentPosition,
		resetCurrentTracking,
		stopCurrentTracking,
		startBackgroundGeolocation,
		stopBackgroundGeolocation,
		continueCurrentTracking,
		getCurrentPosition,
		getTrackingType_Data_Map,
		getTrackingTypeMinimal_Data_Map,
		getTrackingTypeAdvanced_Data_Map,
		getFullTrackingTypeList,
		addTrackToCurrentTracking,
		pauseCurrentTracking,
		startCurrentTrackingTrackBy_TrackType,
		getTrackIconByType,
		getCurrentTrackingType,
		calculateDistance_for_CurrentTrack,
		addCoordinateToLatestTrack,
		getCurrentTrackingSubType,
		getCurrentTrackingTrackType,
		getLatestTrack,
		getPositionWatchId,
		getTrackingAutoStopIntervalId,
		setPositionWatchId,
		setTrackingAutoStopIntervalId,
		hasCurrentPositionChanged,
		setDuration,
		isPositionCloseToLatestPosition,
		hasLastTracking,
		lastTracking,
		lastTrackings,
		setStartAddressByCurrentPositionToCurrentTracking,
		hasStartAddress,
		hasEndAddress,
		startAddress,
		startCoordinate,
		endAddress,
		endCoordinate,
		hasGeolocationPermissions,
		handleGeolocationPermission,
		checkGeolocationPermissions,
		hasInitialCoordinate,
		jumpToLastActiveCard,
		jumpToActiveCard,
		setCurrentTrackingWeatherItem,
		getCurrentTrackingWeatherItem,
		getDistanceCalculation,
	}
}
