<template>
	<ion-page>
		<ion-modal :is-open="true" :show-backdrop="true" :swipe-to-close="true" :keyboardClose="true"
				   @didDismiss="closeModalAndGoBack">
			<ion-header translucent>
				<ion-toolbar>
					<ion-title>🔒 Datenschutzrichtlinie</ion-title>
					<ion-buttons slot="end">
						<ion-button @click="dismissModal()">Schließen</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>
			<ion-content fullscreen>
				<div style="padding: 10px">
					<h1>Datenschutzerklärung für Mobi Points</h1>

					<p>Gemäß Art 13, Art 14 DSGVO sowie § 165 Abs 3 TKG informieren wir dich hier umfassend über alle Datenverarbeitungen. Bitte mach dir ein Bild darüber, welche deiner personenbezogenen Daten (im Folgenden einfach "Daten" genannt) wie und warum verarbeitet werden, wenn du die App "Mobi Points" nutzt. Welche Datenkategorien verarbeiten wir im Rahmen dieser App? Bei der Nutzung der App können folgende Datenkategorien verarbeitet werden: Endgeräte-ID, Name, E-Mail-Adresse, Foto (Wohn-)Adresse(n), GPS- bzw. Standort-Daten, Fahrzeiten, gewählte Verkehrsmittel. Wir verarbeiten diese Daten zu dem Zweck, dir diese App als Belohnungssystem zur Verfügung zu stellen, um breitenwirksame Anreize für nachhaltige Mobilität sowie ökologisches Handeln zu schaffen. Die Verarbeitung erfolgt im jeweils erforderlichen Umfang basierend auf deiner freiwilligen Einwilligung zur Nutzung dieser App (Art 6 Abs 1 lit a sowie Art 49 Abs 1 lit a - Ausnahmen für bestimmte Fälle - DSGVO) sowie unser überwiegendes berechtigtes Interesse auf die Schaffung eines dauerhaften Bewusstseins für eine nachhaltige Mobilität (Art 6 Abs 1 lit f DSGVO). Du bist nicht verpflichtet, uns deine Daten bereitzustellen, doch kannst du die App ohne Bereitstellung deiner Daten nicht nutzen. Für den Betrieb der App ist es notwendig, dass wir einige deiner Daten den folgenden Empfängern übermitteln:</p>

					<ul>
						<li>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, sowie Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, für die Nutzung des Dienstes "Google Maps" zur Anzeige von Standorten. Der Anbieter kann die erhobenen Daten verwenden, um die Anzeigen seines eigenen Werbenetzwerks zu kontextualisieren und personalisieren. Daher wurde eine Vereinbarung über die gemeinsame Verantwortlichkeit gemäß Art 26 DSGVO geschlossen: <a href="https://business.safety.google/intl/de/controllerterms/">Link zur Vereinbarung</a>.</li>
						<li>Für die Verwendung des Google-Logins werden die Scopes "Email" und "Profile" verwendet, die nur für die Anmeldung benötigt werden. Die Verarbeitung dieser Daten erfolgt ausschließlich zum Zweck der Authentifizierung und wird im jeweils erforderlichen Umfang basierend auf deiner Einwilligung zur Nutzung des Google-Logins (Art 6 Abs 1 lit a DSGVO) sowie unser überwiegendes berechtigtes Interesse an einer reibungslosen Anmeldeerfahrung (Art 6 Abs 1 lit f DSGVO) durchgeführt.</li>
					</ul>

					<p>Wie lange werden deine Daten gespeichert? Wir speichern deine Daten nur so lange, wie es für jene Zwecke erforderlich ist, für die wir deine Daten erhoben haben. In diesem Zusammenhang sind gegebenenfalls gesetzliche Aufbewahrungspflichten zu berücksichtigen.</p>

					<p>Welche Rechte stehen dir hinsichtlich der Datenverarbeitung zu? Wir möchten dich darüber informieren, dass du, sofern die gesetzlichen Voraussetzungen dafür vorliegen, die Rechte hast:</p>

					<ul>
						<li>Auskunft darüber zu verlangen, welche Daten von dir bei uns verarbeitet werden (siehe im Detail Art 15 DSGVO).</li>
						<li>Die Berichtigung oder Vervollständigung dich betreffender unrichtiger oder unvollständiger Daten zu verlangen (siehe im Detail Art 16 DSGVO).</li>
						<li>Auf Löschung deiner Daten (siehe im Detail Art 17 DSGVO), soweit dem keine Aufbewahrungspflichten entgegenstehen.</li>
						<li>Auf Einschränkung der Verarbeitung deiner Daten (siehe im Detail Art 18 DSGVO).</li>
						<li>Auf Datenübertragbarkeit - den Erhalt der von dir bereitgestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format (siehe im Detail Art 20 DSGVO).</li>
						<li>Gegen eine Verarbeitung deiner Daten, die auf Artikel 6 Absatz 1 Buchstaben e oder f DSGVO basiert, Widerspruch einzulegen (siehe im Detail Art 21 DSGVO). Sofern wir deine Daten auf Basis deiner Einwilligung verarbeiten, hast du das Recht, diese Einwilligung jederzeit zu widerrufen. Dadurch wird die Rechtmäßigkeit der bis zu diesem Zeitpunkt erfolgten Datenverarbeitung nicht beeinträchtigt (Art 7 Abs 3 DSGVO).</li>
					</ul>

					<p>Sollte es wider Erwarten zu einer Verletzung deines Rechtes auf rechtmäßige Verarbeitung deiner Daten kommen, setz dich bitte mit uns in Verbindung. Wir werden uns bemühen, dein Anliegen umgehend, spätestens jedoch innerhalb der gesetzlichen Frist von einem Monat, zu bearbeiten. Du hast zudem stets das Recht, eine Beschwerde bei der für dich zuständigen Aufsichtsbehörde für Datenschutzangelegenheiten zu erheben.</p>

					<p>Wer ist der Verantwortliche und wie kannst du mit uns Kontakt aufnehmen? Verantwortlich im Sinne des Art 4 Z 7 DSGVO für die hier dargelegten Datenverarbeitungen ist:</p>

					<p>niceshops GmbH Saaz 99 8341 Paldau Österreich office@niceshops.com, mobitaskforce@niceshops.com</p>

					<p>Geschäftsführer: Roland Fink, Mag. Christoph Schreiner, Barbara Unterkofler Die Kontaktdaten des Datenschutzbeauftragten der niceshops-Gruppe sind:</p>

					<ul>
						<li>E-Mail: privacy@niceshops.com</li>
						<li>Postalisch: niceshops GmbH, z. H. des Datenschutzbeauftragten, Annenstraße 23, 8020 Graz, Österreich.</li>
					</ul>

				</div>
			</ion-content>
		</ion-modal>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonModal, IonContent, modalController, IonButtons, IonTitle, IonHeader, IonToolbar} from '@ionic/vue';
import router from '@/router';

export default {
	name: 'Privacy',
	components: {IonPage, IonModal, IonContent, IonButtons, IonTitle, IonHeader, IonToolbar},
	setup() {
		function dismissModal() {
			modalController.dismiss();
		}

		function closeModalAndGoBack() {
			router.go(-1);
		}

		return {dismissModal, closeModalAndGoBack};
	},
};
</script>

<style>
</style>