<template>
	<div v-if="!isLoading">
		<ion-alert
				:is-open="showDialog"
				:header="alertTitle"
				:sub-header="alertSubTitle"
				:message='getAlertMessage()'
				css-class="my-custom-class"
				:buttons="getDialogButtons()"
				:backdropDismiss="false"
				@didDismiss="setDialogOpen(false)">
		</ion-alert>
	</div>
</template>

<script lang="ts">
import {onMounted, ref, watch} from "vue";
import {IonAlert, loadingController} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTracking from "@/composable/useTracking";
import useData from "@/composable/useData";
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";
import {AddressInterface, useCoordinate} from "@/composable/useCoordinate";
import {MobiPointsTrackingCoordinateCoordinateInterface} from "@/mobipoints/tracking/coordinate/coordinate.interface";
import {MobiPointApiProfile, ResponseError} from "@/mobipoints/api/profile";
import store from "@/store";
import useMainData from "@/composable/useMainData";
import useToastMessage from "@/components/core/ToastMessage.vue";
import {MobiPointsLocationTypeInterface, MobiPointsLocationTypes} from "@/mobipoints/location/location_type.interface";
import useTrackingFactory from "@/composable/useTrackingFactory";
import {MobiPointsProfileUserLocationInterface} from "@/mobipoints/profile/userLocation/user_location.interface";
import {AxiosError} from "axios";

export default {
	name: 'AddCurrentPositionToUserLocationDialog',
	components: {
		IonAlert,
	},
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
		coordinateToCheck: {
			type: MobiPointsTrackingCoordinateCoordinate,
			default: null
		},
		mapSize: {
			type: String,
			default: '400x200'
		},
		mapZoom: {
			type: String,
			default: '18'
		},
	},
	emits: ['locationAdded', 'locationAddError', 'cancel'],
	setup(props, {emit}) {
		const {
			hasActiveTracking,
			hasActiveTrack,
			currentTracking,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			getCurrentTrackingType,
			getCurrentTrackingSubType,
			getCurrentPosition,
			getCurrentTrackingTrackType,
			getDistance,
			startAddress,
		} = useTracking();
		const {getTextValueByKey} = useData();
		const googleMapsKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

		const isDialogOpen = ref(props.showDialog);
		const setDialogOpen = (state: boolean) => isDialogOpen.value = state;
		const locationAddress: AddressInterface|any = ref();
		const loadingTimeout = ref(1000);
		const isLoading = ref(true);
		const alertTitle = ref(getTextValueByKey('add.current_position_to_user_location.title.text', [], 'Tracking stoppen?'))
		const alertSubTitle = ref(getTextValueByKey('add.current_position_to_user_location.subtitle.text', [], 'Unbekannter Wohnort gefunden, es scheint als ob du deinen Wohnort nicht hinterlegt hast'))

		async function loadAddressByCoordinate() {
			const currentPosition = await getCurrentPositionToCalculateAddress();
			try {
				const loading = await loadingController
					.create({
						cssClass: '',
						message: getTextValueByKey('add.current_position_to_user_location.loading.text', [], 'Bitte warten...wir überprüfen deine aktuelle Position!'),
						duration: loadingTimeout.value,
					});

				setTimeout(function () {
					loading.dismiss()
				}, loadingTimeout.value);

				loading.present().then(() => {
					useCoordinate().getAddressFromCoordinate(currentPosition, true).then((response) => {
						locationAddress.value = response.value;
						isLoading.value = false;
						loading.dismiss();
					});
				});
			} catch(error) {
				console.log(error);
				if (!locationAddress.value) {
					locationAddress.value.latitude = currentPosition.latitude;
					locationAddress.value.longitude = currentPosition.longitude;
				}
				isLoading.value = false;
			}
		}

		function validateUserAddressData() {
			return !(!locationAddress.value || !locationAddress.value.latitude || !locationAddress.value.longitude || !locationAddress.value.city);
		}

		async function createNewUserLocationByCoordinates() {
			if (!validateUserAddressData()) {
				await useToastMessage().openToast(getTextValueByKey('add.current_position_to_user_location.validation.error', [], 'Adresse konnte nicht überprüft werden!'), "danger", "top", true, 5000, undefined, true)
			} else {
				locationAddress.value.type = useMainData().getLocationTypeByCode(MobiPointsLocationTypes.HOME_LOCATION) as MobiPointsLocationTypeInterface;
				const response = await MobiPointApiProfile.createUserLocation(locationAddress.value);
				if (response.status === 201) {
					return true;
				} else {
					await useToastMessage().openToast("Leider ist ein Fehler aufgetreten! Bitte nocheinmal probieren :)", "danger", "top")
					return false;
				}
			}
		}

		function getDialogButtons() {
			return [
				{
					text: getTextValueByKey('add.current_position_to_user_location.cancel.btn', [], 'Abbrechen'),
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						emit('cancel');
					},
				},
				{
					text: getTextValueByKey('add.current_position_to_user_location.add.btn', [], 'Hinzufügen'),
					handler: async function () {
						try {
							const result: any = await createNewUserLocationByCoordinates();
							if (result) {
								store.commit('mainData/resetUserLocations');
								const loading = await loadingController
									.create({
										cssClass: '',
										message: 'Bitte warten...deine neue Adresse wird hinzugefügt',
										duration: loadingTimeout.value,
									});

								setTimeout(function () {
									loading.dismiss()
								}, 5000);

								try {
									loading.present().then(async () => {
										const response = await MobiPointApiProfile.getUserLocation();
										const locationResult = response.data.locations;

										const list: Array<MobiPointsProfileUserLocationInterface> = [];

										locationResult.user.forEach((item) => {
											item.isEditable = true;
										});
										locationResult.userGroups.forEach((item) => {
											item.isEditable = false;
										});

										list.push(...(locationResult.user || []));
										list.push(...(locationResult.userGroups || []));

										store.commit('mainData/userLocations', list);
										await useToastMessage().openToast("Der neue Standort wurde erfolgreich gespeichert!", "success", "top");
										emit('locationAdded');
										await loading.dismiss();
									});

								} catch (error: any | AxiosError) {
									throw new ResponseError(
										error.status,
										error.error.message
									);
								}
							} else {
								emit('locationAddError');
							}
						} catch (error: any | AxiosError) {
							console.log(error.message, JSON.stringify(error))
							await useToastMessage().openToast("Der neue Standort konnte nicht gespeichert werden! Fehler: " + (error && error.message ? error.message : '') ?? 'Unbekannt', "danger", "top", true, 15000, undefined, true);
							emit('locationAddError');
						}
					},
				},
			];
		}

		function getFormattedAddress(): string
		{
			let formattedAddress = locationAddress.value.formattedAddress;
			if (!formattedAddress || formattedAddress.length === 0) {
				formattedAddress = getTextValueByKey('add.current_position_to_user_location.formattedAddress.default', [], 'Adresse unbekannt')
			}
			return formattedAddress;
		}

		function getAlertMessage(): string {
			const mapsLink = 'https://www.google.com/maps/search/?api=1&query='+getCurrentPositionCoordinateAsString();
			return getTextValueByKey('add.current_position_to_user_location.alert.message', [{
				'key': 'mapsLink',
				'value': mapsLink
			}, {'key': 'formattedAddress', 'value': getFormattedAddress()}, {
				'key': 'currentPositionImage',
				'value': showCurrentPositionAsGoogleMapImage()
			}], "<b>Möchtest</b> du deine aktuelle Position, ( <a target='_blank' href='{mapsLink}'>{formattedAddress}</a> als deinen Wohnort hinterlegen? Wenn du es nicht machst könnte es sein das dein Tracking abgelehnt wird und du keine Punkte dafür bekommst.) {currentPositionImage}");
		}

		async function getCurrentPositionToCalculateAddress(): Promise<MobiPointsTrackingCoordinateCoordinateInterface>
		{
			let currentPositionCoordinate;
			if (props.coordinateToCheck) {
				currentPositionCoordinate = props.coordinateToCheck;
			} else {
				currentPositionCoordinate = getCurrentPosition.value;
				if (!currentPositionCoordinate || !currentPositionCoordinate.latitude) {
					currentPositionCoordinate = await useTrackingFactory().getTrackingFactory().createBackgroundGeolocationFacade().loadCurrentPosition();
				}
			}
			return currentPositionCoordinate;
		}

		function getCurrentPositionCoordinateAsString(): string {
			if (props.coordinateToCheck) {
				return props.coordinateToCheck.latitude + ',' + props.coordinateToCheck.longitude;
			} else {
				return getCurrentPosition.value.latitude + ',' + getCurrentPosition.value.longitude;
			}
		}

		function showCurrentPositionAsGoogleMapImage() {
			const size = props.mapSize;
			const zoom = props.mapZoom;
			const markers = 'size:mid|color:red|'+ getCurrentPositionCoordinateAsString();
			const googleMapsImageUrl = 'https://maps.googleapis.com/maps/api/staticmap?key='+googleMapsKey+'&center='+getCurrentPositionCoordinateAsString()+'&zoom='+zoom+'&size='+size+'&markers='+markers+'&maptype=satellite';
			return '<img alt="Dein aktueller Standort '+getFormattedAddress()+'" src="'+googleMapsImageUrl+'" style="height: 20em; width: 100%"></img>';
		}

		watch(() => props.showDialog, (newValue: boolean) => {
			if (newValue) {
				loadAddressByCoordinate();
			}
		});

		onMounted(() => {
			if (props.showDialog) {
				loadAddressByCoordinate();
			}
		});

		return {
			icons,
			hasActiveTracking,
			hasActiveTrack,
			currentTracking,
			getTrackingTypeMinimal_Data_Map,
			getTrackingTypeAdvanced_Data_Map,
			getCurrentTrackingType,
			getCurrentTrackingSubType,
			getCurrentPosition,
			getCurrentTrackingTrackType,
			getDistance,
			startAddress,
			getDialogButtons,
			setDialogOpen,
			isDialogOpen,
			getAlertMessage,
			isLoading,
			alertTitle,
			alertSubTitle
		}
	}
}
</script>

<style scoped>
.triggerTrackButton {
    height: 3rem;
    width: auto;
    font-size: 0.9rem;
}

ion-card-subtitle {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}

.activeTrackingCardHeaderTitle {
    font-size: 13px;
    line-height: 18px;
}

.activeTrackingCardHeaderSubTitle {
    font-size: 15px;
    font-weight: normal;
    text-align: right;
    line-height: 18px;
}

.buttonsPosition {
    position: relative;
    float: right;
    padding-left: 7px;
    padding-right: 0;
    bottom: 10px;
    right: 0;
}

.activeTrackingButton {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    box-shadow: -2px 3px 19px 5px rgba(62, 100, 255, 0.3);
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.continueTrackingButton {
    float: right;
}

.stopActiveTrackingButton {
    float: right;
    margin-left: 7px;
}

.pauseActiveTrackingButton {
    float: right;
}

.changeTrackButton {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    box-shadow: -2px 3px 19px 5px rgba(62, 100, 255, 0.3);
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}


.smallFont {
    font-size: 16px;
}

.activeTrackingCard {
    min-height: 22em;
}

.activeTrackingCardHeader {
    padding: 0;
}

.activeTrackingTitle {
    padding-bottom: 0;
}

.back-drop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(var(--ion-color-medium-rgb), .85);
    padding-top: 4em;
    padding-bottom: 4em;
    z-index: 9;
    backdrop-filter: blur(3px);
}
</style>