<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<div class="flex" v-if="!isIosPlatform()">
					<ion-img v-if="!isIosPlatform()" slot="start" :src="niceshopsLogo"
							 class="h-8 pl-2 sm:pl-4"></ion-img>
					<ion-title>{{ treasureHuntTitle }}</ion-title>
				</div>
				<div class="flex" v-else>
					<ion-title><ion-img slot="start" :src="niceshopsLogo"
										class="ion-float-left ion-align-items-start h-8 pl-2 sm:pl-4 pr-2"></ion-img>{{ treasureHuntTitle }}</ion-title>
				</div>
				<ion-buttons v-show="true" :collapse="true" slot="end">
					<ion-button @click="refreshCollectedTickets()" class="buttonRefreshWallet">
						<ion-icon :icon="icons.refresh"></ion-icon>
					</ion-button>
					<ion-button class="buttonShowChallengeDialog"
								@click="setHasSeenTreasureHuntChallengeDialog(false)">
						<ion-icon :icon="icons.helpOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<TransitionRoot as="template" :show="!hasSeenTreasureHuntChallengeDialog && isAuthenticated">
				<TailwindDialog as="div" class="relative z-10" @close="setHasSeenTreasureHuntChallengeDialog(true)">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
									 enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100"
									 leave-to="opacity-0">
						<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
					</TransitionChild>

					<div class="fixed inset-0 z-10 overflow-y-auto">
						<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild as="template" enter="ease-out duration-300"
											 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
											 enter-to="opacity-100 translate-y-0 sm:scale-100"
											 leave="ease-in duration-200"
											 leave-from="opacity-100 translate-y-0 sm:scale-100"
											 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<DialogPanel
										class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div class="mt-3 text-center sm:mt-5">
											<DialogTitle as="h3"
														 class="text-base font-semibold leading-6 text-gray-900">
												{{ treasureHuntDialogTitle }}
											</DialogTitle>
											<div class="mt-2">
												<p class="text-sm text-gray-500"
												   v-html="treasureHuntDialogDescription"/>
											</div>
											<ion-img class="w-full h-full sm:-mt-4 sm:-mb-4"
													 :src="firstTimeTreasureHuntAnimation"
													 :alt="'test'"/>
										</div>
									</div>
									<div class="mt-3 sm:mt-4">
										<button type="button"
												class="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
												@click="setHasSeenTreasureHuntChallengeDialog(true)">
											{{ treasureHuntDialogBackButton }}
										</button>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</TailwindDialog>
			</TransitionRoot>

			<confetti-bomb-animation v-if="hasAllTicketsCollected"></confetti-bomb-animation>

			<div v-if="!isCollectEasterEggDone && !isLoading"
				 class="relative isolate flex flex-col flex-wrap justify-around items-center overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
				<div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
					 aria-hidden="true">
					<div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
						 style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"/>
				</div>
				<div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
					 aria-hidden="true">
					<div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
						 style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"/>
				</div>
				<p class="text-sm leading-6 text-gray-900 text-center flex">
					<strong class="font-semibold" v-html="easterEggBannerTitle"/>
				</p>
				<redeem-voucher-modal :voucher-type="treasureVoucherType" :show-input-field="false" title="Osterei scannen"
									  button-title="Scan"></redeem-voucher-modal>
			</div>

			<div>
				<ion-card>
					<ion-img :src="firstTimeTreasureHuntAnimation" class="h-20 p-1"
							 alt="Meine Lose"/>
					<div class="text-center p-2 text-xl"><span class="font-bold">{{
							treasureWallet?.getPointsFormatted() ?? 0
						}}</span> Lose gesammelt
					</div>
				</ion-card>

				<div class="ion-padding">
					<h3 class="text-base font-semibold leading-6 text-gray-500 dark:text-white">Heute gesammelt
						Lose
						<span :class="['pl-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-gray-800', collectedCount === 0 ? 'bg-gray-100' : '', collectedCount === 1 ? 'bg-yellow-400': '', collectedCount === 2 ? 'bg-orange-400' : '', collectedCount === 3 ? 'bg-green-300' : '', collectedCount === 4 ? 'bg-green-700' : '']">
							<span v-if="collectedCount === 4"
								  class="font-bold text-white">Alle Lose gesammelt 🎉</span><span
								v-else>{{ collectedCount }} / 4</span>
						</span>
					</h3>
					<dl class="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
						<div v-for="ticket in collectedTickets" :key="ticket.type"
							 class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
							<dt class="truncate text-lg font-medium text-gray-500">{{ ticket.name }}</dt>
							<dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900 text-center flex">
								<span v-if="ticket.done" class="flex">
									<span>Los gesammelt</span>
									<CheckCircleIcon class="h-12 w-auto text-gr-primary self-center" aria-hidden="true"/>
								</span>
								<span v-else class="flex">
									<span>noch offen</span>
									<XCircleIcon class="h-10 w-auto text-red-600 self-center"></XCircleIcon>
								</span>
							</dd>
						</div>
					</dl>
				</div>
			</div>

			<UserWallet :wallet-type="treasureWalletType" :show-icon="false" :show-stats="false" :show-badge="false" @handle-refresh="refreshCollectedTickets()"
						class="pb-12 sm:pb-0"
						:show-motivation-text="false"></UserWallet>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
  loadingController,
} from '@ionic/vue';
import UserWallet from '@/components/wallet/UserWallet.vue';
import {computed, onMounted, ref} from 'vue';
import useData from '@/composable/useData';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import {Dialog as TailwindDialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import useCustomStore from '@/composable/useCustomStore';
import useAuth from '@/composable/useAuth';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/20/solid';
import useWallet from '@/composable/useWallet';
import {WalletTreasures} from '@/mobipoints/wallet/treasures/wallet_treasures.type';
import {AxiosError} from 'axios';
import {addDays, formatDateAsIsoString, getCurrentDateAsIsoString} from '@/mobipoints/core/date';
import * as icons from 'ionicons/icons';
import RedeemVoucherModal from '@/components/voucher/RedeemVoucherModal.vue';
import {MobiPointsQueueEventTypeRedeemVoucherType} from '@/mobipoints/queue/event/type/redeem_voucher_event.type';
import useSystem from '@/composable/useSystem';
import {WalletItemState, WalletItemSubType} from '@/mobipoints/wallet/item/wallet_item.interface';
import ConfettiBombAnimation from '@/components/animation/ConfettiBombAnimation.vue';
import {MobiPointsChallengeType} from "@/mobipoints/challenge/challenge/challenge.type";

const enum TicketType {
	TREASURE_HUNT_BUSINESS_TRIP_START,
	TREASURE_HUNT_BUSINESS_TRIP_END,
	TREASURE_HUNT_EAT_VEGGIE,
	TREASURE_HUNT_COLLECT_EASTER_EGG,
}

interface CollectedTickets {
	type: TicketType,
	done: boolean,
	name: string,
	icon?: any,
	color?: string,
}

export default {
	name: 'TreasureHuntTab',
	computed: {
		icons() {
			return icons;
		},
	},
	components: {
			ConfettiBombAnimation,
		RedeemVoucherModal,
		IonIcon,
		IonButton, IonButtons,
		IonImg, UserWallet, IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
		TailwindDialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckCircleIcon, XCircleIcon, IonCard,
	},
	setup(props: any) {
		const {setHasSeenTreasureHuntChallengeDialog, hasSeenTreasureHuntChallengeDialog} = useCustomStore();
		const {isAuthenticated} = useAuth();
		const {wallets, initWallets, filterWalletItems} = useWallet();
		const {isIosPlatform} = useSystem();
		const treasureWallet = ref<WalletTreasures>();
		const isLoading = ref(true);

		const firstTimeTreasureHuntAnimation = ref('/assets/img/treasure_hunt/treasure_without_bg_min.gif');
		const niceshopsLogo = ref('/assets/img/treasure_hunt/nice_logo.png');
		const treasureWalletType = WalletType.TREASURES;
		const treasureVoucherType = MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE;
		const treasureHuntTitle = ref(useData().getTextValueByKey('mobipoints.treasurehunt.title', [], 'Treasure Hunt'));
		const treasureHuntDialogTitle = ref(
				useData().getTextValueByKey('mobipoints.treasurehunt.dialog.title', [], 'Treasure Hunt'));
		const treasureHuntDialogDescription = ref(useData().
		getTextValueByKey('mobipoints.treasurehunt.dialog.description', [],
				'🌟 Willkommen zur niceshops Treasure Hunt! 🌟' +
				'<br><br>' +
				'🔍 Schnapp dir deine Chance, tolle Preise zu gewinnen! 🔍' +
				'<br><br>' +
				'🚲 Ein Los für jeden, der "Grün ins Büro" kommt - sei es mit dem Fahrrad, den öffentlichen Verkehrsmitteln oder auf andere umweltfreundliche Weise!' +
				'<br><br>' +
				'🌿 Ein weiteres Los für diejenigen, die "Grün nach Hause" fahren - sei es zu Fuß, mit dem Fahrrad, dem Zug oder einer anderen umweltfreundlichen Option!' +
				'<br><br>' +
				'🥗 Genieße ein Veggie-Menü in unserer niceshops-Kantine und hol dir ein zusätzliches Los!' +
				'<br><br>' +
				'🥚 Finde das versteckte gültige Osterei mit dem QR-Code im Unternehmen und scanne ihn - aber pass auf, es könnte auch eine Niete sein!' +
				'<br><br>' +
				'🎉 Sammle bis zu 4 Lose am Tag und erhöhe deine Chancen auf großartige Gewinne! Je mehr Lose du sammelst, desto höher ist deine Gewinnchance bei der Verlosung! 🎉' +
				'<br><br>' +
				'Viel Spaß beim Entdecken und viel Glück suchen! 🏆✨'));
		const treasureHuntDialogBackButton = ref(
				useData().getTextValueByKey('mobipoints.treasurehunt.dialog.back.button.title', [], 'Los geht\'s'));
		const easterEggBannerTitle = ref(useData().
		getTextValueByKey('mobipoints.treasurehunt.banner.easteregg.title', [],
				'🔍 Suche jetzt das Osterei und gewinne! 🥚✨'));

		const collectedTickets = ref<Array<CollectedTickets>>([
			{
				type: TicketType.TREASURE_HUNT_BUSINESS_TRIP_START,
				done: false,
				name: useData().getTextValueByKey('mobipoints.treasurehunt.business_trip_start.title', [], 'Grün ins Büro 🚲'),
			},
			{
				type: TicketType.TREASURE_HUNT_BUSINESS_TRIP_END,
				done: false,
				name: useData().getTextValueByKey('mobipoints.treasurehunt.business_trip_end.title', [], 'Grün nach Hause 🚲'),
			},
			{
				type: TicketType.TREASURE_HUNT_EAT_VEGGIE,
				done: false,
				name: useData().getTextValueByKey('mobipoints.treasurehunt.eat_veggie.title', [], 'Veggie Menü 🥦'),
			},
			{
				type: TicketType.TREASURE_HUNT_COLLECT_EASTER_EGG,
				done: false,
				name: useData().getTextValueByKey('mobipoints.treasurehunt.collect_easter_egg.title', [], 'Osterei suche 🥚'),
			},
		]);

		const collectedCount = computed(() => {
			return collectedTickets.value.filter(ticket => ticket.done).length;
		});

		const hasAllTicketsCollected = computed(() => {
			return collectedCount.value === 4 && !isLoading.value;
		});

		const isCollectEasterEggDone = computed(() => {
			const collectEasterEggTask = collectedTickets.value.find(
					ticket => ticket.type === TicketType.TREASURE_HUNT_COLLECT_EASTER_EGG);
			if (collectEasterEggTask) {
				return collectEasterEggTask.done;
			}
			return false; // Return false if the task is not found
		});

		function setDoneStates(type?: TicketType, done = false): void {
			if (type !== undefined) {
				const ticket = collectedTickets.value.find(ticket => ticket.type === type);
				if (ticket) {
					ticket.done = done;
				}
			} else {
				collectedTickets.value.forEach(ticket => {
					ticket.done = done;
				});
			}
		}

    async function loadCollectedTickets() {
      if (treasureWallet.value) {
        const today = getCurrentDateAsIsoString();
        const tomorrowDate = formatDateAsIsoString(addDays(1));
        setDoneStates(undefined, false);
        let trackingCount = 0;
        filterWalletItems(treasureWallet.value?.uuid, today, tomorrowDate, null, null, false).then((items) => {
          items.forEach((ticket) => {
            if (ticket.state !== WalletItemState.ACTIVE) {
              return;
            }
            if (ticket.getChallengeType() === MobiPointsChallengeType.CHALLENGE_TYPE_CASH_VOUCHER) {
              switch (ticket.getVoucherType()) {
                case MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_TREASURE_HUNT_TYPE:
                  setDoneStates(TicketType.TREASURE_HUNT_COLLECT_EASTER_EGG, true);
                  break;
                case MobiPointsQueueEventTypeRedeemVoucherType.REDEEM_VOUCHER_NICESHOPS_VEGGIE_CHALLENGE:
                default:
                  setDoneStates(TicketType.TREASURE_HUNT_EAT_VEGGIE, true);
                  break;
              }
            } else if (ticket.getChallengeType() === MobiPointsChallengeType.CHALLENGE_TYPE_BUSINESS_TRIP) {
              trackingCount++;
            }
          });
          if (trackingCount === 1) {
            setDoneStates(TicketType.TREASURE_HUNT_BUSINESS_TRIP_START, true);
          } else if (trackingCount === 2) {
            setDoneStates(TicketType.TREASURE_HUNT_BUSINESS_TRIP_START, true);
            setDoneStates(TicketType.TREASURE_HUNT_BUSINESS_TRIP_END, true);
          }
        });
      }
    }

		function initTreasureWallet() {
			treasureWallet.value = wallets.value.getFirst_by_Type(WalletType.TREASURES);
		}

		async function refreshCollectedTickets() {
			try {
				if (!wallets.value) {
					const loading = await loadingController.create({
						cssClass: '',
						duration: 3000,
					});

					setTimeout(function() {
						loading.dismiss();
					}, 3000);

					return loading.present().then(() => {
						initWallets(true).then(() => {
							initTreasureWallet();
							loadCollectedTickets().then(() => {
								loading.dismiss();
								isLoading.value = false;
							});
						});
					});
				} else {
					initTreasureWallet();
					loadCollectedTickets();
					isLoading.value = false;
				}
			} catch (error: any | AxiosError) {
				console.log(error);
			}
		}

		async function initWallet(forceReload = false) {
			try {
				if (!wallets.value || forceReload) {
					const loading = await loadingController.create({
						cssClass: '',
						duration: 3000,
					});

					setTimeout(function() {
						loading.dismiss();
					}, 3000);

					return loading.present().then(() => {
						initWallets(forceReload).then(() => {
							initTreasureWallet();
							loadCollectedTickets().then(() => {
								loading.dismiss();
								isLoading.value = false;
							});
						});
					});
				} else {
					initTreasureWallet();
					loadCollectedTickets();
					isLoading.value = false;
				}
			} catch (error: any | AxiosError) {
				console.log(error);
			}
		}

		onMounted(() => {
			initWallet(true);
		});

		return {
			treasureHuntTitle,
			treasureWalletType,
			treasureWallet,
			firstTimeTreasureHuntAnimation,
			treasureHuntDialogBackButton,
			treasureHuntDialogTitle,
			treasureHuntDialogDescription,
			hasSeenTreasureHuntChallengeDialog,
			setHasSeenTreasureHuntChallengeDialog,
			isAuthenticated,
			wallets,
			collectedTickets,
			collectedCount,
			isCollectEasterEggDone,
			easterEggBannerTitle,
			treasureVoucherType,
			niceshopsLogo,
			isIosPlatform,
			isLoading,
			hasAllTicketsCollected,
		  loadCollectedTickets,
		  initWallet,
					refreshCollectedTickets
		};
	},
};
</script>

<style scoped>
.buttonShowChallengeDialog {
    --color: var(--ion-color-medium);
}
.buttonRefreshWallet {
    --color: var(--ion-color-medium);
}
</style>
