<template>
	<ion-page>
		<ion-content class="ion-no-padding">
			<div class="bg-white">
				<div class="isolate">
					<!-- Hero section -->
					<div class="relative pt-14">
						<div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
							 aria-hidden="true">
							<div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
								 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
						</div>
						<div class="py-24 sm:py-32">
							<div class="mx-auto max-w-7xl px-6 lg:px-8">
								<div class="mx-auto max-w-2xl text-center">
									<h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" v-html="titleText"></h1>
									<p class="mt-6 text-lg leading-8 text-gray-600" v-html="descriptionText"></p>
									<div class="mt-10 flex items-center justify-center gap-x-6">
										<router-link to="/login">
											<a class="h-12 cursor-pointer rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Login
												<span aria-hidden="true">→</span></a>
										</router-link>
									</div>
								</div>
<!--								<div class="mt-16 flow-root sm:mt-24">-->
<!--									<div class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">-->
<!--										<img src="https://tailwindui.com/img/component-images/project-app-screenshot.png"-->
<!--											 alt="App screenshot" width="2432" height="1442"-->
<!--											 class="rounded-md shadow-2xl ring-1 ring-gray-900/10"/>-->
<!--									</div>-->
<!--								</div>-->
							</div>
						</div>
						<div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
							 aria-hidden="true">
							<div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
								 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
						</div>
					</div>

					<!-- Feature section -->
					<div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
						<div class="mx-auto max-w-2xl lg:text-center">
							<h2 class="text-base font-semibold leading-7 text-indigo-600" v-html="featureTitle"></h2>
							<p class="mt-6 text-lg leading-8 text-gray-600" v-html="featureDescription"></p>
						</div>
						<div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
							<dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
								<div v-for="feature in features" :key="feature.name" class="relative pl-16">
									<dt class="text-base font-semibold leading-7 text-gray-900">
										<div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
											<component :is="feature.icon" class="h-6 w-6 text-white"
													   aria-hidden="true"/>
										</div>
										{{ feature.name }}
									</dt>
									<dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
								</div>
							</dl>
						</div>
					</div>

					<!-- Testimonial section -->
					<div class="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
						<div class="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
							<img class="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
								 src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
								 alt=""/>
							<div class="absolute inset-0 bg-gray-900/90 mix-blend-multiply"/>
							<div class="absolute -left-80 -top-56 transform-gpu blur-3xl" aria-hidden="true">
								<div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-[0.45]"
									 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
							</div>
							<div class="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
								 aria-hidden="true">
								<div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
									 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
							</div>
							<div class="relative mx-auto max-w-2xl lg:mx-0">
								<blockquote class="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
									<p>“{{quoteTitle}}”</p>
									<div class="font-semibold text-center mt-2" v-html="quoteAuthor"></div>
								</blockquote>
							</div>
						</div>
					</div>

					<!-- FAQs -->
					<div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
						<div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
							<h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">FAQ</h2>
							<dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
								<Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6"
											v-slot="{ open }">
									<dt>
										<DisclosureButton
												class="flex w-full items-start justify-between text-left text-gray-900">
											<span class="text-base font-semibold leading-7">{{ faq.question }}</span>
											<span class="ml-6 flex h-7 items-center">
										<ion-icon v-if="!open" size="large" :icon="icons.addOutline"
												  aria-hidden="true"></ion-icon>
										<ion-icon v-else size="large" :icon="icons.removeOutline"
												  aria-hidden="true"></ion-icon></span>
										</DisclosureButton>
									</dt>
									<DisclosurePanel as="dd" class="mt-2 pr-12">
										<p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
									</DisclosurePanel>
								</Disclosure>
							</dl>
						</div>
					</div>

					<!-- CTA section -->
					<div class="relative -z-10 mt-32 px-6 lg:px-8">
						<div class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
							 aria-hidden="true">
							<div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
								 style="clip-path: polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)"/>
						</div>
						<div class="mx-auto max-w-2xl text-center">
							<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Auf gehts!</h2>
							<div class="mt-10 flex items-center justify-center gap-x-6">
								<router-link to="/login">
									<a class="h-12 cursor-pointer rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
										Login</a>
								</router-link>
							</div>
						</div>
						<div class="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
							 aria-hidden="true">
							<div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
								 style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
						</div>
					</div>
				</div>

				<!-- Footer -->
				<div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
					<footer aria-labelledby="footer-heading"
							class="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32">
						<h2 id="footer-heading" class="sr-only">Footer</h2>
						<div class="xl:grid xl:grid-cols-3 xl:gap-8">
							<img class="h-7" src="../assets/images/logo/MobilityLogo_Grn.png"
								 alt="MobiPoints"/>
							<div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
								<div class="md:grid md:grid-cols-2 md:gap-8">
									<div>
										<h3 class="text-sm font-semibold leading-6 text-gray-900">Solutions</h3>
										<ul role="list" class="mt-6 space-y-4">
											<li v-for="item in footerNavigation.solutions" :key="item.name">
												<a :href="item.href"
												   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{
														item.name
													}}</a>
											</li>
										</ul>
									</div>
									<div class="mt-10 md:mt-0">
										<h3 class="text-sm font-semibold leading-6 text-gray-900">Support</h3>
										<ul role="list" class="mt-6 space-y-4">
											<li v-for="item in footerNavigation.support" :key="item.name">
												<a :href="item.href"
												   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{
														item.name
													}}</a>
											</li>
										</ul>
									</div>
								</div>
								<div class="md:grid md:grid-cols-2 md:gap-8">
									<div>
										<h3 class="text-sm font-semibold leading-6 text-gray-900">Company</h3>
										<ul role="list" class="mt-6 space-y-4">
											<li v-for="item in footerNavigation.company" :key="item.name">
												<a :href="item.href"
												   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{
														item.name
													}}</a>
											</li>
										</ul>
									</div>
									<div class="mt-10 md:mt-0">
										<h3 class="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
										<ul role="list" class="mt-6 space-y-4">
											<li v-for="item in footerNavigation.legal" :key="item.name">
												<a :href="item.href"
												   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{
														item.name
													}}</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {ref} from 'vue';
import {IonContent, IonIcon, IonPage} from '@ionic/vue';
import useData from '@/composable/useData';
import {
	ArrowPathIcon,
	CloudArrowUpIcon,
	FingerPrintIcon,
	LockClosedIcon,
	GlobeEuropeAfricaIcon,
	TrophyIcon,
	MapIcon,
	SunIcon,
} from '@heroicons/vue/24/outline';
import {CheckIcon} from '@heroicons/vue/20/solid';
import * as icons from 'ionicons/icons';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';

export default {
	name: 'Landing',
	components: {
			DisclosurePanel,
			DisclosureButton,
			Disclosure,
			IonIcon,
		IonPage, IonContent,
		ArrowPathIcon,
		CloudArrowUpIcon,
		FingerPrintIcon,
		LockClosedIcon,
		CheckIcon,
	},
	setup() {
		const {getTextValueByKey} = useData();

		const features = [
			{
				name: getTextValueByKey('landing.feature.tracking.title', [], ' 🚴‍♂️🌍 Punkte Sammeln durch Nachhaltiges Pendeln'),
				description: getTextValueByKey('landing.feature.tracking.description', [], 'Mit MobiPoints wird jede nachhaltige Fahrt belohnt! Ob Du zur Arbeit radelst, öffentliche Verkehrsmittel nutzt, zu Fuß gehst oder eine Fahrgemeinschaft bildest – unser intelligentes Tracking-System erfasst jede umweltfreundliche Meile. Für jede grüne Entscheidung auf Deinem Weg sammelst Du Punkte, die Deinen Beitrag zum Umweltschutz sichtbar machen. Mach jede Fahrt zur Chance, unseren Planeten zu schützen und gleichzeitig tolle Belohnungen zu verdienen!'),
				icon: MapIcon,
			},
			{
				name: getTextValueByKey('landing.feature.veggie.title', [], '🥗🌱 Punkte für Vegetarisches Essen in der Kantine'),
				description: getTextValueByKey('landing.feature.veggie.description', [], 'Ernährung spielt eine große Rolle im Klimaschutz. MobiPoints belohnt Dich für jede vegetarische Mahlzeit in der Firmenkantine. Zeige mit Deiner Essenswahl, dass Dir die Umwelt am Herzen liegt, und sammle Punkte, die Du gegen spannende Prämien eintauschen kannst. Mit jedem vegetarischen Gericht trägst Du nicht nur zu Deiner Gesundheit bei, sondern reduzierst auch Deinen ökologischen Fußabdruck. Starte jetzt und mach Deine Mittagspause zum Umweltbeitrag!'),
				icon: SunIcon,
			},
			{
				name: getTextValueByKey('landing.feature.sustainability.title', [], '♻️✨ Nachhaltiges Handeln im Alltag'),
				description: getTextValueByKey('landing.feature.sustainability.description', [], 'MobiPoints geht über das Pendeln und Essen hinaus und belohnt Dein nachhaltiges Handeln im gesamten Alltag. Ob Du Müll trennst, Energie sparst, nachhaltige Produkte kaufst oder an Clean-Up-Events teilnimmst – mit unserer App kannst Du für all diese Aktionen Punkte sammeln. Wir möchten Dich motivieren, in jedem Aspekt Deines Lebens umweltbewusste Entscheidungen zu treffen. Lass uns gemeinsam die kleinen Schritte zelebrieren, die in ihrer Summe einen großen Unterschied machen!'),
				icon: GlobeEuropeAfricaIcon,
			},
			{
				name: getTextValueByKey('landing.feature.challenges.title', [], '🏆👫 Herausforderungen und Wettbewerbe'),
				description: getTextValueByKey('landing.feature.challenges.description', [], 'Steigere den Spaß und das Engagement mit MobiPoints durch spannende Challenges! Tritt gegen Deine Kollegen an, setze neue persönliche Bestleistungen und feiere Deine Erfolge gemeinsam. Unsere App bietet eine Vielzahl an Herausforderungen, die nicht nur Deinen Teamgeist stärken, sondern auch dazu motivieren, nachhaltige Gewohnheiten zu entwickeln und beizubehalten. Ob es darum geht, die meisten vegetarischen Mahlzeiten zu wählen oder die meisten grünen Kilometer zurückzulegen – jede Challenge macht Spaß und stärkt das Bewusstsein für unseren Planeten. Sei dabei und beweise, dass Du ein Umwelt-Champion bist!'),
				icon: TrophyIcon,
			},
		];

		const faqs = [
			{
				id: 1,
				question: 'Wie funktioniert das Tracking meiner nachhaltigen Fahrten?',
				answer: 'Unser App verwendet GPS und andere Sensoren deines Smartphones, um deine Fortbewegungsmittel automatisch zu erkennen und zu verfolgen, wenn du dich für eine nachhaltige Option wie Fahrradfahren, Gehen, die Nutzung öffentlicher Verkehrsmittel oder Fahrgemeinschaften entscheidest. Nach jeder Fahrt erhältst du eine Zusammenfassung und die entsprechenden MobiPoints gutgeschrieben.\n',
			},
			{
				id: 2,
				question: 'Wie werden Punkte für vegetarische Mahlzeiten in der Kantine gutgeschrieben?',
				answer: 'Ganz einfach! Sobald du in der Firmenkantine ein vegetarisches Gericht auswählst und beim abholen deinen QR-Code scannst, wird das Gericht automatisch erkannt und die entsprechenden MobiPoints werden direkt in deiner App gutgeschrieben. Du musst nichts weiter tun – genieße dein Essen und sammle Punkte für deinen Beitrag zum Umweltschutz!',
			},
			{
				id: 3,
				question: 'Welche Art von Belohnungen kann ich mit meinen Punkten erhalten?',
				answer: 'Deine gesammelten Punkte können derzeit exklusiv gegen Gutscheine eingetauscht werden, die bei niceshops oder für ausgewählte Produkte verwendet werden können. Wir arbeiten daran, das Spektrum der Belohnungen kontinuierlich zu erweitern, um dir noch mehr Möglichkeiten zu bieten, deine Punkte sinnvoll einzusetzen.',
			},
			{
				id: 4,
				question: 'Wie funktionieren die Herausforderungen und Wettbewerbe?',
				answer: 'Unsere App bietet regelmäßig Herausforderungen und Wettbewerbe an, bei denen du gegen dich selbst, deine Kollegen oder andere Community-Mitglieder antreten kannst. Dazu gehören zum Beispiel Wettbewerbe, wer die meisten vegetarischen Mahlzeiten in einem Monat isst oder wer die meisten nachhaltigen Kilometer zurücklegt. Teilnahme und Erfolge werden in der App festgehalten und belohnt.',
			},
			{
				id: 5,
				question: 'Kann ich meine Punkte auch spenden?',
				answer: 'Das Spenden von Punkten an Umwelt- und Nachhaltigkeitsprojekte ist eine Funktion, die wir sehr ernst nehmen und die wir in absehbarer Zeit einführen möchten. Wir verstehen die Wichtigkeit des Beitrags zu größeren Zielen und arbeiten hart daran, diese Option so schnell wie möglich verfügbar zu machen. Halte Ausschau nach Updates, denn deine Punkte können bald einen noch größeren Impact haben!',
			},
			{
				id: 6,
				question: 'Ist MobiPoints kostenlos?',
				answer: 'Ja, die MobiPoints-App ist vollkommen kostenlos. Unser Ziel ist es, so viele Menschen wie möglich zu motivieren, nachhaltiger zu leben und zu arbeiten. Es gibt keine versteckten Kosten oder Gebühren für die Nutzung der App und die Teilnahme an Herausforderungen.',
			},
		];

		const footerNavigation = {
			solutions: [],
			support: [],
			company: [],
			legal: [
				{name: 'Impressumg', href: 'https://www.crudafoch.com/impressum.html'},
				{name: 'Datenschutz', href: 'https://www.crudafoch.com/privacy.html'},
			],
		};

		const titleText = ref(getTextValueByKey('landing.title', [], '🌍 MobiPoints: Dein grüner Fußabdruck zählt! 🚴‍♂️🚌🌱'));
		const descriptionText = ref(getTextValueByKey('landing.title', [], 'Willkommen bei MobiPoints – der App, die Dein Engagement für die Umwelt sichtbar und belohnbar macht! 🎉 Mit MobiPoints leistest du mit deinem nachhaltigen Handeln nicht nur einen Beitrag zum Klimaschutz, sondern sammelst gleichzeitig Punkte, die sich auszahlen. 🚀'));

		const featureTitle = ref(getTextValueByKey('landing.feature.title', [], 'MobiPoints'));
		const featureDescription = ref(getTextValueByKey('landing.feature.description', [], 'Entdecke die Welt der Nachhaltigkeit'));

		const quoteTitle = ref(getTextValueByKey('landing.quote.title', [], 'Sei der Wandel, den Du in der Welt sehen möchtest.'));
		const quoteAuthor = ref(getTextValueByKey('landing.quote.author', [], 'Mahatma Gandhi'));

		return {titleText, descriptionText, featureTitle, featureDescription, footerNavigation, faqs, features, quoteTitle, quoteAuthor, icons};
	},
};
</script>

<style>
</style>
