import {AbstractWallet} from '@/mobipoints/wallet/abstract_wallet.type';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';

export class WalletList extends Array<AbstractWallet> {

	constructor(items: Array<AbstractWallet> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractWallet) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractWallet {
		return this[index];
	}

	getItemById(item_uuid: string): AbstractWallet | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getFirst_by_Type(walletType: WalletType): AbstractWallet | undefined {
		return this.find(({type}) => type === walletType);
	}
}