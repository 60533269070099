<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>{{co2Title}}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">{{co2Title}}</ion-title>
				</ion-toolbar>
			</ion-header>


			<TransitionRoot as="template" :show="!hasSeenCo2ChallengeDialog && isAuthenticated">
				<TailwindDialog as="div" class="relative z-10" @close="setHasSeenCo2ChallengeDialog(true)">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<div class="fixed inset-0 z-10 overflow-y-auto">
						<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
									<div>
										<div class="mt-3 text-center sm:mt-5">
											<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{co2DialogTitle}}</DialogTitle>
											<div class="mt-2">
												<p class="text-sm text-gray-500">{{co2DialogDescription}}</p>
											</div>
											<ion-img class="w-full h-full" :src="firstTimeCo2Animation"
													 :alt="'test'"/>
										</div>
									</div>
									<div class="mt-5 sm:mt-6">
										<button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="setHasSeenCo2ChallengeDialog(true)">{{co2DialogBackButton}}</button>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</TailwindDialog>
			</TransitionRoot>

			<UserWallet :wallet-type="co2EmissionWallet"></UserWallet>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonImg} from '@ionic/vue';
import UserWallet from '@/components/wallet/UserWallet.vue';
import {ref} from 'vue';
import useData from '@/composable/useData';
import {WalletType} from '@/mobipoints/wallet/wallet.interface';
import { Dialog as TailwindDialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import useCustomStore from '@/composable/useCustomStore';
import useAuth from '@/composable/useAuth';

export default {
	name: 'Co2Tab',
	components: {IonImg, UserWallet, IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
			TailwindDialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot },
	setup(props: any) {
		const {setHasSeenCo2ChallengeDialog, hasSeenCo2ChallengeDialog} = useCustomStore();
		const {isAuthenticated} = useAuth();

		const firstTimeCo2Animation = ref("/assets/img/co2/sunshine-cropped.gif");
		const co2EmissionWallet = WalletType.CARBON_EMISSIONS;
		const co2Title = ref(useData().getTextValueByKey('mobipoints.co2.title', [], 'Mein CO2 Verbrauch'));
		const co2DialogTitle = ref(useData().getTextValueByKey('mobipoints.co2.dialog.title', [], 'CO2 Challenge'));
		const co2DialogDescription = ref(useData().getTextValueByKey('mobipoints.co2.dialog.description', [], '💚 Unsere neue CO2 Challenge belohnt dich mit Punkten für das Einsparen von CO2! 🌍 Hier findest du deine Punktehistorie! So behältst du den Überblick über deine Erfolge im CO2-Sparen'));
		const co2DialogBackButton = ref(useData().getTextValueByKey('mobipoints.co2.dialog.back.button.title', [], 'Zur Übersicht'));


		return {co2Title, co2EmissionWallet, firstTimeCo2Animation, co2DialogBackButton, co2DialogTitle, co2DialogDescription, hasSeenCo2ChallengeDialog, setHasSeenCo2ChallengeDialog, isAuthenticated}
	}
}
</script>