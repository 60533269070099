<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>Map</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Tab 2</ion-title>
				</ion-toolbar>
			</ion-header>
			<div style="height: 99%;">
				<button @click="startGeolocation">START GEOLOCATION</button>
				<button @click="stopGeolocation">STOP GEOLOCATION</button>
				{{currentPosition}}
				<default-map :disableUI="false" :zoom="12" mapType="roadmap" :init-cords="{ lat: 38.8977859, lng: -77.0057621 }"></default-map>
<!--				<default-map :disableUI="false" :zoom="12" mapType="roadmap" :init-cords="{ lat: currentPosition.latitude, lng: currentPosition.longitude }"></default-map>-->

				<!--			TODO show add button in explorecontainer-->
<!--				<ExploreContainer name="Tab 2 page">-->
<!--				</ExploreContainer>-->
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import DefaultMap from "@/components/map/DefaultMap.vue";
import {MobiPointsTrackingGeolocationWebBackgroundGeolocation} from "@/mobipoints/tracking/geolocation/web/web_background_geolocation";
import store from "@/store";
import { computed, onMounted } from 'vue';
import {MobiPointsTrackingCoordinateCoordinate} from "@/mobipoints/tracking/coordinate/coordinate.type";

export default {
	name: 'DetailTab',
	components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, DefaultMap},
	setup(props: any) {
		console.log(props);

		const currentPosition = computed<MobiPointsTrackingCoordinateCoordinate>(() => store.getters['tracking/getCurrentPosition']);

		// const backgroundGeolocation = null;
		const backgroundGeolocation = new MobiPointsTrackingGeolocationWebBackgroundGeolocation();

		function startGeolocation() {
			backgroundGeolocation.startGeolocationTracking();
		}

		function stopGeolocation() {
			backgroundGeolocation.stopGeolocationTracking();
		}

		onMounted(() => {
			// backgroundGeolocation.handlePermissionNative();
			// backgroundGeolocation.handlePermission();
		});

		return {
			startGeolocation,
			stopGeolocation,
			currentPosition
		};
	}
}
</script>